import {
  UPDATE,
  UPDATE_SUCCEEDED,
  UPDATE_FAILED,
  ACTIVE,
  ACTIVE_SUCCEEDED,
  ACTIVE_FAILED,
  OBLIGATORIO,
  OBLIGATORIO_SUCCEEDED,
  OBLIGATORIO_FAILED,
  LIST_MODULESEMESTER,
  LIST_MODULESEMESTER_SUCCEEDED,
} from './action-types';
import utils from '../utils';

const initialState = {
  loading: false,
  semestersmodules: [],
};

const roadMapModulesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case UPDATE: {
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_SUCCEEDED: {
      utils.dialog({
        title: '¡Perfecto!',
        message: 'La petición se ha procesado con éxito',
      });
      return {
        ...state,
        loading: false,
      };
    }

    case UPDATE_FAILED: {
      const { error } = action;

      let message = 'La petición no se ha completado con éxito.';

      if (error.response && error.response.data && error.response.data.message) {
        ({ message } = error.response.data);
      }
      utils.dialog({
        type: 'error',
        title: 'Error inesperado',
        message,
      });
      return {
        ...state,
        loading: false,
      };
    }
    case ACTIVE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ACTIVE_SUCCEEDED: {
      utils.dialog({
        title: '¡Perfecto!',
        message: 'La petición se ha procesado con éxito',
      });
      return {
        ...state,
        loading: false,
      };
    }

    case ACTIVE_FAILED: {
      const { error } = action;

      let message = 'La petición no se ha completado con éxito.';

      if (error.response && error.response.data && error.response.data.message) {
        ({ message } = error.response.data);
      }
      utils.dialog({
        type: 'error',
        title: 'Error inesperado',
        message,
      });
      return {
        ...state,
        loading: false,
      };
    }
    case OBLIGATORIO: {
      return {
        ...state,
        loading: true,
      };
    }
    case OBLIGATORIO_SUCCEEDED: {
      utils.dialog({
        title: '¡Perfecto!',
        message: 'La petición se ha procesado con éxito',
      });
      return {
        ...state,
        loading: false,
      };
    }

    case OBLIGATORIO_FAILED: {
      const { error } = action;

      let message = 'La petición no se ha completado con éxito.';

      if (error.response && error.response.data && error.response.data.message) {
        ({ message } = error.response.data);
      }
      utils.dialog({
        type: 'error',
        title: 'Error inesperado',
        message,
      });
      return {
        ...state,
        loading: false,
      };
    }
    case LIST_MODULESEMESTER:
    case LIST_MODULESEMESTER_SUCCEEDED: {
      const { data } = action;
      return {
        ...state,
        semestersmodules: data,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default roadMapModulesReducer;
