import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'ui/ReactTable';
import 'react-table/react-table.css';
import { Button } from '@material-ui/core';
import SubTitle from 'ui/SubTitle';
import { Link } from 'react-router-dom';

class BasicInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      data,
      methodology,
      oldMethodology,
      text,
      showStudentButton,
    } = this.props;

    const dataSource = [data];
    let studentButton;
    if (showStudentButton) {
      studentButton = <Link to={`/alumnos/editar/${dataSource[0].idStudent}`}><Button size="small">Ver ficha de alumno</Button></Link>;
    }

    return (
      <div className="basic-information">
        <SubTitle>
          {text}
          {studentButton}
        </SubTitle>
        <ReactTable
          columns={[
            {
              Header: 'Última actualización',
              accessor: 'updatedAt',
            },
            {
              Header: 'Metodología',
              Cell: methodology,
            },
            {
              Header: 'Metodología Antigua',
              Cell: oldMethodology,
            },
            {
              Header: 'Pedido',
              accessor: 'idEnrollment',
            },
            {
              Header: 'Centro',
              accessor: 'academyName',
            },
            {
              Header: 'Email',
              accessor: 'email',
            },
            {
              Header: 'Nombre',
              accessor: 'studentName',
            },
            {
              Header: 'Nif',
              accessor: 'idDocumentNumber',
            },
            {
              Header: 'Formación',
              accessor: 'courseName',
            },
            {
              Header: 'Estado',
              accessor: 'state',
            },
            {
              Header: 'Fecha de pago',
              accessor: 'paymentDate',
            },
          ]}
          data={dataSource}
          showPagination={false}
          defaultPageSize={1}
        />
      </div>
    );
  }
}

BasicInformation.propTypes = {
  data: PropTypes.object,
  text: PropTypes.string,
  methodology: PropTypes.string,
  oldMethodology: PropTypes.string,
  showStudentButton: PropTypes.bool,
};

BasicInformation.defaultProps = {
  data: {},
  methodology: '',
  oldMethodology: '-',
  text: 'Información Básica',
  showStudentButton: false,
};

export default BasicInformation;
