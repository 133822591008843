import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import theme from 'ui/theme';
import Select from '@material-ui/core/Select';
import {
  TextField,
  Button,
} from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import themeFilters from 'ui/muiFiltersTheme';
import { Wrapper } from './Filters.styles';

class Filters extends React.Component {
  constructor(props) {
    super(props);
    const {
      outlook,
      ldap,
      alexia,
      adobe,
      outlookGroup,
      outlookLicence,
      alexiaCheck,
      alexiaExtra,
      alexiaCohort,
      alexiaEnrollment,
      outlookCheck,
      adobeCheck,
      alexia101,
      idDocumentNumber,
    } = props.filtersApplied;
    this.state = {
      outlook,
      ldap,
      alexia,
      adobe,
      outlookGroup,
      outlookLicence,
      alexiaCheck,
      alexiaExtra,
      alexiaCohort,
      alexiaEnrollment,
      outlookCheck,
      adobeCheck,
      alexia101,
      idDocumentNumber,
    };
  }

  onApplyFilters = (e) => {
    e.preventDefault();
    const { applyFilters } = this.props;

    const {
      outlook,
      ldap,
      alexia,
      adobe,
      outlookGroup,
      outlookLicence,
      alexiaCheck,
      alexiaExtra,
      alexiaCohort,
      alexiaEnrollment,
      outlookCheck,
      adobeCheck,
      alexia101,
      idDocumentNumber,
    } = this.state;
    applyFilters({
      outlook,
      ldap,
      alexia,
      adobe,
      outlookGroup,
      outlookLicence,
      alexiaCheck,
      alexiaExtra,
      alexiaCohort,
      alexiaEnrollment,
      outlookCheck,
      adobeCheck,
      alexia101,
      idDocumentNumber,
    });
  };

  resetFilters = async (e) => {
    e.preventDefault();
    this.setState({
      outlook: null,
      ldap: null,
      alexia: null,
      adobe: null,
      outlookGroup: null,
      outlookLicence: null,
      alexiaCheck: null,
      alexiaExtra: null,
      alexiaCohort: null,
      alexiaEnrollment: null,
      outlookCheck: null,
      adobeCheck: null,
      alexia101: null,
      idDocumentNumber: '',
    });
  }

  handleChange = name => (e) => {
    this.setState({ [name]: e.target.value });
  }

  render() {
    const optionsFilter = [];
    optionsFilter.push(<option key={-1} value={-1}>Vacio</option>);
    optionsFilter.push(<option key={0} value={0}>Waiting</option>);
    optionsFilter.push(<option key={1} value={1}>Error</option>);
    optionsFilter.push(<option key={2} value={2}>Success</option>);

    const {
      outlook,
      ldap,
      alexia,
      adobe,
      outlookGroup,
      outlookLicence,
      alexiaCheck,
      alexiaExtra,
      alexiaCohort,
      alexiaEnrollment,
      outlookCheck,
      adobeCheck,
      alexia101,
      idDocumentNumber,
    } = this.state;

    return (
      <Wrapper theme={theme}>
        <MuiThemeProvider theme={themeFilters}>
          <form onSubmit={this.onApplyFilters} className="filters-form">
            <div className="filters--simple-row">
              <TextField
                className="filter-item filter-space"
                id="idDocumentNumber"
                placeholder="DNI"
                onChange={this.handleChange('idDocumentNumber')}
                value={idDocumentNumber}
              />
              <Select
                native
                id="outlook"
                onChange={this.handleChange('outlook')}
                className="filter-item"
                defaultValue={outlook}
              >
                <option value={null}>Outlook</option>
                {optionsFilter}
              </Select>
              <Select
                native
                id="ldap"
                onChange={this.handleChange('ldap')}
                className="filter-item"
                defaultValue={ldap}
              >
                <option value={null}>Ldap</option>
                {optionsFilter}
              </Select>
              <Select
                native
                id="alexia"
                onChange={this.handleChange('alexia')}
                className="filter-item"
                defaultValue={alexia}
              >
                <option value={null}>Alexia</option>
                {optionsFilter}
              </Select>
              <Select
                native
                id="adobe"
                onChange={this.handleChange('adobe')}
                className="filter-item"
                defaultValue={adobe}
              >
                <option value={null}>Adobe</option>
                {optionsFilter}
              </Select>
              <Select
                native
                id="outlookGroup"
                onChange={this.handleChange('outlookGroup')}
                className="filter-item"
                defaultValue={outlookGroup}
              >
                <option value={null}>Outlook Group</option>
                {optionsFilter}
              </Select>
              <Select
                native
                id="outlookLicence"
                onChange={this.handleChange('outlookLicence')}
                className="filter-item"
                defaultValue={outlookLicence}
              >
                <option value={null}>Outlook Licence</option>
                {optionsFilter}
              </Select>
              <Select
                native
                id="alexiaCheck"
                onChange={this.handleChange('alexiaCheck')}
                className="filter-item"
                defaultValue={alexiaCheck}
              >
                <option value={null}>Moodle Check</option>
                {optionsFilter}
              </Select>
              <Select
                native
                id="alexiaExtra"
                onChange={this.handleChange('alexiaExtra')}
                className="filter-item"
                defaultValue={alexiaExtra}
              >
                <option value={null}>Moodle Extra</option>
                {optionsFilter}
              </Select>
              <Select
                native
                id="alexiaCohort"
                onChange={this.handleChange('alexiaCohort')}
                className="filter-item"
                defaultValue={alexiaCohort}
              >
                <option value={null}>Moodle Cohort</option>
                {optionsFilter}
              </Select>
              <Select
                native
                id="alexiaEnrollment"
                onChange={this.handleChange('alexiaEnrollment')}
                className="filter-item"
                defaultValue={alexiaEnrollment}
              >
                <option value={null}>Moodle Enrollment</option>
                {optionsFilter}
              </Select>
              <Select
                native
                id="outlookCheck"
                onChange={this.handleChange('outlookCheck')}
                className="filter-item"
                defaultValue={outlookCheck}
              >
                <option value={null}>Outlook Check</option>
                {optionsFilter}
              </Select>
              <Select
                native
                id="adobeCheck"
                onChange={this.handleChange('adobeCheck')}
                className="filter-item"
                defaultValue={adobeCheck}
              >
                <option value={null}>Adobe Check</option>
                {optionsFilter}
              </Select>
              <Select
                native
                id="alexia101"
                onChange={this.handleChange('alexia101')}
                className="filter-item"
                defaultValue={alexia101}
              >
                <option value={null}>Moodle 101</option>
                {optionsFilter}
              </Select>

              <Button
                color="primary"
                type="submit"
                className="apply-filters-button"
              >
                APLICAR FILTROS
              </Button>
              <Button
                color="secondary"
                onClick={this.resetFilters}
                className="reset-filters-button"
              >
                LIMPIAR FILTROS
              </Button>
            </div>
          </form>
        </MuiThemeProvider>
      </Wrapper>
    );
  }
}

Filters.propTypes = {
  // dispatch: PropTypes.func.isRequired,
  applyFilters: PropTypes.func.isRequired,
  filtersApplied: PropTypes.object,
};

Filters.defaultProps = {
  filtersApplied: {},
};

export default connect(
  state => ({
    filtersApplied: state.vouchers.filters,
  }),
)(Filters);
