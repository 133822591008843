import {
  REQUEST_UTM_FILTERS,
  REQUEST_UTM_FILTERS_SUCCEEDED,
  REQUEST_UTM_FILTERS_FAILED,
  REQUEST_UTM_EXPORT,
  REQUEST_UTM_EXPORT_SUCCEEDED,
  REQUEST_UTM_EXPORT_FAILED,
  REQUEST_UTM_EXPORT_WITH_ENROLL,
  REQUEST_UTM_EXPORT_WITH_ENROLL_SUCCEEDED,
  REQUEST_UTM_EXPORT_WITH_ENROLL_FAILED,
} from './action-types';

import utils from '../utils';

const initialState = {
  data: [],
  totals: {},
  loading: false,
};

const utmReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case REQUEST_UTM_EXPORT:
    case REQUEST_UTM_FILTERS:
    case REQUEST_UTM_EXPORT_WITH_ENROLL:
    {
      return {
        ...state,
        loading: true,
      };
    }
    case REQUEST_UTM_FILTERS_SUCCEEDED: {
      const {
        data, pages, bindingObject: { filters },
      } = action;
      return {
        ...state,
        utms: data,
        filters,
        pages,
        loading: false,
      };
    }
    case REQUEST_UTM_FILTERS_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error inesperado',
        message: 'No se ha podido recoger los ciclos, ha ocurrido un error.',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case REQUEST_UTM_EXPORT_SUCCEEDED:
    case REQUEST_UTM_EXPORT_WITH_ENROLL_SUCCEEDED: {
      return {
        ...state,
        loading: false,
      };
    }
    case REQUEST_UTM_EXPORT_FAILED:
    case REQUEST_UTM_EXPORT_WITH_ENROLL_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error en la exportación',
        message: 'Ha ocurrido un error contacte con el administrador',
      });
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default utmReducer;
