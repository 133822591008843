import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  requestEnrollmentModify,
  checkModule,
  moveModuleTransaction,
  checkUnit,
  setCurrentTab,
  createTransaction,
  removeTransaction,
  getTransactionPdf,
  setModuleTransaction,
  setUnitTransaction,
  setModuleCostTransaction,
  setUnitCostTransaction,
  setDiscountCostTransaction,
  setTransactionPenalty,
  setTransactionUpdatePrices,
} from '$redux/enrollments/actions';
import Title from 'ui/Title';
import Loading from '$routes/Loading';
import Failed from '$routes/Failed';
import { Wrapper } from './Modify.styles';
import BasicInformation from '../BasicInformation';
import Transactions from './Transactions';
import RoadMap from './RoadMap';
import Methodology from './Methodology';


class Enrollments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    dispatch(requestEnrollmentModify(match.params.idEnrollment));
  }

  onCheckUnit = (idModule, idUnit, checked, semester) => {
    const { dispatch } = this.props;
    dispatch(checkUnit(idModule, idUnit, checked, semester));
  }

  onCheckModule = (idModule, checked, semester) => {
    const { dispatch } = this.props;
    dispatch(checkModule(idModule, checked, semester));
  }

  onMoveModule = (idModule, semester, courseYear) => {
    const { dispatch, enrollment } = this.props;
    dispatch(
      moveModuleTransaction(enrollment.idEnrollment, idModule, semester, courseYear),
    );
  }

  onTabChange = (event, value) => {
    const { dispatch } = this.props;
    dispatch(setCurrentTab(value));
  };

  onSend = (value) => {
    const { dispatch } = this.props;
    const enrollmentTransaction = value;
    dispatch(createTransaction({ enrollmentTransaction }));
  };

  setModule = (idModule, value) => {
    const { dispatch } = this.props;
    dispatch(setModuleTransaction(idModule, value));
    this.forceUpdate();
  }

  setModuleCost = (idModule, value) => {
    const { dispatch } = this.props;
    dispatch(setModuleCostTransaction(idModule, value));
    this.forceUpdate();
  }

  setUnit = (idModule, idUnit, value) => {
    const { dispatch } = this.props;
    dispatch(setUnitTransaction(idModule, idUnit, value));
    this.forceUpdate();
  }


  setUnitCost = (idModule, idUnit, value) => {
    const { dispatch } = this.props;
    dispatch(setUnitCostTransaction(idModule, idUnit, value));
    this.forceUpdate();
  }

  setDiscountCost = (newDiscount) => {
    const { dispatch } = this.props;
    dispatch(setDiscountCostTransaction(newDiscount));
    this.forceUpdate();
  }

  setPenalty = (value) => {
    const { dispatch } = this.props;
    dispatch(setTransactionPenalty(value));
    this.forceUpdate();
  }

  onRemove = (value) => {
    const { dispatch } = this.props;
    dispatch(removeTransaction(value));
  };

  getTransactionPdf = (idEnrollmentTransaction) => {
    const { dispatch } = this.props;
    dispatch(getTransactionPdf(idEnrollmentTransaction));
  }

  changeMethodology = (idEnrollment, idTrainingPlan, idRoadMap, idAcademy) => {
    if (!idEnrollment || idTrainingPlan === 0 || idRoadMap === 0 || idAcademy === 0) {
      alert('Para realizar el cambio de metodología hay que rellenar todas las propiedades');
    }

    window.location.href = `/matriculas/metodologia/${idEnrollment}/${idTrainingPlan}/${idRoadMap}/${idAcademy}`;
  }

  setNewPrices = (idEnrollment, amount, enrollmentTransactions) => {
    const { dispatch } = this.props;

    const modules = [];
    const discounts = [];
    enrollmentTransactions.forEach((et) => {
      et.Modules.concat(et.ModuleHolds).forEach((m) => {
        if (m) {
          const units = [];
          m.Units.forEach((u) => {
            units.push({
              idUnit: u.idUnit,
              cost: u.cost,
            });
          });
          modules.push({
            idModule: m.idModule,
            cost: m.cost,
            units,
          });
        }
      });

      et.Discounts.forEach((discount) => {
        discounts.push({
          idEnrollmentTransactionDiscount: discount.idEnrollmentTransactionDiscount,
          amount: discount.amount,
        });
      });
    });

    const newPrices = {
      idEnrollment,
      amount,
      modules,
      discounts,
    };

    dispatch(setTransactionUpdatePrices(newPrices));
  }

  render() {
    const {
      enrollment,
      loading,
      enrollmentTransactions,
      roadMap,
      oldMethodology,
      optionsMethodology,
      match,
      selectedTab,
    } = this.props;

    if (loading) return <Loading />;
    if (!enrollment) return <Failed />;
    if (!roadMap || !roadMap.TrainingPlan) return <Loading />;

    return (
      <Wrapper>
        <Title>Modificar matrícula #{match.params.idEnrollment}</Title>
        <BasicInformation
          data={enrollment}
          methodology={roadMap.TrainingPlan.Methodology.name}
          oldMethodology={oldMethodology ? oldMethodology.tp.Methodology.name : '-'}
        />
        <RoadMap
          data={roadMap}
          checkmodule={this.onCheckModule}
          checkunit={this.onCheckUnit}
          movemodule={this.onMoveModule}
          type={enrollment.type}
        />
        <Transactions
          idEnrollment={enrollment.idEnrollment}
          enrollmentTransactions={enrollmentTransactions}
          totalAmount={enrollment.totalAmount * 1}
          selectedTab={selectedTab}
          onTabChange={this.onTabChange}
          onSend={this.onSend}
          onRemove={this.onRemove}
          getTransactionPdf={this.getTransactionPdf}
          setModule={this.setModule}
          setUnit={this.setUnit}
          setModuleCost={this.setModuleCost}
          setUnitCost={this.setUnitCost}
          setPenalty={this.setPenalty}
          setNewPrices={this.setNewPrices}
          setDiscountCost={this.setDiscountCost}
        />
        <Methodology
          data={enrollment}
          methodology={roadMap.TrainingPlan.Methodology.name}
          oldMethodology={oldMethodology}
          optionsMethodology={optionsMethodology}
          changeMethodology={this.changeMethodology}
        />
      </Wrapper>
    );
  }
}

Enrollments.propTypes = {
  dispatch: PropTypes.func.isRequired,
  enrollment: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  enrollmentTransactions: PropTypes.array,
  roadMap: PropTypes.object,
  oldMethodology: PropTypes.object,
  optionsMethodology: PropTypes.object,
  selectedTab: PropTypes.number,
};

Enrollments.defaultProps = {
  enrollment: {},
  enrollmentTransactions: [],
  roadMap: {},
  oldMethodology: null,
  optionsMethodology: null,
  selectedTab: 0,
};

export default connect(
  state => ({
    loading: state.enrollments.loading,
    enrollment: state.enrollments.enrollment,
    enrollmentTransactions: state.enrollments.enrollmentTransactions,
    roadMap: state.enrollments.roadMap,
    oldMethodology: state.enrollments.oldMethodology,
    optionsMethodology: state.enrollments.optionsMethodology,
    selectedTab: state.enrollments.selectedTab,
  }),
)(Enrollments);
