import dotenv from 'dotenv';
import {
  select,
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';
import api from 'linkia-api';

import { tokenSelector } from '$redux/login/selectors';
import { makeHash } from '$utils/hashing';

import { LOGIN, REFRESH_PERMISSIONS } from './action-types';
import {
  loginSucceeded,
  loginFailed,
  refreshPermissionsSucceeded,
  refreshPermissionsFailed,
} from './actions';

dotenv.config();
const { NODE_ENV: env } = process.env;

function* reqLogin(action) {
  const { email, password } = action;
  try {
    const {
      token,
      staff,
    } = yield call(api.staff.login, { email, password: makeHash(password) });
    yield put(loginSucceeded(token, staff));
  } catch (e) {
    // eslint-disable-next-line no-console
    if (env === 'development' || !e.response || e.response.status !== 401) console.error(e);
    yield put(loginFailed());
  }
}

function* reqPermissions() {
  const token = yield select(tokenSelector);
  try {
    const permissions = yield call(api.staff.me.getPermissions, { token });
    yield put(refreshPermissionsSucceeded(permissions));
  } catch (e) {
    // eslint-disable-next-line no-console
    if (env === 'development' || !e.response || e.response.status !== 401) console.error(e);
    yield put(refreshPermissionsFailed());
  }
}

export default [
  takeEvery(LOGIN, reqLogin),
  takeEvery(REFRESH_PERMISSIONS, reqPermissions),
];
