import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import swal from 'sweetalert2';

import { list as listCharges, remove as removeCharge } from '$redux/charges/actions';

import { Button } from '@material-ui/core';
import Title from 'ui/Title';
import Loading from '$routes/Loading';
import Failed from '$routes/Failed';

import Grid from './Grid';

class Charges extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(listCharges());
  }

  removeCharge = async (id) => {
    const { dispatch } = this.props;

    // ask for confirmation
    const { value } = await swal({
      title: 'Confirmación',
      text: `¿Seguro que quieres borrar el cobro con id #${id}?`,
      type: 'warning',
      confirmButtonText: 'Sí, ¡bórralo!',
      confirmButtonColor: '#e05959',
      showCancelButton: true,
      cancelButtonText: 'No, volver atrás',
    });

    if (!value) return;
    dispatch(removeCharge(id));
  }

  render() {
    const { data, loading } = this.props;

    if (loading) return <Loading />;
    if (!data) return <Failed />;

    return (
      <div>
        <Title>
          Cobros
          <Link to="/cobros/nuevo">
            <Button size="small">Crear nuevo cobro</Button>
          </Link>
        </Title>
        <Grid data={data} loading={loading} removeItem={this.removeCharge} />
      </div>
    );
  }
}

Charges.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.array,
  loading: PropTypes.bool.isRequired,
};

Charges.defaultProps = {
  data: null,
};

export default connect(
  state => ({
    data: state.charges.list,
    loading: state.charges.loading,
  }),
)(Charges);
