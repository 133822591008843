import React from 'react';
import Title from 'ui/Title';
import theme from 'ui/theme';
import { Wrapper } from './styles';
import Grid from './Grid';


class Candidates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {},
    };
  }

  applyFilters = (filtersSelected) => {
    this.setState({ filters: filtersSelected });
  }

  render() {
    const { filters } = this.state;

    return (
      <Wrapper theme={theme}>
        <Title>Candidatos Payment Log</Title>
        <Grid filters={filters} />
      </Wrapper>
    );
  }
}

export default Candidates;
