import {
  REQUEST_FAILED,
  LIST,
  LIST_SUCCEEDED,
  GET,
  GET_SUCCEEDED,
  UPDATE,
  UPDATE_SUCCEEDED,
  REMOVE,
  REMOVE_SUCCEEDED,
  CREATE,
  CREATE_SUCCEEDED,
  SET,
  RESET,
  LIST_BILLING_RULES,
  LIST_BILLING_RULES_SUCCEEDED,
} from './action-types';

export const requestFailed = error => ({ type: REQUEST_FAILED, error });
export const list = () => ({ type: LIST });
export const listSucceeded = data => ({ type: LIST_SUCCEEDED, data });
export const get = (id, selectedSemester) => ({ type: GET, id, selectedSemester });
export const getSucceeded = data => ({ type: GET_SUCCEEDED, data });
export const update = (id, data) => ({ type: UPDATE, id, data });
export const updateSucceeded = success => ({ type: UPDATE_SUCCEEDED, success });
export const remove = id => ({ type: REMOVE, id });
export const removeSucceeded = success => ({ type: REMOVE_SUCCEEDED, success });
export const create = data => ({ type: CREATE, data });
export const createSucceeded = success => ({ type: CREATE_SUCCEEDED, success });
export const set = (key, value) => ({ type: SET, key, value });
export const reset = () => ({ type: RESET });
export const listBillingRules = idAcademy => ({ type: LIST_BILLING_RULES, idAcademy });
export const listBillingRulesSucceeded = () => ({ type: LIST_BILLING_RULES_SUCCEEDED });
