import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Wrapper, PlanHeaderWrapper, PlanRowWrapper, RedButton,
} from './EditModule.styles';

class ModuleReqTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  eraseRequirement = (requirement) => {
    const { data, set } = this.props;
    const { code, name } = requirement;
    let index;
    data.ModuleRequirements.find((requirementScanned, i) => {
      index = i;
      return (requirementScanned.ModuleRequired.code === code
        && requirementScanned.ModuleRequired.name === name);
    });
    if (index !== undefined) {
      const newList = data.ModuleRequirements;
      newList.splice(index, 1);
      set(newList);
    } else {
      throw new Error('No se ha podido eliminar la dependencia');
    }
  }

  render() {
    const { data } = this.props;
    if (data) {
      return (
        data.ModuleRequirements.length > 0
          ? (
            <Wrapper>
              <PlanHeaderWrapper>
                <div className="columns">
                  <div className="column is-one-fifths">
                    Código
                  </div>
                  <div className="column is-three-fifths">
                    Nombre
                  </div>
                </div>
              </PlanHeaderWrapper>
              {data ? data.ModuleRequirements.map(requirement => (
                <PlanRowWrapper key={requirement.ModuleRequired.idModule}>
                  <div className="columns is-vcentered">
                    <div className="column is-one-fifths">
                      {requirement.ModuleRequired.code}
                    </div>
                    <div className="column is-three-fifths">
                      {requirement.ModuleRequired.name}
                    </div>
                    <RedButton style={{ backgroundColor: 'transparent' }} onClick={() => this.eraseRequirement(requirement)}>
                      Eliminar
                    </RedButton>
                  </div>
                </PlanRowWrapper>)) : ''}
            </Wrapper>
          )
          : ''
      );
    }
    return '';
  }
}

ModuleReqTable.propTypes = {
  data: PropTypes.object,
  set: PropTypes.func.isRequired,
};

ModuleReqTable.defaultProps = {
  data: {},
};


export default connect()(ModuleReqTable);
