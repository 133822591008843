import {
  REQUEST_UTM_FORM_FILTERS,
  REQUEST_UTM_FORM_FILTERS_SUCCEEDED,
  REQUEST_UTM_FORM_FILTERS_FAILED,
  REQUEST_UTM_FORM_CREATE_LEAD,
  REQUEST_UTM_FORM_CREATE_LEAD_SUCCEEDED,
  REQUEST_UTM_FORM_CREATE_LEAD_FAILED,

} from './action-types';

export const reqUtmFormFilters = bindingObject => ({
  type: REQUEST_UTM_FORM_FILTERS, bindingObject,
});
export const reqUtmFormFiltersSucceeded = ({
  utmForms: { data, pages },
}, bindingObject) => ({
  type: REQUEST_UTM_FORM_FILTERS_SUCCEEDED,
  data,
  pages,
  bindingObject,
});
export const reqUtmFormFiltersFailed = () => ({
  type: REQUEST_UTM_FORM_FILTERS_FAILED,
});

export const reqUtmFormCreateLead = bindingObject => ({
  type: REQUEST_UTM_FORM_CREATE_LEAD,
  bindingObject,
});
export const reqUtmFormCreateSuccess = success => ({
  type: REQUEST_UTM_FORM_CREATE_LEAD_SUCCEEDED,
  success,
});
export const reqUtmFormCreateFailed = success => ({
  type: REQUEST_UTM_FORM_CREATE_LEAD_FAILED,
  success,
});
