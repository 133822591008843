import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import SubTitle from 'ui/SubTitle';
import moment from 'moment';

import {
  Button,
  FormControlLabel,
  InputLabel,
  Select,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import faTrash from '@fortawesome/fontawesome-free-solid/faTrashAlt';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import swal from 'sweetalert2';
import faPencil from '@fortawesome/fontawesome-free-solid/faPencilAlt';
import { Wrapper } from './Validation.styles';
import {
  deleteEnrollmentJustificanteTransfe,
  updateEnrollmentJustificanteTransfe,
} from '../../../../redux/enrollments/actions';
import encodeFileBase64 from '../../../../utils/encodeFileBase64';

class Validation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  createPaymentSelect(data) {
    const { set } = this.props;

    return (
      <div className="column is-full">
        <div className="columns is-vcentered">
          <div className="column is-2">
            <InputLabel>Pago: </InputLabel>
          </div>
          <div className="column is-2">
            <Select
              fullWidth
              value={data.isPaid ? data.isPaid : 0}
              onChange={e => set('isPaid', e.target.value)}
            >
              <option value={0}>Pendiente de pago</option>
              <option value={1}>Pagado</option>
            </Select>
          </div>
          <div className="column" />
        </div>
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  createInputFile(edit) {
    const handleFileChange = async (event) => {
      event.preventDefault();

      const { data } = this.props;
      const { dispatch } = this.props;
      const file = event.target.files[0];

      if (file) {
        const maxFileSize = 10485760;
        if (file.size <= maxFileSize) {
          encodeFileBase64(file)
            .then(async (result) => {
              const bindingObject = {
                idEnrollment: data.idEnrollment,
                file: result,
              };
              await dispatch(updateEnrollmentJustificanteTransfe(bindingObject));
            })
            .catch((err) => {
              swal('Error', err.toString(), 'error');
            });
        } else {
          await swal('Error', 'El archivo no puede tener más de 10MB', 'error');
        }
      }
    };

    return (
      <label htmlFor="upload-file" className="edit-justificante-centered-label">
        <input
          style={{ display: 'none' }}
          id="upload-file"
          name="upload-file"
          type="file"
          onChange={handleFileChange}
          accept="application/pdf"
        />
        {edit ? (
          <FontAwesomeIcon
            icon={faPencil}
            className="icon-pencil"
          />
        ) : (
          <span className="add-file">
            Añadir Fichero
          </span>
        )}
        <Typography variant="body2" color="textSecondary">
          (pdf, max 10MB)
        </Typography>
      </label>
    );
  }

  render() {
    const {
      states,
      payments,
      data,
      onSend,
      set,
      userCan,
      onGetOrder,
    } = this.props;
    const paymentData = data && !payments.length ? this.createPaymentSelect(data) : null;

    const statesList = states.map(item => (
      <option key={item.idState} value={item.idState}>{item.name}</option>
    ));

    let cancelDateEdit;
    let refundEdit;

    const currentState = states.find(st => st.idState === data.idState);
    if (currentState) {
      if (currentState.code === 'BA' || currentState.code === 'BDP') {
        cancelDateEdit = (
          <Fragment>
            <div className="column is-1">
              <InputLabel>Fecha baja: </InputLabel>
            </div>
            <div className="column is-2">
              <TextField
                type="date"
                fullWidth
                onChange={e => set('cancelDate', e.target.value)}
                defaultValue={data.cancelDate ? `${data.cancelDate.slice(0, 10)}` : new Date().toLocaleDateString()}
              />
            </div>
          </Fragment>
        );
      }
      if (currentState.code === 'BDP') {
        refundEdit = (
          <Fragment>
            <div className="column is-1">
              <InputLabel>Reembolso: </InputLabel>
            </div>
            <div className="column is-2">
              <TextField
                type="number"
                inputProps={{
                  style: {
                    textAlign: 'right',
                    paddingRight: '5px',
                  },
                }}
                onChange={e => set('refundAmount', e.target.value)}
                value={(data.refundAmount * 1).toFixed(2)}
              />
            </div>
          </Fragment>
        );
      }
    }

    let paymentDate;
    if (data.paymentMethod === 'transfer') {
      paymentDate = (
        <div className="columns is-vcentered">
          <div className="column is-2">
            <InputLabel>Fecha de pago (Transferencia): </InputLabel>
          </div>
          <div className="column is-3">
            <TextField
              type="date"
              fullWidth
              onChange={e => set('paymentDate', e.target.value)}
              defaultValue={data.paymentDate ? moment(data.paymentDate, 'DD/MM/YYYY')
                .format('YYYY-MM-DD') : null}
            />
          </div>
        </div>
      );
    }

    const stateOptions = (
      <div className="column is-full">
        <div className="columns is-vcentered">
          <div className="column is-2">
            <InputLabel>Estado: </InputLabel>
          </div>
          <div className="column is-3">
            <Select
              fullWidth
              value={data.idState ? data.idState : 1}
              onChange={e => set('idState', e.target.value)}
            >
              {statesList}
            </Select>
          </div>
          {cancelDateEdit}
          {refundEdit}
        </div>
        {paymentDate}
      </div>
    );


    let validateDiscounts;
    if (data) {
      validateDiscounts = (
        <Fragment>
          <div className="column is-2">
            <InputLabel>Descuentos validados: </InputLabel>
          </div>
          <div className="column is-2">
            <FormControlLabel
              control={<Switch color="secondary" />}
              checked={(data.discountsValidation !== 0 || data.discountsValidation !== false)
                && (data.discountsValidation === 1 || data.discountsValidation === true)}
              onChange={(e) => {
                set('discountsValidation', e.target.checked);
                if (e.target.checked) {
                  set('uploadedFile', !e.target.checked);
                }
              }}
            />
          </div>
        </Fragment>
      );
    }

    let reEnrollment;
    if (data) {
      reEnrollment = (
        <Fragment>
          <div className="column is-2">
            <InputLabel>Rematrícula: </InputLabel>
          </div>
          <div className="column is-2">
            <FormControlLabel
              control={<Switch color="secondary" />}
              checked={data.reenroll}
              onChange={(e) => {
                set('reenroll', e.target.checked);
                if (e.target.checked) {
                  set('uploadedFile', !e.target.checked);
                }
              }}
            />
          </div>
        </Fragment>
      );
    }

    let paymentType;
    let orderId;
    if (data.paymentMethod === 'financiado') {
      paymentType = 'Financiado';
    } else if (data.paymentMethod === 'Aplazame') {
      paymentType = 'Financiado (Aplazame)';
    } else if (data.paymentMethod === 'transfer') {
      paymentType = 'Transferencia';
      paymentDate = (
        <div>
          Fecha de pago:
        </div>
      );
    } else if (data.paymentMethod && data.paymentMethod.includes('stripe')) {
      paymentType = 'Tarjeta';
    } else if (data.paymentMethod === 'pagantis') {
      paymentType = 'Paga + Tarde';
      orderId = (
        <div>
          Orden id: <Button onClick={onGetOrder}>{data.orderId}</Button>
        </div>
      );
    }

    const deleteJustificanteTransfe = async (e) => {
      e.preventDefault();
      if (data.idEnrollment) {
        const { value } = await swal({
          title: 'Confirmación',
          text: '¿Seguro que quieres borrar el justificante de transferencia?',
          type: 'warning',
          confirmButtonText: 'Sí, ¡bórralo!',
          confirmButtonColor: '#e05959',
          showCancelButton: true,
          cancelButtonText: 'No, volver atrás',
        });

        if (!value) return;
        const { dispatch } = this.props;
        await dispatch(deleteEnrollmentJustificanteTransfe(data.idEnrollment));
      }
    };

    const paymentMethod = (
      <div className="column is-full">
        <div className="columns is-vcentered">
          <div className="column is-2">
            <InputLabel>Método de pago: </InputLabel>
          </div>
          <div className="column is-2">
            {paymentType}
          </div>
          <div className="column">
            {orderId}
          </div>
        </div>
        <div className="columns is-vcentered">
          <div className="column is-2"><InputLabel>Justificante: </InputLabel></div>
          {data.paymentDocument
            ? (<>
              <div className="column is-2">
                <a
                  href={data.paymentDocument}
                  target="_blank"
                  rel="noopener noreferrer"
                >Ver
                </a>
              </div>
              <div className="column is-3">
                <div className="row">
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="icon-trash"
                    onClick={deleteJustificanteTransfe}
                  />&nbsp;&nbsp;
                  {this.createInputFile(true)}
                </div>
              </div>
            </>) : (<div className="column is-2">{this.createInputFile(false)}</div>)}
        </div>
      </div>
    );

    return (
      <Wrapper>
        <SubTitle>Validación de la matrícula</SubTitle>
        <div className="columns is-multiline is-vcentered">
          {userCan('ENROLLMENT_PAYMENTS') ? paymentMethod : null}
          {userCan('ENROLLMENT_PAYMENTS') ? paymentData : null}
          {stateOptions}
          <div className="column is-full">
            <div className="columns is-vcentered">
              {userCan('ENROLLMENT_DISCOUNT_VALIDATION') ? validateDiscounts : null}
              {reEnrollment}
            </div>
          </div>
          <div className="column is-two-fifths">
            <Button color="primary" onClick={onSend}>Actualizar</Button>
          </div>
        </div>
      </Wrapper>
    );
  }
}

Validation.propTypes = {
  data: PropTypes.object,
  states: PropTypes.array,
  payments: PropTypes.array,
  set: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  userCan: PropTypes.func.isRequired,
  onGetOrder: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

Validation.defaultProps = {
  data: {},
  states: [],
  payments: [],
};

export default connect()(Validation);
