import {
  UPDATE,
  UPDATE_SUCCEEDED,
  UPDATE_FAILED,
  ACTIVE,
  ACTIVE_SUCCEEDED,
  ACTIVE_FAILED,
  LIST_MODULESEMESTER,
  LIST_MODULESEMESTER_SUCCEEDED,
  OBLIGATORIO,
  OBLIGATORIO_SUCCEEDED,
  OBLIGATORIO_FAILED,
} from './action-types';

export const update = data => ({ type: UPDATE, data });
export const updateSucceeded = success => ({ type: UPDATE_SUCCEEDED, success });
export const updateFailed = error => ({ type: UPDATE_FAILED, error });

export const active = data => ({ type: ACTIVE, data });
export const activeSucceeded = success => ({ type: ACTIVE_SUCCEEDED, success });
export const activeFailed = error => ({ type: ACTIVE_FAILED, error });

export const obligatorio = data => ({ type: OBLIGATORIO, data });
export const obligatorioSucceeded = success => ({ type: OBLIGATORIO_SUCCEEDED, success });
export const obligatorioFailed = error => ({ type: OBLIGATORIO_FAILED, error });

export const listModuleSemester = () => ({ type: LIST_MODULESEMESTER });
export const listSucceeded = data => ({ type: LIST_MODULESEMESTER_SUCCEEDED, data });
