import React from 'react';
import Spinner from 'react-spinkit';

import { Wrapper } from './Loading.styles';

const Loading = () => (
  <Wrapper>
    <Spinner name="line-scale" fadeIn="none" color="#000" />
  </Wrapper>
);

export default Loading;
