import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Title from 'ui/Title';
import swal from 'sweetalert2';
import Select from '@material-ui/core/Select';
import { Button } from '@material-ui/core';
import theme from 'ui/theme';
import { Wrapper } from './styles';
import Filters from './Filters';
import Grid from './Grid';
import { requestUtmExport, requestUtmExportWithEnroll } from '$redux/utms/actions';

class Utms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {},
      exportType: 'simple',
    };
  }

  applyFilters = (filtersSelected) => {
    this.setState({
      filters: filtersSelected,
    });
  }

  handleChange = () => (event) => {
    event.preventDefault();
    this.setState({
      exportType: event.target.value,
    });
  };

  exportData = async () => {
    const { dispatch } = this.props;
    const { exportType, filters } = this.state;

    const { value } = await swal({
      title: 'Exportación Matrícula',
      html: 'Si continua se generara un fichero con la exportación y se le enviará al correo en la máxima brevedad posible.',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#6896c8',
      confirmButtonText: 'Generar',
      cancelButtonColor: '#ee0000',
      cancelButtonText: 'Cancelar',
    });

    if (value) {
      switch (exportType) {
        case 'simple':
          dispatch(requestUtmExport(filters));
          break;
        case 'enroll':
          dispatch(requestUtmExportWithEnroll(filters));
          break;
        default:
          break;
      }
    }
  }

  render() {
    const { filters, exportType, loading } = this.state;

    return (
      <Wrapper theme={theme}>
        <Title>UTMs
          <div>
            <Link to="/utm-forms">
              <Button size="small">Ver solo formularios</Button>
            </Link>
          </div>
        </Title>
        { loading }
        <Filters
          applyFilters={this.applyFilters}
        />
        <Grid
          filters={filters}
        />
        <div className="column export-button">
          <Select
            native
            className="filter-item"
            onChange={this.handleChange()}
            value={exportType}
          >
            <option value="simple">Simple</option>
            <option value="enroll">Con Matrículas</option>
          </Select>
          <Button onClick={() => this.exportData()}>
            EXPORTAR
          </Button>
        </div>
      </Wrapper>
    );
  }
}

Utms.propTypes = {
};

Utms.defaultProps = {
};

export default connect(
  state => ({
    loading: state.utms.loading,
    result: state.utms.result,
  }),
)(Utms);
