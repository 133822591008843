import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import swal from 'sweetalert2';

import ReactTable from 'ui/ReactTable';
import 'react-table/react-table.css';

import theme, { colors } from 'ui/theme';
import grey from '@material-ui/core/colors/grey';
import Spinner from 'react-spinkit';
import {
  Button,
  Checkbox,
  withStyles,
  Menu,
  MenuItem,
} from '@material-ui/core';

import { canSelector, hasAcademy } from '$redux/login/selectors';
import {
  request as requestEnrollments,
  alexiaReset,
  requestEnrollmentExport,
  requestEnrollmentGradeExport,
  requestGetAlexiaLog as getAlexiaLog,
} from '$redux/enrollments/actions';

import Failed from '$routes/Failed';

import { Wrapper, SmallBall, SpinnerWrapper } from './styles';
import { WarningValue, LinkiaUpdatedValue, AcademyUpdatedValue } from './Grid.helpers';

const ALEXIA_UNTRIED = 0;
const ALEXIA_DONE = 1;
const ALEXIA_ERROR = 2;

const StyledCheckbox = withStyles({
  root: {
    height: 'auto',
    width: 'auto',
    transform: 'translateY(-2px)',
    padding: 0,

    // custom color
    color: `${grey[500]} !important`,
  },
})(props => (
  <Checkbox {...props} />
));

const confirmationDialog = async ({ title, message, type = 'question' }) => swal({
  title,
  html: message,
  type,
  showCancelButton: true,
  confirmButtonColor: colors.primary,
  confirmButtonText: 'Adelante',
  cancelButtonColor: colors.disabled,
  cancelButtonText: 'Cancelar',
});


class Grid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      sorted: [],
      pageSize: 10,
    };
  }

  componentDidUpdate(prevProps) {
    const { filters } = this.props;
    const { pageSize } = this.state;

    // Typical usage (don't forget to compare props):
    if (filters !== prevProps.filters) {
      const { dispatch } = this.props;
      const bindingObject = {
        pageSize,
        page: 0,
        sorted: [],
        filters,
      };
      dispatch(requestEnrollments(bindingObject, 'enrollments'));
    }
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  exportData = async (type) => {
    const { filters, dispatch } = this.props;
    const { sorted } = this.state;
    const bindingObject = {
      type,
      filters,
      sorted,
    };
    dispatch(requestEnrollmentExport(bindingObject));
    this.setState({ anchorEl: null });
  }

  exportDataStudentGrade = async () => {
    const { filters, dispatch } = this.props;
    if (!filters.idSemester) {
      alert('Tiene que estar filtrado por semestre');
    } else {
      const bindingObject = {
        idSemester: filters.idSemester,
      };
      dispatch(requestEnrollmentGradeExport(bindingObject));
      this.setState({ anchorEl: null });
    }
  }

  fetchData = (state) => {
    let { filters } = this.props;
    const { dispatch, filtersApplied } = this.props;
    filters = {
      ...filtersApplied,
      ...filters,
    };
    const { pageSize, page, sorted } = state;
    const bindingObject = {
      pageSize,
      page,
      sorted,
      filters,
    };
    dispatch(requestEnrollments(bindingObject, 'enrollments'));
    this.setState({ pageSize });
    this.setState({ sorted });
  }

  handleClickOnAlexiaBall = async (id, event) => {
    event.preventDefault();
    const { dispatch } = this.props;
    if (event.type === 'contextmenu') {
      dispatch(getAlexiaLog(id));
    } else {
      const { value: confirmed } = await confirmationDialog({
        title: `¿Resetear integración con Alexia de matrícula #${id}?`,
        message: `Esto limpiará el estado de integración con Alexia en nuestra base de datos
para esta matrícula, por lo que después <strong>podrás volver a intentar la integración</strong>.`,
      });

      if (!confirmed) return;

      dispatch(alexiaReset(id));
    }
  }

  render() {
    const {
      loading, pages, data, alexiaResetLoadingId,
    } = this.props;
    const { anchorEl, pageSize } = this.state;

    if (!data) return <Failed />;

    let exportButton;
    if (data.length > 0) {
      exportButton = (
        <div className="column align-right">

          <Button
            aria-owns={anchorEl ? 'simple-menu' : undefined}
            aria-haspopup="true"
            onClick={this.handleClick}
          >
            EXPORTAR...
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
          >
            <MenuItem
              key="current"
              onClick={() => this.exportData('current')}
            >
              Vista actual
            </MenuItem>
            <MenuItem
              key="shipping"
              onClick={() => this.exportData('shipping')}
            >
              Envío de materiales
            </MenuItem>
            <MenuItem
              key="full"
              onClick={() => this.exportData('full')}
            >
              Todo
            </MenuItem>
            <MenuItem
              key="full"
              onClick={() => this.exportDataStudentGrade()}
            >
              Grados
            </MenuItem>
          </Menu>
        </div>
      );
    }

    const {
      userCan, selections, setSelection, isAcademy,
    } = this.props;

    return (
      <Wrapper theme={theme}>
        <ReactTable
          topBorder
          columns={[
            ...userCan('ENROLLMENT_ALEXIA') ? (
              [{
                Header: 'Integr.',
                accessor: 'integratedIntoAlexia',
                Cell: ({
                  original: { idEnrollment: id, integratedIntoAlexia: integrated, paymentDate },
                }) => {
                  const selected = !!selections.find(s => s === id);
                  const isIntegrable = !!paymentDate;

                  // is loading
                  if (alexiaResetLoadingId === id) {
                    return (
                      <SpinnerWrapper>
                        <div>
                          <Spinner size="small" name="circle" fadeIn="none" color="#777" />
                        </div>
                      </SpinnerWrapper>
                    );
                  // cannot be integrated
                  } if (!isIntegrable) {
                    return (
                      <SmallBall
                        disabled
                        color="#ccc" // grey
                      />
                    );

                    // can be integrated but still didn't
                  } if (integrated === ALEXIA_UNTRIED) {
                    return (
                      <StyledCheckbox
                        checked={selected}
                        onChange={() => setSelection(id, !selected)}
                      />
                    );

                    // tried to integrate, but gave alexia error
                  } if (integrated === ALEXIA_ERROR) {
                    return (
                      <SmallBall
                        color="#dcda71" // yellow
                        onContextMenu={event => this.handleClickOnAlexiaBall(id, event)}
                        onClick={event => this.handleClickOnAlexiaBall(id, event)}
                      />
                    );

                    // was successfully integrated
                  } if (integrated === ALEXIA_DONE) {
                    return (
                      <SmallBall
                        color="#83c5f5" // blue
                        onClick={event => this.handleClickOnAlexiaBall(id, event)}
                      />
                    );
                  }

                  // unknown state
                  return null;
                },
                width: 70,
              }]
            ) : [],
            {
              Header: 'Última actualización',
              accessor: 'updatedAt',
              Cell: (row) => {
                const {
                  value, original: { spoiledDeadline, linkiaValidation, academyValidation },
                } = row;
                if (spoiledDeadline) return <WarningValue>{value}</WarningValue>;
                if (linkiaValidation && !isAcademy) {
                  return <LinkiaUpdatedValue>{value}</LinkiaUpdatedValue>;
                }
                if (academyValidation) return <AcademyUpdatedValue>{value}</AcademyUpdatedValue>;
                return value;
              },
              width: 120,
            },
            {
              Header: 'Pedido',
              accessor: 'idEnrollment',
              width: 80,
            },
            {
              Header: 'Centro',
              accessor: 'academyName',
            },
            // {
            //   Header: 'Email',
            //   accessor: 'email',
            // },
            {
              Header: 'Nombre',
              accessor: 'studentName',
            },
            // {
            //   Header: 'Nif',
            //   accessor: 'idDocumentNumber',
            // },
            {
              Header: 'Formación',
              accessor: 'courseCode',
            },
            {
              Header: 'Estado',
              accessor: 'state',
            },
            {
              Header: 'Fecha de pago',
              accessor: 'paymentDate',
              Cell: ({ value }) => (value || '-'),
              maxWidth: 80,
            },
            {
              Header: 'Tipo',
              accessor: 'reenroll',
              Cell: (row) => {
                const {
                  original: { reenroll, idEnrollmentParent },
                } = row;
                return (reenroll === 1 ? 'Rematrícula' : 'Nueva') + (!idEnrollmentParent ? ' ' : '(Generada)');
              },
              maxWidth: 80,
            },
            {
              Header: '',
              fixed: 'right',
              Cell: rowClicked => (
                <Link to={`matriculas/editar/${rowClicked.original.idEnrollment}`}><Button>Ver</Button></Link>
              ),
              resizable: false,
              sortable: false,
              width: 50,
            },
          ]}
          manual
          defaultPageSize={pageSize}
          data={data}
          pages={pages} // Display the total number of pages
          loading={loading} // Display the loading overlay when we need it
          onFetchData={this.fetchData} // Request new data when things change
        />
        {exportButton}
      </Wrapper>
    );
  }
}

Grid.propTypes = {
  dispatch: PropTypes.func.isRequired,
  userCan: PropTypes.func.isRequired,
  filters: PropTypes.object,
  data: PropTypes.array,
  pages: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  selections: PropTypes.array.isRequired,
  setSelection: PropTypes.func.isRequired,
  filtersApplied: PropTypes.object,
  alexiaResetLoadingId: PropTypes.number,
  isAcademy: PropTypes.bool.isRequired,
};

Grid.defaultProps = {
  filters: {},
  data: [],
  pages: 0,
  filtersApplied: {},
  alexiaResetLoadingId: null,
};

export default connect(
  state => ({
    userCan: canSelector(state),
    isAcademy: hasAcademy(state),
    data: state.enrollments.enrollments,
    pages: state.enrollments.pages,
    loading: state.enrollments.loading,
    filtersApplied: state.enrollments.filters,
    alexiaResetLoadingId: state.enrollments.alexiaResetLoadingId,
    log: state.enrollments.log,
  }),
)(Grid);
