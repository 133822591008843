import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import theme from 'ui/theme';
import {
  Button,
} from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import themeFilters from 'ui/muiFiltersTheme';
import { canSelector } from '$redux/login/selectors';
import { filtersRequestHold, cleanFiltersRequest } from '$redux/enrollments/actions';
import Failed from '$routes/Failed';
import { Wrapper } from './Filters.styles';

class Filters extends React.Component {
  constructor(props) {
    super(props);
    const {
      idSemester,
    } = props.filtersApplied;
    this.state = {
      idSemester,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(filtersRequestHold());
  }

  onApplyFilters = (e) => {
    e.preventDefault();
    const { applyFilters } = this.props;

    const {
      idSemester,
    } = this.state;
    applyFilters({
      idSemester,
    });
  };

  resetFilters = async (e) => {
    e.preventDefault();
    const { dispatch } = this.props;
    dispatch(cleanFiltersRequest());
    dispatch(filtersRequestHold());
  }

  handleChange = name => (event) => {
    this.setState({ [name]: event.target.value });
  };

  render() {
    const {
      filtersData,
    } = this.props;

    const {
      idSemester,
    } = this.state;

    if (!filtersData) return <Failed />;

    const semestersList = filtersData.semesters.map(
      item => (
        <option key={item.idSemester} value={item.idSemester}>{item.name} {item.courseYear}</option>
      ),
    );

    return (
      <Wrapper theme={theme}>
        <MuiThemeProvider theme={themeFilters}>
          <form onSubmit={this.onApplyFilters} className="filters-form">
            <div className="filters">
              <Select
                native
                onChange={this.handleChange('idSemester')}
                className="filter-item semester"
                defaultValue={idSemester}
              >
                <option value={0}>Semestre</option>
                {semestersList}
              </Select>
              <Button
                color="primary"
                type="submit"
                className="apply-filters-button"
              >
                APLICAR FILTROS
              </Button>
              <Button
                color="secondary"
                onClick={this.resetFilters}
                className="reset-filters-button"
              >
                LIMPIAR FILTROS
              </Button>
            </div>
          </form>
        </MuiThemeProvider>
      </Wrapper>
    );
  }
}

Filters.propTypes = {
  dispatch: PropTypes.func.isRequired,
  applyFilters: PropTypes.func.isRequired,
  filtersData: PropTypes.object,
  filtersApplied: PropTypes.object,
};

Filters.defaultProps = {
  filtersData: undefined,
  filtersApplied: {},
};

export default connect(
  state => ({
    userCan: canSelector(state),
    filtersData: state.enrollments.filtersData,
    filtersApplied: state.enrollments.filters,
  }),
)(Filters);
