import {
  REQUEST_ORDER_RECOVERY_SEARCH,
  REQUEST_ORDER_RECOVERY_SEARCH_SUCCEEDED,
  REQUEST_ORDER_RECOVERY_SEARCH_FAILED,
  REQUEST_ORDER_RECOVERY_RECOVER,
  REQUEST_ORDER_RECOVERY_RECOVER_SUCCEEDED,
  REQUEST_ORDER_RECOVERY_RECOVER_FAILED,
} from './action-types';
import utils from '../utils';

const initialState = {
  data: [],
  pages: 0,
  loading: false,
};

const orderRecoveryReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case REQUEST_ORDER_RECOVERY_SEARCH:
    case REQUEST_ORDER_RECOVERY_RECOVER:
      return {
        ...state,
        loading: true,
      };
    case REQUEST_ORDER_RECOVERY_SEARCH_SUCCEEDED: {
      const { data, pages } = action;
      return {
        ...state,
        data,
        pages,
        loading: false,
      };
    }
    case REQUEST_ORDER_RECOVERY_SEARCH_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error inesperado',
        message: 'No se ha podido recuperar el listado de matriculas pendientes',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case REQUEST_ORDER_RECOVERY_RECOVER_SUCCEEDED: {
      utils.dialog({
        type: 'succes',
        title: 'Todo ha ido bien',
        message: 'La matrícula se ha recuperado correctamente',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case REQUEST_ORDER_RECOVERY_RECOVER_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error inesperado',
        message: 'La matrícula no se ha podido generar',
      });
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default orderRecoveryReducer;
