import React from 'react';
import { connect } from 'react-redux';

import Title from 'ui/Title';
import theme from 'ui/theme';

import { Wrapper } from './styles';
import Filters from './Filters';
import Grid from './Grid';


class EnrollmentsWithServices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {},
    };
  }

  applyFilters = (filtersSelected) => {
    this.setState({ filters: filtersSelected });
  }

  render() {
    const { filters } = this.state;

    return (
      <Wrapper theme={theme}>
        <Title>
          Consulta Servicios
        </Title>
        <Filters applyFilters={this.applyFilters} />
        <Grid filters={filters} />
      </Wrapper>
    );
  }
}

EnrollmentsWithServices.propTypes = {};

export default connect()(EnrollmentsWithServices);
