import React from 'react';
import PropTypes from 'prop-types';
import { Typography, TextField } from '@material-ui/core';
import { Wrapper } from './styles';
import SubTitle from '../../../../ui/SubTitle';

class BankInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    let documentNumber;
    const {
      data, disabled, set,
    } = this.props;

    if (data) {
      documentNumber = (
        <div className="column">
          <div className="columns is-vcentered">
            <div className="column is-one-fifth">
              <Typography>IBAN</Typography>
            </div>
            <div className="column is-three-fifths">
              <TextField
                fullWidth
                disabled={disabled}
                onChange={e => set('bankInfo', 'ibanNumber', e.target.value)}
                value={data.ibanNumber ? data.ibanNumber : ''}
              />
            </div>
          </div>
        </div>
      );
    }

    const { title } = this.props;

    return (
      <Wrapper>
        <div className="student-data">
          <SubTitle>{title}</SubTitle>
          <div className="columns is-multiline is-narrow content">
            {documentNumber}
          </div>
        </div>
      </Wrapper>);
  }
}

BankInfo.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object,
  disabled: PropTypes.bool,
  set: PropTypes.func.isRequired,
};

BankInfo.defaultProps = {
  data: undefined,
  disabled: false,
};

export default (BankInfo);
