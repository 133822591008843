import {
  GET,
  GET_SUCCEEDED,
  GET_FAILED,
  UPDATE_MAIL,
  UPDATE_MAIL_SUCCEEDED,
  UPDATE_MAIL_FAILED,
  SET,
} from './action-types';

export const get = () => ({ type: GET });
export const getSucceeded = mails => ({ type: GET_SUCCEEDED, mails });
export const getFailed = () => ({ type: GET_FAILED });

export const updateMails = mail => ({ type: UPDATE_MAIL, mail });
export const updateMailsSucceeded = mails => (
  { type: UPDATE_MAIL_SUCCEEDED, mails }
);
export const updateMailsFailed = () => ({ type: UPDATE_MAIL_FAILED });
export const setMail = (idMail, field, newText) => ({
  type: SET, idMail, field, newText,
});
