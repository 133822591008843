import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const Wrapper = styled.div`
  .educational-level-select {
    margin-top: 15px;
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex !important;
    font-size: 14px;
  }

  .modal-main {
    position:fixed;
    background: white;
    width: 80%;
    height: auto;
    max-height: 90%;
    overflow-y: scroll;

    .close-cross {
      float: right;
      margin-right: 8px;
      margin-top: 8px;
      cursor: pointer;
    }

    .modal-form {
      padding-left: 50px;
      padding-right: 50px;
      .modal-form-title {
        font-weight: 300;
      }
    }
  }

  .unit-list-edition {
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .unit-row-container {
    border-top: solid 1px #E9E9E9;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .unit-cell {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      height: 40px;
  }

  .unit-cell:hover {
      overflow: visible;
      white-space: normal;
      /* height: auto; */
  }

  .unit-name:hover {
      display: block;
      background-color: white;
  }

  .save-button {
    margin-top: 20px;
    margin-bottom: 20px;
    color:"primary";
  }
`;

export const PlanHeaderWrapper = styled.div`
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 500;
  margin-top: 20px;
`;

export const PlanRowWrapper = styled.div`
  padding: 2px 0;
  padding-top: 8px;
  padding-bottom: 8px;
  border-top: 1px solid #ccc;
  font-size: 15px;
`;

export const RedButton = styled(Button)`
  color: #e05959 !important;
  background-color: transparent;
`;
