import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { list as listAcademies } from '$redux/academies/actions';

import { Button } from '@material-ui/core';
import Title from 'ui/Title';
import Loading from '$routes/Loading';
import Failed from '$routes/Failed';

import Grid from './Grid';

class Academies extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(listAcademies());
  }

  render() {
    const { data, loading } = this.props;

    if (loading) return <Loading />;
    if (!data) return <Failed />;

    return (
      <div>
        <Title>
          Centros
          <Link to="/centros/nuevo">
            <Button size="small">Crear nuevo centro</Button>
          </Link>
        </Title>
        <Grid data={data} loading={loading} />
      </div>
    );
  }
}

Academies.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.array,
  loading: PropTypes.bool.isRequired,
};

Academies.defaultProps = {
  data: null,
};

export default connect(
  state => ({
    data: state.academies.list,
    loading: state.academies.loading,
  }),
)(Academies);
