export const REQUEST_FAILED = 'COURSE_CATEGORIES_REQUEST_FAILED';
export const LIST = 'COURSE_CATEGORIES_LIST';
export const LIST_SUCCEEDED = 'COURSE_CATEGORIES_LIST_SUCCEEDED';
export const GET = 'COURSE_CATEGORY_GET';
export const GET_SUCCEEDED = 'COURSE_CATEGORY_GET_SUCCEEDED';
export const UPDATE = 'COURSE_CATEGORY_UPDATE';
export const UPDATE_SUCCEEDED = 'COURSE_CATEGORY_UPDATE_SUCCEEDED';
export const REMOVE = 'COURSE_CATEGORY_REMOVE';
export const REMOVE_SUCCEEDED = 'COURSE_CATEGORY_REMOVE_SUCCEEDED';
export const CREATE = 'COURSE_CATEGORY_CREATE';
export const CREATE_SUCCEEDED = 'COURSE_CATEGORY_CREATE_SUCCEEDED';
export const SET = 'COURSE_CATEGORY_SET';
export const RESET = 'COURSE_CATEGORY_RESET';
