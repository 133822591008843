import styled from 'styled-components';
import theme from 'ui/theme';

export const Wrapper = styled.div`
  border-top: 1px solid #e7e7e7;
  padding: 25px 0;
  font-size: 14px;

  .new-tag {
    font-weight: 400;
    font-size: 10px;
    color: #f00;
    margin-right: 7px;
  }

  .message-line {
    display: flex;
    justify-content: space-between;

    /* show only the first line */
    .the-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    > button {
      border: none;
      background: none;
      text-transform: uppercase;
      cursor: pointer;
      color: ${theme.primary.main};
    }
  }

  &.is-new {
    font-weight: 700;
  }
`;
