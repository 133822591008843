export const StatusEnum = {
  WAITING: 0,
  FAIL: 1,
  OK: 2,
  RUNNING: 3,
  WAITING_FOR_CONFIRMATION: 4,
};

export const getStatusLabel = (value) => {
  switch (value) {
    case StatusEnum.WAITING:
    case StatusEnum.FAIL:
      return 'FAIL';
    case StatusEnum.OK:
      return 'OK';
    case StatusEnum.RUNNING:
      return 'RUNNING';
    case StatusEnum.WAITING_FOR_CONFIRMATION:
      return 'WAITING FOR CONFIRMATION';
    default:
      return 'WAITING';
  }
};
