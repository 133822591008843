import utils from '../utils';

import {
  ENROLLMENT_SPLIT_PAYMENT_CONFIRM_EXPORTAR_REQUEST,
  ENROLLMENT_SPLIT_PAYMENT_CONFIRM_EXPORTAR_REQUEST_FAILED,
  ENROLLMENT_SPLIT_PAYMENT_CONFIRM_EXPORTAR_REQUEST_SUCCEEDED,
} from './action-types';

const initialState = {};

const enrollmentSplitPaymentConfirmsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ENROLLMENT_SPLIT_PAYMENT_CONFIRM_EXPORTAR_REQUEST: {
      return {
        ...state,
      };
    }
    case ENROLLMENT_SPLIT_PAYMENT_CONFIRM_EXPORTAR_REQUEST_SUCCEEDED: {
      const { data } = action;
      const encodedUri = escape(data);
      const link = document.createElement('a');
      const file = 'exportar-facturacion-stripe.csv';
      link.setAttribute('href', `data:text/csv;charset=utf-8,%EF%BB%BF${encodedUri}`);
      link.setAttribute('download', file);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return {
        ...state,
      };
    }
    case ENROLLMENT_SPLIT_PAYMENT_CONFIRM_EXPORTAR_REQUEST_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error inesperado',
        message: 'No se ha podido descargar el documento',
      });
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

export default enrollmentSplitPaymentConfirmsReducer;
