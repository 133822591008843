import React from 'react';
import PropTypes from 'prop-types';

import {
  TextField,
} from '@material-ui/core';

// eslint-disable-next-line
class BasicForm extends React.Component {
  render() {
    const {
      data: { voucher },
      set,
    } = this.props;

    return (
      <div>
        <div className="columns is-multiline">
          <div className="column is-one-quarter">
            <TextField
              label="Código promocional"
              fullWidth
              value={voucher}
              onChange={e => set('voucher', e.target.value)}
            />
          </div>
        </div>
      </div>
    );
  }
}

BasicForm.propTypes = {
  data: PropTypes.object.isRequired,
  set: PropTypes.func.isRequired,
};

export default BasicForm;
