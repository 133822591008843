import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import swal from 'sweetalert2';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';

import PaymentIcon from '@material-ui/icons/Payment';
import MailIcon from '@material-ui/icons/Mail';
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';
import UpdateIcon from '@material-ui/icons/Update';

import {
  paymentMarkAsPending,
  paymentCharge,
  paymentMarkAsPaid,
  paymentUpdateDeadline,
  paymentSendMail,
} from '$redux/enrollments/actions';

import { colors } from 'ui/theme';

import { Wrapper } from './Timeline.styles';

import Dot from './Dot';
import Stretch from './Stretch';


const dateFormat = 'DD/MM/YYYY';

const confirmationDialog = async ({ title, message }) => swal({
  title,
  html: message,
  showCancelButton: true,
  confirmButtonColor: colors.primary,
  confirmButtonText: 'Adelante',
  cancelButtonColor: colors.disabled,
  cancelButtonText: 'Cancelar',
});

class Timeline extends React.Component {
  state = {
    anchorEl: null,
    selectedPayment: {},
  }

  handleDropMenu = (event, payment) => {
    this.setState({
      anchorEl: event.currentTarget,
      selectedPayment: payment,
    });
  }

  handleDropMenuClose = () => this.setState({ anchorEl: null })

  handlePaymentAction = async (payment, action) => {
    this.handleDropMenuClose();

    const { dispatch } = this.props;
    const {
      idEnrollmentSplitPayment: id,
      termNumber: term,
      deadlineDate,
      splitCost: cost,
    } = payment;

    switch (action) {
      case 'mark-as-pending': {
        const { value: confirmed } = await confirmationDialog({
          title: `Actualizar plazo #${term}`,
          message: '¿Seguro que desea marcar este plazo <strong>como pendiente</strong>?',
        });
        if (!confirmed) return;
        dispatch(paymentMarkAsPending(id));
        break;
      }
      case 'charge': {
        const { value: confirmed } = await confirmationDialog({
          title: `Cobrar plazo #${term} ahora`,
          message: `¿Seguro que desea cobrar <strong>${cost}€</strong> ahora mismo?`,
        });
        if (!confirmed) return;
        dispatch(paymentCharge(id));
        break;
      }
      case 'mail': {
        const { value: confirmed } = await confirmationDialog({
          title: `Enviar mail de pago #${term} ahora`,
          message: `¿Seguro que desea enviar la sugerencia de cobro de <strong>${cost}€</strong> ahora?`,
        });
        if (!confirmed) return;
        dispatch(paymentSendMail(id));
        break;
      }
      case 'mark-as-paid': {
        const { value: confirmed } = await confirmationDialog({
          title: `Actualizar plazo #${term}`,
          message: '¿Seguro que desea marcar este plazo <strong>como pagado</strong>?',
        });
        if (!confirmed) return;
        dispatch(paymentMarkAsPaid(id));
        break;
      }
      case 'update-deadline': {
        const { value: newDateString } = await swal({
          title: `Cambiar vencimiento plazo #${term}`,
          input: 'text',
          inputValue: moment(deadlineDate).format(dateFormat),
          showCancelButton: true,
          confirmButtonColor: colors.primary,
          confirmButtonText: 'Cambiar ahora',
          cancelButtonColor: colors.disabled,
          cancelButtonText: 'Cancelar',
          inputValidator: (date) => {
            if (!moment(date, dateFormat, true).isValid()) {
              return 'El formato de fecha es incorrecto';
            }
            const diff = moment(date, dateFormat).diff(moment(), 'days');
            if (diff < 0) {
              return 'El vencimiento no puede ser anterior a hoy';
            }
            return false;
          },
        });

        if (!newDateString) return;
        const newDate = moment(newDateString, dateFormat);

        dispatch(paymentUpdateDeadline(id, newDate));
        break;
      }
      default:
        console.error(`Unknown menu action: '${action}'`); // eslint-disable-line
    }
  }

  render() {
    const { payments } = this.props;
    const { anchorEl, selectedPayment } = this.state;

    let normalIsReached = false;

    return (
      <Wrapper>
        {payments
          .sort(({ termNumber: termA }, { termNumber: termB }) => termA - termB)
          .map((payment, index, { length }) => {
            const {
              loading,
              idEnrollmentSplitPayment: id,
              isPaid,
              deadlineDate,
              paymentDate,
              splitCost: cost,
            } = payment;

            const diffDays = moment(deadlineDate).diff(moment(), 'days');

            let type = 'disabled';
            let date = new Date();

            if (!normalIsReached || isPaid) {
              if (isPaid) {
                type = 'success';
                date = new Date(paymentDate);
              } else if (diffDays > 0) {
                normalIsReached = true;
                type = 'normal';
                date = new Date(deadlineDate);
              } else if (diffDays === 0) {
                type = 'warning';
                date = new Date(deadlineDate);
              } else if (diffDays < 0) {
                type = 'error';
                date = new Date(deadlineDate);
              }
            }

            return (
              <React.Fragment key={id}>
                <Dot
                  loading={loading}
                  type={type}
                  date={date}
                  cost={parseInt(cost, 10)}
                  onClick={e => this.handleDropMenu(e, payment)}
                />
                {index < length - 1 ? <Stretch type={type} /> : null}
              </React.Fragment>
            );
          })
        }

        {(() => {
          const {
            isPaid,
            deadlineDate,
            splitCost: cost,
            termNumber: term,
          } = selectedPayment;

          return (
            <Menu
              key="menu"
              anchorEl={anchorEl}
              open={!!anchorEl}
              onClose={this.handleDropMenuClose}
            >
              {
                isPaid ? [
                  <MenuItem
                    key="mark-as-pending"
                    onClick={() => this.handlePaymentAction(selectedPayment, 'mark-as-pending')}
                  >
                    <ListItemIcon><WarningIcon /></ListItemIcon>
                    <Typography><strong>Marcar plazo #{term} como pendiente</strong></Typography>
                  </MenuItem>,
                ] : [
                  <MenuItem
                    key="charge"
                    onClick={() => this.handlePaymentAction(selectedPayment, 'charge')}
                  >
                    <ListItemIcon><PaymentIcon /></ListItemIcon>
                    <Typography><strong>Cobrar plazo #{term} ({cost}€) ahora</strong></Typography>
                  </MenuItem>,
                  <MenuItem
                    key="mail"
                    onClick={() => this.handlePaymentAction(selectedPayment, 'mail')}
                  >
                    <ListItemIcon><MailIcon /></ListItemIcon>
                    <Typography>
                      Enviar mail de pago #{term} ({cost}€)
                    </Typography>
                  </MenuItem>,
                  <MenuItem
                    key="mark-as-paid"
                    onClick={() => this.handlePaymentAction(selectedPayment, 'mark-as-paid')}
                  >
                    <ListItemIcon><CheckIcon /></ListItemIcon>
                    <Typography>Marcar como pagado</Typography>
                  </MenuItem>,
                  <MenuItem
                    key="update-deadline"
                    onClick={() => this.handlePaymentAction(selectedPayment, 'update-deadline')}
                  >
                    <ListItemIcon><UpdateIcon /></ListItemIcon>
                    <Typography>
                      Cambiar vencimiento: {moment(deadlineDate).format(dateFormat)}
                    </Typography>
                  </MenuItem>,
                ]
              }
            </Menu>
          );
        })()}
      </Wrapper>
    );
  }
}

Timeline.propTypes = {
  dispatch: PropTypes.func.isRequired,
  payments: PropTypes.array,
};

Timeline.defaultProps = {
  payments: [],
};

export default connect()(Timeline);
