import React from 'react';
import SubTitle from 'ui/SubTitle';
import theme from 'ui/theme';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Wrapper } from './styles';
import Upload from './upload';
import Loading from '$routes/Loading';
import { getRegulations, showDocument } from '$redux/regulations/actions';

class Regulations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    this.loadRegulations();
  }

  loadRegulations = () => {
    const { dispatch } = this.props;
    dispatch(getRegulations());
  }

  handleClick = (target) => {
    const { dispatch } = this.props;
    dispatch(showDocument(target));
  }

  render() {
    const {
      loading,
      economic,
      academic,
      endpoint,
      token,
    } = this.props;
    if (loading) return <Loading />;
    return (
      <Wrapper theme={theme}>
        <SubTitle>Normativas</SubTitle>
        <Upload
          fieldName="E"
          target="economic"
          endpoint={endpoint}
          token={token}
          value={economic}
          label="Económica"
          reload={this.loadRegulations}
          onClick={this.handleClick}
        />
        <Upload
          fieldName="A"
          target="academic"
          endpoint={endpoint}
          token={token}
          value={academic}
          label="Académica"
          reload={this.loadRegulations}
          onClick={this.handleClick}
        />
      </Wrapper>
    );
  }
}

Regulations.propTypes = {
  dispatch: PropTypes.func.isRequired,
  economic: PropTypes.string.isRequired,
  academic: PropTypes.string.isRequired,
  token: PropTypes.string,
  endpoint: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

Regulations.defaultProps = {
  token: '',
};

export default connect(
  state => ({
    economic: state.regulations.economic,
    academic: state.regulations.academic,
    token: state.regulations.token,
    endpoint: state.regulations.endpoint,
    loading: state.regulations.loading,
  }),
)(Regulations);
