import {
  REQUEST_FAILED,
  LIST,
  LIST_SUCCEEDED,
  REMOVE,
  REMOVE_SUCCEEDED,
  CREATE,
  CREATE_SUCCEEDED,
} from './action-types';

export const requestFailed = error => ({ type: REQUEST_FAILED, error });
export const list = () => ({ type: LIST });
export const listSucceeded = (data, stats) => ({ type: LIST_SUCCEEDED, data, stats });
export const remove = id => ({ type: REMOVE, id });
export const removeSucceeded = success => ({ type: REMOVE_SUCCEEDED, success });
export const create = data => ({ type: CREATE, data });
export const createSucceeded = success => ({ type: CREATE_SUCCEEDED, success });
