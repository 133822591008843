import {
  select, call, put, takeEvery,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import api from 'linkia-api';

import {
  REQUEST_GROUP_CREATE,
  REQUEST_GROUP_LIST_CICLES,
  REQUEST_GROUP_FILTERS,
  REQUEST_GROUP_GET,
  REQUEST_GROUP_MASSIVE_OPTIONS,
  REQUEST_GROUP_UPDATE_ENROLLMENT_TO_GROUP,
  REQUEST_GROUP_GET_CICLE_ENROLLMENT_BY_GROUP,
  REQUEST_GROUP_GET_ALL_COURSES_BY_SEMESTER_AND_COURSE,
  REQUEST_GROUP_UPDATE_COURSES_BY_SEMESTER_AND_COURSE,
  REQUEST_GROUP_REMOVE_CICLES,
} from './action-types';

import {
  reqGroupListCiclesSucceeded,
  reqGroupListCiclesFailed,
  reqGroupFiltersSucceeded,
  reqGroupFiltersFailed,
  reqGroupMassiveOptionsSucceeded,
  reqGroupMassiveOptionsFailed,
  reqGroupCreateSucceeded,
  reqGroupCreateFailed,
  reqGroupGetSucceeded,
  reqGroupGetFailed,
  reqGroupMoveEnrollmentToGroupUpdateSuccess,
  reqGroupMoveEnrollmentToGroupUpdateFailed,
  reqGroupGetCicleEnrollmentsByGroupSucceeded,
  reqGroupGetCicleEnrollmentsByGroupFailed,
  reqGroupGetAllCoursesBySemesterAndCourseSucceeded,
  reqGroupGetAllCoursesBySemesterAndCourseFailed,
  reqGroupUpdateCoursesBySemesterAndCourseSucceeded,
  reqGroupUpdateCoursesBySemesterAndCourseFailed,
  reqGroupRemoveCiclesSucceeded,
  reqGroupRemoveCiclesFailed,
} from './actions';


import { tokenSelector } from '../login/selectors';

function* actionFilters() {
  const token = yield select(tokenSelector);
  try {
    const filtersData = yield call(api.group.getFilters, { token });
    yield put(reqGroupFiltersSucceeded(filtersData));
  } catch (e) {
    yield put(reqGroupFiltersFailed());
  }
}
function* actionGroupListCicles(action) {
  const token = yield select(tokenSelector);
  const {
    bindingObject,
  } = action;
  try {
    const data = yield call(api.group.getAllFiltered, {
      bindingObject, token,
    });
    yield put(reqGroupListCiclesSucceeded(data));
  } catch (e) {
    yield put(reqGroupListCiclesFailed());
  }
}

function* actionFiltersAll() {
  const token = yield select(tokenSelector);
  try {
    const filtersData = yield call(api.group.getGroupFilters, { token });
    yield put(reqGroupMassiveOptionsSucceeded(filtersData));
  } catch (e) {
    yield put(reqGroupMassiveOptionsFailed());
  }
}

function* actionGroupGet(action) {
  const token = yield select(tokenSelector);
  try {
    const { idCicle } = action;
    const data = yield call(api.group.getGroup, {
      token,
      idCicle,
    });
    yield put(reqGroupGetSucceeded(data));
  } catch (e) {
    yield put(reqGroupGetFailed());
  }
}

function* actionGroupGetCicleEnrollmentsByGroup(action) {
  const token = yield select(tokenSelector);
  try {
    const { idCicleGroup } = action;
    const data = yield call(api.group.getCicleEnrollmentsByGroup, {
      token,
      idCicleGroup,
    });
    yield put(reqGroupGetCicleEnrollmentsByGroupSucceeded(data));
  } catch (e) {
    yield put(reqGroupGetCicleEnrollmentsByGroupFailed());
  }
}

function* actionGroupGetAllCoursesBySemesterAndCourse(action) {
  const token = yield select(tokenSelector);
  try {
    const { idCourse, idSemester } = action;
    const data = yield call(api.group.getAllCoursesBySemesterAndCourse, {
      token,
      idCourse,
      idSemester,
    });
    yield put(reqGroupGetAllCoursesBySemesterAndCourseSucceeded(data));
  } catch (e) {
    yield put(reqGroupGetAllCoursesBySemesterAndCourseFailed());
  }
}

function* actionGroupUpdateCoursesBySemesterAndCourse(action) {
  const token = yield select(tokenSelector);
  try {
    const { bindingObject } = action;
    const data = yield call(api.group.updateAllCoursesBySemesterAndCourse, {
      token,
      bindingObject,
    });
    yield put(reqGroupUpdateCoursesBySemesterAndCourseSucceeded(data));
  } catch (e) {
    yield put(reqGroupUpdateCoursesBySemesterAndCourseFailed());
  }
}

function* actionGroupRemoveCicles(action) {
  const token = yield select(tokenSelector);
  try {
    const { bindingObject } = action;
    const data = yield call(api.group.removeCicles, {
      token,
      bindingObject,
    });
    yield put(reqGroupRemoveCiclesSucceeded(data));
  } catch (e) {
    yield put(reqGroupRemoveCiclesFailed());
  }
}

function* actionCreateGroup(action) {
  const token = yield select(tokenSelector);
  const { data } = action;
  try {
    const success = yield call(api.group.create, { token, data });
    yield put(reqGroupCreateSucceeded(success));
    yield put(push(`/groups/editar/${success.idCicle}`));
  } catch (e) {
    yield put(reqGroupCreateFailed(e));
  }
}

function* actionGroupMoveEnrollmentToGroupUpdate(action) {
  const token = yield select(tokenSelector);
  const { data } = action;
  try {
    const success = yield call(api.group.moveEnrollmentToGroup, {
      token,
      data,
    });
    yield put(reqGroupMoveEnrollmentToGroupUpdateSuccess(success));
  } catch (e) {
    yield put(reqGroupMoveEnrollmentToGroupUpdateFailed(e));
  }
}

export default [
  takeEvery(REQUEST_GROUP_CREATE, actionCreateGroup),
  takeEvery(REQUEST_GROUP_LIST_CICLES, actionGroupListCicles),
  takeEvery(REQUEST_GROUP_FILTERS, actionFilters),
  takeEvery(REQUEST_GROUP_MASSIVE_OPTIONS, actionFiltersAll),
  takeEvery(REQUEST_GROUP_GET, actionGroupGet),
  takeEvery(
    REQUEST_GROUP_GET_CICLE_ENROLLMENT_BY_GROUP,
    actionGroupGetCicleEnrollmentsByGroup,
  ),
  takeEvery(
    REQUEST_GROUP_UPDATE_ENROLLMENT_TO_GROUP,
    actionGroupMoveEnrollmentToGroupUpdate,
  ),
  takeEvery(
    REQUEST_GROUP_GET_ALL_COURSES_BY_SEMESTER_AND_COURSE,
    actionGroupGetAllCoursesBySemesterAndCourse,
  ),
  takeEvery(
    REQUEST_GROUP_UPDATE_COURSES_BY_SEMESTER_AND_COURSE,
    actionGroupUpdateCoursesBySemesterAndCourse,
  ),
  takeEvery(REQUEST_GROUP_REMOVE_CICLES, actionGroupRemoveCicles),
];
