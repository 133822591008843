import {
  REGULATIONS_REQUEST,
  REGULATIONS_REQUEST_SUCCEEDED,
  REGULATIONS_REQUEST_FAILED,
  REGULATIONS_SHOW_REQUEST,
  REGULATIONS_SHOW_REQUEST_SUCCEEDED,
  REGULATIONS_SHOW_REQUEST_FAILED,
} from './action-types';

export const getRegulations = data => ({ type: REGULATIONS_REQUEST, data });
export const getRegulationsSucceeded = result => (
  { type: REGULATIONS_REQUEST_SUCCEEDED, result }
);
export const getRegulationsFailed = error => ({ type: REGULATIONS_REQUEST_FAILED, error });

export const showDocument = document => ({ type: REGULATIONS_SHOW_REQUEST, document });
export const showDocumentSucceeded = url => (
  { type: REGULATIONS_SHOW_REQUEST_SUCCEEDED, url }
);
export const showDocumentFailed = error => ({ type: REGULATIONS_SHOW_REQUEST_FAILED, error });
