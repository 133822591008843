import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  get as getAcademy,
  set as setAcademy,
  update as updateAcademy,
} from '$redux/academies/actions';

import Title from 'ui/Title';
import Loading from '$routes/Loading';
import Failed from '$routes/Failed';

import Form from '../Form';

class Academies extends React.Component {
  componentDidMount() {
    const { dispatch, match: { params: { id } } } = this.props;
    dispatch(getAcademy(id));
  }

  onUpdate = () => {
    const { dispatch, data } = this.props;
    dispatch(updateAcademy(data.idAcademy, data));
  }

  set = (key, value) => {
    const { dispatch } = this.props;
    dispatch(setAcademy(key, value));
    if (key === 'selectedSemester') {
      const { match: { params: { id } } } = this.props;
      dispatch(getAcademy(id, value));
    }
  }

  render() {
    const {
      data, loading, semesters, match: { params: { id } },
    } = this.props;

    if (loading) return <Loading />;
    if (!data) return <Failed />;

    return (
      <div>
        <Title>Editar centro #{id}</Title>
        <Form
          action="edit"
          data={data}
          semesters={semesters}
          set={this.set}
          onSend={this.onUpdate}
        />
      </div>
    );
  }
}

Academies.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  semesters: PropTypes.array,
};

Academies.defaultProps = {
  data: null,
  semesters: [],
};

export default connect(
  state => ({
    data: state.academies.single,
    loading: state.academies.loading,
    semesters: state.academies.single.semesters,
  }),
)(Academies);
