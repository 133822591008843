import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Table, TableBody, TableCell, TableHead, TableRow,
} from '@material-ui/core';
import { Wrapper } from './RoadMapTab.styles';
import { connect } from 'react-redux';
import ModuleItem from './ModuleItem';
import Module from '../EditModule';
import Unit from '../EditUnit';
import RoadmapEditDialog from './RoadmapEditDialog';
import {
  active as activeRoadMapModule,
  obligatorio as obligatorioRoadMapModule,
} from '$redux/road-maps-module/actions';

import { get as getRoadMap } from '$redux/road-maps/actions';

class RoadMapTab extends React.Component {
  state = {
    showModal: false,
    moduleToShow: undefined,
    showUnitModal: false,
    unitToShow: undefined,
  };

  hideModuleEdit = () => {
    const { reload } = this.props;
    reload();
    this.setState({
      showModal: false,
      moduleToShow: undefined,
      showModalRoadmap: false,
    });
  };

  showModal = ({ idModule }) => {
    this.setState({
      showModal: true,
      moduleToShow: idModule,
    });
  };

  showUnitModal = ({ idUnit }) => () => {
    this.setState({
      showUnitModal: true,
      unitToShow: idUnit,
    });
  };

  hideUnitEdit = () => {
    const { reload } = this.props;
    reload();
    this.setState({
      showUnitModal: false,
      unitToShow: undefined,
    });
  };

  showUnitModal = ({ idUnit }) => () => {
    this.setState({
      showUnitModal: true,
      unitToShow: idUnit,
    });
  };

  hideRoadmapEditDialog = () => {
    this.setState({
      showModalRoadmap: false,
    });
  };

  showRoadmapEditDialog = (idRoadMap) => {
    const { dispatch } = this.props;
    dispatch(getRoadMap(idRoadMap));
    this.setState({
      showModalRoadmap: true,
    });
  };

  onClickDisabled = ({
    idRoadMapModule,
    isActive,
  }) => {
    const {
      dispatch,
      reload,
    } = this.props;
    dispatch(activeRoadMapModule({
      idRoadMapModule,
      active: isActive,
    }));

    reload();
  };

  onObligatorioClicked = ({
    idRoadMapModule,
    isObligatorio,
  }) => {
    const {
      dispatch,
      reload,
    } = this.props;
    dispatch(obligatorioRoadMapModule({
      idRoadMapModule,
      obligatorio: isObligatorio,
    }));

    reload();
  };

  render() {
    let moduleEdition;
    let unitEdition;
    const {
      roadMap: {
        modules,
        idRoadMap,
      },
      reload,
      methodology,
      semesters,
      updateSemester,
      onClickDelete,
      dispatch,
      single,
      loading,
    } = this.props;

    const {
      showModalRoadmap,
    } = this.state;

    const modulesList = modules.map(row => (
      <ModuleItem
        key={row.idModule}
        module={row}
        onClickUnit={this.showUnitModal}
        onClickModule={this.showModal}
        onClickDelete={onClickDelete}
        onClickDisabled={this.onClickDisabled}
        idRoadMap={idRoadMap}
        semesters={semesters}
        updateSemester={updateSemester}
        onObligatorioClicked={this.onObligatorioClicked}
      />
    ));
    const {
      showModal,
      moduleToShow,
    } = this.state;
    if (showModal) {
      moduleEdition = (
        <Module
          moduleToShow={moduleToShow}
          showModal={showModal}
          hideModuleEdit={this.hideModuleEdit}
          reload={reload}
          methodology={methodology}
        />
      );
    }

    const {
      showUnitModal,
      unitToShow,
    } = this.state;
    if (showUnitModal) {
      unitEdition = (
        <Unit
          unitToShow={unitToShow}
          showUnitModal={showUnitModal}
          hideUnitEdit={this.hideUnitEdit}
          reload={reload}
          methodology={methodology}
        />
      );
    }
    return (
      <Wrapper>
        <Table className="table-modules">
          <TableHead>
            <TableRow>
              <TableCell className="cell-module column-code" align="center"><strong>CÓDIGO</strong></TableCell>
              <TableCell className="cell-module" align="center"><strong>NOMBRE</strong></TableCell>
              <TableCell
                className="cell-module column-semesters"
                align="center"
              ><strong>SEMESTRE</strong>
              </TableCell>
              <TableCell className="cell-module column-button" align="center" />
              <TableCell className="cell-module column-button" align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            {modulesList}
          </TableBody>
        </Table>
        <br />
        <Button color="primary" onClick={() => this.showRoadmapEditDialog(idRoadMap)}>Editar
          Itinerario
        </Button>
        {showModalRoadmap
          ? (
            <RoadmapEditDialog
              close={this.hideRoadmapEditDialog}
              idRoadMap={idRoadMap}
              roadMap={single}
              dispatch={dispatch}
              loading={loading}
            />
          )
          : ''
        }
        {moduleEdition}
        {unitEdition}
      </Wrapper>
    );
  }
}

RoadMapTab.propTypes = {
  dispatch: PropTypes.func.isRequired,
  roadMap: PropTypes.object,
  reload: PropTypes.func.isRequired,
  methodology: PropTypes.object,
  semesters: PropTypes.array.isRequired,
  updateSemester: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  single: PropTypes.object,
  loading: PropTypes.bool,
};

RoadMapTab.defaultProps = {
  roadMap: {},
  methodology: {},
  single: null,
  loading: false,
};

export default connect(
  state => ({
    loading: state.roadmaps.loading,
    single: state.roadmaps.single,
  }),
)(RoadMapTab);
