import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Title from 'ui/Title';
import theme from 'ui/theme';

import swal from 'sweetalert2';

import { Button } from '@material-ui/core';
import { resumen } from '../../utils/resumen';
import Filters from './Filters';

import Grid from './Grid';
import { requestModulePrices, setModificado, requestModulePricesConfirm } from '$redux/modules/actions';
import { Wrapper } from './styles';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {},
      page: 0,
      pageSize: 10,
      sorted: [],
    };
  }

  applyFilters = (filtersSelected) => {
    this.setState({ filters: filtersSelected });
  }

  applyPagination = (bindingObject) => {
    this.setState({
      page: bindingObject.page,
      pageSize: bindingObject.pageSize,
      sorted: bindingObject.sorted,
    });
  }

  modificarPrecioModulos = ({
    idModule,
    precio,
    precioBase,
    originalPrecio,
    originalPrecioBase,
    tipoPrecio,
    codigo,
  }) => {
    const { dispatch } = this.props;
    // type
    dispatch(setModificado({
      idModule,
      precio,
      precioBase,
      originalPrecio,
      originalPrecioBase,
      tipoPrecio,
      codigo,
    }));
  }

  confirmar = () => {
    const {
      modificados,
      dispatch,
    } = this.props;

    const {
      filters,
      page,
      pageSize,
      sorted,
    } = this.state;

    if (!modificados.length) {
      return false;
    }

    swal({
      type: 'question',
      title: '¿Esta seguro de que quiere actualizar estos modulos?',
      html: resumen(modificados),
      showCancelButton: true,
      confirmButtonText: 'Confirmar cambios',
      textCancelButton: 'No, quiero revisarlo',
    }).then((result) => {
      if (!result.dismiss) {
        const prices = [];
        modificados.forEach((m) => {
          prices.push({
            idModule: m.idModule,
            price: m.precio,
            basePrice: m.precioBase,
          });
        });
        dispatch(requestModulePricesConfirm(prices));
        const bindingObject = {
          pageSize,
          page,
          sorted,
          filters,
        };
        dispatch(requestModulePrices(bindingObject));
      }
    });

    return true;
  }

  render() {
    const {
      filters,
    } = this.state;

    const {
      modificados,
    } = this.props;

    return (
      <Wrapper theme={theme}>
        <Title>Cambio masivo de precios
          <Button color="primary" className="button-update" type="submit" onClick={() => this.confirmar()} disabled={modificados.length === 0}>
            { modificados.length > 0 ? `Actualizar (${modificados.length})` : 'Actualizar Precios' }
          </Button>
        </Title>
        <Filters applyFilters={this.applyFilters} />
        <Grid
          filters={filters}
          modificarPrecioModulos={this.modificarPrecioModulos}
          applyPagination={this.applyPagination}
        />
      </Wrapper>
    );
  }
}

Index.propTypes = {
  dispatch: PropTypes.func.isRequired,
  modificados: PropTypes.array.isRequired,
};

Index.defaultProps = {
};

export default connect(
  state => ({
    modificados: state.modules.modificados,
  }),
)(Index);
