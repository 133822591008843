import {
  select, call, put, takeEvery,
} from 'redux-saga/effects';
import api from 'linkia-api';

import {
  LIST,
  REMOVE,
  CREATE,
} from './action-types';
import {
  requestFailed,
  listSucceeded,
  list,
  removeSucceeded,
  createSucceeded,
} from './actions';

import { tokenSelector } from '../login/selectors';

function* listInfoChannels() {
  const token = yield select(tokenSelector);
  try {
    const data = yield call(api.infoChannel.getAll);
    const stats = yield call(api.infoChannel.getStats, { token });
    yield put(listSucceeded(data, stats));
  } catch (e) {
    yield put(requestFailed(e));
  }
}


function* removeInfoChannel(action) {
  const token = yield select(tokenSelector);
  const { id: idInfoChannel } = action;
  try {
    const success = yield call(api.infoChannel.delete, { token, idInfoChannel });
    yield put(removeSucceeded(success));
    yield put(list());
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* createInfoChannel(action) {
  const token = yield select(tokenSelector);
  const { data: name } = action;
  try {
    const success = yield call(api.infoChannel.create, { token, name });
    yield put(createSucceeded(success));
    yield put(list());
  } catch (e) {
    yield put(requestFailed(e));
  }
}

export default [
  takeEvery(LIST, listInfoChannels),
  takeEvery(REMOVE, removeInfoChannel),
  takeEvery(CREATE, createInfoChannel),
];
