import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';

import {
  TextField,
  FormControlLabel,
  Switch,
  Button,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import DateRangePicker from 'ui/DateRangePicker';

import { Wrapper } from './Form.styles';


moment.locale('es');

class Form extends React.Component {
  state = {
    dates: {
      focusedInput: null,
    },
  }

  setDates = ({ startDate, endDate }) => {
    const { set } = this.props;
    set('startDate', startDate);
    set('endDate', endDate);
  }

  setType = (type) => {
    const { set } = this.props;
    set('type', type);
    if (type !== 'H') set('hours', 0);
  }

  render() {
    const {
      action,
      data: {
        name,
        code,
        type,
        value,
        startDate,
        endDate,
        hours,
        order,
        isCumulative,
        active,
        selectedTrainingPlan,
        reenroll,
      },
      ui: {
        trainingPlans,
      },
      set,
      onSend,
    } = this.props;
    const { dates } = this.state;

    let reenrollControl;
    if (type === 'H' || type === 'P') {
      reenrollControl = (
        <div className="column is-one-quarter-fullhd is-two-quarters-desktop check-field">
          <FormControlLabel
            control={<Switch color="primary" />}
            label="Rematrícula"
            checked={reenroll}
            onChange={e => set('reenroll', e.target.checked)}
          />
        </div>
      );
    }
    return (
      <Wrapper>
        <div className="columns is-multiline">
          <div className="column is-one-quarter">
            <TextField
              label="Nombre"
              fullWidth
              value={name}
              onChange={e => set('name', e.target.value)}
            />
          </div>
          <div className="column is-one-quarter">
            <TextField
              label="Código"
              fullWidth
              value={code}
              onChange={e => set('code', e.target.value)}
            />
          </div>
          <div className="column is-one-quarter">
            <FormControl fullWidth>
              <InputLabel>Tipo</InputLabel>
              <Select
                value={type}
                onChange={e => this.setType(e.target.value)}
              >
                <MenuItem value="F">Fijo</MenuItem>
                <MenuItem value="S">Pago único</MenuItem>
                <MenuItem value="H">Horas</MenuItem>
                <MenuItem value="P">Periodo</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="column is-one-quarter">
            <TextField
              label="Valor"
              fullWidth
              type="number"
              InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
              value={value}
              onChange={e => set('value', e.target.value)}
            />
          </div>
          <div className="column is-two-quarters">
            <DateRangePicker
              label="Rango de fechas"
              startDate={startDate ? moment(startDate) : null}
              startDateId="start_date"
              endDate={endDate ? moment(endDate) : null}
              endDateId="end_date"
              onDatesChange={newDates => this.setDates(newDates)}
              focusedInput={dates.focusedInput}
              onFocusChange={focusedInput => this.setState({ dates: { focusedInput } })}
            />
          </div>
          <div className="column is-one-quarter">
            <div className="columns">
              <div className="column is-half">
                <TextField
                  label="Horas"
                  fullWidth
                  type="number"
                  InputProps={{ endAdornment: <InputAdornment position="end">h</InputAdornment> }}
                  value={hours}
                  onChange={e => set('hours', e.target.value)}
                  disabled={type !== 'H'}
                />
              </div>
              <div className="column is-half">
                <TextField
                  label="Orden"
                  fullWidth
                  type="number"
                  value={order}
                  onChange={e => set('order', e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="column is-one-quarter-fullhd is-two-quarters-desktop check-fields">
            <FormControlLabel
              control={<Switch color="secondary" />}
              label="Acumulativo"
              checked={isCumulative}
              onChange={e => set('isCumulative', e.target.checked)}
            />
            <FormControlLabel
              control={<Switch color="primary" />}
              label="Activo"
              checked={active}
              onChange={e => set('active', e.target.checked)}
            />
          </div>
        </div>
        <div className="columns">
          <div className="column is-half">
            <FormControl fullWidth>
              <InputLabel>Curso con metodología</InputLabel>
              <Select
                value={selectedTrainingPlan || 'none'}
                onChange={e => set('selectedTrainingPlan', e.target.value)}
              >
                <MenuItem value="none">Cualquiera</MenuItem>
                {trainingPlans.map(({
                  idTrainingPlan,
                  courseCode,
                  courseName,
                  methodologyName,
                }) => (
                  <MenuItem key={idTrainingPlan} value={idTrainingPlan}>
                    {courseCode} - {courseName} - {methodologyName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {reenrollControl}
        </div>
        <div className="form-actions">
          {
            action === 'edit' ? (
              <Button color="primary" onClick={onSend}>Actualizar</Button>
            ) : (
              <Button color="secondary" onClick={onSend}>Crear</Button>
            )
          }
          <Link to="/descuentos"><Button>Volver</Button></Link>
        </div>
      </Wrapper>
    );
  }
}

Form.propTypes = {
  action: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  set: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
};

export default Form;
