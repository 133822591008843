import {
  select, call, put, takeEvery,
} from 'redux-saga/effects';
import api from 'linkia-api';
import {
  REQUEST,
  FILTERS_REQUEST,
  UPDATE_USER,
  CHANGE_LEAD_PASSWORD,
  REQUEST_PAYMENT_LOG,
} from './action-types';
import {
  requestSucceeded,
  requestFailed,
  filtersRequestSucceeded,
  filtersRequestFailed,
  changeLeadSucceeded,
  changeLeadFailed,
  requestPaymentLogSucceeded,
  requestPaymentLogFailed,
} from './actions';

import { tokenSelector } from '../login/selectors';

function* reqCandidates(action) {
  const token = yield select(tokenSelector);
  const {
    bindingObject,
  } = action;
  try {
    const data = yield call(api.candidate.getAllFiltered, {
      bindingObject, token,
    });
    yield put(requestSucceeded(data));
  } catch (e) {
    yield put(requestFailed());
  }
}

function* reqCandidatePaymentLogs(action) {
  const token = yield select(tokenSelector);
  const {
    bindingObject,
  } = action;
  try {
    const data = yield call(api.candidate.paymentLogList, {
      bindingObject, token,
    });
    yield put(requestPaymentLogSucceeded(data));
  } catch (e) {
    yield put(requestPaymentLogFailed());
  }
}

function* reqFilters() {
  const token = yield select(tokenSelector);
  try {
    const filtersData = yield call(api.candidate.getFilters, { token });
    yield put(filtersRequestSucceeded(filtersData));
  } catch (e) {
    yield put(filtersRequestFailed());
  }
}

function* updateUser(action) {
  const token = yield select(tokenSelector);
  const {
    idUser, skipHourLimits, bindingObject, forceCatOldTp,
  } = action;
  try {
    yield call(api.user.update, {
      idUser, skipHourLimits, token, forceCatOldTp,
    });
    const data = yield call(api.candidate.getAllFiltered, {
      bindingObject, token,
    });
    yield put(requestSucceeded(data));
  } catch (e) {
    // No throw error
  }
}

function* generarNuevaContraseña(action) {
  const token = yield select(tokenSelector);
  const { email, value } = action;
  try {
    const { success } = yield call(api.candidate.recoverPassword, { email, value, token });
    if (success) {
      yield put(changeLeadSucceeded());
    } else {
      yield put(changeLeadFailed());
    }
  } catch (e) {
    yield put(changeLeadFailed());
  }
}

export default [
  takeEvery(REQUEST, reqCandidates),
  takeEvery(REQUEST_PAYMENT_LOG, reqCandidatePaymentLogs),
  takeEvery(FILTERS_REQUEST, reqFilters),
  takeEvery(UPDATE_USER, updateUser),
  takeEvery(CHANGE_LEAD_PASSWORD, generarNuevaContraseña),
];
