import styled from 'styled-components';

export const Wrapper = styled.div`
  button {
    margin-top: 15px;
    border-radius: 0 !important;
    font-weight: 300;
  }

  textarea {
    min-height: 100px;
  }
`;
