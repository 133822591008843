import {
  select, all, call, put, takeEvery,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import api from 'linkia-api';

import {
  LIST,
  BEFORE_CREATE,
  GET,
  UPDATE,
  REMOVE,
  CREATE,
} from './action-types';
import {
  requestFailed,
  listSucceeded,
  beforeCreateSucceeded,
  getSucceeded,
  updateSucceeded,
  get,
  removeSucceeded,
  createSucceeded,
} from './actions';

import { tokenSelector } from '../login/selectors';

function* listDiscounts() {
  const token = yield select(tokenSelector);
  try {
    const data = yield call(api.discount.listAll, { token });
    yield put(listSucceeded(data));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* beforeCreateDiscount() {
  const token = yield select(tokenSelector);
  try {
    const trainingPlans = yield call(api.trainingPlan.listOptions, { token });
    yield put(beforeCreateSucceeded({ trainingPlans }));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* getDiscount(action) {
  const token = yield select(tokenSelector);
  const { id } = action;
  try {
    const [data, trainingPlans] = yield all([
      call(api.discount.get, { token, id }),
      call(api.trainingPlan.listOptions, { token }),
    ]);
    yield put(getSucceeded(data, { trainingPlans }));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* updateDiscount(action) {
  const token = yield select(tokenSelector);
  const { id, data } = action;
  try {
    const success = yield call(api.discount.update, { token, id, data });
    yield put(updateSucceeded(success));
    yield put(get(id));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* removeDiscount(action) {
  const token = yield select(tokenSelector);
  const { id } = action;
  try {
    const success = yield call(api.discount.get, { token, id });
    yield put(removeSucceeded(success));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* createDiscount(action) {
  const token = yield select(tokenSelector);
  const { data } = action;
  try {
    const success = yield call(api.discount.create, { token, data });
    yield put(createSucceeded(success));
    yield put(push(`/descuentos/editar/${success.idDiscount}`));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

export default [
  takeEvery(LIST, listDiscounts),
  takeEvery(BEFORE_CREATE, beforeCreateDiscount),
  takeEvery(GET, getDiscount),
  takeEvery(UPDATE, updateDiscount),
  takeEvery(REMOVE, removeDiscount),
  takeEvery(CREATE, createDiscount),
];
