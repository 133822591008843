import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import theme from 'ui/theme';
import moment from 'moment';
import { Button } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import DateRangePicker from 'ui/DateRangePicker';
import themeFilters from 'ui/muiFiltersTheme';
import Failed from '$routes/Failed';
import Loading from '$routes/Loading';
import { Wrapper } from './Filters.styles';

moment.locale('es');

class Filters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: undefined,
      endDate: undefined,
      dates: {
        enrollmentFocusedInput: null,
        courseFocusedInput: null,
      },
    };
  }

  onApplyFilters = (e) => {
    e.preventDefault();
    const { startDate, endDate } = this.state;
    const { applyFilters, filtersData } = this.props;
    applyFilters({ idSemester: filtersData.selectedSemester, startDate, endDate });
  };


  setEnrollmentDates = ({ startDate, endDate }) => {
    this.setState({ startDate, endDate });
  }

  handleChange = () => (event) => {
    event.preventDefault();
    const { set, filtersData } = this.props;
    const idSemester = event.target.value;
    set('filtersData', { ...filtersData, selectedSemester: idSemester });
  };

  render() {
    const { filtersData, loading } = this.props;
    const { startDate, endDate, dates } = this.state;

    if (loading) return <Loading />;
    if (!filtersData) return <Failed />;

    const semestersList = filtersData.semesters.map(
      item => (
        <option key={item.idSemester} value={item.idSemester}>{item.name.concat(' ', item.courseYear)}</option>
      ),
    );
    return (
      <Wrapper theme={theme}>
        <MuiThemeProvider theme={themeFilters}>
          <form onSubmit={this.onApplyFilters} className="filters-form">
            <div className="filters">
              <Select
                native
                value={filtersData.selectedSemester}
                onChange={this.handleChange()}
                className="filter-item"
              >
                {semestersList}
              </Select>
              <DateRangePicker
                border={false}
                startDate={startDate ? moment(startDate) : null}
                startDateId="start_date"
                endDate={endDate ? moment(endDate) : null}
                endDateId="end_date"
                onDatesChange={newDates => this.setEnrollmentDates(newDates)}
                focusedInput={dates.enrollmentFocusedInput}
                onFocusChange={enrollmentFocusedInput => (
                  this.setState({ dates: { enrollmentFocusedInput } })
                )}
              />
              <Button
                color="primary"
                type="submit"
                className="button"
              >
                APLICAR FILTROS
              </Button>
            </div>
          </form>
        </MuiThemeProvider>
      </Wrapper>
    );
  }
}

Filters.propTypes = {
  applyFilters: PropTypes.func.isRequired,
  filtersData: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  set: PropTypes.func.isRequired,
};

Filters.defaultProps = {
  filtersData: undefined,
};

export default connect(
  state => ({
    loading: state.billing.loading,
  }),
)(Filters);
