import dotenv from 'dotenv';
import {
  select, call, put, takeEvery,
} from 'redux-saga/effects';
import api from 'linkia-api';

import {
  FILTERS_REQUEST,
  ROADMAPS_REQUEST,
  FILTERED_REQUEST,
  INFO_REQUEST,
  UPDATE_REQUEST,
  CLEAN_FILTERS_REQUEST,
  ENROLLMENT_DOWNLOAD_REQUEST,
  REQUEST_ACAD_EXPORT,
} from './action-types';
import {
  filtersRequestSucceeded,
  filtersRequestFailed,
  roadMapsRequestSucceeded,
  roadMapsRequestFailed,
  filteredRequestSucceeded,
  filteredRequestFailed,
  informationRequestSucceeded,
  informationRequestFailed,
  updateRequestSucceeded,
  updateRequestFailed,
  downloadEnrollmentSucceeded,
  requestExportAcademyInformedSucceeded,
  requestExportAcademyInformedFailed,
} from './actions';

import { tokenSelector } from '../login/selectors';

dotenv.config();
const { NODE_ENV: env } = process.env;

function* reqFilters() {
  const token = yield select(tokenSelector);
  try {
    const filtersData = yield call(api.student.getFilters, { token });
    yield put(filtersRequestSucceeded(filtersData));
  } catch (e) {
    // eslint-disable-next-line no-console
    if (env === 'development' || !e.response || e.response.status !== 401) console.error(e);
    yield put(filtersRequestFailed());
  }
}

function* reqRoadMaps(action) {
  const { idTrainingPlan } = action;
  const token = yield select(tokenSelector);
  try {
    const roadMapsData = yield call(api.student.getRoadMaps, { idTrainingPlan, token });
    yield put(roadMapsRequestSucceeded(roadMapsData));
  } catch (e) {
    // eslint-disable-next-line no-console
    if (env === 'development' || !e.response || e.response.status !== 401) console.error(e);
    yield put(roadMapsRequestFailed());
  }
}

function* reqCleanFilters() {
  const token = yield select(tokenSelector);
  const bindingObject = {
    pageSize: 10,
    page: 0,
    sorted: [],
    filters: {},
  };
  try {
    const data = yield call(api.student.getAllFiltered, {
      bindingObject, token,
    });
    yield put(filteredRequestSucceeded(data, bindingObject));
  } catch (e) {
    yield put(filteredRequestFailed());
  }
}

function* reqFilteredStudents(action) {
  const token = yield select(tokenSelector);
  const {
    bindingObject,
  } = action;
  try {
    const data = yield call(api.student.getAllFiltered, {
      bindingObject, token,
    });
    yield put(filteredRequestSucceeded(data, bindingObject));
  } catch (e) {
    yield put(filteredRequestFailed());
  }
}

function* reqInformation(action) {
  const token = yield select(tokenSelector);
  const {
    idStudent,
  } = action;
  try {
    const { studentData, idAcademy, optionsData } = yield call(api.student.getAllInfo, {
      idStudent, token,
    });
    yield put(informationRequestSucceeded({ studentData, idAcademy, optionsData }));
  } catch (e) {
    yield put(informationRequestFailed());
  }
}

function* reqUpdate(action) {
  const token = yield select(tokenSelector);
  const {
    studentData,
  } = action;
  try {
    const success = yield call(api.student.updateData, {
      studentData, token,
    });
    yield put(updateRequestSucceeded(success));
  } catch (error) {
    yield put(updateRequestFailed({ error }));
  }
}

function* reqEnrollmentPdf(action) {
  const token = yield select(tokenSelector);
  const { idEnrollment } = action;
  try {
    const document = yield call(api.enrollment.getEnrollmentPdf, { token, idEnrollment });
    const name = `Matrícula ${idEnrollment}`;
    yield put(downloadEnrollmentSucceeded(document, name));
  } catch (e) {
    yield put(informationRequestFailed());
  }
}

function* reqExportAcadInfo() {
  const token = yield select(tokenSelector);
  try {
    const { studentsWithInformedAcademy } = yield call(api.student.getStudentAcadExport, {
      token,
    });
    yield put(requestExportAcademyInformedSucceeded(studentsWithInformedAcademy));
  } catch (e) {
    yield put(requestExportAcademyInformedFailed());
  }
}

export default [
  takeEvery(FILTERS_REQUEST, reqFilters),
  takeEvery(ROADMAPS_REQUEST, reqRoadMaps),
  takeEvery(FILTERED_REQUEST, reqFilteredStudents),
  takeEvery(INFO_REQUEST, reqInformation),
  takeEvery(UPDATE_REQUEST, reqUpdate),
  takeEvery(CLEAN_FILTERS_REQUEST, reqCleanFilters),
  takeEvery(ENROLLMENT_DOWNLOAD_REQUEST, reqEnrollmentPdf),
  takeEvery(REQUEST_ACAD_EXPORT, reqExportAcadInfo),
];
