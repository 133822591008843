import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

class DiscountItem extends React.Component {
  changeDiscountCost = (event) => {
    const {
      discount,
      setDiscountCost,
    } = this.props;

    const price = event.target.value;
    const amount = Math.abs((price * 1).toFixed(2));
    const newDiscount = { ...discount, amount };

    setDiscountCost(newDiscount);
  }

  render() {
    const {
      discount,
    } = this.props;

    return (
      <div className="columns is-vcentered discounts">
        <div className="column is-three-fifths is-offset-one-fifth">{discount.discountName}</div>
        <div className="column is-one-fifths amount">
          <TextField
            fullWidth
            type="number"
            inputProps={{
              style: {
                textAlign: 'right',
                paddingRight: '12px',
              },
            }}
            onChange={e => this.changeDiscountCost(e)}
            value={(discount.amount * -1).toFixed(2)}
          />
        </div>
      </div>
    );
  }
}

DiscountItem.propTypes = {
  discount: PropTypes.object.isRequired,
  setDiscountCost: PropTypes.func.isRequired,
};

export default DiscountItem;
