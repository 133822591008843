export const REQUEST = 'CANDIDATE_LIST_REQUEST';
export const REQUEST_SUCCEEDED = 'CANDIDATE_LIST_REQUEST_SUCCEEDED';
export const REQUEST_FAILED = 'CANDIDATE_LIST_REQUEST_FAILED';

export const FILTERS_REQUEST = 'CANDIDATE_FILTERS_REQUEST';
export const FILTERS_REQUEST_SUCCEEDED = 'CANDIDATE_FILTERS_REQUEST_SUCCEEDED';
export const FILTERS_REQUEST_FAILED = 'CANDIDATE_FILTERS_REQUEST_FAILED';

export const CHANGE_LEAD_PASSWORD = 'CANDIDATE_CHANGE_PASSWORD';
export const CHANGE_LEAD_PASSWORD_SUCCEEDED = 'CANDIDATE_CHANGE_PASSWORD_SUCCEEDED';
export const CHANGE_LEAD_PASSWORD_FAILED = 'CANDIDATE_CHANGE_PASSWORD_FAILED';

export const UPDATE_USER = 'CANDIDATE_USER_UPDATE';

export const REQUEST_PAYMENT_LOG = 'CANDIDATE_PAYMENT_LOG_LIST_REQUEST';
export const REQUEST_PAYMENT_LOG_SUCCEEDED = 'CANDIDATE_PAYMENT_LOG_LIST_REQUEST_SUCCEEDED';
export const REQUEST_PAYMENT_LOG_FAILED = 'CANDIDATE_PAYMENT_LOG_LIST_REQUEST_FAILED';
