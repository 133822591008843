import {
  GET,
  GET_SUCCEEDED,
  GET_FAILED,
  UPDATE_MAIL,
  UPDATE_MAIL_SUCCEEDED,
  UPDATE_MAIL_FAILED,
  SET,
} from './action-types';
import utils from '../utils';

const initialState = {
  mails: [],
};

const mailsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_SUCCEEDED: {
      const { mails } = action;
      return {
        ...state,
        mails,
        loading: false,
      };
    }
    case GET_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error',
        message: 'Ha habido un error al recuperar los mails',
      });
      return {
        ...state,
        mails: [],
      };
    }
    case UPDATE_MAIL: {
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_MAIL_SUCCEEDED: {
      const { mails } = action;
      return {
        ...state,
        loading: false,
        mails,
      };
    }
    case UPDATE_MAIL_FAILED: {
      utils.dialog({
        type: 'error',
        title: 'Error de actualización',
        message: 'Ha habido un error con la actualización del mails',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case SET: {
      const { idMail, field, newText } = action;
      let i;
      const { mails } = state;
      const mail = mails.find((m, index) => {
        if (idMail === m.idMail) {
          i = index;
        }
        return idMail === m.idMail;
      });
      mail[field] = newText;
      mails.splice(i, 1, mail);
      return {
        ...state,
        mails,
      };
    }
    default:
      return state;
  }
};

export default mailsReducer;
