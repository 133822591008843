import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loading from '$routes/Loading';
import Failed from '$routes/Failed';

import {
  Select,
  TextField,
  Checkbox,
} from '@material-ui/core';

const origenSeed = [
  {
    name: 'LLamada Web',
    value: 'llamada-web',
  },
  {
    name: 'LLamada SEM',
    value: 'llamada-sem',
  },
  {
    name: 'Whatsapp',
    value: 'whatsapp',
  },
  {
    name: 'Correo Electronico',
    value: 'correo',
  },
  {
    name: 'Visita Centro',
    value: 'visita-centro',
  },
  {
    name: 'Otros',
    value: 'otros',
  },
];

// eslint-disable-next-line
class BasicForm extends React.Component {
  render() {
    const {
      courses,
      loading,
      data,
      setValue,
    } = this.props;

    const {
      origin,
      originCustom,
      name,
      postalCode,
      email,
      phone,
      courseInterestedIn,
      ads,
      privacity,
    } = data;

    if (loading) return <Loading />;
    if (!courses) return <Failed />;

    const origenList = origenSeed.map(
      element => (
        <option key={`origen-${element.value}`} value={`origen-${element.value}`}>{element.name}</option>
      ),
    );

    const coursesList = courses.map(
      element => (
        <option key={element.idCourse} value={element.idCourse}>{element.name}</option>
      ),
    );

    return (
      <div>
        <div className="columns is-vcentered">
          <div className="column is-one-fifth">Origen</div>
          <div className="column is-four-fifths">
            <Select
              native
              key="origin"
              className="filter-item"
              value={origin}
              onChange={setValue('origin')}
            >
              <option value={0}>Selecciona origen...</option>
              {origenList}
            </Select>
          </div>
        </div>
        { origin === 'origen-otros' ? (
          <div className="columns is-vcentered">
            <div className="column is-one-fifth">¿Que origen ha sido?</div>
            <div className="column is-four-fifths">
              <TextField
                key="originCustom"
                fullWidth
                value={originCustom}
                onChange={setValue('originCustom')}
              />
            </div>
          </div>
        ) : ''}
        <div className="columns is-vcentered">
          <div className="column is-one-fifth">Nombre</div>
          <div className="column is-four-fifths">
            <TextField
              key="name"
              fullWidth
              value={name}
              onChange={setValue('name')}
            />
          </div>
        </div>
        <div className="columns is-vcentered">
          <div className="column is-one-fifth">Codigo postal</div>
          <div className="column is-four-fifths">
            <TextField
              key="postalCode"
              fullWidth
              value={postalCode}
              onChange={setValue('postalCode')}
            />
          </div>
        </div>
        <div className="columns is-vcentered">
          <div className="column is-one-fifth">Correo</div>
          <div className="column is-four-fifths">
            <TextField
              fullWidth
              key="email"
              value={email}
              onChange={setValue('email')}
            />
          </div>
        </div>
        <div className="columns is-vcentered">
          <div className="column is-one-fifth">Teléfono</div>
          <div className="column is-four-fifths">
            <TextField
              fullWidth
              key="phtone"
              value={phone}
              onChange={setValue('phone')}
            />
          </div>
        </div>
        <div className="columns is-vcentered">
          <div className="column is-one-fifth">Ciclo formativo de interés</div>
          <div className="column is-four-fifths">
            <Select
              native
              key="courseInterestedId"
              className="filter-item"
              value={courseInterestedIn}
              onChange={setValue('courseInterestedIn')}
            >
              <option value={0}>Selecciona ciclo...</option>
              {coursesList}
            </Select>
          </div>
        </div>
        <div className="columns is-vcentered">
          <div className="column is-one-fifth">Ads</div>
          <div className="column is-four-fifths">
            <Checkbox
              key="ads"
              checked={ads}
              onChange={setValue('ads')}
            />
          </div>
        </div>
        <div className="columns is-vcentered">
          <div className="column is-one-fifth">Privacidad</div>
          <div className="column is-four-fifths">
            <Checkbox
              key="privacity"
              checked={privacity}
              onChange={setValue('privacity')}
            />
          </div>
        </div>
      </div>
    );
  }
}

BasicForm.propTypes = {
  courses: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
};

BasicForm.defaultProps = {
};

export default connect(
  state => ({
    courses: state.courses.list,
    loading: state.courses.loading,
  }),
)(BasicForm);
