import React from 'react';

import Button from '@material-ui/core/Button';

import { Wrapper } from './Failed.styles';

class Failed extends React.Component {
  onRetryClick = () => {
    window.location.reload();
  }

  render() {
    return (
      <Wrapper>
        <img src="/img/failed-face.svg" alt="Failed" />
        <Button
          color="primary"
          size="large"
          style={{ marginTop: 100 }}
          onClick={this.onRetryClick}
        >
          Volver a intentar
        </Button>
      </Wrapper>
    );
  }
}

export default Failed;
