import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Title from 'ui/Title';
import Form from '../Form';
import Loading from '../../../Loading';

import {
  get as getCourse,
  set as setCourse,
  update as updateCourse,
  activateTrainingPlan,
  showOldTrainingPlan,
  deleteModule,
  modifyMoodleCode,
} from '$redux/courses/actions';

import {
  list as listModuleCourseModalities,
} from '$redux/course-modalities/actions';

import {
  list as listModuleCourseCategories,
} from '$redux/course-categories/actions';

import {
  create as createRoadMap,
} from '$redux/road-maps/actions';

import {
  listModuleSemester,
  update as updateSemester,
} from '$redux/road-maps-module/actions';


class Courses extends React.Component {
  componentDidMount() {
    const { dispatch, match: { params: { id } } } = this.props;
    dispatch(getCourse(id));
    dispatch(listModuleSemester());
    dispatch(listModuleCourseModalities());
    dispatch(listModuleCourseCategories());
  }

  addRoadMap = async (newRoadMap) => {
    if (newRoadMap.name !== null && newRoadMap.name !== '') {
      const { dispatch } = this.props;
      await dispatch(createRoadMap(newRoadMap));
      this.reload();
    }
  }

  saveCourse = () => {
    const { dispatch, data } = this.props;
    dispatch(updateCourse(data.idCourse, data));
  }

  set = (key, value) => {
    const { dispatch } = this.props;
    dispatch(setCourse(key, value));
  }

  reload = () => {
    const { dispatch, match: { params: { id } } } = this.props;
    dispatch(getCourse(id));
  }

  updateSemesterRoadMapModule = (data) => {
    const { dispatch } = this.props;
    dispatch(updateSemester(data));
  }

  handleActive = (idTrainingPlan, active) => {
    const { dispatch, data } = this.props;
    dispatch(activateTrainingPlan(idTrainingPlan, active, data.idCourse));
  }

  handleActiveOldTP = (idTrainingPlan, active) => {
    const { dispatch, data } = this.props;
    dispatch(showOldTrainingPlan(idTrainingPlan, active, data.idCourse));
  }

  handleMoodleCode = (idTrainingPlan, moodleCode) => {
    const { dispatch, data } = this.props;
    dispatch(modifyMoodleCode(idTrainingPlan, moodleCode, data.idCourse));
  }

  deleteModule = async ({ idModule, idRoadMap }) => {
    const { dispatch, match: { params: { id } } } = this.props;
    await dispatch(deleteModule(idRoadMap, idModule, id));
    this.forceUpdate();
  }

  render() {
    const {
      data, semestersmodules, modalities, categories,
    } = this.props;

    if (!data || !semestersmodules || !modalities || !categories) return <Loading />;
    return (
      <div>
        <Title>Ciclos</Title>
        <Form
          data={data}
          addRoadMap={this.addRoadMap}
          set={this.set}
          reload={this.reload}
          semesters={semestersmodules}
          modalities={modalities}
          categories={categories}
          saveCourse={this.saveCourse}
          updateSemester={this.updateSemesterRoadMapModule}
          handleActive={this.handleActive}
          handleMoodleCode={this.handleMoodleCode}
          handleActiveOldTP={this.handleActiveOldTP}
          onClickDelete={this.deleteModule}
        />
      </div>
    );
  }
}

Courses.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.object,
  match: PropTypes.object.isRequired,
  semestersmodules: PropTypes.array,
  modalities: PropTypes.array,
  categories: PropTypes.array,
};

Courses.defaultProps = {
  data: null,
  semestersmodules: null,
  modalities: [],
  categories: [],
};

export default connect(
  state => ({
    data: state.courses.single,
    semestersmodules: state.roadmapsmodule.semestersmodules,
    modalities: state.courseModalities.list,
    categories: state.courseCategories.list,
  }),
)(Courses);
