import { createStore, compose, applyMiddleware } from 'redux';
import persistState from 'redux-localstorage';
import createSagaMiddleware from 'redux-saga';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import rootReducer from './root-reducer';
import rootSaga from './root-saga';
import history from '../history';

// eslint-disable-next-line
const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  connectRouter(history)(rootReducer),
  compose(
    persistState('login'),
    applyMiddleware(
      routerMiddleware(history),
      sagaMiddleware,
    ),
    // eslint-disable-next-line no-underscore-dangle
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
  ),
);

// run the saga
sagaMiddleware.run(rootSaga);

export default store;
