import {
  LIST_STAFF,
  LIST_STAFF_SUCCEEDED,
  LIST_STAFF_FAILED,
  GET_STAFF,
  GET_STAFF_SUCCEEDED,
  GET_STAFF_FAILED,
  UPDATE_STAFF,
  UPDATE_STAFF_SUCCEEDED,
  UPDATE_STAFF_FAILED,
  REMOVE_STAFF,
  REMOVE_STAFF_SUCCEEDED,
  REMOVE_STAFF_FAILED,
  CREATE_STAFF,
  CREATE_STAFF_SUCCEEDED,
  CREATE_STAFF_FAILED,
  SET_STAFF_VALUE,
  RESET_STAFF_VALUE,
  CHANGE_STAFF_PASSWORD,
  CHANGE_STAFF_PASSWORD_SUCCEEDED,
  CHANGE_STAFF_PASSWORD_FAILED,
  GET_STAFF_PROPS,
  GET_STAFF_PROPS_SUCCEEDED,
  GET_STAFF_PROPS_FAILED,
} from './action-types';
import utils from '../utils';

const initialState = {
  list: null,
  roles: null,
  academies: null,
  single: {
    name: '',
    email: '',
    idRole: 0,
    idAcademy: null,
  },
  loading: false,
};

const staffsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LIST_STAFF_FAILED:
    case REMOVE_STAFF_FAILED:
    case CREATE_STAFF_FAILED:
    case UPDATE_STAFF_FAILED:
    case GET_STAFF_PROPS_FAILED:
    case GET_STAFF_FAILED: {
      const { error } = action;

      let message = 'La petición no se ha completado con éxito.';
      let status = 0;

      if (error.response && error.response.data && error.response.data.message) {
        ({ message } = error.response.data);
        ({ status } = error.response);
      }

      utils.dialog({
        type: status === 422 ? 'warning' : 'error',
        title: status === 422 ? 'Error de validación' : 'Error inesperado',
        message,
      });
      return {
        ...state,
        loading: false,
      };
    }
    case CHANGE_STAFF_PASSWORD_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error inesperado',
        message: 'No se ha podido generar la nueva contraseña. Comprobar si el email es correcto.',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case LIST_STAFF:
    case GET_STAFF:
    case GET_STAFF_PROPS:
    case UPDATE_STAFF:
    case REMOVE_STAFF:
    case CHANGE_STAFF_PASSWORD:
    case CREATE_STAFF: {
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_STAFF_SUCCEEDED: {
      const { data } = action;
      return {
        ...state,
        list: data,
        loading: false,
      };
    }
    case GET_STAFF_PROPS_SUCCEEDED: {
      const { roles, academies } = action.data;
      return {
        ...state,
        roles,
        academies,
        loading: false,
      };
    }
    case GET_STAFF_SUCCEEDED: {
      const { data } = action;
      return {
        ...state,
        single: data,
        loading: false,
      };
    }
    case UPDATE_STAFF_SUCCEEDED:
    case REMOVE_STAFF_SUCCEEDED:
    case CREATE_STAFF_SUCCEEDED: {
      utils.dialog({
        title: '¡Perfecto!',
        message: 'La petición se ha procesado con éxito',
      });

      return {
        ...state,
        loading: false,
      };
    }
    case SET_STAFF_VALUE: {
      const { key, value } = action;

      return {
        ...state,
        single: {
          ...state.single,
          [key]: value,
        },
      };
    }
    case RESET_STAFF_VALUE: {
      return {
        ...state,
        single: initialState.single,
      };
    }
    case CHANGE_STAFF_PASSWORD_SUCCEEDED: {
      utils.dialog({
        type: 'succes',
        title: 'Contraseña enviada',
        message: 'La nueva contraseña se ha enviado al usuario seleccionado',
      });
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default staffsReducer;
