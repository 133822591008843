import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography, TextField, Select, MenuItem, FormControl,
} from '@material-ui/core';
import { Wrapper } from './styles';
import SubTitle from '../../../../ui/SubTitle';

class PersonalForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    let documentationType;
    let gender;
    let documentNumber;
    let birthDate;
    let name;
    let birthCountry;
    let surname1;
    let birthCity;
    let surname2;
    let nationality;
    const {
      data, optionsData, disabled, set,
    } = this.props;

    if (data) {
      documentationType = (
        <div className="column is-half">
          <div className="columns is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Tipo de documento</Typography>
            </div>
            <div className="column is-three-fifths">
              <FormControl fullWidth>
                <Select
                  disabled={disabled}
                  value={data.idDocumentationType ? data.idDocumentationType : ''}
                  onChange={e => set('personalInfo', 'idDocumentationType', e.target.value)}
                >
                  {optionsData.documentationTypes.map(docType => (
                    <MenuItem
                      key={docType.idDocumentationType}
                      value={docType.idDocumentationType}
                    >
                      {docType.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      );
      gender = (
        <div className="column is-half">
          <div className="columns right is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Sexo</Typography>
            </div>
            <div className="column is-three-fifths">
              <FormControl fullWidth>
                <Select
                  disabled={disabled}
                  value={data.gender ? data.gender : ''}
                  onChange={e => set('personalInfo', 'gender', e.target.value)}
                >
                  <MenuItem key="H" value="H">Hombre</MenuItem>
                  <MenuItem key="D" value="D">Mujer</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      );
      documentNumber = (
        <div className="column is-half">
          <div className="columns is-vcentered">
            <div className="column is-two-fifths">
              <Typography>N˚ de documento</Typography>
            </div>
            <div className="column is-three-fifths">
              <TextField
                fullWidth
                disabled={disabled}
                onChange={e => set('personalInfo', 'idDocumentNumber', e.target.value)}
                value={data.idDocumentNumber ? data.idDocumentNumber : ''}
              />
            </div>
          </div>
        </div>
      );
      birthDate = (
        <div className="column is-half">
          <div className="columns right is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Fecha de nacimiento</Typography>
            </div>
            <div className="column is-three-fifths">
              <TextField
                type="date"
                disabled={disabled}
                fullWidth
                onChange={e => set('personalInfo', 'birthDate', e.target.value)}
                defaultValue={data.birthDate ? `${data.birthDate.slice(0, 10)}` : 'dd/mm/aaaa'}
              />
            </div>
          </div>
        </div>
      );
      name = (
        <div className="column is-half">
          <div className="columns is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Nombre</Typography>
            </div>
            <div className="column is-three-fifths">
              <TextField
                fullWidth
                disabled={disabled}
                onChange={e => set('personalInfo', 'name', e.target.value)}
                value={data.name}
              />
            </div>
          </div>
        </div>
      );
      birthCountry = (
        <div className="column is-half">
          <div className="columns right is-vcentered">
            <div className="column is-two-fifths">
              <Typography>País de nacimiento</Typography>
            </div>
            <div className="column is-three-fifths">
              <FormControl fullWidth>
                <Select
                  disabled={disabled}
                  value={data.idBirthCountry ? data.idBirthCountry : ''}
                  onChange={e => set('personalInfo', 'idBirthCountry', e.target.value)}
                >
                  {optionsData.countries.map(country => (
                    <MenuItem
                      key={country.idCountry}
                      value={country.idCountry}
                    >
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      );
      surname1 = (
        <div className="column is-half">
          <div className="columns is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Apellido #1</Typography>
            </div>
            <div className="column is-three-fifths">
              <TextField
                fullWidth
                disabled={disabled}
                onChange={e => set('personalInfo', 'surname1', e.target.value)}
                value={data.surname1 ? data.surname1 : ''}
              />
            </div>
          </div>
        </div>
      );
      birthCity = (
        <div className="column is-half">
          <div className="columns right is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Municipio</Typography>
            </div>
            <div className="column is-three-fifths">
              <TextField
                disabled={disabled}
                fullWidth
                onChange={e => set('personalInfo', 'birthCity', e.target.value)}
                value={data.birthCity ? data.birthCity : ''}
              />
            </div>
          </div>
        </div>
      );
      surname2 = (
        <div className="column is-half">
          <div className="columns is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Apellido #2</Typography>
            </div>
            <div className="column is-three-fifths">
              <TextField
                fullWidth
                disabled={disabled}
                onChange={e => set('personalInfo', 'surname2', e.target.value)}
                value={data.surname2 ? data.surname2 : ''}
              />
            </div>
          </div>
        </div>
      );
      nationality = (
        <div className="column is-half">
          <div className="columns right is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Nacionalidad</Typography>
            </div>
            <div className="column is-three-fifths">
              <FormControl fullWidth>
                <Select
                  disabled={disabled}
                  value={data.idNationality ? data.idNationality : ''}
                  onChange={e => set('personalInfo', 'idNationality', e.target.value)}
                >
                  {optionsData.countries.map(country => (
                    <MenuItem
                      key={country.idCountry}
                      value={country.idCountry}
                    >
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      );
    }

    const { title } = this.props;

    return (
      <Wrapper>
        <div className="student-data">
          <SubTitle>{title}</SubTitle>
          <div className="columns is-multiline is-narrow content">
            {documentationType}
            {gender}
            {documentNumber}
            {birthDate}
            {name}
            {birthCountry}
            {surname1}
            {birthCity}
            {surname2}
            {nationality}
          </div>
        </div>
      </Wrapper>);
  }
}

PersonalForm.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object,
  optionsData: PropTypes.object,
  disabled: PropTypes.bool,
  set: PropTypes.func.isRequired,
};

PersonalForm.defaultProps = {
  data: undefined,
  optionsData: {},
  disabled: false,
};

export default (PersonalForm);
