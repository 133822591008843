import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import theme from 'ui/theme';
import Select from '@material-ui/core/Select';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  Button,
  TextField,
} from '@material-ui/core';
import Loading from '$routes/Loading';

import {
  cleanFiltersRequest,
  filtersRequest,
  roadMapsRequest,
} from '$redux/student/actions';
import themeFilters from 'ui/muiFiltersTheme';

import { Wrapper } from './Filters.styles';


class Filters extends React.Component {
  constructor(props) {
    super(props);
    const {
      course,
      academy,
      year,
      semester,
      specialNeeds,
      FCT,
      moreThan28,
      lastYearStudents,
      studentType,
      roadMap,
      discount,
      birthYear,
      name,
      idNumber,
    } = props.filtersApplied;
    this.state = {
      course,
      academy,
      year,
      semester,
      specialNeeds,
      FCT,
      moreThan28,
      lastYearStudents,
      studentType,
      roadMap,
      discount,
      birthYear,
      name,
      idNumber,
      choseTrainingPlan: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(filtersRequest());
  }

  onApplyFilters = (e) => {
    const { applyFilters } = this.props;
    e.preventDefault();
    const {
      course,
      academy,
      year,
      semester,
      specialNeeds,
      FCT,
      moreThan28,
      lastYearStudents,
      studentType,
      roadMap,
      discount,
      birthYear,
      name,
      idNumber,
    } = this.state;
    applyFilters({
      filtersObject: {
        course,
        academy,
        year,
        semester,
        specialNeeds,
        FCT,
        moreThan28,
        lastYearStudents,
        studentType,
        roadMap,
        discount,
        birthYear,
        name,
        idNumber,
      },
    });
  };

  resetFilters = async (e) => {
    e.preventDefault();
    const { dispatch } = this.props;
    dispatch(filtersRequest());
    dispatch(cleanFiltersRequest());
  };

  handleBlur = name => (event) => {
    event.preventDefault();
    event.persist();
    this.setState({
      [name]: event.target.value,
    });
  };

  handleChange = name => (event) => {
    const { checked } = event.target;
    if (name === 'course') {
      const { dispatch } = this.props;
      const idTrainingPlan = event.target.value;
      dispatch(roadMapsRequest({ idTrainingPlan }));
      this.setState({
        [name]: event.target.value,
        choseTrainingPlan: true,
      });
    } else {
      this.setState({
        [name]: checked,
      });
    }
  };

  render() {
    let courseList = [];
    let yearList = [];
    let semesterList = [];
    let academyList = [];
    let specialNeedsList = [];
    let studentTypeList = [];
    let roadMapsList = [];
    let discountsList = [];
    let onlyOneAcademy = false;

    const {
      filtersData, filtersApplied, loading,
    } = this.props;

    if (loading) return <Loading />;

    if (filtersData) {
      const { filters } = filtersData;
      const courseYears = [];
      filters.semesters.forEach((s) => {
        if (!courseYears.includes(s.courseYear)) {
          courseYears.push(s.courseYear);
        }
      });

      yearList = courseYears
        .map(element => <option key={element} value={element}>{element}</option>);

      semesterList = filters.semesters
        .map(
          element => (
            <option key={element.name + element.courseYear} value={element.idSemester}>
              {element.name} {element.courseYear}
            </option>
          ),
        );

      academyList = filters.academies
        .map(
          element => (
            <option key={element.name} value={element.idAcademy}>{element.name}</option>
          ),
        );

      if (academyList.length === 1) {
        onlyOneAcademy = true;
      }

      if (!onlyOneAcademy) {
        courseList = filters.courses
          .map(
            element => (
              <option
                key={`${element.idCourse} ${element.Methodology.name}`}
                value={element.idTrainingPlan}
              >
                {`${element.idCourse} ${element.Methodology.name}`}
              </option>
            ),
          );
      } else {
        courseList = filters.courses
          .map(
            element => (
              <option key={element.name} value={element.idTrainingPlan}>{element.name}</option>
            ),
          );
      }

      specialNeedsList = filters.specialNeeds
        .map(element => (
          <option
            key={element.name}
            value={element.idEducationalSpecialNeed}
          >
            {element.name}
          </option>
        ));

      studentTypeList = filters.studentTypes
        .map((element) => {
          let name;
          if (element === 'New') {
            name = { value: '0', text: 'Estudiantes nuevos' };
          } else {
            name = { value: '1', text: 'Rematrícula' };
          }
          return <option key={name.text} value={name.value}>{name.text}</option>;
        });

      const { roadMapsData } = this.props;
      if (roadMapsData) {
        roadMapsList = roadMapsData.roadMaps.map(
          element => (
            <option key={element.idRoadMap} value={element.idRoadMap}>{element.name}</option>
          ),
        );
      }
      discountsList = filters.discounts.map(
        element => (
          <option key={element.idDiscount} value={element.idDiscount}>{element.name}</option>
        ),
      );
    }

    const birthYears = [];
    for (let i = 2005; i > 1918; i -= 1) {
      birthYears.push(<option key={i}>{i}</option>);
    }

    const {
      choseTrainingPlan,
      FCT,
      moreThan28,
      lastYearStudents,
    } = this.state;

    return (
      <Wrapper theme={theme}>
        <MuiThemeProvider theme={themeFilters}>
          <form onSubmit={this.onApplyFilters} className="filters-form">
            <div className="filters">
              <Select
                native
                disabled={onlyOneAcademy}
                onBlur={this.handleBlur('academy')}
                className="filter-item"
                defaultValue={filtersApplied.academy ? filtersApplied.academy : 0}
              >
                <option value={0}>Centro</option>
                {academyList}
              </Select>
              <Select
                native
                onChange={this.handleChange('course')}
                className="filter-item"
                defaultValue={filtersApplied.course ? filtersApplied.course : 0}
              >
                <option value={0}>Formación</option>
                {courseList}
              </Select>
              <Select
                native
                disabled={!choseTrainingPlan}
                className="filter-item"
                onBlur={this.handleBlur('roadMap')}
                defaultValue={filtersApplied.roadMap ? filtersApplied.roadMap : 0}
              >
                <option value={0}>Itinerario</option>
                {roadMapsList}
              </Select>
              <Select
                native
                onBlur={this.handleBlur('semester')}
                className="filter-item semester"
                defaultValue={filtersApplied.semester ? filtersApplied.semester : 0}
              >
                <option value={0}>Semestre</option>
                {semesterList}
              </Select>
              <Select
                native
                onBlur={this.handleBlur('specialNeeds')}
                className="filter-item"
                defaultValue={filtersApplied.specialNeeds ? filtersApplied.specialNeeds : 0}
              >
                <option value={0}>Nec. esp.</option>
                {specialNeedsList}
              </Select>
              <FormControlLabel
                key="FCT"
                className="no-margin filter-item"
                control={(
                  <Checkbox
                    checked={FCT}
                    onChange={this.handleChange('FCT')}
                    value="FCT"
                  />
                )}
                label="FCTs"
              />
              <FormControlLabel
                key="moreThan28"
                className="no-margin filter-item"
                control={(
                  <Checkbox
                    checked={moreThan28}
                    onChange={this.handleChange('moreThan28')}
                    value="moreThan28"
                  />
                )}
                label="< 28 años"
              />
              <FormControlLabel
                key="lastYearStudent"
                className="no-margin filter-item"
                control={(
                  <Checkbox
                    checked={lastYearStudents}
                    onChange={this.handleChange('lastYearStudents')}
                    value="lastYearStudents"
                  />
                )}
                label="Últ. año"
              />
              <Select
                native
                className="filter-item"
                onBlur={this.handleBlur('studentType')}
                defaultValue={filtersApplied.studentType ? filtersApplied.studentType : 0}
              >
                <option value={0}>Tipo</option>
                {studentTypeList}
              </Select>
              <Select
                native
                onBlur={this.handleBlur('year')}
                className="filter-item"
                defaultValue={filtersApplied.year ? filtersApplied.year : 0}
              >
                <option value={0}>Curso</option>
                {yearList}
              </Select>
              <Select
                native
                className="filter-item"
                onBlur={this.handleBlur('discount')}
                defaultValue={filtersApplied.discount ? filtersApplied.discount : 0}
              >
                <option value={0}>Descuentos</option>
                {discountsList}
              </Select>
              <Select
                native
                className="filter-item"
                onBlur={this.handleBlur('birthYear')}
                defaultValue={filtersApplied.birthYear ? filtersApplied.birthYear : 0}
              >
                <option value={0}>Año Nacimiento</option>
                {birthYears}
              </Select>
              <TextField
                className="filter-item"
                id="name"
                placeholder="Nombre"
                onBlur={this.handleBlur('name')}
                defaultValue={filtersApplied.name ? filtersApplied.name : ''}
              />
              <TextField
                className="filter-item"
                id="id"
                placeholder="NIF"
                onBlur={this.handleBlur('idNumber')}
                defaultValue={filtersApplied.idNumber ? filtersApplied.idNumber : ''}
              />
              <Button
                type="submit"
                className="apply-filters-button"
              >
                APLICAR FILTROS
              </Button>
              <Button
                color="secondary"
                onClick={this.resetFilters}
                className="reset-filters-button"
              >
                LIMPIAR FILTROS
              </Button>
            </div>
          </form>
        </MuiThemeProvider>
      </Wrapper>
    );
  }
}

Filters.propTypes = {
  applyFilters: PropTypes.func.isRequired,
  filtersData: PropTypes.object,
  roadMapsData: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  filtersApplied: PropTypes.object,
  loading: PropTypes.bool.isRequired,
};

Filters.defaultProps = {
  filtersData: undefined,
  roadMapsData: undefined,
  filtersApplied: {},
};

export default connect(
  state => ({
    filtersData: state.student.filtersData,
    roadMapsData: state.student.roadMapsData,
    loading: state.student.loading,
    filtersApplied: state.student.filters,
  }),
)(Filters);
