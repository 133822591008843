import styled from 'styled-components';

export const Wrapper = styled.div`
  .student-data {
    border-bottom: none;
    font-size: .75em;
  }

  .student-data .fLcYOY {
    border-bottom: none;
    margin: 0;
    padding: 0;
  }

  .content {
    margin-top: 20px;
    margin-bottom: 30px;
    max-width: 95%;
  }

  .right {
    padding-left: 2rem;
  }

`;
