import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import theme from 'ui/theme';
import {
  TextField, Button, FormControlLabel, Checkbox,
} from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import themeFilters from 'ui/muiFiltersTheme';
import { canSelector } from '$redux/login/selectors';
import { filtersRequest, cleanFiltersRequest } from '$redux/enrollments/actions';
import Failed from '$routes/Failed';
import Loading from '$routes/Loading';
import { Wrapper } from './Filters.styles';

class Filters extends React.Component {
  constructor(props) {
    super(props);
    const {
      idState,
      idAcademy,
      idTrainingPlan,
      idSemester,
      idDiscount,
      name,
      surname1,
      paid,
      paymentMethod,
      pendingDiscuntsValidation,
      hold,
      reenroll,
      idCollaboratingCenter,
      email,
      secondaryEmail,
      collaboratingCenterStudentEmail,
    } = props.filtersApplied;
    this.state = {
      idState,
      idAcademy,
      idTrainingPlan,
      idSemester,
      idDiscount,
      name,
      surname1,
      paid,
      paymentMethod,
      pendingDiscuntsValidation,
      hold,
      reenroll,
      idCollaboratingCenter,
      email,
      secondaryEmail,
      collaboratingCenterStudentEmail,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(filtersRequest());
  }

  onApplyFilters = (e) => {
    e.preventDefault();
    const { applyFilters } = this.props;

    const {
      idState,
      idAcademy,
      idTrainingPlan,
      idSemester,
      idDiscount,
      name,
      surname1,
      paid,
      paymentMethod,
      pendingDiscuntsValidation,
      hold,
      reenroll,
      idCollaboratingCenter,
      email,
      secondaryEmail,
      collaboratingCenterStudentEmail,
    } = this.state;
    applyFilters({
      idState,
      idAcademy,
      idTrainingPlan,
      idSemester,
      idDiscount,
      name,
      surname1,
      paid,
      paymentMethod,
      pendingDiscuntsValidation,
      hold,
      reenroll,
      idCollaboratingCenter,
      email,
      secondaryEmail,
      collaboratingCenterStudentEmail,
    });
  };

  resetFilters = async (e) => {
    e.preventDefault();
    const { dispatch, applyFilters } = this.props;
    dispatch(filtersRequest());
    dispatch(cleanFiltersRequest());
    applyFilters({});
  }

  handleChange = name => (event) => {
    if (name === 'pendingDiscuntsValidation' || name === 'hold') {
      const { checked } = event.target;
      this.setState({ [name]: checked });
    } else {
      this.setState({ [name]: event.target.value });
    }
  };

  render() {
    const {
      filtersData, loading, userCan, filtersApplied,
    } = this.props;
    let academiesList;
    let defaultAcademy = <option value={0}>Centro</option>;

    if (loading) return <Loading />;
    if (!filtersData) return <Failed />;
    if (!filtersData.states) return <Failed />;

    const statesList = filtersData.states.map(
      item => (
        <option key={item.idState} value={item.idState}>{item.name}</option>
      ),
    );

    if (filtersData.academies.length === 1) {
      defaultAcademy = (
        <option
          key={filtersData.academies[0].idAcademy}
          value={filtersData.academies[0].idAcademy}
        >
          {filtersData.academies[0].name}
        </option>
      );
    } else {
      academiesList = filtersData.academies.map(
        item => (
          <option key={item.idAcademy} value={item.idAcademy}>{item.name}</option>
        ),
      );
    }

    const trainingPlansList = filtersData.trainingPlans.map(
      item => (
        <option key={item.idTrainingPlan} value={item.idTrainingPlan}>
          {`${item.courseCode} ${item.methodologyName}`}
        </option>
      ),
    );

    const paymentMethodsList = filtersData.paymentMethods.map(
      item => (
        <option key={item.code} value={item.code}>
          {item.name}
        </option>
      ),
    );

    const semestersList = filtersData.semesters.map(
      item => (
        <option key={item.idSemester} value={item.idSemester}>{item.name} {item.courseYear}</option>
      ),
    );

    const discountsList = filtersData.discounts.map(
      item => (
        <option key={item.idDiscount} value={item.idDiscount}>
          {item.name}
        </option>
      ),
    );

    const collaboratingCentersList = filtersData.collaboratingCenters.map(
      item => (
        <option key={item.idCollaboratingCenter} value={item.idCollaboratingCenter}>
          {item.name}
        </option>
      ),
    );

    const {
      name,
      surname1,
      email,
      secondaryEmail,
      collaboratingCenterStudentEmail,
      pendingDiscuntsValidation,
      hold,
    } = this.state;

    return (
      <Wrapper theme={theme}>
        <MuiThemeProvider theme={themeFilters}>
          <form onSubmit={this.onApplyFilters} className="filters-form">
            <div className="filters">
              <Select
                native
                onChange={this.handleChange('idState')}
                className="filter-item"
                defaultValue={filtersApplied.idState ? filtersApplied.idState : 0}
              >
                <option value={0}>Estado</option>
                {statesList}
              </Select>
              <Select
                native
                onChange={this.handleChange('idAcademy')}
                className="filter-item"
                defaultValue={filtersApplied.idAcademy ? filtersApplied.idAcademy : 0}
              >
                {defaultAcademy}
                {academiesList}
              </Select>
              <Select
                native
                onChange={this.handleChange('idTrainingPlan')}
                className="filter-item"
                defaultValue={filtersApplied.idTrainingPlan ? filtersApplied.idTrainingPlan : 0}
              >
                <option value={0}>Ciclo</option>
                {trainingPlansList}
              </Select>
              <Select
                native
                onChange={this.handleChange('idSemester')}
                className="filter-item semester"
                defaultValue={filtersApplied.idSemester ? filtersApplied.idSemester : 0}
              >
                <option value={0}>Semestre</option>
                {semestersList}
              </Select>
              {
                userCan('ENROLLMENT_LIST_UNPAID') ? (
                  <Select
                    native
                    onChange={this.handleChange('paid')}
                    className="filter-item"
                    defaultValue={filtersApplied.paid ? filtersApplied.paid : 0}
                  >
                    <option value={0}>Estado Pago</option>
                    <option value={1}>Pendiente</option>
                    <option value={2}>Pagada</option>
                    <option value={3}>Vencido</option>
                    <option value={4}>Vence hoy</option>
                    <option value={5}>Vence en 7 días</option>
                  </Select>
                ) : null
              }
              <TextField
                className="filter-item"
                id="name"
                placeholder="Nombre"
                onChange={this.handleChange('name')}
                defaultValue={name}
              />
              <TextField
                className="filter-item"
                id="surname1"
                placeholder="Apellido #1"
                onChange={this.handleChange('surname1')}
                defaultValue={surname1}
              />
              <TextField
                className="filter-item"
                id="email"
                placeholder="Email"
                onChange={this.handleChange('email')}
                defaultValue={email}
              />
              <TextField
                className="filter-item"
                id="secondaryEmail"
                placeholder="Linkia Email"
                onChange={this.handleChange('secondaryEmail')}
                defaultValue={secondaryEmail}
              />
              <TextField
                className="filter-item"
                id="collaboratingCenterStudentEmail"
                placeholder="CC Email"
                onChange={this.handleChange('collaboratingCenterStudentEmail')}
                defaultValue={collaboratingCenterStudentEmail}
              />
              <Select
                native
                onChange={this.handleChange('idCollaboratingCenter')}
                className="filter-item"
                defaultValue={filtersApplied.idCollaboratingCenter
                  ? filtersApplied.idCollaboratingCenter
                  : 0
                }
              >
                <option value={0}>Centros Colaboradores</option>
                {collaboratingCentersList}
              </Select>
              {
                userCan('ENROLLMENT_LIST_UNPAID') ? (
                  <Select
                    native
                    onChange={this.handleChange('paymentMethod')}
                    className="filter-item"
                    defaultValue={filtersApplied.paymentMethod ? filtersApplied.paymentMethod : 0}
                  >
                    <option value={0}>Método de Pago</option>
                    {paymentMethodsList}
                  </Select>
                ) : null
              }
              <Select
                native
                onChange={this.handleChange('idDiscount')}
                className="filter-item"
                defaultValue={filtersApplied.idDiscount ? filtersApplied.idDiscount : 0}
              >
                <option value={0}>Descuentos</option>
                {discountsList}
              </Select>
              <Select
                native
                onChange={this.handleChange('reenroll')}
                className="filter-item"
                defaultValue={filtersApplied.reenroll ? filtersApplied.reenroll : -1}
              >
                <option value={-1}>Tipo matrícula</option>
                <option value={0}>Nueva</option>
                <option value={1}>Rematrícula</option>
              </Select>
              <FormControlLabel
                key="pendingDiscuntsValidation"
                className="no-margin filter-item extra-item"
                control={(
                  <Checkbox
                    checked={pendingDiscuntsValidation}
                    onChange={this.handleChange('pendingDiscuntsValidation')}
                    value="pendingDiscuntsValidation"
                  />
                )}
                label="Pendientes de validar descuentos"
              />
              <FormControlLabel
                key="hold"
                className="no-margin filter-item extra-item"
                control={(
                  <Checkbox
                    checked={hold}
                    onChange={this.handleChange('hold')}
                    value="hold"
                  />
                )}
                label="Hold"
              />
              <Button
                color="primary"
                type="submit"
                className="apply-filters-button"
              >
                APLICAR FILTROS
              </Button>
              <Button
                color="secondary"
                onClick={this.resetFilters}
                className="reset-filters-button"
              >
                LIMPIAR FILTROS
              </Button>
            </div>
          </form>
        </MuiThemeProvider>
      </Wrapper>
    );
  }
}

Filters.propTypes = {
  dispatch: PropTypes.func.isRequired,
  userCan: PropTypes.func.isRequired,
  applyFilters: PropTypes.func.isRequired,
  filtersData: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  filtersApplied: PropTypes.object,
};

Filters.defaultProps = {
  filtersData: undefined,
  filtersApplied: {},
};

export default connect(
  state => ({
    userCan: canSelector(state),
    filtersData: state.enrollments.filtersData,
    loading: state.enrollments.loading,
    filtersApplied: state.enrollments.filters,
  }),
)(Filters);
