import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  get as getCharge,
  set as setCharge,
  update as updateCharge,
} from '$redux/charges/actions';

import Title from 'ui/Title';
import Loading from '$routes/Loading';
import Failed from '$routes/Failed';

import Form from '../Form';

class Charges extends React.Component {
  componentDidMount() {
    const { dispatch, match: { params: { id } } } = this.props;
    dispatch(getCharge(id));
  }

  onUpdate = () => {
    const { dispatch, data } = this.props;
    dispatch(updateCharge(data.idSplitPayment, data));
  }

  set = (key, value) => {
    const { dispatch } = this.props;
    dispatch(setCharge(key, value));
  }

  render() {
    const {
      data,
      ui,
      loading,
      match: { params: { id } },
    } = this.props;

    if (loading) return <Loading />;
    if (!data) return <Failed />;

    return (
      <div>
        <Title>Editar cobro #{id}</Title>
        <Form
          action="edit"
          data={data}
          ui={ui}
          set={this.set}
          onSend={this.onUpdate}
        />
      </div>
    );
  }
}

Charges.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.object,
  ui: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
};

Charges.defaultProps = {
  data: null,
};

export default connect(
  state => ({
    data: state.charges.single,
    ui: state.charges.ui,
    loading: state.charges.loading,
  }),
)(Charges);
