export const CREATE = 'ROADMAP_CREATE';
export const CREATE_SUCCEEDED = 'ROADMAP_CREATE_SUCCEEDED';
export const CREATE_FAILED = 'ROADMAP_CREATE_FAILED';

export const UPDATE = 'ROADMAP_UPDATE';
export const UPDATE_SUCCEEDED = 'ROADMAP_UPDATE_SUCCEEDED';
export const UPDATE_FAILED = 'ROADMAP_UPDATE_FAILED';

export const GET = 'ROADMAP_GET';
export const GET_SUCCEEDED = 'ROADMAP_GET_SUCCEEDED';
export const GET_FAILED = 'ROADMAP_GET_FAILED';
