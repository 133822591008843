import React from 'react';
import { Link } from 'react-router-dom';
import Title from 'ui/Title';
import theme from 'ui/theme';
import { Button } from '@material-ui/core';
import { Wrapper } from './styles';
import Filters from './Filters';
import Grid from './Grid';

class Candidates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {},
    };
  }

  applyFilters = (filtersSelected) => {
    this.setState({ filters: filtersSelected });
  }

  render() {
    const { filters } = this.state;

    return (
      <Wrapper theme={theme}>
        <Title>
          Candidatos
          <Link to="/candidate-payment-logs">
            <Button size="small">Logs de tipo de pago</Button>
          </Link>
        </Title>
        <Filters applyFilters={this.applyFilters} />
        <Grid filters={filters} />
      </Wrapper>
    );
  }
}

export default Candidates;
