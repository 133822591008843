import {
  GET,
  GET_SUCCEEDED,
  GET_FAILED,
} from './action-types';

export const get = bindingObject => ({ type: GET, bindingObject });
export const getSucceeded = ({ splitPaymentTransactions, pages }) => ({
  type: GET_SUCCEEDED, splitPaymentTransactions, pages,
});
export const getFailed = () => ({ type: GET_FAILED });
