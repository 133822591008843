import React from 'react';
import { connect } from 'react-redux';
import Title from 'ui/Title';
import theme from 'ui/theme';
import {
  removeSelections,
  requestResetServicesActionsWaiting,
  setSelection,
} from '$redux/services/actions';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { Wrapper } from './styles';
import Grid from './Grid';
import LogDialog from '../ServiceActions/LogDialog';

class ServicesActionsWaiting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {},
      open: false,
      idUserServiceAction: '-1',
    };
  }

  openLogWindow = (idUserServiceAction) => {
    this.setState({
      open: true,
      idUserServiceAction,
    });
  };

  closeThread = () => {
    this.setState({
      open: false,
    });
  };

  setSelection = (id, value) => {
    const { dispatch } = this.props;
    dispatch(setSelection(id, value));
  };

  removeSelections = () => {
    const { dispatch } = this.props;
    dispatch(removeSelections());
  };

  applyFilters = (filtersSelected) => {
    this.setState({ filters: filtersSelected });
  };

  enviarWaiting = () => {
    const {
      dispatch,
      selections,
    } = this.props;
    dispatch(requestResetServicesActionsWaiting({ idUserService: selections[0] }));
  };

  render() {
    const {
      selections,
      selections: { length: selectedItems },
    } = this.props;

    const {
      filters,
      open,
      idUserServiceAction,
    } = this.state;

    return (
      <Wrapper theme={theme}>
        <Title>Acciones en Espera
          <div>
            <Button
              size="small"
              disabled={!selectedItems}
              onClick={this.enviarWaiting}
            >Reactivar
            </Button>
            <Button
              size="small"
              disabled={!selectedItems}
              onClick={this.removeSelections}
            >Quitar selección
            </Button>
          </div>
        </Title>
        <LogDialog
          open={open}
          idUserServiceAction={idUserServiceAction}
          closeThread={this.closeThread}
        />
        <Grid
          filters={filters}
          selections={selections}
          setSelection={this.setSelection}
          openLogWindow={this.openLogWindow}
        />
      </Wrapper>
    );
  }
}

ServicesActionsWaiting.propTypes = {
  dispatch: PropTypes.func.isRequired,
  selections: PropTypes.array.isRequired,
};

export default connect(state => ({
  selections: state.services.selections,
}))(ServicesActionsWaiting);
