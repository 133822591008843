import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import theme from 'ui/theme';
import moment from 'moment';
import { TextField, Button } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import themeFilters from 'ui/muiFiltersTheme';
import Failed from '$routes/Failed';
import Loading from '$routes/Loading';
import { Wrapper } from './Filters.styles';
import { reqGroupFilters } from '$redux/groups/actions';

moment.locale('es');

class Filters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idUtm: '',
      formFromUrl: '',
      name: '',
      email: '',
      phone: '',
      privacity: '',
      ads: '',
      zip: '',
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(reqGroupFilters());
  }

  onApplyFilters = (e) => {
    e.preventDefault();
    const { applyFilters } = this.props;

    const {
      idUtm,
      formId,
      formName,
      formFromUrl,
      name,
      lastName,
      province,
      course,
      lastCourse,
      email,
      phone,
      privacity,
      ads,
    } = this.state;
    applyFilters({
      idUtm,
      formId,
      formName,
      formFromUrl,
      name,
      lastName,
      province,
      course,
      lastCourse,
      email,
      phone,
      privacity,
      ads,
    });
  };

  handleChange = name => (event) => {
    event.preventDefault();
    this.setState({ [name]: event.target.value });
  };

  render() {
    const { filtersData, loading } = this.props;

    if (loading) return <Loading />;
    if (!filtersData) return <Failed />;

    const {
      idUtm,
      formFromUrl,
      name,
      email,
      phone,
      privacity,
      ads,
      zip,
    } = this.state;

    return (
      <Wrapper theme={theme}>
        <MuiThemeProvider theme={themeFilters}>
          <form onSubmit={this.onApplyFilters} className="filters-form">
            <div className="filters">
              <TextField
                className="filter-item"
                id="idUtm"
                placeholder="Utm Id"
                onChange={this.handleChange('idUtm')}
                defaultValue={idUtm}
              />
              <TextField
                className="filter-item"
                id="formFromUrl"
                placeholder="Url"
                onChange={this.handleChange('formFromUrl')}
                defaultValue={formFromUrl}
              />
              <TextField
                className="filter-item"
                id="name"
                placeholder="Name"
                onChange={this.handleChange('name')}
                defaultValue={name}
              />
              <TextField
                className="filter-item"
                id="zip"
                placeholder="Zip"
                onChange={this.handleChange('zip')}
                defaultValue={zip}
              />
              <TextField
                className="filter-item"
                id="email"
                placeholder="Email"
                onChange={this.handleChange('email')}
                defaultValue={email}
              />
              <TextField
                className="filter-item"
                id="phone"
                placeholder="Telefono"
                onChange={this.handleChange('phone')}
                defaultValue={phone}
              />
              <TextField
                className="filter-item"
                id="privacity"
                placeholder="Privacidad"
                onChange={this.handleChange('privacity')}
                defaultValue={privacity}
              />
              <TextField
                className="filter-item"
                id="ads"
                placeholder="Ads"
                onChange={this.handleChange('ads')}
                defaultValue={ads}
              />
              <Button
                color="primary"
                type="submit"
                className="button"
              >
                APLICAR FILTROS
              </Button>
            </div>
          </form>
        </MuiThemeProvider>
      </Wrapper>
    );
  }
}

Filters.propTypes = {
  applyFilters: PropTypes.func.isRequired,
  filtersData: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

Filters.defaultProps = {
  filtersData: undefined,
};

export default connect(
  state => ({
    filtersData: state.groups.filtersData,
    loading: state.groups.loading,
  }),
)(Filters);
