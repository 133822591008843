import swal from 'sweetalert2';
import { env } from '$config';

const dialog = ({ type, title, message }) => {
  switch (type) {
    case 'info':
    case 'error':
      // TODO: Handle errors centrally
      // eslint-disable-next-line no-console
      if (env === 'development') console.error(message);
      swal({
        type,
        title,
        html: message,
      });
      break;
    case 'warning':
      swal({
        type,
        title,
        html: message,
      });
      break;
    case 'reload':
      swal({
        title,
        html: message,
        type: 'success',
      }).then(() => {
        window.location.reload();
      });
      break;
    default:
      swal({
        title,
        html: message,
        type: 'success',
      });
  }
};

export default dialog;
