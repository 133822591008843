export const REQUEST_FAILED = 'COURSE_REQUEST_FAILED';
export const LIST = 'COURSE_LIST';
export const LIST_SUCCEEDED = 'COURSE_LIST_SUCCEEDED';
export const GET = 'COURSE_GET';
export const GET_SUCCEEDED = 'COURSE_GET_SUCCEEDED';
export const UPDATE = 'COURSE_UPDATE';
export const UPDATE_SUCCEEDED = 'COURSE_UPDATE_SUCCEEDED';
export const REMOVE = 'COURSE_REMOVE';
export const REMOVE_SUCCEEDED = 'COURSE_REMOVE_SUCCEEDED';
export const CREATE = 'COURSE_CREATE';
export const CREATE_SUCCEEDED = 'COURSE_CREATE_SUCCEEDED';
export const SET = 'COURSE_SET';
export const RESET = 'COURSE_RESET';
export const SWITCH = 'SWITCH_COURSE';
export const SWITCH_SUCCEEDED = 'SWITCH_COURSE_SUCCEEDED';
export const SWITCH_FAILED = 'SWITCH_COURSE_FAILED';
export const GET_METHODOLOGIES = 'COURSE_GET_METHODOLOGIES';
export const GET_METHODOLOGIES_SUCCEEDED = 'COURSE_GET_METHODOLOGIES_SUCCEEDED';
export const ACTIVATE_TP = 'COURSE_ACTIVATE_TRAINING_PLAN';
export const ACTIVATE_TP_SUCCESS = 'COURSE_ACTIVATE_TRAINING_PLAN_SUCCESS';
export const SHOW_OLD_TP = 'COURSE_SHOW_OLD_TRAINING_PLAN';
export const SHOW_OLD_TP_SUCCESS = 'COURSE_SHOW_OLD_TRAINING_PLAN_SUCCESS';
export const DELETE_MODULE = 'COURSE_MODULE_DELETE';
export const MODIFY_MOODLE_CODE_TP = 'COURSE_MODIFY_MOODLE_CODE_TRAINING_PLAN';
export const MODIFY_MOODLE_CODE_TP_SUCCESS = 'COURSE_MODIFY_MOODLE_CODE_TRAINING_PLAN_SUCCESS';
