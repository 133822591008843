import {
  select, call, put, takeEvery,
} from 'redux-saga/effects';
import api from 'linkia-api';

import {
  REQUEST_UTM_FORM_FILTERS,
  REQUEST_UTM_FORM_CREATE_LEAD,
} from './action-types';

import {
  reqUtmFormFiltersSucceeded,
  reqUtmFormFiltersFailed,
  reqUtmFormCreateSuccess,
  reqUtmFormCreateFailed,
} from './actions';


import { tokenSelector } from '../login/selectors';

function* actionUtmFormFilters(action) {
  const token = yield select(tokenSelector);
  const {
    bindingObject,
  } = action;
  try {
    const data = yield call(api.utm.getUtmFormsFiltered, {
      bindingObject, token,
    });
    yield put(reqUtmFormFiltersSucceeded(data, bindingObject));
  } catch (e) {
    yield put(reqUtmFormFiltersFailed());
  }
}

function* actionUtmFormCreateLead(action) {
  const token = yield select(tokenSelector);
  const {
    bindingObject,
  } = action;
  try {
    yield call(api.utm.insertUTMFormLead, {
      bindingObject, token,
    });
    yield put(reqUtmFormCreateSuccess(bindingObject));
  } catch (e) {
    yield put(reqUtmFormCreateFailed());
  }
}

export default [
  takeEvery(REQUEST_UTM_FORM_FILTERS, actionUtmFormFilters),
  takeEvery(REQUEST_UTM_FORM_CREATE_LEAD, actionUtmFormCreateLead),
];
