import React from 'react';
import PropTypes from 'prop-types';

import {
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import { Wrapper, PlanHeaderWrapper, PlanRowWrapper } from './CoursesForm.styles';

class BasicForm extends React.Component {
  onCheckCourse = (id, checked) => {
    const { data, set } = this.props;
    set(
      'selectedAcademies',
      [
        // always remove checked/unchecked id
        ...data.selectedAcademies.filter(p => p !== id),

        // then add checked id if proceeds
        ...(checked ? [id] : []),
      ],
    );
  }

  selectAll = (trainingPlans, checked) => {
    const array = [];
    if (checked) {
      trainingPlans.forEach((element, index) => {
        array.push(index + 1);
      });
    }
    const { set } = this.props;
    set(
      'selectedAcademies', array,
    );
  }

  render() {
    const {
      data: {
        selectedAcademies,
      },
      ui: {
        academies,
      },
    } = this.props;

    const bulmaCols = {
      1: 'is-1',
      2: '',
    };

    return (
      <Wrapper>
        <PlanHeaderWrapper>
          <div className="columns">
            <div className={`column ${bulmaCols[1]} checkbox`}>
              <Checkbox
                onChange={e => this.selectAll(academies, e.target.checked)}
                checked={selectedAcademies.length === academies.length}
              />
            </div>
            <div className={`column ${bulmaCols[2]}`}>
              Nombre del centro
            </div>
          </div>
        </PlanHeaderWrapper>
        <div>
          {academies.map((plan) => {
            const {
              idAcademy,
              name,
            } = plan;

            return (
              <PlanRowWrapper key={idAcademy}>
                <div className="columns is-vcentered">
                  <div className={`column ${bulmaCols[1]}`}>
                    <FormControlLabel
                      control={<Checkbox color="primary" />}
                      checked={!!selectedAcademies.find(id => id === idAcademy)}
                      onChange={e => this.onCheckCourse(idAcademy, e.target.checked)}
                    />
                  </div>
                  <div className={`column ${bulmaCols[2]}`}>
                    {name}
                  </div>
                </div>
              </PlanRowWrapper>
            );
          })}
        </div>
      </Wrapper>
    );
  }
}

BasicForm.propTypes = {
  data: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  set: PropTypes.func.isRequired,
};

export default BasicForm;
