import styled from 'styled-components';

export const Wrapper = styled.div`
  .radio-buttons-container {
    flex-direction: row;
  }
  .formControl {
    width: 100%;
  }
`;
