export const ADD_SERVICES_ACTION_USER = 'ADD_SERVICES_ACTION_USER';
export const ADD_SERVICES_ACTION_USER_SUCCEED = 'ADD_SERVICES_ACTION_USER_SUCCEED';
export const ADD_SERVICES_ACTION_USER_FAILED = 'ADD_SERVICES_ACTION_USER_FAILED';
export const GET_SERVICES_FILTER_FILTERED = 'GET_SERVICES_FILTER_FILTERED';
export const GET_SERVICES_FILTER_FILTERED_SUCCEED = 'GET_SERVICES_FILTER_FILTERED_SUCCEED';
export const GET_SERVICES_FILTER_FILTERED_FAILED = 'GET_SERVICES_FILTER_FILTERED_FAILED';
export const GET_SERVICES_LOG_FILTER_FILTERED = 'GET_SERVICES_LOG_FILTER_FILTERED';
export const GET_SERVICES_LOG_FILTER_FILTERED_SUCCEED = 'GET_SERVICES_LOG_FILTER_FILTERED_SUCCEED';
export const GET_SERVICES_LOG_FILTER_FILTERED_FAILED = 'GET_SERVICES_LOG_FILTER_FILTERED_FAILED';
export const GET_SERVICES_ACTIONS_FILTER_FILTERED = 'GET_SERVICES_ACTIONS_FILTER_FILTERED';
export const GET_SERVICES_ACTIONS_FILTER_FILTERED_SUCCEED = 'GET_SERVICES_ACTIONS_FILTER_FILTERED_SUCCEED';
export const GET_SERVICES_ACTIONS_FILTER_FILTERED_FAILED = 'GET_SERVICES_ACTIONS_FILTER_FILTERED_FAILED';
export const GET_SERVICES_ACTIONS_LOGS_FILTER_FILTERED = 'GET_SERVICES_ACTIONS_LOGS_FILTER_FILTERED';
export const GET_SERVICES_ACTIONS_LOGS_FILTER_FILTERED_SUCCEED = 'GET_SERVICES_ACTIONS_LOGS_FILTER_FILTERED_SUCCEED';
export const GET_SERVICES_ACTIONS_LOGS_FILTER_FILTERED_FAILED = 'GET_SERVICES_ACTIONS_LOGS_FILTER_FILTERED_FAILED';
export const GET_SERVICES_STUDENT_GRADES_FILTER_FILTERED = 'GET_SERVICES_STUDENT_GRADES_FILTER_FILTERED';
export const GET_SERVICES_STUDENT_GRADES_FILTER_FILTERED_SUCCEED = 'GET_SERVICES_STUDENT_GRADES_FILTER_FILTERED_SUCCEED';
export const GET_SERVICES_STUDENT_GRADES_FILTER_FILTERED_FAILED = 'GET_SERVICES_STUDENT_GRADES_FILTER_FILTERED_FAILED';
export const GET_SERVICES_EXPORT = 'GET_SERVICES_EXPORT';
export const GET_SERVICES_EXPORT_SUCCEEDED = 'GET_SERVICES_EXPORT_SUCCEEDED';
export const GET_SERVICES_EXPORT_FAILED = 'GET_SERVICES_EXPORT_FAILED';
export const ADD_SERVICES_ACTION_BULK = 'ADD_SERVICES_ACTION_BULK';
export const ADD_SERVICES_ACTION_BULK_SUCCEED = 'ADD_SERVICES_ACTION_BULK_SUCCEED';
export const ADD_SERVICES_ACTION_BULK_FAILED = 'ADD_SERVICES_ACTION_BULK_FAILED';
export const GET_SERVICES_ACTIONS_WAITING = 'GET_SERVICES_ACTIONS_WAITING';
export const GET_SERVICES_ACTIONS_WAITING_SUCCEED = 'GET_SERVICES_ACTIONS_WAITING_SUCCEED';
export const GET_SERVICES_ACTIONS_WAITING_FAILED = 'GET_SERVICES_ACTIONS_WAITING_FAILED';

export const SET_SELECTION = 'SERVICES_ACTIONS_WAITING_SET_SELECTION';
export const REMOVE_SELECTIONS = 'SERVICES_ACTIONS_WAITING_REMOVE_SELECTIONS';

export const RESET_SERVICES_ACTIONS_WAITING = 'RESET_SERVICES_ACTIONS_WAITING';
export const RESET_SERVICES_ACTIONS_WAITING_SUCCEED = 'RESET_SERVICES_ACTIONS_WAITING_SUCCEED';
export const RESET_SERVICES_ACTIONS_WAITING_FAILED = 'RESET_SERVICES_ACTIONS_WAITING_FAILED';
export const UPDATE_SERVICES_ACTION_STATUS = 'UPDATE_SERVICES_ACTION_STATUS';
export const UPDATE_SERVICES_ACTION_STATUS_SUCCEED = 'UPDATE_SERVICES_ACTION_STATUS_SUCCEED';
export const UPDATE_SERVICES_ACTION_STATUS_FAILED = 'UPDATE_SERVICES_ACTION_STATUS_FAILED';
export const REMOVE_SERVICES_ACTION_STATUS = 'REMOVE_SERVICES_ACTION_STATUS';
export const REMOVE_SERVICES_ACTION_STATUS_SUCCEED = 'REMOVE_SERVICES_ACTION_STATUS_SUCCEED';
export const REMOVE_SERVICES_ACTION_STATUS_FAILED = 'REMOVE_SERVICES_ACTION_STATUS_FAILED';
