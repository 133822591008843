import React from 'react';
import PropTypes from 'prop-types';

import {
  TextField,
} from '@material-ui/core';

import ZipCodesGroup from './ZipCodesGroup';

// eslint-disable-next-line
class BasicForm extends React.Component {
  render() {
    const {
      data: {
        name,
        cohort,
        alexiaId,
        Provinces,
        ZipCodes,
      },
      set,
    } = this.props;

    return (
      <div>
        <div className="columns is-vcentered">
          <div className="column is-one-fifth">Nombre</div>
          <div className="column is-four-fifths">
            <TextField
              fullWidth
              value={name}
              onChange={e => set('name', e.target.value)}
            />
          </div>
        </div>
        <div className="columns is-vcentered">
          <div className="column is-one-fifth">ID Alexia</div>
          <div className="column is-four-fifths">
            <TextField
              fullWidth
              value={alexiaId}
              onChange={e => set('alexiaId', e.target.value)}
            />
          </div>
        </div>
        <div className="columns is-vcentered">
          <div className="column is-one-fifth">Cohort</div>
          <div className="column is-four-fifths">
            <TextField
              fullWidth
              value={cohort}
              onChange={e => set('cohort', e.target.value)}
            />
          </div>
        </div>
        <ZipCodesGroup
          type="Provinces"
          data={Provinces}
          set={set}
          label="Prefijos provincia"
        />
        <ZipCodesGroup
          type="ZipCodes"
          data={ZipCodes}
          set={set}
          label="Códigos postales"
        />
      </div>
    );
  }
}

BasicForm.propTypes = {
  data: PropTypes.object.isRequired,
  set: PropTypes.func.isRequired,
};

export default BasicForm;
