import React from 'react';

import Title from 'ui/Title';

import InfoChannels from './InfoChannels';
import Regulations from './Regulations';
import PaymentModes from './PaymentModes';

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Title>
          Configuración General
        </Title>
        <Regulations />
        <PaymentModes />
        <InfoChannels />
      </div>
    );
  }
}

export default (Settings);
