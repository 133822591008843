import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import Title from 'ui/Title';
import Button from '@material-ui/core/Button';
import Loading from '../../Loading';
import { canSelector } from '$redux/login/selectors';
import {
  informationRequest, set as setData, downloadEnrollment,
} from '$redux/student/actions';

import PersonalForm from './Forms/personalForm';
import ServiceForm from './Forms/serviceForm';
import { Wrapper } from './styles';

class StudentService extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { match: { params: { idStudent } } } = this.props;
    dispatch(informationRequest({ idStudent }));
  }

  onClick = idEnrollment => () => {
    const { dispatch } = this.props;
    dispatch(downloadEnrollment(idEnrollment));
  }

  set = (location, key, value) => {
    const { dispatch } = this.props;
    dispatch(setData(location, key, value));
  }

  render() {
    const {
      studentData, optionsData, userCan, loading,
    } = this.props;

    if (loading) return <Loading />;

    let disabled = true;
    if (!userCan('STUDENT_UPDATE')) {
      disabled = true;
    }

    const buttons = <Link to="/alumnos"><Button>Volver</Button></Link>;
    return (
      // TODO: remove key -> quick fix for undesired overridden fields
      <Wrapper key={studentData.idStudent}>
        <div>
          <Title>
            Alumnos (Estado de sus servicios)
          </Title>
          <PersonalForm
            title="Información
            personal"
            data={studentData.personalInfo}
            optionsData={optionsData}
            disabled={disabled}
            set={this.set}
          />
          <ServiceForm
            title="Estado de los servicios"
            accountInfo={studentData.accountInfo}
            optionsData={optionsData}
            idStudent={studentData.idStudent}
          />
          <div className="button-save">
            {buttons}
          </div>
        </div>
      </Wrapper>
    );
  }
}

StudentService.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  studentData: PropTypes.object,
  optionsData: PropTypes.object,
  userCan: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

StudentService.defaultProps = {
  studentData: {
    personalInfo: undefined,
    contactInfo: undefined,
    shippingInfo: undefined,
    billingInfo: undefined,
    academicInfo: undefined,
  },
  optionsData: undefined,
};

export default connect(
  state => ({
    studentData: state.student.studentData,
    optionsData: state.student.optionsData,
    userCan: canSelector(state),
    loading: state.student.loading,
  }),
)(StudentService);
