import dotenv from 'dotenv';
import {
  select,
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';
import api from 'linkia-api';
import { tokenSelector } from '$redux/login/selectors';


import { GET } from './action-types';
import {
  getSucceeded,
  getFailed,
} from './actions';

dotenv.config();
const { NODE_ENV: env } = process.env;

function* reqGetSplitPaymentTransactions(action) {
  const token = yield select(tokenSelector);
  const {
    bindingObject,
  } = action;
  try {
    const { splitPaymentTransactions, pages } = yield call(
      api.splitPaymentTransactions.get, { token, bindingObject },
    );
    yield put(getSucceeded({ splitPaymentTransactions, pages: Number(pages) }));
  } catch (e) {
    // eslint-disable-next-line no-console
    if (env === 'development' || !e.response || e.response.status !== 401) console.error(e);
    yield put(getFailed());
  }
}

export default [
  takeEvery(GET, reqGetSplitPaymentTransactions),
];
