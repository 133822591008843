import {
  select, call, put, takeEvery,
} from 'redux-saga/effects';
import api from 'linkia-api';
import {
  REQUEST_ORDER_RECOVERY_SEARCH,
  REQUEST_ORDER_RECOVERY_RECOVER,
} from './action-types';
import {
  requestOrderRecoverySearchSucceeded,
  requestOrderRecoverySearchFailed,
  requestOrderRecoveryRecoverSucceeded,
  requestOrderRecoveryRecoverFailed,
} from './actions';

import { tokenSelector } from '../login/selectors';

function* requestOrderRecoverySearch(action) {
  const token = yield select(tokenSelector);

  const {
    bindingObject,
  } = action;
  try {
    const data = yield call(api.orderStripe.orderStripeSearch, {
      bindingObject, token,
    });
    yield put(requestOrderRecoverySearchSucceeded(data));
  } catch (e) {
    yield put(requestOrderRecoverySearchFailed());
  }
}

function* requestOrderRecoveryRecover(action) {
  const token = yield select(tokenSelector);
  const {
    bindingObject,
  } = action;
  try {
    const data = yield call(api.orderStripe.orderStripeRestore, {
      bindingObject,
      token,
    });
    yield put(requestOrderRecoveryRecoverSucceeded(data));
  } catch (e) {
    yield put(requestOrderRecoveryRecoverFailed());
  }
}

export default [
  takeEvery(REQUEST_ORDER_RECOVERY_SEARCH, requestOrderRecoverySearch),
  takeEvery(REQUEST_ORDER_RECOVERY_RECOVER, requestOrderRecoveryRecover),
];
