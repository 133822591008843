import React from 'react';
import PropTypes from 'prop-types';
import SubTitle from 'ui/SubTitle';
import moment from 'moment';
import {
  Button,
  TextField,
} from '@material-ui/core';
import { Wrapper } from './Validation.styles';


class EnrollmentTransactionTransfer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toRemove: [],
    };
  }

  setTransfer = (index, field, value) => {
    const { data: { Transfers }, set } = this.props;
    set(
      'Transfers',
      Transfers.map((d, i) => (
        i === index ? { ...d, [field]: value } : d
      )),
    );
  }

  addTransfer = () => {
    const { data: { Transfers, idEnrollmentTransaction }, set } = this.props;
    set(
      'Transfers',
      [...Transfers, {
        idEnrollmentTransactionTransfer: null,
        idEnrollmentTransaction,
        ref: '00000',
        amount: 0.0,
        paymentDate: moment().format('YYYY-MM-DD'),
        toRemove: false,
      }],
    );
  }

  removeTransfer = (indexToRemove) => {
    const { data: { Transfers }, set } = this.props;
    const { toRemove } = this.state;

    const deleteTransfer = Transfers[indexToRemove];
    if (!deleteTransfer.idEnrollmentTransactionTransfer) {
      set(
        'Transfers',
        Transfers.filter((value, index) => index !== indexToRemove),
      );
    } else {
      toRemove.push(
        deleteTransfer.idEnrollmentTransactionTransfer,
      );
      this.setState({ toRemove });
      this.setTransfer(indexToRemove, 'toRemove', true);
    }
  }

  applyTransfers = () => {
    const { onSend } = this.props;

    onSend();
  }

  render() {
    const { data: { Transfers, paymentMethod, totalAmount } } = this.props;
    const { toRemove } = this.state;

    if (paymentMethod !== 'transfer') return ('');

    let transfersListComponent = (<div className="column is-four-fifths"><b>Todavía no hay ninguna transferencia registrada</b></div>);
    if (Transfers && Transfers.length > toRemove.length) {
      transfersListComponent = Transfers.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} style={{ display: toRemove.includes(item.idEnrollmentTransactionTransfer) ? 'none' : 'block' }}>
          <div className="columns" style={{ alignItems: 'flex-end', marginBottom: 12 }}>
            <div className="column is-narrow" style={{ fontSize: 14, marginBottom: 12, width: 200 }}>
              Transferencia #{index + 1}
            </div>
            <div className="column is-one-quarter">
              <TextField
                // eslint-disable-next-line react/no-array-index-key
                key={`item-ref-${index}`}
                label="Ref"
                fullWidth
                type="text"
                disabled={false}
                value={item.ref}
                onChange={e => this.setTransfer(index, 'ref', e.target.value)}
              />
            </div>
            <div className="column is-one-quarter">
              <TextField
                // eslint-disable-next-line react/no-array-index-key
                key={`item-paymentDate-${index}`}
                label="Fecha"
                fullWidth
                type="date"
                disabled={0}
                value={moment(item.paymentDate).format('YYYY-MM-DD')}
                onChange={e => this.setTransfer(index, 'paymentDate', e.target.value)}
              />
            </div>
            <div className="column is-one-quarter">
              <TextField
                // eslint-disable-next-line react/no-array-index-key
                key={`item-amount-${index}`}
                label="Cantidad"
                fullWidth
                type="number"
                disabled={0}
                value={item.amount}
                onChange={e => this.setTransfer(index, 'amount', e.target.value)}
              />
            </div>
            <div className="column is-one-quarter">
              <Button color="primary" onClick={() => this.removeTransfer(index)}>Quitar</Button>
            </div>
          </div>
        </div>
      ));
    }

    let totalPay = 0;
    if (Transfers && Transfers.length > 0) {
      Transfers.forEach((transfer) => {
        if (!transfer.toRemove || transfer.toRemove === false) {
          totalPay += parseFloat(transfer.amount, 10);
        }
      });
    }

    const transfersListComponentTotalPay = (
      <div className="column is-four-fifths" key="transfer-total">
        <div className="columns" style={{ alignItems: 'flex-end' }}>
          <div className="column is-one-quarter">
            &nbsp;
          </div>
          <div className="column is-one-quarter">
            &nbsp;
          </div>
          <div className="column is-one-quarter">
            &nbsp;
          </div>
          <div className="column is-narrow" style={{ fontSize: 14, width: 200, backgroundColor: '#cccccc' }}>
            Total Pagado
          </div>
          <div className="column is-one-quarter">
            { parseFloat(totalPay).toFixed(2) } €
          </div>
        </div>
      </div>
    );

    const transfersListComponentTotal = (
      <div className="column is-four-fifths" key="transfer-total">
        <div className="columns" style={{ alignItems: 'flex-end', marginBottom: 12 }}>
          <div className="column is-one-quarter">
            &nbsp;
          </div>
          <div className="column is-one-quarter">
            &nbsp;
          </div>
          <div className="column is-one-quarter">
            &nbsp;
          </div>
          <div className="column is-narrow" style={{ fontSize: 14, width: 200, backgroundColor: '#cccccc' }}>
            Total
          </div>
          <div className="column is-one-quarter">
            { parseFloat(totalAmount).toFixed(2) } €
          </div>
        </div>
      </div>
    );

    const remainTotal = parseFloat(totalAmount - totalPay).toFixed(2);
    let remainTotalMessage = (<b>Pagado</b>);
    if (remainTotal > 0) {
      remainTotalMessage = (<b>Falta</b>);
    }
    if (remainTotal < 0) {
      remainTotalMessage = (<b>Pagado(a devolver)</b>);
    }
    const transfersListComponentTotalRest = (
      <div className="column is-four-fifths" key="transfer-total">
        <div className="columns" style={{ alignItems: 'flex-end', marginBottom: 12 }}>
          <div className="column is-one-quarter">
            &nbsp;
          </div>
          <div className="column is-one-quarter">
            &nbsp;
          </div>
          <div className="column is-one-quarter">
            &nbsp;
          </div>
          <div className="column is-narrow" style={{ fontSize: 14, width: 200 }}>
            {
              remainTotalMessage
            }
          </div>
          <div className="column is-one-quarter">
            { remainTotal } €
          </div>
        </div>
      </div>
    );

    return (
      <Wrapper>
        <SubTitle>Transferencias</SubTitle>
        <div className="columns is-multiline" style={{ padding: 8 }}>
          {transfersListComponent}
          {transfersListComponentTotalPay}
          {transfersListComponentTotal}
          {transfersListComponentTotalRest}
          <div className="column is-four-fifths">
            <Button color="secondary" onClick={this.addTransfer}>Añadir Transferencia</Button>
          </div>
          <div className="column is-four-fifths">
            <Button color="primary" onClick={this.applyTransfers}>Actualizar</Button>
          </div>
        </div>
      </Wrapper>
    );
  }
}

EnrollmentTransactionTransfer.propTypes = {
  data: PropTypes.object,
  set: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
};

EnrollmentTransactionTransfer.defaultProps = {
  data: {},
};

export default EnrollmentTransactionTransfer;
