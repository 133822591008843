import {
  FILTERS_REQUEST,
  FILTERS_REQUEST_SUCCEEDED,
  FILTERS_REQUEST_FAILED,
  ROADMAPS_REQUEST,
  ROADMAPS_REQUEST_SUCCEEDED,
  ROADMAPS_REQUEST_FAILED,
  FILTERED_REQUEST,
  FILTERED_REQUEST_SUCCEEDED,
  FILTERED_REQUEST_FAILED,
  INFO_REQUEST,
  INFO_REQUEST_SUCCEEDED,
  INFO_REQUEST_FAILED,
  SET,
  UPDATE_REQUEST,
  UPDATE_REQUEST_SUCCEEDED,
  UPDATE_REQUEST_FAILED,
  CLEAN_FILTERS_REQUEST,
  ENROLLMENT_DOWNLOAD_REQUEST,
  ENROLLMENT_DOWNLOAD_REQUEST_SUCCEEDED,
  REQUEST_ACAD_EXPORT,
  REQUEST_ACAD_EXPORT_SUCCEEDED,
  REQUEST_ACAD_EXPORT_FAILED,
} from './action-types';


export const filtersRequest = () => ({ type: FILTERS_REQUEST });
export const filtersRequestSucceeded = filtersData => ({
  type: FILTERS_REQUEST_SUCCEEDED,
  filtersData,
});
export const filtersRequestFailed = () => ({ type: FILTERS_REQUEST_FAILED });

export const roadMapsRequest = ({ idTrainingPlan }) => ({ type: ROADMAPS_REQUEST, idTrainingPlan });
export const roadMapsRequestSucceeded = roadMapsData => ({
  type: ROADMAPS_REQUEST_SUCCEEDED,
  roadMapsData,
});
export const roadMapsRequestFailed = () => ({ type: ROADMAPS_REQUEST_FAILED });

export const filteredRequest = bindingObject => ({ type: FILTERED_REQUEST, bindingObject });
export const filteredRequestSucceeded = ({
  students: { data, pages },
}, bindingObject) => ({
  type: FILTERED_REQUEST_SUCCEEDED, data, pages, bindingObject,
});
export const filteredRequestFailed = () => ({ type: FILTERED_REQUEST_FAILED });

export const cleanFiltersRequest = () => ({ type: CLEAN_FILTERS_REQUEST });

export const informationRequest = ({ idStudent }) => ({ type: INFO_REQUEST, idStudent });
export const informationRequestSucceeded = ({ studentData, idAcademy, optionsData }) => ({
  type: INFO_REQUEST_SUCCEEDED, studentData, idAcademy, optionsData,
});
export const informationRequestFailed = () => ({ type: INFO_REQUEST_FAILED });

export const set = (location, key, value) => ({
  type: SET, location, key, value,
});

export const updateRequest = studentData => ({ type: UPDATE_REQUEST, studentData });
export const updateRequestSucceeded = success => ({
  type: UPDATE_REQUEST_SUCCEEDED, success,
});
export const updateRequestFailed = ({ error }) => ({ type: UPDATE_REQUEST_FAILED, error });

export const downloadEnrollment = idEnrollment => ({
  type: ENROLLMENT_DOWNLOAD_REQUEST, idEnrollment,
});
export const downloadEnrollmentSucceeded = (document, name) => (
  { type: ENROLLMENT_DOWNLOAD_REQUEST_SUCCEEDED, document, name });

export const requestExportAcademyInformed = () => ({ type: REQUEST_ACAD_EXPORT });
export const requestExportAcademyInformedSucceeded = data => ({
  type: REQUEST_ACAD_EXPORT_SUCCEEDED, data,
});
export const requestExportAcademyInformedFailed = () => ({ type: REQUEST_ACAD_EXPORT_FAILED });
