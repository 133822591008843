import React from 'react';
import { Button, Select, Checkbox } from '@material-ui/core';
import SubTitle from 'ui/SubTitle';
import theme from 'ui/theme';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Wrapper } from './styles';
import Loading from '$routes/Loading';
import {
  getPaymentModes,
  updatePaymentMode,
  setSelection,
  setPaymentInfo,
  setActiveInfo,
} from '$redux/paymentModes/actions';

class PaymentModes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    this.loadPaymentModes();
  }

  loadPaymentModes = () => {
    const { dispatch } = this.props;
    dispatch(getPaymentModes());
  }

  handleChange = () => (event) => {
    const { dispatch } = this.props;
    dispatch(setSelection(event.target.value));
  };

  handleChangeText = () => (event) => {
    const { dispatch } = this.props;
    dispatch(setPaymentInfo(event.target.value));
  };

  handleChangeVisible = () => {
    const { dispatch } = this.props;
    dispatch(setActiveInfo());
  };

  handleClick = (target) => {
    const { dispatch } = this.props;
    dispatch(updatePaymentMode(target));
  }

  onUpdate = () => {
    const {
      dispatch,
      selectedId,
      paymentInfo,
      active,
    } = this.props;
    const paymentMode = { idPaymentMode: selectedId, paymentInfo, active };
    dispatch(updatePaymentMode(paymentMode));
  }

  render() {
    const {
      loading,
      paymentModes,
      selectedId,
      paymentInfo,
      active,
      readOnly,
    } = this.props;
    if (loading) return <Loading />;

    const paymentModesList = paymentModes.map(
      item => (
        <option key={item.code} value={item.idPaymentMode}>
          {item.name}
        </option>
      ),
    );

    return (
      <Wrapper theme={theme}>
        <SubTitle>Modos de pago</SubTitle>
        <div className="columns">
          <div className="column is-two-fifths">
            <Select
              fullWidth
              native
              onChange={this.handleChange()}
              className="filter-item"
              defaultValue={selectedId}
            >
              {paymentModesList}
            </Select>
          </div>
          <div className="column" />
        </div>

        <div className="columns">
          <div className="column is-three-fifths">
            <textarea
              className="textarea has-fixed-size"
              rows="6"
              value={paymentInfo}
              onChange={this.handleChangeText()}
              disabled={readOnly}
            />
            Visible en la App de Matriculación:
            <Checkbox
              onChange={() => this.handleChangeVisible()}
              checked={active}
            />
          </div>
          <div className="column" />
        </div>

        <div className="columns">
          <div className="column is-two-fifths">
            <Button color="primary" onClick={this.onUpdate}>Guardar</Button>
          </div>
          <div className="column" />
        </div>
      </Wrapper>
    );
  }
}

PaymentModes.propTypes = {
  dispatch: PropTypes.func.isRequired,
  paymentModes: PropTypes.array,
  selectedId: PropTypes.string,
  paymentInfo: PropTypes.string,
  active: PropTypes.bool,
  readOnly: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

PaymentModes.defaultProps = {
  selectedId: '0',
  paymentInfo: null,
  active: false,
  paymentModes: [],
};


export default connect(
  state => ({
    paymentModes: state.paymentModes.paymentModes,
    selectedId: state.paymentModes.selectedId,
    paymentInfo: state.paymentModes.paymentInfo,
    active: state.paymentModes.active,
    readOnly: state.paymentModes.readOnly,
    loading: state.paymentModes.loading,
  }),
)(PaymentModes);
