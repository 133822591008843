import styled from 'styled-components';

export const Wrapper = styled.div`
  .alignItems {
    align-items: center;
  }
  .training-plan-activation {
    margin-top: 24px;
    margin-left: 0px;
  }
  .training-plan-code {
    margin-top: 0px;
    margin-bottom: 40px;
    margin-left: 2px;
  }
`;
