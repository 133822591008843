import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Line } from './Stretch.styles';

// eslint-disable-next-line
class Stretch extends React.Component {
  render() {
    const { type } = this.props;

    return (
      <Wrapper>
        <Line type={type} />
      </Wrapper>
    );
  }
}

Stretch.propTypes = {
  type: PropTypes.oneOf(['normal', 'error', 'success', 'warning', 'disabled']),
};

Stretch.defaultProps = {
  type: 'disabled',
};

export default Stretch;
