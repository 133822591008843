import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { FormControl, InputLabel } from '@material-ui/core';
import swal from 'sweetalert2';

const Uppy = require('@uppy/core');
const FileInput = require('@uppy/file-input');
const XHRUpload = require('@uppy/xhr-upload');

class Upload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {
      token, fieldName, endpoint, target, reload,
    } = this.props;
    const uppy = new Uppy({
      autoProceed: true,
      restrictions: {
        maxFileSize: 10000000, // 10 MB
        maxNumberOfFiles: 1,
        allowedFileTypes: ['.pdf', '.docx', '.rtf', '.jpg', '.jpeg', '.png', '.doc', '.PDF', '.DOCX', '.RTF', '.JPG', '.JPEG', '.PNG', '.DOC'],
      },
      onBeforeFileAdded: (currentFile) => {
        uppy.use(XHRUpload, {
          endpoint,
          headers: { authorization: `Bearer ${token}` },
          formData: true,
          fieldName,
        });
        if (currentFile.data.size > 10000000) {
          swal('Error', 'El archivo no puede tener más de 10MB', 'error');
        }
      },
    });
    uppy.use(FileInput, {
      target: `.${target}`,
      pretty: true,
      inputName: fieldName,
      locale: {
        strings: {
          chooseFiles: 'Actualizar',
        },
      },
    });

    uppy.on('file-added', (file) => {
      document.querySelector(`#${target}`).value = file.name;
      document.querySelector(`#${target}`).classList.add('is-bold');
    });
    uppy.on('complete', (result) => {
      if (result.failed.length === 0) {
        reload();
        swal('¡Hecho!', `${result.successful[0].name} se ha enviado satisfactoriamente`, 'success');
      } else {
        swal('Oops! Error inesperado');
      }
    });
  }

  handleClick = (e) => {
    e.preventDefault();
    const { onClick, target } = this.props;
    onClick(target);
  }

  render() {
    const {
      endpoint, value, target, label,
    } = this.props;
    return (
      <div className="upload-cols columns">
        <div className="upload-label column is-1">
          <InputLabel>{label}</InputLabel>
        </div>
        <div className="upload-input column is-10">
          <div className="upload-step">
            <TextField
              value={value}
              className="column is-10"
              id={target}
              placeholder="Seleccionar archivo"
              disabled
              onClick={this.handleClick}
            />
            <FormControl className={target} action={endpoint} />
          </div>
        </div>
      </div>
    );
  }
}

Upload.propTypes = {
  endpoint: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  token: PropTypes.string,
  value: PropTypes.string,
  reload: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

Upload.defaultProps = {
  value: '',
  token: '',
};

// details components
export default Upload;
