import { createMuiTheme } from '@material-ui/core/styles';
import uiTheme from './theme';

const themeFilters = createMuiTheme({
  // custom colors via material's color tool:
  // https://goo.gl/T92kNk
  palette: {
    background: {
      default: '#fff !important',
    },
    primary: uiTheme.primary,
    secondary: uiTheme.secondary,
  },

  // other global properties
  shadows: Array(25).fill('none'),

  // typography variants mui error
  typography: {
    useNextVariants: true,
  },
});

// SvgIcons
themeFilters.overrides.MuiSvgIcon = {
  root: {
    '&.tick': {
      width: '18px',
      height: '18px',
      color: '#9c9c9c',
      background: '#514641',
    },
    '&.button-close-icon': {
      width: '12px',
      height: '20px',
      float: 'right',
      marginLeft: '10px',
    },
  },
};


// TextField
themeFilters.overrides.MuiInput = {
  root: {
    '&.filter-item': {
      height: '40px',
    },
    backgroundColor: '#ECECEC',
    border: 'none',
    boxShadow: 'none',
    fontSize: '11px',
  },
  input: {
    paddingLeft: '12px',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  underline: {
    '&:before': {
      borderBottom: '1px solid transparent',
    },
    '&:after': {
      borderBottom: '1px solid transparent',
    },
    '&:hover': {
      '&:before': {
        borderBottom: '1px solid transparent !important',
      },
    },
  },
};

themeFilters.overrides.MuiInputBase = {
  inputType: {
    fontSize: '12px',
    height: '20px',
  },
};

// Select
themeFilters.overrides.MuiSelect = {
  root: {
    backgroundColor: '#ececec',
    height: '100%',
  },
  select: {
    paddingLeft: '12px',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingRight: '0px',
    '&:focus': {
      background: '#E9E9E9',
    },
    height: '12px',
  },
};

// Button
themeFilters.overrides.MuiButton = {
  root: {
    color: '#6896c8',
    padding: 'inherit',
  },
};

// Paper (Draw body)
themeFilters.overrides.MuiPaper = {
  root: {
    backgroundColor: '#1D1410',
    width: '328px',
    height: '100%',
    overflowY: 'hidden!important',
    discountsFormBody: {
      background: '#666666',
    },
  },
};

// Typography
themeFilters.overrides.MuiTypography = {
  root: {
    fontWeight: '100',
  },
  button: {
    fontWeight: '400',
    fontSize: '14px',
  },
  body1: {
    fontSize: '11px',
    fontWeight: '300',
  },
};

export default themeFilters;
