import {
  REQUEST_UTM_STUDENT_FILTERS,
  REQUEST_UTM_STUDENT_FILTERS_SUCCEEDED,
  REQUEST_UTM_STUDENT_FILTERS_FAILED,
} from './action-types';

export const reqUtmStudentFilters = (bindingObject, idStudent) => ({
  type: REQUEST_UTM_STUDENT_FILTERS,
  bindingObject,
  idStudent,
});
export const reqUtmStudentFiltersSucceeded = ({
  utmStudents: { data, pages },
}, bindingObject) => ({
  type: REQUEST_UTM_STUDENT_FILTERS_SUCCEEDED,
  data,
  pages,
  bindingObject,
});
export const reqUtmStudentFiltersFailed = () => ({
  type: REQUEST_UTM_STUDENT_FILTERS_FAILED,
});
