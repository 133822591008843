import { makeHash } from '$utils/hashing';
import saveAs from 'file-saver';
import {
  FILTERS_REQUEST,
  FILTERS_REQUEST_SUCCEEDED,
  FILTERS_REQUEST_FAILED,
  ROADMAPS_REQUEST,
  ROADMAPS_REQUEST_SUCCEEDED,
  ROADMAPS_REQUEST_FAILED,
  FILTERED_REQUEST,
  FILTERED_REQUEST_SUCCEEDED,
  FILTERED_REQUEST_FAILED,
  INFO_REQUEST,
  INFO_REQUEST_SUCCEEDED,
  INFO_REQUEST_FAILED,
  SET,
  UPDATE_REQUEST,
  UPDATE_REQUEST_SUCCEEDED,
  UPDATE_REQUEST_FAILED,
  CLEAN_FILTERS_REQUEST,
  ENROLLMENT_DOWNLOAD_REQUEST_SUCCEEDED,
  REQUEST_ACAD_EXPORT,
  REQUEST_ACAD_EXPORT_SUCCEEDED,
  REQUEST_ACAD_EXPORT_FAILED,
} from './action-types';
import utils from '../utils';

const initialState = {
  token: null,
  user: null,
  loading: false,
  studentData: undefined,
};

const studentReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case FILTERS_REQUEST: {
      return {
        ...state,
        filteredData: undefined,
        loading: true,
      };
    }
    case FILTERS_REQUEST_SUCCEEDED: {
      const { filtersData } = action;
      return {
        ...state,
        filtersData,
        loading: false,
      };
    }
    case FILTERS_REQUEST_FAILED: {
      utils.dialog({
        type: 'error',
        title: 'Error de carga',
        message: 'No se ha podido traer la información referente a los filtros',
      });
      return {
        ...state,
        loading: false,
      };
    }

    case CLEAN_FILTERS_REQUEST: {
      return {
        ...state,
        filters: {},
      };
    }

    case ROADMAPS_REQUEST: {
      return {
        ...state,
        // loading: true,
      };
    }
    case ROADMAPS_REQUEST_SUCCEEDED: {
      const { roadMapsData } = action;
      return {
        ...state,
        roadMapsData,
        loading: false,
      };
    }
    case ROADMAPS_REQUEST_FAILED: {
      utils.dialog({
        type: 'error',
        title: 'Error de carga',
        message: 'No se ha podido traer la información referente a los filtros',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case FILTERED_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case FILTERED_REQUEST_SUCCEEDED: {
      const { data, pages, bindingObject: { filters } } = action;
      return {
        ...state,
        data,
        filters,
        pages,
        loading: false,
      };
    }
    case FILTERED_REQUEST_FAILED: {
      utils.dialog({
        type: 'error',
        title: 'Error de carga',
        message: 'No se ha podido traer la información filtrada de los estudiantes',
      });
      return {
        ...state,
        loading: false,
      };
    }

    case REQUEST_ACAD_EXPORT:
    case INFO_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case INFO_REQUEST_SUCCEEDED: {
      const { studentData, idAcademy, optionsData } = action;
      return {
        ...state,
        studentData: {
          ...studentData,
          accountInfo: {
            ...studentData.accountInfo,
            oldPassword: studentData.accountInfo.password,
          },
        },
        idAcademy,
        optionsData,
        loading: false,
      };
    }
    case INFO_REQUEST_FAILED: {
      utils.dialog({
        type: 'error',
        title: 'Error de carga',
        message: 'No se ha podido traer la información del estudiante',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case SET: {
      const { location, key, value } = action;
      if (key === 'password') {
        return {
          ...state,
          studentData: {
            ...state.studentData,
            [location]: {
              ...state.studentData[location],
              oldPassword: state.studentData[location].password,
              [key]: makeHash(value),
            },
          },
        };
      }
      return {
        ...state,
        studentData: {
          ...state.studentData,
          [location]: {
            ...state.studentData[location],
            [key]: value,
          },
        },
      };
    }

    case ENROLLMENT_DOWNLOAD_REQUEST_SUCCEEDED: {
      const { document, name } = action;
      saveAs(document, name);
      return {
        ...state,
        loadingDownload: false,
      };
    }

    case UPDATE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_REQUEST_SUCCEEDED: {
      utils.dialog({
        title: '¡Perfecto!',
        message: 'La petición se ha procesado con éxito',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case UPDATE_REQUEST_FAILED: {
      const { error } = action;
      if (error.response.status === 422) {
        utils.dialog({
          type: 'error',
          title: 'Error de validación',
          message: `${error.response.data.message}`,
        });
      } else {
        utils.dialog({
          type: 'error',
          title: 'Error de carga',
          message: 'No se ha podido actualizar la información del estudiante',
        });
      }
      return {
        ...state,
        loading: false,
      };
    }

    case REQUEST_ACAD_EXPORT_SUCCEEDED: {
      const { data } = action;
      const encodedUri = escape(data);
      const link = document.createElement('a');
      const file = 'estudiantes_academia_info.csv';
      link.setAttribute('href', `data:text/csv;charset=utf-8,%EF%BB%BF${encodedUri}`);
      link.setAttribute('download', file);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return {
        ...state,
        loading: false,
      };
    }
    case REQUEST_ACAD_EXPORT_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error de exportación',
        message: 'No se han podido exportar los datos',
      });
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
};

export default studentReducer;
