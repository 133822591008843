/* eslint-disable react/no-unused-state */
import React from 'react';

import {
  Button,
} from '@material-ui/core';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SubTitle from 'ui/SubTitle';

import { Wrapper } from './styles';

import Loading from '$routes/Loading';
import Failed from '$routes/Failed';

import BasicForm from './BasicForm';
import swal from 'sweetalert2';

import {
  list as listCourses,
} from '$redux/courses/actions';

import {
  reqUtmFormCreateLead,
} from '$redux/utm-forms/actions';

// eslint-disable-next-line
class Form extends React.Component {
  constructor(props) {
    super(props);
    const { dispatch } = this.props;
    this.state = {
      origin: 0,
      originCustom: '',
      name: '',
      postalCode: '',
      email: '',
      phone: '',
      courseInterestedIn: 0,
      ads: false,
      privacity: false,
    };

    dispatch(listCourses());
  }

  onCreate = () => {
    const { dispatch } = this.props;

    const {
      origin, originCustom, name, postalCode, email, phone, courseInterestedIn, ads, privacity,
    } = this.state;

    const validations = [
      origin === 0,
      !name,
      !postalCode,
      !email,
      !phone,
      courseInterestedIn === 0,
    ];

    const validationOrigenCustom = !((origin === 'origen-otros' && originCustom === ''));

    if (!validationOrigenCustom || validations.some(validation => validation)) {
      swal({
        type: 'warning',
        title: 'Error de validación',
        text: 'Todos los campos son obliagatorios',
      });

      return false;
    }

    dispatch(reqUtmFormCreateLead(
      {
        origin,
        originCustom,
        name,
        postalCode,
        email,
        phone,
        courseInterestedIn,
        ads,
        privacity,
      },
    ));

    return true;
  }

  setValue = name => (event) => {
    let { value } = event.target;

    const { type } = event.target;
    if (type === 'checkbox') {
      value = event.target.checked;
    }

    this.setState({
      [name]: value,
    });
  }

  render() {
    const {
      courses,
      loading,
    } = this.props;

    if (loading) return <Loading />;
    if (!courses) return <Failed />;

    return (
      <Wrapper>
        <SubTitle>Información del centro</SubTitle>
        <BasicForm data={this.state} setValue={this.setValue} />
        <Button color="secondary" onClick={this.onCreate}>Crear</Button>
      </Wrapper>
    );
  }
}

Form.propTypes = {
  dispatch: PropTypes.func.isRequired,
  courses: PropTypes.array,
  loading: PropTypes.bool.isRequired,
};

Form.defaultProps = {
  courses: null,
};

export default connect(
  state => ({
    courses: state.courses.list,
    loading: state.courses.loading,
  }),
)(Form);
