import {
  REQUEST,
  REQUEST_SUCCEEDED,
  REQUEST_FAILED,
  FILTERS_REQUEST,
  FILTERS_REQUEST_SUCCEEDED,
  FILTERS_REQUEST_FAILED,
  CHANGE_LEAD_PASSWORD,
  CHANGE_LEAD_PASSWORD_SUCCEEDED,
  CHANGE_LEAD_PASSWORD_FAILED,
  UPDATE_USER,
  REQUEST_PAYMENT_LOG,
  REQUEST_PAYMENT_LOG_SUCCEEDED,
  REQUEST_PAYMENT_LOG_FAILED,
} from './action-types';
import utils from '../utils';

const initialState = {
  data: [],
  loading: false,
};

const candidatesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case UPDATE_USER: {
      return {
        ...state,
      };
    }
    case CHANGE_LEAD_PASSWORD:
    case REQUEST_PAYMENT_LOG:
    case REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case CHANGE_LEAD_PASSWORD_SUCCEEDED: {
      utils.dialog({
        type: 'succes',
        title: 'La contraseña se ha cambiado correctamente',
        message: 'La nueva contraseña se ha enviado al usuario seleccionado',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case REQUEST_SUCCEEDED:
    case REQUEST_PAYMENT_LOG_SUCCEEDED: {
      const { data, pages } = action;
      return {
        ...state,
        data,
        pages,
        loading: false,
      };
    }
    case CHANGE_LEAD_PASSWORD_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error inesperado',
        message: 'No se ha podido generar la nueva contraseña. Comprobar si el email es correcto.',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case REQUEST_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error inesperado',
        message: 'No se ha podido cargar las matrículas',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case REQUEST_PAYMENT_LOG_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error inesperado',
        message: 'No se ha podido cargar los logs de pago',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case FILTERS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case FILTERS_REQUEST_SUCCEEDED: {
      const { filtersData } = action;
      return {
        ...state,
        filtersData,
        loading: false,
      };
    }
    case FILTERS_REQUEST_FAILED: {
      utils.dialog({
        type: 'error',
        title: 'Error de carga',
        message: 'No se ha podido traer la información referente a los filtros',
      });
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default candidatesReducer;
