export const LIST_STAFF = 'STAFF_LIST_REQUEST';
export const LIST_STAFF_SUCCEEDED = 'STAFF_LIST_SUCCEEDED';
export const LIST_STAFF_FAILED = 'STAFF_LIST_FAILED';
export const GET_STAFF_PROPS = 'STAFF_GET_PROPS_REQUEST';
export const GET_STAFF_PROPS_SUCCEEDED = 'STAFF_GET_PROPS_SUCCEEDED';
export const GET_STAFF_PROPS_FAILED = 'STAFF_GET_PROPS_FAILED';
export const GET_STAFF = 'STAFF_GET_REQUEST';
export const GET_STAFF_SUCCEEDED = 'STAFF_GET_SUCCEEDED';
export const GET_STAFF_FAILED = 'STAFF_GET_FAILED';
export const UPDATE_STAFF = 'STAFF_UPDATE_REQUEST';
export const UPDATE_STAFF_SUCCEEDED = 'STAFF_UPDATE_SUCCEEDED';
export const UPDATE_STAFF_FAILED = 'STAFF_UPDATE_FAILED';
export const REMOVE_STAFF = 'STAFF_REMOVE';
export const REMOVE_STAFF_SUCCEEDED = 'STAFF_REMOVE_SUCCEEDED';
export const REMOVE_STAFF_FAILED = 'STAFF_REMOVE_FAILED';
export const CREATE_STAFF = 'STAFF_CREATE';
export const CREATE_STAFF_SUCCEEDED = 'STAFF_CREATE_SUCCEEDED';
export const CREATE_STAFF_FAILED = 'STAFF_CREATE_FAILED';
export const SET_STAFF_VALUE = 'STAFF_SET';
export const RESET_STAFF_VALUE = 'STAFF_RESET';
export const CHANGE_STAFF_PASSWORD = 'STAFF_CHANGE_PASSWORD';
export const CHANGE_STAFF_PASSWORD_SUCCEEDED = 'STAFF_CHANGE_PASSWORD_SUCCEEDED';
export const CHANGE_STAFF_PASSWORD_FAILED = 'STAFF_CHANGE_PASSWORD_FAILED';
