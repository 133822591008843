import React from 'react';
import PropTypes from 'prop-types';

import {
  TextField,
  Button,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
} from '@material-ui/core';

import { Wrapper } from './Editor.styles';

class Editor extends React.Component {
  state = {}

  onSend = () => {
    const { onSend } = this.props;
    onSend();
  }

  render() {
    const {
      action,
      data: {
        stage,
        semester,
        hoursLimit,
        courseYear,
        order,
      },
      set,
      onClose,
    } = this.props;

    return (
      <Wrapper>
        <div className="columns is-bottom-aligned">
          <div className="column is-3">
            <TextField
              label="Nombre"
              fullWidth
              value={stage}
              onChange={e => set('stage', e.target.value)}
            />
          </div>
          <div className="column is-2">
            <TextField
              label="Límite horas"
              fullWidth
              type="number"
              value={hoursLimit}
              onChange={e => set('hoursLimit', e.target.value)}
            />
          </div>
          <div className="column is-2">
            <FormControl fullWidth>
              <InputLabel>Semestre</InputLabel>
              <Select
                value={semester}
                onChange={e => set('semester', e.target.value)}
              >
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="2">2</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="column is-2">
            <FormControl fullWidth>
              <InputLabel>Año curso</InputLabel>
              <Select
                value={courseYear}
                onChange={e => set('courseYear', e.target.value)}
              >
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="column is-1">
            <TextField
              label="Orden"
              fullWidth
              type="number"
              value={order}
              onChange={e => set('order', e.target.value)}
            />
          </div>
          <div className="column is-2 form-actions">
            {
              action === 'edit' ? (
                <Button color="primary" onClick={this.onSend}>Guardar</Button>
              ) : (
                <Button color="secondary" onClick={this.onSend}>Crear</Button>
              )
            }
            <Button className="close-button" onClick={onClose}>X</Button>
          </div>
        </div>
      </Wrapper>
    );
  }
}

Editor.propTypes = {
  action: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  set: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Editor;
