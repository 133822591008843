import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import Title from 'ui/Title';
import Button from '@material-ui/core/Button';
import SubTitle from '../../../ui/SubTitle';
import Loading from '../../Loading';
import { canSelector } from '$redux/login/selectors';
import {
  informationRequest, set as setData, updateRequest, downloadEnrollment,
} from '$redux/student/actions';

import PersonalForm from './Forms/personalForm';
import BillingForm from './Forms/billingForm';
import ShippingForm from './Forms/shippingForm';
import ContactForm from './Forms/contactForm';
import AcademicForm from './Forms/academicForm';
import BankInfoForm from './Forms/bankInfoForm';
import PasswordForm from './Forms/passwordForm';
import OtherInfo from './Forms/otherInfo';
import EnrollmentInfo from './Grids/enrollmentsGrid';

import UtmStudent from '../../UtmStudents/Grid';

import { Wrapper } from './styles';

class StudentEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledButton: false,
      idStudent: -1,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { match: { params: { idStudent } } } = this.props;
    this.setState({
      idStudent,
    });
    dispatch(informationRequest({ idStudent }));
  }

  onClick = idEnrollment => () => {
    const { dispatch } = this.props;
    dispatch(downloadEnrollment(idEnrollment));
  }

  onUpdate = studentDataToUpdate => () => {
    const { dispatch } = this.props;
    const {
      password,
      email,
      oldPassword,
      secondaryEmail,
      collaboratingCenterStudentEmail,
      idCollaboratingCenter,
    } = studentDataToUpdate.accountInfo;
    let passwordUpdate = oldPassword;
    if (oldPassword !== password) {
      passwordUpdate = password;
    }
    const studentData = {
      ...studentDataToUpdate,
      accountInfo: {
        password: passwordUpdate,
        email,
        secondaryEmail,
        collaboratingCenterStudentEmail,
        idCollaboratingCenter,
      },
    };
    dispatch(updateRequest(studentData));
  }

  set = (location, key, value) => {
    const { dispatch } = this.props;
    dispatch(setData(location, key, value));
  }

  disableButton = confirmation => this.setState({ disabledButton: confirmation });

  render() {
    const {
      studentData, optionsData, userCan, loading,
    } = this.props;
    const { disabledButton, idStudent } = this.state;

    if (idStudent === -1) return '';

    if (loading) return <Loading />;

    let disabled = false;
    if (!userCan('STUDENT_UPDATE')) {
      disabled = true;
    }

    let buttons;
    if (userCan('STUDENT_UPDATE')) {
      buttons = (
        <div>
          <Button
            color="primary"
            onClick={this.onUpdate(studentData)}
            disabled={disabledButton}
          >
            Guardar cambios
          </Button>
          <Link to="/alumnos"><Button>Volver</Button></Link>
        </div>
      );
    } else {
      buttons = <Link to="/alumnos"><Button>Volver</Button></Link>;
    }

    return (
      // TODO: remove key -> quick fix for undesired overridden fields
      <Wrapper key={studentData.idStudent}>
        <div>
          <Title>
            Alumnos
          </Title>
          <PersonalForm
            title="Información
            personal"
            data={studentData.personalInfo}
            optionsData={optionsData}
            disabled={disabled}
            set={this.set}
          />
          <ContactForm
            title="Información de contacto"
            data={studentData.contactInfo}
            optionsData={optionsData}
            disabled={disabled}
            set={this.set}
          />
          <ShippingForm
            title="Información de envío de material"
            data={studentData.shippingInfo}
            optionsData={optionsData}
            disabled={disabled}
            set={this.set}
          />
          <BillingForm
            title="Información de datos de facturación"
            data={studentData.billingInfo}
            optionsData={optionsData}
            disabled={disabled}
            set={this.set}
          />
          <AcademicForm
            title="Datos académicos"
            data={studentData.academicInfo}
            optionsData={optionsData}
            disabled={disabled}
            set={this.set}
          />
          <BankInfoForm
            title="Datos bancarios"
            data={studentData.bankInfo}
            disabled={disabled}
            set={this.set}
          />
          <PasswordForm
            title="Email y Contraseña"
            data={studentData.accountInfo}
            disabled={disabled}
            disableButton={this.disableButton}
            set={this.set}
          />
          <OtherInfo
            title="Otros"
            data={studentData.otherInfo}
            disableButton={this.disableButton}
            set={this.set}
          />
          <EnrollmentInfo
            title="Matrículas"
            data={studentData.enrollmentsInfo}
            onClick={this.onClick}
          />
          <div className="student-enrollments">
            <SubTitle>UTMs</SubTitle>
            <UtmStudent
              idStudent={idStudent}
            />
          </div>
          <div className="button-save">
            {buttons}
          </div>
        </div>
      </Wrapper>
    );
  }
}

StudentEdit.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  studentData: PropTypes.object,
  optionsData: PropTypes.object,
  userCan: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

StudentEdit.defaultProps = {
  studentData: {
    personalInfo: undefined,
    contactInfo: undefined,
    shippingInfo: undefined,
    billingInfo: undefined,
    academicInfo: undefined,
  },
  optionsData: undefined,
};

export default connect(
  state => ({
    studentData: state.student.studentData,
    optionsData: state.student.optionsData,
    userCan: canSelector(state),
    loading: state.student.loading,
  }),
)(StudentEdit);
