import {
  REGULATIONS_REQUEST,
  REGULATIONS_REQUEST_SUCCEEDED,
  REGULATIONS_REQUEST_FAILED,
  REGULATIONS_SHOW_REQUEST_SUCCEEDED,
} from './action-types';
import utils from '../utils';

const initialState = {
  loading: false,
  economic: '',
  academic: '',
  endpoint: '',
  token: null,
};

const regulationsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case REGULATIONS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case REGULATIONS_REQUEST_SUCCEEDED: {
      const {
        token, endpoint, economic, academic,
      } = action.result;
      return {
        ...state,
        economic,
        academic,
        token,
        endpoint,
        loading: false,
      };
    }
    case REGULATIONS_SHOW_REQUEST_SUCCEEDED: {
      const { url } = action;
      window.open(url);
      return {
        ...state,
        loading: false,
      };
    }
    case REGULATIONS_REQUEST_FAILED: {
      const { error } = action;

      let message = 'La petición no se ha completado con éxito.';

      if (error.response && error.response.data && error.response.data.message) {
        ({ message } = error.response.data);
      }
      utils.dialog({
        type: 'error',
        title: 'Error inesperado',
        message,
      });
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default regulationsReducer;
