export const LIST = 'MESSAGES_LIST';
export const LIST_SUCCEEDED = 'MESSAGES_LIST_SUCCEEDED';
export const LIST_FAILED = 'MESSAGES_LIST_FAILED';
export const GET = 'MESSAGES_GET';
export const GET_SUCCEEDED = 'MESSAGES_GET_SUCCEEDED';
export const GET_FAILED = 'MESSAGES_GET_FAILED';
export const SEND = 'MESSAGES_SEND';
export const SEND_SUCCEEDED = 'MESSAGES_SEND_SUCCEEDED';
export const SEND_FAILED = 'MESSAGES_SEND_FAILED';
export const READ_THREAD = 'MESSAGES_READ_THREAD';
export const UNREAD_MESSAGE = 'MESSAGES_UNREAD_MESSAGE';
