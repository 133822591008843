import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { ModuleWrapper } from './RoadMap.styles';
import RoadMapUnitRow from './RoadMapUnitRow';


class RoadMapModuleRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onCheckUnit = (idUnit, checked) => {
    const { checkunit, semester, module: { idModule } } = this.props;
    checkunit(idModule, idUnit, checked, semester);
  }

  render() {
    const {
      checked, itemText, module, checkmodule, semester, disabled,
    } = this.props;
    let unitList;
    // Has subitems
    if (module.Units.length > 0) {
      unitList = module.Units.map(
        unit => (
          <RoadMapUnitRow
            key={unit.idUnit}
            disabled={disabled}
            checked={
              unit.checked && checked
            }
            idUnit={unit.idUnit}
            checkunit={this.onCheckUnit}
            itemText={unit.code.toUpperCase().concat(': ').concat(unit.name)}
          />
        ),
      );
    }

    return (
      <div className="column is-half">
        <ModuleWrapper>
          <div className="columns is-vcentered">
            <div className="column is-1">
              <FormControlLabel
                disabled={disabled}
                control={<Checkbox color="primary" />}
                checked={checked}
                onChange={e => checkmodule(module, semester, e.target.checked)}
              />
            </div>
            <div className="column">
              {itemText}
            </div>
          </div>
        </ModuleWrapper>
        {unitList}
      </div>
    );
  }
}

RoadMapModuleRow.propTypes = {
  checkmodule: PropTypes.func.isRequired,
  checkunit: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  itemText: PropTypes.string.isRequired,
  module: PropTypes.object.isRequired,
  semester: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default RoadMapModuleRow;
