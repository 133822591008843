import styled from 'styled-components';
import colorTools from 'color';

export const Wrapper = styled.div`
  .export-data {
    margin-top: 20px;
    float: right;
    button {
      color: #6896c8;
    }
  }
  .align-right {
    text-align: right;
    padding: 0;
  }
  .export {
    color: #6896c8;
    font-size: 12px;
  }
`;

export const AlexiaLoading = styled.div`
  display: flex;
  align-items: center;

  font-size: 14px;

  .alexia-label {
    margin-left: 20px;
    font-weight: 700;
    color: #777;
  }
`;

const ballSize = 10;
export const SmallBall = styled.div`
  margin-top: 5px;
  margin-left: 7px;
  height: ${ballSize}px;
  width: ${ballSize}px;
  border-radius: ${ballSize}px;
  background: ${({ color }) => color};

  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  transition-duration: 200ms;
  transition-timing-function: ease-out;
  transition-property: transform, background;
  :hover {
    transform: ${({ disabled }) => (disabled ? 'none' : 'scale(1.5)')};
    background: ${({ color, disabled }) => (disabled
    ? color : colorTools(color).darken(0.15).rgb().string())};
  }
`;

export const SpinnerWrapper = styled.div`
  margin-top: 5px;
  margin-left: 7px;
  height: ${ballSize}px;
  width: ${ballSize}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
