import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';

import {
  TextField,
  FormControlLabel,
  Switch,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import DateRangePicker from 'ui/DateRangePicker';

import { Wrapper } from './Form.styles';


moment.locale('es');

class Form extends React.Component {
  state = {
    dates: {
      focusedInput: null,
    },
  }

  setDates = ({ startDate, endDate }) => {
    const { set } = this.props;
    set('startDate', startDate);
    set('endDate', endDate);
  }

  setType = (type) => {
    const { set } = this.props;
    set('type', type);
    if (type !== 'H') set('hours', 0);
  }

  render() {
    const {
      action,
      data: {
        reference,
        code,
        name,
        discountValue,
        startDate,
        endDate,
        uses,
        maxUses,
        isCumulative,
        active,
        selectedTrainingPlan,
        calcType,
        reenroll,
        minValue,
      },
      ui: {
        trainingPlans,
      },
      set,
      onSend,
    } = this.props;

    const { dates } = this.state;

    return (
      <Wrapper>
        <div className="columns is-multiline">
          <div className="column is-one-quarter">
            <TextField
              label="Nombre"
              fullWidth
              value={name}
              onChange={e => set('name', e.target.value)}
            />
          </div>
          <div className="column is-one-quarter">
            <TextField
              label="Referencia"
              fullWidth
              value={reference}
              onChange={e => set('reference', e.target.value)}
            />
          </div>
          <div className="column is-one-quarter">
            <TextField
              label="Código usable"
              fullWidth
              value={code}
              onChange={e => set('code', e.target.value)}
            />
          </div>
          <div className="column is-one-quarter">
            <div className="columns">
              <div className="column is-half">
                <TextField
                  fullWidth
                  label="Valor descuento"
                  type="number"
                  value={discountValue}
                  inputProps={{ style: { textAlign: 'right' } }}
                  onChange={e => set('discountValue', e.target.value)}
                />
              </div>
              <div className="column is-half">
                <FormControl fullWidth>
                  <InputLabel>Tipo de cálculo</InputLabel>
                  <Select
                    value={calcType || 'P'}
                    onChange={e => set('calcType', e.target.value)}
                  >
                    <MenuItem value="P">%</MenuItem>
                    <MenuItem value="F">€</MenuItem>
                  </Select>
                </FormControl>
              </div>

            </div>
          </div>
          <div className="column is-two-quarters">
            <DateRangePicker
              label="Rango de fechas"
              startDate={startDate ? moment(startDate) : null}
              startDateId="start_date"
              endDate={endDate ? moment(endDate) : null}
              endDateId="end_date"
              onDatesChange={newDates => this.setDates(newDates)}
              focusedInput={dates.focusedInput}
              onFocusChange={focusedInput => this.setState({ dates: { focusedInput } })}
            />
          </div>
          <div className="column is-half">
            <div className="columns">
              <div className="column is-one-quarter">
                <TextField
                  label="Usos"
                  fullWidth
                  type="number"
                  value={uses}
                  disabled
                />
              </div>
              <div className="column is-one-quarter">
                <TextField
                  label="Máx. usos"
                  fullWidth
                  type="number"
                  value={maxUses}
                  onChange={e => set('maxUses', e.target.value)}
                />
              </div>
              <div className="column is-two-quarter">
                <TextField
                  fullWidth
                  label="Importe mínimo de matrícula"
                  type="number"
                  value={minValue}
                  inputProps={{ style: { textAlign: 'right' } }}
                  onChange={e => set('minValue', e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column is-half">
            <FormControl fullWidth>
              <InputLabel>Curso con metodología</InputLabel>
              <Select
                value={selectedTrainingPlan || 'none'}
                onChange={e => set('selectedTrainingPlan', e.target.value)}
              >
                <MenuItem value="none">Cualquiera</MenuItem>
                {trainingPlans.map(({
                  idTrainingPlan,
                  courseCode,
                  courseName,
                  methodologyName,
                }) => (
                  <MenuItem key={idTrainingPlan} value={idTrainingPlan}>
                    {courseCode} - {courseName} - {methodologyName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="column is-two-quarter-fullhd is-two-quarters-desktop check-fields">
            <FormControlLabel
              control={<Switch color="primary" />}
              label="Rematrícula"
              checked={!!(reenroll)}
              onChange={e => set('reenroll', e.target.checked)}
            />
            <FormControlLabel
              control={<Switch color="primary" />}
              label="Acumulativo"
              checked={!!(isCumulative)}
              onChange={e => set('isCumulative', e.target.checked)}
            />
            <FormControlLabel
              control={<Switch color="primary" />}
              label="Activo"
              checked={!!(active)}
              onChange={e => set('active', e.target.checked)}
            />
          </div>
        </div>
        <div className="form-actions">
          {
            action === 'edit' ? (
              <Button color="primary" onClick={onSend}>Actualizar</Button>
            ) : (
              <Button color="secondary" onClick={onSend}>Crear</Button>
            )
          }
          <Link to="/codigos-promocionales"><Button>Volver</Button></Link>
        </div>
      </Wrapper>
    );
  }
}

Form.propTypes = {
  action: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  set: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
};

export default Form;
