import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import theme from 'ui/theme';
import {
  TextField, Button,
} from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import themeFilters from 'ui/muiFiltersTheme';
import { filtersRequest, cleanFiltersRequest } from '$redux/modules/actions';
import Failed from '$routes/Failed';
import Loading from '$routes/Loading';
import { Wrapper } from './Filters.styles';

class Filters extends React.Component {
  constructor(props) {
    super(props);
    const {
      idCourse,
      idCourseModality,
      producto,
      active,
    } = props.filtersApplied;
    this.state = {
      idCourse,
      idCourseModality,
      producto,
      active,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(filtersRequest());
  }

  onApplyFilters = (e) => {
    e.preventDefault();
    const { applyFilters } = this.props;

    const {
      idCourse,
      idCourseModality,
      producto,
      active,
    } = this.state;
    applyFilters({
      idCourse,
      idCourseModality,
      producto,
      active,
    });
  };

  resetFilters = async (e) => {
    e.preventDefault();
    const { dispatch, applyFilters } = this.props;
    dispatch(filtersRequest());
    dispatch(cleanFiltersRequest());
    applyFilters({});
  }

  handleChange = name => (event) => {
    this.setState({ [name]: event.target.value });
  };

  render() {
    const {
      filtersData, loading, filtersApplied,
    } = this.props;

    if (loading) return <Loading />;
    if (!filtersData || !filtersData.courses || !filtersData.modalidades) return <Failed />;

    const cursosLista = filtersData.courses.map(
      item => (
        <option key={`course${item.idCourse}`} value={item.idCourse}>{item.name}</option>
      ),
    );

    const modalidadesLista = filtersData.modalidades.map(
      item => (
        <option key={`course${item.idCourseModality}`} value={item.idCourseModality}>{item.name}</option>
      ),
    );

    const {
      producto,
    } = this.state;

    return (
      <Wrapper theme={theme}>
        <MuiThemeProvider theme={themeFilters}>
          <form onSubmit={this.onApplyFilters} className="filters-form">
            <div className="filters">
              <Select
                native
                onChange={this.handleChange('idCourse')}
                className="filter-item"
                defaultValue={filtersApplied.idCourse ? filtersApplied.idCourse : 0}
              >
                <option value="">Selecciona curso</option>
                {cursosLista}
              </Select>
              <Select
                native
                onChange={this.handleChange('idCourseModality')}
                className="filter-item"
                defaultValue={filtersApplied.idCourseModality ? filtersApplied.idCourseModality : 0}
              >
                <option value="">Selecciona Modalidad</option>
                {modalidadesLista}
              </Select>
              <TextField
                className="filter-item"
                id="producto"
                placeholder="Filtrar por Producto"
                onChange={this.handleChange('producto')}
                defaultValue={producto}
              />
              <Select
                native
                onChange={this.handleChange('active')}
                className="filter-item"
                defaultValue={filtersApplied.active ? filtersApplied.active : -1}
              >
                <option value="">¿Está visible?</option>
                <option value={0}>No</option>
                <option value={1}>Sí</option>
              </Select>
              <Button
                color="primary"
                type="submit"
                className="apply-filters-button"
              >
                APLICAR FILTROS
              </Button>
              <Button
                color="secondary"
                onClick={this.resetFilters}
                className="reset-filters-button"
              >
                LIMPIAR FILTROS
              </Button>
            </div>
          </form>
        </MuiThemeProvider>
      </Wrapper>
    );
  }
}

Filters.propTypes = {
  dispatch: PropTypes.func.isRequired,
  applyFilters: PropTypes.func.isRequired,
  filtersData: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  filtersApplied: PropTypes.object,
};

Filters.defaultProps = {
  filtersData: undefined,
  filtersApplied: {},
};

export default connect(
  state => ({
    filtersData: state.modules.filtersData,
    loading: state.modules.loading,
    filtersApplied: state.modules.filters,
  }),
)(Filters);
