import React from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';

import {
  TextField,
  Button,
} from '@material-ui/core';

import { Wrapper } from './Editor.styles';

class Editor extends React.Component {
  state = {}

  onSend = () => {
    const { onSend } = this.props;
    onSend();
  }

  render() {
    const {
      action,
      data: {
        url,
        idCourse,
      },
      set,
      onClose,
      courses,
    } = this.props;

    const coursesList = courses
      .map(
        element => (
          <option key={element.name} value={element.idCourse}>{element.name}</option>
        ),
      );

    return (
      <Wrapper>
        <div className="columns is-bottom-aligned">
          <div className="column is-3">
            <TextField
              label="URL"
              fullWidth
              value={url}
              onChange={e => set('url', e.target.value)}
            />
          </div>
          <div className="column is-3">
            <Select
              native
              onChange={e => set('idCourse', e.target.value)}
              className="filter-item"
              value={idCourse}
            >
              <option value={0}>Ciclo</option>
              {coursesList}
            </Select>
          </div>
          <div className="column is-2 form-actions">
            {
              action === 'edit' ? (
                <Button color="primary" onClick={this.onSend}>Guardar</Button>
              ) : (
                <Button color="secondary" onClick={this.onSend}>Crear</Button>
              )
            }
            <Button className="close-button" onClick={onClose}>X</Button>
          </div>
        </div>
      </Wrapper>
    );
  }
}

Editor.propTypes = {
  action: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  set: PropTypes.func.isRequired,
  courses: PropTypes.array.isRequired,
  onSend: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Editor;
