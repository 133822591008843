import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography, TextField, FormControl, MenuItem, Select,
} from '@material-ui/core';
import { Wrapper } from './styles';
import SubTitle from '../../../../ui/SubTitle';

class ShippingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    let shippingName;
    let shippingZipCode;
    let shippingAddress;
    let shippingCountry;
    let shippingCity;

    const {
      data, optionsData, disabled, set,
    } = this.props;

    if (data) {
      shippingName = (
        <div className="column is-half">
          <div className="columns is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Nombre</Typography>
            </div>
            <div className="column is-three-fifths">
              <TextField
                disabled={disabled}
                fullWidth
                onChange={e => set('shippingInfo', 'shippingName', e.target.value)}
                value={data.shippingName ? data.shippingName : ''}
              />
            </div>
          </div>
        </div>
      );
      shippingZipCode = (
        <div className="column is-half">
          <div className="columns right is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Código postal</Typography>
            </div>
            <div className="column is-three-fifths">
              <TextField
                disabled={disabled}
                fullWidth
                onChange={e => set('shippingInfo', 'shippingZipCode', e.target.value)}
                value={data.shippingZipCode ? data.shippingZipCode : ''}
              />
            </div>
          </div>
        </div>
      );
      shippingAddress = (
        <div className="column is-half">
          <div className="columns is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Dirección</Typography>
            </div>
            <div className="column is-three-fifths">
              <TextField
                fullWidth
                disabled={disabled}
                onChange={e => set('shippingInfo', 'shippingAddress', e.target.value)}
                value={data.shippingAddress ? data.shippingAddress : ''}
              />
            </div>
          </div>
        </div>
      );
      shippingCountry = (
        <div className="column is-half">
          <div className="columns right is-vcentered">
            <div className="column is-two-fifths">
              <Typography>País</Typography>
            </div>
            <div className="column is-three-fifths">
              <FormControl fullWidth>
                <Select
                  disabled={disabled}
                  value={data.shippingIdCountry ? data.shippingIdCountry : ''}
                  onChange={e => set('shippingInfo', 'shippingIdCountry', e.target.value)}
                >
                  {optionsData.countries.map(country => (
                    <MenuItem
                      key={country.idCountry}
                      value={country.idCountry}
                    >
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      );
      shippingCity = (
        <div className="column is-half">
          <div className="columns is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Municipio</Typography>
            </div>
            <div className="column is-three-fifths">
              <TextField
                fullWidth
                disabled={disabled}
                onChange={e => set('shippingInfo', 'shippingCity', e.target.value)}
                value={data.shippingCity ? data.shippingCity : ''}
              />
            </div>
          </div>
        </div>
      );
    }

    const { title } = this.props;

    return (
      <Wrapper>
        <div className="student-data">
          <SubTitle>{title}</SubTitle>
          <div className="columns is-multiline is-narrow content">
            {shippingName}
            {shippingZipCode}
            {shippingAddress}
            {shippingCountry}
            {shippingCity}
          </div>
        </div>
      </Wrapper>);
  }
}

ShippingForm.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object,
  optionsData: PropTypes.object,
  disabled: PropTypes.bool,
  set: PropTypes.func.isRequired,
};

ShippingForm.defaultProps = {
  data: undefined,
  optionsData: {},
  disabled: false,
};

export default (ShippingForm);
