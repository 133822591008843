import React from 'react';
import PropTypes from 'prop-types';

import ReactTable from 'ui/ReactTable';
import 'react-table/react-table.css';

import { Button, Radio, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';
import grey from '@material-ui/core/colors/grey';
import { requestGetServicesActionsWaiting } from '../../../redux/services/actions';

const StyledRadio = withStyles({
  root: {
    height: 'auto',
    width: 'auto',
    transform: 'translateY(-2px)',
    padding: 0,

    // custom color
    color: `${grey[500]} !important`,
  },
})(props => (
  <Radio {...props} />
));

class Grid extends React.Component {
  componentDidUpdate(prevProps) {
    const { filters } = this.props;
    // Typical usage (don't forget to compare props):
    if (filters !== prevProps.filters) {
      const {
        dispatch,
        pageSize,
      } = this.props;
      const bindingObject = {
        pageSize,
        page: 0,
        sorted: [],
        filters,
      };
      dispatch(
        requestGetServicesActionsWaiting(bindingObject, 'services'),
      );
    }
  }

  fetchData = (state) => {
    let { filters } = this.props;
    const {
      dispatch,
      filtersApplied,
    } = this.props;
    filters = {
      ...filtersApplied,
      ...filters,
    };
    const {
      pageSize,
      page,
      sorted,
    } = state;
    const bindingObject = {
      pageSize,
      page,
      sorted,
      filters,
    };
    dispatch(
      requestGetServicesActionsWaiting(bindingObject),
    );
  };

  render() {
    const {
      data,
      loading,
      pages,
      pageSize,
      openLogWindow,
    } = this.props;

    const {
      selections,
      setSelection,
    } = this.props;
    return (
      <ReactTable
        className="gridResult"
        topBorder
        columns={[
          {
            Header: '',
            Cell: ({
              original: { idUserService: id },
            }) => {
              const selected = !!selections.find(s => s === id);
              return (
                <>
                  <StyledRadio
                    checked={selected}
                    onChange={() => {
                      setSelection(id, !selected);
                    }}
                  />
                </>
              );
            },
            width: 50,
          },
          {
            Header: 'Id Acción',
            accessor: 'idUserServiceAction',
            width: 80,
          },
          {
            Header: 'Pedido',
            accessor: 'idEnrollment',
            width: 80,
          },
          {
            Header: 'Nombre',
            accessor: 'studentName',
          },
          {
            Header: 'Email',
            accessor: 'studentEmail',
          },
          {
            Header: 'Action Message',
            accessor: 'actionMessage',
          },
          {
            Header: '',
            fixed: 'right',
            columns: [
              {
                Cell: rowClicked => (
                  <Link to={`matriculas/editar/${rowClicked.original.idEnrollment}`}>
                    <Button>Ver Matrícula
                    </Button>
                  </Link>
                ),
                maxWidth: 100,
                resizable: false,
                sortable: false,
              },
              {
                Cell: rowClicked => (
                  <Button onClick={() => openLogWindow(rowClicked.original.idUserServiceAction)}>
                    Ver Acción
                  </Button>
                ),
                maxWidth: 100,
                resizable: false,
                sortable: false,
              },
            ],
            resizable: false,
            sortable: false,
            maxWidth: 50,
            style: { textAlign: 'center' },
          },
        ]}
        manual
        defaultPageSize={pageSize}
        data={data}
        pages={pages}
        loading={loading}
        onFetchData={this.fetchData}
      />
    );
  }
}

Grid.propTypes = {
  data: PropTypes.array,
  filters: PropTypes.object,
  filtersApplied: PropTypes.object,
  pageSize: PropTypes.number,
  pages: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  selections: PropTypes.array.isRequired,
  setSelection: PropTypes.func.isRequired,
  openLogWindow: PropTypes.func.isRequired,
};

Grid.defaultProps = {
  filters: {},
  data: [],
  pageSize: 20,
  pages: 0,
  filtersApplied: {},
};

export default connect(
  state => ({
    data: state.services.data,
    loading: state.services.loading,
    pages: state.services.pages,
    filtersApplied: state.services.filters,
  }),
)(Grid);
