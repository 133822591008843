import {
  REQUEST_FAILED,
  LIST,
  LIST_SUCCEEDED,
  BEFORE_CREATE,
  BEFORE_CREATE_SUCCEEDED,
  GET,
  GET_SUCCEEDED,
  UPDATE,
  UPDATE_SUCCEEDED,
  REMOVE,
  REMOVE_SUCCEEDED,
  CREATE,
  CREATE_SUCCEEDED,
  SET,
  RESET,
} from './action-types';

export const requestFailed = error => ({ type: REQUEST_FAILED, error });
export const list = () => ({ type: LIST });
export const listSucceeded = data => ({ type: LIST_SUCCEEDED, data });
export const beforeCreate = () => ({ type: BEFORE_CREATE });
export const beforeCreateSucceeded = ui => ({ type: BEFORE_CREATE_SUCCEEDED, ui });
export const get = id => ({ type: GET, id });
export const getSucceeded = (data, ui) => ({ type: GET_SUCCEEDED, data, ui });
export const update = (id, data) => ({ type: UPDATE, id, data });
export const updateSucceeded = success => ({ type: UPDATE_SUCCEEDED, success });
export const remove = id => ({ type: REMOVE, id });
export const removeSucceeded = success => ({ type: REMOVE_SUCCEEDED, success });
export const create = data => ({ type: CREATE, data });
export const createSucceeded = success => ({ type: CREATE_SUCCEEDED, success });
export const set = (key, value) => ({ type: SET, key, value });
export const reset = () => ({ type: RESET });
