import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Select, MenuItem, FormControl,
} from '@material-ui/core';
import { Wrapper } from './styles';
import SubTitle from '../../../../ui/SubTitle';

class AcademicForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    let educationalSpecialNeed;
    let educationalCourse;
    let accessRequirement;
    let lastCourseCountry;
    let educationalLevel;

    const {
      data, optionsData, disabled, set,
    } = this.props;

    if (data) {
      educationalSpecialNeed = (
        <div className="column is-half">
          <div className="columns is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Necesidades especiales</Typography>
            </div>
            <div className="column is-three-fifths">
              <Select
                fullWidth
                disabled={disabled}
                value={data.idEducationalSpecialNeed ? data.idEducationalSpecialNeed : ''}
                onChange={e => set('academicInfo', 'idEducationalSpecialNeed', e.target.value)}
              >
                {optionsData.educationalSpecialNeeds.map(need => (
                  <MenuItem
                    key={need.idEducationalSpecialNeed}
                    value={need.idEducationalSpecialNeed}
                  >
                    {need.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        </div>
      );
      accessRequirement = (
        <div className="column is-half">
          <div className="columns is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Requisitos Académicos de Acceso</Typography>
            </div>
            <div className="column is-three-fifths">
              <Select
                fullWidth
                disabled={disabled}
                value={data.idAccessRequirement ? data.idAccessRequirement : ''}
                onChange={e => set('academicInfo', 'idAccessRequirement', e.target.value)}
              >
                {optionsData.accessRequirements.map(req => (
                  <MenuItem
                    key={req.idAccessRequirement}
                    value={req.idAccessRequirement}
                  >
                    {req.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        </div>
      );
      lastCourseCountry = (
        <div className="column is-half">
          <div className="columns right is-vcentered">
            <div className="column is-two-fifths">
              <Typography>País donde se cursaron los últimos estudios</Typography>
            </div>
            <div className="column is-three-fifths">
              <FormControl fullWidth>
                <Select
                  fullWidth
                  disabled={disabled}
                  value={data.idLastCourseCountry ? data.idLastCourseCountry : ''}
                  onChange={e => set('academicInfo', 'idLastCourseCountry', e.target.value)}
                >
                  {optionsData.countries.map(country => (
                    <MenuItem
                      key={country.idCountry}
                      value={country.idCountry}
                    >
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      );
      educationalCourse = (
        <div className="column is-half">
          <div className="columns right is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Últimos estudios cursados</Typography>
            </div>
            <div className="column is-three-fifths">
              <Select
                fullWidth
                disabled={disabled}
                value={data.idLastEducationalCourse ? data.idLastEducationalCourse : ''}
                onChange={e => set('academicInfo', 'idLastEducationalCourse', e.target.value)}
              >
                {optionsData.educationalCourses.map(course => (
                  <MenuItem
                    key={course.idEducationalCourse}
                    value={course.idEducationalCourse}
                  >
                    {course.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        </div>
      );
      educationalLevel = (
        <div className="column is-half">
          <div className="columns is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Nivel de estudios</Typography>
            </div>
            <div className="column is-three-fifths">
              <Select
                fullWidth
                disabled={disabled}
                value={data.idEducationalLevel ? data.idEducationalLevel : ''}
                onChange={e => set('academicInfo', 'idEducationalLevel', e.target.value)}
              >
                {optionsData.educationalLevels.map(level => (
                  <MenuItem
                    key={level.idEducationalLevel}
                    value={level.idEducationalLevel}
                  >
                    {level.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        </div>
      );
    }

    const { title } = this.props;

    return (
      <Wrapper>
        <div className="student-data">
          <SubTitle>{title}</SubTitle>
          <div className="columns is-multiline is-narrow content">
            {educationalSpecialNeed}
            {educationalCourse}
            {accessRequirement}
            {lastCourseCountry}
            {educationalLevel}
          </div>
        </div>
      </Wrapper>);
  }
}

AcademicForm.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object,
  optionsData: PropTypes.object,
  disabled: PropTypes.bool,
  set: PropTypes.func.isRequired,
};

AcademicForm.defaultProps = {
  data: undefined,
  optionsData: {},
  disabled: false,
};

export default (AcademicForm);
