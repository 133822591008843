import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/es';

import {
  TextField,
  InputAdornment,
  Button,
} from '@material-ui/core';
import SubTitle from 'ui/SubTitle';

import { DeadlineActions, SubTitleNoFlex } from './DeadlinesForm.styles';

moment.locale('es');

class DeadlinesForm extends React.Component {
  setDeadline = (index, field, value) => {
    const { data: { SplitPaymentDeadlines }, set } = this.props;
    set(
      'SplitPaymentDeadlines',
      SplitPaymentDeadlines.map((d, i) => (
        i === index ? { ...d, [field]: value } : d
      )),
    );
  }

  addDeadline = () => {
    const { data: { SplitPaymentDeadlines }, set } = this.props;
    set(
      'SplitPaymentDeadlines',
      [...SplitPaymentDeadlines, { percentage: '', deadLineDate: null }],
    );
  }

  removeDeadline = () => {
    const { data: { SplitPaymentDeadlines }, set } = this.props;
    const [, ...deadlinesWithoutLastOneR] = SplitPaymentDeadlines.reverse();
    const deadlinesWithoutLastOne = deadlinesWithoutLastOneR.reverse();
    set(
      'SplitPaymentDeadlines',
      deadlinesWithoutLastOne,
    );
  }

  render() {
    const { data: { SplitPaymentDeadlines } } = this.props;

    return (
      <div>
        <SubTitle>
          <SubTitleNoFlex>
            Plazos de pago
            → {
              (() => {
                const percentageTotal = SplitPaymentDeadlines.reduce(
                  (total, { percentage }) => {
                    const floatPercentage = parseFloat(percentage);
                    return total + (Number.isNaN(floatPercentage) ? 0 : floatPercentage);
                  }, 0,
                );

                if (percentageTotal === 100) return <span>{percentageTotal}%</span>;
                return <span style={{ color: '#f00' }}>{percentageTotal}%</span>;
              })()
            } a pagar en {SplitPaymentDeadlines.length} plazos
          </SubTitleNoFlex>
        </SubTitle>
        <DeadlineActions>
          <Button size="small" onClick={this.addDeadline}>Añadir plazo</Button>
          <Button size="small" onClick={this.removeDeadline}>Quitar plazo</Button>
        </DeadlineActions>
        <div>
          {
            /* eslint-disable react/no-array-index-key */
            SplitPaymentDeadlines.map(({ deadLineDate, percentage }, index) => (
              <div key={index}>
                <div className="columns" style={{ alignItems: 'flex-end' }}>
                  <div className="column is-narrow" style={{ fontSize: 14, marginBottom: 9 }}>
                    Plazo #{index + 1}
                  </div>
                  <div className="column is-one-quarter">
                    <TextField
                      label="Fecha límite"
                      fullWidth
                      type="date"
                      value={moment(deadLineDate).format('YYYY-MM-DD')}
                      onChange={e => this.setDeadline(index, 'deadLineDate', e.target.value)}
                    />
                  </div>
                  <div className="column is-one-quarter">
                    <TextField
                      label="Procentaje a pagar"
                      fullWidth
                      type="number"
                      InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                      value={percentage}
                      onChange={e => this.setDeadline(index, 'percentage', e.target.value)}
                    />
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    );
  }
}

DeadlinesForm.propTypes = {
  data: PropTypes.object.isRequired,
  set: PropTypes.func.isRequired,
};

export default DeadlinesForm;
