import {
  PAYMENTMODES_REQUEST,
  PAYMENTMODES_REQUEST_SUCCEEDED,
  PAYMENTMODES_REQUEST_FAILED,
  PAYMENTMODES_UPDATE,
  PAYMENTMODES_UPDATE_SUCCEEDED,
  PAYMENTMODES_UPDATE_FAILED,
  PAYMENTMODES_SET_SELECTION,
  PAYMENTMODES_SET_PAYMENTINFO,
  PAYMENTMODES_SET_ACTIVE,
} from './action-types';
import utils from '../utils';

const initialState = {
  loading: false,
  paymentModes: [],
  selectedId: '0',
  paymentInfo: '',
  active: false,
  readOnly: true,
};

const paymentModesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case PAYMENTMODES_UPDATE:
    case PAYMENTMODES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case PAYMENTMODES_REQUEST_SUCCEEDED: {
      const paymentModes = action.result;
      const [pm] = paymentModes;
      let selectedId = '0';
      let paymentInfo = '';
      let active = false;
      let readOnly = true;
      if (paymentModes) {
        selectedId = pm.idPaymentMode.toString();
        // eslint-disable-next-line prefer-destructuring
        paymentInfo = pm.paymentInfo || '';
        active = pm.active || false;
        readOnly = !(pm && pm.code.startsWith('fin'));
      }
      return {
        ...state,
        paymentModes,
        selectedId,
        paymentInfo,
        active,
        readOnly,
        loading: false,
      };
    }
    case PAYMENTMODES_UPDATE_SUCCEEDED: {
      return {
        ...state,
        loading: false,
      };
    }
    case PAYMENTMODES_SET_SELECTION: {
      const { id } = action;
      const pm = state.paymentModes.find(p => p.idPaymentMode.toString() === id);
      return {
        ...state,
        selectedId: id,
        paymentInfo: (pm && pm.paymentInfo) ? pm.paymentInfo : '',
        active: (pm && pm.active) ? pm.active : false,
        readOnly: !(pm && pm.code.startsWith('fin')),
      };
    }
    case PAYMENTMODES_SET_PAYMENTINFO: {
      const { text } = action;
      return {
        ...state,
        paymentInfo: text,
      };
    }
    case PAYMENTMODES_SET_ACTIVE: {
      const { active } = state;
      const newStatus = !active;
      return {
        ...state,
        active: newStatus,
      };
    }
    case PAYMENTMODES_UPDATE_FAILED:
    case PAYMENTMODES_REQUEST_FAILED: {
      const { error } = action;

      let message = 'La petición no se ha completado con éxito.';

      if (error.response && error.response.data && error.response.data.message) {
        ({ message } = error.response.data);
      }
      utils.dialog({
        type: 'error',
        title: 'Error inesperado',
        message,
      });
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default paymentModesReducer;
