import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: ${({ topBorder }) => (topBorder ? 0 : '-20px')};
  font-size: .8em;

  .ReactTable {
    border: none;
  }

  .rt-thead.-headerGroups {
    display: none;
  }

  .rt-thead.-header {
    border-top: 1px solid ${({ topBorder }) => (topBorder ? '#000' : 'transparent')};
    box-shadow: none;

    .rt-th {
      padding: 20px 5px;
      border: none;

      text-transform: uppercase;
      font-weight: 700;
      text-align: left;
      .darken {
        background-color: red;
      }
    }
  }

  .rt-tbody {
    .rt-tr-group {
      border-top: 1px solid #e7e7e7;
      border-bottom: none;
    }
    .rt-td {
      padding: 20px 5px;
      border: none;

      color: #999;
    }
    .darken {
        background-color: rgba(0, 0, 0, 0.02);
    }

    button {
      font-size: .8em;
      padding:  2px 7px;
      min-width: 0;
      min-height: 0;
      color: #6896c8;
    }
  }

  .pagination-bottom {
    border-top: 1px solid #e7e7e7;
    padding-top: 20px;

    .-pagination {
      box-shadow: none;
      border-top: none;

      button {
        background: none;
        text-transform: uppercase;
        font-size: 12px;
        color: #6896cf;
        border-radius: 0;
      }

      .-pageInfo {
        text-transform: uppercase;

        .-pageJump input {
          border-radius: 0;
          margin: 0 7px;
        }
      }

      .select-wrap {
        select {
          border-radius: 0;
        }
      }
    }
  }
`;
