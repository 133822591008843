import styled from 'styled-components';

export const Wrapper = styled.div`
  .export-data {
    margin-top: 20px;
    float: right;
    button {
      color: #6896c8;
    }
  }
  .align-right {
    text-align: right;
    padding: 0;
  }
  .export {
    color: #6896c8;
    font-size: 12px;
  }
  .buttons {
    margin-top: 30px;
    .return-button {
      margin-left: 20px;
    }
    .download-button {
      margin-left: 5px;
    }
  }
`;
