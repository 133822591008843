import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import ReactTable from 'ui/ReactTable';
import 'react-table/react-table.css';

import { connect } from 'react-redux';

import {
  requestGetServicesLogFilterFiltered as requestServices,
} from '$redux/services/actions';

const dateFormat = 'DD-MM-YYYY HH:MM';

class GridLog extends React.Component {
  componentDidUpdate() {
  }

  fetchData = (state) => {
    let { filters } = this.props;
    const { dispatch, filtersApplied, idUserService } = this.props;
    filters = {
      ...filtersApplied,
      ...filters,
    };
    filters.idUserService = idUserService;
    const { pageSize, page, sorted } = state;
    sorted.push(
      {
        id: 'createdAt',
        desc: true,
      },
    );
    const bindingObject = {
      pageSize,
      page,
      sorted,
      filters,
    };
    dispatch(
      requestServices(bindingObject, 'services'),
    );
  }

  render() {
    const {
      data,
      loading,
      pages,
      pageSize,
    } = this.props;

    return (
      <ReactTable
        className="gridResult"
        topBorder
        columns={[
          {
            Header: 'ID',
            accessor: 'idUserServiceLog',
            maxWidth: 80,
          },
          {
            Header: 'Action',
            accessor: 'message',
          },
          {
            Header: 'Fecha Creacion',
            accessor: 'createdAt',
            Cell: ({ value }) => (value ? moment(value).format(dateFormat) : '-'),
            maxWidth: 120,
          },
        ]}
        manual
        defaultPageSize={pageSize}
        data={data}
        pages={pages}
        loading={loading}
        onFetchData={this.fetchData}
      />
    );
  }
}

GridLog.propTypes = {
  data: PropTypes.array,
  filters: PropTypes.object,
  filtersApplied: PropTypes.object,
  pageSize: PropTypes.number,
  pages: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  idUserService: PropTypes.any.isRequired,
  dispatch: PropTypes.func.isRequired,
};

GridLog.defaultProps = {
  filters: {},
  data: [],
  pageSize: 20,
  pages: 0,
  filtersApplied: {},
};

export default connect(
  state => ({
    data: state.services.dataLog,
    loading: state.services.loading,
    pages: state.services.pagesLog,
    filtersApplied: state.services.filters,
  }),
)(GridLog);
