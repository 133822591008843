import {
  select, all, call, put, takeEvery,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import api from 'linkia-api';

import {
  REQUEST,
  LIST,
  BEFORE_CREATE,
  GET,
  UPDATE,
  REMOVE,
  CREATE,
} from './action-types';
import {
  requestSucceeded,
  requestFailed,
  listSucceeded,
  beforeCreateSucceeded,
  getSucceeded,
  updateSucceeded,
  get,
  removeSucceeded,
  createSucceeded,
} from './actions';

import { tokenSelector } from '../login/selectors';

function* listVouchers() {
  const token = yield select(tokenSelector);
  try {
    const data = yield call(api.voucher.listAll, { token });
    yield put(listSucceeded(data));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* beforeCreateVoucher() {
  const token = yield select(tokenSelector);
  try {
    const trainingPlans = yield call(api.trainingPlan.listOptions, { token });
    yield put(beforeCreateSucceeded({ trainingPlans }));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* getVoucher(action) {
  const token = yield select(tokenSelector);
  const { id } = action;
  try {
    const [data, trainingPlans] = yield all([
      call(api.voucher.get, { token, id }),
      call(api.trainingPlan.listOptions, { token }),
    ]);
    yield put(getSucceeded(data, { trainingPlans }));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* updateVoucher(action) {
  const token = yield select(tokenSelector);
  const { id, data } = action;
  try {
    const success = yield call(api.voucher.update, { token, id, data });
    yield put(updateSucceeded(success));
    yield put(get(id));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* removeVoucher(action) {
  const token = yield select(tokenSelector);
  const { id } = action;
  try {
    const success = yield call(api.voucher.get, { token, id });
    yield put(removeSucceeded(success));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* createVoucher(action) {
  const token = yield select(tokenSelector);
  const { data } = action;
  try {
    const success = yield call(api.voucher.create, { token, data });
    yield put(createSucceeded(success));
    yield put(push(`/codigos-promocionales/editar/${success.idVoucher}`));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* reqVouchers(action) {
  const token = yield select(tokenSelector);
  const {
    bindingObject,
    origin,
  } = action;
  try {
    const data = yield call(api.voucher.getAllFiltered, {
      bindingObject,
      token,
    });
    yield put(requestSucceeded(data, origin, bindingObject));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

export default [
  takeEvery(LIST, listVouchers),
  takeEvery(BEFORE_CREATE, beforeCreateVoucher),
  takeEvery(GET, getVoucher),
  takeEvery(UPDATE, updateVoucher),
  takeEvery(REMOVE, removeVoucher),
  takeEvery(CREATE, createVoucher),
  takeEvery(REQUEST, reqVouchers),
];
