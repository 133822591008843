import {
  REQUEST_LEAD_EXPORT_CREATE_LEAD,
  REQUEST_LEAD_EXPORT_CREATE_LEAD_SUCCEEDED,
  REQUEST_LEAD_EXPORT_CREATE_LEAD_FAILED,
} from './action-types';

import utils from '../utils';

const initialState = {
  data: [],
  loading: false,
};

const leadExportarReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case REQUEST_LEAD_EXPORT_CREATE_LEAD: {
      return {
        ...state,
        loading: true,
      };
    }
    case REQUEST_LEAD_EXPORT_CREATE_LEAD_SUCCEEDED: {
      utils.dialog({
        type: 'reload',
        title: 'Lead Creado',
        message: 'El Lead se ha creado correctamente',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case REQUEST_LEAD_EXPORT_CREATE_LEAD_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error inesperado',
        message: 'No se ha podido guardar el Lead, recuerda todos los campos son obligatorios.',
      });
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default leadExportarReducer;
