import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ReactTable from 'ui/ReactTable';
import 'react-table/react-table.css';

import theme from 'ui/theme';
import grey from '@material-ui/core/colors/grey';
import {
  Checkbox,
  withStyles,
} from '@material-ui/core';

import { canSelector, hasAcademy } from '$redux/login/selectors';
import {
  requestEnrollmentServices as requestEnrollments,
} from '$redux/enrollments/actions';

import Failed from '$routes/Failed';

import { Wrapper, SmallBall } from './styles';
import { WarningValue, LinkiaUpdatedValue, AcademyUpdatedValue } from './Grid.helpers';

const StyledCheckbox = withStyles({
  root: {
    height: 'auto',
    width: 'auto',
    transform: 'translateY(-2px)',
    padding: 0,

    // custom color
    color: `${grey[500]} !important`,
  },
})(props => (
  <Checkbox {...props} />
));


class Grid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps) {
    const { filters } = this.props;

    if (filters !== prevProps.filters) {
      const { dispatch, pageSize } = this.props;
      const bindingObject = {
        pageSize,
        page: 0,
        sorted: [],
        filters,
      };
      dispatch(requestEnrollments(bindingObject, 'enrollments'));
    }
  }

  fetchData = (state) => {
    let { filters } = this.props;
    const { dispatch, filtersApplied } = this.props;
    filters = {
      ...filtersApplied,
      ...filters,
    };
    const { pageSize, page, sorted } = state;
    const bindingObject = {
      pageSize,
      page,
      sorted,
      filters,
    };
    dispatch(requestEnrollments(bindingObject, 'enrollments'));
  }

  render() {
    const {
      loading, pages, pageSize, data,
    } = this.props;

    if (!data) return <Failed />;

    const {
      selections, setSelection, isAcademy,
    } = this.props;

    return (
      <Wrapper theme={theme}>
        <ReactTable
          topBorder
          columns={[
            {
              Header: 'Integr.',
              Cell: ({
                original: { idEnrollment: id },
              }) => {
                const selected = !!selections.find(s => s === id);

                return (
                  <StyledCheckbox
                    checked={selected}
                    onChange={() => setSelection(id, !selected)}
                  />
                );
              },
              width: 70,
            },
            {
              Header: 'Servicios',
              accessor: 'usRegisterBasic',
              Cell: (row) => {
                const {
                  value,
                } = row;

                let color = '';
                switch (value) {
                  case 1:
                    color = '#ff9800';
                    break;
                  case 2:
                    color = '#0B0';
                    break;
                  default:
                    color = '#f44336';
                }
                return <SmallBall disabled color={color} />;
              },
              width: 80,
            },
            {
              Header: 'Última actualización',
              accessor: 'updatedAt',
              Cell: (row) => {
                const {
                  value, original: { spoiledDeadline, linkiaValidation, academyValidation },
                } = row;
                if (spoiledDeadline) return <WarningValue>{value}</WarningValue>;
                if (linkiaValidation && !isAcademy) {
                  return <LinkiaUpdatedValue>{value}</LinkiaUpdatedValue>;
                }
                if (academyValidation) return <AcademyUpdatedValue>{value}</AcademyUpdatedValue>;
                return value;
              },
              width: 120,
            },
            {
              Header: 'Pedido',
              accessor: 'idEnrollment',
              width: 80,
            },
            {
              Header: 'Nombre',
              accessor: 'studentName',
            },
            {
              Header: 'Centro',
              accessor: 'academyName',
            },
            {
              Header: 'Formación',
              accessor: 'courseCode',
              width: 90,
            },
            {
              Header: 'Estado',
              accessor: 'state',
            },
            {
              Header: 'Tipo',
              accessor: 'reenroll',
              Cell: (row) => {
                const {
                  original: { reenroll, idEnrollmentParent },
                } = row;
                return (reenroll === 1 ? 'Rematrícula' : 'Nueva') + (!idEnrollmentParent ? ' ' : '(Generada)');
              },
              maxWidth: 80,
            },
          ]}
          manual
          defaultPageSize={pageSize}
          data={data}
          pages={pages} // Display the total number of pages
          loading={loading} // Display the loading overlay when we need it
          onFetchData={this.fetchData} // Request new data when things change
        />
      </Wrapper>
    );
  }
}

Grid.propTypes = {
  dispatch: PropTypes.func.isRequired,
  filters: PropTypes.object,
  data: PropTypes.array,
  pages: PropTypes.number,
  pageSize: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  selections: PropTypes.array.isRequired,
  setSelection: PropTypes.func.isRequired,
  filtersApplied: PropTypes.object,
  isAcademy: PropTypes.bool.isRequired,
};

Grid.defaultProps = {
  filters: {},
  data: [],
  pageSize: 10,
  pages: 0,
  filtersApplied: {},
};

export default connect(
  state => ({
    userCan: canSelector(state),
    isAcademy: hasAcademy(state),
    data: state.enrollments.enrollments,
    pages: state.enrollments.pages,
    loading: state.enrollments.loading,
    filtersApplied: state.enrollments.filters,
    log: state.enrollments.log,
  }),
)(Grid);
