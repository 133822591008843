import React from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert2';
import UnitRow from './UnitRow';
import Loading from '../../../Loading';
import {
  RedButton,
} from './EditModule.styles';

class UnitTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  delete = id => async () => {
    const { deleteUnit } = this.props;
    const { value } = await swal({
      title: 'Confirmación',
      text: `¿Seguro que quieres eliminar la UF con id #${id}? Este cambio es irreversible.`,
      type: 'warning',
      confirmButtonText: 'Eliminar',
      confirmButtonColor: '#e05959',
      showCancelButton: true,
      cancelButtonText: 'No, volver atrás',
    });

    if (value) {
      await deleteUnit(id);
    }
  }

  render() {
    const { data, addUnit } = this.props;
    if (!data) return <Loading />;
    return (
      <div>
        <UnitRow addUnit={addUnit} />
        <div className="unit-list-edition">
          {data.map(unit => (
            <div key={unit.name} className="columns is-multiline unit-row-container">
              <div className="column is-1 unit-cell">
                {unit.code}
              </div>
              <div className="column is-3 unit-cell">
                <span className="unit-name">{unit.name}</span>
              </div>
              <div className="column is-1 unit-cell">
                {unit.onLineHours}
              </div>
              <div className="column is-1 unit-cell">
                {unit.inClassHours}
              </div>
              <div className="column is-1 unit-cell">
                {unit.onLineHourPrice}
              </div>
              <div className="column is-1 unit-cell">
                {unit.inClassHourPrice}
              </div>
              <div className="column is-1 unit-cell">
                {unit.price}
              </div>
              <div className="column is-1" />
              <div className="column is-2 is-vcentered">
                {unit.idUnit ? (
                  <RedButton
                    style={{ backgroundColor: 'transparent' }}
                    onClick={this.delete(unit.idUnit)}
                  > Eliminar UF
                  </RedButton>) : ''}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

UnitTable.propTypes = {
  data: PropTypes.array,
  deleteUnit: PropTypes.func.isRequired,
  addUnit: PropTypes.func.isRequired,
};

UnitTable.defaultProps = {
  data: undefined,
};

export default UnitTable;
