import {
  REQUEST,
  REQUEST_SUCCEEDED,
  REQUEST_FAILED,
  FILTERS_REQUEST,
  FILTERS_REQUEST_SUCCEEDED,
  FILTERS_REQUEST_FAILED,
  SET,
  REQUEST_EXPORT,
  REQUEST_EXPORT_SUCCEEDED,
  REQUEST_EXPORT_FAILED,
} from './action-types';
import utils from '../utils';

const initialState = {
  data: [],
  totals: {},
  loading: false,
};

const billingReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case REQUEST_EXPORT:
    case REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case REQUEST_SUCCEEDED: {
      const {
        data, totals, pages, academies,
      } = action;
      return {
        ...state,
        data,
        totals,
        academies,
        pages,
        loading: false,
      };
    }
    case REQUEST_EXPORT_SUCCEEDED: {
      const { data } = action;
      const encodedUri = escape(data);
      const link = document.createElement('a');
      const file = 'facturacion.csv';
      link.setAttribute('href', `data:text/csv;charset=utf-8,%EF%BB%BF${encodedUri}`);
      link.setAttribute('download', file);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return {
        ...state,
        loading: false,
      };
    }
    case REQUEST_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error inesperado',
        message: 'No se ha podido cargar los datos de facturación',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case REQUEST_EXPORT_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error en la exportación',
        message: 'No se ha podido cargar los datos de facturación',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case FILTERS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case FILTERS_REQUEST_SUCCEEDED: {
      const { filtersData } = action;
      return {
        ...state,
        filtersData,
        loading: false,
      };
    }
    case FILTERS_REQUEST_FAILED: {
      utils.dialog({
        type: 'error',
        title: 'Error de carga',
        message: 'No se ha podido traer la información referente a los filtros',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case SET: {
      const { key, value } = action;

      return {
        ...state,
        [key]: value,
      };
    }
    default:
      return state;
  }
};

export default billingReducer;
