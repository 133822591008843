import {
  REQUEST_ORDER_RECOVERY_SEARCH,
  REQUEST_ORDER_RECOVERY_SEARCH_SUCCEEDED,
  REQUEST_ORDER_RECOVERY_SEARCH_FAILED,
  REQUEST_ORDER_RECOVERY_RECOVER,
  REQUEST_ORDER_RECOVERY_RECOVER_SUCCEEDED,
  REQUEST_ORDER_RECOVERY_RECOVER_FAILED,
} from './action-types';

export const requestOrderRecoverySearch = bindingObject => ({
  type: REQUEST_ORDER_RECOVERY_SEARCH, bindingObject,
});

export const requestOrderRecoverySearchSucceeded = ({
  data, pages,
}) => ({ type: REQUEST_ORDER_RECOVERY_SEARCH_SUCCEEDED, data, pages });

export const requestOrderRecoverySearchFailed = () => ({
  type: REQUEST_ORDER_RECOVERY_SEARCH_FAILED,
});

export const requestOrderRecoveryRecover = bindingObject => ({
  type: REQUEST_ORDER_RECOVERY_RECOVER, bindingObject,
});

export const requestOrderRecoveryRecoverSucceeded = () => ({
  type: REQUEST_ORDER_RECOVERY_RECOVER_SUCCEEDED,
});

export const requestOrderRecoveryRecoverFailed = () => ({
  type: REQUEST_ORDER_RECOVERY_RECOVER_FAILED,
});
