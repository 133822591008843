import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Title from 'ui/Title';
import theme from 'ui/theme';
import { Wrapper } from './styles';
import Filters from './Filters';
import Grid from './Grid';

class Utms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {},
      idStudent: -1,
    };
  }

  componentDidMount() {
    const { match: { params: { idStudent } } } = this.props;
    this.setState({
      idStudent,
    });
  }

  applyFilters = (filtersSelected) => {
    this.setState({
      filters: filtersSelected,
    });
  }

  render() {
    const { filters, loading, idStudent } = this.state;

    if (idStudent === -1) return '';

    return (
      <Wrapper theme={theme}>
        <Title>UTMs Students</Title>
        { loading }
        <Filters
          applyFilters={this.applyFilters}
        />
        <Grid
          filters={filters}
          idStudent={idStudent}
        />
      </Wrapper>
    );
  }
}

Utms.propTypes = {
  match: PropTypes.object.isRequired,
};

Utms.defaultProps = {
};

export default connect(
  state => ({
    loading: state.utms.loading,
    result: state.utms.result,
  }),
)(Utms);
