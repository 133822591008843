import React from 'react';
import SubTitle from 'ui/SubTitle';
import PropTypes from 'prop-types';
import {
  TextField,
} from '@material-ui/core';


class UnitRow extends React.Component {
  handleChange = (name, value) => {
    const stateReplica = { canBeAdded: true };
    stateReplica[name] = value;
    this.setState(stateReplica);
  }


  render() {
    const { data, set } = this.props;
    return (
      <div>
        <SubTitle className="modal-form-title">Unidades formativa</SubTitle>
        <div className="columns is-multiline">
          <div className="column is-1">
            <TextField
              label="SKU"
              defaultValue={data ? data.code : ''}
              onChange={event => set('code', event.target.value)}
            />
          </div>
          <div className="column is-5">
            <TextField
              fullWidth
              onChange={event => set('name', event.target.value)}
              label="NOMBRE"
              defaultValue={data ? data.name : ''}
            />
          </div>
          <div className="column is-1">
            <TextField
              onChange={event => set('onLineHours', event.target.value)}
              label="H.ONLINE"
              defaultValue={data ? data.onLineHours : ''}
            />
          </div>
          <div className="column is-1">
            <TextField
              onChange={event => set('inClassHours', event.target.value)}
              label="H. PRE."
              defaultValue={data ? data.inClassHours : ''}
            />
          </div>
          <div className="column is-1">
            <TextField
              onChange={event => set('onLineHourPrice', event.target.value)}
              label="P. ONLINE"
              defaultValue={data ? data.onLineHourPrice : ''}
            />
          </div>
          <div className="column is-1">
            <TextField
              onChange={event => set('inClassHourPrice', event.target.value)}
              label="P. PRE."
              defaultValue={data ? data.inClassHourPrice : ''}
            />
          </div>
          <div className="column is-1">
            <TextField
              onChange={event => set('price', event.target.value)}
              label="P. FIJO"
              defaultValue={data ? data.price : ''}
            />
          </div>
        </div>
      </div>
    );
  }
}
UnitRow.propTypes = {
  data: PropTypes.object,
  set: PropTypes.func.isRequired,
};

UnitRow.defaultProps = {
  data: {},
};

export default UnitRow;
