import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper } from './ThreadsList.styles';
import ThreadBox from './ThreadBox';

const ThreadsList = ({
  threads,
  onClickUnreadMessage,
  onSendMessage,
}) => (
  <Wrapper>
    <div className="header columns">
      <div className="column is-one-fifth">Fecha / Hora</div>
      <div className="column is-one-fifth">Remitente</div>
      <div className="column">Mensaje</div>
    </div>
    {threads.map(thread => (
      thread ? (
        <ThreadBox
          key={thread.idUser}
          thread={thread}
          onClickUnreadMessage={onClickUnreadMessage}
          onSendMessage={onSendMessage}
        />
      ) : ''
    ))}
  </Wrapper>
);

ThreadsList.propTypes = {
  threads: PropTypes.array.isRequired,
  onClickUnreadMessage: PropTypes.func.isRequired,
  onSendMessage: PropTypes.func.isRequired,
};

export default ThreadsList;
