import React from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import PropTypes from 'prop-types';
import ReactTable from 'ui/ReactTable';
import theme from 'ui/theme';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { filteredRequest, requestExportAcademyInformed } from '$redux/student/actions';
import Failed from '$routes/Failed';
import { Wrapper } from './Grid.styles';

class Grid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps) {
    const { filters } = this.props;

    // Typical usage (don't forget to compare props):
    if (filters !== prevProps.filters) {
      const { dispatch, pageSize } = this.props;
      const bindingObject = {
        pageSize,
        page: 0,
        sorted: [],
        filters,
      };
      dispatch(filteredRequest(bindingObject));
    }
  }

  fetchData = (state) => {
    let { filters } = this.props;
    const { dispatch, filtersApplied } = this.props;
    filters = {
      ...filtersApplied,
      ...filters,
    };
    const { pageSize, page, sorted } = state;
    const bindingObject = {
      pageSize,
      page,
      sorted,
      filters,
    };
    dispatch(filteredRequest(bindingObject));
  }

  exportStudentsWithInformedAcademy = () => {
    const { dispatch } = this.props;
    dispatch(requestExportAcademyInformed());
  }

  render() {
    const {
      data, loading, pages, pageSize,
    } = this.props;
    if (!data) return <Failed />;
    let columnTitles = [['Ha habido un fallo al exportar']];
    let exportButton;
    if (data.length > 0) {
      columnTitles = [
        ['Alumno', 'Ciclo', 'Centro', 'Email', 'Outlook Email', 'CC Email'],
        ...data.map(item => [
          item.studentName,
          item.courseName,
          item.academyName,
          item.email,
          item.secondaryEmail,
          item.collaboratingCenterStudentEmail,
        ]),
      ];
      exportButton = (
        <div className="column align-right">
          <Button>
            <CSVLink key={data.map(item => item.email[0]).join('-')} data={columnTitles} className="export">EXPORTAR LISTADO</CSVLink>
          </Button>
        </div>
      );
    }

    /* eslint-disable */
    return (
      <Wrapper theme={theme}>
        <div>
          <ReactTable
            topBorder
            columns={[
              {
                Header: 'Alumno',
                id: 'studentName',
                accessor: d => `${d.studentName}`,
                maxWidth: 200,
              },
              {
                Header: 'Ciclo',
                id: 'courseName',
                accessor: d => d.courseName,
                maxWidth: 200,
              },
              {
                Header: 'Centro',
                id: 'academyName',
                accessor: d => d.academyName,
                maxWidth: 200,
              },
              {
                Header: 'Email',
                id: 'email',
                accessor: d => d.email,
                maxWidth: 200,
              },
              {
                Header: 'Outlook Email',
                id: 'secondaryEmail',
                accessor: d => d.secondaryEmail,
                maxWidth: 200,
              },
              {
                Header: 'CC Email',
                id: 'collaboratingCenterStudentEmail',
                accessor: d => d.collaboratingCenterStudentEmail,
              },
              {
                Header: '',
                id: 'check',
                accessor: 'viewButton',
                columns: [
                  {
                    Cell: rowClicked => (
                      <Link to={`alumnos/services/${rowClicked.original.idStudent}`}><Button>SVC</Button></Link>
                    ),
                    maxWidth: 50,
                    resizable: false,
                    sortable: false,
                  },
                  {
                    Cell: rowClicked => (
                      <Link to={`alumnos/editar/${rowClicked.original.idStudent}`}><Button>Ver</Button></Link>
                    ),
                    maxWidth: 50,
                    resizable: false,
                    sortable: false,
                  },
                  {
                    Cell: rowClicked => (
                      <Link to={`/alumnos/grades/${rowClicked.original.idStudent}`}><Button>Notas</Button></Link>
                    ),
                    maxWidth: 50,
                    resizable: false,
                    sortable: false,
                  }
                ],
                maxWidth: 50,
                style: { textAlign: 'center' },
                resizable: false,
                sortable: false,
              },
            ]}
            manual
            data={data}
            loading={loading}
            pages={pages} // Display the total number of pages
            onFetchData={this.fetchData} // Request new data when things change
            defaultPageSize={pageSize}
          />
          <br />
        </div>
        <div style={{display: 'flex'}}>
          {exportButton}
          <div className="column align-right">
            <Button onClick={() => this.exportStudentsWithInformedAcademy()}>
              EXPORTAR ALUMNOS CON INFO ACADEMIA
            </Button>
          </div>
        </div>
      </Wrapper>
    );
  }
}

Grid.propTypes = {
  dispatch: PropTypes.func.isRequired,
  filters: PropTypes.object,
  data: PropTypes.array,
  pages: PropTypes.number,
  pageSize: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  filtersApplied: PropTypes.object,
};

Grid.defaultProps = {
  filters: {},
  data: [],
  pageSize: 10,
  pages: 0,
  filtersApplied: {},
};

export default connect(
  state => ({
    data: state.student.data,
    pages: state.student.pages,
    loading: state.student.loading,
    filtersApplied: state.student.filters,
  }),
)(Grid);
