import {
  LIST,
  LIST_SUCCEEDED,
  LIST_FAILED,
  GET,
  GET_SUCCEEDED,
  GET_FAILED,
  EDIT,
  EDIT_SUCCEEDED,
} from './action-types';
import utils from '../utils';

const initialState = {
  list: null,
  loading: false,
  single: null,
  loadingSingle: false,
};

const messagesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LIST: {
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_SUCCEEDED: {
      const { data } = action;
      return {
        ...state,
        list: data,
        loading: false,
      };
    }
    case LIST_FAILED: {
      utils.dialog({
        type: 'error',
        title: 'Error al recibir los hilos',
        message: 'Lo sentimos, se ha producido un error inesperado...',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case GET: {
      return {
        ...state,
        loadingSingle: true,
      };
    }
    case GET_SUCCEEDED: {
      const { data } = action;
      return {
        ...state,
        single: data,
        loadingSingle: false,
      };
    }
    case GET_FAILED: {
      utils.dialog({
        type: 'error',
        title: 'Error al recibir la unidad',
        message: 'Lo sentimos, se ha producido un error inesperado...',
      });
      return {
        ...state,
        loadingSingle: false,
      };
    }
    case EDIT: {
      return {
        ...state,
        loading: true,
      };
    }
    case EDIT_SUCCEEDED: {
      const { data } = action;
      utils.dialog({
        title: 'Cambios guardados',
        message: 'Se ha editado la unidad con éxito.',
      });
      return {
        ...state,
        single: data.unit,
        loading: false,
      };
    }

    default:
      return state;
  }
};

export default messagesReducer;
