import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Editor } from 'react-draft-wysiwyg';
import {
  EditorState, convertToRaw, convertFromHTML, ContentState,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Button } from '@material-ui/core';
import { Wrapper } from './mailEditPanel.styles';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  setMail,
} from '$redux/mails/actions';

class MailEditPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: undefined,
    };
  }

  onChange = (idMail, field, newText) => {
    const { dispatch } = this.props;
    dispatch(setMail(idMail, field, newText));
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  save = () => {
    const { onUpdate } = this.props;
    const { dispatch, mail } = this.props;
    const { editorState } = this.state;
    if (editorState !== undefined) {
      const rawObject = convertToRaw(editorState.getCurrentContent());
      const text = draftToHtml(rawObject);
      dispatch(onUpdate({
        ...mail,
        text,
      }));
    } else {
      dispatch(onUpdate(mail));
    }
  }


  render() {
    const { mail, title } = this.props;
    const { editorState } = this.state;
    let editorStateContent;
    if (editorState === undefined) {
      const blocksFromHTML = convertFromHTML(mail.text);
      if (blocksFromHTML.contentBlocks === null) {
        editorStateContent = EditorState.createEmpty();
      } else {
        const state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        );
        editorStateContent = EditorState.createWithContent(state);
      }
    } else {
      editorStateContent = editorState;
    }

    return (
      <Wrapper>
        <form className="form" onSubmit={() => this.save()}>
          <div>
            <h3>{title}</h3>
            <a id={mail.subject} href>
              <span>
                Asunto:
              </span>
            </a>
            <input
              type="text"
              defaultValue={mail.subject}
              onBlur={e => this.onChange(mail.idMail, 'subject', e.target.value)}
            />
          </div>
          <div>
            <span>
              Texto:
            </span>
            <Editor
              editorState={editorStateContent}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={this.onEditorStateChange}
            />
          </div>
          <Button color="primary" type="submit">Guardar</Button>
        </form>
      </Wrapper>
    );
  }
}

MailEditPanel.propTypes = {
  mail: PropTypes.object,
  onUpdate: PropTypes.func,
  dispatch: PropTypes.func,
  title: PropTypes.string,
};

MailEditPanel.defaultProps = {
  mail: {},
  onUpdate: () => {},
  dispatch: () => {},
  title: '',
};


export default connect(
  state => ({
    mailsState: state.mails,
  }),
)(MailEditPanel);
