import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  reset as resetVoucher,
  set as setVoucher,
  create as createVoucher,
  beforeCreate as beforeCreateVoucher,
} from '$redux/vouchers/actions';

import Title from 'ui/Title';
import Loading from '$routes/Loading';
import Failed from '$routes/Failed';

import Form from '../Form';

class Vouchers extends React.Component {
  componentDidMount = () => {
    const { dispatch } = this.props;
    dispatch(resetVoucher());
    dispatch(beforeCreateVoucher());
  }

  onCreate = () => {
    const { dispatch, data } = this.props;
    dispatch(createVoucher(data));
  }

  set = (key, value) => {
    const { dispatch } = this.props;
    dispatch(setVoucher(key, value));
  }

  render() {
    const {
      data,
      ui,
      loading,
    } = this.props;

    if (loading) return <Loading />;
    if (!data) return <Failed />;

    return (
      <div>
        <Title>Nuevo código promocional</Title>
        <Form
          action="new"
          data={data}
          ui={ui}
          set={this.set}
          onSend={this.onCreate}
        />
      </div>
    );
  }
}

Vouchers.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.object,
  ui: PropTypes.object,
  loading: PropTypes.bool.isRequired,
};

Vouchers.defaultProps = {
  data: null,
  ui: null,
};

export default connect(
  state => ({
    data: state.vouchers.single,
    ui: state.vouchers.ui,
    loading: state.vouchers.loading,
  }),
)(Vouchers);
