import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';

import ReactTable from 'ui/ReactTable';
import 'react-table/react-table.css';

import { Button } from '@material-ui/core';
import { connect } from 'react-redux';

import {
  request as requestVouchers,
  update as updateVoucher,
} from '$redux/vouchers/actions';

const dateFormat = 'DD-MM-YYYY';

class Grid extends React.Component {
  componentDidUpdate(prevProps) {
    const { filters } = this.props;
    // Typical usage (don't forget to compare props):
    if (filters !== prevProps.filters) {
      const { dispatch, pageSize } = this.props;
      const bindingObject = {
        pageSize,
        page: 0,
        sorted: [],
        filters,
      };
      dispatch(requestVouchers(bindingObject, 'vouchers'));
    }
  }

  fetchData = (state) => {
    let { filters } = this.props;
    const { dispatch, filtersApplied } = this.props;
    filters = {
      ...filtersApplied,
      ...filters,
    };
    const { pageSize, page, sorted } = state;
    const bindingObject = {
      pageSize,
      page,
      sorted,
      filters,
    };
    dispatch(requestVouchers(bindingObject, 'vouchers'));
  }

  enableToggle = (voucherData) => {
    const data = voucherData.original;
    if (!data.selectedTrainingPlan) {
      data.selectedTrainingPlan = 'none';
    }
    data.reenroll = false;
    if (data.reenroll) {
      data.reenroll = true;
    }
    data.active = !data.active;
    this.onUpdate(data.idVoucher, data);
  }

  onUpdate = (idVoucher, voucherData) => {
    const { dispatch } = this.props;
    dispatch(updateVoucher(idVoucher, voucherData));
  }

  render() {
    const {
      data,
      loading,
      pages,
      pageSize,
    } = this.props;
    return (
      <ReactTable
        className="gridResult"
        topBorder
        columns={[
          {
            Header: 'ID',
            accessor: 'idVoucher',
            maxWidth: 40,
          },
          {
            Header: 'Código usable',
            accessor: 'code',
            maxWidth: 150,
          },
          {
            Header: 'Referencia',
            accessor: 'reference',
            maxWidth: 90,
          },
          {
            Header: 'Nombre',
            accessor: 'name',
            width: 130,
          },
          {
            Header: 'Fecha inicio',
            accessor: 'startDate',
            Cell: ({ value }) => (value ? moment(value).format(dateFormat) : '-'),
            maxWidth: 100,
          },
          {
            Header: 'Fecha fin',
            accessor: 'endDate',
            Cell: ({ value }) => (value ? moment(value).format(dateFormat) : '-'),
            maxWidth: 100,
          },
          {
            Header: 'Dto.',
            accessor: 'discountValue',
            style: { textAlign: 'right' },
            Cell: row => `${parseInt(row.original.discountValue, 10)} ${row.original.calcType === 'P' ? '%' : '€'}`,
            maxWidth: 40,
          },
          {
            Header: 'Usos',
            accessor: 'uses',
            maxWidth: 55,
            style: { textAlign: 'center' },
          },
          {
            Header: 'Máx. usos',
            accessor: 'maxUses',
            maxWidth: 90,
            style: { textAlign: 'center' },
          },
          {
            Header: 'Rematrícula',
            accessor: 'reenroll',
            style: { textAlign: 'center' },
            Cell: ({ value }) => (value ? 'Sí' : 'No'),
            maxWidth: 100,
          },
          {
            Header: 'Acumulable',
            accessor: 'isCumulative',
            Cell: ({ value }) => (value ? 'Sí' : 'No'),
            maxWidth: 100,
            style: { textAlign: 'center' },
          },
          {
            Header: 'Estado',
            accessor: 'active',
            Cell: ({ value }) => (value ? 'Activo' : 'Inactivo'),
            maxWidth: 70,
            style: { textAlign: 'center' },
          },
          {
            Header: 'Importe min',
            accessor: 'minValue',
            Cell: row => `${parseInt(row.original.minValue, 10)} €`,
            maxWidth: 100,
            style: { textAlign: 'center' },
          },
          {
            Header: 'Acciones',
            fixed: 'right',
            columns: [
              {
                Cell: row => (
                  <Button onClick={() => {
                    this.enableToggle(row);
                  }
                }
                  >{row.original.active ? 'Desactivar' : 'Activar'}
                  </Button>
                ),
                maxWidth: 100,
                resizable: false,
                sortable: false,
              },
              {
                Cell: row => (
                  <Link to={`/codigos-promocionales/editar/${row.original.idVoucher}`}>
                    <Button>Editar</Button>
                  </Link>
                ),
                maxWidth: 100,
                resizable: false,
                sortable: false,
              },
            ],
          },
        ]}
        manual
        defaultPageSize={pageSize}
        data={data}
        pages={pages}
        loading={loading}
        onFetchData={this.fetchData}
      />
    );
  }
}

Grid.propTypes = {
  data: PropTypes.array,
  filters: PropTypes.object,
  filtersApplied: PropTypes.object,
  pageSize: PropTypes.number,
  pages: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

Grid.defaultProps = {
  filters: {},
  data: [],
  pageSize: 10,
  pages: 0,
  filtersApplied: {},
};

export default connect(
  state => ({
    data: state.vouchers.vouchers,
    loading: state.vouchers.loading,
    pages: state.vouchers.pages,
    filtersApplied: state.vouchers.filters,
  }),
)(Grid);
