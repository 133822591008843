import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  TextField,
  FormControlLabel,
  Switch,
  Button,
  Select,
  Input,
  MenuItem,
  FormControl,
} from '@material-ui/core';
import { Wrapper } from './BasicForm.styles';

// eslint-disable-next-line
class BasicForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      methodologiesChosen: [],
      checked: false,
    };
  }

  handleChange = (event) => {
    this.setState({ methodologiesChosen: event.target.value });
  };

  handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    this.setState({
      methodologiesChosen: value,
      checked: false,
    });
  };

  handleActive = (event) => {
    event.persist();
    const { set } = this.props;
    set('active', event.target.checked);
    this.setState({ checked: event.target.checked });
  }

  render() {
    let code;
    let name;
    let coursePrice;
    let inClassHourPrice;
    let onLineHourPrice;
    let active;
    let idCourseModality;
    let idCourseCategory;
    const {
      data, set, saveCourse, methodologies, modalities, categories,
    } = this.props;
    if (data) {
      ({
        data: {
          code,
          name,
          coursePrice,
          inClassHourPrice,
          onLineHourPrice,
          active,
          idCourseModality,
          idCourseCategory,
        },
      } = this.props);
    }
    const { methodologiesChosen } = this.state;
    return (
      <Wrapper>
        <div className="columns is-vcentered">
          <div className="column is-one-fifth">Nombre</div>
          <div className="column is-two-fifths">
            <TextField
              fullWidth
              defaultValue={name}
              onChange={e => set('name', e.target.value)}
            />
          </div>
        </div>
        <div className="columns is-vcentered">
          <div className="column is-one-fifth">Código producto (SALESFORCE)</div>
          <div className="column is-one-fifth">
            <TextField
              fullWidth
              defaultValue={code}
              onChange={e => set('code', e.target.value)}
            />
          </div>
        </div>
        <div className="columns is-vcentered">
          <div className="column is-one-fifth">Precio total</div>
          <div className="column is-one-fifth">
            <TextField
              fullWidth
              defaultValue={coursePrice}
              onChange={e => set('coursePrice', e.target.value)}
            />
          </div>
        </div>
        <div className="columns is-vcentered">
          <div className="column is-one-fifth">Precio hora presencial</div>
          <div className="column is-one-fifth">
            <TextField
              fullWidth
              defaultValue={inClassHourPrice}
              onChange={e => set('inClassHourPrice', e.target.value)}
            />
          </div>
        </div>
        <div className="columns is-vcentered">
          <div className="column is-one-fifth">Precio hora online</div>
          <div className="column is-one-fifth">
            <TextField
              fullWidth
              defaultValue={onLineHourPrice}
              onChange={e => set('onLineHourPrice', e.target.value)}
            />
          </div>
        </div>

        <div className="columns is-vcentered">
          <div className="column is-one-fifth">Activo</div>
          <FormControlLabel
            control={<Switch color="primary" />}
            checked={data ? !!(active) : this.state.checked} // eslint-disable-line
            onChange={data ? e => set('active', e.target.checked) : e => this.handleActive(e)}
          />
        </div>
        {!methodologies
          ? ''
          : (
            <div className="columns is-vcentered">
              <div className="column is-one-fifth">Metodologías</div>
              <div className="column is-one-fifth">
                <FormControl className="formControl">
                  <Select
                    multiple
                    value={methodologiesChosen}
                    onChange={this.handleChange}
                    input={<Input id="select-multiple" />}
                    className="meth-select"
                  >
                    {methodologies.map(methodology => (
                      <MenuItem key={methodology.name} value={methodology.idMethodology}>
                        {methodology.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>)}
        {!modalities
          ? ''
          : (
            <div className="columns is-vcentered">
              <div className="column is-one-fifth">Modalidades</div>
              <div className="column is-one-fifth">
                <FormControl className="formControl">
                  <Select
                    native
                    value={idCourseModality}
                    onChange={e => set('idCourseModality', e.target.value)}
                    className="filter-item"
                  >
                    <option value={0}>Selecciona la modalidad...</option>
                    {modalities.map(modality => (
                      <option
                        value={modality.idCourseModality}
                        key={modality.idCourseModality}
                      >
                        {modality.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>)}
        {!categories
          ? ''
          : (
            <div className="columns is-vcentered">
              <div className="column is-one-fifth">Categorias</div>
              <div className="column is-one-fifth">
                <FormControl className="formControl">
                  <Select
                    native
                    value={idCourseCategory}
                    onChange={e => set('idCourseCategory', e.target.value)}
                    className="filter-item"
                  >
                    <option value={0}>Selecciona la categoria...</option>
                    {categories.map(category => (
                      <option
                        value={category.idCourseCategory}
                        key={category.idCourseCategory}
                      >
                        {category.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>)}
        {data
          ? (
            <Button color="primary" onClick={() => saveCourse()}>
              Guardar
            </Button>
          )
          : (
            <Link to="/ciclos">
              <Button color="primary" onClick={() => saveCourse(methodologiesChosen)}>
                Guardar
              </Button>
            </Link>
          )}
      </Wrapper>
    );
  }
}

BasicForm.propTypes = {
  data: PropTypes.object,
  set: PropTypes.func.isRequired,
  saveCourse: PropTypes.func.isRequired,
  methodologies: PropTypes.array,
  modalities: PropTypes.array,
  categories: PropTypes.array,
};

BasicForm.defaultProps = {
  data: null,
  methodologies: null,
  modalities: [],
  categories: [],
};

export default BasicForm;
