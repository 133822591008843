
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  margin-top: -15px

  img {
    width: 150px;
  }

  .filters--simple-row {
    align-content: space-around;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: 42px;
    grid-column-gap: .4vw;
    & > * {
      max-height: 100%;
      font-size: 11px;
    }
    .extra-item {
      grid-column: span 2;
      & > * {
        font-size: 11px;
      }
    }
    .semester {
        width: 160px;
    }
  }

  .filters-form {
    width: 100%;
  }
  .apply-filters-button,
  .reset-filters-button {
    min-height: auto;
  }
`;
