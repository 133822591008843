import {
  select, call, put, takeEvery,
} from 'redux-saga/effects';
import api from 'linkia-api';

import {
  REQUEST_UTM_STUDENT_FILTERS,
} from './action-types';

import {
  reqUtmStudentFiltersSucceeded,
  reqUtmStudentFiltersFailed,
} from './actions';


import { tokenSelector } from '../login/selectors';

function* actionUtmStudentFilters(action) {
  const token = yield select(tokenSelector);
  const {
    bindingObject,
    idStudent,
  } = action;
  try {
    bindingObject.idStudent = idStudent;
    const data = yield call(api.utm.getUtmStudentsFiltered, {
      bindingObject, token,
    });
    yield put(reqUtmStudentFiltersSucceeded(data, bindingObject));
  } catch (e) {
    yield put(reqUtmStudentFiltersFailed());
  }
}

export default [
  takeEvery(REQUEST_UTM_STUDENT_FILTERS, actionUtmStudentFilters),
];
