import {
  select, call, put, takeEvery,
} from 'redux-saga/effects';
import api from 'linkia-api';

import {
  LIST,
  GET,
  UPDATE,
  REMOVE,
  CREATE,
} from './action-types';
import {
  list,
  reset,
  requestFailed,
  listSucceeded,
  getSucceeded,
  updateSucceeded,
  removeSucceeded,
  createSucceeded,
} from './actions';

import { tokenSelector } from '../login/selectors';

function* listSemesters() {
  const token = yield select(tokenSelector);
  try {
    const data = yield call(api.semester.listAll, { token });
    yield put(listSucceeded(data));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* getSemester(action) {
  const token = yield select(tokenSelector);
  const { id } = action;
  try {
    const data = yield call(api.semester.get, { token, id });
    yield put(getSucceeded(data));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* updateSemester(action) {
  const token = yield select(tokenSelector);
  const { id, data } = action;
  try {
    const success = yield call(api.semester.update, { token, id, data });
    yield put(updateSucceeded(success));
    yield put(list());
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* removeSemester(action) {
  const token = yield select(tokenSelector);
  const { id } = action;
  try {
    const success = yield call(api.semester.get, { token, id });
    yield put(removeSucceeded(success));
    yield put(list());
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* createSemester(action) {
  const token = yield select(tokenSelector);
  const { data } = action;
  try {
    const success = yield call(api.semester.create, { token, data });
    yield put(createSucceeded(success));
    yield put(reset());
    yield put(list());
  } catch (e) {
    yield put(requestFailed(e));
  }
}

export default [
  takeEvery(LIST, listSemesters),
  takeEvery(GET, getSemester),
  takeEvery(UPDATE, updateSemester),
  takeEvery(REMOVE, removeSemester),
  takeEvery(CREATE, createSemester),
];
