import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs } from '@material-ui/core';
import MethodologyTab from '../Edit/MethodologyTab';
import { Wrapper } from './MethodologyForm.styles';

// eslint-disable-next-line
class MethodologyForm extends React.Component {
  state = {
    selectedTab: 0,
  };

  handleChange = (event, value) => {
    this.setState({ selectedTab: value });
  };

  render() {
    const {
      data,
      addRoadMap,
      reload,
      semesters,
      updateSemester,
      handleActive,
      handleActiveOldTP,
      onClickDelete,
      handleMoodleCode,
    } = this.props;

    let methodologyTab;
    const { selectedTab } = this.state;
    const allTabs = data.trainingPlans.map(trainingPlan => (
      <Tab label={trainingPlan.methodologyName} key={trainingPlan.idMethodology} className="tab" />
    ));
    const currentMethodology = data.trainingPlans[selectedTab];
    if (currentMethodology) {
      methodologyTab = (
        <MethodologyTab
          idCourse={data.idCourse}
          methodology={currentMethodology}
          addRoadMap={addRoadMap}
          reload={reload}
          semesters={semesters}
          updateSemester={updateSemester}
          handleActive={handleActive}
          handleActiveOldTP={handleActiveOldTP}
          onClickDelete={onClickDelete}
          handleMoodleCode={handleMoodleCode}
        />
      );
    }
    return (
      <Wrapper>
        <div className="muiTabs">
          <Tabs
            value={selectedTab}
            onChange={this.handleChange}
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons="on"
          >
            {allTabs}
          </Tabs>
          {methodologyTab}
        </div>
      </Wrapper>
    );
  }
}

MethodologyForm.propTypes = {
  data: PropTypes.object.isRequired,
  addRoadMap: PropTypes.func.isRequired,
  reload: PropTypes.func.isRequired,
  semesters: PropTypes.array.isRequired,
  updateSemester: PropTypes.func.isRequired,
  handleActive: PropTypes.func.isRequired,
  handleActiveOldTP: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  handleMoodleCode: PropTypes.func.isRequired,
};

export default MethodologyForm;
