import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'ui/ReactTable';
import { Button } from '@material-ui/core';
import { RedButton } from './Grid.styles';


const Grid = ({ data, onEdit, removeItem }) => (
  <div>
    <ReactTable
      data={data}
      columns={[
        {
          Header: 'ID',
          accessor: 'idCourseModality',
          maxWidth: 30,
        },
        {
          Header: 'Nombre Modalidad',
          accessor: 'name',
        },
        {
          Header: 'Codigo',
          accessor: 'code',
        },
        {
          Header: 'Acciones',
          fixed: 'right',
          columns: [
            {
              Cell: row => (
                <Button onClick={() => onEdit(row.original.idCourseModality)}>Editar</Button>
              ),
              resizable: false,
              sortable: false,
              maxWidth: 70,
            },
            {
              Cell: row => (
                <RedButton onClick={() => removeItem(row.original.idCourseModality)}>
                    Borrar
                </RedButton>
              ),
              resizable: false,
              sortable: false,
              maxWidth: 100,
            },
          ],
        },
      ]}
    />
  </div>
);

Grid.propTypes = {
  data: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
};

export default Grid;
