export const REQUEST_FAILED = 'COLLABORATING_CENTERS_REQUEST_FAILED';
export const LIST = 'COLLABORATING_CENTERS_LIST';
export const LIST_SUCCEEDED = 'COLLABORATING_CENTERS_LIST_SUCCEEDED';
export const GET = 'COLLABORATING_CENTER_GET';
export const GET_SUCCEEDED = 'COLLABORATING_CENTER_GET_SUCCEEDED';
export const UPDATE = 'COLLABORATING_CENTER_UPDATE';
export const UPDATE_SUCCEEDED = 'COLLABORATING_CENTER_UPDATE_SUCCEEDED';
export const REMOVE = 'COLLABORATING_CENTER_REMOVE';
export const REMOVE_SUCCEEDED = 'COLLABORATING_CENTER_REMOVE_SUCCEEDED';
export const CREATE = 'COLLABORATING_CENTER_CREATE';
export const CREATE_SUCCEEDED = 'COLLABORATING_CENTER_CREATE_SUCCEEDED';
export const SET = 'COLLABORATING_CENTER_SET';
export const RESET = 'COLLABORATING_CENTER_RESET';
