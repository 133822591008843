import dotenv from 'dotenv';
import {
  select, call, put, takeEvery,
} from 'redux-saga/effects';
import api from 'linkia-api';

import {
  LIST,
  GET,
  GET_SUCCEEDED,
  SEND,
  SEND_SUCCEEDED,
  READ_THREAD,
  UNREAD_MESSAGE,
} from './action-types';
import {
  list,
  listSucceeded,
  listFailed,
  getSucceeded,
  getFailed,
  readThread,
  sendSucceeded,
  sendFailed,
} from './actions';

import { tokenSelector } from '../login/selectors';

dotenv.config();
const { NODE_ENV: env } = process.env;

function* reqThreads() {
  const token = yield select(tokenSelector);
  try {
    const threads = yield call(api.staff.me.getThreads, { token });
    yield put(listSucceeded(threads));
  } catch (e) {
    // eslint-disable-next-line no-console
    if (env === 'development' || !e.response || e.response.status !== 401) console.error(e);
    yield put(listFailed());
  }
}

function* reqThread(action) {
  const token = yield select(tokenSelector);
  const { idUser } = action;
  try {
    const messages = yield call(api.staff.me.getThreadByUser, { token, idUser });
    yield put(getSucceeded(messages, idUser));
  } catch (e) {
    // eslint-disable-next-line no-console
    if (env === 'development' || !e.response || e.response.status !== 401) console.error(e);
    yield put(getFailed());
  }
}

function* reqThreadSucceeded(action) {
  // mark as read (manually in local state, then fire endpoint)
  const { idUser } = action;
  yield put(readThread(idUser));
}

function* sendMessage(action) {
  const token = yield select(tokenSelector);
  const { message, idUser } = action;
  try {
    yield call(api.staff.me.postMessage, { token, message, idUser });
    yield put(sendSucceeded());
  } catch (e) {
    // eslint-disable-next-line no-console
    if (env === 'development' || !e.response || e.response.status !== 401) console.error(e);
    yield put(sendFailed());
  }
}

function* sendMessageSucceeded() {
  // request all messages again
  yield put(list());
}

function* readThreadOnBackend(action) {
  const token = yield select(tokenSelector);
  const { idUser } = action;
  try {
    yield call(api.message.readThread, { token, idUser });
  } catch (e) {
    // eslint-disable-next-line no-console
    if (env === 'development' || !e.response || e.response.status !== 401) console.error(e);
  }
}

function* unreadMessageOnBackend(action) {
  const token = yield select(tokenSelector);
  const { idMessage } = action;
  try {
    yield call(api.message.unread, { token, idMessage });
  } catch (e) {
    // eslint-disable-next-line no-console
    if (env === 'development' || !e.response || e.response.status !== 401) console.error(e);
  }
}

export default [
  takeEvery(LIST, reqThreads),
  takeEvery(GET, reqThread),
  takeEvery(GET_SUCCEEDED, reqThreadSucceeded),
  takeEvery(SEND, sendMessage),
  takeEvery(SEND_SUCCEEDED, sendMessageSucceeded),
  takeEvery(READ_THREAD, readThreadOnBackend),
  takeEvery(UNREAD_MESSAGE, unreadMessageOnBackend),
];
