import {
  LIST,
  LIST_SUCCEEDED,
  LIST_FAILED,
  GET,
  GET_SUCCEEDED,
  GET_FAILED,
  SEND,
  SEND_SUCCEEDED,
  SEND_FAILED,
  READ_THREAD,
  UNREAD_MESSAGE,
} from './action-types';
import utils from '../utils';

const initialState = {
  list: null,
  loading: false,
  single: null,
  loadingSingle: false,
};

const messagesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LIST: {
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_SUCCEEDED: {
      const { data } = action;
      return {
        ...state,
        list: data,
        loading: false,
      };
    }
    case LIST_FAILED: {
      utils.dialog({
        type: 'error',
        title: 'Error al recibir los hilos',
        message: 'Lo sentimos, se ha producido un error inesperado...',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case GET: {
      return {
        ...state,
        loadingSingle: true,
      };
    }
    case GET_SUCCEEDED: {
      const { data } = action;
      return {
        ...state,
        single: data,
        loadingSingle: false,
      };
    }
    case GET_FAILED: {
      utils.dialog({
        type: 'error',
        title: 'Error al recibir los mensajes del hilo',
        message: 'Lo sentimos, se ha producido un error inesperado...',
      });
      return {
        ...state,
        loadingSingle: false,
      };
    }
    case SEND: {
      utils.dialog({
        title: 'Mensaje enviado',
        message: 'Tu mensaje se ha enviado con éxito.',
      });
      return {
        ...state,
        loading: true,
      };
    }
    case SEND_SUCCEEDED: {
      return {
        ...state,
        loading: false,
      };
    }
    case SEND_FAILED: {
      utils.dialog({
        type: 'error',
        title: 'Error al enviar tu mensaje',
        message: 'Lo sentimos, se ha producido un error inesperado...',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case READ_THREAD: {
      const { idUser } = action;

      return {
        ...state,
        list: state.list.map((thread) => {
          if (thread.idUser === idUser) return ({ ...thread, read: true });
          return thread;
        }),
        single: state.single.map(m => ({ ...m, read: true })),
      };
    }
    case UNREAD_MESSAGE: {
      const { idMessage } = action;
      const { idUser } = state.single[0];

      return {
        ...state,
        list: state.list.map((thread) => {
          if (thread.idUser === idUser) return ({ ...thread, read: false });
          return thread;
        }),
        single: state.single.map((message) => {
          if (message.idMessage === idMessage) return ({ ...message, read: false });
          return message;
        }),
      };
    }
    default:
      return state;
  }
};

export default messagesReducer;
