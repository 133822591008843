import styled from 'styled-components';

export const Wrapper = styled.div`
  .check-fields {
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
  }
  .check-field {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
  }
  .form-actions {
    margin-top: 30px;

    & > * {
      margin-right: 20px;
    }
  }
`;
