import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactTable from 'ui/ReactTable';
import { Button } from '@material-ui/core';
import SubTitle from '../../../../ui/SubTitle';
import { Wrapper } from './enrollmentsGrid.styles';

const dateFormat = 'DD-MM-YYYY';

const EnrollmentsGrid = ({
  title, data, onClick, defaultPageSize,
}) => (
  <Wrapper>
    <div className="student-enrollments">
      <SubTitle>{title}</SubTitle>
      <div className="columns is-multiline is-narrow content">
        <div className="column">
          <ReactTable
            defaultPageSize={defaultPageSize}
            data={data}
            columns={[
              {
                Header: () => <div style={{ textAlign: 'center' }}>ID</div>,
                accessor: 'idEnrollment',
                maxWidth: 50,
                style: { textAlign: 'center' },
              },
              {
                Header: () => <div style={{ textAlign: 'center' }}>FECHA</div>,
                accessor: 'entryData',
                Cell: ({ value }) => (value ? moment(value).format(dateFormat) : '-'),
                style: { textAlign: 'center' },
                maxWidth: 110,
              },
              {
                Header: 'Ciclo',
                accessor: 'courseName',
              },
              {
                Header: () => <div style={{ textAlign: 'center' }}>Metodología</div>,
                accessor: 'methodology',
                style: { textAlign: 'center' },
                maxWidth: 130,
                width: 130,
              },
              {
                Header: 'Semestre',
                accessor: 'semester',
              },
              {
                Header: () => <div style={{ textAlign: 'center' }}>Curso</div>,
                accessor: 'courseYear',
                style: { textAlign: 'center' },
                maxWidth: 80,
              },
              {
                Header: () => <div style={{ textAlign: 'center' }}>Estado</div>,
                style: { textAlign: 'center' },
                accessor: 'stateName',
              },
              {
                Header: '',
                fixed: 'right',
                columns: [
                  {
                    Cell: row => (
                      <Button onClick={onClick(row.original.idEnrollment)}>
                        FACTURA
                      </Button>
                    ),
                    maxWidth: 100,
                    resizable: false,
                    sortable: false,
                  },
                ],
              },
            ]}
          />
        </div>
      </div>
    </div>
  </Wrapper>
);

EnrollmentsGrid.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array,
  onClick: PropTypes.func.isRequired,
  defaultPageSize: PropTypes.number,
};

EnrollmentsGrid.defaultProps = {
  data: [],
  defaultPageSize: 5,
};

export default EnrollmentsGrid;
