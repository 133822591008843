import React from 'react';
import Title from 'ui/Title';

import Form from './Form';

// eslint-disable-next-line react/prefer-stateless-function
class UtmComercial extends React.Component {
  render() {
    return (
      <div>
        <Title>Crear UTM</Title>
        <Form
          action="new"
        />
      </div>
    );
  }
}

UtmComercial.propTypes = {
};

UtmComercial.defaultProps = {
};

export default UtmComercial;
