import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  list as listUtmFormCourses,
  get as getModuleUtmFormCourse,
  reset as resetModuleUtmFormCourse,
  update as updateModuleUtmFormCourse,
  set as setModuleUtmFormCourse,
  create as createModuleUtmFormCourse,
  remove as removeModuleUtmFormCourse,
} from '$redux/utm-form-courses/actions';

import {
  list as listCourses,
} from '$redux/courses/actions';

import { Button } from '@material-ui/core';
import Title from 'ui/Title';
import Loading from '$routes/Loading';
import Failed from '$routes/Failed';

import Grid from './Grid';
import Editor from './Editor';

class ModuleUtmFormCourse extends React.Component {
  state = {
    editorOpen: false,
    editorAction: 'new',
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(listUtmFormCourses());
    dispatch(listCourses());
  }

  onCreate = () => {
    const { dispatch, single } = this.props;
    dispatch(createModuleUtmFormCourse(single));
  }

  onUpdate = () => {
    const { dispatch, single } = this.props;
    dispatch(updateModuleUtmFormCourse(single.idUtmFormCourse, single));
  }

  set = (key, value) => {
    const { dispatch } = this.props;
    dispatch(setModuleUtmFormCourse(key, value));
  }

  openEditor = (id) => {
    const { dispatch } = this.props;
    if (id) {
      dispatch(getModuleUtmFormCourse(id));
      this.setState({ editorOpen: true, editorAction: 'edit' });
    } else {
      dispatch(resetModuleUtmFormCourse());
      this.setState({ editorOpen: true, editorAction: 'new' });
    }
  }

  removeModuleUtmFormCourse = (id) => {
    const { dispatch } = this.props;
    if (id) {
      dispatch(removeModuleUtmFormCourse(id));
    }
  }

  render() {
    const {
      data,
      courses,
      single,
      loading,
    } = this.props;

    if (loading) return <Loading />;
    if (!data) return <Failed />;
    if (!courses) return <Loading />;

    const { editorOpen, editorAction } = this.state;

    return (
      <div>
        <Title>
          Utm Forms - Ciclos.
          <Button onClick={() => this.openEditor()} size="small">Crear</Button>
        </Title>
        {
          editorOpen ? (
            <Editor
              action={editorAction}
              data={single}
              courses={courses}
              set={this.set}
              onSend={editorAction === 'new' ? this.onCreate : this.onUpdate}
              onClose={() => this.setState({ editorOpen: false })}
            />
          ) : null
        }
        <Grid
          data={data}
          loading={loading}
          onEdit={this.openEditor}
          removeItem={this.removeModuleUtmFormCourse}
        />
      </div>
    );
  }
}

ModuleUtmFormCourse.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.array,
  single: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  courses: PropTypes.array,
};

ModuleUtmFormCourse.defaultProps = {
  data: null,
  courses: null,
};

export default connect(
  state => ({
    courses: state.courses.list,
    data: state.utmFormCourses.list,
    single: state.utmFormCourses.single,
    loading: state.utmFormCourses.loading,
  }),
)(ModuleUtmFormCourse);
