import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Spinner from 'react-spinkit';

import { colors } from 'ui/theme';

import Tooltip from './Tooltip';

import {
  Wrapper,
  BallWrapper,
  Ball,
  UpperInfo,
  Icon,
} from './Dot.styles';

// eslint-disable-next-line
class Dot extends React.Component {
  render() {
    const {
      loading,
      type,
      date,
      cost,
      onClick,
    } = this.props;
    const diffDays = moment(date).diff(moment(), 'days');
    const dateFormat = 'DD/MM/YYYY';
    const dateWithFormat = moment(date).format(dateFormat);

    return (
      <Wrapper loading={loading}>
        {
          type && type !== 'disabled' ? (
            <UpperInfo>
              <Tooltip position="top" type={type}>
                {type === 'normal' ? `Faltan ${diffDays} días` : null}
                {type === 'warning' ? 'Menos de 24h' : null}
                {type === 'error' ? `Vencido hace ${-diffDays} día${-diffDays !== 1 ? 's' : ''}` : null}
                {type === 'success' ? `Pagado el ${dateWithFormat}` : null}
              </Tooltip>
              <Icon>
                {
                  loading ? (
                    <div style={{ transform: 'scale(1)' }}>
                      <Spinner
                        size="small"
                        name="cube-grid"
                        fadeIn="none"
                        color={colors[type]}
                      />
                    </div>
                  ) : (
                    <span role="img" aria-label="Icon">
                      {type === 'normal' ? '🏁' : null}
                      {type === 'warning' ? '⚠️' : null}
                      {type === 'error' ? '❌' : null}
                      {type === 'success' ? '✔️' : null}
                    </span>
                  )
                }
              </Icon>
            </UpperInfo>
          ) : null
        }
        <BallWrapper type={type} loading={loading} onClick={!loading ? onClick : undefined}>
          <Ball type={type} />
        </BallWrapper>
        <Tooltip position="bottom" type={type}>
          {cost}€
        </Tooltip>
      </Wrapper>
    );
  }
}

Dot.propTypes = {
  loading: PropTypes.bool,
  type: PropTypes.oneOf(['normal', 'warning', 'error', 'success', 'disabled']),
  date: PropTypes.instanceOf(Date).isRequired,
  cost: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

Dot.defaultProps = {
  loading: false,
  type: 'disabled',
};

export default Dot;
