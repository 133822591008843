import React from 'react';

import ReactTable from 'react-table';
import withFixedColumns from 'react-table-hoc-fixed-columns';

import { Wrapper } from './ReactTable.styles';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

class CustomReactTable extends React.Component {
  constructor(props) {
    super(props);
    this.dateFormat = 'DD-MM-YYYY';
  }

  render() {
    const { props } = this;

    return (
      <Wrapper topBorder={props.topBorder}>
        <ReactTableFixedColumns
          {...props}
          previousText="Página anterior"
          nextText="Página siguiente"
          pageText="Página"
          ofText="de"
          rowsText="filas"
          noDataText="No hay información que mostrar"
          pageSizeOptions={[5, 10, 25, 50, 100, 200, 500]}
        />
      </Wrapper>
    );
  }
}

CustomReactTable.defaultProps = {
  topBorder: false,
  defaultPageSize: 10,
  className: '-highlight',
};

export default CustomReactTable;
