import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { reset as loginReset, refreshPermissions } from '$redux/login/actions';

import Login from '$routes/Login';
import Private from './Private';

class App extends React.Component {
  constructor(props) {
    super(props);
    const { dispatch } = props;
    dispatch(loginReset());

    this.state = {
      loginResetDone: false,
      loginRequired: this.loginRequired(props),
    };

    // only already logged users need to refresh permissions
    const { login: { token } } = props;
    if (token) dispatch(refreshPermissions());
  }

  componentDidMount = () => {
    this.setState({ loginResetDone: true });
  }

  componentWillReceiveProps = async (props) => {
    this.setState({ loginRequired: this.loginRequired(props) });
  }

  loginRequired = (props) => {
    const { history, location: { pathname }, login: { token } } = props;

    // already on login route
    if (pathname === '/login') {
      if (!token) return true; // login required

      // login not needed
      history.push('/');
      return false;
    }

    // on every other route, require login if no token is set
    if (!token) {
      history.push('/login');
      return true;
    }

    return false;
  }

  render() {
    const { loginResetDone, loginRequired } = this.state;
    if (!loginResetDone) return null;

    const { login: { permissionsReady } } = this.props;
    if (loginRequired || !permissionsReady) return <Login />;
    return <Private />;
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  login: PropTypes.object.isRequired,
};

export default withRouter(connect(
  state => ({
    login: state.login,
  }),
)(App));
