import React from 'react';
import 'react-table/react-table.css';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SubTitle from 'ui/SubTitle';
import Loading from '$routes/Loading';
import {
  requestEnrollmentCheckMethodology,
  requestEnrollmentChangeMethodology,
} from '$redux/enrollments/actions';
import {
  Button,
} from '@material-ui/core';
import RoadMapMethodology from './RoadMapMethodology';

class MethodologyChange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: [],
      units: [],
    };
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    dispatch(requestEnrollmentCheckMethodology(
      match.params.idEnrollment,
      match.params.idTrainingPlan,
      match.params.idRoadMap,
      match.params.idAcademy,
    ));
  }

  addUnit = (module, idUnit, checked, semester) => {
    const { units } = this.state;

    const already = units.find(selected => selected.idUnit === idUnit);
    const alreadyIndex = units.findIndex(selected => selected.idUnit === idUnit);

    if (already && !checked) {
      units.splice(alreadyIndex, 1);

      return units;
    }

    if (already && checked) {
      Object.assign(units[alreadyIndex], {
        ...already,
        courseYear: semester.courseYear,
        semester: semester.semester,
        checked,
      });
    } else if (!already && checked) {
      units.push({
        idUnit,
        idModule: module.idModule,
        courseYear: semester.courseYear,
        semester: semester.semester,
        checked,
      });
    }

    return units;
  }

  onCheckUnit = (module, idUnit, checked, semester) => {
    const units = this.addUnit(
      module,
      idUnit,
      checked,
      semester,
    );

    this.setState({
      units,
    });

    this.onCheckModule(
      module,
      semester,
      units.length > 0,
    );
  }

  onCheckModule = (module, semester, checked, idRoadMapModule) => {
    const { modules, units } = this.state;

    const already = modules.find(selected => selected.idModule === module.idModule);
    const alreadyIndex = modules.findIndex(selected => selected.idModule === module.idModule);

    if (already) {
      already.courseYear = semester.courseYear;
      already.semester = semester.semester;
      already.checked = checked;
      Object.assign(modules[alreadyIndex], already);
    } else {
      modules.push({
        idModule: module.idModule,
        courseYear: semester.courseYear,
        semester: semester.semester,
        inClassHourPrice: module.inClassHourPrice,
        inClassHours: module.inClassHours,
        onLineHourPrice: module.onLineHourPrice,
        onLineHours: module.onLineHours,
        price: module.price,
        idRoadMapModule,
        checked,
      });
    }

    this.setState({
      modules,
    });

    const alreadyUnit = units.find(selected => selected.idModule === module.idModule);

    if (!checked || (checked && !alreadyUnit)) {
      module.Units.forEach((unit) => {
        this.addUnit(
          module,
          unit.idUnit,
          checked,
          semester,
        );
      });
    }
    return true;
  }

  // eslint-disable-next-line class-methods-use-this
  changeMethodology() {
    const {
      modules,
      units,
    } = this.state;

    const {
      dispatch,
      match,
      destSemesters,
    } = this.props;

    const roadMap = {
      ...destSemesters,
    };

    const modulesChecked = modules.filter(
      module => module.checked === true,
    );

    const unitsChecked = units.filter(
      unit => unit.checked === true,
    );

    roadMap.RoadMapModules.forEach((module) => {
      const moduleIsCheck = modulesChecked.find(
        mc => mc.idModule === module.idModule,
      );

      const checked = (!!moduleIsCheck) || false;

      // eslint-disable-next-line no-param-reassign
      module.Module.checked = checked;

      if (checked) {
        // eslint-disable-next-line no-param-reassign
        module.ModuleSemester.courseYear = moduleIsCheck.courseYear;
        // eslint-disable-next-line no-param-reassign
        module.ModuleSemester.semester = moduleIsCheck.semester;
        // eslint-disable-next-line no-param-reassign
        module.ModuleSemester.idModuleSemester = moduleIsCheck.idModuleSemester;

        module.Module.Units.forEach((unit) => {
          const unitIsCheck = unitsChecked.find(
            uc => uc.idUnit === unit.idUnit,
          );

          // eslint-disable-next-line no-param-reassign
          unit.checked = (!!unitIsCheck) || false;
        });
      }
    });

    dispatch(requestEnrollmentChangeMethodology(
      match.params.idEnrollment,
      match.params.idTrainingPlan,
      match.params.idRoadMap,
      match.params.idAcademy,
      roadMap,
    ));
  }

  render() {
    const {
      loading,
      academy,
      destSemesters,
      enrollment,
      enrollmentTransactions,
      origRoadMap,
      redirectTo,
      history,
    } = this.props;

    const {
      modules,
      units,
    } = this.state;

    if (redirectTo !== '') {
      history.push(redirectTo);
      return true;
    }

    if (loading
      || !academy
      || !destSemesters
      || !enrollment
      || !enrollmentTransactions
      || !origRoadMap) return <Loading />;

    return (
      <div className="basic-information">
        <SubTitle>
          Cambiar metodología a la matrícula
        </SubTitle>
        <div className="form-actions">
          <ul>
            <li>
              <b>Curso</b>:
              De <i>{ origRoadMap.TrainingPlan.Course.name }</i>
              &nbsp;a <i><b>{ destSemesters.TrainingPlan.Course.name }</b></i>
            </li>
            <li>
              <b>Academia</b>: <i><b>{ academy.name }</b></i>
            </li>
            <li>
              <b>RoadMap:</b>
              De <i>{ origRoadMap.name }</i>
              &nbsp;a <i><b>{ destSemesters.name }</b></i>
            </li>
            <li>
              <b>Metodología</b>:
              De <i>{ origRoadMap.TrainingPlan.Methodology.name }</i>
              &nbsp;a <i><b>{ destSemesters.TrainingPlan.Methodology.name }</b></i>
            </li>
          </ul>
        </div>
        <RoadMapMethodology
          data={destSemesters}
          onCheckModule={this.onCheckModule}
          onCheckUnit={this.onCheckUnit}
          modules={modules}
          units={units}
        />
        <div className="columns is-hcentered">
          <div className="column is-one-fifth">
            <Button color="primary" onClick={() => this.changeMethodology()} disabled={modules.filter(module => module.checked === true).length === 0}>
              Cambiar Metodología
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

MethodologyChange.propTypes = {
  match: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  academy: PropTypes.any,
  destSemesters: PropTypes.any,
  enrollment: PropTypes.any,
  enrollmentTransactions: PropTypes.any,
  origRoadMap: PropTypes.any,
  redirectTo: PropTypes.string,
  history: PropTypes.any,
};

MethodologyChange.defaultProps = {
  loading: true,
  academy: null,
  destSemesters: null,
  enrollment: null,
  enrollmentTransactions: [],
  origRoadMap: null,
  redirectTo: '',
  history: null,
};

export default connect(
  state => ({
    loading: state.enrollments.loading,
    academy: state.enrollments.academy,
    destSemesters: state.enrollments.destSemesters,
    enrollment: state.enrollments.enrollment,
    enrollmentTransactions: state.enrollments.enrollmentTransactions,
    origRoadMap: state.enrollments.origRoadMap,
    redirectTo: state.enrollments.redirectTo,
  }),
)(MethodologyChange);
