import styled from 'styled-components';
import { colors } from 'ui/theme';

const ballSize = 12;

export const Wrapper = styled.div`
  position: relative;

  opacity: ${({ loading }) => (loading ? 0.5 : 'unset')};
  transition: opacity 500ms ease-out;
`;

export const BallWrapper = styled.div`
  cursor: ${({ loading }) => (!loading ? 'pointer' : 'unset')};

  margin: 7px;
  padding: 3px;

  border-radius: ${ballSize * 2}px;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ type }) => colors[type]};

  :hover > * {
    transform: ${({ loading }) => (!loading ? 'scale(1.6)' : 'unset')};
  }
`;

export const Ball = styled.div`
  transition: transform 200ms ease-out;

  width: ${ballSize}px;
  height: ${ballSize}px;

  border-radius: ${ballSize}px;
  background: ${({ type }) => colors[type]};
`;

export const UpperInfo = styled.div`
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
`;

export const Icon = styled.div`
  margin-top: 7px;
  font-size: 24px;

  height: 36px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
