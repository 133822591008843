import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';

import ReactTable from 'ui/ReactTable';
import 'react-table/react-table.css';

import { Button } from '@material-ui/core';
import { connect } from 'react-redux';

import { requestGetServicesFilterFiltered as requestServices } from '$redux/services/actions';
import ServiceIcon from '$components/Service/service-icon';
import ServiceResult from '$components/Service/service-result';

import {
  OUTLOOK_ICON,
  OUTLOOK_CHECK_ICON,
  OUTLOOK_GROUP_ICON,
  ADOBE_ICON,
  MOODLE_ICON,
  MOODLE_101_ICON,
  MOODLE_CHECK_ICON,
  MOODLE_COHORT_ICON,
  MOODLE_ENROLLMENT_ICON,
  MOODLE_EXTRA_ICON,
  LDAP_ICON,
  SERVICE_CALENDAR_ICON,
} from '../../constants';

const dateFormat = 'DD-MM-YYYY';

class Grid extends React.Component {
  componentDidUpdate(prevProps) {
    const { filters } = this.props;
    // Typical usage (don't forget to compare props):
    if (filters !== prevProps.filters) {
      const {
        dispatch,
        pageSize,
      } = this.props;
      const bindingObject = {
        pageSize,
        page: 0,
        sorted: [],
        filters,
      };
      dispatch(
        requestServices(bindingObject, 'services'),
      );
    }
  }

  fetchData = (state) => {
    let { filters } = this.props;
    const {
      dispatch,
      filtersApplied,
    } = this.props;
    filters = {
      ...filtersApplied,
      ...filters,
    };
    const {
      pageSize,
      page,
      sorted,
    } = state;
    const bindingObject = {
      pageSize,
      page,
      sorted,
      filters,
    };
    dispatch(
      requestServices(bindingObject, 'services'),
    );
  };

  render() {
    const {
      data,
      loading,
      pages,
      pageSize,
      openLogWindow,
    } = this.props;

    function showName(row) {
      let result = '';
      if (row.User) {
        result += row.User.name;
      }
      if (row.User && row.User.Student && row.User.Student.idDocumentNumber) {
        result += ` (${row.User.Student.idDocumentNumber})`;
      } else {
        result += ' ( sin documento )';
      }

      return result;
    }

    return (
      <ReactTable
        className="gridResult"
        topBorder
        columns={[
          {
            Header: 'ID',
            columns: [
              {
                Cell: row => (
                  showName(row.original)
                ),
                resizable: false,
                sortable: false,
              },
            ],
            maxWidth: 10,
          },
          {
            Header: ServiceIcon(OUTLOOK_ICON),
            accessor: 'outlook',
            Cell: ({ value }) => (
              ServiceResult(value)
            ),
            maxWidth: 56,
          },
          {
            Header: ServiceIcon(OUTLOOK_CHECK_ICON),
            accessor: 'outlookCheck',
            Cell: ({ value }) => (
              ServiceResult(value)
            ),
            maxWidth: 56,
          },
          {
            Header: ServiceIcon(OUTLOOK_GROUP_ICON),
            accessor: 'outlookGroup',
            Cell: ({ value }) => (
              ServiceResult(value)
            ),
            width: 56,
          },
          {
            Header: ServiceIcon(LDAP_ICON),
            accessor: 'ldap',
            Cell: ({ value }) => (
              ServiceResult(value)
            ),
            width: 56,
          },
          {
            Header: ServiceIcon(ADOBE_ICON),
            accessor: 'adobe',
            Cell: ({ value }) => (
              ServiceResult(value)
            ),
            width: 56,
          },
          {
            Header: ServiceIcon(MOODLE_ICON),
            accessor: 'alexia',
            Cell: ({ value }) => (
              ServiceResult(value)
            ),
            width: 56,
          },
          {
            Header: ServiceIcon(MOODLE_CHECK_ICON),
            accessor: 'alexiaCheck',
            Cell: ({ value }) => (
              ServiceResult(value)
            ),
            width: 56,
          },
          {
            Header: ServiceIcon(MOODLE_EXTRA_ICON),
            accessor: 'alexiaExtra',
            Cell: ({ value }) => (
              ServiceResult(value)
            ),
            width: 56,
          },
          {
            Header: ServiceIcon(MOODLE_COHORT_ICON),
            accessor: 'alexiaCohort',
            Cell: ({ value }) => (
              ServiceResult(value)
            ),
            width: 56,
          },
          {
            Header: ServiceIcon(MOODLE_ENROLLMENT_ICON),
            accessor: 'alexiaEnrollment',
            Cell: ({ value }) => (
              ServiceResult(value)
            ),
            width: 56,
          },
          {
            Header: ServiceIcon(MOODLE_101_ICON),
            accessor: 'alexia101',
            Cell: ({ value }) => (
              ServiceResult(value)
            ),
            width: 56,
          },
          {
            Header: ServiceIcon(SERVICE_CALENDAR_ICON),
            accessor: 'createdAt',
            Cell: ({ value }) => (value ? moment(value)
              .format(dateFormat) : '-'),
            maxWidth: 80,
          },
          {
            Header: ServiceIcon(SERVICE_CALENDAR_ICON),
            accessor: 'updatedAt',
            Cell: ({ value }) => (value ? moment(value)
              .format(dateFormat) : '-'),
            maxWidth: 80,
          },
          {
            Header: 'Acciones',
            fixed: 'right',
            columns: [
              {
                Cell: row => (
                  <Link to={`/servicios/acciones/${row.original.idUserService}`}>
                    <Button>VER</Button>
                  </Link>
                ),
                maxWidth: 50,
                resizable: false,
                sortable: false,
              },
              {
                Cell: row => (
                  <Button onClick={() => openLogWindow(row.original.idUserService)}>
                    Ver Log
                  </Button>
                ),
                maxWidth: 100,
                resizable: false,
                sortable: false,
              },
            ],
          },
        ]}
        manual
        defaultPageSize={pageSize}
        data={data}
        pages={pages}
        loading={loading}
        onFetchData={this.fetchData}
      />
    );
  }
}

Grid.propTypes = {
  data: PropTypes.array,
  filters: PropTypes.object,
  filtersApplied: PropTypes.object,
  pageSize: PropTypes.number,
  pages: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  openLogWindow: PropTypes.func.isRequired,
};

Grid.defaultProps = {
  filters: {},
  data: [],
  pageSize: 10,
  pages: 0,
  filtersApplied: {},
};

export default connect(
  state => ({
    data: state.services.data,
    loading: state.services.loading,
    pages: state.services.pages,
    filtersApplied: state.services.filters,
  }),
)(Grid);
