import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ReactTable from 'ui/ReactTable';
import 'react-table/react-table.css';

import theme from 'ui/theme';
import grey from '@material-ui/core/colors/grey';
import {
  Checkbox,
  withStyles,
} from '@material-ui/core';

import {
  requestEnrollmentHold as requestEnrollments,
} from '$redux/enrollments/actions';

import Failed from '$routes/Failed';

import { Wrapper } from './styles';

const StyledCheckbox = withStyles({
  root: {
    height: 'auto',
    width: 'auto',
    transform: 'translateY(-2px)',
    padding: 0,

    // custom color
    color: `${grey[500]} !important`,
  },
})(props => (
  <Checkbox {...props} />
));

class Grid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10,
    };
  }

  componentDidUpdate(prevProps) {
    const { filters, reload } = this.props;
    const { pageSize } = this.state;

    // Typical usage (don't forget to compare props):
    if (filters !== prevProps.filters || reload) {
      const { dispatch } = this.props;
      const bindingObject = {
        pageSize,
        page: 0,
        sorted: [],
        filters,
      };
      dispatch(requestEnrollments(bindingObject, filters.idSemester));
    }
  }

  fetchData = (state) => {
    const { filters, dispatch } = this.props;
    const { pageSize, page, sorted } = state;
    const bindingObject = {
      pageSize,
      page,
      sorted,
      filters,
    };
    if (filters.idSemester) {
      dispatch(requestEnrollments(bindingObject, filters.idSemester));
      this.setState({ pageSize });
    }
  }

  render() {
    const {
      loading, data, pages,
    } = this.props;

    const { pageSize } = this.state;

    if (!data) return <Failed />;

    const {
      selections, setSelection,
    } = this.props;

    return (
      <Wrapper theme={theme}>
        <ReactTable
          topBorder
          columns={[
            {
              Header: 'Seleccionar',
              Cell: ({
                original,
              }) => {
                const selected = !!selections.find(s => s.idEnrollment === original.idEnrollment);
                return (
                  <StyledCheckbox
                    checked={selected}
                    onChange={() => setSelection(original, !selected)}
                  />
                );
              },
              width: 120,
              sortable: false,
              filterable: false,
            },
            {
              Header: 'Estudiante',
              accessor: 'name',
              width: 220,
              sortable: false,
              filterable: false,
            },
            {
              Header: 'Nombre del curso',
              accessor: 'courseName',
              width: 250,
              sortable: false,
              filterable: false,
            },
            {
              Header: 'Su Año',
              accessor: 'courseYear',
              width: 80,
              sortable: false,
              filterable: false,
            },
            {
              Header: 'Su Semestre',
              accessor: 'semester',
              width: 130,
              sortable: false,
              filterable: false,
            },
            {
              Header: 'Modulos',
              accessor: 'modules',
              Cell: ({ value }) => (
                <ul>
                  {
                    value.map(item => (<li>{item.code}: {item.name}</li>))
                  }
                </ul>
              ),
            },
          ]}
          manual
          defaultPageSize={pageSize}
          data={data}
          pages={pages}
          onFetchData={this.fetchData}
          loading={loading} // Display the loading overlay when we need it
        />
      </Wrapper>
    );
  }
}

Grid.propTypes = {
  dispatch: PropTypes.func.isRequired,
  filters: PropTypes.object,
  data: PropTypes.array,
  pageSize: PropTypes.number,
  pages: PropTypes.number,
  reload: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  selections: PropTypes.array.isRequired,
  setSelection: PropTypes.func.isRequired,
};

Grid.defaultProps = {
  filters: {},
  data: [],
  pages: 1,
  reload: false,
  pageSize: 10,
};

export default connect(
  state => ({
    data: state.enrollments.list,
    pages: state.enrollments.pages,
    loading: state.enrollments.loading,
    filtersApplied: state.enrollments.filters,
    reload: state.enrollments.reload,
    log: state.enrollments.log,
  }),
)(Grid);
