import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';

import Title from 'ui/Title';
import theme from 'ui/theme';

import { Wrapper } from './styles';
import Grid from './Grid';
import Filters from './Filters';

class Vouchers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {},
    };
  }

  applyFilters = (filtersSelected) => {
    this.setState({ filters: filtersSelected });
  }

  render() {
    const { data, loading } = this.props;
    const { filters } = this.state;

    return (
      <Wrapper theme={theme}>
        <Title>
          Códigos promocionales
          <Link to="/codigos-promocionales/nuevo">
            <Button size="small">Nuevo código promocional</Button>
          </Link>
        </Title>
        <Filters applyFilters={this.applyFilters} />
        <Grid
          data={data}
          loading={loading}
          filters={filters}
        />
      </Wrapper>
    );
  }
}

Vouchers.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool.isRequired,
};

Vouchers.defaultProps = {
  data: null,
};

export default connect(
  state => ({
    data: state.vouchers.list,
    loading: state.vouchers.loading,
  }),
)(Vouchers);
