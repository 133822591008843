import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import theme from 'ui/theme';
import {
  Button,
} from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import themeFilters from 'ui/muiFiltersTheme';
import { canSelector } from '$redux/login/selectors';
import { filtersRequest, cleanFiltersRequest } from '$redux/enrollments/actions';
import Failed from '$routes/Failed';
import Loading from '$routes/Loading';
import { Wrapper } from './Filters.styles';

class Filters extends React.Component {
  constructor(props) {
    super(props);
    const {
      idState,
      idAcademy,
      idTrainingPlan,
      idSemester,
      idDiscount,
      name,
      surname1,
      paid,
      paymentMethod,
      pendingDiscuntsValidation,
      reenroll,
      idCollaboratingCenter,
      email,
      secondaryEmail,
      collaboratingCenterStudentEmail,
    } = props.filtersApplied;
    this.state = {
      idState,
      idAcademy,
      idTrainingPlan,
      idSemester,
      idDiscount,
      name,
      surname1,
      paid,
      paymentMethod,
      pendingDiscuntsValidation,
      reenroll,
      idCollaboratingCenter,
      email,
      secondaryEmail,
      collaboratingCenterStudentEmail,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(filtersRequest());
  }

  onApplyFilters = (e) => {
    e.preventDefault();
    const { applyFilters } = this.props;

    const {
      idState,
      idAcademy,
      idTrainingPlan,
      idSemester,
      idDiscount,
      name,
      surname1,
      paid,
      paymentMethod,
      pendingDiscuntsValidation,
      hold,
      reenroll,
      idCollaboratingCenter,
      email,
      secondaryEmail,
      collaboratingCenterStudentEmail,
    } = this.state;
    applyFilters({
      idState,
      idAcademy,
      idTrainingPlan,
      idSemester,
      idDiscount,
      name,
      surname1,
      paid,
      paymentMethod,
      pendingDiscuntsValidation,
      hold,
      reenroll,
      idCollaboratingCenter,
      email,
      secondaryEmail,
      collaboratingCenterStudentEmail,
    });
  };

  resetFilters = async (e) => {
    e.preventDefault();
    const { dispatch } = this.props;
    dispatch(cleanFiltersRequest());
    dispatch(filtersRequest());
  }

  handleChange = name => (event) => {
    if (name === 'pendingDiscuntsValidation' || name === 'hold') {
      const { checked } = event.target;
      this.setState({ [name]: checked });
    } else {
      this.setState({ [name]: event.target.value });
    }
  };

  render() {
    const {
      filtersData, loading, filtersApplied,
    } = this.props;
    let defaultAcademy = <option value={0}>Centro</option>;

    if (loading) return <Loading />;
    if (!filtersData || !filtersData.states) return <Failed />;

    if (filtersData.academies.length === 1) {
      defaultAcademy = (
        <option
          key={filtersData.academies[0].idAcademy}
          value={filtersData.academies[0].idAcademy}
        >
          {filtersData.academies[0].name}
        </option>
      );
    }

    const trainingPlansList = filtersData.trainingPlans.map(
      item => (
        <option key={item.idTrainingPlan} value={item.idTrainingPlan}>
          {`${item.courseCode} ${item.methodologyName}`}
        </option>
      ),
    );

    const semestersList = filtersData.semesters.map(
      item => (
        <option key={item.idSemester} value={item.idSemester}>{item.name} {item.courseYear}</option>
      ),
    );

    const collaboratingCentersList = filtersData.collaboratingCenters.map(
      item => (
        <option key={item.idCollaboratingCenter} value={item.idCollaboratingCenter}>
          {item.name}
        </option>
      ),
    );

    return (
      <Wrapper theme={theme}>
        <MuiThemeProvider theme={themeFilters}>
          <form onSubmit={this.onApplyFilters} className="filters-form">
            <div className="filters">
              <Select
                native
                onChange={this.handleChange('idTrainingPlan')}
                className="filter-item"
                defaultValue={filtersApplied.idTrainingPlan ? filtersApplied.idTrainingPlan : 0}
              >
                <option value={0}>Ciclo</option>
                {trainingPlansList}
              </Select>
              <Select
                native
                onChange={this.handleChange('idSemester')}
                className="filter-item semester"
                defaultValue={filtersApplied.idSemester ? filtersApplied.idSemester : 0}
              >
                <option value={0}>Semestre</option>
                {semestersList}
              </Select>
              <Select
                native
                onChange={this.handleChange('idAcademy')}
                className="filter-item"
                defaultValue={filtersApplied.idAcademy ? filtersApplied.idAcademy : 0}
              >
                {defaultAcademy}
                {filtersData.academies.map(
                  item => (
                    <option key={item.idAcademy} value={item.idAcademy}>{item.name}</option>
                  ),
                )};
              </Select>
              <Select
                native
                onChange={this.handleChange('idCollaboratingCenter')}
                className="filter-item"
                defaultValue={filtersApplied.idCollaboratingCenter
                  ? filtersApplied.idCollaboratingCenter
                  : 0
                }
              >
                <option value={0}>Centros Colaboradores</option>
                {collaboratingCentersList}
              </Select>
              <Select
                native
                onChange={this.handleChange('reenroll')}
                className="filter-item"
                defaultValue={filtersApplied.reenroll ? filtersApplied.reenroll : -1}
              >
                <option value={-1}>Tipo matrícula</option>
                <option value={0}>Nueva</option>
                <option value={1}>Rematrícula</option>
              </Select>
              <Button
                color="primary"
                type="submit"
                className="apply-filters-button"
              >
                APLICAR FILTROS
              </Button>
              <Button
                color="secondary"
                onClick={this.resetFilters}
                className="reset-filters-button"
              >
                LIMPIAR FILTROS
              </Button>
            </div>
          </form>
        </MuiThemeProvider>
      </Wrapper>
    );
  }
}

Filters.propTypes = {
  dispatch: PropTypes.func.isRequired,
  applyFilters: PropTypes.func.isRequired,
  filtersData: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  filtersApplied: PropTypes.object,
};

Filters.defaultProps = {
  filtersData: undefined,
  filtersApplied: {},
};

export default connect(
  state => ({
    userCan: canSelector(state),
    filtersData: state.enrollments.filtersData,
    loading: state.enrollments.loading,
    filtersApplied: state.enrollments.filters,
  }),
)(Filters);
