import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper } from './Main.styles';

const Main = ({ children }) => (
  <Wrapper>
    <div className="container">
      {children}
    </div>
  </Wrapper>
);

Main.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Main;
