import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert2';
import { colors } from 'ui/theme';
import {
  TextField,
  Button,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
} from '@material-ui/core';

import { Wrapper } from './Editor.styles';

const confirmationDialog = async ({ title, message, type = 'info' }) => swal({
  title,
  html: message,
  type,
  showCancelButton: true,
  confirmButtonColor: colors.primary,
  confirmButtonText: 'Enviar',
  cancelButtonColor: colors.disabled,
  cancelButtonText: 'Cancelar',
});

class Editor extends React.Component {
  state = {}

  onSend = () => {
    const { onSend } = this.props;
    onSend();
  }

  onPassword = async (email) => {
    // event.preventDefault();
    if (email) {
      const { onPassword } = this.props;
      const { value: confirmed } = await confirmationDialog({
        title: `Generar contraseña para: ${email}`,
        message: 'Se creará una nueva contraseña y se enviará al usuario.',
      });
      if (!confirmed) return;
      onPassword(email);
    }
  }

  render() {
    const {
      action,
      data,
      roles,
      academies,
      set,
      onClose,
    } = this.props;

    if (action === 'new' && data.idRole === 0) {
      data.idRole = roles[0].idRole;
    }
    return (
      <Wrapper>
        <div className="columns is-bottom-aligned">
          <div className="column is-2">
            <TextField
              label="Nombre"
              fullWidth
              value={data.name}
              onChange={e => set('name', e.target.value)}
            />
          </div>
          <div className="column is-3">
            <TextField
              label="Email"
              fullWidth
              type="email"
              value={data.email}
              onChange={e => set('email', e.target.value)}
            />
          </div>
          <div className="column is-2">
            <FormControl fullWidth>
              <InputLabel>Roles</InputLabel>
              <Select
                value={data.idRole}
                onChange={e => set('idRole', e.target.value)}
              >
                {roles.map(r => (<MenuItem key={r.idRole} value={r.idRole}>{r.name}</MenuItem>))}
              </Select>
            </FormControl>
          </div>
          <div className="column is-2">
            <FormControl fullWidth>
              <InputLabel>Academia</InputLabel>
              <Select
                value={data.idAcademy}
                onChange={e => set('idAcademy', e.target.value)}
              >
                <MenuItem value={0}> - </MenuItem>
                {academies.map(a => (
                  <MenuItem key={a.idAcademy} value={a.idAcademy}>{a.name}</MenuItem>))
                }
              </Select>
            </FormControl>
          </div>
          <div className="column is-3 form-actions">
            {
              action === 'edit' ? (
                <Fragment>
                  <Button color="primary" onClick={this.onSend}>Guardar</Button>
                  <Button color="primary" onClick={() => this.onPassword(data.email)}>
                    Password
                  </Button>
                </Fragment>
              ) : (
                <Button color="secondary" onClick={this.onSend}>Crear</Button>
              )
            }
            <Button className="close-button" onClick={onClose}>X</Button>
          </div>
        </div>
      </Wrapper>
    );
  }
}

Editor.propTypes = {
  action: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  set: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onPassword: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  academies: PropTypes.array.isRequired,
};

export default Editor;
