import {
  RESET,
  LOGIN,
  LOGIN_SUCCEEDED,
  LOGIN_FAILED,
  LOGOUT,
  REFRESH_PERMISSIONS,
  REFRESH_PERMISSIONS_SUCCEEDED,
  REFRESH_PERMISSIONS_FAILED,
} from './action-types';

export const reset = () => ({ type: RESET });
export const login = (email, password) => ({ type: LOGIN, email, password });
export const loginSucceeded = (token, user) => ({ type: LOGIN_SUCCEEDED, token, user });
export const loginFailed = () => ({ type: LOGIN_FAILED });
export const logout = () => ({ type: LOGOUT });

export const refreshPermissions = () => ({ type: REFRESH_PERMISSIONS });
export const refreshPermissionsSucceeded = permissions => (
  { type: REFRESH_PERMISSIONS_SUCCEEDED, permissions }
);
export const refreshPermissionsFailed = () => ({ type: REFRESH_PERMISSIONS_FAILED });
