import React from 'react';
import PropTypes from 'prop-types';

const DiscountItem = ({ discount }) => (
  <div className="columns is-vcentered discounts">
    <div className="column is-three-fifths is-offset-one-fifth">{discount.discountName}</div>
    <div className="column is-one-fifths amount">- {discount.amount}</div>
  </div>
);

DiscountItem.propTypes = {
  discount: PropTypes.object.isRequired,
};

export default DiscountItem;
