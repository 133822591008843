import {
  GET,
  GET_SUCCEEDED,
  GET_FAILED,
} from './action-types';
import utils from '../utils';

const initialState = {
  splitPaymentTransactions: [],
  pages: 0,
};

const splitPaymentTransactionsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_SUCCEEDED: {
      const { splitPaymentTransactions, pages } = action;
      return {
        ...state,
        splitPaymentTransactions,
        pages,
        loading: false,
      };
    }
    case GET_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error',
        message: 'Ha habido un error al recuperar las transacciones',
      });
      return {
        ...state,
        splitPaymentTransactions: [],
      };
    }
    default:
      return state;
  }
};

export default splitPaymentTransactionsReducer;
