import styled from 'styled-components';

export const Wrapper = styled.div`

  link {
    margin-top: 20px;
  }

  .ReactTable .rt-noData{
    display: none;
  }

  .delete-button {
    background-color: #B81C14;
    color: white;
    margin-left: 5px;
  }

  .delete-button:hover {
    background-color: #D71F18;
    color: white;
  }

  .buttons {
    margin-top: 30px;
    justify-content: flex-start;
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex !important;
  }

  .modal-main {
    position:fixed;
    background: white;
    width: 40%;
    height: auto;

    .modal-form {
      padding: 50px;
    }

    .modal-form-ok {
      float:right;
      margin-top: 10px;
    }
  }

  .display-block {
    display: block;
  }

  .display-none {
    display: none;
  }

  @media (max-width: 640px) {
    .content {
      font-size: 0.5em;
    }
    .modal-main {
      font-size: 0.6em;
      width: 80%;
      .modal-form {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
`;
