import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  list as listModuleCourseModalities,
  get as getModuleCourseModality,
  reset as resetModuleCourseModality,
  update as updateModuleCourseModality,
  set as setModuleCourseModality,
  create as createModuleCourseModality,
  remove as removeModuleCourseModality,
} from '$redux/course-modalities/actions';

import { Button } from '@material-ui/core';
import Title from 'ui/Title';
import Loading from '$routes/Loading';
import Failed from '$routes/Failed';

import Grid from './Grid';
import Editor from './Editor';

class ModuleCourseModality extends React.Component {
  state = {
    editorOpen: false,
    editorAction: 'new',
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(listModuleCourseModalities());
  }

  onCreate = () => {
    const { dispatch, single } = this.props;
    dispatch(createModuleCourseModality(single));
  }

  onUpdate = () => {
    const { dispatch, single } = this.props;
    dispatch(updateModuleCourseModality(single.idCourseModality, single));
  }

  set = (key, value) => {
    const { dispatch } = this.props;
    dispatch(setModuleCourseModality(key, value));
  }

  openEditor = (id) => {
    const { dispatch } = this.props;
    if (id) {
      dispatch(getModuleCourseModality(id));
      this.setState({ editorOpen: true, editorAction: 'edit' });
    } else {
      dispatch(resetModuleCourseModality());
      this.setState({ editorOpen: true, editorAction: 'new' });
    }
  }

  removeModuleCourseModality = (id) => {
    const { dispatch } = this.props;
    if (id) {
      dispatch(removeModuleCourseModality(id));
    }
  }

  render() {
    const { data, single, loading } = this.props;

    if (loading) return <Loading />;
    if (!data) return <Failed />;

    const { editorOpen, editorAction } = this.state;

    return (
      <div>
        <Title>
          Modalidades
          <Button onClick={() => this.openEditor()} size="small">Crear nueva modalidad</Button>
        </Title>
        {
          editorOpen ? (
            <Editor
              action={editorAction}
              data={single}
              set={this.set}
              onSend={editorAction === 'new' ? this.onCreate : this.onUpdate}
              onClose={() => this.setState({ editorOpen: false })}
            />
          ) : null
        }
        <Grid
          data={data}
          loading={loading}
          onEdit={this.openEditor}
          removeItem={this.removeModuleCourseModality}
        />
      </div>
    );
  }
}

ModuleCourseModality.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.array,
  single: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

ModuleCourseModality.defaultProps = {
  data: null,
};

export default connect(
  state => ({
    data: state.courseModalities.list,
    single: state.courseModalities.single,
    loading: state.courseModalities.loading,
  }),
)(ModuleCourseModality);
