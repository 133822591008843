import React from 'react';

import SubTitle from 'ui/SubTitle';

import { TimelineWrapper } from './index.styles';

import Timeline from './Timeline';

export default props => (
  <div>
    <SubTitle>Seguimiento de pagos fraccionados</SubTitle>
    <TimelineWrapper>
      <Timeline {...props} />
    </TimelineWrapper>
  </div>
);
