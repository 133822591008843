import React from 'react';
import {
  OUTLOOK_ICON,
  OUTLOOK_CHECK_ICON,
  OUTLOOK_GROUP_ICON,
  ADOBE_ICON,
  MOODLE_ICON,
  MOODLE_101_ICON,
  MOODLE_CHECK_ICON,
  MOODLE_COHORT_ICON,
  MOODLE_ENROLLMENT_ICON,
  MOODLE_EXTRA_ICON,
  LDAP_ICON,
  SERVICE_CHECK_ICON,
  SERVICE_FAIL_ICON,
  SERVICE_EMPTY_ICON,
  SERVICE_CALENDAR_ICON,
} from '../../constants';

const ServiceIcon = (name) => {
  let fileUrl = '';
  switch (name) {
    case OUTLOOK_ICON:
      fileUrl = '/img/services/outlook.svg';
      break;
    case OUTLOOK_CHECK_ICON:
      fileUrl = '/img/services/outlook-check.svg';
      break;
    case OUTLOOK_GROUP_ICON:
      fileUrl = '/img/services/outlook-group.svg';
      break;
    case ADOBE_ICON:
      fileUrl = '/img/services/adobe.svg';
      break;
    case MOODLE_ICON:
      fileUrl = '/img/services/moodle.svg';
      break;
    case MOODLE_101_ICON:
      fileUrl = '/img/services/moodle-101.svg';
      break;
    case MOODLE_CHECK_ICON:
      fileUrl = '/img/services/moodle-check.svg';
      break;
    case MOODLE_COHORT_ICON:
      fileUrl = '/img/services/moodle-cohort.svg';
      break;
    case MOODLE_ENROLLMENT_ICON:
      fileUrl = '/img/services/moodle-enrollment.svg';
      break;
    case MOODLE_EXTRA_ICON:
      fileUrl = '/img/services/moodle-extra.svg';
      break;
    case LDAP_ICON:
      fileUrl = '/img/services/ldap.svg';
      break;
    case SERVICE_CHECK_ICON:
      fileUrl = '/img/services/service-check.svg';
      break;
    case SERVICE_FAIL_ICON:
      fileUrl = '/img/services/service-fail.svg';
      break;
    case SERVICE_EMPTY_ICON:
      fileUrl = '/img/services/service-empty.svg';
      break;
    case SERVICE_CALENDAR_ICON:
      fileUrl = '/img/services/calendar.svg';
      break;
    default:
      fileUrl = '/img/services/calendar.svg';
  }
  return (<img src={fileUrl} alt="Failed" className="service-image" />);
};

export default ServiceIcon;
