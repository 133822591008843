import React from 'react';
import PropTypes from 'prop-types';

import {
  Select,
  Button,
} from '@material-ui/core';

import { Wrapper } from './Editor.styles';

class Editor extends React.Component {
  state = {
    action: '',
  }

  set = (key, value) => {
    this.setState({
      [key]: value,
    });
  }

  onSend = () => {
    const { onSend } = this.props;
    const { action } = this.state;
    onSend(action);
  }

  render() {
    const {
      onClose,
    } = this.props;

    const {
      action,
    } = this.state;

    return (
      <Wrapper>
        <div className="columns is-bottom-aligned">
          <div className="column is-3">
            <Select
              native
              onChange={e => this.set('action', e.target.value)}
              className="filter-item"
              value={action}
            >
              <option value="">Seleccionar acción</option>
              <option value="_registro_basico">Grupo: Registro Basico</option>
              <option value="outlookCreate">outlookCreate</option>
              <option value="outlookUpdate">outlookUpdate</option>
              <option value="outlookCheck">outlookCheck</option>
              <option value="outlookGroupCreate">outlookGroupCreate</option>
              <option value="outlookLicenceCreate">outlookLicenceCreate</option>
              <option value="ldapCreate">ldapCreate</option>
              <option value="ldapCheck">ldapCheck</option>
              <option value="ldapUpdate">ldapUpdate</option>
              <option value="alexiaCreate">alexiaCreate</option>
              <option value="alexiaCheck">alexiaCheck</option>
              <option value="alexiaUpdate">alexiaUpdate</option>
              <option value="alexiaUpdateByDNI">alexiaUpdateByDNI</option>
              <option value="alexiaExtraCreate">alexiaExtraCreate</option>
              <option value="alexiaEnrollmentCreate">alexiaEnrollmentCreate</option>
              <option value="alexiaCohortCreate">alexiaCohortCreate</option>
              <option value="alexia101">alexia101</option>
              <option value="alexiaSyncEnrollment">alexiaSyncEnrollment</option>
              <option value="osTicketCreate">osTicketCreate</option>
              <option value="osTicketCheck">osTicketCheck</option>
              <option value="osTicketUpdate">osTicketUpdate</option>
              <option value="adobeCreate">adobeCreate</option>
              <option value="adobeCheck">adobeCheck</option>
              <option value="adobeUpdate">adobeUpdate</option>
              <option value="testSuccess">testSuccess</option>
              <option value="testError">testError</option>
            </Select>
          </div>
          <div className="column is-2 form-actions">
            <Button color="secondary" onClick={this.onSend}>Crear</Button>
            <Button className="close-button" onClick={onClose}>X</Button>
          </div>
        </div>
      </Wrapper>
    );
  }
}

Editor.propTypes = {
  onSend: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Editor;
