import styled from 'styled-components';

export const DeadlineActions = styled.div`
  margin-bottom: 20px;

  & > * {
    margin-right: 20px !important;
  }
`;

export const SubTitleNoFlex = styled.div`
  h2 { display: block }
`;
