import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from '@material-ui/core';

import Title from 'ui/Title';
import theme from 'ui/theme';

import { setSelection, removeSelections } from '$redux/enrollments/actions';
import { requestAddServicesActionBulk } from '$redux/services/actions';
import { Wrapper } from './styles';
import Filters from './Filters';
import Grid from './Grid';
import ServiceDialog from './ServiceDialog';

class Enrollments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {},
      serviceDialog: false,
    };
  }

  setSelection = (id, value) => {
    const { dispatch } = this.props;
    dispatch(setSelection(id, value));
  }

  removeSelections = () => {
    const { dispatch } = this.props;
    dispatch(removeSelections());
  }

  integrateServices = () => {
    this.openServiceDialog();
    // const { selections } = this.props;
  }

  applyFilters = (filtersSelected) => {
    this.setState({ filters: filtersSelected });
  }

  openServiceDialog = () => {
    this.setState({
      serviceDialog: true,
    });
  }

  closeServiceDialog = () => {
    this.setState({
      serviceDialog: false,
    });
  }

  integrateServicesApi = (actions, searchFilter) => {
    const { dispatch, selections } = this.props;
    const { filters } = this.state;
    dispatch(requestAddServicesActionBulk({
      actions,
      searchFilter,
      filters,
      selections,
    }));
  }

  render() {
    const {
      selections,
      selections: { length: selectedItems },
    } = this.props;

    const { filters, serviceDialog } = this.state;

    return (
      <Wrapper theme={theme}>
        <Title>
          Alta en Servicios
          {
            <div>
              <Button
                size="small"
                disabled={!selectedItems}
                onClick={this.integrateServices}
              >
                Integrar ({selectedItems}) en los Servicios
              </Button>
              <Button
                size="small"
                disabled={!selectedItems}
                onClick={this.removeSelections}
              >
                Quitar selección
              </Button>
            </div>
          }
        </Title>
        <Filters applyFilters={this.applyFilters} />
        <Grid
          filters={filters}
          selections={selections}
          setSelection={this.setSelection}
        />
        <ServiceDialog
          open={serviceDialog}
          closeThread={this.closeServiceDialog}
          integrateServicesApi={this.integrateServicesApi}
          allowSearchFilter={Object.keys(filters).length === 0}
        />
      </Wrapper>
    );
  }
}

Enrollments.propTypes = {
  dispatch: PropTypes.func.isRequired,
  selections: PropTypes.array.isRequired,
};

export default connect(
  state => ({
    selections: state.enrollments.selections,
  }),
)(Enrollments);
