import React from 'react';
import PropTypes from 'prop-types';
import SubTitle from 'ui/SubTitle';
import {
  AppBar, Tabs, Tab, withStyles,
} from '@material-ui/core';
import TransactionTab from './TransactionTab';
import { Wrapper } from './Transactions.styles';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
});

class Transacions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      enrollmentTransactions, selectedTab,
      onTabChange, onSend, onRemove, getTransactionPdf,
      setModule, setUnit, setPenalty, setModuleCost, setUnitCost, setNewPrices, setDiscountCost,
    } = this.props;
    let enrollmentTransaction;
    const transactionTabs = enrollmentTransactions.map(et => (
      <Tab key={et.idEnrollmentTransaction} label={et.name} />));

    let amountTransactions = 0.00;
    enrollmentTransactions.forEach((et) => {
      amountTransactions += ((parseFloat(et.amount) || 0) * 1);
    });
    // recoger y sumar todos los amounts de las transacciones¿
    const subtitle = (
      <SubTitle>Historial de cambios
        <div className="total-matricula">
          Total matrícula: {amountTransactions.toFixed(2)} €
        </div>
      </SubTitle>
    );

    const currentET = enrollmentTransactions[selectedTab];
    if (currentET) {
      enrollmentTransaction = (
        <TransactionTab
          enrollmentTransactions={enrollmentTransactions}
          selectedTab={selectedTab}
          onSend={onSend}
          onRemove={onRemove}
          getTransactionPdf={getTransactionPdf}
          setModule={setModule}
          setUnit={setUnit}
          setPenalty={setPenalty}
          setModuleCost={setModuleCost}
          setUnitCost={setUnitCost}
          setNewPrices={setNewPrices}
          setDiscountCost={setDiscountCost}
        />
      );
    }
    return (
      <Wrapper>
        {subtitle}
        <div className="transactions">
          <AppBar position="static" color="default">
            <Tabs
              value={selectedTab}
              onChange={onTabChange}
              indicatorColor="primary"
              textColor="primary"
            >
              {transactionTabs}
            </Tabs>
          </AppBar>
          {enrollmentTransaction}
        </div>
      </Wrapper>
    );
  }
}

Transacions.propTypes = {
  enrollmentTransactions: PropTypes.array,
  selectedTab: PropTypes.number,
  onTabChange: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  getTransactionPdf: PropTypes.func.isRequired,
  setModule: PropTypes.func.isRequired,
  setUnit: PropTypes.func.isRequired,
  setPenalty: PropTypes.func.isRequired,
  setModuleCost: PropTypes.func.isRequired,
  setUnitCost: PropTypes.func.isRequired,
  setNewPrices: PropTypes.func.isRequired,
  setDiscountCost: PropTypes.func.isRequired,
};

Transacions.defaultProps = {
  enrollmentTransactions: [],
  selectedTab: 0,
};

// export default Transacions;
export default withStyles(styles, { withTheme: true })(Transacions);
