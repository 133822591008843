import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import swal from 'sweetalert2';
import { Link, Redirect } from 'react-router-dom';
import { canSelector } from '$redux/login/selectors';
import {
  requestDetail,
  set as setEnrollment,
  update as updateEnrollment,
  removeEnrollment,
  requestPagantisOrder,
  clearPagantisOrder,
  enrollmentTransactionTransferUpdates,
} from '$redux/enrollments/actions';

import Button from '@material-ui/core/Button';
import Title from 'ui/Title';
import Loading from '$routes/Loading';
import Failed from '$routes/Failed';

import { Wrapper } from './styles';
import BasicInformation from '../BasicInformation';
import RoadMap from '../RoadMap';
import Validation from './Validation';
import Documents from './Documents';
import Modules from './Modules';
import ChangeAcademy from './ChangeAcademy';
import ChangeCollaborationCenter from './ChangeCollaborationCenter';
import Timeline from './Timeline';
import PagantisInfo from './PagantisInfo';
import EnrollmentTransactionTransfer from './EnrollmentTransactionTransfer';

class Enrollments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toMainEnrollmentsPage: false,
    };
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    dispatch(requestDetail(match.params.idEnrollment));
  }

  onUpdate = () => {
    const { dispatch, enrollment } = this.props;
    dispatch(updateEnrollment(enrollment));
  }

  onUpdateEnrollmentTransactionTransfer = () => {
    const { dispatch, enrollment } = this.props;
    dispatch(enrollmentTransactionTransferUpdates(enrollment.Transfers));
  }

  onGetOrderPagantis = () => {
    const { dispatch, enrollment: { orderId } } = this.props;
    dispatch(requestPagantisOrder(orderId));
  }

  onClose = () => {
    const { dispatch } = this.props;
    dispatch(clearPagantisOrder());
  }

  set = (key, value) => {
    const { dispatch } = this.props;
    dispatch(setEnrollment(key, value));
  }

  removeEnrollment = async () => {
    const { dispatch, match } = this.props;

    // ask for confirmation
    const { value } = await swal({
      title: 'Confirmación',
      text: `¿Seguro que quieres borrar la matrícula con id #${match.params.idEnrollment}?`,
      type: 'warning',
      confirmButtonText: 'Sí, ¡bórralo!',
      confirmButtonColor: '#e05959',
      showCancelButton: true,
      cancelButtonText: 'No, volver atrás',
    });

    if (!value) return;
    this.setState({
      toMainEnrollmentsPage: true,
    });
    dispatch(removeEnrollment(match.params.idEnrollment));
  }

  render() {
    const { toMainEnrollmentsPage } = this.state;
    let pagantisInfo;


    if (toMainEnrollmentsPage) {
      return <Redirect to="/matriculas" />;
    }
    const {
      enrollment,
      documents,
      loading,
      loadingDownload,
      idAcademy,
      states,
      payments,
      modules,
      moduleHolds,
      discounts,
      academies,
      collaboratingCenters,
      oldMethodology,
      match,
      userCan,
      pagantisOrder,
      reload,
    } = this.props;

    if (reload) {
      window.location.reload();
    }
    if (loading) return <Loading />;
    if (!enrollment) return <Failed />;

    let changeAcademy;
    if (!idAcademy) {
      changeAcademy = (
        <ChangeAcademy
          academies={academies}
          data={enrollment}
          set={this.set}
          onSend={this.onUpdate}
        />
      );
    }

    let changeCollaborationCenter;
    if (collaboratingCenters) {
      changeCollaborationCenter = (
        <ChangeCollaborationCenter
          collaboratingCenters={collaboratingCenters}
          data={enrollment}
          set={this.set}
          onSend={this.onUpdate}
        />
      );
    }

    const enrollmentTransactionTransferWidget = (
      <EnrollmentTransactionTransfer
        collaboratingCenters={collaboratingCenters}
        data={enrollment}
        set={this.set}
        onSend={this.onUpdateEnrollmentTransactionTransfer}
      />
    );

    if (pagantisOrder) {
      pagantisInfo = (
        <PagantisInfo
          order={pagantisOrder.order}
          onClose={this.onClose}
        />
      );
    }
    return (
      <Wrapper>
        <Title>Matrícula #{match.params.idEnrollment}</Title>
        <BasicInformation
          data={enrollment}
          showStudentButton
          methodology={enrollment.roadMap ? enrollment.roadMap.TrainingPlan.Methodology.name : '-'}
          oldMethodology={oldMethodology ? oldMethodology.tp.Methodology.name : '-'}
        />
        <RoadMap data={enrollment} />
        {payments.length && userCan('ENROLLMENT_PAYMENTS') ? <Timeline payments={payments} /> : null}
        <Validation
          idAcademy={idAcademy}
          states={states}
          payments={payments}
          data={enrollment}
          set={this.set}
          onSend={this.onUpdate}
          userCan={userCan}
          onGetOrder={this.onGetOrderPagantis}
        />
        {enrollmentTransactionTransferWidget}
        {changeAcademy}
        {changeCollaborationCenter}
        <Documents
          data={documents}
          set={this.set}
          enrollment={enrollment}
          loadingDownload={loadingDownload}
        />
        <Modules
          modules={modules}
          moduleHolds={moduleHolds}
          discounts={discounts}
          data={enrollment}
          userCan={userCan}
          showModifyLink
        />
        <div className="buttons">
          <Link to="/matriculas"><Button className="back-button" color="primary">Volver</Button></Link>
          {userCan('ENROLLMENT_DELETE') ? (
            <Button className="delete-button" onClick={this.removeEnrollment}>Eliminar matrícula</Button>
          ) : undefined}
        </div>
        {pagantisInfo}
      </Wrapper>
    );
  }
}

Enrollments.propTypes = {
  dispatch: PropTypes.func.isRequired,
  enrollment: PropTypes.object,
  pagantisOrder: PropTypes.object,
  documents: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  reload: PropTypes.bool.isRequired,
  loadingDownload: PropTypes.bool,
  match: PropTypes.object.isRequired,
  idAcademy: PropTypes.number,
  states: PropTypes.array,
  payments: PropTypes.array,
  discounts: PropTypes.array,
  modules: PropTypes.array,
  moduleHolds: PropTypes.array,
  academies: PropTypes.array,
  collaboratingCenters: PropTypes.array,
  userCan: PropTypes.func.isRequired,
  oldMethodology: PropTypes.object,
};

Enrollments.defaultProps = {
  enrollment: {},
  documents: [],
  idAcademy: 0,
  states: [],
  payments: [],
  discounts: [],
  modules: [],
  moduleHolds: [],
  academies: [],
  collaboratingCenters: [],
  loadingDownload: false,
  pagantisOrder: {},
  oldMethodology: null,
};

export default connect(
  state => ({
    loading: state.enrollments.loading,
    reload: state.enrollments.reload,
    loadingDownload: state.enrollments.loadingDownload,
    enrollment: state.enrollments.enrollment,
    states: state.enrollments.states,
    documents: state.enrollments.documents,
    modules: state.enrollments.modules,
    moduleHolds: state.enrollments.moduleHolds,
    payments: state.enrollments.payments,
    discounts: state.enrollments.discounts,
    idAcademy: state.enrollments.idAcademy,
    academies: state.enrollments.academies,
    collaboratingCenters: state.enrollments.collaboratingCenters,
    pagantisOrder: state.enrollments.pagantisOrder,
    oldMethodology: state.enrollments.oldMethodology,
    userCan: canSelector(state),
  }),
)(Enrollments);
