export const REQUEST_FAILED = 'SEMESTERS_REQUEST_FAILED';
export const LIST = 'SEMESTERS_LIST';
export const LIST_SUCCEEDED = 'SEMESTERS_LIST_SUCCEEDED';
export const GET = 'SEMESTER_GET';
export const GET_SUCCEEDED = 'SEMESTER_GET_SUCCEEDED';
export const UPDATE = 'SEMESTER_UPDATE';
export const UPDATE_SUCCEEDED = 'SEMESTER_UPDATE_SUCCEEDED';
export const REMOVE = 'SEMESTER_REMOVE';
export const REMOVE_SUCCEEDED = 'SEMESTER_REMOVE_SUCCEEDED';
export const CREATE = 'SEMESTER_CREATE';
export const CREATE_SUCCEEDED = 'SEMESTER_CREATE_SUCCEEDED';
export const SET = 'SEMESTER_SET';
export const RESET = 'SEMESTER_RESET';
