import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  update as updateRoadMap,
} from '$redux/road-maps/actions';

import {
  withStyles,
  Select,
  InputLabel,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const StyledDialog = withStyles({ paper: { padding: 50 } })(props => (
  <Dialog {...props}>{props.children}</Dialog>
));

const StyledTextField = withStyles({ root: { '& > div': { padding: '10px !important' } } })(
  props => (
    <TextField {...props} />
  ),
);

class RoadmapEditDialog extends React.Component {
  state = {
    idRoadMap: 0,
    name: '',
    isCustomizable: 0,
    semester: '',
    active: false,
  };

  componentWillReceiveProps(nextProps) {
    const { idRoadMap } = this.state;
    if (nextProps.idRoadMap !== idRoadMap) {
      this.setState(
        nextProps.roadMap,
      );
    }
  }

  handleChange = (name, value) => {
    const roadMap = {};
    roadMap[name] = value;
    this.setState(roadMap);
  }

  saveChange = () => {
    const { dispatch } = this.props;
    const { idRoadMap } = this.state;
    if (idRoadMap === 0) {
      return;
    }
    dispatch(updateRoadMap(this.state));
  }

  render() {
    const {
      open,
      close,
      loading,
    } = this.props;

    const {
      name,
      isCustomizable,
      semester,
      active,
    } = this.state;

    if (loading) return <b>Cargando</b>;

    const moduleItem = (
      <StyledDialog
        className="messages-dialog"
        open={open}
        onClose={close}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">Editar Itinerario</DialogTitle>
        <DialogContent>
          {loading
            ? (
              <div>
                <b>Loading</b>
              </div>
            )
            : (
              <div>
                <b>Itinerario</b>
                <form>
                  <StyledTextField
                    placeholder="Nombre"
                    fullWidth
                    margin="normal"
                    variant="filled"
                    multiline
                    value={name}
                    onChange={event => this.handleChange('name', event.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <div className="customizedNewRoadMap">
                    <Select
                      native
                      className="filter-academy"
                      value={semester}
                      onChange={event => this.handleChange('semester', event.target.value)}
                    >
                      <option value={0}>Cualquier semestre</option>
                      <option value={1}>De Sep a Enero</option>
                      <option value={2}>De Feb a Mayo</option>
                    </Select>
                  </div>
                  <div className="customizedNewRoadMap">
                    <InputLabel>Personalizado </InputLabel>
                    <FormControlLabel
                      control={<Switch color="secondary" />}
                      checked={isCustomizable}
                      onChange={event => this.handleChange('isCustomizable', event.target.checked)}
                    />
                  </div>
                  <div className="customizedNewRoadMap">
                    <InputLabel>Activo </InputLabel>
                    <FormControlLabel
                      control={<Switch color="secondary" />}
                      checked={active}
                      onChange={event => this.handleChange('active', event.target.checked)}
                    />
                  </div>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => this.saveChange()}
                  >
                    Salvar Itinerario
                  </Button>
                </form>
              </div>
            )}
        </DialogContent>
      </StyledDialog>
    );

    return moduleItem;
  }
}

RoadmapEditDialog.propTypes = {
  dispatch: PropTypes.func.isRequired,
  open: PropTypes.bool,
  close: PropTypes.func.isRequired,
  idRoadMap: PropTypes.number.isRequired,
  roadMap: PropTypes.object.isRequired,
  loading: PropTypes.object.isRequired,
};

RoadmapEditDialog.defaultProps = {
  open: true,
};

export default connect(
  state => ({
    single: state.roadmaps.single,
  }),
)(RoadmapEditDialog);
