import React from 'react';
import PropTypes from 'prop-types';
import {
  Switch,
  FormControlLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import { Wrapper } from './styles';
import SubTitle from '../../../../ui/SubTitle';

class OtherInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    let documentNumber;
    let forceCatNewTp;
    const {
      data, set,
    } = this.props;

    if (data) {
      documentNumber = (
        <div className="column">
          <div className="columns is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Cómo nos ha conocido:</Typography>
            </div>
            <div className="column is-two-fifths">
              <TextField
                fullWidth
                disabled
                value={data.infoChannel.name ? data.infoChannel.name : ''}
              />
            </div>
          </div>
        </div>
      );
      forceCatNewTp = (
        <div className="column">
          <div className="columns is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Pasar a plan nuevo:</Typography>
            </div>
            <div className="column is-two-fifths">
              <FormControlLabel
                control={<Switch color="secondary" />}
                checked={!!data.forceCatNewTp}
                onChange={e => set('otherInfo', 'forceCatNewTp', e.target.checked)}
              />
            </div>
          </div>
        </div>
      );
    }

    const { title } = this.props;

    return (
      <Wrapper>
        <div className="student-data">
          <SubTitle>{title}</SubTitle>
          <div className="columns is-multiline is-narrow content">
            {documentNumber}
            {forceCatNewTp}
          </div>
        </div>
      </Wrapper>);
  }
}

OtherInfo.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object,
  set: PropTypes.func.isRequired,
};

OtherInfo.defaultProps = {
  data: undefined,
};

export default (OtherInfo);
