import styled from 'styled-components';

export const Wrapper = styled.div`
  align-right {
    text-align: right;
  }
  .align-right {
    text-align: right;
    padding: 0;
    button {
      color: #6896c8;
      font-size: 12px;
    }
  }

  .export {
    color: #6896c8;
    font-size: 12px;
  }
`;
