import {
  CREATE,
  CREATE_SUCCEEDED,
  CREATE_FAILED,
  UPDATE,
  UPDATE_SUCCEEDED,
  UPDATE_FAILED,
  GET,
  GET_SUCCEEDED,
  GET_FAILED,
} from './action-types';
import utils from '../utils';

const initialState = {
  loading: false,
  single: null,
};

const roadMapReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case CREATE: {
      return {
        ...state,
        loading: true,
      };
    }
    case CREATE_SUCCEEDED: {
      utils.dialog({
        title: '¡Perfecto!',
        message: 'La petición se ha procesado con éxito',
      });
      return {
        ...state,
        loading: false,
      };
    }

    case CREATE_FAILED: {
      const { error } = action;

      let message = 'La petición no se ha completado con éxito.';

      if (error.response && error.response.data && error.response.data.message) {
        ({ message } = error.response.data);
      }
      utils.dialog({
        type: 'error',
        title: 'Error inesperado',
        message,
      });
      return {
        ...state,
        loading: false,
      };
    }

    case UPDATE: {
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_SUCCEEDED: {
      utils.dialog({
        title: '¡Perfecto!',
        message: 'La petición se ha procesado con éxito',
        type: 'reload',
      });
      return {
        ...state,
        loading: false,
      };
    }

    case UPDATE_FAILED: {
      const { error } = action;

      let message = 'La petición no se ha completado con éxito.';

      if (error.response && error.response.data && error.response.data.message) {
        ({ message } = error.response.data);
      }
      utils.dialog({
        type: 'error',
        title: 'Error inesperado',
        message,
      });
      return {
        ...state,
        loading: false,
      };
    }

    case GET: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_SUCCEEDED: {
      const { success: { data } } = action;
      return {
        ...state,
        loading: false,
        single: data,
      };
    }

    case GET_FAILED: {
      const { error } = action;

      let message = 'La petición no se ha completado con éxito.';

      if (error.response && error.response.data && error.response.data.message) {
        ({ message } = error.response.data);
      }
      utils.dialog({
        type: 'error',
        title: 'Error inesperado',
        message,
      });
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
};

export default roadMapReducer;
