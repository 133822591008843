import {
  ALEXIA,
  ALEXIA_FAILED,
  ALEXIA_RESET,
  ALEXIA_RESET_FAILED,
  ALEXIA_RESET_SUCCEEDED,
  ALEXIA_SUCCEEDED,
  CHECK_MODULE,
  CHECK_MODULE_SUCCEEDED,
  CHECK_UNIT,
  CHECK_UNIT_SUCCEEDED,
  CLEAN_FILTERS_REQUEST,
  CLEAR_PAGANTIS_ORDER,
  CREATE_TRANSACTION,
  CREATE_TRANSACTION_FAILED,
  DELETE_JUSTIFICANTE_TRANSFE,
  DELETE_JUSTIFICANTE_TRANSFE_FAILED,
  DELETE_JUSTIFICANTE_TRANSFE_SUCCEEDED,
  DOC_DOWNLOAD_REQUEST,
  DOC_DOWNLOAD_REQUEST_FAILED,
  DOC_DOWNLOAD_REQUEST_SUCCEEDED,
  DOC_REQUEST,
  DOC_REQUEST_FAILED,
  DOC_REQUEST_SUCCEEDED,
  ENROLLMENT_TRANSACTION_TRANSFER_UPDATES,
  ENROLLMENT_TRANSACTION_TRANSFER_UPDATES_SUCCEEDED,
  FILTERS_REQUEST,
  FILTERS_REQUEST_FAILED,
  FILTERS_REQUEST_HOLD,
  FILTERS_REQUEST_SUCCEEDED,
  GET_TRANSACTION_PDF,
  GET_TRANSACTION_PDF_SUCCEEDED,
  MOVE_TRANSACTION_MODULE,
  MOVE_TRANSACTION_MODULE_SUCCEEDED,
  PAYMENT_CHARGE,
  PAYMENT_FAILED,
  PAYMENT_MAIL,
  PAYMENT_MARK_AS_PAID,
  PAYMENT_MARK_AS_PENDING,
  PAYMENT_SUCCEEDED,
  PAYMENT_UPDATE_DEADLINE,
  REMOVE,
  REMOVE_SELECTIONS,
  REMOVE_SUCCEEDED,
  REMOVE_TRANSACTION,
  REMOVE_TRANSACTION_FAILED,
  REQUEST,
  REQUEST_DETAIL,
  REQUEST_DETAIL_FAILED,
  REQUEST_DETAIL_SUCCEEDED,
  REQUEST_ENROLLMENT_ACTIVATION,
  REQUEST_ENROLLMENT_ACTIVATION_FAILED,
  REQUEST_ENROLLMENT_ACTIVATION_SUCCEEDED,
  REQUEST_ENROLLMENT_CHANGE_METHODOLOGY,
  REQUEST_ENROLLMENT_CHANGE_METHODOLOGY_FAILED,
  REQUEST_ENROLLMENT_CHANGE_METHODOLOGY_SUCCEEDED,
  REQUEST_ENROLLMENT_CHECK_METHODOLOGY,
  REQUEST_ENROLLMENT_CHECK_METHODOLOGY_FAILED,
  REQUEST_ENROLLMENT_CHECK_METHODOLOGY_SUCCEEDED,
  REQUEST_ENROLLMENT_EXPORT,
  REQUEST_ENROLLMENT_EXPORT_SUCCEEDED,
  REQUEST_ENROLLMENT_GRADE_EXPORT,
  REQUEST_ENROLLMENT_GRADE_EXPORT_SUCCEEDED,
  REQUEST_ENROLLMENT_HOLD,
  REQUEST_ENROLLMENT_HOLD_FAILED,
  REQUEST_ENROLLMENT_HOLD_SUCCEEDED,
  REQUEST_ENROLLMENT_SERVICES,
  REQUEST_ENROLLMENT_SERVICES_FAILED,
  REQUEST_ENROLLMENT_SERVICES_SUCCEEDED,
  REQUEST_FAILED,
  REQUEST_GET_ALEXIA_LOG,
  REQUEST_GET_ALEXIA_LOG_SUCCEEDED,
  REQUEST_GET_ALL_FOR_STUDENT,
  REQUEST_GET_ALL_FOR_STUDENT_FAILED,
  REQUEST_GET_ALL_FOR_STUDENT_SUCCEEDED,
  REQUEST_MODIFY,
  REQUEST_MODIFY_FAILED,
  REQUEST_MODIFY_SUCCEEDED,
  REQUEST_PAGANTIS_ORDER,
  REQUEST_PAGANTIS_ORDER_FAILED,
  REQUEST_PAGANTIS_ORDER_SUCCEEDED,
  REQUEST_SUCCEEDED,
  SET,
  SET_DISCOUNT_COST_TRANSACTION,
  SET_MODULE_COST_TRANSACTION,
  SET_MODULE_TRANSACTION,
  SET_SELECTION,
  SET_TRANSACTION_PENALTY,
  SET_TRANSACTION_UPDATE_PRICES,
  SET_TRANSACTION_UPDATE_PRICES_FAILED,
  SET_TRANSACTION_UPDATE_PRICES_SUCCEEDED,
  SET_UNIT_COST_TRANSACTION,
  SET_UNIT_TRANSACTION,
  TAB_CHANGE,
  UPDATE,
  UPDATE_DOC,
  UPDATE_DOC_SUCCEEDED,
  UPDATE_JUSTIFICANTE_TRANSFE,
  UPDATE_JUSTIFICANTE_TRANSFE_FAILED,
  UPDATE_JUSTIFICANTE_TRANSFE_SUCCEEDED,
  UPDATE_SUCCEEDED,
  REQUEST_ENROLMENT_TRANSFER_ESPERA,
  REQUEST_ENROLMENT_TRANSFER_ESPERA_SUCCEEDED,
  REQUEST_ENROLMENT_TRANSFER_ESPERA_FAILED,
} from './action-types';
import { DELETE_MODULE } from '../courses/action-types';


export const request = (bindingObject, origin) => ({
  type: REQUEST,
  bindingObject,
  origin,
});
export const requestSucceeded = ({
  enrollment: {
    data,
    pages,
  },
}, origin, bindingObject) => ({
  type: REQUEST_SUCCEEDED,
  data,
  pages,
  origin,
  bindingObject,
});
export const requestFailed = () => ({ type: REQUEST_FAILED });

export const filtersRequest = () => ({ type: FILTERS_REQUEST });
export const filtersRequestSucceeded = filtersData => ({
  type: FILTERS_REQUEST_SUCCEEDED,
  filtersData,
});
export const filtersRequestFailed = () => ({ type: FILTERS_REQUEST_FAILED });

export const filtersRequestHold = () => ({ type: FILTERS_REQUEST_HOLD });

export const cleanFiltersRequest = () => ({ type: CLEAN_FILTERS_REQUEST });

export const alexia = ids => ({
  type: ALEXIA,
  ids,
});
export const alexiaSucceeded = data => ({
  type: ALEXIA_SUCCEEDED,
  data,
});
export const alexiaFailed = () => ({ type: ALEXIA_FAILED });
export const alexiaReset = id => ({
  type: ALEXIA_RESET,
  id,
});
export const alexiaResetSucceeded = id => ({
  type: ALEXIA_RESET_SUCCEEDED,
  id,
});
export const alexiaResetFailed = () => ({ type: ALEXIA_RESET_FAILED });

export const requestDetail = idEnrollment => ({
  type: REQUEST_DETAIL,
  idEnrollment,
});
export const requestDetailSucceeded = ({
  enrollment,
  states,
  documents,
  modules,
  moduleHolds,
  payments,
  discounts,
  idAcademy,
  academies,
  collaboratingCenters,
  oldMethodology,
}) => ({
  type: REQUEST_DETAIL_SUCCEEDED,
  enrollment,
  states,
  documents,
  modules,
  moduleHolds,
  payments,
  discounts,
  idAcademy,
  academies,
  collaboratingCenters,
  oldMethodology,
});
export const requestDetailFailed = () => ({ type: REQUEST_DETAIL_FAILED });

export const requestEnrollmentModify = idEnrollment => ({
  type: REQUEST_MODIFY,
  idEnrollment,
});
export const requestEnrollmentModifySucceeded = ({
  enrollment,
  enrollmentTransactions,
  roadMap,
  collaboratingCenters,
  oldMethodology,
  optionsMethodology,
}) => ({
  type: REQUEST_MODIFY_SUCCEEDED,
  enrollment,
  enrollmentTransactions,
  roadMap,
  collaboratingCenters,
  oldMethodology,
  optionsMethodology,
});

export const requestEnrollmentModifyFailed = () => ({ type: REQUEST_MODIFY_FAILED });

export const set = (key, value) => ({
  type: SET,
  key,
  value,
});

export const update = data => ({
  type: UPDATE,
  data,
});
export const updateSucceeded = success => ({
  type: UPDATE_SUCCEEDED,
  success,
});

export const enrollmentTransactionTransferUpdates = data => ({
  type: ENROLLMENT_TRANSACTION_TRANSFER_UPDATES,
  data,
});
export const enrollmentTransactionTransferUpdatesSucceeded = success => ({
  type: ENROLLMENT_TRANSACTION_TRANSFER_UPDATES_SUCCEEDED,
  success,
});

export const updateDocument = (idStudentDocument, idStudent, idEnrollment, checked) => ({
  type: UPDATE_DOC,
  idStudentDocument,
  idStudent,
  idEnrollment,
  checked,
});
export const updateDocumentSucceeded = success => ({
  type: UPDATE_DOC_SUCCEEDED,
  success,
});

export const requestDoc = idStudentDocument => ({
  type: DOC_REQUEST,
  idStudentDocument,
});
export const requestDocSucceeded = (idStudentDocument, url) => ({
  type: DOC_REQUEST_SUCCEEDED,
  idStudentDocument,
  url,
});
export const requestDocFailed = () => ({ type: DOC_REQUEST_FAILED });

export const requestDownloadDoc = idStudentDocument => ({
  type: DOC_DOWNLOAD_REQUEST,
  idStudentDocument,
});
export const requestDownloadDocSucceeded = (idStudentDocument, data) => ({
  type: DOC_DOWNLOAD_REQUEST_SUCCEEDED,
  idStudentDocument,
  data,
});
export const requestDownloadDocFailed = () => ({ type: DOC_DOWNLOAD_REQUEST_FAILED });

export const setSelection = (id, value) => ({
  type: SET_SELECTION,
  id,
  value,
});
export const removeSelections = () => ({ type: REMOVE_SELECTIONS });

export const removeEnrollment = id => ({
  type: REMOVE,
  id,
});
export const removeEnrollmentSucceeded = () => ({ type: REMOVE_SUCCEEDED });

export const paymentMarkAsPending = id => ({
  type: PAYMENT_MARK_AS_PENDING,
  id,
});
export const paymentCharge = id => ({
  type: PAYMENT_CHARGE,
  id,
});
export const paymentSendMail = id => ({
  type: PAYMENT_MAIL,
  id,
});
export const paymentMarkAsPaid = id => ({
  type: PAYMENT_MARK_AS_PAID,
  id,
});
export const paymentUpdateDeadline = (id, value) => ({
  type: PAYMENT_UPDATE_DEADLINE,
  id,
  value,
});
export const paymentSucceeded = (id, data) => ({
  type: PAYMENT_SUCCEEDED,
  id,
  data,
});
export const paymentFailed = (id, error) => ({
  type: PAYMENT_FAILED,
  id,
  error,
});
export const checkModule = (idModule, value, semester) => ({
  type: CHECK_MODULE,
  idModule,
  value,
  semester,
});
export const checkUnit = (idModule, idUnit, value, semester) => ({
  type: CHECK_UNIT,
  idModule,
  idUnit,
  value,
  semester,
});
export const checkModuleSucceeded = ({ enrollmentTransactions }) => ({
  type: CHECK_MODULE_SUCCEEDED,
  enrollmentTransactions,
});
export const checkUnitSucceeded = ({ enrollmentTransactions }) => ({
  type: CHECK_UNIT_SUCCEEDED,
  enrollmentTransactions,
});

export const moveModuleTransaction = (idEnrollment, idModule, semester, courseYear) => (
  {
    type: MOVE_TRANSACTION_MODULE,
    idEnrollment,
    idModule,
    semester,
    courseYear,
  });

export const moveModuleTransactionSucceeded = ({ enrollmentTransactions }) => ({
  type: MOVE_TRANSACTION_MODULE_SUCCEEDED,
  enrollmentTransactions,
});
export const moveModuleTransactionError = error => ({
  type: REQUEST_FAILED,
  error,
});
export const setCurrentTab = value => ({
  type: TAB_CHANGE,
  value,
});

export const createTransaction = data => ({
  type: CREATE_TRANSACTION,
  data,
});
export const removeTransaction = idEnrollmentTransaction => ({
  type: REMOVE_TRANSACTION,
  idEnrollmentTransaction,
});
export const createTransactionFailed = () => ({ type: CREATE_TRANSACTION_FAILED });
export const removeTransactionFailed = () => ({ type: REMOVE_TRANSACTION_FAILED });
export const requestEnrollmentExport = bindingObject => ({
  type: REQUEST_ENROLLMENT_EXPORT,
  bindingObject,
});

export const requestEnrollmentExportSucceeded = (data, exportType) => (
  {
    type: REQUEST_ENROLLMENT_EXPORT_SUCCEEDED,
    data,
    exportType,
  });


export const requestEnrollmentGradeExport = bindingObject => ({
  type: REQUEST_ENROLLMENT_GRADE_EXPORT,
  bindingObject,
});

export const requestEnrollmentExportGradeSucceeded = data => (
  {
    type: REQUEST_ENROLLMENT_GRADE_EXPORT_SUCCEEDED,
    data,
  });

export const requestGetAlexiaLog = idEnrollment => (
  {
    type: REQUEST_GET_ALEXIA_LOG,
    idEnrollment,
  });
export const requestGetAlexiaLogSucceeded = (log, idEnrollment) => (
  {
    type: REQUEST_GET_ALEXIA_LOG_SUCCEEDED,
    log,
    idEnrollment,
  });

export const getTransactionPdf = idEnrollmentTransaction => ({
  type: GET_TRANSACTION_PDF,
  idEnrollmentTransaction,
});
export const getTransactionPdfSucceeded = (document, idEnrollmentTransaction) => ({
  type: GET_TRANSACTION_PDF_SUCCEEDED,
  document,
  idEnrollmentTransaction,
});

export const setModuleTransaction = (idModule, value) => ({
  type: SET_MODULE_TRANSACTION,
  idModule,
  value,
});

export const setUnitTransaction = (idModule, idUnit, value) => ({
  type: SET_UNIT_TRANSACTION,
  idModule,
  idUnit,
  value,
});

export const setModuleCostTransaction = (idModule, value) => ({
  type: SET_MODULE_COST_TRANSACTION,
  idModule,
  value,
});

export const setUnitCostTransaction = (idModule, idUnit, value) => ({
  type: SET_UNIT_COST_TRANSACTION,
  idModule,
  idUnit,
  value,
});

export const setDiscountCostTransaction = newDiscount => ({
  type: SET_DISCOUNT_COST_TRANSACTION,
  newDiscount,
});

export const setTransactionPenalty = value => ({
  type: SET_TRANSACTION_PENALTY,
  value,
});

export const requestPagantisOrder = orderId => ({
  type: REQUEST_PAGANTIS_ORDER,
  orderId,
});
export const requestPagantisOrderSucceeded = data => ({
  type: REQUEST_PAGANTIS_ORDER_SUCCEEDED,
  data,
});
export const requestPagantisOrderFailed = () => ({ type: REQUEST_PAGANTIS_ORDER_FAILED });
export const clearPagantisOrder = () => ({ type: CLEAR_PAGANTIS_ORDER });


export const requestGetAllForStudent = idStudent => (
  {
    type: REQUEST_GET_ALL_FOR_STUDENT,
    idStudent,
  }
);
export const requestGetAllForStudentSucceded = data => ({
  type: REQUEST_GET_ALL_FOR_STUDENT_SUCCEEDED,
  data,
});
export const requestGetAllForStudentFailed = () => ({ type: REQUEST_GET_ALL_FOR_STUDENT_FAILED });

export const requestEnrollmentChangeMethodology = (
  idEnrollment,
  idTrainingPlan,
  idRoadMap,
  idAcademy,
  roadMap,
) => ({
  type: REQUEST_ENROLLMENT_CHANGE_METHODOLOGY,
  idEnrollment,
  idTrainingPlan,
  idRoadMap,
  idAcademy,
  roadMap,
});
export const requestEnrollmentChangeMethodologySucceeded = data => ({
  type: REQUEST_ENROLLMENT_CHANGE_METHODOLOGY_SUCCEEDED,
  data,
});
export const requestEnrollmentChangeMethodologyFailed = () => ({
  type: REQUEST_ENROLLMENT_CHANGE_METHODOLOGY_FAILED,
});

export const requestEnrollmentCheckMethodology = (
  idEnrollment,
  idTrainingPlan,
  idRoadMap,
  idAcademy,
) => ({
  type: REQUEST_ENROLLMENT_CHECK_METHODOLOGY,
  idEnrollment,
  idTrainingPlan,
  idRoadMap,
  idAcademy,
});
export const requestEnrollmentCheckMethodologySucceeded = data => ({
  type: REQUEST_ENROLLMENT_CHECK_METHODOLOGY_SUCCEEDED,
  data,
});
export const requestEnrollmentCheckMethodologyFailed = () => ({
  type: REQUEST_ENROLLMENT_CHECK_METHODOLOGY_FAILED,
});

export const requestEnrollmentHold = (search, semester) => ({
  type: REQUEST_ENROLLMENT_HOLD,
  search,
  semester,
});

export const requestEnrollmentHoldSucceded = data => ({
  type: REQUEST_ENROLLMENT_HOLD_SUCCEEDED,
  data,
});
export const requestEnrollmentHoldFailed = () => ({
  type: REQUEST_ENROLLMENT_HOLD_FAILED,
});

export const requestEnrollmentActivation = (semester, activations) => ({
  type: REQUEST_ENROLLMENT_ACTIVATION,
  semester,
  activations,
});

export const requestEnrollmentActivationSucceded = data => ({
  type: REQUEST_ENROLLMENT_ACTIVATION_SUCCEEDED,
  data,
});
export const requestEnrollmentActivationFailed = () => ({
  type: REQUEST_ENROLLMENT_ACTIVATION_FAILED,
});

export const setTransactionUpdatePrices = newPrices => ({
  type: SET_TRANSACTION_UPDATE_PRICES,
  newPrices,
});
export const setTransactionUpdatePricesSucceeded = data => ({
  type: SET_TRANSACTION_UPDATE_PRICES_SUCCEEDED,
  data,
});
export const setTransactionUpdatePricesFailed = () => ({
  type: SET_TRANSACTION_UPDATE_PRICES_FAILED,
});

export const requestEnrollmentServices = (bindingObject, origin) => ({
  type: REQUEST_ENROLLMENT_SERVICES,
  bindingObject,
  origin,
});
export const requestEnrollmentServicesSucceeded = ({
  enrollment: {
    data,
    pages,
  },
}, origin, bindingObject) => ({
  type: REQUEST_ENROLLMENT_SERVICES_SUCCEEDED,
  data,
  pages,
  origin,
  bindingObject,
});
export const requestEnrollmentServicesFailed = () => ({ type: REQUEST_ENROLLMENT_SERVICES_FAILED });

export const deleteModule = (idRoadMap, idModule, id) => ({
  type: DELETE_MODULE,
  idRoadMap,
  idModule,
  id,
});

export const deleteEnrollmentJustificanteTransfe = idEnrollment => ({
  type: DELETE_JUSTIFICANTE_TRANSFE,
  idEnrollment,
});
export const deleteEnrollmentJustificanteTransfeSucceeded = () => (
  { type: DELETE_JUSTIFICANTE_TRANSFE_SUCCEEDED }
);
export const deleteEnrollmentJustificanteTransfeFailed = () => (
  { type: DELETE_JUSTIFICANTE_TRANSFE_FAILED }
);
export const updateEnrollmentJustificanteTransfe = bindingObject => ({
  type: UPDATE_JUSTIFICANTE_TRANSFE,
  bindingObject,
});
export const updateEnrollmentJustificanteTransfeSucceeded = () => (
  { type: UPDATE_JUSTIFICANTE_TRANSFE_SUCCEEDED }
);
export const updateEnrollmentJustificanteTransfeFailed = () => (
  { type: UPDATE_JUSTIFICANTE_TRANSFE_FAILED }
);

export const requestEnrollmentTransferEspera = bindingObject => ({
  type: REQUEST_ENROLMENT_TRANSFER_ESPERA,
  bindingObject,
});
export const requestEnrollmentTransferEsperaSucceeded = ({ data, pages }) => ({
  type: REQUEST_ENROLMENT_TRANSFER_ESPERA_SUCCEEDED, data, pages,
});
export const requestEnrollmentTransferEsperaFailed = () => ({
  type: REQUEST_ENROLMENT_TRANSFER_ESPERA_FAILED,
});
