import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Title from 'ui/Title';
import Grid from './Grid';
import PersonalForm from '../Service/Forms/personalForm';
import Loading from '$routes/Loading';
import {
  FormControl,
  Select,
  Button,
} from '@material-ui/core';

import {
  informationRequest,
} from '$redux/student/actions';

import {
  get as syncGradeStudent,
} from '$redux/grades/actions';

import {
  requestGetAllForStudent,
} from '$redux/enrollments/actions';

class StudentGrades extends React.Component { // eslint-disable-line
  constructor(props) {
    super(props);
    this.state = {
      idEnrollment: 0,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { match: { params: { idStudent } } } = this.props;

    dispatch(informationRequest({ idStudent }));
    dispatch(requestGetAllForStudent(idStudent));
  }

  gradeSync = (idUser, idEnrollment) => {
    const { dispatch } = this.props;
    dispatch(syncGradeStudent(
      idUser,
      idEnrollment,
    ));
  }

  set = (key, value) => {
    this.setState({
      [key]: value,
    });
  }

  render() {
    const {
      studentData,
      loading,
      optionsData,
      reload,
      semesters,
    } = this.props;

    const {
      idEnrollment,
    } = this.state;

    const disabled = true;

    if (loading) return <Loading />;
    if (reload) window.location.reload();

    if (!studentData
      || !studentData.personalInfo
      || !studentData.personalInfo.idUser) return <Loading />;

    const semestersList = semesters.map(
      item => (
        <option key={item.value} value={item.value}>{item.name}</option>
      ),
    );

    return (
      <div>
        <Title>
          Notas
        </Title>
        <PersonalForm
          title="Información personal"
          data={studentData.personalInfo}
          optionsData={optionsData}
          disabled={disabled}
        />
        <div className="columns is-vcentered" style={{ backgroundColor: '#86efac', marginLeft: '2px', marginRight: '2px' }}>
          <div className="column is-one-fifth">Semestres</div>
          <div className="column">
            <FormControl className="formControl">
              <Select
                value={idEnrollment}
                onChange={e => this.set('idEnrollment', e.target.value)}
                native
                className="meth-select"
                style={{ width: '220px' }}
              >
                <option value={0}>Todos</option>
                {semestersList}
              </Select>
            </FormControl>
          </div>
          <Button onClick={() => this.gradeSync(studentData.personalInfo.idUser, idEnrollment)} size="small" style={{ marginRight: '16px' }}>
            {idEnrollment > 0 ? 'Sincronizar Semestre' : 'Sincronizar todos los Semestres' }
          </Button>
        </div>
        <Grid
          idUser={studentData.personalInfo.idUser}
          idEnrollment={idEnrollment}
        />
      </div>);
  }
}

StudentGrades.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  studentData: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  optionsData: PropTypes.object,
  reload: PropTypes.bool,
  semesters: PropTypes.array,
};

// /student/me/enrollment (se suopne q)

StudentGrades.defaultProps = {
  studentData: {
    personalInfo: undefined,
    contactInfo: undefined,
    shippingInfo: undefined,
    billingInfo: undefined,
    academicInfo: undefined,
  },
  semesters: undefined,
  optionsData: undefined,
  reload: false,
};

export default connect(
  state => ({
    studentData: state.student.studentData,
    loading: state.student.loading,
    optionsData: state.student.optionsData,
    semesters: state.enrollments.data,
    reload: state.grades.reload,
  }),
)(StudentGrades);
