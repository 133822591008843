export const REQUEST = 'VOUCHERS_REQUEST';
export const REQUEST_SUCCEEDED = 'VOUCHERS_REQUEST_SUCCEEDED';
export const REQUEST_FAILED = 'VOUCHERS_REQUEST_FAILED';
export const LIST = 'VOUCHERS_LIST';
export const LIST_SUCCEEDED = 'VOUCHERS_LIST_SUCCEEDED';
export const BEFORE_CREATE = 'VOUCHER_BEFORE_CREATE';
export const BEFORE_CREATE_SUCCEEDED = 'VOUCHER_BEFORE_CREATE_SUCCEEDED';
export const GET = 'VOUCHER_GET';
export const GET_SUCCEEDED = 'VOUCHER_GET_SUCCEEDED';
export const UPDATE = 'VOUCHER_UPDATE';
export const UPDATE_SUCCEEDED = 'VOUCHER_UPDATE_SUCCEEDED';
export const REMOVE = 'VOUCHER_REMOVE';
export const REMOVE_SUCCEEDED = 'VOUCHER_REMOVE_SUCCEEDED';
export const CREATE = 'VOUCHER_CREATE';
export const CREATE_SUCCEEDED = 'VOUCHER_CREATE_SUCCEEDED';
export const SET = 'VOUCHER_SET';
export const RESET = 'VOUCHER_RESET';
export const CLEAN_FILTERS_REQUEST = 'VOUCHER_CLEAN_FILTERS_REQUEST';
export const FILTERS_REQUEST = 'VOUCHER_FILTERS_REQUEST';
export const FILTERS_REQUEST_SUCCEEDED = 'VOUCHER_FILTERS_REQUEST_SUCCEEDED';
export const FILTERS_REQUEST_FAILED = 'VOUCHER_FILTERS_REQUEST_FAILED';
