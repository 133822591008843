import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Spinner from 'react-spinkit';
import { Button } from '@material-ui/core';

import Title from 'ui/Title';
import theme from 'ui/theme';

import { canSelector } from '$redux/login/selectors';
import { setSelection, removeSelections, alexia } from '$redux/enrollments/actions';

import { Wrapper, AlexiaLoading } from './styles';
import Filters from './Filters';
import Grid from './Grid';


class Enrollments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {},
    };
  }

  setSelection = (id, value) => {
    const { dispatch } = this.props;
    dispatch(setSelection(id, value));
  }

  removeSelections = () => {
    const { dispatch } = this.props;
    dispatch(removeSelections());
  }

  integrateAlexia = () => {
    const { dispatch, selections } = this.props;
    dispatch(alexia(selections));
  }

  applyFilters = (filtersSelected) => {
    this.setState({ filters: filtersSelected });
  }

  render() {
    const {
      selections,
      selections: { length: selectedItems },
      alexiaLoading,
      userCan,
    } = this.props;
    const { filters } = this.state;

    return (
      <Wrapper theme={theme}>
        <Title>
          Matrículas
          {
            // ALEXIA BUTTONS
            // eslint-disable-next-line
            userCan('ENROLLMENT_ALEXIA') ? (
              alexiaLoading ? (
                <AlexiaLoading>
                  <Spinner size="small" name="rotating-plane" fadeIn="none" color="#777" />
                  <span className="alexia-label">Integrando ({selectedItems}) en Alexia...</span>
                </AlexiaLoading>
              ) : (
                <div>
                  <Button
                    size="small"
                    disabled={!selectedItems}
                    onClick={this.integrateAlexia}
                  >
                    Integrar ({selectedItems}) en Alexia
                  </Button>
                  <Button
                    size="small"
                    disabled={!selectedItems}
                    onClick={this.removeSelections}
                  >
                    Quitar selección
                  </Button>
                </div>
              )
            ) : null
          }
        </Title>
        <Filters applyFilters={this.applyFilters} />
        <Grid
          filters={filters}
          selections={selections}
          setSelection={this.setSelection}
        />
      </Wrapper>
    );
  }
}

Enrollments.propTypes = {
  dispatch: PropTypes.func.isRequired,
  userCan: PropTypes.func.isRequired,
  selections: PropTypes.array.isRequired,
  alexiaLoading: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    userCan: canSelector(state),
    selections: state.enrollments.selections,
    alexiaLoading: state.enrollments.alexiaLoading,
  }),
)(Enrollments);
