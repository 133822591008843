import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 3 !Important;

  img {
    width: 150px;
  }

  .filters {
    align-content: space-around;
    height: 85px;
    width: 100%;
    display: grid;
    /* grid-template-columns: repeat(4, 1fr); */
    grid-template-columns: 240px 260px 120px;
    grid-template-rows: 40px;
    grid-column-gap: .75vw;

    & > * {
      max-height: 100%;
      font-size: 11px;

      &.button {
        height: 100%;
      }
    }
    &.DateRangePicker {
      border: none;
    }
  }

  .filters-form {
    width: 100%;
  }

  .filter-item {
    font-size: 14px;
  }

  select {
    height: 20px;
  }
`;
