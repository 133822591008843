import styled from 'styled-components';
import { colors } from 'ui/theme';

export const Wrapper = styled.div`
  flex-grow: 1;
`;

export const Line = styled.div`
  border-top-width: 5px;
  border-top-style: ${({ type }) => (type === 'success' ? 'solid' : 'dashed')};
  border-top-color: ${({ type }) => colors[type]};
`;
