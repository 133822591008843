import React from 'react';
import PropTypes from 'prop-types';
import {
  NavLink,
  withRouter,
} from 'react-router-dom';

import { faCaretDown } from '@fortawesome/fontawesome-free-solid';
import {
  DropdownIcon,
  SubMenuItems,
  Text,
  Wrapper as MenuItemWrapper,
} from './MenuItem.styles';

const MenuItem = ({
  children, hasSubPaths, to, blank, onClick, isMenu, paths, ...props
}) => {
  const { location } = props;
  const haveSubItems = Array.isArray(children);
  const firstChild = haveSubItems ? children[0] : children;

  let link;

  // external link
  if (to.startsWith('http')) {
    link = (
      <a
        href={to}
        target={blank ? '_blank' : '_self'}
        onClick={onClick}
      >
        {children}
      </a>
    );
    // internal link
  } else {
    link = (
      <NavLink exact={(!haveSubItems && !hasSubPaths) || to === '/'} to={to} onClick={onClick}>
        <Text>{firstChild}</Text>
        {isMenu && <DropdownIcon icon={faCaretDown} />}
      </NavLink>
    );
  }

  return (
    <div>
      <MenuItemWrapper>
        {link}
      </MenuItemWrapper>
      {haveSubItems && paths.includes(location.pathname)
        ? <SubMenuItems>{children.slice(1)}</SubMenuItems> : ''}
    </div>
  );
};

MenuItem.propTypes = {
  hasSubPaths: PropTypes.bool,
  blank: PropTypes.bool,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isMenu: PropTypes.bool,
  paths: PropTypes.array,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};

MenuItem.defaultProps = {
  hasSubPaths: false,
  blank: false,
  onClick: () => {
  },
  isMenu: false,
  paths: [],
  location: {
    pathname: '',
  },
};

export default withRouter(MenuItem);
