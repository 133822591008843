import {
  select, call, put, takeEvery,
} from 'redux-saga/effects';
import api from 'linkia-api';

import {
  LIST_STAFF,
  GET_STAFF,
  UPDATE_STAFF,
  REMOVE_STAFF,
  CREATE_STAFF,
  CHANGE_STAFF_PASSWORD,
  GET_STAFF_PROPS,
} from './action-types';
import {
  list,
  listSucceeded,
  listFailed,
  getSucceeded,
  getFailed,
  updateSucceeded,
  updateFailed,
  removeSucceeded,
  removeFailed,
  createSucceeded,
  createFailed,
  reset,
  redoStaffPasswordSucceeded,
  redoStaffPasswordFailed,
  getPropsSucceeded,
  getPropsFailed,
} from './actions';

import { tokenSelector } from '../login/selectors';

function* listStaff() {
  const token = yield select(tokenSelector);
  try {
    const data = yield call(api.staff.listStaff, { token });
    yield put(listSucceeded(data));
  } catch (e) {
    yield put(listFailed(e));
  }
}

function* getStaff(action) {
  const token = yield select(tokenSelector);
  const { id } = action;
  try {
    const data = yield call(api.staff.get, { token, id });
    yield put(getSucceeded(data));
  } catch (e) {
    yield put(getFailed(e));
  }
}

function* updateStaff(action) {
  const token = yield select(tokenSelector);
  const { id, data } = action;
  try {
    const success = yield call(api.staff.update, { token, id, data });
    yield put(updateSucceeded(success));
    yield put(list());
  } catch (e) {
    yield put(updateFailed(e));
  }
}

function* removeStaff(action) {
  const token = yield select(tokenSelector);
  const { id } = action;
  try {
    const success = yield call(api.staff.delete, { token, id });
    yield put(removeSucceeded(success));
    yield put(list());
  } catch (e) {
    yield put(removeFailed(e));
  }
}

function* createStaff(action) {
  const token = yield select(tokenSelector);
  const { data } = action;
  try {
    const success = yield call(api.staff.create, { token, data });
    yield put(createSucceeded(success));
    yield put(reset());
    yield put(list());
  } catch (e) {
    yield put(createFailed(e));
  }
}

function* generarContraseña(action) {
  const token = yield select(tokenSelector);
  const { email } = action;
  try {
    const { success } = yield call(api.staff.redoPassword, { email, token });
    if (success) {
      yield put(redoStaffPasswordSucceeded());
    } else {
      yield put(redoStaffPasswordFailed());
    }
  } catch (e) {
    yield put(redoStaffPasswordFailed());
  }
}

function* getStaffProps() {
  const token = yield select(tokenSelector);
  try {
    const data = yield call(api.staff.getStaffProperties, { token });
    yield put(getPropsSucceeded(data));
  } catch (e) {
    yield put(getPropsFailed(e));
  }
}

export default [
  takeEvery(LIST_STAFF, listStaff),
  takeEvery(GET_STAFF, getStaff),
  takeEvery(UPDATE_STAFF, updateStaff),
  takeEvery(REMOVE_STAFF, removeStaff),
  takeEvery(CREATE_STAFF, createStaff),
  takeEvery(CHANGE_STAFF_PASSWORD, generarContraseña),
  takeEvery(GET_STAFF_PROPS, getStaffProps),
];
