import React from 'react';
import PropTypes from 'prop-types';
import { Typography, TextField } from '@material-ui/core';
import { Wrapper } from './styles';
import SubTitle from '../../../../ui/SubTitle';
import { connect } from 'react-redux';
import Select from '@material-ui/core/Select';
import {
  list as listModuleCollaboratingCenters,
} from '$redux/collaborating-centers/actions';

class PasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      hasPasswordError: false,
      passwordConfirmation: '',
      email: '',
      hasEmailError: false,
      emailConfirmation: '',
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(
      listModuleCollaboratingCenters(),
    );
  }

  setEmail = (emailConfirmation) => {
    const { disableButton } = this.props;
    const { email } = this.state;

    if (emailConfirmation !== email) {
      disableButton(true);
      this.setState({
        hasEmailError: true,
        emailConfirmation,
      });
    } else {
      const { set } = this.props;
      disableButton(false);
      set('accountInfo', 'email', emailConfirmation);
      this.setState({
        hasEmailError: false,
        emailConfirmation,
      });
    }
  }


  setPassword = (passwordConfirmation) => {
    const { disableButton } = this.props;
    const { password } = this.state;

    if (passwordConfirmation !== password) {
      disableButton(true);
      this.setState({
        hasPasswordError: true,
        passwordConfirmation,
      });
    } else {
      const { set } = this.props;
      disableButton(false);
      set('accountInfo', 'password', passwordConfirmation);
      this.setState({
        hasPasswordError: false,
        passwordConfirmation,
      });
    }
  }

  firstEmail = (email) => {
    const { disableButton } = this.props;
    const { emailConfirmation } = this.state;

    if (email !== emailConfirmation) {
      disableButton(true);
      this.setState({
        email,
        hasEmailError: true,
      });
    } else {
      disableButton(false);
      this.setState({
        email,
        hasEmailError: false,
      });
    }
  }

  firstPassword = (password) => {
    const { disableButton } = this.props;
    const { passwordConfirmation } = this.state;

    if (password !== passwordConfirmation) {
      disableButton(true);
      this.setState({
        password,
        hasPasswordError: true,
      });
    } else {
      disableButton(false);
      this.setState({
        password,
        hasPasswordError: false,
      });
    }
  }

  setSecondaryEmail = (value) => {
    const { set } = this.props;
    set('accountInfo', 'secondaryEmail', value);
  };

  setCollaboratingCenterEmail = (value) => {
    const { set } = this.props;
    set('accountInfo', 'collaboratingCenterStudentEmail', value);
  };

  setIdCollaboratingCenter = () => (event) => {
    const { set } = this.props;
    event.preventDefault();
    set('accountInfo', 'idCollaboratingCenter', event.target.value);
  };

  render() {
    let password;
    let passwordConfirmation;
    let email;
    let emailConfirmation;

    let secondaryEmail;
    let collaboratingCenterStudentEmail;
    let idCollaboratingCenter;

    let collaboratingCenterLists;
    const {
      data,
      disabled,
      title,
      collaboratingCenters,
    } = this.props;

    if (collaboratingCenters) {
      collaboratingCenterLists = collaboratingCenters.map(
        item => (
          <option key={item.idCollaboratingCenter} value={item.idCollaboratingCenter}>
            {item.name}
          </option>
        ),
      );
    } else {
      collaboratingCenterLists = [];
    }

    const { hasEmailError, hasPasswordError } = this.state;

    if (data) {
      email = (
        <div className="column is-half">
          <div className="columns is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Email</Typography>
            </div>
            <div className="column is-three-fifths">
              <TextField
                fullWidth
                disabled={disabled}
                onChange={e => this.firstEmail(e.target.value)}
                defaultValue={data.email ? data.email : ''}
              />
            </div>
          </div>
        </div>
      );
      emailConfirmation = (
        <div className="column is-half">
          <div className="columns right is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Confirmar Email</Typography>
            </div>
            <div className="column is-three-fifths">
              <TextField
                fullWidth
                disabled={disabled}
                error={hasEmailError}
                onChange={e => this.setEmail(e.target.value)}
              />
            </div>
          </div>
        </div>
      );
      password = (
        <div className="column is-half">
          <div className="columns is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Contraseña</Typography>
            </div>
            <div className="column is-three-fifths">
              <TextField
                fullWidth
                disabled={disabled}
                onChange={e => this.firstPassword(e.target.value)}
              />
            </div>
          </div>
        </div>
      );
      passwordConfirmation = (
        <div className="column is-half">
          <div className="columns right is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Confirmar Contraseña</Typography>
            </div>
            <div className="column is-three-fifths">
              <TextField
                fullWidth
                disabled={disabled}
                error={hasPasswordError}
                onChange={e => this.setPassword(e.target.value)}
              />
            </div>
          </div>
        </div>
      );
      secondaryEmail = (
        <div className="column is-half">
          <div className="columns is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Email Linkia</Typography>
            </div>
            <div className="column is-three-fifths">
              <TextField
                fullWidth
                disabled={1}
                placeholder="Se genera automatica..."
                onChange={e => this.setSecondaryEmail(e.target.value)}
                defaultValue={data.secondaryEmail ? data.secondaryEmail : ''}
              />
            </div>
          </div>
        </div>
      );
      collaboratingCenterStudentEmail = (
        <div className="column is-half">
          <div className="columns right is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Email CC</Typography>
            </div>
            <div className="column is-three-fifths">
              <TextField
                fullWidth
                disabled={disabled}
                onChange={e => this.setCollaboratingCenterEmail(e.target.value)}
                defaultValue={data.collaboratingCenterStudentEmail ? data.collaboratingCenterStudentEmail : ''}
              />
            </div>
          </div>
        </div>
      );
      idCollaboratingCenter = (
        <div className="column is-half">
          <div className="columns is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Centro Colaborador</Typography>
            </div>
            <div className="column is-three-fifths">
              <Select
                native
                value={data.idCollaboratingCenter}
                onChange={this.setIdCollaboratingCenter()}
                className="filter-item"
              >
                <option key="collaboratingCenterKey" value="">Seleccionar CC</option>
                {collaboratingCenterLists}
              </Select>
            </div>
          </div>
        </div>
      );
    }
    return (
      <Wrapper>
        <div className="student-data">
          <SubTitle>{title}</SubTitle>
          <div className="columns is-multiline is-narrow content">
            {email}
            {emailConfirmation}
            {password}
            {passwordConfirmation}
            {secondaryEmail}
            {collaboratingCenterStudentEmail}
            {idCollaboratingCenter}
          </div>
        </div>
      </Wrapper>);
  }
}

PasswordForm.propTypes = {
  data: PropTypes.object,
  collaboratingCenters: PropTypes.array,
  disabled: PropTypes.bool,
  set: PropTypes.func.isRequired,
  disableButton: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

PasswordForm.defaultProps = {
  collaboratingCenters: [],
  data: undefined,
  disabled: false,
};

export default connect(
  state => ({
    collaboratingCenters: state.collaboratingCenters.list,
  }),
)(PasswordForm);
