import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import 'react-table/react-table.css';

import GridLog from './GridLog';

const StyledDialog = withStyles({ paper: { padding: 50 } })(props => (
  <Dialog {...props}>{props.children}</Dialog>
));

// eslint-disable-next-line react/prefer-stateless-function
class LogDialog extends React.Component {
  render() {
    const {
      open,
      closeThread,
      idUserServiceAction,
    } = this.props;

    return (
      <StyledDialog
        className="messages-dialog"
        open={open}
        onClose={closeThread}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">Logs</DialogTitle>
        <DialogContent>
          <GridLog idUserServiceAction={idUserServiceAction} />
        </DialogContent>
      </StyledDialog>
    );
  }
}

LogDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeThread: PropTypes.func.isRequired,
  idUserServiceAction: PropTypes.any.isRequired,
};

LogDialog.defaultProps = {
};

export default LogDialog;
