import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  reset as resetCharge,
  set as setCharge,
  create as createCharge,
  beforeCreate as beforeCreateCharge,
} from '$redux/charges/actions';

import Title from 'ui/Title';
import Loading from '$routes/Loading';
import Failed from '$routes/Failed';

import Form from '../Form';

class Charges extends React.Component {
  componentDidMount = () => {
    const { dispatch } = this.props;
    dispatch(resetCharge());
    dispatch(beforeCreateCharge());
  }

  onCreate = () => {
    const { dispatch, data } = this.props;
    dispatch(createCharge(data));
  }

  set = (key, value) => {
    const { dispatch } = this.props;
    dispatch(setCharge(key, value));
  }

  render() {
    const {
      data,
      ui,
      loading,
    } = this.props;

    if (loading) return <Loading />;
    if (!data) return <Failed />;

    return (
      <div>
        <Title>Crear nuevo cobro</Title>
        <Form
          action="new"
          data={data}
          ui={ui}
          set={this.set}
          onSend={this.onCreate}
        />
      </div>
    );
  }
}

Charges.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.object,
  ui: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

Charges.defaultProps = {
  data: null,
};

export default connect(
  state => ({
    data: state.charges.single,
    ui: state.charges.ui,
    loading: state.charges.loading,
  }),
)(Charges);
