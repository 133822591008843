import {
  REQUEST_GET_ALL_MODULE_CODES,
  REQUEST_GET_ALL_MODULE_CODES_SUCCEEDED,
  REQUEST_GET_ALL_MODULE_CODES_FAILED,
  REQUEST_UPDATE_ALL_MODULE_CODES,
  REQUEST_UPDATE_ALL_MODULE_CODES_SUCCEEDED,
  REQUEST_UPDATE_ALL_MODULE_CODES_FAILED,
} from './action-types';

export const reqGetAllModuleCodes = idSemester => ({
  type: REQUEST_GET_ALL_MODULE_CODES, idSemester,
});
export const reqGetAllModuleCodesSucceeded = ({
  data: { data },
}) => ({
  type: REQUEST_GET_ALL_MODULE_CODES_SUCCEEDED,
  data,
});
export const reqGetAllModuleCodesFailed = () => (
  { type: REQUEST_GET_ALL_MODULE_CODES_FAILED });


export const reqUpdateAllModuleCodes = bindingObject => ({
  type: REQUEST_UPDATE_ALL_MODULE_CODES, bindingObject,
});
export const reqUpdateAllModuleCodesSucceeded = ({
  data: { result },
}) => ({
  type: REQUEST_UPDATE_ALL_MODULE_CODES_SUCCEEDED,
  result,
});
export const reqUpdateAllModuleCodesFailed = () => (
  { type: REQUEST_UPDATE_ALL_MODULE_CODES_FAILED });
