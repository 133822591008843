import React from 'react';
import PropTypes from 'prop-types';
import SubTitle from 'ui/SubTitle';
import {
  Wrapper,
  SemesterWrapper,
} from './RoadMap.styles';
import RoadMapModuleRowMethodology from './RoadMapModuleRowMethodology';

class RoadMapMethodology extends React.Component {
  constructor(props) {
    super(props);
    this.semesters = [];
  }

  getModuleName = module => (module.code.toUpperCase().concat(': ').concat(module.name));

  getModuleCode = module => ('M'.concat(module.idModule));

  createModuleItem = (roadMapModule, semester) => {
    const { onCheckModule, onCheckUnit } = this.props;
    const { modules, units } = this.props;
    let itemModule;

    if (roadMapModule.ModuleSemester.semester === semester.semester
      && roadMapModule.ModuleSemester.courseYear === semester.courseYear) {
      const module = roadMapModule.Module;
      module.stage = roadMapModule.ModuleSemester.stage;

      const itemText = this.getModuleName(module);
      const moduleKey = this.getModuleCode(module);

      let checked = false;
      const already = modules.find(selected => selected.idModule === module.idModule);
      if (already
        && already.semester === semester.semester
        && already.courseYear === semester.courseYear) {
        // eslint-disable-next-line prefer-destructuring
        checked = already.checked;
      }

      itemModule = (
        <RoadMapModuleRowMethodology
          key={moduleKey}
          checked={checked || false}
          itemText={itemText}
          module={module}
          semester={semester}
          checkmodule={onCheckModule}
          units={units}
          checkunit={onCheckUnit}
          disabled={false}
          idRoadMapModule={roadMapModule.idRoadMapModule}
        />
      );
    }
    return itemModule;
  }

  createSemesterItem(semester, roadMapModules) {
    const semesterGroupKey = 'SG'.concat(semester.idModuleSemester);
    const semesterRowKey = 'SR'.concat(semester.idModuleSemester);
    const semesterKey = 'S'.concat(semester.idModuleSemester);

    const subItems = roadMapModules.map(
      roadMapModule => this.createModuleItem(roadMapModule, semester),
    );

    return (
      <div key={semesterGroupKey} className="roadmap-semester">
        <SemesterWrapper>
          <div key={semesterRowKey} className="columns">
            <div key={semesterKey} className="column">{ semester.stage }</div>
          </div>
        </SemesterWrapper>
        <div className="modules columns is-multiline content">
          {subItems}
        </div>
      </div>
    );
  }

  render() {
    const { data } = this.props;
    let roadMapCustomizable;
    if (data.Semesters) {
      roadMapCustomizable = data.Semesters.map(
        semester => this.createSemesterItem(
          semester,
          data.RoadMapModules,
        ),
      );
    }

    return (
      <Wrapper>
        <SubTitle>Módulos y UFs</SubTitle>
        {roadMapCustomizable}
      </Wrapper>
    );
  }
}

RoadMapMethodology.propTypes = {
  data: PropTypes.object,
  onCheckModule: PropTypes.func.isRequired,
  onCheckUnit: PropTypes.func.isRequired,
  modules: PropTypes.array,
  units: PropTypes.array,
};

RoadMapMethodology.defaultProps = {
  data: null,
  modules: [],
  units: [],
};

export default RoadMapMethodology;
