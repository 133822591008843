import {
  select, call, put, takeEvery,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import api from 'linkia-api';

import {
  LIST,
  GET,
  UPDATE,
  REMOVE,
  CREATE,
  SWITCH,
  GET_METHODOLOGIES,
  ACTIVATE_TP,
  SHOW_OLD_TP,
  DELETE_MODULE,
  MODIFY_MOODLE_CODE_TP,
} from './action-types';
import {
  requestFailed,
  listSucceeded,
  getSucceeded,
  updateSucceeded,
  get,
  removeSucceeded,
  createSucceeded,
  switchStateSucceeded,
  getMethodologiesSucceeded,
  activateTrainingPlanSucceeded,
  showOldTrainingPlanSucceeded,
} from './actions';

import { tokenSelector } from '../login/selectors';

function* listCourses() {
  const token = yield select(tokenSelector);
  try {
    const data = yield call(api.course.listOptions, { token });
    yield put(listSucceeded(data));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* getCourse(action) {
  const token = yield select(tokenSelector);
  const { id } = action;
  try {
    const data = yield call(api.course.get, { token, idCourse: id });
    yield put(getSucceeded(data));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* updateCourse(action) {
  const token = yield select(tokenSelector);
  const { id, data } = action;
  try {
    const success = yield call(api.course.update, { token, idCourse: id, courseEdit: data });
    yield put(updateSucceeded(success));
    if (id && id !== '0') {
      yield put(get(id));
    }
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* removeCourse(action) {
  const token = yield select(tokenSelector);
  const { id } = action;
  try {
    const success = yield call(api.course.get, { token, id });
    yield put(removeSucceeded(success));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* createCourse(action) {
  const token = yield select(tokenSelector);
  const { data } = action;
  try {
    const success = yield call(api.course.create, { token, data });
    yield put(createSucceeded(success));
    yield put(push(`/centros/editar/${success.idCourse}`));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* switchCourse(action) {
  const token = yield select(tokenSelector);
  const { id } = action;
  try {
    const data = yield call(api.course.switch, { token, id });
    yield put(switchStateSucceeded(data));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* getMethodologies() {
  const token = yield select(tokenSelector);
  try {
    const data = yield call(api.course.getMethodologies, { token });
    yield put(getMethodologiesSucceeded(data));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* reqActivateTrainingPlan(action) {
  const token = yield select(tokenSelector);
  const { active, idTrainingPlan, idCourse } = action;
  try {
    const data = yield call(api.trainingPlan.activateTrainingPlan, {
      token, active, idTrainingPlan,
    });
    yield put(get(idCourse));
    yield put(activateTrainingPlanSucceeded(data));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* reqShowOldTrainingPlan(action) {
  const token = yield select(tokenSelector);
  const { active, idTrainingPlan, idCourse } = action;
  try {
    const data = yield call(api.trainingPlan.showOldTrainingPlan, {
      token, active, idTrainingPlan,
    });
    yield put(get(idCourse));
    yield put(showOldTrainingPlanSucceeded(data));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* reqDeleteModule(action) {
  const token = yield select(tokenSelector);
  const { idRoadMap, idModule, id } = action;
  try {
    yield call(api.module.delete, { token, idModule, idRoadMap });
    const data = yield call(api.course.get, { token, idCourse: id });
    yield put(getSucceeded(data));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* reqModifyMoodleCodeTrainingPlan(action) {
  const token = yield select(tokenSelector);
  const { moodleCode, idTrainingPlan, idCourse } = action;
  try {
    const data = yield call(api.trainingPlan.modifyMoodleCodeTrainingPlan, {
      token, moodleCode, idTrainingPlan,
    });
    yield put(get(idCourse));
    yield put(activateTrainingPlanSucceeded(data));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

export default [
  takeEvery(LIST, listCourses),
  takeEvery(GET, getCourse),
  takeEvery(UPDATE, updateCourse),
  takeEvery(REMOVE, removeCourse),
  takeEvery(CREATE, createCourse),
  takeEvery(SWITCH, switchCourse),
  takeEvery(GET_METHODOLOGIES, getMethodologies),
  takeEvery(ACTIVATE_TP, reqActivateTrainingPlan),
  takeEvery(DELETE_MODULE, reqDeleteModule),
  takeEvery(MODIFY_MOODLE_CODE_TP, reqModifyMoodleCodeTrainingPlan),
  takeEvery(SHOW_OLD_TP, reqShowOldTrainingPlan),
];
