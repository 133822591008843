import React from 'react';
import PropTypes from 'prop-types';

import {
  withStyles,
  FormControlLabel,
  Checkbox,
  Button,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import 'react-table/react-table.css';
import { Wrapper } from './ServiceDialog.styles';

const StyledDialog = withStyles({ paper: { padding: 50 } })(props => (
  <Dialog {...props}>{props.children}</Dialog>
));

// eslint-disable-next-line react/prefer-stateless-function
class ServiceDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchFilter: false,
      isActive: true,
      groups: [{
        group: 'OUTLOOK',
        actions: [
          {
            name: 'outlookCreate',
            title: 'Crear Servicio en outlookCreate',
            checked: true,
            tag: 'registro_basico',
          },
          {
            name: 'outlookCheck',
            title: 'Comprobar que el servicio existe outlookCheck',
            checked: true,
            tag: 'registro_basico',
          },
          {
            name: 'outlookGroupCreate',
            title: 'Crear Servicio en outlookGroupCreate',
            checked: true,
            tag: 'registro_basico',
          },
          {
            name: 'outlookLicenceCreate',
            title: 'Comprobar que el servicio existe outlookLicenceCreate',
            checked: true,
            tag: 'registro_basico',
          },
        ],
      },
      {
        group: 'LDAP',
        actions: [
          {
            name: 'ldapCreate',
            title: 'Crear Servicio en ldapCreate',
            checked: true,
            tag: 'registro_basico',
          },
          {
            name: 'ldapCheck',
            title: 'Comprobar que el servicio existe ldapCheck',
            checked: true,
            tag: 'registro_basico',
          },
        ],
      },
      {
        group: 'ADOBE',
        actions: [
          {
            name: 'adobeCreate',
            title: 'Crear Servicio en adobeCreate',
            checked: true,
            tag: 'registro_basico',
          },
          {
            name: 'adobeCheck',
            title: 'Comprobar que el servicio existe adobeCheck',
            checked: true,
            tag: 'registro_basico',
          },
        ],
      },
      {
        group: 'MOODLE',
        actions: [
          {
            name: 'alexiaCreate',
            title: 'Crear Servicio en alexiaCreate',
            checked: true,
            tag: 'registro_basico',
          },
          {
            name: 'alexiaCheck',
            title: 'Comprobar que el servicio existe alexiaCheck',
            checked: true,
            tag: 'registro_basico',
          },
          {
            name: 'alexiaEnrollmentCreate',
            title: 'Crear Servicio en alexiaEnrollmentCreate',
            checked: true,
          },
          {
            name: 'alexiaExtraCreate',
            title: 'Crear Servicio en alexiaExtraCreate',
            checked: false,
          },
        ],
      },
      ],
    };
  }

  onChangeAction = (actionName, checked) => {
    const { groups } = this.state;

    let isActive = false;
    groups.forEach((group) => {
      group.actions.forEach((action) => {
        if (action.name === actionName) {
          Object.assign(action, { ...action, checked });
        }

        if (action.checked === true) {
          isActive = true;
        }
      });
    });

    this.setState({
      groups,
      isActive,
    });
  };

  onChangeSearchFilter = (checked) => {
    this.setState({
      searchFilter: checked,
    });
  };

  onIntegrateServicesApi = () => {
    const { integrateServicesApi } = this.props;
    const { groups, searchFilter } = this.state;

    const actions = [];
    groups.forEach((group) => {
      group.actions.forEach((action) => {
        if (action.checked) {
          actions.push(action.name);
        }
      });
    });

    if (actions.length > 0) {
      integrateServicesApi(actions, searchFilter);
    }
  }

  render() {
    const {
      open,
      closeThread,
      allowSearchFilter,
    } = this.props;

    const {
      groups,
      searchFilter,
      isActive,
    } = this.state;

    const drawActions = actions => actions.map(action => (
      <FormControlLabel
        control={<Checkbox color="primary" />}
        checked={action.checked}
        key={action.name}
        label={action.title}
        value={action.name}
        onChange={e => this.onChangeAction(action.name, e.target.checked)}
      />
    ));

    return (
      <StyledDialog
        className="messages-dialog"
        open={open}
        onClose={closeThread}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">Servicios</DialogTitle>
        <DialogContent>
          <Wrapper>
            <div className="layout">
              {groups.map(g => (
                <div className="column-group" key={g.group}>
                  <b>{ g.group }</b>
                  { drawActions(g.actions) }
                </div>
              ))}
            </div>
            <div className="buttons">
              <FormControlLabel
                control={<Checkbox color="primary" />}
                checked={searchFilter}
                value={searchFilter}
                label="Aplicar a todos los usuarios que coindican con el filtro"
                onChange={e => this.onChangeSearchFilter(e.target.checked)}
                disabled={allowSearchFilter}
              />
              <Button type="button" className={isActive !== true ? 'button-inactive' : ''} onClick={() => this.onIntegrateServicesApi(groups)} disabled={!isActive}>Guardar Servicios</Button>
            </div>
          </Wrapper>
        </DialogContent>
      </StyledDialog>
    );
  }
}

ServiceDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeThread: PropTypes.func.isRequired,
  integrateServicesApi: PropTypes.func.isRequired,
  allowSearchFilter: PropTypes.bool.isRequired,
};

ServiceDialog.defaultProps = {
};

export default ServiceDialog;
