import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ReactTable from 'ui/ReactTable';
import 'react-table/react-table.css';

import theme from 'ui/theme';

import {
  requestModulePrices,
} from '$redux/modules/actions';

import Failed from '$routes/Failed';

import {
  TextField,
  FormControl,
} from '@material-ui/core';

import { Wrapper } from './styles';

class Grid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10,
    };
  }

  componentDidUpdate(prevProps) {
    const { filters } = this.props;
    const { pageSize } = this.state;

    if (filters !== prevProps.filters) {
      const { dispatch } = this.props;
      const bindingObject = {
        pageSize,
        page: 0,
        sorted: [],
        filters,
      };
      dispatch(requestModulePrices(bindingObject));
    }
  }

  fetchData = (state) => {
    let { filters } = this.props;
    const { dispatch, filtersApplied, applyPagination } = this.props;
    filters = {
      ...filtersApplied,
      ...filters,
    };
    const { pageSize, page, sorted } = state;
    const bindingObject = {
      pageSize,
      page,
      sorted,
      filters,
    };
    dispatch(requestModulePrices(bindingObject));
    applyPagination(bindingObject);
    this.setState({ pageSize });
  }

  cambiarPrecio = (row, precio) => {
    const { modificarPrecioModulos, data } = this.props;

    modificarPrecioModulos({
      idModule: row.original.idModule,
      precio: parseFloat(precio).toFixed(2),
      precioBase: row.original.precioBase,
      originalPrecio: row.original.precio,
      originalPrecioBase: row.original.precioBase,
      codigo: row.original.codigo,
      tipoPrecio: 'precio',
    });

    const { index } = row;

    data[index].precio = precio;
    this.setState({
      // eslint-disable-next-line react/no-unused-state
      data,
    });

    return true;
  }

  cambiarPrecioBase = (row, precioBase) => {
    const { modificarPrecioModulos, data } = this.props;

    modificarPrecioModulos({
      idModule: row.original.idModule,
      precio: row.original.precio,
      precioBase: parseFloat(precioBase).toFixed(2),
      originalPrecio: row.original.precio,
      originalPrecioBase: row.original.precioBase,
      codigo: row.original.codigo,
      tipoPrecio: 'precioBase',
    });

    const { index } = row;

    data[index].precioBase = precioBase;
    this.setState({
      // eslint-disable-next-line react/no-unused-state
      data,
    });

    return true;
  }

  render() {
    const {
      loading,
      pages,
      data,
    } = this.props;
    const { pageSize } = this.state;

    if (!data) return <Failed />;

    return (
      <Wrapper theme={theme}>
        <ReactTable
          topBorder
          columns={[
            {
              Header: 'idCiclo',
              accessor: 'idCourse',
            },
            {
              Header: 'Curso',
              accessor: 'nombreCurso',
            },
            {
              Header: 'Modalidad',
              accessor: 'modalidad',
            },
            {
              Header: 'Codigo',
              accessor: 'codigo',
            },
            {
              Header: 'Nombre Módulo / Producto',
              accessor: 'nombre',
            },
            {
              Header: 'Visible',
              accessor: 'visible',
            },
            {
              Header: 'P. sin descuento',
              Cell: row => (
                <FormControl key={`fc${row.original.idModule}`} className="formControl" style={{ width: '100%' }}>
                  <TextField
                    fullWidth
                    key={`mod${row.original.idModule}`}
                    value={
                      row.original.precioBase || '0.00'
                    }
                    onChange={e => this.cambiarPrecioBase(row, e.target.value.replace(/[^0-9.]/g, ''))}
                  />
                </FormControl>
              ),
            },
            {
              Header: 'P. fijo',
              Cell: row => (
                <FormControl className="formControl" style={{ width: '100%' }}>
                  <TextField
                    fullWidth
                    value={
                      row.original.precio || '0.00'
                    }
                    onChange={e => this.cambiarPrecio(row, e.target.value.replace(/[^0-9.]/g, ''))}
                  />
                </FormControl>
              ),
            },
          ]}
          manual
          defaultPageSize={pageSize}
          data={data}
          pages={pages} // Display the total number of pages
          loading={loading} // Display the loading overlay when we need it
          onFetchData={this.fetchData} // Request new data when things change
        />
      </Wrapper>
    );
  }
}

Grid.propTypes = {
  dispatch: PropTypes.func.isRequired,
  filters: PropTypes.object,
  data: PropTypes.array,
  pages: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  filtersApplied: PropTypes.object,
  modificarPrecioModulos: PropTypes.func.isRequired,
  applyPagination: PropTypes.func.isRequired,
};

Grid.defaultProps = {
  filters: {},
  data: [],
  pages: 0,
  filtersApplied: {},
};

export default connect(
  state => ({
    data: state.modules.modules,
    pages: state.modules.pages,
    loading: state.modules.loading,
    filtersApplied: state.modules.filters,
  }),
)(Grid);
