import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  /* paddings for absolute items */
  padding: 0 40px;
  padding-top: 80px;
  padding-bottom: 35px;
`;
