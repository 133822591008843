import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { login } from '$redux/login/actions';

import LoginScreen from './LoginScreen';

class Login extends React.Component {
  state = {
    fields: {
      user: '',
      password: '',
    },
  }

  onSubmit = (e) => {
    const { dispatch } = this.props;

    e.preventDefault(); // disable submit's default reloading
    const { fields: { user, password } } = this.state;

    // do the actual login
    dispatch(login(user, password));
  }

  updateField = field => (e) => {
    const { value } = e.target;

    return this.setState(state => (
      { fields: { ...state.fields, [field]: value } }
    ));
  };

  render() {
    const { fields: { user, password } } = this.state;
    const { loginState: { loadingLogin, loadingPermissions } } = this.props;
    const loading = loadingLogin || loadingPermissions;

    return (
      <LoginScreen
        loading={loading}
        user={user}
        password={password}
        updateUser={this.updateField('user')}
        updatePassword={this.updateField('password')}
        onSubmit={this.onSubmit}
      />
    );
  }
}

Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loginState: PropTypes.object.isRequired,
};

export default connect(
  state => ({
    loginState: state.login,
  }),
)(Login);
