import {
  LIST,
  LIST_SUCCEEDED,
  LIST_FAILED,
  GET,
  GET_SUCCEEDED,
  GET_FAILED,
  CREATE,
  CREATE_SUCCEEDED,
  CREATE_FAILED,
  EDIT,
  EDIT_SUCCEEDED,
  EDIT_FAILED,
  ADD_UNIT,
  SET_REQUIREMENTS,
  SET_UNIT_REQUIREMENTS,
  DELETE_UNIT,
  CLEAN_FILTERS_REQUEST,
  FILTERS_REQUEST,
  FILTERS_REQUEST_FAILED,
  FILTERS_REQUEST_SUCCEEDED,
  REQUEST_MODULE_PRICES,
  REQUEST_MODULE_PRICES_SUCCEEDED,
  REQUEST_MODULE_PRICES_FAILED,
  SET_MODIFICADO,
  REMOVE_MODIFICADOS,
  REQUEST_MODULE_PRICES_CONFIRM,
  REQUEST_MODULE_PRICES_CONFIRM_SUCCEEDED,
  REQUEST_MODULE_PRICES_CONFIRM_FAILED,
} from './action-types';

export const list = () => ({ type: LIST });
export const listSucceeded = data => ({ type: LIST_SUCCEEDED, data });
export const listFailed = () => ({ type: LIST_FAILED });
export const get = idModule => ({ type: GET, idModule });
export const getSucceeded = (data, idModule) => ({ type: GET_SUCCEEDED, data, idModule });
export const getFailed = () => ({ type: GET_FAILED });
export const create = moduleObject => ({ type: CREATE, moduleObject });
export const createSucceeded = data => ({ type: CREATE_SUCCEEDED, data });
export const createFailed = () => ({ type: CREATE_FAILED });
export const edit = (idModule, moduleEdit) => ({ type: EDIT, moduleEdit, idModule });
export const editSucceeded = data => ({ type: EDIT_SUCCEEDED, data });
export const editFailed = () => ({ type: EDIT_FAILED });
export const addUnit = unitObject => ({ type: ADD_UNIT, unitObject });
export const setRequirements = newList => ({ type: SET_REQUIREMENTS, newList });
export const setUnitRequirements = newList => ({ type: SET_UNIT_REQUIREMENTS, newList });
export const deleteUnit = (idUnit, idModule) => ({ type: DELETE_UNIT, idUnit, idModule });
export const filtersRequest = () => ({ type: FILTERS_REQUEST });
export const filtersRequestSucceeded = filtersData => ({
  type: FILTERS_REQUEST_SUCCEEDED,
  filtersData,
});
export const filtersRequestFailed = () => ({ type: FILTERS_REQUEST_FAILED });
export const cleanFiltersRequest = () => ({ type: CLEAN_FILTERS_REQUEST });

export const requestModulePrices = bindingObject => ({
  type: REQUEST_MODULE_PRICES,
  bindingObject,
});
export const requestModulePricesSucceeded = ({
  modules: {
    data,
    pages,
  },
}, bindingObject) => ({
  type: REQUEST_MODULE_PRICES_SUCCEEDED,
  data,
  pages,
  bindingObject,
});
export const requestModulePricesFailed = () => ({ type: REQUEST_MODULE_PRICES_FAILED });
export const setModificado = ({
  idModule,
  precio,
  precioBase,
  originalPrecio,
  originalPrecioBase,
  tipoPrecio,
  codigo,
}) => ({
  type: SET_MODIFICADO,
  idModule,
  precio,
  precioBase,
  originalPrecio,
  originalPrecioBase,
  tipoPrecio,
  codigo,
});
export const removeModificados = () => ({ type: REMOVE_MODIFICADOS });

export const requestModulePricesConfirm = prices => ({
  type: REQUEST_MODULE_PRICES_CONFIRM,
  prices,
});
export const requestModulePricesConfirmSucceeded = () => ({
  type: REQUEST_MODULE_PRICES_CONFIRM_SUCCEEDED,
});
export const requestModulePricesConfirmFailed = () => ({
  type: REQUEST_MODULE_PRICES_CONFIRM_FAILED,
});
