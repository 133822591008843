import {
  select, all, call, put, takeEvery,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import api from 'linkia-api';

import {
  LIST,
  BEFORE_CREATE,
  GET,
  UPDATE,
  REMOVE,
  CREATE,
} from './action-types';
import {
  list,
  requestFailed,
  listSucceeded,
  beforeCreateSucceeded,
  getSucceeded,
  updateSucceeded,
  get,
  removeSucceeded,
  createSucceeded,
} from './actions';

import { tokenSelector } from '../login/selectors';

function* listCharges() {
  const token = yield select(tokenSelector);
  try {
    const data = yield call(api.charge.listAll, { token });
    yield put(listSucceeded(data));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* beforeCreateCharge() {
  const token = yield select(tokenSelector);
  try {
    const [trainingPlans, academies] = yield all([
      call(api.trainingPlan.listOptions, { token }),
      call(api.academy.listOptions, { token }),
    ]);
    yield put(beforeCreateSucceeded({ trainingPlans, academies }));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* getCharge(action) {
  const token = yield select(tokenSelector);
  const { id } = action;
  try {
    const [data, trainingPlans, academies] = yield all([
      call(api.charge.get, { token, id }),
      call(api.trainingPlan.listOptions, { token }),
      call(api.academy.listOptions, { token }),
    ]);
    yield put(getSucceeded(data, { trainingPlans, academies }));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* updateCharge(action) {
  const token = yield select(tokenSelector);
  const { id, data } = action;
  try {
    const success = yield call(api.charge.update, { token, id, data });
    yield put(updateSucceeded(success));
    yield put(get(id));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* removeCharge(action) {
  const token = yield select(tokenSelector);
  const { id } = action;
  try {
    const success = yield call(api.charge.delete, { token, id });
    yield put(removeSucceeded(success));

    // request all charges again
    yield put(list());
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* createCharge(action) {
  const token = yield select(tokenSelector);
  const { data } = action;
  try {
    const success = yield call(api.charge.create, { token, data });
    yield put(createSucceeded(success));
    yield put(push(`/cobros/editar/${success.idSplitPayment}`));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

export default [
  takeEvery(LIST, listCharges),
  takeEvery(BEFORE_CREATE, beforeCreateCharge),
  takeEvery(GET, getCharge),
  takeEvery(UPDATE, updateCharge),
  takeEvery(REMOVE, removeCharge),
  takeEvery(CREATE, createCharge),
];
