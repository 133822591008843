import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import ReactTable from 'ui/ReactTable';
import 'react-table/react-table.css';

import theme from 'ui/theme';
import {
  Button,
} from '@material-ui/core';

import {
  requestGetEnrollmentsWithServices,
} from '$redux/enrollmentsWithServices/actions';

import Failed from '$routes/Failed';

import { Wrapper } from './styles';

import ServiceIcon from '$components/Service/service-icon';
import ServiceResult from '$components/Service/service-result';

import {
  OUTLOOK_ICON,
  OUTLOOK_CHECK_ICON,
  OUTLOOK_GROUP_ICON,
  ADOBE_ICON,
  MOODLE_ICON,
  MOODLE_101_ICON,
  MOODLE_CHECK_ICON,
  MOODLE_COHORT_ICON,
  MOODLE_ENROLLMENT_ICON,
  MOODLE_EXTRA_ICON,
  LDAP_ICON,
} from '../../constants';

class Grid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10,
    };
  }

  componentDidUpdate(prevProps) {
    const { filters } = this.props;
    const { pageSize } = this.state;

    // Typical usage (don't forget to compare props):
    if (filters !== prevProps.filters) {
      const { dispatch } = this.props;
      const bindingObject = {
        pageSize,
        page: 0,
        sorted: [],
        filters,
      };
      dispatch(requestGetEnrollmentsWithServices(bindingObject, 'enrollments'));
    }
  }

  fetchData = (state) => {
    let { filters } = this.props;
    const { dispatch, filtersApplied } = this.props;
    filters = {
      ...filtersApplied,
      ...filters,
    };
    const { pageSize, page, sorted } = state;
    const bindingObject = {
      pageSize,
      page,
      sorted,
      filters,
    };
    this.setState({ pageSize });
    dispatch(requestGetEnrollmentsWithServices(bindingObject, 'enrollments'));
  }

  render() {
    const {
      loading, pages, data,
    } = this.props;
    const {
      pageSize,
    } = this.state;

    if (!data) return <Failed />;

    return (
      <Wrapper theme={theme}>
        <ReactTable
          topBorder
          columns={[
            {
              Header: 'Pedido',
              accessor: 'idEnrollment',
              width: 80,
            },
            {
              Header: 'Nombre',
              accessor: 'studentName',
            },
            {
              Header: 'Formación',
              accessor: 'courseCode',
            },
            {
              Header: 'Estado',
              accessor: 'state',
            },
            {
              Header: 'Tipo',
              accessor: 'reenroll',
              Cell: (row) => {
                const {
                  original: { reenroll, idEnrollmentParent },
                } = row;
                return (reenroll === 1 ? 'Rematrícula' : 'Nueva') + (!idEnrollmentParent ? ' ' : '(Generada)');
              },
              maxWidth: 80,
            },
            {
              Header: ServiceIcon(OUTLOOK_ICON),
              accessor: 'outlook',
              Cell: ({ value }) => (
                ServiceResult(value)
              ),
              maxWidth: 56,
            },
            {
              Header: ServiceIcon(OUTLOOK_CHECK_ICON),
              accessor: 'outlookCheck',
              Cell: ({ value }) => (
                ServiceResult(value)
              ),
              maxWidth: 56,
            },
            {
              Header: ServiceIcon(OUTLOOK_GROUP_ICON),
              accessor: 'outlookGroup',
              Cell: ({ value }) => (
                ServiceResult(value)
              ),
              width: 56,
            },
            {
              Header: ServiceIcon(LDAP_ICON),
              accessor: 'ldap',
              Cell: ({ value }) => (
                ServiceResult(value)
              ),
              width: 56,
            },
            {
              Header: ServiceIcon(ADOBE_ICON),
              accessor: 'adobe',
              Cell: ({ value }) => (
                ServiceResult(value)
              ),
              width: 56,
            },
            {
              Header: ServiceIcon(MOODLE_ICON),
              accessor: 'alexia',
              Cell: ({ value }) => (
                ServiceResult(value)
              ),
              width: 56,
            },
            {
              Header: ServiceIcon(MOODLE_CHECK_ICON),
              accessor: 'alexiaCheck',
              Cell: ({ value }) => (
                ServiceResult(value)
              ),
              width: 56,
            },
            {
              Header: ServiceIcon(MOODLE_EXTRA_ICON),
              accessor: 'alexiaExtra',
              Cell: ({ value }) => (
                ServiceResult(value)
              ),
              width: 56,
            },
            {
              Header: ServiceIcon(MOODLE_COHORT_ICON),
              accessor: 'alexiaCohort',
              Cell: ({ value }) => (
                ServiceResult(value)
              ),
              width: 56,
            },
            {
              Header: ServiceIcon(MOODLE_ENROLLMENT_ICON),
              accessor: 'alexiaEnrollment',
              Cell: ({ value }) => (
                ServiceResult(value)
              ),
              width: 56,
            },
            {
              Header: ServiceIcon(MOODLE_101_ICON),
              accessor: 'alexia101',
              Cell: ({ value }) => (
                ServiceResult(value)
              ),
              width: 56,
            },
            {
              Header: '',
              fixed: 'right',
              Cell: rowClicked => (
                <Link to={`matriculas/editar/${rowClicked.original.idEnrollment}`}><Button>Ver</Button></Link>
              ),
              resizable: false,
              sortable: false,
              width: 50,
            },
          ]}
          manual
          defaultPageSize={pageSize}
          data={data}
          pages={pages} // Display the total number of pages
          loading={loading} // Display the loading overlay when we need it
          onFetchData={this.fetchData} // Request new data when things change
        />
      </Wrapper>
    );
  }
}

Grid.propTypes = {
  dispatch: PropTypes.func.isRequired,
  filters: PropTypes.object,
  data: PropTypes.array,
  pages: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  filtersApplied: PropTypes.object,
};

Grid.defaultProps = {
  filters: {},
  data: [],
  pages: 0,
  filtersApplied: {},
};

export default connect(
  state => ({
    data: state.enrollmentsWithServices.enrollments,
    pages: state.enrollmentsWithServices.pages,
    loading: state.enrollmentsWithServices.loading,
    filtersApplied: state.enrollmentsWithServices.filters,
  }),
)(Grid);
