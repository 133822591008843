import {
  REQUEST_LEAD_EXPORT_CREATE_LEAD,
  REQUEST_LEAD_EXPORT_CREATE_LEAD_SUCCEEDED,
  REQUEST_LEAD_EXPORT_CREATE_LEAD_FAILED,

} from './action-types';

export const reqLeadExportCreateLead = bindingObject => ({
  type: REQUEST_LEAD_EXPORT_CREATE_LEAD,
  bindingObject,
});
export const reqLeadExportCreateSuccess = success => ({
  type: REQUEST_LEAD_EXPORT_CREATE_LEAD_SUCCEEDED,
  success,
});
export const reqLeadExportCreateFailed = success => ({
  type: REQUEST_LEAD_EXPORT_CREATE_LEAD_FAILED,
  success,
});
