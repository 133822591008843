import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

class UnitItem extends React.Component {
  changeUnitCost = (event) => {
    const {
      idModule,
      unit,
      setUnitCost,
    } = this.props;

    const price = event.target.value;
    const cost = (price * 1).toFixed(2);

    setUnitCost(idModule, unit.idUnit, cost);
  }

  render() {
    let costUnit;
    let amountUnit;

    const {
      unit, idModule, isMaster,
      setUnit, idEnrollmentTransaction,
    } = this.props;

    // const value = isMaster ? unit.cost : unit.amount;
    if (unit.cost) {
      costUnit = (
        <TextField
          fullWidth
          type="number"
          inputProps={{
            style: {
              textAlign: 'right',
              paddingRight: '12px',
            },
          }}
          value={(unit.cost * 1).toFixed(2)}
          onChange={e => this.changeUnitCost(e)}
        />
      );
      if (!isMaster) {
        if (idEnrollmentTransaction > 0) {
          amountUnit = (
            <div className="column is-one-fifths amount">
              <TextField
                fullWidth
                inputProps={{
                  style: {
                    textAlign: 'right',
                    paddingRight: '12px',
                  },
                }}
                value={(unit.amount * 1).toFixed(2)}
                onChange={e => setUnit(idModule, unit.idUnit, e.target.value)}
              />
            </div>
          );
        } else {
          amountUnit = (
            <div className="column is-one-fifths amount">
              <TextField
                fullWidth
                type="number"
                inputProps={{
                  style: {
                    textAlign: 'right',
                    paddingRight: '12px',
                  },
                }}
                onChange={e => setUnit(idModule, unit.idUnit, e.target.value)}
                value={(unit.amount * 1).toFixed(2)}
              />
            </div>
          );
        }
      }
    }

    const unitItem = (
      <div className="columns is-vcentered units">
        <div className="column is-one-fifths">{unit.code}</div>
        <div className="column is-two-fifths">{unit.name}</div>
        <div className="column is-one-fifths is-offset-one-fifth amount">
          {costUnit}
        </div>
        {amountUnit}
      </div>
    );
    return unitItem;
  }
}

UnitItem.propTypes = {
  unit: PropTypes.object.isRequired,
  isMaster: PropTypes.bool.isRequired,
  setUnit: PropTypes.func.isRequired,
  setUnitCost: PropTypes.func.isRequired,
  idModule: PropTypes.number.isRequired,
  idEnrollmentTransaction: PropTypes.number,
};
UnitItem.defaultProps = {
  idEnrollmentTransaction: 0,
};
export default UnitItem;
