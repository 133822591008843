import {
  ENROLLMENT_SPLIT_PAYMENT_CONFIRM_EXPORTAR_REQUEST,
  ENROLLMENT_SPLIT_PAYMENT_CONFIRM_EXPORTAR_REQUEST_SUCCEEDED,
  ENROLLMENT_SPLIT_PAYMENT_CONFIRM_EXPORTAR_REQUEST_FAILED,
} from './action-types';

export const requesEnrollmentSplitPaymentConfirmExportar = () => ({
  type: ENROLLMENT_SPLIT_PAYMENT_CONFIRM_EXPORTAR_REQUEST,
});
export const requesEnrollmentSplitPaymentConfirmExportarSucceeded = data => ({
  type: ENROLLMENT_SPLIT_PAYMENT_CONFIRM_EXPORTAR_REQUEST_SUCCEEDED,
  data,
});
export const requesEnrollmentSplitPaymentConfirmExportarFailed = () => ({
  type: ENROLLMENT_SPLIT_PAYMENT_CONFIRM_EXPORTAR_REQUEST_FAILED,
});
