import ServiceIcon from './service-icon';
import {
  SERVICE_CHECK_ICON,
  SERVICE_FAIL_ICON,
  SERVICE_EMPTY_ICON,
} from '../../constants';

const ServiceResult = (value) => {
  if (value === 2) {
    return ServiceIcon(SERVICE_CHECK_ICON);
  }
  if (value === 1) {
    return ServiceIcon(SERVICE_FAIL_ICON);
  }
  return ServiceIcon(SERVICE_EMPTY_ICON);
};

export default ServiceResult;
