import {
  REQUEST_UTM_FILTERS,
  REQUEST_UTM_FILTERS_SUCCEEDED,
  REQUEST_UTM_FILTERS_FAILED,
  REQUEST_UTM_EXPORT,
  REQUEST_UTM_EXPORT_SUCCEEDED,
  REQUEST_UTM_EXPORT_FAILED,
  REQUEST_UTM_EXPORT_WITH_ENROLL,
  REQUEST_UTM_EXPORT_WITH_ENROLL_SUCCEEDED,
  REQUEST_UTM_EXPORT_WITH_ENROLL_FAILED,
} from './action-types';

export const reqUtmFilters = bindingObject => ({
  type: REQUEST_UTM_FILTERS, bindingObject,
});

export const reqUtmFiltersSucceeded = ({
  utms: { data, pages },
}, bindingObject) => ({
  type: REQUEST_UTM_FILTERS_SUCCEEDED,
  data,
  pages,
  bindingObject,
});
export const reqUtmFiltersFailed = () => ({
  type: REQUEST_UTM_FILTERS_FAILED,
});
export const requestUtmExport = bindingObject => ({
  type: REQUEST_UTM_EXPORT, bindingObject,
});
export const requestUtmExportSucceeded = data => (
  { type: REQUEST_UTM_EXPORT_SUCCEEDED, data });

export const requestUtmExportFailed = () => ({ type: REQUEST_UTM_EXPORT_FAILED });

export const requestUtmExportWithEnroll = bindingObject => ({
  type: REQUEST_UTM_EXPORT_WITH_ENROLL, bindingObject,
});

export const requestUtmExportWithEnrollSucceeded = data => (
  { type: REQUEST_UTM_EXPORT_WITH_ENROLL_SUCCEEDED, data });

export const requestUtmExportWithEnrollFailed = () => (
  { type: REQUEST_UTM_EXPORT_WITH_ENROLL_FAILED }
);
