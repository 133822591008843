import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  list as listSemesters,
  get as getSemester,
  reset as resetSemester,
  update as updateSemester,
  set as setSemester,
  create as createSemester,
} from '$redux/semesters/actions';

import { Button } from '@material-ui/core';
import Title from 'ui/Title';
import Loading from '$routes/Loading';
import Failed from '$routes/Failed';

import Grid from './Grid';
import Editor from './Editor';

class Calendar extends React.Component {
  state = {
    editorOpen: false,
    editorAction: 'new',
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(listSemesters());
  }

  onCreate = () => {
    const { dispatch, single } = this.props;
    dispatch(createSemester(single));
  }

  onUpdate = () => {
    const { dispatch, single } = this.props;
    dispatch(updateSemester(single.idSemester, single));
  }

  set = (key, value) => {
    const { dispatch } = this.props;
    dispatch(setSemester(key, value));
  }

  openEditor = (id) => {
    const { dispatch } = this.props;

    if (id) {
      dispatch(getSemester(id));
      this.setState({ editorOpen: true, editorAction: 'edit' });
    } else {
      dispatch(resetSemester());
      this.setState({ editorOpen: true, editorAction: 'new' });
    }
  }

  render() {
    const { data, single, loading } = this.props;

    if (loading) return <Loading />;
    if (!data) return <Failed />;

    const { editorOpen, editorAction } = this.state;

    return (
      <div>
        <Title>
          Calendario
          <Button onClick={() => this.openEditor()} size="small">Crear nuevo semestre</Button>
        </Title>
        {
          editorOpen ? (
            <Editor
              action={editorAction}
              data={single}
              set={this.set}
              onSend={editorAction === 'new' ? this.onCreate : this.onUpdate}
              onClose={() => this.setState({ editorOpen: false })}
            />
          ) : null
        }
        <Grid
          data={data}
          loading={loading}
          onEdit={this.openEditor}
        />
      </div>
    );
  }
}

Calendar.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.array,
  single: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

Calendar.defaultProps = {
  data: null,
};

export default connect(
  state => ({
    data: state.semesters.list,
    single: state.semesters.single,
    loading: state.semesters.loading,
  }),
)(Calendar);
