import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactTable from 'ui/ReactTable';
import 'react-table/react-table.css';
import moment from 'moment';
import { Button, Checkbox } from '@material-ui/core';
import SubTitle from 'ui/SubTitle';
import Loading from '$routes/Loading';
import { Wrapper } from './Modules.styles';
import { requestDoc, requestDownloadDoc, updateDocument } from '$redux/enrollments/actions';

const dateFormat = 'DD-MM-YYYY';

class Documents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }


  onClickDocument = async ({ idStudentDocument }) => {
    const { dispatch } = this.props;
    await dispatch(requestDoc(idStudentDocument));
  };

  onClickDownloadDocument = async ({ idStudentDocument }) => {
    const { dispatch } = this.props;
    await dispatch(requestDownloadDoc(idStudentDocument));
  };

  onCheckDocument = async (idStudentDocument, checked) => {
    const { dispatch, set, enrollment: { idEnrollment, idStudent } } = this.props;
    set('uploadedFiles', false);
    await dispatch(updateDocument(idStudentDocument, idStudent, idEnrollment, checked));
  };

  render() {
    const { data, loadingDownload } = this.props;
    if (loadingDownload) return <Loading />;
    return (
      <Wrapper>
        <div className="documents">
          <SubTitle>Documentación</SubTitle>
          <ReactTable
            columns={[
              {
                Header: 'Fecha subida',
                accessor: 'updatedAt',
                Cell: ({ value }) => (value ? moment(value).format(dateFormat) : '-'),
                maxWidth: 100,
                style: { textAlign: 'center' },
              },
              {
                Header: 'Documento',
                accessor: 'DocType.name',
              },
              {
                Header: 'Validado',
                Cell: rowClicked => (
                  <Checkbox
                    className="checkbox"
                    checked={rowClicked.original.validated}
                    onChange={(e) => {
                      this.onCheckDocument(rowClicked.original.idStudentDocument, e.target.checked);
                    }}
                  >Validar
                  </Checkbox>
                ),
                style: { textAlign: 'center' },
                resizable: false,
                sortable: false,
                maxWidth: 80,
              },
              {
                Header: '',
                fixed: 'right',
                Cell: rowClicked => (
                  <Button onClick={() => {
                    this.onClickDocument({
                      idStudentDocument: rowClicked.original.idStudentDocument,
                    });
                  }}
                  >Ver
                  </Button>
                ),
                style: { textAlign: 'center' },
                resizable: false,
                sortable: false,
                maxWidth: 50,
              },
              {
                Header: '',
                fixed: 'right',
                Cell: rowClicked => (
                  <Button onClick={() => {
                    this.onClickDownloadDocument({
                      idStudentDocument: rowClicked.original.idStudentDocument,
                    });
                  }}
                  >Descargar
                  </Button>
                ),
                style: { textAlign: 'center' },
                resizable: false,
                sortable: false,
                maxWidth: 80,
              },

            ]}
            data={data}
            showPagination={false}
            defaultPageSize={data.length}
          />
        </div>
      </Wrapper>
    );
  }
}

Documents.propTypes = {
  data: PropTypes.array,
  dispatch: PropTypes.func.isRequired,
  loadingDownload: PropTypes.bool.isRequired,
};

Documents.defaultProps = {
  data: [],
};

export default connect()(Documents);
