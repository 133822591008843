import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  get as getThread,
} from '$redux/messages/actions';

import { Wrapper } from './ThreadBox.styles';
import ThreadDialog from './ThreadDialog';

class ThreadBox extends React.Component {
  state = {
    open: false,
  }

  componentWillReceiveProps(nextProps) {
    const { loading: wasLoading } = this.props;
    const { loading: willBeLoading } = nextProps;

    if (wasLoading && !willBeLoading) {
      setTimeout(() => {
        this.scrollDownMessages();
      }, 1); // slow down the scrolling action in order to wait for the messages to render
    }
  }

  openThread = () => {
    const {
      dispatch,
      thread: { idUser },
    } = this.props;

    dispatch(getThread(idUser));
    this.setState({ open: true });
  }

  scrollDownMessages = () => {
    document.querySelectorAll('.messages-dialog > div > div')
      .forEach((n) => {
        // eslint-disable-next-line
        n.scrollTop = n.scrollHeight;
      });
  }

  closeThread = () => {
    this.setState({ open: false });
  }

  render() {
    const {
      thread: {
        idUser,
        sent: rawSent,
        sender,
        read,
        message,
      },

      // redux
      data,
      loading,

      // funcs
      onClickUnreadMessage,
      onSendMessage,
    } = this.props;
    const { open } = this.state;

    const sent = moment(rawSent).format('DD-MM-YYYY / hh:mm');

    return (
      <Wrapper className={read ? '' : 'is-new'}>
        <div className="columns">
          <div className="column is-one-fifth">{sent}</div>
          <div className="column is-one-fifth">{sender.name}</div>
          <div className="column is-three-fifths">
            <div className="message-line">
              <div className="the-text">
                {read ? '' : <span className="new-tag">(nuevo)</span>}
                {message}
              </div>
              <button type="button" onClick={this.openThread}>Ver</button>
            </div>
          </div>
        </div>
        <ThreadDialog
          open={open}
          loading={loading}
          data={data}
          idUser={idUser}
          closeThread={this.closeThread}
          onClickUnreadMessage={onClickUnreadMessage}
          onSendMessage={onSendMessage}
        />
      </Wrapper>
    );
  }
}

ThreadBox.propTypes = {
  thread: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  onClickUnreadMessage: PropTypes.func.isRequired,
  onSendMessage: PropTypes.func.isRequired,
};

ThreadBox.defaultProps = {
  data: null,
};

export default connect(
  state => ({
    data: state.messages.single,
    loading: state.messages.loadingSingle,
  }),
)(ThreadBox);
