import defaultConfig from './default';
import development from './development';
import staging from './staging';
import production from './production';

const { NODE_ENV } = process.env;
const hostname = (window && window.location && window.location.hostname) || '';
export const env = hostname.endsWith('interficie.com') ? 'staging' : NODE_ENV;

const envConfings = {
  development,
  staging,
  production,
};

const currentEnvConfing = envConfings[
  env in envConfings ? env : 'development'
];

// the ultimate config
export default {
  defaultConfig,
  ...currentEnvConfing,
};
