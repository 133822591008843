import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import theme from 'ui/theme';
import { TextField, Button } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import themeFilters from 'ui/muiFiltersTheme';
import { filtersRequest } from '$redux/candidates/actions';
import Failed from '$routes/Failed';
import Loading from '$routes/Loading';
import { Wrapper } from './Filters.styles';

class Filters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idState: 0,
      idSelectedAcademy: 0,
      idSelectedCourse: 0,
      name: '',
      email: '',
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(filtersRequest());
  }

  onApplyFilters = (e) => {
    e.preventDefault();
    const { applyFilters } = this.props;

    const {
      idState,
      idSelectedAcademy,
      idSelectedCourse,
      name,
      email,
    } = this.state;
    applyFilters({
      idState,
      idSelectedAcademy,
      idSelectedCourse,
      name,
      email,
    });
  };

  handleChange = name => (event) => {
    event.preventDefault();
    this.setState({ [name]: event.target.value });
  };

  render() {
    const { filtersData, loading } = this.props;
    let academiesList;
    let defaultAcademy = <option value={0}>Centro</option>;

    if (loading) return <Loading />;
    if (!filtersData) return <Failed />;

    const statesList = filtersData.states.map(
      item => <option key={item.idState} value={item.idState}>{item.name}</option>,
    );

    if (filtersData.academies.length === 1) {
      defaultAcademy = (
        <option
          key={filtersData.academies[0].idSelectedAcademy}
          value={filtersData.academies[0].idSelectedAcademy}
        >
          {filtersData.academies[0].name}
        </option>
      );
    } else {
      academiesList = filtersData.academies.map(
        item => (
          <option key={item.idSelectedAcademy} value={item.idSelectedAcademy}>{item.name}</option>
        ),
      );
    }

    const coursesList = filtersData.courses.map(
      item => (
        <option key={item.idSelectedCourse} value={item.idSelectedCourse}>
          {`${item.courseCode} ${item.courseName}`}
        </option>
      ),
    );

    const {
      idState,
      idSelectedAcademy,
      idSelectedCourse,
      name,
      email,
    } = this.state;

    return (
      <Wrapper theme={theme}>
        <MuiThemeProvider theme={themeFilters}>
          <form onSubmit={this.onApplyFilters} className="filters-form">
            <div className="filters">
              <Select
                native
                value={idState}
                onChange={this.handleChange('idState')}
                className="filter-item"
              >
                <option value={0}>Estado</option>
                {statesList}
              </Select>
              <Select
                native
                value={idSelectedAcademy}
                onChange={this.handleChange('idSelectedAcademy')}
                className="filter-item"
              >
                {defaultAcademy}
                {academiesList}
              </Select>
              <Select
                native
                value={idSelectedCourse}
                onChange={this.handleChange('idSelectedCourse')}
                className="filter-item"
              >
                <option value={0}>Ciclo</option>
                {coursesList}
              </Select>
              <TextField
                className="filter-item"
                id="name"
                placeholder="Nombre"
                onChange={this.handleChange('name')}
                value={name}
              />
              <TextField
                className="filter-item"
                id="email"
                placeholder="Email"
                onChange={this.handleChange('email')}
                value={email}
              />
              <Button
                color="primary"
                type="submit"
                className="apply-filters-button"
              >
                APLICAR FILTROS
              </Button>
            </div>
          </form>
        </MuiThemeProvider>
      </Wrapper>
    );
  }
}

Filters.propTypes = {
  applyFilters: PropTypes.func.isRequired,
  filtersData: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

Filters.defaultProps = {
  filtersData: undefined,
};

export default connect(
  state => ({
    filtersData: state.candidates.filtersData,
    loading: state.candidates.loading,
  }),
)(Filters);
