import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Wrapper } from './MessageBox.styles';

const MessageBox = ({ message, onClickUnread }) => {
  const {
    idMessage,
    senderType,
    sender,
    message: body,
    read,
  } = message;
  const sent = moment(message.sent).format('hh:mm · DD-MM-YYYY');

  return (
    <Wrapper className={read ? '' : 'is-new'}>
      <div className="header">
        <div>
          <strong className="sender">
            {sender.name}
          </strong>
          &nbsp;|&nbsp;
          <span className="sender-type">
            {senderType === 'staff' ? 'Linkia' : 'Alumno'}
          </span>
          &nbsp;·&nbsp;
          <span>
            {sent}
          </span>
        </div>
        <button type="button" className="unread" onClick={() => onClickUnread(idMessage)}>
          Marcar
        </button>
      </div>
      <p className="body">{body}</p>
    </Wrapper>
  );
};

MessageBox.propTypes = {
  message: PropTypes.object.isRequired,
  onClickUnread: PropTypes.func.isRequired,
};

export default MessageBox;
