import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReactTable from 'ui/ReactTable';
import 'react-table/react-table.css';
import { Button } from '@material-ui/core';
import { request as requestEnrollments } from '$redux/enrollments/actions';
import Failed from '$routes/Failed';

import { WarningValue } from './Grid.helpers';

class GridPaid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const bindingObject = {
      pageSize: 5,
      page: 0,
      sorted: [{ id: 'paymentDate', desc: true }],
      filters: {},
    };
    dispatch(requestEnrollments(bindingObject, 'paidEnrollments'));
  }

  render() {
    const {
      loading, pages, data,
    } = this.props;
    if (!data) return <Failed />;

    const dataToShow = [];
    data.forEach((enrollment) => {
      if (enrollment.paymentDate) {
        dataToShow.push(enrollment);
      }
    });

    return (
      <ReactTable
        topBorder
        columns={[
          {
            Header: 'Última actualización',
            accessor: 'updatedAt',
            Cell: (row) => {
              const { value, original: { spoiledDeadline } } = row;
              if (spoiledDeadline) return <WarningValue>{value}</WarningValue>;
              return value;
            },
            width: 120,
          },
          {
            Header: 'Pedido',
            accessor: 'idEnrollment',
          },
          {
            Header: 'Centro',
            accessor: 'academyName',
          },
          {
            Header: 'Email',
            accessor: 'email',
          },
          {
            Header: 'Nombre',
            accessor: 'studentName',
          },
          {
            Header: 'Nif',
            accessor: 'idDocumentNumber',
          },
          {
            Header: 'Formación',
            accessor: 'courseName',
          },
          {
            Header: 'Fecha de pago',
            accessor: 'paymentDate',
            Cell: ({ value }) => (value || '-'),
            maxWidth: 80,
          },
          {
            Header: '',
            fixed: 'right',
            Cell: rowClicked => (
              <Link to={`matriculas/editar/${rowClicked.original.idEnrollment}`}><Button>Ver</Button></Link>
            ),
            resizable: false,
            sortable: false,
            width: 50,
          },
        ]}
        manual
        defaultPageSize={5}
        data={dataToShow}
        pages={pages} // Display the total number of pages
        loading={loading} // Display the loading overlay when we need it
        showPagination={false}
      />
    );
  }
}

GridPaid.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.array,
  pages: PropTypes.number,
  loading: PropTypes.bool.isRequired,
};

GridPaid.defaultProps = {
  data: [],
  pages: 0,
};

export default connect(
  state => ({
    data: state.enrollments.paidEnrollments,
    pages: state.enrollments.pages,
    loading: state.enrollments.loading,
  }),
)(GridPaid);
