import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'ui/ReactTable';
import { Button } from '@material-ui/core';

const Grid = ({ data, onEdit }) => (
  <div>
    <ReactTable
      data={data}
      columns={[
        {
          Header: 'ID',
          accessor: 'idUtmFormCourse',
          maxWidth: 30,
        },
        {
          Header: 'Url',
          accessor: 'url',
        },
        {
          Header: 'Ciclo',
          accessor: 'Course.name',
        },
        {
          Header: 'Acciones',
          fixed: 'right',
          columns: [
            {
              Cell: row => (
                <Button onClick={() => onEdit(row.original.idUtmFormCourse)}>Editar</Button>
              ),
              resizable: false,
              sortable: false,
              maxWidth: 70,
            },
          ],
        },
      ]}
    />
  </div>
);

Grid.propTypes = {
  data: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default Grid;
