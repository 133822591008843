import React from 'react';
import { connect } from 'react-redux';

import Title from 'ui/Title';

import Grid from './Grid';

const OrderRecovery = () => (
  <div>
    <Title>
      Pagos con tarjeta pendientes
    </Title>
    <Grid />
  </div>
);

OrderRecovery.propTypes = {};

OrderRecovery.defaultProps = {};

export default connect()(OrderRecovery);
