import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import ReactTable from 'ui/ReactTable';
import { Button } from '@material-ui/core';
import {
  list as listCourses,
  switchState,
} from '$redux/courses/actions';
import Loading from '$routes/Loading';
import Failed from '$routes/Failed';

class Grid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(listCourses());
  }

  switchState = id => () => {
    const { dispatch } = this.props;
    dispatch(switchState(id));
  }

  render() {
    const { data, loading } = this.props;
    if (loading) return <Loading />;
    if (!data) return <Failed />;
    return (
      <div>
        <ReactTable
          data={data}
          columns={[
            {
              Header: 'ID',
              accessor: 'idCourse',
              maxWidth: 50,
            },
            {
              Header: 'Curso',
              accessor: 'name',
            },
            {
              id: 'isActive',
              Header: 'Activo',
              accessor: d => (d.active ? 'Sí' : 'No'),
              maxWidth: 300,
            },
            {
              id: 'idCourseModality',
              Header: 'Modalidad',
              accessor: d => (d.CourseModality ? d.CourseModality.name : ''),
              maxWidth: 300,
            },
            {
              id: 'idCourseCategory',
              Header: 'Categoría',
              accessor: d => (d.CourseCategory ? d.CourseCategory.name : ''),
              maxWidth: 300,
            },
            {
              // separator
              resizable: false,
            },
            {
              Header: 'Acciones',
              fixed: 'right',
              columns: [
                {
                  Cell: row => (
                    <Link to={`/ciclos/editar/${row.original.idCourse}`}>
                      <Button>EDITAR</Button>
                    </Link>
                  ),
                  maxWidth: 100,
                  resizable: false,
                  sortable: false,
                },
              ],
            },
          ]}
        />
      </div>
    );
  }
}

Grid.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.array,
  loading: PropTypes.bool.isRequired,
};

Grid.defaultProps = {
  data: null,
};

export default connect(
  state => ({
    data: state.courses.list,
    loading: state.courses.loading,
  }),
)(Grid);
