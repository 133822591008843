import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loading from '../../../Loading';
import faTimes from '@fortawesome/fontawesome-free-solid/faTimes';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import UnitRow from './UnitRow';
import { Wrapper } from './EditUnit.styles';
import {
  Button,
} from '@material-ui/core';

import {
  get as getUnit,
  edit as editUnit,
} from '$redux/units/actions';

class Unit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: undefined,
      name: undefined,
      onLineHours: undefined,
      inClassHours: undefined,
      onLineHourPrice: undefined,
      inClassHourPrice: undefined,
      price: undefined,
    };
  }

  componentDidMount() {
    const { dispatch, unitToShow } = this.props;
    dispatch(getUnit(unitToShow)); // eslint-disable-line
  }

  edit = (unitEdit) => {
    const { dispatch, data } = this.props;
    dispatch(editUnit(data.idUnit, unitEdit));
  }

  set = (name, value) => {
    const stateReplica = {};
    stateReplica[name] = value;
    this.setState(stateReplica);
  }

  render() {
    const {
      showUnitModal,
      data,
      unitToShow,
      // methodology,
      loadingSingle,
    } = this.props;
    const showHideClassName = showUnitModal ? 'modal display-block' : 'modal display-none';
    if (loadingSingle) return <Loading />;
    /* eslint-disable */
    return (
      <Wrapper>
        <div className={showHideClassName}>
          <section className="modal-main">
            <div className="close-cross" onClick={this.props.hideUnitEdit}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
            <div className="modal-form">
            <UnitRow data={data} edit={this.edit} create={this.create} unitToShow={unitToShow} set={this.set}/>

            <Button
              color="primary"
              className="save-button"
              onClick={() => this.edit({
                  ...this.state,
                })}
            > Guardar Datos
            </Button>
            </div>
          </section>
        </div>
      </Wrapper>
    );
  }
}

Unit.propTypes = {
  dispatch: PropTypes.func.isRequired,
  unitToShow: PropTypes.number.isRequired,
  showUnitModal: PropTypes.bool.isRequired,
  data: PropTypes.object,
  methodology: PropTypes.object,
  loadingSingle: PropTypes.bool.isRequired,
};

Unit.defaultProps = {
  data: undefined,
  methodology: {},
}

export default connect(
  state => ({
    data: state.units.single,
    loadingSingle: state.units.loadingSingle,
  }),
)(Unit);
