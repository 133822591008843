import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import ReactTable from 'ui/ReactTable';
import 'react-table/react-table.css';

import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import {
  requestGetServicesActionFilterFiltered as requestServices,
} from '$redux/services/actions';
import Select from '@material-ui/core/Select';
import { getStatusLabel, StatusEnum } from './status';
import { RedButton } from '../../CourseModality/Grid.styles';

const dateFormat = 'DD-MM-YYYY';

class Grid extends React.Component {
  componentDidUpdate(prevProps) {
    const { filters } = this.props;
    // Typical usage (don't forget to compare props):
    if (filters !== prevProps.filters) {
      const {
        dispatch,
        pageSize,
      } = this.props;
      const bindingObject = {
        pageSize,
        page: 0,
        sorted: [],
        filters,
      };
      dispatch(
        requestServices(bindingObject, 'services'),
      );
    }
  }

  fetchData = (state) => {
    let { filters } = this.props;
    const {
      dispatch,
      filtersApplied,
      idUserService,
    } = this.props;
    filters = {
      ...filtersApplied,
      ...filters,
    };
    filters.idUserService = idUserService;
    const {
      pageSize,
      page,
      sorted,
    } = state;
    const bindingObject = {
      pageSize,
      page,
      sorted,
      filters,
    };
    dispatch(
      requestServices(bindingObject, 'services'),
    );
  };

  handleStatusChange = (idUserServiceAction, status) => {
    const {
      onChangeStatus,
    } = this.props;
    onChangeStatus(idUserServiceAction, status);
  };

  render() {
    const {
      data,
      loading,
      pages,
      pageSize,
      openLogWindow,
      removeItem,
    } = this.props;

    return (
      <ReactTable
        className="gridResult"
        topBorder
        columns={[
          {
            Header: 'ID',
            accessor: 'idUserServiceAction',
            maxWidth: 45,
          },
          {
            Header: 'Action',
            accessor: 'action',
            maxWidth: 120,
          },
          {
            Header: 'Retries',
            accessor: 'retries',
            maxWidth: 120,
          },
          {
            Header: 'Max Retries',
            accessor: 'maxRetries',
            width: 120,
          },
          {
            Header: 'Status',
            accessor: 'status',
            Cell: ({
              row,
            }) => (
              <Select
                native
                onChange={
                  // eslint-disable-next-line max-len
                  event => this.handleStatusChange(row.idUserServiceAction, event.target.value)
                }
                defaultValue={row.status}
              >
                <option>Status</option>
                {Object.entries(StatusEnum)
                  .map(([label, val]) => ({
                    label,
                    val,
                  }))
                  .map(option => (
                    <option key={option.val} value={option.val}>
                      {getStatusLabel(option.val)}
                    </option>
                  ))}
              </Select>

            ),
            width: 90,
          },
          {
            Header: 'Fecha Creacion',
            accessor: 'createdAt',
            Cell: ({ value }) => (value ? moment(value)
              .format(dateFormat) : '-'),
            maxWidth: 120,
          },
          {
            Header: 'Fecha Updated',
            accessor: 'updatedAt',
            Cell: ({ value }) => (value ? moment(value)
              .format(dateFormat) : '-'),
          },
          {
            Header: 'Acciones',
            fixed: 'right',
            columns: [
              {
                Cell: row => (
                  <Button onClick={() => openLogWindow(row.original.idUserServiceAction)}>
                    Ver Log
                  </Button>
                ),
                maxWidth: 100,
                resizable: false,
                sortable: false,
              },
              {
                Cell: row => (
                  <RedButton onClick={() => removeItem(row.original.idUserServiceAction)}>
                    Eliminar
                  </RedButton>
                ),
                resizable: false,
                sortable: false,
                maxWidth: 100,
              },
            ],
          },
        ]}
        manual
        defaultPageSize={pageSize}
        data={data}
        pages={pages}
        loading={loading}
        onFetchData={this.fetchData}
      />
    );
  }
}

Grid.propTypes = {
  data: PropTypes.array,
  filters: PropTypes.object,
  filtersApplied: PropTypes.object,
  pageSize: PropTypes.number,
  pages: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  idUserService: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  openLogWindow: PropTypes.func.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
};

Grid.defaultProps = {
  filters: {},
  data: [],
  pageSize: 20,
  pages: 0,
  filtersApplied: {},
};

export default connect(
  state => ({
    data: state.services.data,
    loading: state.services.loading,
    pages: state.services.pages,
    filtersApplied: state.services.filters,
  }),
)(Grid);
