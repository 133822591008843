import React from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  Button,
  InputLabel,
  FormControlLabel,
  Switch,
  Select,
} from '@material-ui/core';
import { Wrapper } from './AddRoadMapForm.styles';

// eslint-disable-next-line
class AddRoadMap extends React.Component {
  state = {
    name: '',
    isCustomizable: false,
    semester: 0,
  }

  handleChange = (name, value) => {
    const roadMap = {};
    roadMap[name] = value;
    this.setState(roadMap);
  }

  render() {
    const { name, isCustomizable, semester } = this.state;
    const { addRoadMap, idTrainingPlan } = this.props;
    return (
      <Wrapper>
        <TextField
          placeholder="Nombre itinerario"
          className="inputNewRoadMap"
          onChange={event => this.handleChange('name', event.target.value)}
        />
        <div className="customizedNewRoadMap">
          <InputLabel>Personalizado </InputLabel>
          <FormControlLabel
            control={<Switch color="secondary" />}
            checked={isCustomizable}
            onChange={event => this.handleChange('isCustomizable', event.target.checked)}
          />
        </div>
        <div className="customizedNewRoadMap">
          <Select
            native
            className="filter-academy"
            value={semester}
            onChange={event => this.handleChange('semester', event.target.value)}
          >
            <option value={0}>Cualquier semestre</option>
            <option value={1}>De Sep a Enero</option>
            <option value={2}>De Feb a Mayo</option>
          </Select>
        </div>
        <Button
          color="primary"
          onClick={() => addRoadMap({
            name,
            isCustomizable,
            idTrainingPlan,
            semester,
          })}
          className="margin8"
          disabled={name === '' || name === null}
        >Añadir itinerario
        </Button>
      </Wrapper>
    );
  }
}

AddRoadMap.propTypes = {
  addRoadMap: PropTypes.func.isRequired,
  idTrainingPlan: PropTypes.number,
};

AddRoadMap.defaultProps = {
  idTrainingPlan: 0,
};

export default AddRoadMap;
