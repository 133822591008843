import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactTable from 'ui/ReactTable';
import 'react-table/react-table.css';
import theme from 'ui/theme';
import { request as requestBilling } from '$redux/billing/actions';
import Failed from '$routes/Failed';
import { Wrapper } from './styles';

class Grid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps) {
    const { dispatch, filters, academy } = this.props;

    // Typical usage (don't forget to compare props):
    if (filters !== prevProps.filters || academy !== prevProps.academy) {
      const bindingObject = {
        filters,
        academy,
      };
      dispatch(requestBilling(bindingObject));
    }
  }

  fetchData = () => {
    const { dispatch, filters } = this.props;
    const bindingObject = { filters };
    dispatch(requestBilling(bindingObject));
  }

  render() {
    const {
      loading, data, totals, academy,
    } = this.props;
    if (!data) return <Failed />;

    const columnsList = [
      {
        Header: () => <div style={{ textAlign: 'center' }}>CICLOS</div>,
        columns: [
          {
            Header: () => <div style={{ textAlign: 'center' }}>CÓDIGO</div>,
            accessor: 'courseName',
            style: { textAlign: 'center' },
            Footer: (
              <span>
                <strong>TOTAL</strong>
              </span>
            ),
          },
        ],
      },
      {
        Header: () => <div style={{ textAlign: 'center' }}>MATRÍCULAS</div>,
        columns: [
          {
            Header: () => <div style={{ textAlign: 'center' }}>NUEVAS</div>,
            accessor: 'newEnrollments',
            style: { textAlign: 'center' },
            width: 90,
            className: 'darken',
            Footer: (
              <span>
                <strong>{totals.newEnrollments}</strong>
              </span>
            ),
          },
          {
            Header: () => <div style={{ textAlign: 'center' }}>REMATRÍCULAS</div>,
            accessor: 'reEnrollments',
            style: { textAlign: 'center' },
            width: 115,
            className: 'darken',
            Footer: (
              <span>
                <strong>{totals.reEnrollments}</strong>
              </span>
            ),
          },
          {
            Header: () => <div style={{ textAlign: 'center' }}>TOTAL</div>,
            accessor: 'totalEnrollments',
            style: { textAlign: 'center' },
            width: 90,
            className: 'darken',
            Footer: (
              <span>
                <strong>{totals.totalEnrollments}</strong>
              </span>
            ),
          },
        ],
      },
      {
        Header: () => <div style={{ textAlign: 'center' }}>NUEVAS</div>,
        columns: [
          {
            Header: () => <div style={{ textAlign: 'right' }}>COBRADO</div>,
            accessor: 'newsPaid',
            style: { textAlign: 'right' },
            width: 90,
            Footer: (
              <span>
                <strong>{totals.newsPaid}</strong>
              </span>
            ),
          },
          {
            Header: () => <div style={{ textAlign: 'right' }}>PENDIENTE</div>,
            accessor: 'newsPending',
            style: { textAlign: 'right' },
            width: 90,
            Footer: (
              <span>
                <strong>{totals.newsPending}</strong>
              </span>
            ),
          },
          {
            Header: () => <div style={{ textAlign: 'right' }}>FACTURADO</div>,
            accessor: 'newsCharged',
            style: { textAlign: 'right' },
            width: 90,
            Footer: (
              <span>
                <strong>{totals.newsCharged}</strong>
              </span>
            ),
          },
        ],
      },
      {
        Header: () => <div style={{ textAlign: 'center' }}>REMATRÍCULAS</div>,
        columns: [
          {
            Header: () => <div style={{ textAlign: 'right' }}>COBRADO</div>,
            accessor: 'reEnrollPaid',
            style: { textAlign: 'right' },
            width: 90,
            className: 'darken',
            Footer: (
              <span>
                <strong>{totals.reEnrollPaid}</strong>
              </span>
            ),
          },
          {
            Header: () => <div style={{ textAlign: 'right' }}>PENDIENTE</div>,
            accessor: 'reEnrollPending',
            style: { textAlign: 'right' },
            width: 90,
            className: 'darken',
            Footer: (
              <span>
                <strong>{totals.reEnrollPending}</strong>
              </span>
            ),
          },
          {
            Header: () => <div style={{ textAlign: 'right' }}>FACTURADO</div>,
            accessor: 'reEnrollCharged',
            style: { textAlign: 'right' },
            width: 90,
            className: 'darken',
            Footer: (
              <span>
                <strong>{totals.reEnrollCharged}</strong>
              </span>
            ),
          },
        ],
      },
      {
        Header: () => <div style={{ textAlign: 'center' }}>TOTALES</div>,
        columns: [
          {
            Header: () => <div style={{ textAlign: 'right' }}>COBRADO</div>,
            accessor: 'totalPaid',
            style: { textAlign: 'right' },
            width: 90,
            Footer: (
              <span>
                <strong>{totals.totalPaid}</strong>
              </span>
            ),
          },
          {
            Header: () => <div style={{ textAlign: 'right' }}>PENDIENTE</div>,
            accessor: 'totalPending',
            style: { textAlign: 'right' },
            width: 90,
            Footer: (
              <span>
                <strong>{totals.totalPending}</strong>
              </span>
            ),
          },
          {
            Header: () => <div style={{ textAlign: 'right' }}>FACTURADO</div>,
            accessor: 'totalCharged',
            style: { textAlign: 'right' },
            width: 90,
            Footer: (
              <span>
                <strong>{totals.totalCharged}</strong>
              </span>
            ),
          },
        ],
      },
    ];
    if (academy && academy !== '0') {
      const profitColumn = {
        Header: () => <div style={{ textAlign: 'right' }}>PROFIT</div>,
        accessor: 'benefitCenter',
        style: { textAlign: 'right' },
        width: 90,
        Footer: (
          <span>
            <strong>{totals.benefitCenter}</strong>
          </span>
        ),
      };
      columnsList[4].columns.push(profitColumn);
    }
    return (
      <Wrapper theme={theme}>
        <ReactTable
          topBorder
          columns={columnsList}
          manual
          showPagination={false}
          defaultPageSize={data.length}
          data={data}
          loading={loading} // Display the loading overlay when we need it
          onFetchData={this.fetchData} // Request new data when things change
        />
      </Wrapper>
    );
  }
}

Grid.propTypes = {
  dispatch: PropTypes.func.isRequired,
  filters: PropTypes.object,
  data: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  academy: PropTypes.string,
  totals: PropTypes.object,
};

Grid.defaultProps = {
  filters: {},
  data: [],
  totals: {},
  academy: '0',
};

export default connect(
  state => ({
    data: state.billing.data,
    totals: state.billing.totals,
    loading: state.billing.loading,
  }),
)(Grid);
