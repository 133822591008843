import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button } from '@material-ui/core';
import Title from 'ui/Title';

import Grid from './Grid';

class Discounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Title>
          Descuentos
          <Link to="/descuentos/nuevo">
            <Button size="small">Crear nuevo descuento</Button>
          </Link>
        </Title>
        <Grid />
      </div>
    );
  }
}


export default connect()(Discounts);
