import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';

import ReactTable from 'ui/ReactTable';
import { Button } from '@material-ui/core';

import { RedButton } from './Grid.styles';

const dateFormat = 'DD-MM-YYYY';

const Grid = ({ data, removeItem }) => (
  <div>
    <ReactTable
      data={data}
      columns={[
        {
          Header: 'ID',
          accessor: 'idSplitPayment',
          maxWidth: 30,
        },
        {
          Header: 'Nombre',
          accessor: 'name',
          width: 150,
        },
        {
          Header: 'Nombre público',
          accessor: 'displayName',
          width: 150,
        },
        {
          Header: 'Interés',
          accessor: 'interest',
          Cell: ({ value }) => `${parseInt(value, 10)}%`,
          maxWidth: 70,
        },
        {
          Header: 'Coste matrícula',
          accessor: 'enrollmentCost',
          Cell: ({ value }) => `${parseInt(value, 10)}€`,
          maxWidth: 120,
        },
        {
          Header: 'Fecha inicio',
          accessor: 'startDate',
          Cell: ({ value }) => (value ? moment(value).format(dateFormat) : '-'),
          maxWidth: 100,
        },
        {
          Header: 'Fecha fin',
          accessor: 'finishDate',
          Cell: ({ value }) => (value ? moment(value).format(dateFormat) : '-'),
          maxWidth: 100,
        },
        {
          // separator
          resizable: false,
        },
        {
          Header: 'Acciones',
          fixed: 'right',
          columns: [
            {
              Cell: row => (
                <Link to={`/cobros/editar/${row.original.idSplitPayment}`}>
                  <Button>Editar</Button>
                </Link>
              ),
              maxWidth: 70,
              resizable: false,
              sortable: false,
            },
            {
              Cell: row => (
                <RedButton onClick={() => removeItem(row.original.idSplitPayment)}>
                    Borrar
                </RedButton>
              ),
              maxWidth: 100,
              resizable: false,
              sortable: false,
            },
          ],
        },
      ]}
    />
  </div>
);

Grid.propTypes = {
  data: PropTypes.array.isRequired,
  removeItem: PropTypes.func.isRequired,
};

export default Grid;
