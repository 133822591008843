import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactTable from 'ui/ReactTable';
import 'react-table/react-table.css';
import faUnlock from '@fortawesome/fontawesome-free-solid/faUnlockAlt';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import theme, { colors } from 'ui/theme';
import { request as requestCandidates, updateUser, changeLeadPassword } from '$redux/candidates/actions';
import { CSVLink } from 'react-csv';
import swal from 'sweetalert2';
import { Button, Checkbox, withStyles } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import Failed from '$routes/Failed';
import { Wrapper } from './styles';

const StyledCheckbox = withStyles({
  root: {
    height: 'auto',
    width: 'auto',
    transform: 'translateY(-2px)',
    padding: 0,
    // custom color
    color: `${grey[500]} !important`,
  },
})(props => (
  <Checkbox {...props} />
));

const confirmationDialog = async ({ title, message, type = 'info' }) => swal({
  title,
  html: message,
  input: 'text',
  inputAttributes: { autocapitalize: 'off' },
  type,
  showCancelButton: true,
  confirmButtonColor: colors.primary,
  confirmButtonText: 'Enviar',
  cancelButtonColor: colors.disabled,
  cancelButtonText: 'Cancelar',
  preConfirm: async (password) => {
    try {
      const regexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\dd#$@!%&*?ñÑ]{6,}$/;
      if (!regexPassword.test(password)) {
        throw new Error('6');
      }
      return password;
    } catch (error) {
      if (error.message === '6') {
        swal.showValidationError('El password debe tener al menos: 6 carácteres, un número, una mayúscula, una minúscula');
      } else {
        swal.showValidationError(error);
      }
    }
    return true;
  },
});

class Grid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      sorted: [],
    };
  }

  componentDidUpdate(prevProps) {
    const { filters } = this.props;

    // Typical usage (don't forget to compare props):
    if (filters !== prevProps.filters) {
      const { dispatch, pageSize } = this.props;
      const bindingObject = {
        pageSize,
        page: 0,
        sorted: [],
        filters,
      };
      dispatch(requestCandidates(bindingObject));
    }
  }

  setUserHoursLimit = (idUser, skipHourLimits) => {
    const { dispatch, filters, pageSize } = this.props;
    const { page, sorted } = this.state;
    const bindingObject = {
      pageSize,
      page,
      sorted,
      filters,
    };
    dispatch(updateUser({ idUser, skipHourLimits, bindingObject }));
  }

  setForceCatOldTp = (idUser, forceCatOldTp) => {
    const { dispatch, filters, pageSize } = this.props;
    const { page, sorted } = this.state;
    const bindingObject = {
      pageSize,
      page,
      sorted,
      filters,
    };
    dispatch(updateUser({ idUser, forceCatOldTp, bindingObject }));
  }

  fetchData = (state) => {
    const { dispatch, filters } = this.props;
    const { pageSize, page, sorted } = state;
    const bindingObject = {
      pageSize,
      page,
      sorted,
      filters,
    };
    this.setState({ page, sorted });
    dispatch(requestCandidates(bindingObject));
  }

  handleClickResesPassword = async (email, event) => {
    event.preventDefault();
    const { dispatch } = this.props;
    const { value } = await confirmationDialog({
      title: `Nueva contraseña para: ${email}`,
      message: 'Introducir una nueva contraseña y se enviará al usuario.',
    });
    if (!value) return;
    dispatch(changeLeadPassword(email, value));
  }

  render() {
    const {
      loading, pages, pageSize, data,
    } = this.props;
    if (!data) return <Failed />;

    let columnTitles = [['Ha habido un fallo al exportar']];
    let exportButton;
    if (data.length > 0) {
      columnTitles = [
        ['ID', 'Últ. actualización', 'Últ. paso', 'Nombre', 'Teléfono', 'Email', 'CP', 'Centro', 'Formación', 'Estado'],
        ...data.map(item => [
          item.idCandidate,
          item.updatedAt,
          item.step,
          item.name,
          item.phone,
          item.email,
          item.zipCode,
          item.academyName,
          item.courseName,
          item.state,
        ]),
      ];
      exportButton = (
        <div className="column align-right">
          <Button>
            <CSVLink key={data.map(item => item.idCandidate).join('-')} data={columnTitles} className="export">EXPORTAR LISTADO</CSVLink>
          </Button>
        </div>
      );
    }

    return (
      <Wrapper theme={theme}>
        <ReactTable
          topBorder
          columns={[
            {
              Header: 'Últ. actualización',
              accessor: 'updatedAt',
              Cell: ({ value }) => value || '-',
              maxWidth: 120,
              style: { textAlign: 'center' },
            },
            {
              Header: 'ID',
              accessor: 'idCandidate',
              Cell: ({ value }) => value || '-',
              maxWidth: 50,
            },
            {
              Header: 'Últ. paso',
              accessor: 'step',
              Cell: ({ value }) => value || '-',
            },
            {
              Header: 'Nombre',
              accessor: 'name',
              Cell: ({ value }) => value || '-',
            },
            {
              Header: 'Teléfono',
              accessor: 'phone',
              Cell: ({ value }) => value || '-',
            },
            {
              Header: 'Email',
              accessor: 'email',
              Cell: ({ value }) => value || '-',
            },
            {
              Header: 'CP',
              accessor: 'zipCode',
              Cell: ({ value }) => value || '-',
            },
            {
              Header: 'Centro',
              accessor: 'academyName',
              Cell: ({ value }) => value || '-',
            },
            {
              Header: 'Formación',
              accessor: 'courseName',
              Cell: ({ value }) => value || '-',
            },
            {
              Header: 'Estado',
              accessor: 'state',
              Cell: ({ value }) => value || '-',
            },
            {
              Header: 'Sin límite de horas',
              accessor: 'skipHourLimits',
              Cell: ({
                original: { idUser, skipHourLimits },
              }) => {
                const checked = (skipHourLimits === 1);
                const skipHourLimitsChanged = (checked) ? 0 : 1;
                return (
                  <StyledCheckbox
                    checked={checked}
                    onChange={() => this.setUserHoursLimit(idUser, skipHourLimitsChanged)}
                  />
                );
              },
              maxWidth: 80,
            },
            {
              Header: 'Permitir Plan Antiguo',
              accessor: 'forceCatOldTp',
              maxWidth: 240,
              Cell: ({
                original: { idUser, forceCatOldTp },
              }) => {
                const checked = (forceCatOldTp === 1);
                const forceCatOldTpChanged = (checked) ? 0 : 1;
                return (
                  <StyledCheckbox
                    checked={checked}
                    onChange={() => this.setForceCatOldTp(idUser, forceCatOldTpChanged)}
                  />
                );
              },
            },
            {
              // separator
              resizable: false,
            },
            {
              Header: 'Acciones',
              fixed: 'right',
              columns: [
                {
                  Cell: row => (
                    <Button
                      onClick={event => this.handleClickResesPassword(row.original.email, event)}
                    >
                      <FontAwesomeIcon icon={faUnlock} />
                    </Button>
                  ),
                  maxWidth: 50,
                  resizable: false,
                  sortable: false,
                },
              ],
            },
          ]}
          manual
          defaultPageSize={pageSize}
          data={data}
          pages={pages} // Display the total number of pages
          loading={loading} // Display the loading overlay when we need it
          onFetchData={this.fetchData} // Request new data when things change
        />
        {exportButton}
      </Wrapper>
    );
  }
}

Grid.propTypes = {
  dispatch: PropTypes.func.isRequired,
  filters: PropTypes.object,
  data: PropTypes.array,
  pages: PropTypes.number,
  pageSize: PropTypes.number,
  loading: PropTypes.bool.isRequired,
};

Grid.defaultProps = {
  filters: {},
  data: [],
  pageSize: 10,
  pages: 0,
};

export default connect(
  state => ({
    data: state.candidates.data,
    pages: state.candidates.pages,
    loading: state.candidates.loading,
  }),
)(Grid);
