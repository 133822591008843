exports.PERMISSION_SUMMARY = 'SUMMARY';
exports.PERMISSION_ENROLLMENT = 'ENROLLMENT';
exports.PERMISSION_SERVICIOS = 'SERVICIOS';
exports.PERMISSION_ENROLLMENT_MATRICULAS_ESPERA = 'ENROLLMENT_MATRICULAS_ESPERA';
exports.PERMISSION_ENROLLMENT_TRANSFERENCIAS_EN_ESPERA = 'ENROLLMENT_TRANSFERENCIAS_EN_ESPERA';
exports.PERMISSION_STUDENT = 'STUDENT';
exports.PERMISSION_MESSAGE = 'MESSAGE';
exports.PERMISSION_CHARGE = 'CHARGE';
exports.PERMISSION_BILLING = 'BILLING';
exports.PERMISSION_DISCOUNT = 'DISCOUNT';
exports.PERMISSION_VOUCHER = 'VOUCHER';
exports.PERMISSION_LEAD = 'LEAD';
exports.PERMISSION_ACADEMY = 'ACADEMY';
exports.PERMISSION_COURSE = 'COURSE';
exports.PERMISSION_CALENDAR = 'CALENDAR';
exports.PERMISSION_MODULESEMESTERS = 'MODULESEMESTERS';
exports.PERMISSION_SETTINGS = 'SETTINGS';
exports.PERMISSION_STAFF = 'STAFF';
exports.PERMISSION_MAILS = 'MAILS';

exports.ADOBE_ICON = 'ADOBE_ICON';
exports.OUTLOOK_ICON = 'OUTLOOK_ICON';
exports.OUTLOOK_CHECK_ICON = 'OUTLOOK_CHECK_ICON';
exports.OUTLOOK_GROUP_ICON = 'OUTLOOK_GROUP_ICON';
exports.MOODLE_ICON = 'MOODLE_ICON';
exports.MOODLE_101_ICON = 'MOODLE_101_ICON';
exports.MOODLE_CHECK_ICON = 'MOODLE_CHECK_ICON';
exports.MOODLE_COHORT_ICON = 'MOODLE_COHORT_ICON';
exports.MOODLE_ENROLLMENT_ICON = 'MOODLE_ENROLLMENT_ICON';
exports.MOODLE_EXTRA_ICON = 'MOODLE_EXTRA_ICON';
exports.LDAP_ICON = 'LDAP_ICON';
exports.SERVICE_CHECK_ICON = 'SERVICE_CHECK_ICON';
exports.SERVICE_FAIL_ICON = 'SERVICE_FAIL_ICON';
exports.SERVICE_EMPTY_ICON = 'SERVICE_EMPTY_ICON';
exports.SERVICE_CALENDAR_ICON = 'SERVICE_CALENDAR_ICON';
