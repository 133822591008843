import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  list as listThreads,
  get as getThread,
  send,
  unreadMessage,
} from '$redux/messages/actions';
import { tokenSelector } from '$redux/login/selectors';

import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import AsyncSelect from 'react-select/lib/Async';

import Title from 'ui/Title';

import Loading from '$routes/Loading';
import Failed from '$routes/Failed';

import api from 'linkia-api';

import ThreadsList from './ThreadsList';
import ThreadDialog from './ThreadDialog';

const StyledDialog = withStyles({
  root: { overflow: 'visible' },
  paper: { padding: 25, overflow: 'visible' },
})(props => (
  <Dialog {...props}>{props.children}</Dialog>
));

const StyledDialogContent = withStyles({
  root: { overflow: 'visible' },
})(props => (
  <DialogContent {...props}>{props.children}</DialogContent>
));


class Messages extends React.Component {
  state = {
    openSearch: false,
    openThread: false,
    openThreadIdUser: null,
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(listThreads());
  }

  onSearchChange = ({ value }) => {
    this.setState({ openThreadIdUser: parseInt(value, 10) });
  }

  onSendMessage = (e, { message, idUser }) => {
    const { dispatch } = this.props;
    dispatch(send(message, idUser));
  }

  onSendMessageAndClose = (e, data) => {
    this.onSendMessage(e, data);
    this.setState({ openThread: false });
  }

  onClickUnreadMessage = (senderType, idMessage) => {
    const { dispatch } = this.props;
    dispatch(unreadMessage(senderType, idMessage));
  }

  closeSearch = () => this.setState({ openSearch: false })

  openSearch = () => this.setState({ openSearch: true })

  closeThread = () => this.setState({ openThread: false })

  openThread = (idUser) => {
    const { dispatch } = this.props;
    const { openThreadIdUser } = this.state;

    // eslint-disable-next-line no-param-reassign
    if (!idUser) idUser = openThreadIdUser;

    dispatch(getThread(idUser));
    this.closeSearch();

    this.setState({ openThread: true, openThreadIdUser: idUser });
  }

  render() {
    const { data, loading } = this.props;

    if (loading) return <Loading />;
    if (!data) return <Failed />;

    const { single, loadingSingle, token } = this.props;
    const { openSearch, openThread, openThreadIdUser } = this.state;

    return (
      <div>
        <Title>
          Mensajes
          <Button size="small" onClick={this.openSearch}>
            Buscar alumno
          </Button>
        </Title>
        <ThreadsList
          threads={data}
          onClickUnreadMessage={this.onClickUnreadMessage}
          onSendMessage={this.onSendMessage}
        />

        <StyledDialog
          open={openSearch}
          onClose={this.closeSearch}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Buscar alumno</DialogTitle>
          <StyledDialogContent>
            <AsyncSelect
              cacheOptions
              defaultOptions
              placeholder="Seleccionar..."
              onChange={this.onSearchChange}
              loadOptions={search => (
                new Promise((resolve) => {
                  api.user.filterAll({ token, search })
                    .then(
                      users => (
                        resolve(users.map(({ idUser, name }) => ({ value: idUser, label: name })))
                      ),
                    );
                })
              )}
            />
            <Button
              type="submit"
              color="primary"
              onClick={() => this.openThread()}
              style={{ marginTop: 30 }}
            >
              Abrir
            </Button>
          </StyledDialogContent>
        </StyledDialog>

        <ThreadDialog
          open={openThread}
          loading={loadingSingle}
          data={single}
          idUser={openThreadIdUser}
          closeThread={this.closeThread}
          onClickUnreadMessage={this.onClickUnreadMessage}
          onSendMessage={this.onSendMessageAndClose}
        />
      </div>
    );
  }
}

Messages.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  single: PropTypes.array,
  loadingSingle: PropTypes.bool.isRequired,
  token: PropTypes.string.isRequired,
};

Messages.defaultProps = {
  data: null,
  single: null,
};

export default connect(
  state => ({
    data: state.messages.list,
    loading: state.messages.loading,
    single: state.messages.single,
    loadingSingle: state.messages.loadingSingle,
    token: tokenSelector(state),
  }),
)(Messages);
