import styled from 'styled-components';
import theme from 'ui/theme';

export const Wrapper = styled.div`
  padding: 20px 0;
  border-top: 1px solid #e7e7e7;
  font-size: 14px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .sender-type {
      color: #777;
    }

    button.unread {
      border: none;
      background: none;
      text-transform: uppercase;
      font-size: 9px;
      cursor: pointer;
      color: ${theme.primary.main};
      opacity: .5;

      :hover {
        opacity: 1;
      }
    }
  }

  .body {
    color: #777;
  }

  transition: background-color 3s ease-out;
  &.is-new {
    background-color: #fffb84;
  }
`;
