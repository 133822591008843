import {
  select, call, put, takeEvery,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import api from 'linkia-api';

import {
  LIST,
  GET,
  UPDATE,
  REMOVE,
  CREATE,
  LIST_BILLING_RULES,
} from './action-types';
import {
  requestFailed,
  listSucceeded,
  getSucceeded,
  updateSucceeded,
  get,
  removeSucceeded,
  createSucceeded,
  listBillingRulesSucceeded,
} from './actions';

import { tokenSelector } from '../login/selectors';

function* listAcademies() {
  const token = yield select(tokenSelector);
  try {
    const data = yield call(api.academy.listAll, { token });
    yield put(listSucceeded(data));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* listAcademyBillingRules(action) {
  const { idAcademy } = action;
  const token = yield select(tokenSelector);
  try {
    const data = yield call(api.academy.listBillingRules, { idAcademy, token });
    yield put(listBillingRulesSucceeded(data));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* getAcademy(action) {
  const token = yield select(tokenSelector);
  const { id, selectedSemester } = action;
  try {
    const data = yield call(api.academy.get, { token, id, selectedSemester });
    yield put(getSucceeded(data));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* updateAcademy(action) {
  const token = yield select(tokenSelector);
  const { id, data } = action;
  try {
    const success = yield call(api.academy.update, { token, id, data });
    yield put(updateSucceeded(success));
    yield put(get(id, data.selectedSemester));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* removeAcademy(action) {
  const token = yield select(tokenSelector);
  const { id } = action;
  try {
    const success = yield call(api.academy.get, { token, id });
    yield put(removeSucceeded(success));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* createAcademy(action) {
  const token = yield select(tokenSelector);
  const { data } = action;
  try {
    const success = yield call(api.academy.create, { token, data });
    yield put(createSucceeded(success));
    yield put(push(`/centros/editar/${success.idAcademy}`));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

export default [
  takeEvery(LIST, listAcademies),
  takeEvery(GET, getAcademy),
  takeEvery(UPDATE, updateAcademy),
  takeEvery(REMOVE, removeAcademy),
  takeEvery(CREATE, createAcademy),
  takeEvery(LIST_BILLING_RULES, listAcademyBillingRules),
];
