import {
  select, call, put, takeEvery,
} from 'redux-saga/effects';
import { delay } from 'redux-saga';
import api from 'linkia-api';

import {
  LIST,
  GET,
  UPDATE,
  REMOVE,
  CREATE,
} from './action-types';
import {
  list,
  reset,
  requestFailed,
  listSucceeded,
  getSucceeded,
  updateSucceeded,
  removeSucceeded,
  createSucceeded,
} from './actions';

import { tokenSelector } from '../login/selectors';

function* listGrades(action) {
  const token = yield select(tokenSelector);
  try {
    const { idUser, idEnrollment } = action;

    const data = {
      idUser,
      idEnrollment,
    };

    const success = yield call(api.grade.studentGet, { token, data });
    yield put(listSucceeded(success));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* updateGrade(action) {
  const token = yield select(tokenSelector);
  const { data } = action;
  try {
    // studentSync
    const success = yield call(api.grade.studentUpdate, { token, data });
    yield delay(1000);
    yield put(updateSucceeded(success));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* getGrade(action) {
  const token = yield select(tokenSelector);
  const { idUser, idEnrollment } = action;
  try {
    const data = {
      idUser,
      idEnrollment,
    };
    const success = yield call(api.grade.studentSync, { token, data });
    yield put(getSucceeded(success));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* removeGrade(action) {
  const token = yield select(tokenSelector);
  const { id } = action;
  try {
    const success = yield call(api.collaboratingCenter.delete, { token, id });
    yield put(removeSucceeded(success));
    yield put(list());
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* createGrade(action) {
  const token = yield select(tokenSelector);
  const { data } = action;
  try {
    const success = yield call(api.collaboratingCenter.create, { token, data });
    yield put(createSucceeded(success));
    yield put(reset());
    yield put(list());
  } catch (e) {
    yield put(requestFailed(e));
  }
}

export default [
  takeEvery(LIST, listGrades),
  takeEvery(GET, getGrade),
  takeEvery(UPDATE, updateGrade),
  takeEvery(REMOVE, removeGrade),
  takeEvery(CREATE, createGrade),
];
