export const REQUEST_FAILED = 'CHARGES_REQUEST_FAILED';
export const LIST = 'CHARGES_LIST';
export const LIST_SUCCEEDED = 'CHARGES_LIST_SUCCEEDED';
export const BEFORE_CREATE = 'CHARGE_BEFORE_CREATE';
export const BEFORE_CREATE_SUCCEEDED = 'CHARGE_BEFORE_CREATE_SUCCEEDED';
export const GET = 'CHARGE_GET';
export const GET_SUCCEEDED = 'CHARGE_GET_SUCCEEDED';
export const UPDATE = 'CHARGE_UPDATE';
export const UPDATE_SUCCEEDED = 'CHARGE_UPDATE_SUCCEEDED';
export const REMOVE = 'CHARGE_REMOVE';
export const REMOVE_SUCCEEDED = 'CHARGE_REMOVE_SUCCEEDED';
export const CREATE = 'CHARGE_CREATE';
export const CREATE_SUCCEEDED = 'CHARGE_CREATE_SUCCEEDED';
export const SET = 'CHARGE_SET';
export const RESET = 'CHARGE_RESET';
