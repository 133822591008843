import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { canSelector } from '$redux/login/selectors';
import { set as setBilling, requestExport, filtersRequest } from '$redux/billing/actions';
import { requesEnrollmentSplitPaymentConfirmExportar } from '$redux/enrollment-split-payment-confirm/actions';
import Title from 'ui/Title';
import { Select, Button } from '@material-ui/core';
import theme from 'ui/theme';

import { Wrapper } from './styles';
import Filters from './Filters';
import Grid from './Grid';

class Billing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {},
      selectedAcademy: undefined,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(filtersRequest());
  }

  set = (key, value) => {
    const { dispatch } = this.props;
    dispatch(setBilling(key, value));
  }

  handleChange = name => (event) => {
    event.preventDefault();
    event.persist();
    this.setState({
      [name]: event.target.value,
    });
  }

  exportData = async () => {
    const { academy, dispatch } = this.props;
    const { filters } = this.state;

    const bindingObject = {
      filters,
      academy,
    };
    dispatch(requestExport(bindingObject));
  }

  exportarPagosStripe = async () => {
    const { dispatch } = this.props;
    dispatch(requesEnrollmentSplitPaymentConfirmExportar());
  }

  applyFilters = (filtersSelected) => {
    this.setState({ filters: filtersSelected });
  }

  render() {
    const { filters } = this.state;
    const { academies, userCan, filtersData } = this.props;
    const { selectedAcademy } = this.state;
    const academiesList = academies
      .map(
        element => (
          <option key={element.name} value={element.idAcademy}>{element.name}</option>
        ),
      );
    return (
      <Wrapper theme={theme}>
        <Title>Facturación
          {
            userCan('BILLING_FILTER_BY_OTHER_ACADEMY') ? (
              <Select
                native
                onChange={this.handleChange('selectedAcademy')}
                className="filter-academy"
              >
                <option value={0}>Centro (Todos)</option>
                {academiesList}
              </Select>
            ) : null
          }
        </Title>
        <Filters applyFilters={this.applyFilters} filtersData={filtersData} set={this.set} />
        <Grid
          filters={filters}
          academy={userCan('BILLING_FILTER_BY_OTHER_ACADEMY') ? selectedAcademy : 'auto-selected'}
        />
        <div className="column export-button">
          <Button onClick={() => this.exportarPagosStripe()}>
            EXPORTAR PAGOS RECIBIDOS STRIPE
          </Button>
          <Button onClick={() => this.exportData()}>
            EXPORTAR
          </Button>
        </div>
      </Wrapper>
    );
  }
}

Billing.propTypes = {
  academies: PropTypes.array,
  dispatch: PropTypes.func.isRequired,
  userCan: PropTypes.func.isRequired,
  filtersData: PropTypes.object,
};

Billing.defaultProps = {
  academies: [],
  filtersData: undefined,
};

export default connect(
  state => ({
    academies: state.billing.academies,
    userCan: canSelector(state),
    filtersData: state.billing.filtersData,
  }),
)(Billing);
