import React from 'react';

import Title from 'ui/Title';

import Filters from './Filters';
import Grid from './Grid';

class Student extends React.Component { // eslint-disable-line
  constructor(props) {
    super(props);
    this.state = {
      filters: {},
    };
  }

  applyFilters = ({ filtersObject = {} }) => {
    const filtersToApply = {};
    Object.keys(filtersObject).forEach((key) => {
      if (filtersObject[key] && filtersObject[key] !== '0') {
        filtersToApply[key] = filtersObject[key];
      }
    });
    this.setState({ filters: filtersToApply });
  }

  render() {
    const { filters } = this.state;

    return (
      <div>
        <Title>Alumnos</Title>
        <Filters applyFilters={this.applyFilters} />
        <Grid filters={filters} />
      </div>);
  }
}


export default Student;
