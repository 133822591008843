import styled from 'styled-components';

export const ZipCodesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const CodeWrapper = styled.div`
  margin-bottom: 20px;
  margin-right: 20px;
  display: flex;
  align-items: center;

  button {
    padding: 0 5px;
    margin-left: 5px;
    border: 0;
    background: none;
    cursor: pointer;

    color: #ccc;

    :hover {
      color: inherit;
    }
  }
`;
