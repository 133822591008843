import dotenv from 'dotenv';
import {
  select, call, put, takeEvery,
} from 'redux-saga/effects';
import api from 'linkia-api';

import {
  LIST,
  GET,
  EDIT,
  CREATE,
  DELETE_UNIT,
  FILTERS_REQUEST,
  REQUEST_MODULE_PRICES,
  REQUEST_MODULE_PRICES_CONFIRM,
} from './action-types';
import {
  listSucceeded,
  listFailed,
  getSucceeded,
  getFailed,
  editSucceeded,
  createSucceeded,
  filtersRequestFailed,
  filtersRequestSucceeded,
  requestModulePricesSucceeded,
  requestModulePricesFailed,
  requestModulePricesConfirmSucceeded,
  requestModulePricesConfirmFailed,
} from './actions';

import { tokenSelector } from '../login/selectors';

dotenv.config();
const { NODE_ENV: env } = process.env;

function* reqThreads() {
  const token = yield select(tokenSelector);
  try {
    const threads = yield call(api.staff.me.getThreads, { token });
    yield put(listSucceeded(threads));
  } catch (e) {
    // eslint-disable-next-line no-console
    if (env === 'development' || !e.response || e.response.status !== 401) console.error(e);
    yield put(listFailed());
  }
}

function* reqGetModule(action) {
  const token = yield select(tokenSelector);
  const { idModule } = action;
  try {
    const data = yield call(api.module.get, { token, idModule });
    yield put(getSucceeded(data, idModule));
  } catch (e) {
    // eslint-disable-next-line no-console
    if (env === 'development' || !e.response || e.response.status !== 401) console.error(e);
    yield put(getFailed());
  }
}

function* reqEditModule(action) {
  const token = yield select(tokenSelector);
  const { idModule, moduleEdit } = action;
  try {
    const data = yield call(api.module.update, { token, idModule, moduleEdit });
    yield put(editSucceeded(data, idModule));
  } catch (e) {
    // eslint-disable-next-line no-console
    if (env === 'development' || !e.response || e.response.status !== 401) console.error(e);
    yield put(getFailed());
  }
}

function* reqCreateModule(action) {
  const token = yield select(tokenSelector);
  const { moduleObject } = action;
  try {
    const module = yield call(api.module.create, { token, moduleObject });
    yield put(createSucceeded(module));
  } catch (e) {
    // eslint-disable-next-line no-console
    if (env === 'development' || !e.response || e.response.status !== 401) console.error(e);
    yield put(getFailed());
  }
}

function* reqDeleteUnit(action) {
  const token = yield select(tokenSelector);
  const { idUnit, idModule } = action;
  try {
    yield call(api.unit.delete, { token, idUnit });
    const data = yield call(api.module.get, { token, idModule });
    yield put(getSucceeded(data, idModule));
  } catch (e) {
    // eslint-disable-next-line no-console
    if (env === 'development' || !e.response || e.response.status !== 401) console.error(e);
    yield put(getFailed());
  }
}

function* reqFilters() {
  const token = yield select(tokenSelector);
  try {
    const filtersData = yield call(api.module.priceFilters, { token });
    yield put(filtersRequestSucceeded(filtersData));
  } catch (e) {
    yield put(filtersRequestFailed());
  }
}

function* reqModulePrices(action) {
  const token = yield select(tokenSelector);
  const {
    bindingObject,
  } = action;
  try {
    const data = yield call(api.module.prices, {
      bindingObject,
      token,
    });
    yield put(requestModulePricesSucceeded(data, bindingObject));
  } catch (e) {
    yield put(requestModulePricesFailed());
  }
}

function* reqModulePricesConfirm(action) {
  const token = yield select(tokenSelector);
  const {
    prices,
  } = action;

  const bindingObject = {
    prices,
  };

  try {
    yield call(api.module.pricesConfirm, {
      bindingObject,
      token,
    });
    yield put(requestModulePricesConfirmSucceeded());
  } catch (e) {
    yield put(requestModulePricesConfirmFailed());
  }
}

export default [
  takeEvery(LIST, reqThreads),
  takeEvery(GET, reqGetModule),
  takeEvery(EDIT, reqEditModule),
  takeEvery(CREATE, reqCreateModule),
  takeEvery(DELETE_UNIT, reqDeleteUnit),
  takeEvery(FILTERS_REQUEST, reqFilters),
  takeEvery(REQUEST_MODULE_PRICES, reqModulePrices),
  takeEvery(REQUEST_MODULE_PRICES_CONFIRM, reqModulePricesConfirm),
];
