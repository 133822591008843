import React from 'react';
import PropTypes from 'prop-types';
import SubTitle from 'ui/SubTitle';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Wrapper } from './Modules.styles';
import DiscountItem from './DiscountItem';
import ModuleItem from './ModuleItem';

class Modules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      data, modules, discounts, userCan, moduleHolds,
    } = this.props;

    let enrollmentAmount = 0.00;
    let modifyLink;
    const modulesList = modules.map(item => (
      <ModuleItem key={item.idEnrollmentTransactionModule} enrollmentModule={item} />));

    const modulesListHold = moduleHolds.map(item => (
      <ModuleItem key={item.idEnrollmentTransactionModuleHold} enrollmentModule={item} />));

    const discountsList = discounts.map(item => (
      <DiscountItem key={item.idEnrollmentTransactionDiscount} discount={item} />));

    if (data) {
      enrollmentAmount = data.totalCost;
      if (data.paymentDate) {
        const { idEnrollment } = data;
        modifyLink = (
          <Link to={`/matriculas/modificar/${idEnrollment}`}>
            <Button size="small">Modificar</Button>
          </Link>
        );
      }
    }

    const subtitle = (
      <SubTitle>Módulos y unidades formativas
        {userCan('ENROLLMENT_ROADMAP_EDIT') ? modifyLink : undefined}
      </SubTitle>
    );

    return (
      <Wrapper>
        {subtitle}
        <div className="column is-four-fifths  is-multiline content ">
          <div className="columns header is-vcentered">
            <div className="column is-one-fifths">CÓDIGO</div>
            <div className="column is-two-fifths">NOMBRE</div>
            <div className="column is-one-fifths">SEMESTRE</div>
            <div className="column is-one-fifths amount">PRECIO</div>
          </div>
          {modulesList}
          {modulesListHold}
          <div className="discounts-group">
            {discountsList}
          </div>
          <div className="columns total">
            <div className="column is-three-fifths is-offset-one-fifth">TOTAL</div>
            <div className="column is-one-fifths amount">{enrollmentAmount}</div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

Modules.propTypes = {
  data: PropTypes.object,
  modules: PropTypes.array,
  moduleHolds: PropTypes.array,
  discounts: PropTypes.array,
  userCan: PropTypes.func.isRequired,
};

Modules.defaultProps = {
  data: {},
  modules: [],
  moduleHolds: [],
  discounts: [],
};

export default Modules;
