export const UPDATE = 'ROADMAPMODULE_UPDATE';
export const UPDATE_SUCCEEDED = 'ROADMAPMODULE_UPDATE_SUCCEEDED';
export const UPDATE_FAILED = 'ROADMAPMODULE_UPDATE_FAILED';

export const ACTIVE = 'ROADMAP_ACTIVE';
export const ACTIVE_SUCCEEDED = 'ROADMAP_ACTIVE_SUCCEEDED';
export const ACTIVE_FAILED = 'ROADMAP_ACTIVE_FAILED';

export const OBLIGATORIO = 'ROADMAP_OBLIGATORIO';
export const OBLIGATORIO_SUCCEEDED = 'ROADMAP_OBLIGATORIO_SUCCEEDED';
export const OBLIGATORIO_FAILED = 'ROADMAP_OBLIGATORIO_FAILED';

export const LIST_MODULESEMESTER = 'MODULESEMESTER_LIST';
export const LIST_MODULESEMESTER_SUCCEEDED = 'MODULESEMESTER_LIST_SUCCEEDED';
