import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Title from 'ui/Title';
import theme from 'ui/theme';

import { Button } from '@material-ui/core';
import { Wrapper } from './styles';
import Grid from './Grid';
import Filters from './Filters';
import LogDialog from './LogDialog';

import {
  requestGetServicesExport,
} from '$redux/services/actions';

class Services extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {},
      open: false,
      idUserService: '-1',
    };
  }

  applyFilters = (filtersSelected) => {
    this.setState({ filters: filtersSelected });
  }

  openLogWindow = (idUserService) => {
    this.setState({
      open: true,
      idUserService,
    });
  }

  closeThread = () => {
    this.setState({
      open: false,
    });
  }

  exportData = async () => {
    const { dispatch } = this.props;

    dispatch(requestGetServicesExport({}));
  }

  render() {
    const { data, loading } = this.props;
    const {
      filters,
      open,
      idUserService,
    } = this.state;

    return (
      <Wrapper theme={theme}>
        <Title>
          Servicios
        </Title>
        <Filters applyFilters={this.applyFilters} />
        <LogDialog
          open={open}
          idUserService={idUserService}
          closeThread={this.closeThread}
        />
        <Grid
          data={data}
          loading={loading}
          filters={filters}
          openLogWindow={this.openLogWindow}
        />
        <div className="column export-button">
          <Button onClick={() => this.exportData()}>
            EXPORTAR
          </Button>
        </div>
      </Wrapper>
    );
  }
}

Services.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool.isRequired,
};

Services.defaultProps = {
  data: null,
};

export default connect(
  state => ({
    data: state.services.list,
    loading: state.services.loading,
  }),
)(Services);
