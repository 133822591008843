import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper } from './ErrorBoundary.styles';

class ErrorBoundary extends React.Component {
  state = {
    error: null,
    errorInfo: null,
  };

  componentDidCatch = (error, errorInfo) => {
    this.setState({ error, errorInfo });
  }

  onRetry = () => window.location.reload();

  render() {
    const { error, errorInfo } = this.state;
    const { children } = this.props;

    if (errorInfo) {
      const { componentStack } = errorInfo;

      return (
        <Wrapper>
          <h1>Ha ocurrido un error inesperado <span role="img" aria-label="Llanto">😢</span></h1>
          <button type="button" onClick={this.onRetry}>Volver a intentarlo</button>

          <h2>Información del error:</h2>
          <pre>
            {error && error.toString()}
            <br />
            {componentStack}
          </pre>
        </Wrapper>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ErrorBoundary;
