import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Title from 'ui/Title';
import theme from 'ui/theme';
import {
  requestAddServicesActionUser,
  requestChangeStatusActionService,
  requestRemoveActionService,
} from '$redux/services/actions';

import { Button } from '@material-ui/core';
import swal from 'sweetalert2';
import { Wrapper } from './styles';
import Grid from './Grid';
import LogDialog from './LogDialog';
import Editor from './Editor';


class Services extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      editorOpen: false,
      idUserServiceAction: '-1',
    };
  }

  openLogWindow = (idUserServiceAction) => {
    this.setState({
      open: true,
      idUserServiceAction,
    });
  };

  closeThread = () => {
    this.setState({
      open: false,
    });
  };

  openEditor = () => {
    this.setState({ editorOpen: true });
  };

  onCreate = (action) => {
    const {
      match,
      dispatch,
    } = this.props;

    const serviceAction = {
      idUserService: match.params.idUserService,
      actions: [
        action,
      ],
      retries: -1,
      maxRetries: -1,
      status: 0,
    };

    dispatch(
      requestAddServicesActionUser(
        serviceAction,
      ),
    );
  };

  removeServiceAction = async (id) => {
    const { dispatch } = this.props;
    if (id) {
      const { value } = await swal({
        title: 'Confirmación',
        text: `¿Seguro que quieres borrar la acción con id #${id}?`,
        type: 'warning',
        confirmButtonText: 'Sí, ¡bórralo!',
        confirmButtonColor: '#e05959',
        showCancelButton: true,
        cancelButtonText: 'No, volver atrás',
      });

      if (!value) return;
      dispatch(requestRemoveActionService(id));
    }
  };

  handleStatusChange = async (id, status) => {
    const {
      dispatch,
    } = this.props;
    dispatch(requestChangeStatusActionService({
      id,
      status,
    }));
  };

  render() {
    const {
      loading,
      match,
      // reload,
    } = this.props;

    const {
      open,
      idUserServiceAction,
    } = this.state;

    const { editorOpen } = this.state;

    /* if (reload) {
      window.location.reload();
    } */

    return (
      <Wrapper theme={theme}>
        <Title>
          Servicios #{match.params.idUserService}
          <Button onClick={() => this.openEditor()} size="small">Añadir nueva acción</Button>
        </Title>
        {
          editorOpen ? (
            <Editor
              onSend={this.onCreate}
              onClose={() => this.setState({ editorOpen: false })}
            />
          ) : null
        }
        <LogDialog
          open={open}
          idUserServiceAction={idUserServiceAction}
          closeThread={this.closeThread}
        />
        <Grid
          loading={loading}
          idUserService={match.params.idUserService}
          openLogWindow={this.openLogWindow}
          onChangeStatus={this.handleStatusChange}
          removeItem={this.removeServiceAction}
        />
      </Wrapper>
    );
  }
}

Services.propTypes = {
  // reload: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

Services.defaultProps = {
  // reload: false,
};

export default connect(
  state => ({
    loading: state.services.loading,
    reload: state.services.reload,
  }),
)(Services);
