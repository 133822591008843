import {
  select, call, put, takeEvery,
} from 'redux-saga/effects';
import api from 'linkia-api';

import {
  UPDATE,
  ACTIVE,
  OBLIGATORIO,
  LIST_MODULESEMESTER,
} from './action-types';
import {
  updateSucceeded,
  updateFailed,
  activeSucceeded,
  activeFailed,
  obligatorioSucceeded,
  obligatorioFailed,
  listSucceeded,
} from './actions';

import { tokenSelector } from '../login/selectors';

function* updateRoadMapModule(action) {
  const token = yield select(tokenSelector);
  const { data: { idRoadMapModule, idModuleSemester } } = action;
  try {
    const success = yield call(api.roadMapModules.update, {
      token, id: idRoadMapModule, data: { idModuleSemester },
    });
    yield put(updateSucceeded(success));
  } catch (e) {
    yield put(updateFailed(e));
  }
}

function* activeRoadMapModule(action) {
  const token = yield select(tokenSelector);
  const { data: { idRoadMapModule, active } } = action;
  try {
    const success = yield call(api.roadMapModules.active, {
      token, id: idRoadMapModule, data: { active },
    });
    yield put(activeSucceeded(success));
  } catch (e) {
    yield put(activeFailed(e));
  }
}

function* obligatorioRoadMapModule(action) {
  const token = yield select(tokenSelector);
  const { data: { idRoadMapModule, obligatorio } } = action;
  try {
    const success = yield call(api.roadMapModules.obligatorio, {
      token, id: idRoadMapModule, data: { obligatorio },
    });
    yield put(obligatorioSucceeded(success));
  } catch (e) {
    console.log(e);
    yield put(obligatorioFailed(e));
  }
}

function* listModuleSemester() {
  const token = yield select(tokenSelector);
  try {
    const data = yield call(api.moduleSemester.listModuleSemester, { token });
    yield put(listSucceeded(data));
  } catch (e) {
    yield put(updateFailed(e));
  }
}

export default [
  takeEvery(UPDATE, updateRoadMapModule),
  takeEvery(ACTIVE, activeRoadMapModule),
  takeEvery(OBLIGATORIO, obligatorioRoadMapModule),
  takeEvery(LIST_MODULESEMESTER, listModuleSemester),
];
