export const REQUEST_GROUP_LIST_CICLES = 'GROUP_LIST_CICLES';
export const REQUEST_GROUP_LIST_CICLES_SUCCEEDED = 'GROUP_LIST_CICLES_SUCCEEDED';
export const REQUEST_GROUP_LIST_CICLES_FAILED = 'GROUP_LIST_CICLES_FAILED';

export const REQUEST_GROUP_FILTERS = 'REQUEST_GROUP_FILTERS';
export const REQUEST_GROUP_FILTERS_SUCCEEDED = 'REQUEST_GROUP_FILTERS_REQUEST_SUCCEEDED';
export const REQUEST_GROUP_FILTERS_FAILED = 'REQUEST_GROUP_FILTERS_REQUEST_FAILED';

export const REQUEST_GROUP_MASSIVE_OPTIONS = 'REQUEST_GROUP_MASSIVE_OPTIONS';
export const REQUEST_GROUP_MASSIVE_OPTIONS_SUCCEEDED = 'REQUEST_GROUP_MASSIVE_OPTIONS_SUCCEEDED';
export const REQUEST_GROUP_MASSIVE_OPTIONS_FAILED = 'REQUEST_GROUP_MASSIVE_OPTIONS_FAILED';

export const REQUEST_GROUP_REMOVE_CICLES = 'REQUEST_GROUP_REMOVE_CICLES';
export const REQUEST_GROUP_REMOVE_CICLES_SUCCEEDED = 'REQUEST_GROUP_REMOVE_CICLES_SUCCEEDED';
export const REQUEST_GROUP_REMOVE_CICLES_FAILED = 'REQUEST_GROUP_REMOVE_CICLES_FAILED';

export const REQUEST_GROUP_CREATE = 'GROUP_CICLES_CREATE';
export const REQUEST_GROUP_CREATE_SUCCEEDED = 'GROUP_CICLES_CREATE_SUCCEEDED';
export const REQUEST_GROUP_CREATE_FAILED = 'GROUP_CICLES_CREATE_FAILED';

export const REQUEST_GROUP_GET = 'GROUP_GET';
export const REQUEST_GROUP_GET_SUCCEEDED = 'GROUP_GET_REQUEST_SUCCEEDED';
export const REQUEST_GROUP_GET_FAILED = 'GROUP_GET_REQUEST_FAILED';

export const REQUEST_GROUP_GET_CICLE_ENROLLMENT_BY_GROUP = 'GROUP_GET_CICLE_ENROLLMENT_BY_GROUP';
export const REQUEST_GROUP_GET_CICLE_ENROLLMENT_BY_GROUP_SUCCEEDED = 'GROUP_GET_CICLE_ENROLLMENT_BY_GROUP_SUCCEEDED';
export const REQUEST_GROUP_GET_CICLE_ENROLLMENT_BY_GROUP_FAILED = 'GROUP_GET_CICLE_ENROLLMENT_BY_GROUP_FAILED';

export const REQUEST_GROUP_GET_ALL_COURSES_BY_SEMESTER_AND_COURSE = 'GROUP_GET_ALL_COURSES_BY_SEMESTER_AND_COURSE';
export const REQUEST_GROUP_GET_ALL_COURSES_BY_SEMESTER_AND_COURSE_SUCCEEDED = 'GROUP_GET_ALL_COURSES_BY_SEMESTER_AND_COURSE_SUCCEEDED';
export const REQUEST_GROUP_GET_ALL_COURSES_BY_SEMESTER_AND_COURSE_FAILED = 'GROUP_GET_ALL_COURSES_BY_SEMESTER_AND_COURSE_FAILED';

export const REQUEST_GROUP_UPDATE_COURSES_BY_SEMESTER_AND_COURSE = 'GROUP_UPDATE_COURSES_BY_SEMESTER_AND_COURSE';
export const REQUEST_GROUP_UPDATE_COURSES_BY_SEMESTER_AND_COURSE_SUCCEEDED = 'GROUP_UPDATE_COURSES_BY_SEMESTER_AND_COURSE_SUCCEEDED';
export const REQUEST_GROUP_UPDATE_COURSES_BY_SEMESTER_AND_COURSE_FAILED = 'GROUP_UPDATE_COURSES_BY_SEMESTER_AND_COURSE_FAILED';

export const REQUEST_GROUP_UPDATE_ENROLLMENT_TO_GROUP = 'REQUEST_GROUP_UPDATE_ENROLLMENT_TO_GROUP';
export const REQUEST_GROUP_UPDATE_ENROLLMENT_TO_GROUP_SUCCEEDED = 'REQUEST_GROUP_UPDATE_ENROLLMENT_TO_GROUP_SUCCEEDED';
export const REQUEST_GROUP_UPDATE_ENROLLMENT_TO_GROUP_FAILED = 'REQUEST_GROUP_UPDATE_ENROLLMENT_TO_GROUP_FAILED';

export const SET = 'GROUP_CICLES_SET';
