import React from 'react';
import PropTypes from 'prop-types';

import { WarningCell, LinkiaUpdatedCell, AcademyUpdatedCell } from './Grid.helpers.styles';

const WarningValue = ({ children }) => (
  <WarningCell>{children} <div className="triangle"><div>!</div></div></WarningCell>
);
const LinkiaUpdatedValue = ({ children }) => (
  <LinkiaUpdatedCell>{children} <div className="dot" /></LinkiaUpdatedCell>
);
const AcademyUpdatedValue = ({ children }) => (
  <AcademyUpdatedCell>{children} <div className="dot" /></AcademyUpdatedCell>
);
WarningValue.propTypes = { children: PropTypes.any.isRequired };
LinkiaUpdatedValue.propTypes = { children: PropTypes.any.isRequired };
AcademyUpdatedValue.propTypes = { children: PropTypes.any.isRequired };

export { WarningValue, LinkiaUpdatedValue, AcademyUpdatedValue };
