import {
  REQUEST_UTM_STUDENT_FILTERS,
  REQUEST_UTM_STUDENT_FILTERS_SUCCEEDED,
  REQUEST_UTM_STUDENT_FILTERS_FAILED,
} from './action-types';

import utils from '../utils';

const initialState = {
  data: [],
  totals: {},
  loading: false,
};

const utmStudentsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case REQUEST_UTM_STUDENT_FILTERS: {
      return {
        ...state,
        loading: true,
      };
    }
    case REQUEST_UTM_STUDENT_FILTERS_SUCCEEDED: {
      const {
        data, pages, bindingObject: { filters },
      } = action;
      return {
        ...state,
        utmStudents: data,
        filters,
        pages,
        loading: false,
      };
    }
    case REQUEST_UTM_STUDENT_FILTERS_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error inesperado',
        message: 'No se ha podido recoger los ciclos, ha ocurrido un error.',
      });
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default utmStudentsReducer;
