import {
  SET,
  REQUEST_GROUP_LIST_CICLES,
  REQUEST_GROUP_LIST_CICLES_SUCCEEDED,
  REQUEST_GROUP_LIST_CICLES_FAILED,
  REQUEST_GROUP_FILTERS,
  REQUEST_GROUP_FILTERS_SUCCEEDED,
  REQUEST_GROUP_FILTERS_FAILED,
  REQUEST_GROUP_MASSIVE_OPTIONS,
  REQUEST_GROUP_MASSIVE_OPTIONS_SUCCEEDED,
  REQUEST_GROUP_MASSIVE_OPTIONS_FAILED,
  REQUEST_GROUP_CREATE,
  REQUEST_GROUP_CREATE_SUCCEEDED,
  REQUEST_GROUP_CREATE_FAILED,
  REQUEST_GROUP_GET,
  REQUEST_GROUP_GET_SUCCEEDED,
  REQUEST_GROUP_GET_FAILED,
  REQUEST_GROUP_UPDATE_ENROLLMENT_TO_GROUP,
  REQUEST_GROUP_UPDATE_ENROLLMENT_TO_GROUP_SUCCEEDED,
  REQUEST_GROUP_UPDATE_ENROLLMENT_TO_GROUP_FAILED,
  REQUEST_GROUP_GET_CICLE_ENROLLMENT_BY_GROUP,
  REQUEST_GROUP_GET_CICLE_ENROLLMENT_BY_GROUP_SUCCEEDED,
  REQUEST_GROUP_GET_CICLE_ENROLLMENT_BY_GROUP_FAILED,
  REQUEST_GROUP_GET_ALL_COURSES_BY_SEMESTER_AND_COURSE,
  REQUEST_GROUP_GET_ALL_COURSES_BY_SEMESTER_AND_COURSE_SUCCEEDED,
  REQUEST_GROUP_GET_ALL_COURSES_BY_SEMESTER_AND_COURSE_FAILED,
  REQUEST_GROUP_UPDATE_COURSES_BY_SEMESTER_AND_COURSE,
  REQUEST_GROUP_UPDATE_COURSES_BY_SEMESTER_AND_COURSE_SUCCEEDED,
  REQUEST_GROUP_UPDATE_COURSES_BY_SEMESTER_AND_COURSE_FAILED,
  REQUEST_GROUP_REMOVE_CICLES,
  REQUEST_GROUP_REMOVE_CICLES_SUCCEEDED,
  REQUEST_GROUP_REMOVE_CICLES_FAILED,
} from './action-types';

export const reqGroupListCicles = bindingObject => ({
  type: REQUEST_GROUP_LIST_CICLES, bindingObject,
});
export const reqGroupListCiclesSucceeded = ({
  groups: { data, totals },
}) => ({
  type: REQUEST_GROUP_LIST_CICLES_SUCCEEDED,
  data,
  totals,
});
export const reqGroupListCiclesFailed = () => ({
  type: REQUEST_GROUP_LIST_CICLES_FAILED,
});

export const reqGroupGet = idCicle => ({
  type: REQUEST_GROUP_GET, idCicle,
});
export const reqGroupGetSucceeded = ({
  group, groupRoom, alreadyInGroupsQuery, candidates,
}) => ({
  type: REQUEST_GROUP_GET_SUCCEEDED,
  group,
  groupRoom,
  alreadyInGroupsQuery,
  candidates,
});
export const reqGroupGetFailed = () => ({ type: REQUEST_GROUP_GET_FAILED });

export const reqGroupGetCicleEnrollmentsByGroup = idCicleGroup => ({
  type: REQUEST_GROUP_GET_CICLE_ENROLLMENT_BY_GROUP, idCicleGroup,
});
export const reqGroupGetCicleEnrollmentsByGroupSucceeded = ({
  data: { enrollmentsForGroups },
}) => ({
  type: REQUEST_GROUP_GET_CICLE_ENROLLMENT_BY_GROUP_SUCCEEDED,
  enrollmentsForGroups,
});
export const reqGroupGetCicleEnrollmentsByGroupFailed = () => (
  { type: REQUEST_GROUP_GET_CICLE_ENROLLMENT_BY_GROUP_FAILED });


export const reqGroupGetAllCoursesBySemesterAndCourse = (idCourse, idSemester) => ({
  type: REQUEST_GROUP_GET_ALL_COURSES_BY_SEMESTER_AND_COURSE, idCourse, idSemester,
});
export const reqGroupGetAllCoursesBySemesterAndCourseSucceeded = ({
  data: { proposals },
}) => ({
  type: REQUEST_GROUP_GET_ALL_COURSES_BY_SEMESTER_AND_COURSE_SUCCEEDED,
  proposals,
});
export const reqGroupGetAllCoursesBySemesterAndCourseFailed = () => (
  { type: REQUEST_GROUP_GET_ALL_COURSES_BY_SEMESTER_AND_COURSE_FAILED });


export const reqGroupUpdateCoursesBySemesterAndCourse = bindingObject => ({
  type: REQUEST_GROUP_UPDATE_COURSES_BY_SEMESTER_AND_COURSE, bindingObject,
});
export const reqGroupUpdateCoursesBySemesterAndCourseSucceeded = ({
  data: { result },
}) => ({
  type: REQUEST_GROUP_UPDATE_COURSES_BY_SEMESTER_AND_COURSE_SUCCEEDED,
  result,
});
export const reqGroupUpdateCoursesBySemesterAndCourseFailed = () => (
  { type: REQUEST_GROUP_UPDATE_COURSES_BY_SEMESTER_AND_COURSE_FAILED });


export const reqGroupRemoveCicles = bindingObject => ({
  type: REQUEST_GROUP_REMOVE_CICLES, bindingObject,
});
export const reqGroupRemoveCiclesSucceeded = ({
  data: { result },
}) => ({
  type: REQUEST_GROUP_REMOVE_CICLES_SUCCEEDED,
  result,
});
export const reqGroupRemoveCiclesFailed = () => (
  { type: REQUEST_GROUP_REMOVE_CICLES_FAILED });


export const reqGroupFilters = () => (
  { type: REQUEST_GROUP_FILTERS }
);
export const reqGroupFiltersSucceeded = filtersData => ({
  type: REQUEST_GROUP_FILTERS_SUCCEEDED,
  filtersData,
});
export const reqGroupFiltersFailed = () => ({ type: REQUEST_GROUP_FILTERS_FAILED });

export const reqGroupCreate = data => ({ type: REQUEST_GROUP_CREATE, data });
export const reqGroupCreateSucceeded = success => ({
  type: REQUEST_GROUP_CREATE_SUCCEEDED,
  success,
});
export const reqGroupCreateFailed = success => ({
  type: REQUEST_GROUP_CREATE_FAILED,
  success,
});

export const reqGroupMoveEnrollmentToGroupUpdate = data => ({
  type: REQUEST_GROUP_UPDATE_ENROLLMENT_TO_GROUP,
  data,
});
export const reqGroupMoveEnrollmentToGroupUpdateSuccess = success => ({
  type: REQUEST_GROUP_UPDATE_ENROLLMENT_TO_GROUP_SUCCEEDED,
  success,
});
export const reqGroupMoveEnrollmentToGroupUpdateFailed = success => ({
  type: REQUEST_GROUP_UPDATE_ENROLLMENT_TO_GROUP_FAILED,
  success,
});

export const reqGroupMassiveOptions = () => (
  { type: REQUEST_GROUP_MASSIVE_OPTIONS }
);
export const reqGroupMassiveOptionsSucceeded = filtersData => ({
  type: REQUEST_GROUP_MASSIVE_OPTIONS_SUCCEEDED,
  filtersData,
});
export const reqGroupMassiveOptionsFailed = () => ({
  type: REQUEST_GROUP_MASSIVE_OPTIONS_FAILED,
});

export const set = (key, value) => ({ type: SET, key, value });
