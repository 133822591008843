import styled from 'styled-components';

export const Wrapper = styled.div`

  .content{
    font-size: 0.9em;

    .total-matricula {
      font-weight: 700;
    }

    .delete-button {
      background-color: #B81C14;
      color: white;
      margin-left: 5px;
    }

    .delete-button:hover {
      background-color: #D71F18;
      color: white;
    }
    .save-button {
      margin-left: 5px;
    }
    .header, .total {
      font-weight: 700;
      padding-right: 12px;
    }

    .modules, .units, .discounts {
      border-top: solid 1px rgba(0, 0, 0, 0.3);
      margin-bottom: 10px;
    }

    .total {
      border-top: solid 2px;
      display: flex;
      align-items: center;
    }

    .units {
      padding-left: 10px;
      opacity: 0.8;
    }

    .discounts-group > div:first-child
    {
      border-top: solid 2px;
    }
    .amount {
      text-align: right;
    }
    .penalty-column {
      display: flex;
      flex-direction: row;
      align-items: center;
      .penalty-label {
        padding-right: 20px;
      }
    }
  }

  .checkbox {
    max-height: 10px;
  }

`;
