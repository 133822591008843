import React from 'react';
import moment from 'moment';
import 'moment/locale/es';
import PropTypes from 'prop-types';

import {
  TextField, Button,
} from '@material-ui/core';

import { Wrapper } from './styles';


moment.locale('es');

class BillingFormNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: null,
      commission: null,
    };
  }

  compare = (a, b) => {
    if (Number(a.limit) < Number(b.limit)) return -1;
    if (Number(a.limit) > Number(b.limit)) return 1;
    return 0;
  }

  handleBlur = name => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  }

  addClick = () => {
    const { set, data } = this.props;
    const { limit, commission } = this.state;
    const newList = [...data.billingRules, { limit, commission }];
    newList.sort(this.compare);
    set('billingRules', newList);
  }

  render() {
    return (
      <Wrapper>
        <div className="columns is-multiline">
          <div className="column is-2">
            <TextField
              label="A PARTIR DE"
              className="input-billing"
              placeholder="€"
              onBlur={this.handleBlur('limit')}
            />
          </div>
          <div className="column is-2">
            <TextField
              className="input-billing"
              label="%"
              onBlur={this.handleBlur('commission')}
            />
          </div>
          <div className="column is-2 is-vcentered">
            <Button
              fullWidth
              onClick={this.addClick}
            > Añadir Escalonado
            </Button>
          </div>
        </div>
      </Wrapper>
    );
  }
}

BillingFormNew.propTypes = {
  set: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default BillingFormNew;
