import styled from 'styled-components';

export const Wrapper = styled.div`
  .form-actions {
    margin-top: 30px;

    & > * {
      margin-right: 20px;
    }
  }
`;
