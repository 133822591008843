import styled from 'styled-components';

export const Wrapper = styled.div`
  .export-data {
    margin-top: 20px;
    float: right;
    button {
      color: #6896c8;
    }
  }
  .align-right {
    text-align: right;
    padding: 0;
  }
  .export {
    color: #6896c8;
    font-size: 12px;
  }
  h2 {
    margin-bottom: 10px;
  }

  .service-image {
    height: 24px;
    width: 48px;
  }
`;
