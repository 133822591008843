import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  reset as resetAcademy,
  set as setAcademy,
  create as createAcademy,
} from '$redux/academies/actions';

import Title from 'ui/Title';

import Form from '../Form';

class Academies extends React.Component {
  componentDidMount = () => {
    const { dispatch } = this.props;
    dispatch(resetAcademy());
  }

  onCreate = () => {
    const { dispatch, data } = this.props;
    dispatch(createAcademy(data));
  }

  set = (key, value) => {
    const { dispatch } = this.props;
    dispatch(setAcademy(key, value));
  }

  render() {
    const { data, semesters } = this.props;

    return (
      <div>
        <Title>Crear nuevo centro</Title>
        <Form
          action="new"
          data={data}
          set={this.set}
          onSend={this.onCreate}
          semesters={semesters}
        />
      </div>
    );
  }
}

Academies.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.object,
  semesters: PropTypes.array,
};

Academies.defaultProps = {
  data: null,
  semesters: [],
};

export default connect(
  state => ({
    data: state.academies.single,
    loading: state.academies.loading,
  }),
)(Academies);
