import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import theme from 'ui/theme';
import moment from 'moment';
import Select from '@material-ui/core/Select';
import { TextField, Button } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import themeFilters from 'ui/muiFiltersTheme';
import Failed from '$routes/Failed';
import Loading from '$routes/Loading';
import { Wrapper } from './Filters.styles';
import { reqGroupFilters } from '$redux/groups/actions';

moment.locale('es');

class Filters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idUtm: '',
      campaignId: '',
      campaignSource: '',
      campaignMedium: '',
      campaignCampaign: '',
      campaignTerm: '',
      campaignContent: '',
      campaignUser: '',
      campaignReferer: '',
      withLead: '',
      withEnrollment: '',
      startDate: undefined,
      endDate: undefined,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(reqGroupFilters());
  }

  onApplyFilters = (e) => {
    e.preventDefault();
    const { applyFilters } = this.props;

    const {
      idUtm,
      campaignId,
      campaignSource,
      campaignMedium,
      campaignCampaign,
      campaignTerm,
      campaignContent,
      campaignUser,
      campaignReferer,
      withLead,
      withEnrollment,
      startDate,
      endDate,
    } = this.state;
    applyFilters({
      idUtm,
      campaignId,
      campaignSource,
      campaignMedium,
      campaignCampaign,
      campaignTerm,
      campaignContent,
      campaignUser,
      campaignReferer,
      withLead,
      withEnrollment,
      startDate,
      endDate,
    });
  };

  handleChange = name => (event) => {
    event.preventDefault();
    this.setState({ [name]: event.target.value });
  };

  setStartDate = (startDate) => {
    this.setState({ startDate });
  }

  setEndDate = (endDate) => {
    this.setState({ endDate });
  }

  render() {
    const { filtersData, loading } = this.props;

    if (loading) return <Loading />;
    if (!filtersData) return <Failed />;

    const {
      idUtm,
      campaignId,
      campaignSource,
      campaignMedium,
      campaignCampaign,
      campaignTerm,
      campaignContent,
      campaignUser,
      campaignReferer,
      withLead,
      withEnrollment,
      startDate,
      endDate,
    } = this.state;

    return (
      <Wrapper theme={theme}>
        <MuiThemeProvider theme={themeFilters}>
          <form onSubmit={this.onApplyFilters} className="filters-form">
            <div className="filters">
              <TextField
                className="filter-item"
                id="idUtm"
                placeholder="Utm Id"
                onChange={this.handleChange('idUtm')}
                defaultValue={idUtm}
              />
              <TextField
                className="filter-item"
                id="campaignId"
                placeholder="Campaña Id"
                onChange={this.handleChange('campaignId')}
                defaultValue={campaignId}
              />
              <TextField
                className="filter-item"
                id="campaignSource"
                placeholder="Source"
                onChange={this.handleChange('campaignSource')}
                defaultValue={campaignSource}
              />
              <TextField
                className="filter-item"
                id="campaignMedium"
                placeholder="Medium"
                onChange={this.handleChange('campaignMedium')}
                defaultValue={campaignMedium}
              />
              <TextField
                className="filter-item"
                id="campaignCampaign"
                placeholder="Campaña"
                onChange={this.handleChange('campaignCampaign')}
                defaultValue={campaignCampaign}
              />
              <TextField
                className="filter-item"
                id="campaignTerm"
                placeholder="Terminos"
                onChange={this.handleChange('campaignTerm')}
                defaultValue={campaignTerm}
              />
              <TextField
                className="filter-item"
                id="campaignContent"
                placeholder="Content"
                onChange={this.handleChange('campaignContent')}
                defaultValue={campaignContent}
              />
              <TextField
                className="filter-item"
                id="campaignUser"
                placeholder="User"
                onChange={this.handleChange('campaignUser')}
                defaultValue={campaignUser}
              />
              <TextField
                className="filter-item"
                id="campaignReferer"
                placeholder="Referer"
                onChange={this.handleChange('campaignReferer')}
                defaultValue={campaignReferer}
              />
              <Select
                native
                className="filter-item"
                onChange={this.handleChange('withLead')}
                value={withLead}
                id={withLead}
              >
                <option value={-1}>¿Tiene lead?</option>
                <option value={0}>Si</option>
                <option value={1}>No</option>
              </Select>
              <Select
                native
                id={withEnrollment}
                className="filter-item"
                onChange={this.handleChange('withEnrollment')}
                value={withEnrollment}
              >
                <option value={-1}>¿Tiene matrícula?</option>
                <option value={0}>Simple</option>
                <option value={1}>Con Matrícula</option>
              </Select>
              <TextField
                type="date"
                value={startDate ? moment(startDate).format('YYYY-MM-DD') : 'dd/mm/aaaa'}
                onChange={e => this.setStartDate(e.target.value)}
              />
              <TextField
                type="date"
                value={endDate ? moment(endDate).format('YYYY-MM-DD') : 'dd/mm/aaaa'}
                onChange={e => this.setEndDate(e.target.value)}
              />
              <Button
                color="primary"
                type="submit"
                className="button"
              >
                APLICAR FILTROS
              </Button>
            </div>
          </form>
        </MuiThemeProvider>
      </Wrapper>
    );
  }
}

Filters.propTypes = {
  applyFilters: PropTypes.func.isRequired,
  filtersData: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

Filters.defaultProps = {
  filtersData: undefined,
};

export default connect(
  state => ({
    filtersData: state.groups.filtersData,
    loading: state.groups.loading,
  }),
)(Filters);
