import React from 'react';
import { connect } from 'react-redux';
import Title from 'ui/Title';
import PropTypes from 'prop-types';
import ReactTable from 'ui/ReactTable';

import { requestEnrollmentTransferEspera } from '$redux/enrollments/actions';

class TransferenciaEspera extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onFetchData = (state) => {
    const { dispatch } = this.props;
    const { pageSize, page, sorted } = state;
    const bindingObject = {
      pageSize,
      page,
      sorted,
    };
    dispatch(requestEnrollmentTransferEspera(bindingObject));
  }

  render() {
    const {
      transfers, pages, pageSize,
    } = this.props;
    return (
      <div>
        <Title>
          Transferencias en espera
        </Title>
        <ReactTable
          topBorder
          columns={[
            {
              Header: 'Fecha',
              accessor: 'createdAt',
              width: 200,
              Cell: (row) => {
                const {
                  original: { createdAt },
                } = row;
                const updatedDate = new Date(createdAt);
                return <p>{`${updatedDate.getDate()}/${updatedDate.getMonth() + 1}/${updatedDate.getFullYear()}`}</p>;
              },
            },
            {
              Header: 'Estudiante',
              accessor: 'name',
            },
            {
              Header: 'Cantidad (Euros)',
              accessor: 'amount',
              width: 220,
            },
            {
              Header: 'Matrícula',
              accessor: 'idEnrollment',
              width: 220,
              Cell: (row) => {
                const {
                  original: { idEnrollment },
                } = row;
                return <a href={`matriculas/editar/${idEnrollment}`} rel="noopener noreferrer" target="_blank">Matrícula</a>;
              },
            },
            {
              Header: 'Justificante',
              accessor: 'paymentDocument',
              width: 220,
              Cell: (row) => {
                const {
                  original: { paymentDocument },
                } = row;
                if (paymentDocument !== '') return <a href={`${paymentDocument}`} rel="noopener noreferrer" target="_blank">Ver</a>;
                return <p>Sin documento</p>;
              },
            },
          ]}
          manual
          defaultPageSize={pageSize}
          pages={pages}
          data={transfers}
          onFetchData={this.onFetchData} // Request new data when things change
        />
      </div>
    );
  }
}

TransferenciaEspera.propTypes = {
  dispatch: PropTypes.func.isRequired,
  transfers: PropTypes.array,
  pageSize: PropTypes.number,
  pages: PropTypes.number,
};

TransferenciaEspera.defaultProps = {
  transfers: [],
  pageSize: 10,
  pages: 0,
};

export default connect(
  state => ({
    transfers: state.enrollments.transfers,
    pages: state.enrollments.pages,
  }),
)(TransferenciaEspera);
