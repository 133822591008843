import React from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert2';

import { withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { Wrapper } from './SendBox.styles';

const StyledTextField = withStyles({ root: { '& > div': { padding: '10px !important' } } })(
  props => (
    <TextField {...props} />
  ),
);

// eslint-disable-next-line
class SendBox extends React.Component {
  state = {
    message: '',
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { message } = this.state;

    if (!message) {
      swal('¡Oye!', '¡No puedes enviar un mensaje vacío!', 'warning');
      return;
    }

    const { onSubmit, idUser } = this.props;
    onSubmit(e, { message, idUser });
    this.setState({ message: '' });
  }

  updateMessage = e => (
    this.setState({ message: e.target.value })
  )

  render() {
    const { disabled } = this.props;
    const { message } = this.state;

    return (
      <Wrapper>
        <form onSubmit={this.onSubmit}>
          <StyledTextField
            onChange={this.updateMessage}
            value={message}
            placeholder="Escribe aquí tu mensaje..."
            fullWidth
            margin="normal"
            variant="filled"
            multiline
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            size="small"
            disabled={disabled}
          >
            Enviar mensaje
          </Button>
        </form>
      </Wrapper>
    );
  }
}

SendBox.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  idUser: PropTypes.number,
  disabled: PropTypes.bool.isRequired,
};

SendBox.defaultProps = {
  idUser: null,
};

export default SendBox;
