export default {
  primary: {
    main: '#6896c8',
    light: '#9ac6fb',
    dark: '#356897',
  },
  secondary: {
    main: '#6f8533',
    light: '#9fb560',
    dark: '#415802',
  },
};

export const colors = {
  primary: '#6896c8',
  secondary: '#6f8533',
  success: '#2ad08c',
  warning: '#d4be16',
  error: '#de6565',
  normal: '#6f96b9',
  disabled: '#ccc',
};
