import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

// import our MaterialUI custom theme & apply it via MuiThemeProvider
import theme from 'ui/muiCustomTheme';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

// Base styles
import 'bulma/css/bulma.css';
import 'react-table/react-table.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './index.css';

// App
import App from './containers/App';
import ErrorBoundary from './containers/ErrorBoundary';
import { unregister } from './registerServiceWorker';

// redux store & related stuff
import store from './redux/store';
import history from './history';

// extra front helpers
// import dialog from './dialog';
// import motd from './motd';

ReactDOM.render(
  <ErrorBoundary>
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </Provider>
    </MuiThemeProvider>
  </ErrorBoundary>,
  document.getElementById('root'),
);

// Disable PWA caching
unregister();


// message of the day :)
// motd();
