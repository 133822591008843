import React from 'react';
import { connect } from 'react-redux';
import Title from 'ui/Title';
import PropTypes from 'prop-types';
import MailEditPanel from '../../components/MailEditPanel/MailEditPanel';
import { get, updateMails } from '$redux/mails/actions';
import SubTitle from '../../ui/SubTitle';

const getClarification = (mail) => {
  let clarification;
  switch (mail.type) {
    case 'validacionDigital':
      clarification = '(Validación Digital)';
      break;
    case 'validacionFisica':
      clarification = '(Validación Física)';
      break;
    case 'documentacionDigitalIncompleta':
      clarification = '(Documentación digital incompleta)';
      break;
    case 'documentacionFisicaIncompleta':
      clarification = '(Documentación física incompleta)';
      break;
    case 'baja':
      clarification = '(Baja)';
      break;
    case 'completa':
      clarification = '(Completa)';
      break;
    case 'regeneratePassword':
      clarification = '(Recuperación contraseña)';
      break;
    case 'bienvenida':
      clarification = '(Bienvenida)';
      break;
    default:
      clarification = '';
  }
  return clarification;
};
class MailsConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(get());
  }

  render() {
    const { mailsState: { mails } } = this.props;
    return (
      <div>
        <Title>
          Personalización de emails
        </Title>
        <SubTitle>
          Listado de mails
        </SubTitle>
        {mails && mails.map((mail) => {
          const clarification = getClarification(mail);
          return (<div><a href={`#${mail.subject}`}>{mail.subject} {clarification}</a></div>);
        })}
        <SubTitle>
          Edición de mails
        </SubTitle>
        {mails && mails
          .map(mail => (<MailEditPanel key={mail.idMail} mail={mail} onUpdate={updateMails} title={`${mail.subject} ${getClarification(mail)}`} />))}
      </div>
    );
  }
}

MailsConfig.propTypes = {
  dispatch: PropTypes.func.isRequired,
  mailsState: PropTypes.object,
};

MailsConfig.defaultProps = {
  mailsState: {},
};

export default connect(
  state => ({
    mailsState: state.mails,
  }),
)(MailsConfig);
