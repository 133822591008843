import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  reset as resetDiscount,
  set as setDiscount,
  create as createDiscount,
  beforeCreate as beforeCreateDiscount,
} from '$redux/discounts/actions';

import Title from 'ui/Title';
import Loading from '$routes/Loading';
import Failed from '$routes/Failed';

import Form from '../Form';

class Discounts extends React.Component {
  componentDidMount = () => {
    const { dispatch } = this.props;
    dispatch(resetDiscount());
    dispatch(beforeCreateDiscount());
  }

  onCreate = () => {
    const { dispatch, data } = this.props;
    dispatch(createDiscount(data));
  }

  set = (key, value) => {
    const { dispatch } = this.props;
    dispatch(setDiscount(key, value));
  }

  render() {
    const {
      data,
      ui,
      loading,
    } = this.props;

    if (loading) return <Loading />;
    if (!data) return <Failed />;

    return (
      <div>
        <Title>Crear nuevo descuento</Title>
        <Form
          action="new"
          data={data}
          ui={ui}
          set={this.set}
          onSend={this.onCreate}
        />
      </div>
    );
  }
}

Discounts.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.object,
  ui: PropTypes.object,
  loading: PropTypes.bool.isRequired,
};

Discounts.defaultProps = {
  data: null,
  ui: null,
};

export default connect(
  state => ({
    data: state.discounts.single,
    ui: state.discounts.ui,
    loading: state.discounts.loading,
  }),
)(Discounts);
