import styled from 'styled-components';
import theme from 'ui/theme';

export const Wrapper = styled.div``;

const inputBackground = '#e9e9e9';
export const DatePickerWrapper = styled.div`
  height: ${({ border }) => (border ? '41px' : '40px')};
  border: ${({ border }) => (border ? '1px solid #ccc' : 'none')};
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${inputBackground};
  box-shadow: ${({ border }) => (border ? '0px 4px 6px -2px rgba(0, 0, 0, 0.12)' : 'none')};

  .DateRangePickerInput_clearDates { visibility: visible; }
  .DateRangePickerInput { background-color: ${inputBackground} }
  .DateInput_input { text-align: center; background-color: ${inputBackground}; }
  .DateInput_input__focused { border-bottom-color: ${theme.primary.main}; }
  .DateInput__small { width: 110px; }
  .CalendarDay { vertical-align: middle; }
  .DayPickerKeyboardShortcuts_buttonReset { display: none; }
  .DateRangePicker_picker { z-index: 3; }
`;
