export const calcularDiferenciaDias = (fecha1, fecha2) => {
  try {
    const partesFecha1 = fecha1.split('-');
    const fechaDate1 = new Date(partesFecha1[0], partesFecha1[1] - 1, partesFecha1[2]);
    const partesFecha2 = fecha2.split('-');
    const fechaDate2 = new Date(partesFecha2[0], partesFecha2[1] - 1, partesFecha2[2]);

    const tiempo = fechaDate2 - fechaDate1;

    return (tiempo <= 0)
      ? -1
      : Math.floor(tiempo / (1000 * 60 * 60 * 24));
  } catch (e) {
    return -1;
  }
};
