import styled from 'styled-components';

export const Wrapper = styled.div`
    border-bottom: 2px solid gray;
    display: flex;
    margin: 20px 0;
    padding: 20px 20px 50px 20px;
    a {
        text-decoration: none;
        color: inherit;
        cursor: inherit;
    }
    form {
        div {
            margin: 10px 0;
            h3 {
                margin-bottom: 20px;
            }
            input {
                margin-left: 30px;
                width: 500px;
                font-family: "Roboto", sans-serif;
                border: lightgray 1px solid;
                padding: 5px;
            }
        }
    }
`;
