import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button } from '@material-ui/core';
import Title from 'ui/Title';

import Grid from './Grid';

class Courses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Title>
          Ciclos
          <Link to="/ciclos/nuevo">
            <Button size="small">Añadir nuevo ciclo</Button>
          </Link>
        </Title>
        <Grid />
      </div>
    );
  }
}

export default connect()(Courses);
