import React from 'react';
import PropTypes from 'prop-types';
import SubTitle from 'ui/SubTitle';
import { Button, InputLabel, Select } from '@material-ui/core';
import { Wrapper } from './Validation.styles';


class ChangeAcademy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      academies, data, onSend, set,
    } = this.props;

    const academiesList = academies.map(item => (
      <option key={item.idAcademy} value={item.idAcademy}>{item.name}</option>
    ));
    const stateOptions = (
      <div className="column is-three-fifths">
        <div className="columns is-vcentered">
          <div className="column is-one-fifths">
            <InputLabel>Centros: </InputLabel>
          </div>
          <div className="column is-four-fifths">
            <Select
              fullWidth
              value={data.idAcademy ? data.idAcademy : 1}
              onChange={e => set('idAcademy', e.target.value)}
            >
              {academiesList}
            </Select>
          </div>
        </div>
      </div>
    );

    return (
      <Wrapper>
        <SubTitle>Cambio de centro</SubTitle>
        <div className="columns is-multiline">
          {stateOptions}
          <div className="column is-four-fifths">
            <Button color="primary" onClick={onSend}>Actualizar</Button>
          </div>
        </div>
      </Wrapper>
    );
  }
}

ChangeAcademy.propTypes = {
  data: PropTypes.object,
  academies: PropTypes.array,
  set: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
};

ChangeAcademy.defaultProps = {
  data: {},
  academies: [],
};

export default ChangeAcademy;
