/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, TextField } from '@material-ui/core';
import { Link } from 'react-router-dom';
import DiscountItem from './DiscountItem';
import ModuleItem from './ModuleItem';

class TransactionTab extends React.Component {
  onTabSend = () => {
    const { enrollmentTransactions, selectedTab, onSend } = this.props;
    onSend(enrollmentTransactions[selectedTab]);
  };

  onTabRemove = () => {
    const { enrollmentTransactions, selectedTab, onRemove } = this.props;
    onRemove(enrollmentTransactions[selectedTab].idEnrollmentTransaction);
  };

  getTransactionPdf = (idEnrollmentTransaction) => {
    const { getTransactionPdf } = this.props;
    getTransactionPdf(idEnrollmentTransaction);
  }

  render() {
    const {
      enrollmentTransactions,
      selectedTab,
      setModule, setUnit, setPenalty, setModuleCost, setUnitCost, setNewPrices, setDiscountCost,
    } = this.props;

    let transactionButtons;
    let amountColumn;
    let penaltyColumn;

    const currentTransaction = enrollmentTransactions[selectedTab];
    const isMaster = (currentTransaction.isMaster === 1);
    const modulesList = currentTransaction.Modules.map(item => (
      <ModuleItem
        key={item.idModule}
        module={item}
        idEnrollmentTransaction={currentTransaction.idEnrollmentTransaction}
        setModule={setModule}
        setUnit={setUnit}
        isMaster={isMaster}
        setModuleCost={setModuleCost}
        setUnitCost={setUnitCost}
      />));

    let modulesListHold = '';
    if (currentTransaction.ModuleHolds && currentTransaction.ModuleHolds.length > 0) {
      modulesListHold = currentTransaction.ModuleHolds.map(item => (
        <ModuleItem
          key={item.idModule}
          module={item}
          idEnrollmentTransaction={currentTransaction.idEnrollmentTransaction}
          setModule={setModule}
          setUnit={setUnit}
          isMaster={isMaster}
          setModuleCost={setModuleCost}
          setUnitCost={setUnitCost}
        />));
    }

    if (!isMaster) {
      amountColumn = <div className="column is-one-fifths amount">IMPORTE</div>;
      penaltyColumn = (
        <React.Fragment>
          <div className="penalty-label">
            %:
          </div>
          <TextField
            type="number"
            inputProps={{
              style: {
                textAlign: 'right',
                paddingRight: '12px',
              },
            }}
            onChange={e => setPenalty(e.target.value)}
            value={(currentTransaction.penaltyPercentage * 1).toFixed(2)}
          />
        </React.Fragment>
      );
    }
    const discountsList = currentTransaction.Discounts.map(item => (
      <DiscountItem key={item.idDiscount} setDiscountCost={setDiscountCost} discount={item} />));

    const invoiceButton = (
      <Button
        className="download-button"
        color="primary"
        onClick={() => this.getTransactionPdf(currentTransaction.idEnrollmentTransaction)}
      >
        Factura
      </Button>
    );

    const priceButton = (
      <Button
        className="download-button"
        color="primary"
        onClick={() => setNewPrices(currentTransaction.idEnrollment, currentTransaction.amount, enrollmentTransactions)}
      >
        Guardar Precio
      </Button>
    );

    if (currentTransaction.isMaster === 0) {
      if (!currentTransaction.paymentDate) {
        if (currentTransaction.idEnrollmentTransaction > 0) {
          transactionButtons = (
            <div>
              {invoiceButton}
              <Button className="delete-button" onClick={() => this.onTabRemove()}>Borrar cambios</Button>
            </div>);
        } else {
          transactionButtons = (
            <div>
              <Button className="save-button" color="secondary" onClick={() => this.onTabSend()}>Guardar cambios</Button>
            </div>);
        }
      } else {
        transactionButtons = <div>{invoiceButton} {priceButton}</div>;
      }
    } else {
      transactionButtons = <div>{invoiceButton} {priceButton}</div>;
    }

    return (
      <div className="column is-four-fifths  is-multiline content ">
        <div className="columns header is-vcentered">
          <div className="column is-one-fifths">CÓDIGO</div>
          <div className="column is-three-fifths">NOMBRE</div>
          <div className="column is-one-fifths amount">PRECIO</div>
          {amountColumn}
        </div>
        {modulesList}
        {modulesListHold}
        <div className="discounts-group">
          {discountsList}
        </div>
        <div className="columns total">
          <div className="column is-two-fifths is-offset-one-fifth">TOTAL</div>
          <div className="column is-one-fifths penalty-column">
            {penaltyColumn}
          </div>
          <div className="column is-one-fifths amount">{currentTransaction.amount}</div>
        </div>
        <div className="buttons">
          <Link to={`/matriculas/editar/${currentTransaction.idEnrollment}`}>
            <Button className="return-button" color="primary">Volver</Button>
          </Link>
          {transactionButtons}
        </div>
      </div>
    );
  }
}

TransactionTab.propTypes = {
  enrollmentTransactions: PropTypes.array,
  selectedTab: PropTypes.number,
  onSend: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  getTransactionPdf: PropTypes.func.isRequired,
  setModule: PropTypes.func.isRequired,
  setUnit: PropTypes.func.isRequired,
  setPenalty: PropTypes.func.isRequired,
  setModuleCost: PropTypes.func.isRequired,
  setUnitCost: PropTypes.func.isRequired,
  setNewPrices: PropTypes.func.isRequired,
  setDiscountCost: PropTypes.func.isRequired,
};

TransactionTab.defaultProps = {
  enrollmentTransactions: [],
  selectedTab: 0,
};

export default TransactionTab;
