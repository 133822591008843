import {
  select, call, put, takeEvery,
} from 'redux-saga/effects';
import api from 'linkia-api';

import {
  ENROLLMENT_SPLIT_PAYMENT_CONFIRM_EXPORTAR_REQUEST,
} from './action-types';
import {
  requesEnrollmentSplitPaymentConfirmExportarSucceeded,
  requesEnrollmentSplitPaymentConfirmExportarFailed,
} from './actions';

import { tokenSelector } from '../login/selectors';

function* exportarPagos() {
  const token = yield select(tokenSelector);
  try {
    const data = yield call(api.enrollmentSplitPaymentConfirm.exportar, { token });
    yield put(requesEnrollmentSplitPaymentConfirmExportarSucceeded(data));
  } catch (e) {
    yield put(requesEnrollmentSplitPaymentConfirmExportarFailed(e));
  }
}

export default [
  takeEvery(ENROLLMENT_SPLIT_PAYMENT_CONFIRM_EXPORTAR_REQUEST, exportarPagos),
];
