import styled from 'styled-components';

export const Wrapper = styled.div`
  .student-enrollments {
    border-bottom: none;
    margin: 0;
    /* padding: 0; */
  }

  .content {
    margin-top: 20px;
    margin-bottom: 30px;
    max-width: 95%;
    /* padding-left: 2rem; */
  }
`;
