import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, TooltipMinWrapper, TooltipWrapper } from './Tooltip.styles';

// eslint-disable-next-line
class Tooltip extends React.Component {
  render() {
    const { type, children, position } = this.props;

    return (
      <Wrapper position={position}>
        <TooltipMinWrapper>
          <TooltipWrapper type={type} position={position}>
            {children}
          </TooltipWrapper>
        </TooltipMinWrapper>
      </Wrapper>
    );
  }
}

Tooltip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  type: PropTypes.oneOf(['normal', 'warning', 'error', 'success', 'disabled']),
  position: PropTypes.oneOf(['top', 'bottom']),
};

Tooltip.defaultProps = {
  type: 'disabled',
  position: 'top',
};

export default Tooltip;
