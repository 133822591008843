import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import theme from 'ui/theme';
import ReactTable from 'ui/ReactTable';
import 'react-table/react-table.css';


import { reqUtmFilters } from '$redux/utms/actions';

import Failed from '$routes/Failed';

import { Wrapper } from './styles';

class Grid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      sorted: [],
    };
  }

  componentDidUpdate(prevProps) {
    const { filters } = this.props;

    // Typical usage (don't forget to compare props):
    if (filters !== prevProps.filters) {
      const { dispatch, pageSize } = this.props;
      const bindingObject = {
        pageSize,
        page: 0,
        sorted: [],
        filters,
      };
      dispatch(reqUtmFilters(bindingObject));
    }
  }

  fetchData = (state) => {
    let { filters } = this.props;
    const { dispatch, filtersApplied } = this.props;
    filters = {
      ...filtersApplied,
      ...filters,
    };
    const { pageSize, page, sorted } = state;
    const bindingObject = {
      pageSize,
      page,
      sorted,
      filters,
    };
    dispatch(reqUtmFilters(bindingObject));
    // eslint-disable-next-line react/no-unused-state
    this.setState({ sorted });
  }

  render() {
    const {
      loading, pages, pageSize, data,
    } = this.props;

    if (!data) return <Failed />;

    return (
      <Wrapper theme={theme}>
        <ReactTable
          topBorder
          columns={[
            {
              Header: 'Utm Id',
              accessor: 'idUtm',
              width: 120,
            },
            {
              Header: 'Campaña Id',
              accessor: 'campaignId',
              width: 120,
            },
            {
              Header: 'Campaña Source',
              accessor: 'campaignSource',
              width: 80,
            },
            {
              Header: 'Campaña Medium',
              accessor: 'campaignMedium',
            },
            {
              Header: 'Campaña',
              accessor: 'campaignCampaign',
            },
            {
              Header: 'Campaña Terminos',
              accessor: 'campaignTerms',
            },
            {
              Header: 'Campaña Contenido',
              accessor: 'campaignContent',
            },
            {
              Header: 'Campaña User',
              accessor: 'campaignUser',
            },
            {
              Header: 'Lead',
              accessor: 'withLead',
              Cell: ({ value }) => (value > 0 ? 'Sí' : 'No'),
            },
            {
              Header: 'Enrollment',
              accessor: 'withEnrollment',
              Cell: ({ value }) => (value > 0 ? 'Sí' : 'No'),
            },
            {
              Header: 'Referer',
              accessor: 'campaignReferer',
            },
            {
              Header: 'Curso',
              accessor: 'UtmForm.UtmFormCourse.Course.name',
            },
            {
              Header: 'URL Formulario',
              accessor: 'UtmForm.formFromUrl',
            },
            {
              Header: 'Nombre',
              accessor: 'UtmForm.name',
            },
            {
              Header: 'Codigo Postal',
              accessor: 'UtmForm.zip',
            },
            {
              Header: 'Email',
              accessor: 'UtmForm.email',
            },
            {
              Header: 'Telefono',
              accessor: 'UtmForm.phone',
            },
            {
              Header: 'Privacidad',
              accessor: 'UtmForm.privacity',
            },
            {
              Header: 'Ads',
              accessor: 'UtmForm.ads',
            },
            {
              Header: 'Creado',
              accessor: 'createdAt',
            },
            {
              Header: 'Actualizado',
              accessor: 'updatedAt',
            },
          ]}
          manual
          defaultPageSize={pageSize}
          data={data}
          pages={pages} // Display the total number of pages
          loading={loading} // Display the loading overlay when we need it
          onFetchData={this.fetchData} // Request new data when things change
        />
      </Wrapper>
    );
  }
}

Grid.propTypes = {
  dispatch: PropTypes.func.isRequired,
  filters: PropTypes.object,
  data: PropTypes.array,
  pages: PropTypes.number,
  pageSize: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  filtersApplied: PropTypes.object,
};

Grid.defaultProps = {
  filters: {},
  data: [],
  pageSize: 10,
  pages: 0,
  filtersApplied: {},
};

export default connect(
  state => ({
    data: state.utms.utms,
    pages: state.utms.pages,
    loading: state.utms.loading,
    filtersApplied: state.utms.filters,
  }),
)(Grid);
