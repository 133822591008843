import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  get as getDiscount,
  set as setDiscount,
  update as updateDiscount,
} from '$redux/discounts/actions';

import Title from 'ui/Title';
import Loading from '$routes/Loading';
import Failed from '$routes/Failed';

import Form from '../Form';

class Discounts extends React.Component {
  componentDidMount() {
    const { dispatch, match: { params: { id } } } = this.props;
    dispatch(getDiscount(id));
  }

  onUpdate = () => {
    const { dispatch, data } = this.props;
    dispatch(updateDiscount(data.idDiscount, data));
  }

  set = (key, value) => {
    const { dispatch } = this.props;
    dispatch(setDiscount(key, value));
  }

  render() {
    const {
      data,
      ui,
      loading,
      match: { params: { id } },
    } = this.props;

    if (loading) return <Loading />;
    if (!data) return <Failed />;

    return (
      <div>
        <Title>Editar descuento #{id}</Title>
        <Form
          action="edit"
          data={data}
          ui={ui}
          set={this.set}
          onSend={this.onUpdate}
        />
      </div>
    );
  }
}

Discounts.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.object,
  ui: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
};

Discounts.defaultProps = {
  data: null,
  ui: null,
};

export default connect(
  state => ({
    data: state.discounts.single,
    ui: state.discounts.ui,
    loading: state.discounts.loading,
  }),
)(Discounts);
