import {
  select, call, put, takeEvery,
} from 'redux-saga/effects';
import api from 'linkia-api';

import {
  FILTERS_REQUEST,
  GET_ENROLLMENTS_FILTERED_WITH_SERVICES,
} from './action-types';

import {
  filtersRequestSucceeded,
  filtersRequestFailed,
  requestGetEnrollmentsWithServicesSucceeded,
  requestGetEnrollmentsWithServicesFailed,
} from './actions';

import { tokenSelector } from '../login/selectors';

function* reqFilters() {
  const token = yield select(tokenSelector);
  try {
    const filtersData = yield call(api.enrollment.getFilters, { token });
    yield put(filtersRequestSucceeded(filtersData));
  } catch (e) {
    yield put(filtersRequestFailed());
  }
}

function* reqEnrollmentsWithServices(action) {
  const token = yield select(tokenSelector);
  const {
    bindingObject, origin,
  } = action;
  try {
    const data = yield call(api.service.getEnrollmentsWithServices, {
      bindingObject, token,
    });
    yield put(requestGetEnrollmentsWithServicesSucceeded(data, origin, bindingObject));
  } catch (e) {
    yield put(requestGetEnrollmentsWithServicesFailed());
  }
}

export default [
  takeEvery(FILTERS_REQUEST, reqFilters),
  takeEvery(GET_ENROLLMENTS_FILTERED_WITH_SERVICES, reqEnrollmentsWithServices),
];
