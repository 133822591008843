export const resumen = (modificados) => {
  let salida = '<table style="width: 100%;"><tr><td>Codigo</td><td>Sin Descuento</td><td>Precio Fijo</td></tr>';
  modificados.forEach((m) => {
    salida += `<tr>
    <td>${m.codigo}</td>
    <td>${parseFloat(m.precioBase).toFixed(2)}€</td>
    <td>${parseFloat(m.precio).toFixed(2)}€</td>
    </tr>`;
  });
  salida += '</table>';
  return salida;
};
