import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loading from '$routes/Loading';

import {
  TextField,
} from '@material-ui/core';

// eslint-disable-next-line
class BasicForm extends React.Component {
  render() {
    const {
      loading,
      data,
      setValue,
    } = this.props;

    const {
      dateInit,
      dateEnd,
    } = data;

    if (loading) return <Loading />;

    return (
      <div>
        <div className="columns is-vcentered">
          <div className="column is-one-fifth">Desde</div>
          <div className="column is-four-fifths">
            <TextField
              key="dateInit"
              value={dateInit}
              type="date"
              onChange={setValue('dateInit')}
            />
          </div>
        </div>
        <div className="columns is-vcentered">
          <div className="column is-one-fifth">Hasta</div>
          <div className="column is-four-fifths">
            <TextField
              key="dateEnd"
              value={dateEnd}
              type="date"
              onChange={setValue('dateEnd')}
            />
          </div>
        </div>
      </div>
    );
  }
}

BasicForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
};

BasicForm.defaultProps = {
};

export default connect(
  state => ({
    courses: state.courses.list,
    loading: state.courses.loading,
  }),
)(BasicForm);
