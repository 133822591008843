import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Title from 'ui/Title';
import MassiveForm from './Form/MassiveForm';
import MassiveFormGrid from './Form/MassiveFormGrid';

import {
  reqGetAllModuleCodes,
  reqUpdateAllModuleCodes,
} from '$redux/course-codes/actions';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idSemester: null,
      error: '',
      init: true,
    };
  }

  componentDidUpdate(PrevProps, prevState) {
    const { idSemester } = this.state;
    if (idSemester !== prevState.idSemester) {
      if (parseInt(idSemester, 10) > 0) {
        this.getAllCoursesBySemesterAndCourse();
      }
    }
  }

  set = (key, value) => {
    this.setState({
      [key]: value,
    });
  }

  getAllCoursesBySemesterAndCourse = () => {
    const {
      dispatch,
    } = this.props;

    const {
      idSemester,
    } = this.state;

    if (idSemester === null) {
      alert('Tienes que seleccionar un trimestre');
    } else {
      dispatch(reqGetAllModuleCodes(idSemester));
    }
  }

  reqUpdateAllModuleCodes = () => {
    const { dispatch, data } = this.props;
    const { idSemester } = this.state;
    const dataToSend = {
      data,
      idSemester,
    };
    dispatch(reqUpdateAllModuleCodes(dataToSend));
  };

  onUpdate = (idModule, code, isM01) => {
    const { dispatch } = this.props;
    const { idSemester } = this.state;
    const bindingObject = {
      idSemester,
      modules: [
        {
          idModule,
          code,
          isM01,
        },
      ],
    };
    dispatch(reqUpdateAllModuleCodes(bindingObject));
  }

  render() {
    const {
      error,
      idCourse,
      idSemester,
      init,
    } = this.state;

    const {
      data,
      result,
      loading,
    } = this.props;

    if (!init && result && result === true) {
      return (
        <div>
          <Title>Asignación Codigos Modules a los Modulos</Title>
          <center>Se han asignado todos los códigos correctamente</center>
        </div>
      );
    }

    return (
      <div>
        <Title>Asignación Codigos Modulos</Title>
        <MassiveForm
          set={this.set}
          error={error}
          idCourse={idCourse}
          idSemester={idSemester}
        />
        <p>&nbsp;</p>
        {loading || (!idSemester || idSemester === '0') || data.length === 0 ? ''
          : (
            <MassiveFormGrid
              data={data}
              loading={loading}
              onUpdate={this.onUpdate}
            />
          )
        }
      </div>
    );
  }
}

Index.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.array,
  result: PropTypes.string,
  loading: PropTypes.bool.isRequired,
};

Index.defaultProps = {
  data: [],
  result: null,
};

export default connect(
  state => ({
    data: state.courseCodes.data,
    loading: state.courseCodes.loading,
  }),
)(Index);
