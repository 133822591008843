import styled from 'styled-components';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

const leftPad = 20;
const activeLineWidth = 4;

export const Wrapper = styled.li`
  margin-bottom: 15px;
  letter-spacing: 1px;

  > a {
    display: flex;
    align-items: center;
    padding: ${activeLineWidth}px 0;
    color: #777;

    &.active {
      border-left: 6px solid #f6bf26;
      padding-left: ${leftPad}px;
      font-weight: 700;
      color: #000;
    }
  }
`;

export const SubMenuItems = styled.ul`
  margin-bottom: 15px;
  padding-left: ${leftPad + activeLineWidth}px;
`;

export const DropdownIcon = styled(FontAwesomeIcon)`
  margin-right: 20px;
`;

export const Text = styled.span`
  flex: 1;
`;
