import styled from 'styled-components';

const warningColor = '#eb3124';
const academyUpdatedColor = '#00B525';
const linkiaUpdatedColor = '#F99B27';

export const WarningCell = styled.div`
  display: flex;
  align-items: center;

  color: ${warningColor};
  font-weight: 700;

  .triangle {
    margin-left: 10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 17.3px 10px;
    border-color: transparent transparent ${warningColor} transparent;

    position: relative;
    > div {
      color: #fff;
      font-size: 10px;

      position: absolute;
      top: 3px;
      left: 0;
      right: 0;

      display: flex;
      justify-content: center;
    }
  }
`;

export const LinkiaUpdatedCell = styled.div`
  display: flex;
  align-items: center;

  color: ${linkiaUpdatedColor};
  font-weight: 700;

  .dot {
    margin-left: 10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-radius: 50%;
    background-color: ${linkiaUpdatedColor};

    position: relative;
  }
`;

export const AcademyUpdatedCell = styled.div`
  display: flex;
  align-items: center;

  color: ${academyUpdatedColor};
  font-weight: 700;

  .dot {
    margin-left: 10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-radius: 50%;
    background-color: ${academyUpdatedColor};

    position: relative;
  }
`;
