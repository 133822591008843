import {
  REQUEST_GET_ALL_MODULE_CODES,
  REQUEST_GET_ALL_MODULE_CODES_SUCCEEDED,
  REQUEST_GET_ALL_MODULE_CODES_FAILED,
  REQUEST_UPDATE_ALL_MODULE_CODES_SUCCEEDED,
  REQUEST_UPDATE_ALL_MODULE_CODES_FAILED,
} from './action-types';

import utils from '../utils';

const initialState = {
  data: [],
  totals: {},
  loading: false,
};

const courseCodeReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case REQUEST_GET_ALL_MODULE_CODES:
    case REQUEST_GET_ALL_MODULE_CODES_SUCCEEDED: {
      const {
        data,
      } = action;
      return {
        ...state,
        data,
        loading: false,
      };
    }
    case REQUEST_UPDATE_ALL_MODULE_CODES_SUCCEEDED: {
      const {
        result,
      } = action;
      return {
        ...state,
        result,
        loading: false,
      };
    }

    case REQUEST_UPDATE_ALL_MODULE_CODES_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error inesperado',
        message: 'No se han podido crear las aulas!',
      });
      return {
        ...state,
        loading: false,
      };
    }

    case REQUEST_GET_ALL_MODULE_CODES_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error',
        message: 'Ha ocurrido un error, no se han podido recoger los modulos',
      });
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default courseCodeReducer;
