import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  TextField,
  Button,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import DateRangePicker from 'ui/DateRangePicker';

import { Wrapper } from './Editor.styles';

moment.locale('es');

class Editor extends React.Component {
  state = {
    dates: {
      enrollmentFocusedInput: null,
      courseFocusedInput: null,
    },
  }

  onSend = () => {
    const { onSend } = this.props;

    // run parent's onSend
    onSend();

    // auto close when done
    // onClose();
  }

  setEnrollmentDates = ({ startDate, endDate }) => {
    const { set } = this.props;
    set('startEnrollmentDate', startDate);
    set('finishEnrollmentDate', endDate);
  }

  setCourseDates = ({ startDate, endDate }) => {
    const { set } = this.props;
    set('startCourseDate', startDate);
    set('finishCourseDate', endDate);
  }

  render() {
    const {
      action,
      data: {
        name,
        startEnrollmentDate,
        finishEnrollmentDate,
        startCourseDate,
        finishCourseDate,
        semester,
        courseYear,
      },
      set,
      onClose,
    } = this.props;
    const { dates } = this.state;

    return (
      <Wrapper>
        <div className="columns is-bottom-aligned">
          <div className="column is-2">
            <TextField
              label="Nombre"
              fullWidth
              value={name}
              onChange={e => set('name', e.target.value)}
            />
          </div>
          <div className="column is-3">
            <DateRangePicker
              label="Rango de fechas"
              startDate={startEnrollmentDate ? moment(startEnrollmentDate) : null}
              startDateId="start_date"
              endDate={finishEnrollmentDate ? moment(finishEnrollmentDate) : null}
              endDateId="end_date"
              onDatesChange={newDates => this.setEnrollmentDates(newDates)}
              focusedInput={dates.enrollmentFocusedInput}
              onFocusChange={enrollmentFocusedInput => (
                this.setState({ dates: { enrollmentFocusedInput } })
              )}
            />
          </div>
          <div className="column is-3">
            <DateRangePicker
              label="Rango de fechas"
              startDate={startCourseDate ? moment(startCourseDate) : null}
              startDateId="start_date"
              endDate={finishCourseDate ? moment(finishCourseDate) : null}
              endDateId="end_date"
              onDatesChange={newDates => this.setCourseDates(newDates)}
              focusedInput={dates.courseFocusedInput}
              onFocusChange={courseFocusedInput => (
                this.setState({ dates: { courseFocusedInput } })
              )}
            />
          </div>
          <div className="column is-1">
            <FormControl fullWidth>
              <InputLabel>Semestre</InputLabel>
              <Select
                value={semester}
                onChange={e => set('semester', e.target.value)}
              >
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="2">2</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="column is-1">
            <FormControl fullWidth>
              <TextField
                label="Curso"
                fullWidth
                value={courseYear}
                onChange={e => set('courseYear', e.target.value)}
              />
            </FormControl>
          </div>
          <div className="column is-2 form-actions">
            {
              action === 'edit' ? (
                <Button color="primary" onClick={this.onSend}>Guardar</Button>
              ) : (
                <Button color="secondary" onClick={this.onSend}>Crear</Button>
              )
            }
            <Button className="close-button" onClick={onClose}>X</Button>
          </div>
        </div>
      </Wrapper>
    );
  }
}

Editor.propTypes = {
  action: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  set: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Editor;
