import {
  REQUEST_FAILED,
  LIST,
  LIST_SUCCEEDED,
  GET,
  GET_SUCCEEDED,
  UPDATE,
  UPDATE_SUCCEEDED,
  REMOVE,
  REMOVE_SUCCEEDED,
  CREATE,
  CREATE_SUCCEEDED,
  SET,
  RESET,
  SWITCH,
  SWITCH_SUCCEEDED,
  GET_METHODOLOGIES,
  GET_METHODOLOGIES_SUCCEEDED,
  ACTIVATE_TP,
  ACTIVATE_TP_SUCCESS,
  SHOW_OLD_TP,
  SHOW_OLD_TP_SUCCESS,
  DELETE_MODULE,
  MODIFY_MOODLE_CODE_TP,
  MODIFY_MOODLE_CODE_TP_SUCCESS,
} from './action-types';

export const requestFailed = error => ({ type: REQUEST_FAILED, error });
export const list = () => ({ type: LIST });
export const listSucceeded = data => ({ type: LIST_SUCCEEDED, data });
export const get = id => ({ type: GET, id });
export const getSucceeded = data => ({ type: GET_SUCCEEDED, data });
export const update = (id, data) => ({ type: UPDATE, id, data });
export const updateSucceeded = success => ({ type: UPDATE_SUCCEEDED, success });
export const remove = id => ({ type: REMOVE, id });
export const removeSucceeded = success => ({ type: REMOVE_SUCCEEDED, success });
export const create = data => ({ type: CREATE, data });
export const createSucceeded = success => ({ type: CREATE_SUCCEEDED, success });
export const set = (key, value) => ({ type: SET, key, value });
export const reset = () => ({ type: RESET });
export const switchState = (id, data) => ({ type: SWITCH, id, data });
export const switchStateSucceeded = data => ({ type: SWITCH_SUCCEEDED, data });
export const getMethodologies = () => ({ type: GET_METHODOLOGIES });
export const getMethodologiesSucceeded = data => ({ type: GET_METHODOLOGIES_SUCCEEDED, data });
export const activateTrainingPlan = (idTrainingPlan, active, idCourse) => ({
  type: ACTIVATE_TP, active, idTrainingPlan, idCourse,
});
export const activateTrainingPlanSucceeded = () => ({ type: ACTIVATE_TP_SUCCESS });
export const deleteModule = (idRoadMap, idModule, id) => ({
  type: DELETE_MODULE, idRoadMap, idModule, id,
});
export const modifyMoodleCode = (idTrainingPlan, moodleCode, idCourse) => ({
  type: MODIFY_MOODLE_CODE_TP, moodleCode, idTrainingPlan, idCourse,
});
export const modifyMoodleCodeSucceeded = () => ({ type: MODIFY_MOODLE_CODE_TP_SUCCESS });
export const showOldTrainingPlan = (idTrainingPlan, active, idCourse) => ({
  type: SHOW_OLD_TP, active, idTrainingPlan, idCourse,
});
export const showOldTrainingPlanSucceeded = () => ({ type: SHOW_OLD_TP_SUCCESS });
