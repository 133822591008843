import {
  ADD_SERVICES_ACTION_BULK,
  ADD_SERVICES_ACTION_BULK_FAILED,
  ADD_SERVICES_ACTION_BULK_SUCCEED,
  ADD_SERVICES_ACTION_USER,
  ADD_SERVICES_ACTION_USER_FAILED,
  ADD_SERVICES_ACTION_USER_SUCCEED,
  GET_SERVICES_ACTIONS_FILTER_FILTERED,
  GET_SERVICES_ACTIONS_FILTER_FILTERED_FAILED,
  GET_SERVICES_ACTIONS_FILTER_FILTERED_SUCCEED,
  GET_SERVICES_ACTIONS_LOGS_FILTER_FILTERED,
  GET_SERVICES_ACTIONS_LOGS_FILTER_FILTERED_FAILED,
  GET_SERVICES_ACTIONS_LOGS_FILTER_FILTERED_SUCCEED,
  GET_SERVICES_ACTIONS_WAITING,
  GET_SERVICES_ACTIONS_WAITING_FAILED,
  GET_SERVICES_ACTIONS_WAITING_SUCCEED,
  GET_SERVICES_EXPORT,
  GET_SERVICES_EXPORT_FAILED,
  GET_SERVICES_EXPORT_SUCCEEDED,
  GET_SERVICES_FILTER_FILTERED,
  GET_SERVICES_FILTER_FILTERED_FAILED,
  GET_SERVICES_FILTER_FILTERED_SUCCEED,
  GET_SERVICES_LOG_FILTER_FILTERED,
  GET_SERVICES_LOG_FILTER_FILTERED_FAILED,
  GET_SERVICES_LOG_FILTER_FILTERED_SUCCEED,
  GET_SERVICES_STUDENT_GRADES_FILTER_FILTERED,
  GET_SERVICES_STUDENT_GRADES_FILTER_FILTERED_FAILED,
  GET_SERVICES_STUDENT_GRADES_FILTER_FILTERED_SUCCEED,
  REMOVE_SELECTIONS,
  REMOVE_SERVICES_ACTION_STATUS,
  REMOVE_SERVICES_ACTION_STATUS_FAILED,
  REMOVE_SERVICES_ACTION_STATUS_SUCCEED,
  RESET_SERVICES_ACTIONS_WAITING,
  RESET_SERVICES_ACTIONS_WAITING_FAILED,
  RESET_SERVICES_ACTIONS_WAITING_SUCCEED,
  SET_SELECTION,
  UPDATE_SERVICES_ACTION_STATUS,
  UPDATE_SERVICES_ACTION_STATUS_FAILED,
  UPDATE_SERVICES_ACTION_STATUS_SUCCEED,
} from './action-types';

//

export const requestGetServicesFilterFiltered = bindingObject => ({
  type: GET_SERVICES_FILTER_FILTERED,
  bindingObject,
});
export const requestGetServicesFilterFilteredSucceeded = ({
  services: {
    data,
    pages,
  },
}, bindingObject) => (
  {
    type: GET_SERVICES_FILTER_FILTERED_SUCCEED,
    data,
    pages,
    bindingObject,
  }
);
export const requestGetServicesFilterFilteredFailed = () => ({
  type: GET_SERVICES_FILTER_FILTERED_FAILED,
});

//

export const requestGetServicesLogFilterFiltered = bindingObject => ({
  type: GET_SERVICES_LOG_FILTER_FILTERED,
  bindingObject,
});
export const requestGetServicesLogFilterFilteredSucceeded = ({
  services: {
    data,
    pages,
  },
}, bindingObject) => (
  {
    type: GET_SERVICES_LOG_FILTER_FILTERED_SUCCEED,
    data,
    pages,
    bindingObject,
  }
);
export const requestGetServicesLogFilterFilteredFailed = () => ({
  type: GET_SERVICES_LOG_FILTER_FILTERED_FAILED,
});

//

export const requestGetServicesActionFilterFiltered = bindingObject => ({
  type: GET_SERVICES_ACTIONS_FILTER_FILTERED,
  bindingObject,
});
export const requestGetServicesActionFilterFilteredSucceeded = ({
  services: {
    data,
    pages,
  },
}, bindingObject) => (
  {
    type: GET_SERVICES_ACTIONS_FILTER_FILTERED_SUCCEED,
    data,
    pages,
    bindingObject,
  }
);
export const requestGetServicesActionFilterFilteredFailed = () => ({
  type: GET_SERVICES_ACTIONS_FILTER_FILTERED_FAILED,
});

//

export const requestGetServicesActionsLogsFilterFiltered = bindingObject => ({
  type: GET_SERVICES_ACTIONS_LOGS_FILTER_FILTERED,
  bindingObject,
});
export const requestGetServicesActionsLogsFilterFilteredSucceeded = ({
  services: {
    data,
    pages,
  },
}, bindingObject) => (
  {
    type: GET_SERVICES_ACTIONS_LOGS_FILTER_FILTERED_SUCCEED,
    data,
    pages,
    bindingObject,
  }
);
export const requestGetServicesActionsLogsFilterFilteredFailed = () => ({
  type: GET_SERVICES_ACTIONS_LOGS_FILTER_FILTERED_FAILED,
});

//

export const requestGetServicesStudentGradesFilterFiltered = bindingObject => ({
  type: GET_SERVICES_STUDENT_GRADES_FILTER_FILTERED,
  bindingObject,
});
export const requestGetServicesStudentGradesFilterFilteredSucceeded = ({
  services: {
    data,
    pages,
  },
}, bindingObject) => (
  {
    type: GET_SERVICES_STUDENT_GRADES_FILTER_FILTERED_SUCCEED,
    data,
    pages,
    bindingObject,
  }
);
export const requestGetServicesStudentGradesFilterFilteredFailed = () => ({
  type: GET_SERVICES_STUDENT_GRADES_FILTER_FILTERED_FAILED,
});

//

export const requestAddServicesActionUser = bindingObject => ({
  type: ADD_SERVICES_ACTION_USER,
  bindingObject,
});
export const requestAddServicesActionUserSucceeded = success => ({
  type: ADD_SERVICES_ACTION_USER_SUCCEED,
  success,
});
export const requestAddServicesActionUserFailed = () => ({
  type: ADD_SERVICES_ACTION_USER_FAILED,
});

export const requestGetServicesExport = bindingObject => ({
  type: GET_SERVICES_EXPORT,
  bindingObject,
});
export const requestGetServicesExportSucceeded = data => (
  {
    type: GET_SERVICES_EXPORT_SUCCEEDED,
    data,
  });
export const requestGetServicesExportFailed = () => ({ type: GET_SERVICES_EXPORT_FAILED });

export const requestAddServicesActionBulk = bindingObject => ({
  type: ADD_SERVICES_ACTION_BULK,
  bindingObject,
});
export const requestAddServicesActionBulkSucceeded = success => ({
  type: ADD_SERVICES_ACTION_BULK_SUCCEED,
  success,
});
export const requestAddServicesActionBulkFailed = () => ({
  type: ADD_SERVICES_ACTION_BULK_FAILED,
});

export const requestGetServicesActionsWaiting = bindingObject => ({
  type: GET_SERVICES_ACTIONS_WAITING,
  bindingObject,
});
export const requestGetServicesActionsWaitingSucceeded = ({
  services: {
    data,
    pages,
  },
}, bindingObject) => (
  {
    type: GET_SERVICES_ACTIONS_WAITING_SUCCEED,
    data,
    pages,
    bindingObject,
  }
);
export const requestGetServicesActionsWaitingFailed = () => ({
  type: GET_SERVICES_ACTIONS_WAITING_FAILED,
});

export const setSelection = (id, value) => ({
  type: SET_SELECTION,
  id,
  value,
});
export const removeSelections = () => ({ type: REMOVE_SELECTIONS });

export const requestResetServicesActionsWaiting = idUserService => ({
  type: RESET_SERVICES_ACTIONS_WAITING,
  idUserService,
});
export const requestResetServicesActionsWaitingSucceeded = success => ({
  type: RESET_SERVICES_ACTIONS_WAITING_SUCCEED,
  success,
});
export const requestResetServicesActionsWaitingFailed = ({ error }) => ({
  type: RESET_SERVICES_ACTIONS_WAITING_FAILED,
  error,
});
export const requestChangeStatusActionService = ({
  id,
  status,
}) => ({
  type: UPDATE_SERVICES_ACTION_STATUS,
  id,
  status,
});
export const requestChangeStatusActionServiceSucceeded = success => ({
  type: UPDATE_SERVICES_ACTION_STATUS_SUCCEED,
  success,
});
export const requestChangeStatusActionServiceFailed = error => ({
  type: UPDATE_SERVICES_ACTION_STATUS_FAILED,
  error,
});
export const requestRemoveActionService = id => ({
  type: REMOVE_SERVICES_ACTION_STATUS,
  id,
});
export const requestRemoveActionServiceSucceeded = success => ({
  type: REMOVE_SERVICES_ACTION_STATUS_SUCCEED,
  success,
});
export const requestRemoveActionServiceFailed = error => ({
  type: REMOVE_SERVICES_ACTION_STATUS_FAILED,
  error,
});
