import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
  Button,
} from '@material-ui/core';

import SubTitle from 'ui/SubTitle';
import Select from '@material-ui/core/Select';

import { Wrapper } from './styles';

import BasicForm from './BasicForm';
import CoursesForm from './CoursesForm';
import BillingFormNew from './BillingFormNew';
import BillingFormTable from './BillingFormTable';

// eslint-disable-next-line
class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      semester: undefined, // eslint-disable-line
    };
  }

  handleChange = name => (event) => {
    event.preventDefault();
    event.persist();
    const { set } = this.props;
    set('selectedSemester', event.target.value);
    this.setState({
      [name]: event.target.value,
    });
  }


  render() {
    const {
      action,
      data,
      set,
      onSend,
      semesters,
    } = this.props;
    const semesterList = semesters
      .map(
        element => (
          <option key={element.name} value={element.idSemester}>{element.name}</option>
        ),
      );

    let table;
    if (data.billingRules && data.billingRules.length > 0) {
      table = <BillingFormTable set={set} data={data} />;
    }
    return (
      <Wrapper>
        <SubTitle>Información del centro</SubTitle>
        <BasicForm data={data} set={set} />

        <SubTitle>Ciclos que se imparten en el centro</SubTitle>
        <CoursesForm data={data} set={set} />
        <SubTitle>Escalonado de facturación
          <Select
            native
            onChange={this.handleChange('semester')}
            className="filter-item"
            value={data.selectedSemester}
          >
            <option value={0}>Semestre</option>
            {semesterList}
          </Select>
        </SubTitle>
        <BillingFormNew set={set} data={data} />
        {table}

        <div className="form-actions">
          {
            action === 'edit' ? (
              <Button color="primary" onClick={onSend}>Actualizar</Button>
            ) : (
              <Button color="secondary" onClick={onSend}>Crear</Button>
            )
          }
          <Link to="/centros"><Button>Volver</Button></Link>
        </div>
      </Wrapper>
    );
  }
}

Form.propTypes = {
  action: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  set: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  semesters: PropTypes.array.isRequired,
};

export default Form;
