import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Wrapper } from './styles';
import Title from '../../../../ui/Title';
import SubTitle from '../../../../ui/SubTitle';
import { connect } from 'react-redux';
import Loading from '$routes/Loading';


import { requestGetServicesFilterFiltered as requestServices } from '$redux/services/actions';

class ServiceForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { accountInfo } = this.props;
    if (accountInfo) {
      this.fetchData();
    }
  }

  fetchData() {
    const { accountInfo } = this.props;
    const { dispatch } = this.props;
    const bindingObject = {
      pageSize: 1,
      page: 0,
      sorted: [],
      filters: {
        idUser: accountInfo.idUser,
      },
    };
    dispatch(
      requestServices(bindingObject, 'services'),
    );
  }

  render() {
    let outlookTab;
    let ldap;
    let adobe;
    let alexia;
    const {
      loading,
      data,
    } = this.props;

    if (loading) return <Loading />;

    function toColumnValue(value) {
      if (value === 0 || value === '0') {
        return 'waiting';
      }
      if (value === 1 || value === '1') {
        return 'error';
      }
      if (value === 2 || value === '2') {
        return 'success';
      }

      return '---';
    }

    function toColumn(label, value) {
      return (
        <div className="column is-half" key={label}>
          <div className="columns is-vcentered">
            <div className="column is-two-fifths">
              <Typography>{label}</Typography>
            </div>
            <div className="column is-three-fifths">
              {toColumnValue(value)}
            </div>
          </div>
        </div>);
    }

    if (data && data[0]) {
      // const { idUserService } = data[0];
      outlookTab = ([
        toColumn('outlook', data[0].outlook),
        toColumn('outlookCheck', data[0].outlookCheck),
        toColumn('outlookGroup', data[0].outlookGroup),
        toColumn('outlookLicense', data[0].outlookLicense),
      ]);
      ldap = ([
        toColumn('ldap', data[0].ldap),
        toColumn('ldapCheck', data[0].ldapCheck),
      ]);
      adobe = ([
        toColumn('adobe', data[0].adobe),
        toColumn('adobeCheck', data[0].adobeCheck),
      ]);
      alexia = ([
        toColumn('alexia', data[0].alexia),
        toColumn('alexiaCheck', data[0].alexiaCheck),
        toColumn('alexiaExtra', data[0].alexiaExtra),
        toColumn('alexiaEnrollment', data[0].alexiaEnrollment),
        toColumn('alexiaCohort', data[0].alexiaCohort),
        toColumn('alexia101', data[0].alexia101),
      ]);
    }

    const { title } = this.props;

    return (
      <Wrapper>
        <div className="student-data">
          <Title>
            {title},
            {data && data[0] && data[0].idUserService
              ? ([
                <Link to={`/servicios/acciones/${data[0].idUserService}`} key="linkAcciones">
                  <Button>GESTIONAR ACCIONES</Button>
                </Link>,
              ])
              : ''
            },
          </Title>
          <SubTitle key="outlookTab">Outlook</SubTitle>
          <div className="columns is-multiline is-narrow content">
            {outlookTab}
          </div>
          <SubTitle key="ldapTab">Ldap</SubTitle>
          <div className="columns is-multiline is-narrow content">
            {ldap}
          </div>
          <SubTitle key="adobeTab">Adobe</SubTitle>
          <div className="columns is-multiline is-narrow content">
            {adobe}
          </div>
          <SubTitle key="moodleTab">Moodle</SubTitle>
          <div className="columns is-multiline is-narrow content">
            {alexia}
          </div>
          <SubTitle key="actionTab">Action</SubTitle>
        </div>
      </Wrapper>);
  }
}

ServiceForm.propTypes = {
  title: PropTypes.string.isRequired,
  accountInfo: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.array,
};

ServiceForm.defaultProps = {
  accountInfo: undefined,
  data: undefined,
};

export default connect(
  state => ({
    data: state.services.data,
    loading: state.services.loading,
    pages: state.services.pages,
  }),
)(ServiceForm);
