import {
  LIST_STAFF,
  LIST_STAFF_SUCCEEDED,
  LIST_STAFF_FAILED,
  GET_STAFF,
  GET_STAFF_SUCCEEDED,
  GET_STAFF_FAILED,
  UPDATE_STAFF,
  UPDATE_STAFF_SUCCEEDED,
  UPDATE_STAFF_FAILED,
  REMOVE_STAFF,
  REMOVE_STAFF_SUCCEEDED,
  REMOVE_STAFF_FAILED,
  CREATE_STAFF,
  CREATE_STAFF_SUCCEEDED,
  CREATE_STAFF_FAILED,
  SET_STAFF_VALUE,
  RESET_STAFF_VALUE,
  CHANGE_STAFF_PASSWORD,
  CHANGE_STAFF_PASSWORD_SUCCEEDED,
  CHANGE_STAFF_PASSWORD_FAILED,
  GET_STAFF_PROPS,
  GET_STAFF_PROPS_SUCCEEDED,
  GET_STAFF_PROPS_FAILED,
} from './action-types';

export const list = () => ({ type: LIST_STAFF });
export const listSucceeded = data => ({ type: LIST_STAFF_SUCCEEDED, data });
export const listFailed = error => ({ type: LIST_STAFF_FAILED, error });

export const getProps = () => ({ type: GET_STAFF_PROPS });
export const getPropsSucceeded = data => ({ type: GET_STAFF_PROPS_SUCCEEDED, data });
export const getPropsFailed = error => ({ type: GET_STAFF_PROPS_FAILED, error });

export const get = id => ({ type: GET_STAFF, id });
export const getSucceeded = data => ({ type: GET_STAFF_SUCCEEDED, data });
export const getFailed = error => ({ type: GET_STAFF_FAILED, error });

export const update = (id, data) => ({ type: UPDATE_STAFF, id, data });
export const updateSucceeded = success => ({ type: UPDATE_STAFF_SUCCEEDED, success });
export const updateFailed = error => ({ type: UPDATE_STAFF_FAILED, error });

export const remove = id => ({ type: REMOVE_STAFF, id });
export const removeSucceeded = success => ({ type: REMOVE_STAFF_SUCCEEDED, success });
export const removeFailed = error => ({ type: REMOVE_STAFF_FAILED, error });

export const create = data => ({ type: CREATE_STAFF, data });
export const createSucceeded = success => ({ type: CREATE_STAFF_SUCCEEDED, success });
export const createFailed = error => ({ type: CREATE_STAFF_FAILED, error });

export const set = (key, value) => ({ type: SET_STAFF_VALUE, key, value });
export const reset = () => ({ type: RESET_STAFF_VALUE });

export const redoStaffPassword = email => ({
  type: CHANGE_STAFF_PASSWORD, email,
});
export const redoStaffPasswordSucceeded = () => ({ type: CHANGE_STAFF_PASSWORD_SUCCEEDED });
export const redoStaffPasswordFailed = () => ({ type: CHANGE_STAFF_PASSWORD_FAILED });
