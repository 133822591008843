import styled from 'styled-components';

export const Wrapper = styled.div`
  .layout {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 10px;
  }

  .column-group {
    background-color: red;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 1px;
    background-color: #eeeeee;
    border-radius: 8px;
  }

  .button-inactive {
    background-color: #888888 !important;
  }

  .buttons {
    padding-top: 8px;
    display: flex;
    width: 100%;
    justify-content: space-between;

    button {
      background-color: #6896c8;
      color: #ffffff;

      &:hover {
        background-color: #000000;
        color: #6896c8;
      }
    }
  }
`;
