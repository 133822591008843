export const FILTERS_REQUEST = 'STUDENT_FILTERS_REQUEST';
export const FILTERS_REQUEST_SUCCEEDED = 'STUDENT_FILTERS_REQUEST_SUCCEEDED';
export const FILTERS_REQUEST_FAILED = 'STUDENT_FILTERS_REQUEST_FAILED';

export const FILTERED_REQUEST = 'STUDENT_FILTERED_REQUEST';
export const FILTERED_REQUEST_SUCCEEDED = 'STUDENT_FILTERED_REQUEST_SUCCEEDED';
export const FILTERED_REQUEST_FAILED = 'STUDENT_FILTERED_REQUEST_FAILED';

export const ROADMAPS_REQUEST = 'STUDENT_ROADMAPS_REQUEST';
export const ROADMAPS_REQUEST_SUCCEEDED = 'STUDENT_ROADMAPS_REQUEST_SUCCEEDED';
export const ROADMAPS_REQUEST_FAILED = 'STUDENT_ROADMAPS_REQUEST_FAILED';

export const INFO_REQUEST = 'STUDENT_INFO_REQUEST';
export const INFO_REQUEST_SUCCEEDED = 'STUDENT_INFO_REQUEST_SUCCEEDED';
export const INFO_REQUEST_FAILED = 'STUDENT_INFO_REQUEST_FAILED';

export const SET = 'STUDENT_SET';

export const UPDATE_REQUEST = 'STUDENT_UPDATE_REQUEST';
export const UPDATE_REQUEST_SUCCEEDED = 'STUDENT_UPDATE_REQUEST_SUCCEEDED';
export const UPDATE_REQUEST_FAILED = 'STUDENT_UPDATE_REQUEST_FAILED';

export const CLEAN_FILTERS_REQUEST = 'STUDENT_CLEAN_FILTERS_REQUEST';

export const ENROLLMENT_DOWNLOAD_REQUEST = 'STUDENT_ENROLLMENT_DOWNLOAD_REQUEST';
export const ENROLLMENT_DOWNLOAD_REQUEST_SUCCEEDED = 'STUDENT_ENROLLMENT_DOWNLOAD_REQUEST_SUCCEEDED';

export const REQUEST_ACAD_EXPORT = 'STUDENT_REQUEST_ACAD_EXPORT';
export const REQUEST_ACAD_EXPORT_SUCCEEDED = 'STUDENT_REQUEST_ACAD_EXPORT_SUCCEEDED';
export const REQUEST_ACAD_EXPORT_FAILED = 'STUDENT_REQUEST_ACAD_EXPORT_FAILED';
