import React from 'react';
import PropTypes from 'prop-types';

import { WarningCell } from './Grid.helpers.styles';

const WarningValue = ({ children }) => (
  <WarningCell>{children} <div className="triangle"><div>!</div></div></WarningCell>
);
WarningValue.propTypes = { children: PropTypes.any.isRequired };

export { WarningValue };
