export const REQUEST = 'BILLING_LIST_REQUEST';
export const REQUEST_SUCCEEDED = 'BILLING_LIST_REQUEST_SUCCEEDED';
export const REQUEST_FAILED = 'BILLING_LIST_REQUEST_FAILED';

export const FILTERS_REQUEST = 'BILLING_FILTERS_REQUEST';
export const FILTERS_REQUEST_SUCCEEDED = 'BILLING_FILTERS_REQUEST_SUCCEEDED';
export const FILTERS_REQUEST_FAILED = 'BILLING_FILTERS_REQUEST_FAILED';

export const REQUEST_EXPORT = 'BILLING_EXPORT_REQUEST';
export const REQUEST_EXPORT_SUCCEEDED = 'BILLING_EXPORT_REQUEST_SUCCEEDED';
export const REQUEST_EXPORT_FAILED = 'BBILLING_EXPORT_REQUEST_FAILED';

export const SET = 'BILLING_SET';
