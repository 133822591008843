import styled from 'styled-components';

export const Wrapper = styled.h2`
  margin: 0;
  padding-bottom: 20px;
  border-bottom: 1px solid #000;
  margin-bottom: 20px;
  margin-top: 25px;

  font-size: 1.4em;
  font-weight: 400;

  display: flex;
  justify-content: space-between;

  button {
    color: #6896c8;
    margin-bottom: -10px;
  }
`;
