import {
  FILTERS_REQUEST,
  FILTERS_REQUEST_SUCCEEDED,
  FILTERS_REQUEST_FAILED,
  CLEAN_FILTERS_REQUEST,
  GET_ENROLLMENTS_FILTERED_WITH_SERVICES,
  GET_ENROLLMENTS_FILTERED_WITH_SERVICES_SUCCEEDED,
  GET_ENROLLMENTS_FILTERED_WITH_SERVICES_FAILED,
} from './action-types';

export const filtersRequest = () => ({ type: FILTERS_REQUEST });
export const filtersRequestSucceeded = filtersData => ({
  type: FILTERS_REQUEST_SUCCEEDED,
  filtersData,
});
export const filtersRequestFailed = () => ({ type: FILTERS_REQUEST_FAILED });

export const cleanFiltersRequest = () => ({ type: CLEAN_FILTERS_REQUEST });

export const requestGetEnrollmentsWithServices = (bindingObject, origin) => ({
  type: GET_ENROLLMENTS_FILTERED_WITH_SERVICES, bindingObject, origin,
});
export const requestGetEnrollmentsWithServicesSucceeded = ({
  enrollment: { data, pages },
}, origin, bindingObject) => ({
  type: GET_ENROLLMENTS_FILTERED_WITH_SERVICES_SUCCEEDED, data, pages, origin, bindingObject,
});
export const requestGetEnrollmentsWithServicesFailed = () => ({
  type: GET_ENROLLMENTS_FILTERED_WITH_SERVICES_FAILED,
});
