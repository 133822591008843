import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import faTimes from '@fortawesome/fontawesome-free-solid/faTimes';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import SubTitle from 'ui/SubTitle';
import {
  Button,
} from '@material-ui/core';
import swal from 'sweetalert2';
import ModuleRow from './ModuleRow';
import UnitTable from './UnitTable';
import ModuleReqAdd from './ModuleReqAdd';
import ModuleReqTable from './ModuleReqTable';
import ModuleReqAddUnits from './ModuleReqAddUnits';
import ModuleReqTableUnits from './ModuleReqTableUnits';
import { Wrapper } from './EditModule.styles';

import {
  get as getModule,
  addUnit,
  edit as editModule,
  create as createModule,
  setRequirements,
  setUnitRequirements,
  deleteUnit,
} from '$redux/modules/actions';
import Loading from '../../../Loading';

class Module extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: undefined,
      name: undefined,
      onLineHours: undefined,
      inClassHours: undefined,
      onLineHourPrice: undefined,
      inClassHourPrice: undefined,
      price: undefined,
      educationalLevel: '1', // eslint-disable-line
    };
  }

  componentDidMount() {
    const { dispatch, moduleToShow } = this.props;
    dispatch(getModule(moduleToShow)); // eslint-disable-line
  }

  deleteUnit = async (id) => {
    const { dispatch, moduleToShow } = this.props;
    dispatch(deleteUnit(id, moduleToShow));
  }

  addUnit = (unitObject) => {
    const { dispatch } = this.props;
    dispatch(addUnit(unitObject));
  }

  edit = (moduleEdit) => {
    const { dispatch, data } = this.props;
    dispatch(editModule(data.idModule, moduleEdit));
  }

  create = (moduleObject) => {
    const { dispatch, methodology } = this.props;
    dispatch(createModule({ ...moduleObject, idTrainingPlan: methodology.idTrainingPlan }));
  }

  setRequirements = (newList) => {
    const { dispatch } = this.props;
    dispatch(setRequirements(newList));
  }

  setUnitRequirements = (newList) => {
    const { dispatch } = this.props;
    dispatch(setUnitRequirements(newList));
  }

  set = (name, value) => {
    const stateReplica = {};
    stateReplica[name] = value;
    this.setState(stateReplica);
  }

  save = (moduleEdit) => {
    const { moduleToShow } = this.props;
    if (moduleToShow) {
      this.edit(moduleEdit);
    } else {
      const {
        code,
        name,
        onLineHours,
        inClassHours,
        onLineHourPrice,
        inClassHourPrice,
        price,
      } = this.state;
      if (code
        && name
        && onLineHours
        && inClassHours
        && onLineHourPrice
        && inClassHourPrice
        && price) {
        this.create(moduleEdit);
      } else {
        swal({
          type: 'warning',
          title: 'Campos vacíos',
          text: 'Todos los campos deben ser rellenados para crear un módulo',
        });
      }
    }
  }

  render() {
    const {
      showModal,
      data,
      educationalLevels,
      moduleToShow,
      methodology,
      loadingSingle,
    } = this.props;
    const showHideClassName = showModal ? 'modal display-block' : 'modal display-none';
    if (loadingSingle) return <Loading />;
    /* eslint-disable */
    return (
      <Wrapper>
        <div className={showHideClassName}>
          <section className="modal-main">
            <div className="close-cross" onClick={this.props.hideModuleEdit}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
            <div className="modal-form">
            <ModuleRow data={data} educationalLevels={educationalLevels} edit={this.edit} create={this.create} moduleToShow={moduleToShow} set={this.set}/>
            {moduleToShow !== 0 ? <UnitTable data={data ? data.Units : undefined} deleteUnit={this.deleteUnit} addUnit={this.addUnit}/> : ''}
            {moduleToShow !== 0
              ? (
                <div>
                  <SubTitle className="modal-form-title"> Requerimientos del Módulo (Módulos)</SubTitle>
                  <ModuleReqAdd set={this.setRequirements} methodology={methodology} data={data}/>
                  <ModuleReqTable data={data} set={this.setRequirements}/>

                  <SubTitle className="modal-form-title"> Requerimientos del Módulo (Unidades)</SubTitle>
                  <ModuleReqAddUnits set={this.setUnitRequirements} methodology={methodology} data={data}/>
                  <ModuleReqTableUnits data={data} set={this.setUnitRequirements}/>
                </div>
              )
              : ''}
            <Button
              color="primary"
              className="save-button"
              onClick={data
                ? () => this.save({
                  ...this.state, units: data.Units, moduleRequirements: data.ModuleRequirements, unitRequirements: data.UnitRequirements,
                })
                : () => this.save({ ...this.state })}
            > Guardar Datos
            </Button>
            </div>
          </section>
        </div>
      </Wrapper>
    );
  }
}

Module.propTypes = {
  dispatch: PropTypes.func.isRequired,
  moduleToShow: PropTypes.number.isRequired,
  showModal: PropTypes.bool.isRequired,
  data: PropTypes.object,
  educationalLevels: PropTypes.array,
  methodology: PropTypes.object,
  loadingSingle: PropTypes.bool.isRequired,
};

Module.defaultProps = {
  data: undefined,
  educationalLevels: [],
  methodology: {},
}

export default connect(
  state => ({
    data: state.modules.single,
    educationalLevels: state.modules.educationalLevels,
    loadingSingle: state.modules.loadingSingle,
  }),
)(Module);
