import styled from 'styled-components';

export const Wrapper = styled.div`

    .export-data {
      margin-top: 20px;
      float: right;
      button {
        color: #6896c8;
      }
    }
    .rt-thead.-headerGroups {
      display: block;
      .rt-th {
        padding: 15px 5px;
        border-right: 1px solid rgba(0,0,0,1);
        background-color: white;
        text-transform: uppercase;
        font-weight: 700;
        text-align: center;
      }
    }
    .rt-noData {
      display: none;
    }
    .filter-academy {
      width: 305px;
    }

    .export-button {
      text-align: right;
      padding-right: 0;
    }

    .rt-tfoot {
      padding-top: 10px;
      border-top: 1px solid rgba(0,0,0,1);
      box-shadow: none;
    }
`;
