import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  FormControl,
  Select,
} from '@material-ui/core';
import { Wrapper } from './BasicForm.styles';
import Failed from '$routes/Failed';
import Loading from '$routes/Loading';
import {
  list as reqSemesterList,
} from '$redux/semesters/actions';
// eslint-disable-next-line
class MassiveForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(reqSemesterList());
  }

  render() {
    const {
      set,
      semesters,
      loading,
      error,
      idSemester,
    } = this.props;

    if (loading) return <Loading />;
    if (!semesters) return <Failed />;

    const semestersList = semesters.map(
      item => (
        <option key={item.idSemester} value={item.idSemester}>{item.name.concat(' ', item.courseYear)}</option>
      ),
    );

    return (
      <Wrapper>
        <div className="columns is-vcentered">
          <div className="column is-one-fifth">Semestres</div>
          <div className="column is-one-fifth">
            <FormControl className="formControl">
              <Select
                value={idSemester}
                onChange={e => set('idSemester', e.target.value)}
                native
                className="meth-select"
              >
                <option value={0}>Semestres</option>
                {semestersList}
              </Select>
            </FormControl>
          </div>
        </div>
        {!error
          ? ''
          : (
            <div className="alert"><p>{ error }</p></div>
          )
      }
      </Wrapper>
    );
  }
}

MassiveForm.propTypes = {
  set: PropTypes.func.isRequired,
  semesters: PropTypes.array,
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  idSemester: PropTypes.any,
};

MassiveForm.defaultProps = {
  error: '',
  idSemester: '',
  semesters: undefined,
};

export default connect(
  state => ({
    semesters: state.semesters.list,
    loading: state.semesters.loading,
  }),
)(MassiveForm);
