import styled from 'styled-components';

export const Wrapper = styled.div`

  .row {
    display: flex;
    padding: 10px 0px;
  }

`;
