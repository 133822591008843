import {
  REQUEST,
  REQUEST_SUCCEEDED,
  REQUEST_FAILED,
  FILTERS_REQUEST,
  FILTERS_REQUEST_SUCCEEDED,
  FILTERS_REQUEST_FAILED,
  REQUEST_EXPORT,
  REQUEST_EXPORT_SUCCEEDED,
  REQUEST_EXPORT_FAILED,
  SET,
} from './action-types';

export const request = bindingObject => ({
  type: REQUEST, bindingObject,
});
export const requestSucceeded = ({
  billing: { data, totals, pages },
}, academies) => ({
  type: REQUEST_SUCCEEDED, data, totals, pages, academies,
});
export const requestFailed = () => ({ type: REQUEST_FAILED });

export const filtersRequest = () => ({ type: FILTERS_REQUEST });
export const filtersRequestSucceeded = filtersData => ({
  type: FILTERS_REQUEST_SUCCEEDED, filtersData,
});
export const filtersRequestFailed = () => ({ type: FILTERS_REQUEST_FAILED });
export const set = (key, value) => ({ type: SET, key, value });

export const requestExport = bindingObject => ({
  type: REQUEST_EXPORT, bindingObject,
});
export const requestExportSucceeded = data => (
  { type: REQUEST_EXPORT_SUCCEEDED, data });

export const requestExportFailed = () => ({ type: REQUEST_EXPORT_FAILED });
