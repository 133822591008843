import React from 'react';
import PropTypes from 'prop-types';
import SubTitle from 'ui/SubTitle';
import { Button, InputLabel, Select } from '@material-ui/core';
import { Wrapper } from './Validation.styles';


class ChangeCollaborationCenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      collaboratingCenters, data, onSend, set,
    } = this.props;

    const collaboratingCentersList = collaboratingCenters.map(item => (
      <option
        key={item.idCollaboratingCenter}
        value={item.idCollaboratingCenter}
      >
        {item.name}
      </option>
    ));
    const stateOptions = (
      <div className="column is-three-fifths">
        <div className="columns is-vcentered">
          <div className="column is-one-fifths">
            <InputLabel>Centros: </InputLabel>
          </div>
          <div className="column is-four-fifths">
            <Select
              fullWidth
              value={data.idCollaboratingCenter}
              onChange={e => set('idCollaboratingCenter', e.target.value)}
            >
              {collaboratingCentersList}
            </Select>
          </div>
        </div>
      </div>
    );

    return (
      <Wrapper>
        <SubTitle>Cambio de centro colaborador</SubTitle>
        <div className="columns is-multiline">
          {stateOptions}
          <div className="column is-four-fifths">
            <Button color="primary" onClick={onSend}>Actualizar</Button>
          </div>
        </div>
      </Wrapper>
    );
  }
}

ChangeCollaborationCenter.propTypes = {
  data: PropTypes.object,
  collaboratingCenters: PropTypes.array,
  set: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
};

ChangeCollaborationCenter.defaultProps = {
  data: {},
  collaboratingCenters: [],
};

export default ChangeCollaborationCenter;
