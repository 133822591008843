import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ReactTable from 'ui/ReactTable';
import 'react-table/react-table.css';

import {
  requestOrderRecoverySearch,
  requestOrderRecoveryRecover,
} from '$redux/orderRecovery/actions';

import Failed from '$routes/Failed';
import moment from 'moment';

import swal from 'sweetalert2';
import { RedButton } from './Grid.styles';

const dateFormat = 'DD-MM-YYYY';

class Grid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 5,
    };
  }

  fetchData = (state) => {
    const { dispatch } = this.props;
    const { pageSize, page } = state;
    const bindingObject = {
      pageSize,
      page,
      sorted: [],
      filters: {},
    };
    dispatch(requestOrderRecoverySearch(bindingObject));
    this.setState({ pageSize });
  }

  recoverEnrollment = async (orderReference) => {
    const { dispatch } = this.props;

    const { value } = await swal({
      title: 'Confirmación',
      text: '¿Seguro que quieres generar una matricula nueva a partir de este pago?',
      type: 'warning',
      confirmButtonText: 'Sí, hazlo!',
      confirmButtonColor: '#e05959',
      showCancelButton: true,
      cancelButtonText: 'No, volver atrás',
    });
    if (!value) return;

    dispatch(requestOrderRecoveryRecover({ orderReference }));
  }

  render() {
    const {
      loading, pages, data,
    } = this.props;
    const { pageSize } = this.state;

    if (!data) return <Failed />;

    return (
      <ReactTable
        topBorder
        columns={[
          {
            Header: 'OrderReference',
            accessor: 'orderReference',
            maxWidth: 30,
          },
          {
            Header: 'OrderId',
            accessor: 'orderId',
            width: 150,
          },
          {
            Header: 'Status',
            accessor: 'status',
            width: 150,
          },
          {
            Header: 'Total',
            accessor: 'totalCost',
            width: 150,
          },
          {
            Header: 'Curso',
            accessor: 'courseName',
            width: 150,
          },
          {
            Header: 'Tipo de pago',
            accessor: 'paymentMethod',
            Cell: ({ value }) => (value === 1 ? 'Pago con tarjeta' : 'Pago con tarjeta fraccionado'),
            maxWidth: 70,
          },
          {
            Header: 'Fecha inicio',
            accessor: 'createdAt',
            Cell: ({ value }) => (value ? moment(value).format(dateFormat) : '-'),
            maxWidth: 100,
          },
          {
            Header: 'Fecha fin',
            accessor: 'updatedAt',
            Cell: ({ value }) => (value ? moment(value).format(dateFormat) : '-'),
            maxWidth: 100,
          },
          {
            // separator
            resizable: false,
          },
          {
            Header: 'Acciones',
            fixed: 'right',
            columns: [
              {
                Cell: row => (
                  <RedButton onClick={() => this.recoverEnrollment(row.original.orderReference)}>
                      Recuperar
                  </RedButton>
                ),
                maxWidth: 100,
                resizable: false,
                sortable: false,
              },
            ],
          },
        ]}
        manual
        defaultPageSize={pageSize}
        data={data}
        pages={pages} // Display the total number of pages
        loading={loading} // Display the loading overlay when we need it
        onFetchData={this.fetchData} // Request new data when things change
      />
    );
  }
}

Grid.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.array,
  pages: PropTypes.number,
  loading: PropTypes.bool,
};

Grid.defaultProps = {
  data: [],
  pages: 0,
  loading: true,
};

export default connect(
  state => ({
    data: state.orderRecovery.data,
    pages: state.orderRecovery.pages,
    loading: state.orderRecovery.loading,
  }),
)(Grid);
