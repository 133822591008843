export const REQUEST_FAILED = 'ACADEMIES_REQUEST_FAILED';
export const LIST = 'ACADEMIES_LIST';
export const LIST_SUCCEEDED = 'ACADEMIES_LIST_SUCCEEDED';
export const GET = 'ACADEMY_GET';
export const GET_SUCCEEDED = 'ACADEMY_GET_SUCCEEDED';
export const UPDATE = 'ACADEMY_UPDATE';
export const UPDATE_SUCCEEDED = 'ACADEMY_UPDATE_SUCCEEDED';
export const REMOVE = 'ACADEMY_REMOVE';
export const REMOVE_SUCCEEDED = 'ACADEMY_REMOVE_SUCCEEDED';
export const CREATE = 'ACADEMY_CREATE';
export const CREATE_SUCCEEDED = 'ACADEMY_CREATE_SUCCEEDED';
export const SET = 'ACADEMY_SET';
export const RESET = 'ACADEMY_RESET';
export const LIST_BILLING_RULES = 'ACADEMIES_LIST_BILLING_RULES';
export const LIST_BILLING_RULES_SUCCEEDED = 'ACADEMIES_LIST_BILLING_RULES_SUCCEEDED';
