import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-spinkit';

import { Wrapper, Overlay } from './LoginScreen.styles';
import { version } from '$root/package.json';

const LoginScreen = ({
  loading,
  user,
  password,
  updateUser,
  updatePassword,
  onSubmit,
}) => (
  <div>
    <Overlay loading={loading}>
      <Spinner name="line-scale" fadeIn="none" color="#fff" />
    </Overlay>
    <Wrapper className="hero is-fullheight">
      <div className="hero-body">
        <div className="container has-text-centered">
          <div className="column is-4 is-offset-4">
            <h3 className="title has-text-grey">Login</h3>
            <p className="subtitle has-text-grey">Haz login para entrar en panel de gestión</p>
            <div className="box">
              <figure className="avatar">
                <img src="/img/linkia-check.png" alt="Linkia Logo" />
              </figure>
              <form onSubmit={onSubmit}>
                <div className="field">
                  <div className="control">
                    {/* eslint-disable jsx-a11y/no-autofocus */}
                    <input
                      value={user}
                      onChange={updateUser}
                      className="input is-large"
                      type="email"
                      placeholder="Tu Email"
                      autoFocus
                    />
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    <input
                      value={password}
                      onChange={updatePassword}
                      className="input is-large"
                      type="password"
                      placeholder="Tu Contraseña"
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  className="button is-block is-info is-large is-fullwidth"
                >
                  Login
                </button>
              </form>
            </div>
            <span className="version-display">
              v{version}
            </span>
          </div>
        </div>
      </div>
    </Wrapper>
  </div>
);

LoginScreen.propTypes = {
  loading: PropTypes.bool.isRequired,
  user: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  updateUser: PropTypes.func.isRequired,
  updatePassword: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default LoginScreen;
