import {
  select, call, put, takeEvery,
} from 'redux-saga/effects';
import api from 'linkia-api';

import {
  REGULATIONS_REQUEST,
  REGULATIONS_SHOW_REQUEST,
} from './action-types';
import {
  getRegulationsSucceeded,
  getRegulationsFailed,
  showDocumentSucceeded,
  showDocumentFailed,
} from './actions';

import { tokenSelector } from '../login/selectors';

function* getRegulationsInfo() {
  const token = yield select(tokenSelector);
  try {
    const { economic, academic } = yield call(api.regulations.getRegulations, { token });
    const { endpoint } = yield call(api.regulations.getConfig);
    const data = {
      token, endpoint, economic, academic,
    };
    yield put(getRegulationsSucceeded(data));
  } catch (e) {
    yield put(getRegulationsFailed(e));
  }
}

function* showDocument(action) {
  try {
    const { document } = action;
    const url = yield call(api.regulations.getDocumentUrl, { type: document });
    yield put(showDocumentSucceeded(url));
  } catch (e) {
    yield put(showDocumentFailed(e));
  }
}

export default [
  takeEvery(REGULATIONS_REQUEST, getRegulationsInfo),
  takeEvery(REGULATIONS_SHOW_REQUEST, showDocument),
];
