import {
  LIST,
  LIST_SUCCEEDED,
  LIST_FAILED,
  GET,
  GET_SUCCEEDED,
  GET_FAILED,
  CREATE,
  CREATE_SUCCEEDED,
  CREATE_FAILED,
  EDIT,
  EDIT_SUCCEEDED,
  EDIT_FAILED,
} from './action-types';

export const list = () => ({ type: LIST });
export const listSucceeded = data => ({ type: LIST_SUCCEEDED, data });
export const listFailed = () => ({ type: LIST_FAILED });
export const get = idUnit => ({ type: GET, idUnit });
export const getSucceeded = (data, idUnit) => ({ type: GET_SUCCEEDED, data, idUnit });
export const getFailed = () => ({ type: GET_FAILED });
export const create = unitObject => ({ type: CREATE, unitObject });
export const createSucceeded = (data, idUnit) => ({ type: CREATE_SUCCEEDED, data, idUnit });
export const createFailed = () => ({ type: CREATE_FAILED });
export const edit = (idUnit, unitEdit) => ({ type: EDIT, unitEdit, idUnit });
export const editSucceeded = data => ({ type: EDIT_SUCCEEDED, data });
export const editFailed = () => ({ type: EDIT_FAILED });
