import React from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Button,
} from '@material-ui/core';

const UnitItem = ({ unit, onClick }) => (
  <TableRow>
    <TableCell className="cell-unit">{unit.code}</TableCell>
    <TableCell className="cell-unit">{unit.name}</TableCell>
    <TableCell className="cell-unit" />
    <TableCell className="cell-unit" />
    <TableCell className="cell-unit">
      <Button onClick={onClick({ idUnit: unit.idUnit })}>
        Editar UF
      </Button>
    </TableCell>
  </TableRow>
);

UnitItem.propTypes = {
  unit: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default UnitItem;
