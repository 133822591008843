import {
  LIST,
  LIST_SUCCEEDED,
  LIST_FAILED,
  GET,
  GET_SUCCEEDED,
  GET_FAILED,
  SEND,
  SEND_SUCCEEDED,
  SEND_FAILED,
  READ_THREAD,
  UNREAD_MESSAGE,
} from './action-types';

export const list = () => ({ type: LIST });
export const listSucceeded = data => ({ type: LIST_SUCCEEDED, data });
export const listFailed = () => ({ type: LIST_FAILED });
export const get = idUser => ({ type: GET, idUser });
export const getSucceeded = (data, idUser) => ({ type: GET_SUCCEEDED, data, idUser });
export const getFailed = () => ({ type: GET_FAILED });
export const send = (message, idUser) => ({ type: SEND, message, idUser });
export const sendSucceeded = () => ({ type: SEND_SUCCEEDED });
export const sendFailed = () => ({ type: SEND_FAILED });
export const readThread = idUser => ({ type: READ_THREAD, idUser });
export const unreadMessage = idMessage => ({ type: UNREAD_MESSAGE, idMessage });
