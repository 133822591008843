import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { tokenSelector, canSelector } from '$redux/login/selectors';

import Button from '@material-ui/core/Button';
import Title from 'ui/Title';

import { SummaryBlockWrapper } from './styles';
import GridPaid from './GridPaid';
import GridDigitalValidation from './GridDigitalValidation';
import GridPhysicalValidation from './GridPhysicalValidation';


class Home extends React.Component {
  render() {
    const { userCan } = this.props;

    return (
      <div>
        {
          userCan('SUMMARY_LAST_PAID_ENROLLMENTS') ? (
            <SummaryBlockWrapper>
              <Title>Últimas matrículas pagadas</Title>
              <GridPaid />
              <Button size="small" onClick={this.openSearch}>
                <Link to="/matriculas">VER TODAS LAS MATRÍCULAS</Link>
              </Button>
            </SummaryBlockWrapper>
          ) : null
        }

        <SummaryBlockWrapper>
          <Title>Últimas matrículas en validación digital</Title>
          <GridDigitalValidation />
          <Button size="small" onClick={this.openSearch}>
            <Link to="/matriculas">VER TODAS LAS MATRÍCULAS</Link>
          </Button>
        </SummaryBlockWrapper>

        <SummaryBlockWrapper>
          <Title>Últimas matrículas en validación física</Title>
          <GridPhysicalValidation filters={{}} />
          <Button size="small" onClick={this.openSearch}>
            <Link to="/matriculas">VER TODAS LAS MATRÍCULAS</Link>
          </Button>
        </SummaryBlockWrapper>
      </div>
    );
  }
}

Home.propTypes = {
  userCan: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    token: tokenSelector(state),
    userCan: canSelector(state),
  }),
)(Home);
