import {
  select, call, put, takeEvery,
} from 'redux-saga/effects';
import api from 'linkia-api';

import {
  LIST,
  GET,
  UPDATE,
  REMOVE,
  CREATE,
} from './action-types';
import {
  list,
  reset,
  requestFailed,
  listSucceeded,
  getSucceeded,
  updateSucceeded,
  removeSucceeded,
  createSucceeded,
} from './actions';

import { tokenSelector } from '../login/selectors';

function* listUtmFormCourses() {
  const token = yield select(tokenSelector);
  try {
    const data = yield call(api.utmFormCourse.getAll, { token });
    yield put(listSucceeded(data));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* getUtmFormCourse(action) {
  const token = yield select(tokenSelector);
  const { id } = action;
  try {
    const data = yield call(api.utmFormCourse.get, { token, id });
    yield put(getSucceeded(data));
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* updateUtmFormCourse(action) {
  const token = yield select(tokenSelector);
  const { id, data } = action;
  try {
    const success = yield call(api.utmFormCourse.update, { token, id, data });
    yield put(updateSucceeded(success));
    yield put(list());
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* removeUtmFormCourse(action) {
  const token = yield select(tokenSelector);
  const { id } = action;
  try {
    const success = yield call(api.utmFormCourse.delete, { token, id });
    yield put(removeSucceeded(success));
    yield put(list());
  } catch (e) {
    yield put(requestFailed(e));
  }
}

function* createUtmFormCourse(action) {
  const token = yield select(tokenSelector);
  const { data } = action;
  try {
    const success = yield call(api.utmFormCourse.create, { token, data });
    yield put(createSucceeded(success));
    yield put(reset());
    yield put(list());
  } catch (e) {
    yield put(requestFailed(e));
  }
}

export default [
  takeEvery(LIST, listUtmFormCourses),
  takeEvery(GET, getUtmFormCourse),
  takeEvery(UPDATE, updateUtmFormCourse),
  takeEvery(REMOVE, removeUtmFormCourse),
  takeEvery(CREATE, createUtmFormCourse),
];
