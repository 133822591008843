import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  list as listStaff,
  get as getStaff,
  reset as resetStaff,
  update as updateStaff,
  set as setStaff,
  create as createStaff,
  remove as removeStaff,
  getProps,
  redoStaffPassword,
} from '$redux/staffs/actions';

import { Button } from '@material-ui/core';
import Title from 'ui/Title';
import Loading from '$routes/Loading';
import Failed from '$routes/Failed';

import Grid from './Grid';
import Editor from './Editor';

class Staffs extends React.Component {
  state = {
    editorOpen: false,
    editorAction: 'new',
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getProps());
    dispatch(listStaff());
  }

  onCreate = () => {
    const { dispatch, single } = this.props;
    dispatch(createStaff(single));
  }

  onUpdate = () => {
    const { dispatch, single } = this.props;
    dispatch(updateStaff(single.idStaff, single));
  }

  onPassword = (email) => {
    const { dispatch } = this.props;
    dispatch(redoStaffPassword(email));
  }

  set = (key, value) => {
    const { dispatch } = this.props;
    dispatch(setStaff(key, value));
  }

  openEditor = (id) => {
    const { dispatch } = this.props;

    if (id) {
      dispatch(getStaff(id));
      this.setState({ editorOpen: true, editorAction: 'edit' });
    } else {
      dispatch(resetStaff());
      this.setState({ editorOpen: true, editorAction: 'new' });
    }
  }

  removeStaff = (id) => {
    const { dispatch } = this.props;
    if (id) {
      dispatch(removeStaff(id));
    }
  }

  render() {
    const {
      data, single, loading, roles, academies,
    } = this.props;

    if (loading) return <Loading />;
    if (!data) return <Failed />;

    const { editorOpen, editorAction } = this.state;

    return (
      <div>
        <Title>
          Usuarios
          <Button onClick={() => this.openEditor()} size="small">Nuevo usuario</Button>
        </Title>
        {
          editorOpen ? (
            <Editor
              action={editorAction}
              data={single}
              roles={roles}
              academies={academies}
              set={this.set}
              onSend={editorAction === 'new' ? this.onCreate : this.onUpdate}
              onClose={() => this.setState({ editorOpen: false })}
              onPassword={this.onPassword}
            />
          ) : null
        }
        <Grid
          data={data}
          loading={loading}
          onEdit={this.openEditor}
          removeItem={this.removeStaff}
        />
      </div>
    );
  }
}

Staffs.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.array,
  roles: PropTypes.array,
  academies: PropTypes.array,
  single: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

Staffs.defaultProps = {
  data: null,
  roles: [],
  academies: [],
};

export default connect(
  state => ({
    data: state.staffs.list,
    single: state.staffs.single,
    roles: state.staffs.roles,
    academies: state.staffs.academies,
    loading: state.staffs.loading,
  }),
)(Staffs);
