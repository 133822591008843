import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import ReactTable from 'ui/ReactTable';
import { Button } from '@material-ui/core';

const dateFormat = 'DD-MM-YYYY';

const Grid = ({ data, onEdit }) => (
  <div>
    <ReactTable
      data={data}
      columns={[
        {
          Header: 'ID',
          accessor: 'idSemester',
          maxWidth: 30,
        },
        {
          Header: 'Nombre',
          accessor: 'name',
          width: 150,
        },
        {
          Header: 'Inicio de matrícula',
          accessor: 'startEnrollmentDate',
          Cell: ({ value }) => (value ? moment(value).format(dateFormat) : '-'),
          maxWidth: 150,
        },
        {
          Header: 'Fin de matrícula',
          accessor: 'finishEnrollmentDate',
          Cell: ({ value }) => (value ? moment(value).format(dateFormat) : '-'),
          maxWidth: 150,
        },
        {
          Header: 'Inicio de curso',
          accessor: 'startCourseDate',
          Cell: ({ value }) => (value ? moment(value).format(dateFormat) : '-'),
          maxWidth: 120,
        },
        {
          Header: 'Fin de curso',
          accessor: 'finishCourseDate',
          Cell: ({ value }) => (value ? moment(value).format(dateFormat) : '-'),
          maxWidth: 120,
        },
        {
          Header: 'Curso',
          accessor: 'courseYear',
          maxWidth: 120,
        },
        {
          // separator
          resizable: false,
        },
        {
          Header: 'Acciones',
          fixed: 'right',
          columns: [
            {
              Cell: row => (
                <Button onClick={() => onEdit(row.original.idSemester)}>Editar</Button>
              ),
              maxWidth: 100,
              resizable: false,
              sortable: false,
            },
          ],
        },
      ]}
    />
  </div>
);

Grid.propTypes = {
  data: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default Grid;
