import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import theme from 'ui/theme';
import ReactTable from 'ui/ReactTable';
import 'react-table/react-table.css';

import { reqUtmFormFilters } from '$redux/utm-forms/actions';

import Failed from '$routes/Failed';

import { Wrapper } from './styles';

class Grid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      sorted: [],
    };
  }

  componentDidUpdate(prevProps) {
    const { filters } = this.props;

    // Typical usage (don't forget to compare props):
    if (filters !== prevProps.filters) {
      const { dispatch, pageSize } = this.props;
      const bindingObject = {
        pageSize,
        page: 0,
        sorted: [],
        filters,
      };
      dispatch(reqUtmFormFilters(bindingObject));
    }
  }

  fetchData = (state) => {
    let { filters } = this.props;
    const { dispatch, filtersApplied } = this.props;
    filters = {
      ...filtersApplied,
      ...filters,
    };
    const { pageSize, page, sorted } = state;
    const bindingObject = {
      pageSize,
      page,
      sorted,
      filters,
    };
    dispatch(reqUtmFormFilters(bindingObject));
    // eslint-disable-next-line react/no-unused-state
    this.setState({ sorted });
  }

  render() {
    const {
      loading, pages, pageSize, data,
    } = this.props;

    if (!data) return <Failed />;

    return (
      <Wrapper theme={theme}>
        <ReactTable
          topBorder
          columns={[
            {
              Header: 'Utm Id',
              accessor: 'idUtm',
              width: 120,
            },
            {
              Header: 'URL Formulario',
              accessor: 'formFromUrl',
            },
            {
              Header: 'Nombre',
              accessor: 'name',
            },
            {
              Header: 'Codigo Postal',
              accessor: 'zip',
            },
            {
              Header: 'Email',
              accessor: 'email',
            },
            {
              Header: 'Telefono',
              accessor: 'phone',
            },
            {
              Header: 'Privacidad',
              accessor: 'privacity',
            },
            {
              Header: 'Ads',
              accessor: 'ads',
            },
            //
            {
              Header: 'Campaña Id',
              accessor: 'Utm.campaignId',
              width: 120,
            },
            {
              Header: 'Campaña Source',
              accessor: 'Utm.campaignSource',
              width: 80,
            },
            {
              Header: 'Campaña Medium',
              accessor: 'Utm.campaignMedium',
            },
            {
              Header: 'Campaña',
              accessor: 'Utm.campaignCampaign',
            },
            {
              Header: 'Campaña Terminos',
              accessor: 'Utm.campaignTerms',
            },
            {
              Header: 'Campaña Contenido',
              accessor: 'Utm.campaignContent',
            },
            {
              Header: 'Campaña User',
              accessor: 'Utm.campaignUser',
            },
            {
              Header: 'Referer',
              accessor: 'Utm.campaignReferer',
            },
            {
              Header: 'Curso',
              accessor: 'UtmFormCourse.Course.name',
            },
            {
              Header: 'Creado',
              accessor: 'createdAt',
            },
            {
              Header: 'Actualizado',
              accessor: 'updatedAt',
            },
          ]}
          manual
          defaultPageSize={pageSize}
          data={data}
          pages={pages} // Display the total number of pages
          loading={loading} // Display the loading overlay when we need it
          onFetchData={this.fetchData} // Request new data when things change
        />
      </Wrapper>
    );
  }
}

Grid.propTypes = {
  dispatch: PropTypes.func.isRequired,
  filters: PropTypes.object,
  data: PropTypes.array,
  pages: PropTypes.number,
  pageSize: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  filtersApplied: PropTypes.object,
};

Grid.defaultProps = {
  filters: {},
  data: [],
  pageSize: 10,
  pages: 0,
  filtersApplied: {},
};

export default connect(
  state => ({
    data: state.utmForms.utmForms,
    pages: state.utmForms.pages,
    loading: state.utmForms.loading,
    filtersApplied: state.utmForms.filters,
  }),
)(Grid);
