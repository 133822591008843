import {
  REQUEST,
  REQUEST_SUCCEEDED,
  REQUEST_FAILED,
  FILTERS_REQUEST,
  FILTERS_REQUEST_SUCCEEDED,
  FILTERS_REQUEST_FAILED,
  UPDATE_USER,
  CHANGE_LEAD_PASSWORD,
  CHANGE_LEAD_PASSWORD_SUCCEEDED,
  CHANGE_LEAD_PASSWORD_FAILED,
  REQUEST_PAYMENT_LOG,
  REQUEST_PAYMENT_LOG_SUCCEEDED,
  REQUEST_PAYMENT_LOG_FAILED,
} from './action-types';

export const request = bindingObject => ({
  type: REQUEST, bindingObject,
});

export const requestSucceeded = ({
  candidate: { data, pages },
}) => ({ type: REQUEST_SUCCEEDED, data, pages });
export const requestFailed = () => ({ type: REQUEST_FAILED });

export const requestPaymentLog = bindingObject => ({
  type: REQUEST_PAYMENT_LOG,
  bindingObject,
});
export const requestPaymentLogSucceeded = ({
  data,
  pages,
}) => ({ type: REQUEST_PAYMENT_LOG_SUCCEEDED, data, pages });
export const requestPaymentLogFailed = () => ({ type: REQUEST_PAYMENT_LOG_FAILED });

export const filtersRequest = () => ({ type: FILTERS_REQUEST });
export const filtersRequestSucceeded = filtersData => ({
  type: FILTERS_REQUEST_SUCCEEDED,
  filtersData,
});
export const filtersRequestFailed = () => ({ type: FILTERS_REQUEST_FAILED });

export const changeLeadPassword = (email, value) => ({
  type: CHANGE_LEAD_PASSWORD, email, value,
});

export const changeLeadSucceeded = () => ({ type: CHANGE_LEAD_PASSWORD_SUCCEEDED });

export const changeLeadFailed = () => ({ type: CHANGE_LEAD_PASSWORD_FAILED });

export const updateUser = ({
  idUser, skipHourLimits, forceCatOldTp, bindingObject,
}) => ({
  type: UPDATE_USER, idUser, skipHourLimits, forceCatOldTp, bindingObject,
});
