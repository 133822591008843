import styled from 'styled-components';
import { colors } from 'ui/theme';

const verticalMargin = 4;
export const Wrapper = styled.div`
  margin-top: ${({ position }) => (position === 'bottom' ? `${verticalMargin}px` : 'unset')};
  margin-bottom: ${({ position }) => (position === 'top' ? `${verticalMargin}px` : 'unset')};

  position: absolute;
  top: ${({ position }) => (position === 'bottom' ? '100%' : 'unset')};
  bottom: ${({ position }) => (position === 'top' ? '100%' : 'unset')};
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
`;

export const TooltipMinWrapper = styled.div`
  min-width: 150px;
  display: flex;
  justify-content: center;
`;

const triangleSize = 5;
export const TooltipWrapper = styled.div`
  margin: 0 auto;
  padding: 3px 5px;
  background: ${({ type }) => colors[type]};
  border-radius: 2px;

  color: #fff;
  line-height: 1.2;
  font-size: 12px;
  font-weight: 700;

  position: relative;
  :before {
    content: '';
    position: absolute;
    top: ${({ position }) => (position === 'bottom' ? `-${triangleSize}px` : 'unset')};
    bottom: ${({ position }) => (position === 'top' ? `-${triangleSize}px` : 'unset')};
    left: 0;
    right: 0;

    width: 0;
    margin: 0 auto;

    border-bottom: ${triangleSize}px solid ${({ type }) => colors[type]};
    border-left: ${triangleSize}px solid transparent;
    border-right: ${triangleSize}px solid transparent;
    transform: scaleY(${({ position }) => (position === 'top' ? -1 : 1)});
  }
`;
