import {
  select, call, put, takeEvery,
} from 'redux-saga/effects';
import api from 'linkia-api';

import {
  REQUEST,
  FILTERS_REQUEST,
  REQUEST_EXPORT,
} from './action-types';

import {
  requestSucceeded,
  requestFailed,
  filtersRequestSucceeded,
  filtersRequestFailed,
  requestExportSucceeded,
  requestExportFailed,
} from './actions';

import { tokenSelector } from '../login/selectors';

function* reqBilling(action) {
  const token = yield select(tokenSelector);
  const {
    bindingObject,
  } = action;
  try {
    const data = yield call(api.billing.getAllFiltered, {
      bindingObject, token,
    });
    const academies = yield call(api.academy.listOptions, { token });
    yield put(requestSucceeded(data, academies));
  } catch (e) {
    yield put(requestFailed());
  }
}

function* reqFilters() {
  const token = yield select(tokenSelector);
  try {
    const filtersData = yield call(api.billing.getFilters, { token });
    yield put(filtersRequestSucceeded(filtersData));
  } catch (e) {
    yield put(filtersRequestFailed());
  }
}

function* reqBillingExport(action) {
  const token = yield select(tokenSelector);
  const { bindingObject } = action;
  try {
    const data = yield call(api.billing.getBillingExport, {
      bindingObject, token,
    });
    yield put(requestExportSucceeded(data));
  } catch (e) {
    yield put(requestExportFailed());
  }
}

export default [
  takeEvery(REQUEST, reqBilling),
  takeEvery(FILTERS_REQUEST, reqFilters),
  takeEvery(REQUEST_EXPORT, reqBillingExport),
];
