import React from 'react';
import SubTitle from 'ui/SubTitle';
import PropTypes from 'prop-types';
import swal from 'sweetalert2';
import {
  TextField, Button,
} from '@material-ui/core';


class UnitRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: undefined,
      name: undefined,
      onLineHours: undefined,
      inClassHours: undefined,
      onLineHourPrice: undefined,
      inClassHourPrice: undefined,
      price: undefined,
    };
  }

  handleChange = (name, value) => {
    const stateReplica = { canBeAdded: true };
    stateReplica[name] = value;
    this.setState(stateReplica);
  }

  handleClick = () => {
    const { addUnit } = this.props;
    const {
      code,
      name,
      onLineHours,
      inClassHours,
      onLineHourPrice,
      inClassHourPrice,
      price,
    } = this.state;
    if (code
      && name
      && onLineHours
      && inClassHours
      && onLineHourPrice
      && inClassHourPrice
      && price) {
      return addUnit({
        code,
        name,
        onLineHours,
        inClassHours,
        onLineHourPrice,
        inClassHourPrice,
        price,
      });
    }
    return swal({
      type: 'warning',
      title: 'Campos vacíos',
      text: 'Todos los campos deben ser rellenados para añadir una unidad',
    });
  }

  render() {
    return (
      <div>
        <SubTitle className="modal-form-title">Unidades formativas del módulo</SubTitle>
        <div className="columns is-multiline">
          <div className="column is-1">
            <TextField
              label="SKU"
              onChange={event => this.handleChange('code', event.target.value)}
            />
          </div>
          <div className="column is-3">
            <TextField
              fullWidth
              onChange={event => this.handleChange('name', event.target.value)}
              label="NOMBRE"
            />
          </div>
          <div className="column is-1">
            <TextField
              onChange={event => this.handleChange('onLineHours', event.target.value)}
              label="H.ONLINE"
            />
          </div>
          <div className="column is-1">
            <TextField
              onChange={event => this.handleChange('inClassHours', event.target.value)}
              label="H. PRE."
            />
          </div>
          <div className="column is-1">
            <TextField
              onChange={event => this.handleChange('onLineHourPrice', event.target.value)}
              label="P. ONLINE"
            />
          </div>
          <div className="column is-1">
            <TextField
              onChange={event => this.handleChange('inClassHourPrice', event.target.value)}
              label="P. PRE."
            />
          </div>
          <div className="column is-1">
            <TextField
              onChange={event => this.handleChange('price', event.target.value)}
              label="P. FIJO"
            />
          </div>
          <div className="column is-2 is-vcentered">
            <Button
              fullWidth
              onClick={() => this.handleClick()}
              style={{ backgroundColor: 'transparent' }}
            > Añadir UF
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
UnitRow.propTypes = {
  addUnit: PropTypes.func.isRequired,
};

export default UnitRow;
