import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Wrapper, PlanHeaderWrapper, PlanRowWrapper, RedButton,
} from './InfoChannel.styles';

class InfoChannelTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  eraseBillingRule = (idInfoChannel) => {
    const { remove } = this.props;
    remove(idInfoChannel);
  }

  render() {
    const { data, stats } = this.props;
    return (
      <Wrapper>
        <PlanHeaderWrapper>
          <div className="columns">
            <div className="column is-1">
              ID
            </div>
            <div className="column is-3">
              Nombre
            </div>
            <div className="column">
              Número de estudiantes
            </div>
            <div className="column" />
          </div>
        </PlanHeaderWrapper>
        {data ? data.map((channel) => {
          const stat = stats.find(item => item.name === channel.name);
          return (
            <PlanRowWrapper key={channel.name}>
              <div className="columns is-vcentered">
                <div className="column is-1">
                  {channel.idInfoChannel}
                </div>
                <div className="column is-3">
                  {channel.name}
                </div>
                <div className="column">
                  {stat ? stat.numberOfStudents : ''}
                </div>
                <RedButton style={{ backgroundColor: 'transparent' }} onClick={() => this.eraseBillingRule(channel.idInfoChannel)}>
                  Eliminar
                </RedButton>
              </div>
            </PlanRowWrapper>
          );
        }) : ''}
      </Wrapper>
    );
  }
}

InfoChannelTable.propTypes = {
  data: PropTypes.array,
  stats: PropTypes.array,
  remove: PropTypes.func.isRequired,
};

InfoChannelTable.defaultProps = {
  data: [],
  stats: [],
};


export default connect()(InfoChannelTable);
