import styled from 'styled-components';

export const Wrapper = styled.div`
  .radio-buttons-container {
    flex-direction: row;
  }
  .formControl {
    width: 100%;
  }

  .alert {
    margin-top: 16px;
    background-color: #880000;
    color: #ffffff;
    padding: 8px;
  }
`;
