import React from 'react';
import PropTypes from 'prop-types';
import 'react-table/react-table.css';
import {
  Button,
  Select,
  FormControl,
} from '@material-ui/core';
import SubTitle from 'ui/SubTitle';

class Methodology extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idCourse: props.optionsMethodology.idCourse,
      idTrainingPlan: props.data.idTrainingPlan,
      idRoadMap: props.data.idRoadMap,
      idAcademy: props.data.idAcademy,
    };
  }

  handleChangeCourse = (event) => {
    event.preventDefault();
    this.setState({ idCourse: event.target.value });
  };

  handleChangeTP = (event) => {
    event.preventDefault();
    this.setState({ idTrainingPlan: event.target.value });
  };

  handleChangeRM = (event) => {
    event.preventDefault();
    this.setState({ idRoadMap: event.target.value });
  };

  handleChangeA = (event) => {
    event.preventDefault();
    this.setState({ idAcademy: event.target.value });
  };

  render() {
    const {
      data,
      optionsMethodology,
      text,
      changeMethodology,
    } = this.props;

    const {
      idTrainingPlan,
      idRoadMap,
      idAcademy,
      idCourse,
    } = this.state;

    const filterTrainingPlans = optionsMethodology.tps.filter(
      tp => tp.idCourse === parseInt(idCourse, 10),
    );

    const filterRoadMaps = optionsMethodology.roadMaps.filter(
      rm => rm.idTrainingPlan === parseInt(idTrainingPlan, 10),
    );

    const allowChangeMethodology = (parseInt(idCourse, 10) === optionsMethodology.idCourse
      && parseInt(idTrainingPlan, 10) === data.idTrainingPlan
      && parseInt(idRoadMap, 10) === data.idRoadMap
      && parseInt(idAcademy, 10) === data.idAcademy);

    let hasCustomizable = false;
    filterRoadMaps.forEach((rm) => {
      if (rm.isCustomizable) {
        hasCustomizable = true;
      }
    });
    return (
      <div className="basic-information">
        <SubTitle>
          {text}
        </SubTitle>
        <div>
          <div className="columns is-vcentered">
            <div className="column is-one-fifth">Cursos</div>
            <div className="column is-one-fifth">
              <FormControl className="formControl">
                <Select
                  onChange={this.handleChangeCourse}
                  className="filter-item"
                  native
                  value={idCourse}
                >
                  <option value={0}>Selecciona el curso</option>
                  {optionsMethodology.courses.map(course => (
                    <option key={course.idCourse} value={course.idCourse}>
                      {course.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="columns is-vcentered">
            <div className="column is-one-fifth">Metodología</div>
            <div className="column is-one-fifth">
              <FormControl className="formControl">
                <Select
                  onChange={this.handleChangeTP}
                  className="filter-item"
                  native
                  value={idTrainingPlan}
                >
                  <option value={0}>Selecciona la Metodologia</option>
                  {filterTrainingPlans.map(tp => (
                    <option key={tp.Methodology.name} value={tp.idTrainingPlan}>
                      {tp.Methodology.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="columns is-vcentered">
            <div className="column is-one-fifth">Itinerario</div>
            <div className="column is-one-fifth">
              <FormControl className="formControl">
                <Select
                  onChange={this.handleChangeRM}
                  className="filter-item"
                  value={idRoadMap}
                  native
                >
                  <option value={0}>Selecciona el Itinerario</option>
                  {hasCustomizable && filterRoadMaps.map(rm => (
                    <option key={rm.idRoadMap} value={rm.idRoadMap}>
                      {rm.name}
                    </option>))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="columns is-vcentered">
            <div className="column is-one-fifth">Academia</div>
            <div className="column is-one-fifth">
              <FormControl className="formControl">
                <Select
                  onChange={this.handleChangeA}
                  className="filter-item"
                  value={idAcademy}
                  native
                >
                  <option value={0}>Selecciona la Academia</option>
                  {optionsMethodology.academies.map(a => (
                    <option key={a.idAcademy} value={a.idAcademy}>
                      {a.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <Button
            color="primary"
            disabled={allowChangeMethodology}
            onClick={() => changeMethodology(
              data.idEnrollment, idTrainingPlan, idRoadMap, idAcademy,
            )}
          >
            Cambiar Metodología
          </Button>
        </div>
      </div>
    );
  }
}

Methodology.propTypes = {
  data: PropTypes.object,
  text: PropTypes.string,
  optionsMethodology: PropTypes.object,
  changeMethodology: PropTypes.func.isRequired,
};

Methodology.defaultProps = {
  data: {},
  optionsMethodology: null,
  text: 'Cambio de metodología',
};

export default Methodology;
