import dotenv from 'dotenv';
import {
  select,
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';
import api from 'linkia-api';
import { tokenSelector } from '$redux/login/selectors';


import { GET, UPDATE_MAIL } from './action-types';
import {
  getSucceeded,
  getFailed,
  updateMailsSucceeded,
  updateMailsFailed,
} from './actions';

dotenv.config();
const { NODE_ENV: env } = process.env;

function* reqGetMails() {
  const token = yield select(tokenSelector);
  try {
    const mails = yield call(api.mails.get, { token });
    yield put(getSucceeded(mails));
  } catch (e) {
    // eslint-disable-next-line no-console
    if (env === 'development' || !e.response || e.response.status !== 401) console.error(e);
    yield put(getFailed());
  }
}

function* reqUpdateMails(action) {
  const { mail } = action;
  const token = yield select(tokenSelector);
  try {
    const { mails } = yield call(api.mails.update, { token, mail });
    yield put(updateMailsSucceeded(mails));
  } catch (e) {
    // eslint-disable-next-line no-console
    if (env === 'development' || !e.response || e.response.status !== 401) console.error(e);
    yield put(updateMailsFailed());
  }
}

export default [
  takeEvery(GET, reqGetMails),
  takeEvery(UPDATE_MAIL, reqUpdateMails),
];
