import {
  REQUEST_FAILED,
  LIST,
  LIST_SUCCEEDED,
  GET,
  GET_SUCCEEDED,
  UPDATE,
  UPDATE_SUCCEEDED,
  REMOVE,
  REMOVE_SUCCEEDED,
  CREATE,
  CREATE_SUCCEEDED,
  SET,
  RESET,
} from './action-types';
import utils from '../utils';

const initialState = {
  list: null,
  single: {
    name: '',
    code: '',
  },
  loading: false,
};

const gradeReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case REQUEST_FAILED: {
      const { error } = action;

      let message = 'La petición no se ha completado con éxito.';
      let status = 0;

      if (error.response && error.response.data && error.response.data.message) {
        ({ message } = error.response.data);
        ({ status } = error.response);
      }

      utils.dialog({
        type: status === 422 ? 'warning' : 'error',
        title: status === 422 ? 'Error de validación' : 'Error inesperado',
        message,
      });
      return {
        ...state,
        loading: false,
      };
    }
    case LIST:
    case GET:
    case UPDATE: {
      return {
        ...state,
        updating: true,
        loading: true,
      };
    }
    case REMOVE:
    case CREATE: {
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_SUCCEEDED: {
      const { data } = action;
      return {
        ...state,
        list: data,
        loading: false,
      };
    }
    case GET_SUCCEEDED: {
      return {
        ...state,
        reload: true,
        loading: false,
      };
    }
    case UPDATE_SUCCEEDED: {
      return {
        ...state,
        updating: false,
        loading: false,
      };
    }
    case REMOVE_SUCCEEDED:
    case CREATE_SUCCEEDED: {
      utils.dialog({
        title: '¡Perfecto!',
        message: 'La petición se ha procesado con éxito',
      });

      return {
        ...state,
        loading: false,
      };
    }
    case SET: {
      const { key, value } = action;

      return {
        ...state,
        single: {
          ...state.single,
          [key]: value,
        },
      };
    }
    case RESET: {
      return {
        ...state,
        single: initialState.single,
      };
    }
    default:
      return state;
  }
};

export default gradeReducer;
