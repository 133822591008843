import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import moment from 'moment';

const dateFormat = 'DD/MM/YYYY';

const PagantisInfo = ({ order, onClose }) => (
  <div className="modal display-block">
    <section className="modal-main">
      <div className="modal-form">
        <div className="columns is-multiline is-vcentered">
          <div className="column is-full">
            <b>Id de orden:</b> {order.orderId}
          </div>
          <div className="column is-full">
            <b>Nombre:</b> {order.name}
          </div>
          <div className="column is-full">
            <b>E-mail:</b> {order.email}
          </div>
          <div className="column is-half">
            <b>Referencia:</b> {order.reference}
          </div>
          <div className="column is-half">
            <b>Estado:</b> {order.status}
          </div>
          <div className="column is-half">
            <b>Creado:</b> {moment(order.created).format(dateFormat)}
          </div>
          <div className="column is-half">
            <b>Confirmado:</b> {moment(order.confirmed).format(dateFormat)}
          </div>
          <div className="column is-full">
            <div className="modal-form-ok">
              <Button
                color="primary"
                onClick={onClose}
              > OK
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
);

PagantisInfo.propTypes = {
  order: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PagantisInfo;
