import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import ReactTable from 'ui/ReactTable';
import { Button } from '@material-ui/core';

const Grid = ({ data }) => (
  <div>
    <ReactTable
      data={data}
      columns={[
        {
          Header: 'ID',
          accessor: 'idAcademy',
          maxWidth: 30,
        },
        {
          Header: 'ID Alexia',
          accessor: 'alexiaId',
          maxWidth: 120,
        },
        {
          Header: 'Nombre',
          accessor: 'name',
          maxWidth: 300,
        },
        {
          Header: 'Cohort',
          accessor: 'cohort',
          maxWidth: 300,
        },
        {
          // separator
          resizable: false,
        },
        {
          Header: 'Acciones',
          fixed: 'right',
          columns: [
            {
              Cell: row => (
                <Link to={`/centros/editar/${row.original.idAcademy}`}>
                  <Button>Editar</Button>
                </Link>
              ),
              maxWidth: 100,
              resizable: false,
              sortable: false,
            },
          ],
        },
      ]}
    />
  </div>
);

Grid.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Grid;
