export const REQUEST_FAILED = 'DISCOUNTS_REQUEST_FAILED';
export const LIST = 'DISCOUNTS_LIST';
export const LIST_SUCCEEDED = 'DISCOUNTS_LIST_SUCCEEDED';
export const BEFORE_CREATE = 'DISCOUNT_BEFORE_CREATE';
export const BEFORE_CREATE_SUCCEEDED = 'DISCOUNT_BEFORE_CREATE_SUCCEEDED';
export const GET = 'DISCOUNT_GET';
export const GET_SUCCEEDED = 'DISCOUNT_GET_SUCCEEDED';
export const UPDATE = 'DISCOUNT_UPDATE';
export const UPDATE_SUCCEEDED = 'DISCOUNT_UPDATE_SUCCEEDED';
export const REMOVE = 'DISCOUNT_REMOVE';
export const REMOVE_SUCCEEDED = 'DISCOUNT_REMOVE_SUCCEEDED';
export const CREATE = 'DISCOUNT_CREATE';
export const CREATE_SUCCEEDED = 'DISCOUNT_CREATE_SUCCEEDED';
export const SET = 'DISCOUNT_SET';
export const RESET = 'DISCOUNT_RESET';
