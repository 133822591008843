import React from 'react';
import PropTypes from 'prop-types';

import {
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import { Wrapper, PlanHeaderWrapper, PlanRowWrapper } from './CoursesForm.styles';

class BasicForm extends React.Component {
  onCheckCourse = (id, checked) => {
    const { data, set } = this.props;
    set(
      'selectedTrainingPlans',
      [
        // always remove checked/unchecked id
        ...data.selectedTrainingPlans.filter(p => p !== id),

        // then add checked id if proceeds
        ...(checked ? [id] : []),
      ],
    );
  }

  selectAll = (trainingPlans, checked) => {
    const array = [];
    if (checked) {
      trainingPlans.forEach((element, index) => {
        array.push(index + 1);
      });
    }
    // this.setState({ selectedAllTrainingPlans: array });
    const { set } = this.props;
    set(
      'selectedTrainingPlans', array,
    );
  }

  render() {
    const {
      data: {
        selectedTrainingPlans,
      },
      ui: {
        trainingPlans,
      },
    } = this.props;

    const bulmaCols = {
      1: 'is-1',
      2: 'is-3',
      3: 'is-2',
      4: '',
    };

    return (
      <Wrapper>
        <PlanHeaderWrapper>
          <div className="columns">
            <div className={`column ${bulmaCols[1]} checkbox`}>
              <Checkbox
                onChange={e => this.selectAll(trainingPlans, e.target.checked)}
                checked={selectedTrainingPlans.length === trainingPlans.length}
              />
            </div>
            <div className={`column ${bulmaCols[2]}`}>
              Ciclo
            </div>
            <div className={`column ${bulmaCols[3]}`}>
              Metodología
            </div>
            <div className={`column ${bulmaCols[4]}`}>
              Estado
            </div>
          </div>
        </PlanHeaderWrapper>
        <div>
          {trainingPlans.map((plan) => {
            const {
              idTrainingPlan,
              courseName,
              methodologyName,
              isActive,
            } = plan;

            return (
              <PlanRowWrapper key={idTrainingPlan}>
                <div className="columns is-vcentered">
                  <div className={`column ${bulmaCols[1]}`}>
                    <FormControlLabel
                      control={<Checkbox color="primary" />}
                      checked={!!selectedTrainingPlans.find(id => id === idTrainingPlan)}
                      onChange={e => this.onCheckCourse(idTrainingPlan, e.target.checked)}
                    />
                  </div>
                  <div className={`column ${bulmaCols[2]}`}>
                    {courseName}
                  </div>
                  <div className={`column ${bulmaCols[3]}`}>
                    {methodologyName}
                  </div>
                  <div className={`column ${bulmaCols[4]}`}>
                    {isActive ? 'Activo' : 'Inactivo'}
                  </div>
                </div>
              </PlanRowWrapper>
            );
          })}
        </div>
      </Wrapper>
    );
  }
}

BasicForm.propTypes = {
  data: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  set: PropTypes.func.isRequired,
};

export default BasicForm;
