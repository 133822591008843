import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { ModuleWrapper } from './RoadMap.styles';
import RoadMapUnitRow from './RoadMapUnitRow';


class RoadMapModuleRowMethodology extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onCheckUnit = (idUnit, checked) => {
    const { checkunit, semester, module } = this.props;
    checkunit(module, idUnit, checked, semester);
  }

  unitChecked(idUnit) {
    const { units, semester } = this.props;

    return !!units.find(
      unit => idUnit === unit.idUnit
      && unit.semester === semester.semester
      && unit.courseYear === semester.courseYear,
    );
  }

  render() {
    const {
      itemText,
      module,
      checkmodule,
      semester,
      disabled,
      checked,
      idRoadMapModule,
    } = this.props;

    let unitList;
    if (module.Units.length > 0) {
      unitList = module.Units.map(
        unit => (
          <RoadMapUnitRow
            key={unit.idUnit}
            disabled={disabled}
            checked={this.unitChecked(unit.idUnit) || false}
            idUnit={unit.idUnit}
            checkunit={this.onCheckUnit}
            itemText={unit.code.toUpperCase().concat(': ').concat(unit.name)}
          />
        ),
      );
    }

    return (
      <div className="column is-half">
        <ModuleWrapper>
          <div className="columns is-vcentered">
            <div className="column is-1">
              <FormControlLabel
                disabled={disabled}
                control={<Checkbox color="primary" />}
                checked={checked}
                onChange={e => checkmodule(module, semester, e.target.checked, idRoadMapModule)}
              />
            </div>
            <div className="column">
              {itemText}
            </div>
          </div>
        </ModuleWrapper>
        {unitList}
      </div>
    );
  }
}

RoadMapModuleRowMethodology.propTypes = {
  checkmodule: PropTypes.func.isRequired,
  checkunit: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  itemText: PropTypes.string.isRequired,
  module: PropTypes.object.isRequired,
  semester: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  units: PropTypes.array.isRequired,
  idRoadMapModule: PropTypes.number.isRequired,
};

export default RoadMapModuleRowMethodology;
