import {
  select, call, put, takeEvery,
} from 'redux-saga/effects';
import api from 'linkia-api';

import {
  CREATE,
} from './action-types';
import {
  createSucceeded,
  createFailed,
} from './actions';

import { tokenSelector } from '../login/selectors';

function* createTrainingPlan(action) {
  const token = yield select(tokenSelector);
  const { data } = action;
  try {
    const success = yield call(api.trainingPlan.create, {
      token, data,
    });
    yield put(createSucceeded(success));
  } catch (e) {
    yield put(createFailed(e));
  }
}

export default [
  takeEvery(CREATE, createTrainingPlan),
];
