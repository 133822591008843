export const REQUEST_FAILED = 'MODULESEMESTERS_REQUEST_FAILED';
export const LIST = 'MODULESEMESTERS_LIST';
export const LIST_SUCCEEDED = 'MODULESEMESTERS_LIST_SUCCEEDED';
export const GET = 'MODULESEMESTER_GET';
export const GET_SUCCEEDED = 'MODULESEMESTER_GET_SUCCEEDED';
export const UPDATE = 'MODULESEMESTER_UPDATE';
export const UPDATE_SUCCEEDED = 'MODULESEMESTER_UPDATE_SUCCEEDED';
export const REMOVE = 'MODULESEMESTER_REMOVE';
export const REMOVE_SUCCEEDED = 'MODULESEMESTER_REMOVE_SUCCEEDED';
export const CREATE = 'MODULESEMESTER_CREATE';
export const CREATE_SUCCEEDED = 'MODULESEMESTER_CREATE_SUCCEEDED';
export const SET = 'MODULESEMESTER_SET';
export const RESET = 'MODULESEMESTER_RESET';
