import {
  PAYMENTMODES_REQUEST,
  PAYMENTMODES_REQUEST_SUCCEEDED,
  PAYMENTMODES_REQUEST_FAILED,
  PAYMENTMODES_UPDATE,
  PAYMENTMODES_UPDATE_SUCCEEDED,
  PAYMENTMODES_UPDATE_FAILED,
  PAYMENTMODES_SET_SELECTION,
  PAYMENTMODES_SET_PAYMENTINFO,
  PAYMENTMODES_SET_ACTIVE,
} from './action-types';


export const getPaymentModes = () => ({ type: PAYMENTMODES_REQUEST });
export const getPaymentModesSucceeded = result => (
  { type: PAYMENTMODES_REQUEST_SUCCEEDED, result }
);
export const getPaymentModesFailed = error => ({ type: PAYMENTMODES_REQUEST_FAILED, error });

export const updatePaymentMode = paymentMode => ({ type: PAYMENTMODES_UPDATE, paymentMode });
export const updatePaymentModeSucceeded = () => (
  { type: PAYMENTMODES_UPDATE_SUCCEEDED }
);
export const updatePaymentModeFailed = error => ({ type: PAYMENTMODES_UPDATE_FAILED, error });

export const setSelection = id => ({ type: PAYMENTMODES_SET_SELECTION, id });
export const setPaymentInfo = text => ({ type: PAYMENTMODES_SET_PAYMENTINFO, text });
export const setActiveInfo = () => ({ type: PAYMENTMODES_SET_ACTIVE });
