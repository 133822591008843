import {
  select, call, put, takeEvery,
} from 'redux-saga/effects';
import api from 'linkia-api';

import {
  PAYMENTMODES_REQUEST,
  PAYMENTMODES_UPDATE,
} from './action-types';
import {
  getPaymentModesSucceeded,
  getPaymentModesFailed,
  updatePaymentModeSucceeded,
  updatePaymentModeFailed,
} from './actions';


import { tokenSelector } from '../login/selectors';

function* getPaymentModes() {
  const token = yield select(tokenSelector);
  try {
    const data = yield call(api.paymentMode.getAll, { token });
    yield put(getPaymentModesSucceeded(data));
  } catch (e) {
    yield put(getPaymentModesFailed(e));
  }
}

function* updatePaymentMode(action) {
  try {
    const token = yield select(tokenSelector);
    const { paymentMode } = action;
    yield call(api.paymentMode.update, { token, paymentMode });
    yield put(updatePaymentModeSucceeded());
  } catch (e) {
    yield put(updatePaymentModeFailed(e));
  }
}

export default [
  takeEvery(PAYMENTMODES_REQUEST, getPaymentModes),
  takeEvery(PAYMENTMODES_UPDATE, updatePaymentMode),
];
