import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import theme from 'ui/theme';
import {
  TextField,
  Button,
} from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import themeFilters from 'ui/muiFiltersTheme';
import { Wrapper } from './Filters.styles';

class Filters extends React.Component {
  constructor(props) {
    super(props);
    const {
      name,
      code,
      reference,
    } = props.filtersApplied;
    this.state = {
      name,
      code,
      reference,
    };
  }

  onApplyFilters = (e) => {
    e.preventDefault();
    const { applyFilters } = this.props;

    const {
      code,
      name,
      reference,
    } = this.state;
    applyFilters({
      code,
      name,
      reference,
    });
  };

  resetFilters = async (e) => {
    e.preventDefault();
    this.setState({
      name: '',
      code: '',
      reference: '',
    });
  }

  handleChange = name => (e) => {
    this.setState({ [name]: e.target.value });
  }

  render() {
    const {
      name,
      reference,
      code,
    } = this.state;

    return (
      <Wrapper theme={theme}>
        <MuiThemeProvider theme={themeFilters}>
          <form onSubmit={this.onApplyFilters} className="filters-form">
            <div className="filters--simple-row">
              <TextField
                className="filter-item"
                id="code"
                placeholder="Código"
                onChange={this.handleChange('code')}
                value={code}
              />
              <TextField
                className="filter-item"
                id="reference"
                placeholder="Referencia"
                onChange={this.handleChange('reference')}
                value={reference}
              />
              <TextField
                className="filter-item"
                id="name"
                placeholder="Nombre"
                onChange={this.handleChange('name')}
                value={name}
              />
              <Button
                color="primary"
                type="submit"
                className="apply-filters-button"
              >
                APLICAR FILTROS
              </Button>
              <Button
                color="secondary"
                onClick={this.resetFilters}
                className="reset-filters-button"
              >
                LIMPIAR FILTROS
              </Button>
            </div>
          </form>
        </MuiThemeProvider>
      </Wrapper>
    );
  }
}

Filters.propTypes = {
  // dispatch: PropTypes.func.isRequired,
  applyFilters: PropTypes.func.isRequired,
  filtersApplied: PropTypes.object,
};

Filters.defaultProps = {
  filtersApplied: {},
};

export default connect(
  state => ({
    filtersApplied: state.vouchers.filters,
  }),
)(Filters);
