import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'ui/ReactTable';

import 'react-table/react-table.css';

import {
  TextField,
  FormControl,
} from '@material-ui/core';

class Grid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  updateMoodleCode = (row, moodleCode) => {
    const {
      data,
    } = this.props;

    const { index } = row;

    data[index].moodleCode = moodleCode;
    this.setState({
      // eslint-disable-next-line react/no-unused-state
      data,
    });
  }

  render() {
    const {
      data, onUpdate,
    } = this.props;

    return (
      <div>
        <ReactTable
          data={data}
          columns={[
            {
              Header: 'Code',
              accessor: 'code',
              width: 120,
            },
            {
              Header: 'Unique Code',
              accessor: 'uniqueCode',
              width: 120,
            },
            {
              Header: 'Course',
              accessor: 'trainingPlanCode',
              width: 150,
            },
            {
              Header: 'Modulo',
              accessor: 'name',
            },
            {
              Header: 'Moodle (Código Aula)',
              Cell: row => (
                <FormControl className="formControl" style={{ width: '100%' }}>
                  <TextField
                    fullWidth
                    value={row.original.moodleCode}
                    onChange={e => this.updateMoodleCode(row, e.target.value)}
                  />
                </FormControl>
              ),
            },
            {
              Header: 'Acciones',
              Cell: row => (
                <button color="primary" type="button" onClick={() => onUpdate(row.original.idModule, row.original.moodleCode, false)}>Guardar</button>
              ),
            },
          ]}
        />
      </div>
    );
  }
}

Grid.propTypes = {
  data: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default Grid;
