import styled from 'styled-components';

export const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const MenuDrawerWrapper = styled.div`
  padding: 30px 50px;
  max-width: 100%;

  @media (max-width: 800px) {
    padding: 30px;
  }

  .actions-container {
    display: flex;
    justify-content: flex-end;
    margin-top: -15px;
    margin-bottom: 15px;
  }

  .close-button {
    border: none;
    background: none;
    font-size: 28px;
    cursor: pointer;
  }
`;
