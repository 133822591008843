export const REQUEST_FAILED = 'COURSE_MODALITIES_REQUEST_FAILED';
export const LIST = 'COURSE_MODALITIES_LIST';
export const LIST_SUCCEEDED = 'COURSE_MODALITIES_LIST_SUCCEEDED';
export const GET = 'COURSE_MODALITY_GET';
export const GET_SUCCEEDED = 'COURSE_MODALITY_GET_SUCCEEDED';
export const UPDATE = 'COURSE_MODALITY_UPDATE';
export const UPDATE_SUCCEEDED = 'COURSE_MODALITY_UPDATE_SUCCEEDED';
export const REMOVE = 'COURSE_MODALITY_REMOVE';
export const REMOVE_SUCCEEDED = 'COURSE_MODALITY_REMOVE_SUCCEEDED';
export const CREATE = 'COURSE_MODALITY_CREATE';
export const CREATE_SUCCEEDED = 'COURSE_MODALITY_CREATE_SUCCEEDED';
export const SET = 'COURSE_MODALITY_SET';
export const RESET = 'COURSE_MODALITY_RESET';
