import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Wrapper, PlanHeaderWrapper, PlanRowWrapper, RedButton,
} from './CoursesForm.styles';

class BillingFormTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  compare = (a, b) => {
    if (Number(a.limit) < Number(b.limit)) return -1;
    if (Number(a.limit) > Number(b.limit)) return 1;
    return 0;
  }

  eraseBillingRule = (rule) => {
    const { data, set } = this.props;
    const { limit, commission } = rule;
    let index;
    data.billingRules.find((ruleScanned, i) => {
      index = i;
      return (ruleScanned.limit === limit && ruleScanned.commission === commission);
    });
    if (index !== undefined) {
      const newList = data.billingRules;
      newList.splice(index, 1);
      newList.sort(this.compare);
      set('billingRules', newList);
    } else {
      throw new Error('No se ha podido eliminar el registro');
    }
  }

  render() {
    const { data } = this.props;
    return (
      <Wrapper>
        <PlanHeaderWrapper>
          <div className="columns">
            <div className="column is-two-fifths">
              Facturación del Semestre
            </div>
            <div className="column is-two-fifths">
              Porcentaje de la facturación
            </div>
          </div>
        </PlanHeaderWrapper>
        {data.billingRules ? data.billingRules.map(rule => (
          <PlanRowWrapper key={rule.limit}>
            <div className="columns is-vcentered">
              <div className="column is-two-fifths">
                A partir de {rule.limit} €
              </div>
              <div className="column is-two-fifths">
                {rule.commission}%
              </div>
              <RedButton style={{ backgroundColor: 'transparent' }} onClick={() => this.eraseBillingRule(rule)}>
                Eliminar
              </RedButton>
            </div>
          </PlanRowWrapper>)) : ''}
      </Wrapper>
    );
  }
}

BillingFormTable.propTypes = {
  data: PropTypes.object,
  set: PropTypes.func.isRequired,
};

BillingFormTable.defaultProps = {
  data: {},
};


export default connect()(BillingFormTable);
