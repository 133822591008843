import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 8px;
  display: flex;
  align-items: center;

  .inputNewRoadMap {
    margin: 0px 8px;
    width: 200px;
  }
  .customizedNewRoadMap {
    margin: 0px 8px;
  }
`;
