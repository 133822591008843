import styled from 'styled-components';
import { Button } from '@material-ui/core';


export const Wrapper = styled.div`
  font-size: .8em;
  .checkbox {
    padding: 0 0 0 22px;
  }
`;

export const PlanHeaderWrapper = styled.div`
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 500;
  margin-top: 20px;
`;

export const PlanRowWrapper = styled.div`
  padding: 2px 0;
  padding-top: 8px;
  padding-bottom: 8px;
  border-top: 1px solid #ccc;
  font-size: 15px;
`;

export const RedButton = styled(Button)`
  color: #e05959 !important;
`;
