import {
  LIST,
  LIST_SUCCEEDED,
  LIST_FAILED,
  GET,
  GET_SUCCEEDED,
  GET_FAILED,
  ADD_UNIT,
  EDIT,
  EDIT_SUCCEEDED,
  CREATE_SUCCEEDED,
  SET_REQUIREMENTS,
  SET_UNIT_REQUIREMENTS,
  DELETE_UNIT,
  CLEAN_FILTERS_REQUEST,
  FILTERS_REQUEST,
  FILTERS_REQUEST_FAILED,
  FILTERS_REQUEST_SUCCEEDED,
  REQUEST_MODULE_PRICES,
  REQUEST_MODULE_PRICES_SUCCEEDED,
  REQUEST_MODULE_PRICES_FAILED,
  SET_MODIFICADO,
  REMOVE_MODIFICADOS,
  REQUEST_MODULE_PRICES_CONFIRM,
  REQUEST_MODULE_PRICES_CONFIRM_SUCCEEDED,
  REQUEST_MODULE_PRICES_CONFIRM_FAILED,
} from './action-types';
import utils from '../utils';

const initialState = {
  list: null,
  loading: false,
  single: null,
  loadingSingle: false,
  modificados: [],
};

const modulesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case FILTERS_REQUEST:
    case REQUEST_MODULE_PRICES:
    case DELETE_UNIT:
    case REQUEST_MODULE_PRICES_CONFIRM:
    case LIST: {
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_SUCCEEDED: {
      const { data } = action;
      return {
        ...state,
        list: data,
        loading: false,
      };
    }
    case LIST_FAILED: {
      utils.dialog({
        type: 'error',
        title: 'Error al recibir los hilos',
        message: 'Lo sentimos, se ha producido un error inesperado...',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case GET: {
      return {
        ...state,
        loadingSingle: true,
      };
    }
    case GET_SUCCEEDED: {
      const { data } = action;
      return {
        ...state,
        single: data.module,
        educationalLevels: data.educationalLevelsOptions,
        loadingSingle: false,
      };
    }
    case GET_FAILED: {
      utils.dialog({
        type: 'error',
        title: 'Error al recibir el módulo',
        message: 'Lo sentimos, se ha producido un error inesperado...',
      });
      return {
        ...state,
        loadingSingle: false,
      };
    }
    case ADD_UNIT: {
      const { unitObject } = action;
      const { single } = state;
      const { single: { Units: units } } = state;
      units.push({ ...unitObject, idUnit: undefined });
      return {
        ...state,
        single: {
          ...single,
          Units: units,
        },
      };
    }
    case EDIT: {
      return {
        ...state,
        loading: true,
      };
    }
    case EDIT_SUCCEEDED: {
      const { data } = action;
      utils.dialog({
        title: 'Cambios guardados',
        message: 'Se ha editado el módulo con éxito.',
      });
      return {
        ...state,
        single: data.module,
        educationalLevelsOptions: data.educationalLevelsOptions,
        loading: false,
      };
    }

    case CREATE_SUCCEEDED: {
      utils.dialog({
        title: 'Módulo Creado',
        message: 'Se ha creado el módulo con éxito.',
      });
      return {
        ...state,
      };
    }

    case SET_REQUIREMENTS: {
      const { newList } = action;
      return {
        ...state,
        single: {
          ...state.single,
          ModuleRequirements: newList,
        },
      };
    }

    case SET_UNIT_REQUIREMENTS: {
      const { newList } = action;
      return {
        ...state,
        single: {
          ...state.single,
          UnitRequirements: newList,
        },
      };
    }

    case CLEAN_FILTERS_REQUEST: {
      return {
        ...state,
        filters: {},
      };
    }

    case FILTERS_REQUEST_SUCCEEDED: {
      const { filtersData } = action;
      return {
        ...state,
        filtersData,
        loading: false,
      };
    }
    case FILTERS_REQUEST_FAILED: {
      utils.dialog({
        type: 'error',
        title: 'Error de carga',
        message: 'No se ha podido traer la información referente a los filtros',
      });
      return {
        ...state,
        loading: false,
      };
    }

    case REQUEST_MODULE_PRICES_SUCCEEDED: {
      const {
        data,
        pages,
        bindingObject: { filters },
      } = action;
      return {
        ...state,
        modules: data,
        filters,
        pages,
        loading: false,
      };
    }
    case REQUEST_MODULE_PRICES_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error inesperado',
        message: 'No se ha podido cargar los precios de los modulos',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case REQUEST_MODULE_PRICES_CONFIRM_SUCCEEDED: {
      return {
        ...state,
        modificados: [],
        loading: false,
      };
    }
    case REQUEST_MODULE_PRICES_CONFIRM_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error inesperado',
        message: 'No se han podido guardar los nuevos precios',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case SET_MODIFICADO: {
      const {
        idModule,
        precio,
        precioBase,
        originalPrecio,
        originalPrecioBase,
        codigo,
        tipoPrecio,
      } = action;
      const { modificados } = state;

      const module = modificados.find(m => m.idModule === idModule);
      const newModule = {
        idModule,
        precio: precio || null,
        precioBase: precioBase || null,
        originalPrecio,
        originalPrecioBase,
        codigo,
      };

      if (module) {
        if (tipoPrecio === 'precio') {
          module.precio = precio;
        }
        if (tipoPrecio === 'precioBase') {
          module.precioBase = precioBase;
        }
      }

      return {
        ...state,
        modificados: !module
          ? [...modificados, newModule]
          : modificados.filter(
            m => (m.precio !== m.originalPrecio || m.precioBase !== m.originalPrecioBase),
          ),
      };
    }
    case REMOVE_MODIFICADOS: {
      return {
        ...state,
        modificados: [],
      };
    }
    default:
      return state;
  }
};

export default modulesReducer;
