import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Wrapper, PlanHeaderWrapper, PlanRowWrapper, RedButton,
} from './EditModule.styles';

class ModuleReqTableUnits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  eraseRequirement = (requirement) => {
    const { data, set } = this.props;
    const { code, name } = requirement;
    let index;
    data.UnitRequirements.find((requirementScanned, i) => {
      index = i;
      return (requirementScanned.UnitRequired.code === code
        && requirementScanned.UnitRequired.name === name);
    });
    if (index !== undefined) {
      const newList = data.UnitRequirements;
      newList.splice(index, 1);
      set(newList);
    } else {
      throw new Error('No se ha podido eliminar la dependencia');
    }
  }

  render() {
    const { data } = this.props;
    if (data) {
      return (
        data.UnitRequirements.length > 0
          ? (
            <Wrapper>
              <PlanHeaderWrapper>
                <div className="columns">
                  <div className="column is-one-fifths">
                    Código
                  </div>
                  <div className="column is-three-fifths">
                    Nombre
                  </div>
                </div>
              </PlanHeaderWrapper>
              {data ? data.UnitRequirements.map(requirement => (
                <PlanRowWrapper key={requirement.UnitRequired.code}>
                  <div className="columns is-vcentered">
                    <div className="column is-one-fifths">
                      {requirement.UnitRequired.code}
                    </div>
                    <div className="column is-three-fifths">
                      {requirement.UnitRequired.name}
                    </div>
                    <RedButton style={{ backgroundColor: 'transparent' }} onClick={() => this.eraseRequirement(requirement)}>
                      Eliminar
                    </RedButton>
                  </div>
                </PlanRowWrapper>)) : ''}
            </Wrapper>
          )
          : ''
      );
    }
    return '';
  }
}

ModuleReqTableUnits.propTypes = {
  data: PropTypes.object,
  set: PropTypes.func.isRequired,
};

ModuleReqTableUnits.defaultProps = {
  data: {},
};


export default connect()(ModuleReqTableUnits);
