import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/es';

import {
  TextField,
  InputAdornment,
} from '@material-ui/core';
import DateRangePicker from 'ui/DateRangePicker';

moment.locale('es');

class BasicForm extends React.Component {
  state = {
    dates: {
      focusedInput: null,
    },
  }

  setDates = ({ startDate, endDate }) => {
    const { set } = this.props;
    set('startDate', startDate);
    set('endDate', endDate);
  }

  render() {
    const {
      data: {
        name,
        displayName,
        interest,
        enrollmentCost,
        startDate,
        endDate,
        minValue,
        maxValue,
      },
      set,
    } = this.props;
    const { dates } = this.state;

    return (
      <div className="columns is-multiline">
        <div className="column is-one-quarter">
          <TextField
            label="Nombre"
            fullWidth
            value={name}
            onChange={e => set('name', e.target.value)}
          />
        </div>
        <div className="column is-one-quarter">
          <TextField
            label="Nombre público"
            fullWidth
            value={displayName}
            onChange={e => set('displayName', e.target.value)}
          />
        </div>
        <div className="column is-one-quarter">
          <TextField
            label="Interés"
            fullWidth
            type="number"
            InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
            value={interest}
            onChange={e => set('interest', e.target.value)}
          />
        </div>
        <div className="column is-one-quarter">
          <TextField
            label="Coste matrícula"
            fullWidth
            type="number"
            InputProps={{ endAdornment: <InputAdornment position="end">€</InputAdornment> }}
            value={enrollmentCost}
            onChange={e => set('enrollmentCost', e.target.value)}
          />
        </div>
        <div className="column is-two-quarters">
          <DateRangePicker
            label="Rango de fechas"
            startDate={startDate ? moment(startDate) : null}
            startDateId="start_date"
            endDate={endDate ? moment(endDate) : null}
            endDateId="end_date"
            onDatesChange={newDates => this.setDates(newDates)}
            focusedInput={dates.focusedInput}
            onFocusChange={focusedInput => this.setState({ dates: { focusedInput } })}
          />
        </div>
        <div className="column is-one-quarter">
          <TextField
            label="Precio mínimo"
            fullWidth
            type="number"
            InputProps={{ endAdornment: <InputAdornment position="end">€</InputAdornment> }}
            value={minValue}
            onChange={e => set('minValue', e.target.value)}
          />
        </div>
        <div className="column is-one-quarter">
          <TextField
            label="Precio máximo"
            fullWidth
            type="number"
            InputProps={{ endAdornment: <InputAdornment position="end">€</InputAdornment> }}
            value={maxValue}
            onChange={e => set('maxValue', e.target.value)}
          />
        </div>
      </div>
    );
  }
}

BasicForm.propTypes = {
  data: PropTypes.object.isRequired,
  set: PropTypes.func.isRequired,
};

export default BasicForm;
