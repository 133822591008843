import {
  ADD_SERVICES_ACTION_BULK,
  ADD_SERVICES_ACTION_BULK_FAILED,
  ADD_SERVICES_ACTION_BULK_SUCCEED,
  ADD_SERVICES_ACTION_USER,
  ADD_SERVICES_ACTION_USER_FAILED,
  ADD_SERVICES_ACTION_USER_SUCCEED,
  GET_SERVICES_ACTIONS_FILTER_FILTERED,
  GET_SERVICES_ACTIONS_FILTER_FILTERED_FAILED,
  GET_SERVICES_ACTIONS_FILTER_FILTERED_SUCCEED,
  GET_SERVICES_ACTIONS_LOGS_FILTER_FILTERED,
  GET_SERVICES_ACTIONS_LOGS_FILTER_FILTERED_FAILED,
  GET_SERVICES_ACTIONS_LOGS_FILTER_FILTERED_SUCCEED,
  GET_SERVICES_ACTIONS_WAITING,
  GET_SERVICES_ACTIONS_WAITING_FAILED,
  GET_SERVICES_ACTIONS_WAITING_SUCCEED,
  GET_SERVICES_EXPORT,
  GET_SERVICES_EXPORT_FAILED,
  GET_SERVICES_EXPORT_SUCCEEDED,
  GET_SERVICES_FILTER_FILTERED,
  GET_SERVICES_FILTER_FILTERED_FAILED,
  GET_SERVICES_FILTER_FILTERED_SUCCEED,
  GET_SERVICES_LOG_FILTER_FILTERED,
  GET_SERVICES_LOG_FILTER_FILTERED_FAILED,
  GET_SERVICES_LOG_FILTER_FILTERED_SUCCEED,
  GET_SERVICES_STUDENT_GRADES_FILTER_FILTERED,
  GET_SERVICES_STUDENT_GRADES_FILTER_FILTERED_FAILED,
  GET_SERVICES_STUDENT_GRADES_FILTER_FILTERED_SUCCEED,
  REMOVE_SELECTIONS,
  REMOVE_SERVICES_ACTION_STATUS_FAILED,
  REMOVE_SERVICES_ACTION_STATUS_SUCCEED,
  RESET_SERVICES_ACTIONS_WAITING_FAILED,
  RESET_SERVICES_ACTIONS_WAITING_SUCCEED,
  SET_SELECTION,
  UPDATE_SERVICES_ACTION_STATUS,
  UPDATE_SERVICES_ACTION_STATUS_FAILED,
  UPDATE_SERVICES_ACTION_STATUS_SUCCEED,
} from './action-types';
import utils from '../utils';

const initialState = {
  list: null,
  pages: 0,
  loading: false,
  reload: false,
  selections: [],
};

const ServiceReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ADD_SERVICES_ACTION_USER:
    case GET_SERVICES_FILTER_FILTERED_FAILED:
    case GET_SERVICES_LOG_FILTER_FILTERED_FAILED:
    case GET_SERVICES_ACTIONS_FILTER_FILTERED_FAILED:
    case GET_SERVICES_ACTIONS_LOGS_FILTER_FILTERED_FAILED:
    case GET_SERVICES_STUDENT_GRADES_FILTER_FILTERED_FAILED:
    case ADD_SERVICES_ACTION_BULK_FAILED:
    case ADD_SERVICES_ACTION_USER_FAILED:
    case GET_SERVICES_ACTIONS_WAITING_FAILED:
    case RESET_SERVICES_ACTIONS_WAITING_FAILED:
    case UPDATE_SERVICES_ACTION_STATUS_FAILED:
    case REMOVE_SERVICES_ACTION_STATUS_FAILED: {
      const { error } = action;

      let message = 'La petición no se ha completado con éxito.';
      let status = 0;

      if (error && error.response && error.response.data && error.response.data.message) {
        ({ message } = error.response.data);
        ({ status } = error.response);
      }

      utils.dialog({
        type: status === 422 ? 'warning' : 'error',
        title: status === 422 ? 'Error de validación' : 'Error inesperado',
        message,
      });
      return {
        ...state,
        loading: false,
      };
    }
    case ADD_SERVICES_ACTION_BULK:
    case GET_SERVICES_FILTER_FILTERED:
    case GET_SERVICES_LOG_FILTER_FILTERED:
    case GET_SERVICES_ACTIONS_FILTER_FILTERED:
    case GET_SERVICES_ACTIONS_LOGS_FILTER_FILTERED:
    case GET_SERVICES_STUDENT_GRADES_FILTER_FILTERED:
    case GET_SERVICES_EXPORT:
    case GET_SERVICES_ACTIONS_WAITING:
    case UPDATE_SERVICES_ACTION_STATUS: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_SERVICES_FILTER_FILTERED_SUCCEED:
    case GET_SERVICES_ACTIONS_FILTER_FILTERED_SUCCEED:
    case GET_SERVICES_STUDENT_GRADES_FILTER_FILTERED_SUCCEED:
    case GET_SERVICES_ACTIONS_WAITING_SUCCEED: {
      const {
        data,
        pages,
      } = action;
      return {
        ...state,
        data,
        pages,
        loading: false,
      };
    }
    case GET_SERVICES_LOG_FILTER_FILTERED_SUCCEED:
    case GET_SERVICES_ACTIONS_LOGS_FILTER_FILTERED_SUCCEED: {
      const {
        data,
        pages,
      } = action;
      return {
        ...state,
        dataLog: data,
        pagesLog: pages,
        loading: false,
      };
    }
    case ADD_SERVICES_ACTION_BULK_SUCCEED:
    case ADD_SERVICES_ACTION_USER_SUCCEED:
    case RESET_SERVICES_ACTIONS_WAITING_SUCCEED:
    case UPDATE_SERVICES_ACTION_STATUS_SUCCEED:
    case REMOVE_SERVICES_ACTION_STATUS_SUCCEED: {
      utils.dialog({
        type: 'reload',
        title: '¡Perfecto!',
        message: 'La petición se ha procesado con éxito',
      });

      return {
        ...state,
        loading: false,
        reload: true,
      };
    }
    case GET_SERVICES_EXPORT_SUCCEEDED: {
      const { data } = action;
      const encodedUri = escape(data);
      const link = document.createElement('a');
      const file = 'facturacion.csv';
      link.setAttribute('href', `data:text/csv;charset=utf-8,%EF%BB%BF${encodedUri}`);
      link.setAttribute('download', file);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return {
        ...state,
        loading: false,
      };
    }
    case GET_SERVICES_EXPORT_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error en la exportación',
        message: 'No se ha podido exportar los servicios',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case SET_SELECTION: {
      const {
        id,
        value,
      } = action;
      const { selections } = state;

      return {
        ...state,

        // value === true -> add the element
        // value === false -> remove the element
        selections: value ? [id] : selections.filter(s => s !== id),
      };
    }
    case REMOVE_SELECTIONS: {
      return {
        ...state,
        selections: [],
      };
    }
    default:
      return state;
  }
};

export default ServiceReducer;
