import {
  REQUEST,
  REQUEST_SUCCEEDED,
  REQUEST_FAILED,
  LIST,
  LIST_SUCCEEDED,
  BEFORE_CREATE,
  BEFORE_CREATE_SUCCEEDED,
  GET,
  GET_SUCCEEDED,
  UPDATE,
  UPDATE_SUCCEEDED,
  REMOVE,
  REMOVE_SUCCEEDED,
  CREATE,
  CREATE_SUCCEEDED,
  SET,
  RESET,
} from './action-types';
import utils from '../utils';

const initialState = {
  data: [],
  list: null,
  ui: {
    trainingPlans: [],
  },
  single: {
    reference: '',
    code: '',
    name: '',
    discountValue: '',
    startDate: null,
    endDate: null,
    uses: 0,
    maxUses: 0,
    isCumulative: false,
    active: true,
    selectedTrainingPlan: 'none',
    calcType: 'P',
    reenroll: false,
    minValue: 0,
  },
  loading: false,
};

const vouchersReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case REQUEST_SUCCEEDED: {
      const {
        data,
        pages,
        origin,
        bindingObject: { filters },
      } = action;
      if (origin === 'vouchers') {
        return {
          ...state,
          pages,
          [origin]: data,
          loading: false,
          filters,
        };
      }
      return {
        ...state,
        [origin]: data,
        pages,
        loading: false,
      };
    }
    case REQUEST_FAILED: {
      const { error } = action;

      let message = 'La petición no se ha completado con éxito.';
      let status = 0;

      if (error.response && error.response.data && error.response.data.message) {
        ({ message } = error.response.data);
        ({ status } = error.response);
      }

      utils.dialog({
        type: status === 422 ? 'warning' : 'error',
        title: status === 422 ? 'Error de validación' : 'Error inesperado',
        message,
      });
      return {
        ...state,
        loading: false,
      };
    }
    case LIST:
    case BEFORE_CREATE:
    case GET:
    case UPDATE:
    case REMOVE:
    case CREATE: {
      return {
        ...state,
        loading: true,
      };
    }
    case LIST_SUCCEEDED: {
      const { data } = action;
      return {
        ...state,
        list: data,
        loading: false,
      };
    }
    case BEFORE_CREATE_SUCCEEDED: {
      const { ui } = action;
      return {
        ...state,
        ui,
        loading: false,
      };
    }
    case GET_SUCCEEDED: {
      const { data, ui } = action;
      const { reenroll } = data;
      return {
        ...state,
        single: {
          ...data,
          reenroll: !!reenroll,
        },
        ui,
        loading: false,
      };
    }
    case UPDATE_SUCCEEDED:
    case REMOVE_SUCCEEDED:
    case CREATE_SUCCEEDED: {
      utils.dialog({
        title: '¡Perfecto!',
        message: 'La petición se ha procesado con éxito',
      });

      return {
        ...state,
        loading: false,
      };
    }
    case SET: {
      const { key, value } = action;
      return {
        ...state,
        single: {
          ...state.single,
          [key]: value,
        },
      };
    }
    case RESET: {
      return {
        ...state,
        single: initialState.single,
      };
    }
    default:
      return state;
  }
};

export default vouchersReducer;
