import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Title from 'ui/Title';
import { Button } from '@material-ui/core';
import theme from 'ui/theme';
import { Wrapper } from './styles';
import Filters from './Filters';
import Grid from './Grid';

class UtmForms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {},
    };
  }

  applyFilters = (filtersSelected) => {
    this.setState({
      filters: filtersSelected,
    });
  }

  render() {
    const { filters, loading } = this.state;

    return (
      <Wrapper theme={theme}>
        <Title>UTM Forms
          <div>
            <Link to="/utms">
              <Button size="small">Ver todos</Button>
            </Link>
          </div>
        </Title>
        { loading }
        <Filters
          applyFilters={this.applyFilters}
        />
        <Grid
          filters={filters}
        />
      </Wrapper>
    );
  }
}

UtmForms.propTypes = {
};

UtmForms.defaultProps = {
};

export default connect(
  state => ({
    loading: state.utmForms.loading,
    result: state.utmForms.result,
  }),
)(UtmForms);
