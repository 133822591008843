export const LIST = 'MODULES_LIST';
export const LIST_SUCCEEDED = 'MODULES_LIST_SUCCEEDED';
export const LIST_FAILED = 'MODULES_LIST_FAILED';
export const GET = 'MODULE_GET';
export const GET_SUCCEEDED = 'MODULE_GET_SUCCEEDED';
export const GET_FAILED = 'MODULE_GET_FAILED';
export const CREATE = 'MODULE_CREATE';
export const CREATE_SUCCEEDED = 'MODULE_CREATE_SUCCEEDED';
export const CREATE_FAILED = 'MODULE_CREATE_FAILED';
export const EDIT = 'MODULE_EDIT';
export const EDIT_SUCCEEDED = 'MODULE_EDIT_SUCCEEDED';
export const EDIT_FAILED = 'MODULE_EDIT_FAILED';
export const ADD_UNIT = 'MODULE_ADD_UNIT';
export const SET_REQUIREMENTS = 'MODULE_SET_REQUIREMENTS';
export const SET_UNIT_REQUIREMENTS = 'MODULE_SET_UNIT_REQUIREMENTS';
export const DELETE_UNIT = 'MODULE_DELETE_UNIT';
export const CLEAN_FILTERS_REQUEST = 'MODULE_CLEAN_FILTERS_REQUEST';
export const FILTERS_REQUEST = 'MODULE_FILTERS_REQUEST';
export const FILTERS_REQUEST_SUCCEEDED = 'MODULE_FILTERS_REQUEST_SUCCEEDED';
export const FILTERS_REQUEST_FAILED = 'MODULE_FILTERS_REQUEST_FAILED';
export const REQUEST_MODULE_PRICES = 'REQUEST_MODULE_PRICES';
export const REQUEST_MODULE_PRICES_SUCCEEDED = 'REQUEST_MODULE_PRICES_SUCCEEDED';
export const REQUEST_MODULE_PRICES_FAILED = 'REQUEST_MODULE_PRICES_FAILED';
export const SET_MODIFICADO = 'MODULE_SET_MODIFICADO';
export const REMOVE_MODIFICADOS = 'MODULE_REMOVE_MODIFICADOS';
export const REQUEST_MODULE_PRICES_CONFIRM = 'REQUEST_MODULE_PRICES_CONFIRM';
export const REQUEST_MODULE_PRICES_CONFIRM_SUCCEEDED = 'REQUEST_MODULE_PRICES_CONFIRM_SUCCEEDED';
export const REQUEST_MODULE_PRICES_CONFIRM_FAILED = 'REQUEST_MODULE_PRICES_CONFIRM_FAILED';
