import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import Loading from '$routes/Loading';

import MessageBox from './MessageBox';
import SendBox from './SendBox';

const StyledDialog = withStyles({ paper: { padding: 50 } })(props => (
  <Dialog {...props}>{props.children}</Dialog>
));

const ThreadDialog = ({
  open,
  loading,
  data,
  idUser,
  closeThread,
  onClickUnreadMessage,
  onSendMessage,
}) => (
  <StyledDialog
    className="messages-dialog"
    open={open}
    onClose={closeThread}
    scroll="paper"
    aria-labelledby="scroll-dialog-title"
    fullWidth
    maxWidth="md"
  >
    <DialogTitle id="scroll-dialog-title">Mensajes</DialogTitle>
    <DialogContent>
      <div>
        {
          !loading ? (
            data && data.map(m => (
              <MessageBox
                key={`${idUser}/${m.sent}`}
                message={m}
                onClickUnread={onClickUnreadMessage}
              />
            ))
          ) : (
            <Loading />
          )
        }
      </div>
      <SendBox
        onSubmit={onSendMessage}
        idUser={idUser}
        loading={false}
        disabled={loading || !idUser}
      />
    </DialogContent>
  </StyledDialog>
);

ThreadDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array,
  idUser: PropTypes.number,
  closeThread: PropTypes.func.isRequired,
  onClickUnreadMessage: PropTypes.func.isRequired,
  onSendMessage: PropTypes.func.isRequired,
};

ThreadDialog.defaultProps = {
  data: null,
  idUser: null,
};

export default ThreadDialog;
