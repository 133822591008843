import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactTable from 'ui/ReactTable';
import 'react-table/react-table.css';
import theme from 'ui/theme';
import { requestPaymentLog as requestCandidates } from '$redux/candidates/actions';
import Failed from '$routes/Failed';
import { Wrapper } from './styles';

const PAYMENT_METHOD_TRANSFER = 0;
const PAYMENT_METHOD_STRIPE = 1;
const PAYMENT_METHOD_STRIPE_FRAC = 2;
const PAYMENT_METHOD_FINANCIADO_APLAZAME = 3;
const PAYMENT_METHOD_FINANCIADO_SABADELL = 4;

const STATUS_WAITING = 0;
const STATUS_SUCCESS = 1;
const STATUS_ERROR = 2;

class Grid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  fetchData = (state) => {
    const { dispatch } = this.props;
    const { pageSize, page } = state;
    const bindingObject = {
      pageSize,
      sorted: {},
      filters: {},
      page,
    };
    dispatch(requestCandidates(bindingObject));
  }

  render() {
    const {
      loading, pages, pageSize, data,
    } = this.props;
    if (!data) return <Failed />;

    return (
      <Wrapper theme={theme}>
        <ReactTable
          topBorder
          columns={[
            {
              Header: 'Metodo de pago',
              accessor: 'paymentMethod',
              Cell: (row) => {
                const {
                  original: { paymentMethod },
                } = row;
                if (paymentMethod === PAYMENT_METHOD_TRANSFER) return <p style={{ color: 'blue' }}>Transferencia</p>;
                if (paymentMethod === PAYMENT_METHOD_STRIPE) return <p style={{ color: 'orange' }}>Pago con Tarjeta</p>;
                if (paymentMethod === PAYMENT_METHOD_STRIPE_FRAC) return <p style={{ color: 'orange' }}>Pago con Tarjeta Fraccionado</p>;
                if (paymentMethod === PAYMENT_METHOD_FINANCIADO_APLAZAME) return <p style={{ color: 'orange' }}>Financiado Aplazame</p>;
                if (paymentMethod === PAYMENT_METHOD_FINANCIADO_SABADELL) return <p style={{ color: 'orange' }}>Financiado Sabadell</p>;

                return '';
              },
            },
            {
              Header: 'Estado',
              accessor: 'status',
              Cell: (row) => {
                const {
                  original: { status },
                } = row;
                if (status === STATUS_WAITING) return <p style={{ color: 'orange' }}>En espera</p>;
                if (status === STATUS_SUCCESS) return <p style={{ color: 'green' }}>Todo ha ido bien</p>;
                if (status === STATUS_ERROR) return <p style={{ color: 'red' }}>Error de carga</p>;

                return '';
              },
            },
            {
              Header: 'Mensaje',
              accessor: 'message',
              Cell: ({ value }) => value || '-',
            },
            {
              Header: 'Nombre',
              accessor: 'name',
              Cell: ({ value }) => value || '-',
            },
            {
              Header: 'Email',
              accessor: 'email',
              Cell: ({ value }) => value || '-',
            },
            {
              Header: 'Últ. actualización',
              accessor: 'updatedAt',
              Cell: ({ value }) => value || '-',
              maxWidth: 120,
              style: { textAlign: 'center' },
            },
            {
              Header: 'Creado',
              accessor: 'createdAt',
              Cell: ({ value }) => value || '-',
              maxWidth: 120,
              style: { textAlign: 'center' },
            },
          ]}
          manual
          defaultPageSize={pageSize}
          data={data}
          pages={pages} // Display the total number of pages
          loading={loading} // Display the loading overlay when we need it
          onFetchData={this.fetchData} // Request new data when things change
        />
      </Wrapper>
    );
  }
}

Grid.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.array,
  pages: PropTypes.number,
  pageSize: PropTypes.number,
  loading: PropTypes.bool.isRequired,
};

Grid.defaultProps = {
  data: [],
  pageSize: 10,
  pages: 0,
};

export default connect(
  state => ({
    data: state.candidates.data,
    pages: state.candidates.pages,
    loading: state.candidates.loading,
  }),
)(Grid);
