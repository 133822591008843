export const REQUEST_UTM_FILTERS = 'UTM_FILTERS';
export const REQUEST_UTM_FILTERS_SUCCEEDED = 'UTM_FILTERS_SUCCEEDED';
export const REQUEST_UTM_FILTERS_FAILED = 'UTM_FILTERS_FAILED';
export const REQUEST_UTM_EXPORT = 'UTM_EXPORT';
export const REQUEST_UTM_EXPORT_SUCCEEDED = 'UTM_EXPORT_SUCCEEDED';
export const REQUEST_UTM_EXPORT_FAILED = 'UTM_EXPORT_FAILED';

export const REQUEST_UTM_EXPORT_WITH_ENROLL = 'UTM_EXPORT_WITH_ENROLL';
export const REQUEST_UTM_EXPORT_WITH_ENROLL_SUCCEEDED = 'UTM_EXPORT__WITH_ENROLL_SUCCEEDED';
export const REQUEST_UTM_EXPORT_WITH_ENROLL_FAILED = 'UTM_EXPORT__WITH_ENROLL_FAILED';
