import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import UnitItem from './UnitItem';

class ModuleItem extends React.Component {
  changeModuleCost = (event) => {
    const {
      module,
      setModuleCost,
    } = this.props;

    const price = event.target.value;
    const cost = (price * 1).toFixed(2);

    setModuleCost(module.idModule, cost);
  }

  render() {
    let costModule;
    let amountModule;

    const {
      module,
      isMaster,
      idEnrollmentTransaction,
      setModule,
      setUnit,
      setUnitCost,
    } = this.props;

    if (module.cost) {
      costModule = (
        <TextField
          fullWidth
          type="number"
          inputProps={{
            style: {
              textAlign: 'right',
              paddingRight: '12px',
            },
          }}
          disabled={module.Units.length > 0}
          value={(module.cost * 1).toFixed(2)}
          onChange={e => this.changeModuleCost(e)}
        />
      );
      if (!isMaster) {
        if (idEnrollmentTransaction > 0 || module.Units.length > 0) {
          amountModule = (
            <div className="column is-one-fifths amount">
              <TextField
                fullWidth
                inputProps={{
                  style: {
                    textAlign: 'right',
                    paddingRight: '12px',
                  },
                }}
                value={(module.amount * 1).toFixed(2)}
              />
            </div>
          );
        } else {
          amountModule = (
            <div className="column is-one-fifths amount">
              <TextField
                fullWidth
                type="number"
                inputProps={{
                  style: {
                    textAlign: 'right',
                    paddingRight: '12px',
                  },
                }}
                onChange={e => setModule(module.idModule, e.target.value)}
                value={(module.amount * 1).toFixed(2)}
              />
            </div>
          );
        }
      }
    }

    const unitList = module.Units.map(item => (
      <UnitItem
        key={item.idUnit}
        unit={item}
        setUnit={setUnit}
        idModule={module.idModule}
        isMaster={isMaster}
        idEnrollmentTransaction={idEnrollmentTransaction}
        setUnitCost={setUnitCost}
      />));

    const moduleItem = (
      <div>
        <div className="columns is-vcentered modules">
          <div className="column is-one-fifths">{module.code}</div>
          <div className="column is-three-fifths">{module.name} Semestre { module.semester } año { module.courseYear }</div>
          <div className="column is-one-fifths amount">
            {costModule}
          </div>
          {amountModule}
        </div>
        {unitList}
      </div>
    );
    return moduleItem;
  }
}

ModuleItem.propTypes = {
  module: PropTypes.object.isRequired,
  isMaster: PropTypes.bool.isRequired,
  idEnrollmentTransaction: PropTypes.number,
  setModule: PropTypes.func.isRequired,
  setUnit: PropTypes.func.isRequired,
  setModuleCost: PropTypes.func.isRequired,
  setUnitCost: PropTypes.func.isRequired,
};

ModuleItem.defaultProps = {
  idEnrollmentTransaction: 0,
};
export default ModuleItem;
