import React from 'react';
import PropTypes from 'prop-types';
import SubTitle from 'ui/SubTitle';

import BasicForm from './BasicForm';
import MethodologyForm from './MethodologyForm';

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      data,
      addRoadMap,
      handleMoodleCode,
      set,
      reload,
      semesters,
      modalities,
      categories,
      saveCourse,
      updateSemester,
      handleActive,
      handleActiveOldTP,
      onClickDelete,
    } = this.props;
    return (
      <div>
        <SubTitle>Información del ciclo</SubTitle>
        <BasicForm
          key={`${data.name}`}
          data={data}
          set={set}
          modalities={modalities}
          categories={categories}
          saveCourse={saveCourse}
        />
        <SubTitle>Metodologías, itinerarios, módulos y UFs</SubTitle>
        <MethodologyForm
          data={data}
          addRoadMap={addRoadMap}
          reload={reload}
          semesters={semesters}
          updateSemester={updateSemester}
          handleActive={handleActive}
          handleActiveOldTP={handleActiveOldTP}
          handleMoodleCode={handleMoodleCode}
          onClickDelete={onClickDelete}
        />
      </div>
    );
  }
}


Form.propTypes = {
  data: PropTypes.object.isRequired,
  addRoadMap: PropTypes.func.isRequired,
  set: PropTypes.func.isRequired,
  reload: PropTypes.func.isRequired,
  semesters: PropTypes.array.isRequired,
  modalities: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  saveCourse: PropTypes.func.isRequired,
  updateSemester: PropTypes.func.isRequired,
  handleActive: PropTypes.func.isRequired,
  handleActiveOldTP: PropTypes.func.isRequired,
  handleMoodleCode: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
};

export default Form;
