export const REQUEST_FAILED = 'UTM_FORM_COURSES_REQUEST_FAILED';
export const LIST = 'UTM_FORM_COURSES_LIST';
export const LIST_SUCCEEDED = 'UTM_FORM_COURSES_LIST_SUCCEEDED';
export const GET = 'UTM_FORM_COURSE_GET';
export const GET_SUCCEEDED = 'UTM_FORM_COURSE_GET_SUCCEEDED';
export const UPDATE = 'UTM_FORM_COURSE_UPDATE';
export const UPDATE_SUCCEEDED = 'UTM_FORM_COURSE_UPDATE_SUCCEEDED';
export const REMOVE = 'UTM_FORM_COURSE_REMOVE';
export const REMOVE_SUCCEEDED = 'UTM_FORM_COURSE_REMOVE_SUCCEEDED';
export const CREATE = 'UTM_FORM_COURSE_CREATE';
export const CREATE_SUCCEEDED = 'UTM_FORM_COURSE_CREATE_SUCCEEDED';
export const SET = 'UTM_FORM_COURSE_SET';
export const RESET = 'UTM_FORM_COURSE_RESET';
