import dotenv from 'dotenv';
import {
  select, call, put, takeEvery,
} from 'redux-saga/effects';
import api from 'linkia-api';

import {
  LIST,
  GET,
  EDIT,
} from './action-types';
import {
  listSucceeded,
  listFailed,
  getSucceeded,
  getFailed,
  editSucceeded,
} from './actions';

import { tokenSelector } from '../login/selectors';

dotenv.config();
const { NODE_ENV: env } = process.env;

function* reqThreads() {
  const token = yield select(tokenSelector);
  try {
    const threads = yield call(api.staff.me.getThreads, { token });
    yield put(listSucceeded(threads));
  } catch (e) {
    // eslint-disable-next-line no-console
    if (env === 'development' || !e.response || e.response.status !== 401) console.error(e);
    yield put(listFailed());
  }
}

function* reqGetUnit(action) {
  const token = yield select(tokenSelector);
  const { idUnit } = action;
  try {
    const data = yield call(api.unit.get, { token, idUnit });
    yield put(getSucceeded(data, idUnit));
  } catch (e) {
    // eslint-disable-next-line no-console
    if (env === 'development' || !e.response || e.response.status !== 401) console.error(e);
    yield put(getFailed());
  }
}

function* reqEditUnit(action) {
  const token = yield select(tokenSelector);
  const { idUnit, unitEdit } = action;
  try {
    const data = yield call(api.unit.update, { token, idUnit, unitEdit });
    yield put(editSucceeded(data));
  } catch (e) {
    // eslint-disable-next-line no-console
    if (env === 'development' || !e.response || e.response.status !== 401) console.error(e);
    yield put(getFailed());
  }
}

export default [
  takeEvery(LIST, reqThreads),
  takeEvery(GET, reqGetUnit),
  takeEvery(EDIT, reqEditUnit),
];
