import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactTable from 'ui/ReactTable';
import 'react-table/react-table.css';
import {
  TextField,
  FormControl,
} from '@material-ui/core';
import theme from 'ui/theme';
import {
  list as reqGradeStudentGet,
  update as reqGradeStudentUpdate,
} from '$redux/grades/actions';
import Failed from '$routes/Failed';
import { Wrapper } from './Grid.styles';

class Grid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { idUser, idEnrollment } = this.props;

    dispatch(
      reqGradeStudentGet(
        idUser,
        idEnrollment,
      ),
    );
  }

  componentDidUpdate(prevProps) {
    const { dispatch } = this.props;
    const { idUser, idEnrollment } = this.props;

    if (prevProps.idEnrollment !== idEnrollment) {
      dispatch(
        reqGradeStudentGet(
          idUser,
          idEnrollment,
        ),
      );
    }
  }

  onUpdate = (row) => {
    const { dispatch, idUser } = this.props;
    const request = {
      idUser,
      grades: row.grades,
    };
    dispatch(
      reqGradeStudentUpdate(request),
    );
  }

  updateScore = (row, score, name) => {
    const {
      data,
    } = this.props;

    const clean = function validate(s) {
      return parseInt(s.replace(/[^\d.]/g, ''), 10);
    };

    const { index } = row;
    const gradeIndex = data[index].grades.findIndex(grade => grade.call.endsWith(name));
    if (gradeIndex > -1) {
      data[index].grades[gradeIndex].score = clean(score);
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        data,
      });
    }
  }

  // eslint-disable-next-line arrow-body-style
  getCompundV = (grades, name) => {
    if (!grades) {
      return '';
    }
    const found = grades.find(grade => grade.call === name);
    return !found || !found.score ? '' : found.score;
  }

  getScoreMoodle = (grades, name) => {
    if (!grades) {
      return '';
    }
    const found = grades.find(grade => grade.call.endsWith(name));
    return !found || !found.score ? '' : found.score;
  }


  getTrProps = (state, rowInfo) => {
    if (rowInfo) {
      return {
        style: {
          background: rowInfo.original.type === 'u' ? '#38bdf8' : '#a78bfa',
        },
      };
    }
    return {};
  }

  render() {
    const {
      loading, data,
    } = this.props;

    if (!data) return <Failed />;
    if (data.length === 0) {
      return (
        <center>
          <b>
            No hemos encontrado las notas para este estudiante
          </b>
        </center>
      );
    }

    // 1. ESTAS FUNCOINES CELL SERAN DINAMICAS Y DEPNEDIENDO DEL TIPO (M O U)
    // DEVOLVERAN UNOS DATOS U OTROS
    return (
      <Wrapper theme={theme}>
        <ReactTable
          topBorder
          columns={[
            {
              Header: 'Codigo',
              accessor: 'code',
              Cell: ({ value }) => value || '-',
            },
            {
              Header: 'IU',
              accessor: 'id',
              Cell: ({ value }) => value || '-',
            },
            {
              Header: 'Nombre',
              accessor: 'name',
              width: 340,
              Cell: ({ value }) => value || '-',
            },
            {
              Header: 'UFs',
              accessor: 'ufs',
              Cell: ({ value }) => value || '-',
            },
            {
              Header: 'Horas',
              accessor: 'hours',
              Cell: ({ value }) => value || '-',
            },
            {
              Header: 'OR',
              Cell: row => this.getScoreMoodle(row.original.grades, 'OR') || '-',
            },
            {
              Header: 'XT',
              Cell: row => this.getScoreMoodle(row.original.grades, 'XT') || '-',
            },
            {
              Header: 'LINKIA',
              Cell: row => (
                <FormControl className="formControl" style={{ width: '80px' }}>
                  {
                  row.original.type === 'u' && (row.original.grades.findIndex(grade => grade.call.endsWith('LK')) > -1)
                    ? (
                      <TextField
                        fullWidth
                        value={this.getScoreMoodle(row.original.grades, 'LK')}
                        onChange={e => this.updateScore(row, e.target.value, 'LK')}
                      />
                    )
                    : ''
                  }
                </FormControl>
              ),
            },
            {
              Header: 'Acciones',
              Cell: row => (
                row.original.type === 'u'
                  ? (
                    <button color="primary" type="button" onClick={() => this.onUpdate(row.original)}>Actualizar</button>
                  )
                  : ''
              ),
            },
          ]}
          getTrProps={this.getTrProps}
          manual
          data={data}
          loading={loading} // Display the loading overlay when we need it
          showPaginationBottom={false}
        />
      </Wrapper>
    );
  }
}

Grid.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  idUser: PropTypes.string.isRequired,
  idEnrollment: PropTypes.number.isRequired,
};

Grid.defaultProps = {
  data: [],
};

export default connect(
  state => ({
    data: state.grades.list,
    loading: state.grades.loading,
  }),
)(Grid);
