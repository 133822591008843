import {
  select, call, put, takeEvery,
} from 'redux-saga/effects';
import api from 'linkia-api';

import {
  REQUEST_GET_ALL_MODULE_CODES,
  REQUEST_UPDATE_ALL_MODULE_CODES,
} from './action-types';

import {
  reqGetAllModuleCodesSucceeded,
  reqGetAllModuleCodesFailed,
  reqUpdateAllModuleCodesSucceeded,
  reqUpdateAllModuleCodesFailed,
} from './actions';


import { tokenSelector } from '../login/selectors';

function* actionGetAllModuleCodes(action) {
  const token = yield select(tokenSelector);
  try {
    const { idSemester } = action;
    const data = yield call(api.moduleCode.getAllModulesCode, {
      token,
      bindingObject: {
        filters: {
          idSemester,
        },
      },
    });
    yield put(reqGetAllModuleCodesSucceeded(data));
  } catch (e) {
    yield put(reqGetAllModuleCodesFailed());
  }
}

function* actionUpdateAllModuleCodes(action) {
  const token = yield select(tokenSelector);
  try {
    const { bindingObject } = action;
    const data = yield call(api.moduleCode.updateAllModulesCode, {
      token,
      bindingObject,
    });
    yield put(reqUpdateAllModuleCodesSucceeded(data));
  } catch (e) {
    yield put(reqUpdateAllModuleCodesFailed());
  }
}

export default [
  takeEvery(
    REQUEST_GET_ALL_MODULE_CODES,
    actionGetAllModuleCodes,
  ),
  takeEvery(
    REQUEST_UPDATE_ALL_MODULE_CODES,
    actionUpdateAllModuleCodes,
  ),
];
