import { createMuiTheme } from '@material-ui/core/styles';

import uiTheme from './theme';

//
// VARS
// --------------------------------------------------

// ...


//
// CUSTOM THEME BASE
// --------------------------------------------------

const theme = createMuiTheme({
  // custom colors via material's color tool:
  // https://goo.gl/T92kNk
  palette: {
    background: {
      default: '#fff !important',
    },
    primary: uiTheme.primary,
    secondary: uiTheme.secondary,
  },

  // other global properties
  shadows: Array(25).fill('none'),

  // typography variants mui error
  typography: {
    useNextVariants: true,
  },
});


//
// SPECIFIC UI COMPONENT CUSTOMIZATIONS
// --------------------------------------------------

// TextField
theme.overrides.MuiInputLabel = {
  root: {
    transform: 'none!important',
  },
};
theme.overrides.MuiInput = {
  root: {
    backgroundColor: '#e9e9e9',
    border: '1px solid #d0d0d0',
    boxShadow: '0px 4px 6px -2px rgba(0, 0, 0, 0.12)',
  },
  input: {
    paddingLeft: '12px',
    paddingTop: '10px',
    paddingBottom: '10px',
    fontSize: '14px',
  },
  underline: {
    '&:before': {
      borderBottom: '1px solid transparent',
    },
    '&:after': {
      borderBottom: '1px solid transparent',
    },
    '&:hover': {
      '&:before': {
        borderBottom: '1px solid transparent !important',
      },
    },
  },
};
theme.overrides.MuiInputAdornment = {
  root: {
    marginRight: 12,
    marginLeft: 12,
    maxHeight: 'none',
  },
};

// Typography
theme.overrides.MuiTypography = {
  root: {
    fontWeight: '300',
  },
  body1: {
    fontSize: '14px',
    fontWeight: '300',
    lineHeight: '1.6em',
  },
  gutterBottom: {
    width: '100%',
  },
};

theme.overrides.MuiFormControl = {
  root: {
    '&>.check-policy': {
      margin: '10px auto 0px',
    },
  },
};
theme.overrides.MuiFormLabel = {
  root: {
    fontSize: '14px',
  },
};

theme.overrides.MuiFormControlLabel = {
  root: {
    marginLeft: '0px',
    marginRight: '0px',
    paddingLeft: '10px',
  },
  label: {
    fontSize: '12px',
    // marginLeft: '20px',
  },
};

// Select
theme.overrides.MuiSelect = {
  root: {
    backgroundColor: '#ececec',
  },
  select: {
    paddingLeft: '12px',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingRight: '0px',
    '&:focus': {
      background: '#e9e9e9',
    },
  },
};

// Popover (for selects and other droppable menus)
theme.overrides.MuiPopover = {
  paper: {
    borderRadius: 0,
    backgroundColor: '#e9e9e9',
    border: '1px solid #d0d0d0',
    boxShadow: '0px 4px 6px -2px rgba(0, 0, 0, 0.12)',
  },
};

// Radio
theme.overrides.MuiRadio = {
  root: {
    width: '30px',
  },
};

// Button
theme.overrides.MuiButton = {
  root: {
    borderRadius: '5px',
    padding: '10px 16px',
    minWidth: 'none',
  },
  flatPrimary: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  flatSecondary: {
    color: 'white',
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
};

// Tabs
theme.props.MuiTabs = {
  textColor: 'primary',
};
theme.overrides.MuiTab = {
  root: {
    flexGrow: 1,
    minWidth: 'none',
    maxWidth: 'none',
    height: 60,
    fontWeight: 700,
  },
  selected: {
    backgroundColor: theme.palette.background.default,
    fontWeight: 900,
  },
};

theme.overrides.MuiCheckbox = {
  root: {
    color: '#E0E0E0',
    width: '30px',
  },
  colorSecondary: {
    '&.collapsible-item-check-input': {
      color: 'black',
    },
    '&.collapsible-item-check-input2': {
      color: 'gray',
    },
  },
};

theme.overrides.MuiSvgIcon = {
  root: {
    '&.collapsible-item-check-box': {
      fontSize: '16px',
    },
    '&.collapsible-item-check-box2': {
      fontSize: '18px',
    },
  },
};
export default theme;
