import React from 'react';
import SubTitle from 'ui/SubTitle';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import theme from 'ui/theme';
import { Wrapper } from './styles';
import InfoChannelTable from './InfoChannelTable';
import InfoChannelNew from './InfoChannelNew';
import Loading from '$routes/Loading';
import { list, remove, create } from '$redux/info-channels/actions';

class InfoChannels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(list());
  }

  create = (name) => {
    const { dispatch } = this.props;
    dispatch(create(name));
  }

  remove = (id) => {
    const { dispatch } = this.props;
    dispatch(remove(id));
  }

  render() {
    const { data, loading, stats } = this.props;
    if (loading) return <Loading />;
    let table;
    if (data && data.length > 0) {
      table = <InfoChannelTable remove={this.remove} data={data} stats={stats} />;
    }

    return (
      <Wrapper theme={theme}>
        <SubTitle>Cómo nos has conocido?</SubTitle>
        <InfoChannelNew create={this.create} data={data} />
        {table}
      </Wrapper>
    );
  }
}
InfoChannels.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.array,
  stats: PropTypes.array,
  loading: PropTypes.bool.isRequired,
};

InfoChannels.defaultProps = {
  data: null,
  stats: null,
};

export default connect(
  state => ({
    data: state.infoChannels.list,
    stats: state.infoChannels.stats,
    loading: state.infoChannels.loading,
  }),
)(InfoChannels);
