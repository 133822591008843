import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography, TextField, Select, FormControl, MenuItem,
} from '@material-ui/core';
import { Wrapper } from './styles';
import SubTitle from '../../../../ui/SubTitle';

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    let streetType;
    let floor;
    let street;
    let door;
    let streetNumber;
    let zipCode;
    let blockNumber;
    let phoneNumber;
    let buildingNumber;
    let mobileNumber;
    let city;
    const {
      data, optionsData, disabled, set,
    } = this.props;

    if (data) {
      streetType = (
        <div className="column is-half">
          <div className="columns is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Tipo de vía</Typography>
            </div>
            <div className="column is-three-fifths">
              <FormControl fullWidth>
                <Select
                  disabled={disabled}
                  value={data.idStreetType ? data.idStreetType : ''}
                  onChange={e => set('contactInfo', 'idStreetType', e.target.value)}
                >
                  {optionsData.streetTypes.map(type => (
                    <MenuItem
                      key={type.idStreetType}
                      value={type.idStreetType}
                    >
                      {type.streetType}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      );
      floor = (
        <div className="column is-half">
          <div className="columns right is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Planta</Typography>
            </div>
            <div className="column is-three-fifths">
              <TextField
                disabled={disabled}
                fullWidth
                onChange={e => set('contactInfo', 'floor', e.target.value)}
                value={data.floor ? data.floor : ''}
              />
            </div>
          </div>
        </div>
      );
      street = (
        <div className="column is-half">
          <div className="columns is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Nombre de vía</Typography>
            </div>
            <div className="column is-three-fifths">
              <TextField
                fullWidth
                disabled={disabled}
                onChange={e => set('contactInfo', 'street', e.target.value)}
                value={data.street ? data.street : ''}
              />
            </div>
          </div>
        </div>
      );
      door = (
        <div className="column is-half">
          <div className="columns right is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Puerta</Typography>
            </div>
            <div className="column is-three-fifths">
              <TextField
                disabled={disabled}
                fullWidth
                onChange={e => set('contactInfo', 'door', e.target.value)}
                value={data.door ? data.door : ''}
              />
            </div>
          </div>
        </div>
      );
      streetNumber = (
        <div className="column is-half ">
          <div className="columns is-vcentered">
            <div className="column is-two-fifths">
              <Typography>N˚ de vivienda</Typography>
            </div>
            <div className="column is-three-fifths">
              <TextField
                fullWidth
                disabled={disabled}
                onChange={e => set('contactInfo', 'streetNumber', e.target.value)}
                value={data.streetNumber ? data.streetNumber : ''}
              />
            </div>
          </div>
        </div>
      );
      zipCode = (
        <div className="column is-half">
          <div className="columns right is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Código postal</Typography>
            </div>
            <div className="column is-three-fifths">
              <TextField
                fullWidth
                disabled={disabled}
                onChange={e => set('contactInfo', 'zipCode', e.target.value)}
                value={data.zipCode ? data.zipCode : ''}
              />
            </div>
          </div>
        </div>
      );
      blockNumber = (
        <div className="column is-half">
          <div className="columns is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Bloque</Typography>
            </div>
            <div className="column is-three-fifths">
              <TextField
                fullWidth
                disabled={disabled}
                onChange={e => set('contactInfo', 'blockNumber', e.target.value)}
                value={data.blockNumber ? data.blockNumber : ''}
              />
            </div>
          </div>
        </div>
      );
      phoneNumber = (
        <div className="column is-half">
          <div className="columns is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Teléfono</Typography>
            </div>
            <div className="column is-three-fifths">
              <TextField
                disabled={disabled}
                fullWidth
                onChange={e => set('contactInfo', 'phoneNumber', e.target.value)}
                value={data.phoneNumber ? data.phoneNumber : ''}
              />
            </div>
          </div>
        </div>
      );
      buildingNumber = (
        <div className="column is-half">
          <div className="columns is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Escalera</Typography>
            </div>
            <div className="column is-three-fifths">
              <TextField
                fullWidth
                disabled={disabled}
                onChange={e => set('contactInfo', 'buildingNumber', e.target.value)}
                value={data.buildingNumber ? data.buildingNumber : ''}
              />
            </div>
          </div>
        </div>
      );
      mobileNumber = (
        <div className="column is-half">
          <div className="columns right is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Móvil</Typography>
            </div>
            <div className="column is-three-fifths">
              <TextField
                fullWidth
                disabled={disabled}
                onChange={e => set('contactInfo', 'mobileNumber', e.target.value)}
                value={data.mobileNumber ? data.mobileNumber : ''}
              />
            </div>
          </div>
        </div>
      );
      city = (
        <div className="column is-half">
          <div className="columns right is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Municipio</Typography>
            </div>
            <div className="column is-three-fifths">
              <TextField
                fullWidth
                disabled={disabled}
                onChange={e => set('contactInfo', 'city', e.target.value)}
                value={data.city ? data.city : ''}
              />
            </div>
          </div>
        </div>
      );
    }

    const { title } = this.props;

    return (
      <Wrapper>
        <div className="student-data">
          <SubTitle>{title}</SubTitle>
          <div className="columns is-multiline is-narrow content">
            {streetType}
            {floor}
            {street}
            {door}
            {streetNumber}
            {zipCode}
            {blockNumber}
            {city}
            {buildingNumber}
            {mobileNumber}
            {phoneNumber}
          </div>
        </div>
      </Wrapper>);
  }
}

ContactForm.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object,
  optionsData: PropTypes.object,
  disabled: PropTypes.bool,
  set: PropTypes.func.isRequired,
};

ContactForm.defaultProps = {
  data: undefined,
  optionsData: {},
  disabled: false,
};

export default (ContactForm);
