import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button, FormControlLabel, Switch, Tab, Tabs, TextField,
} from '@material-ui/core';
import swal from 'sweetalert2';
import RoadMapTab from './RoadMapTab';
import AddRoadMap from '../Form/AddRoadMapForm';
import { Wrapper } from './MethodologyTab.styles';
import Module from '../EditModule';

import { create as createTrainginPlan } from '$redux/training-plans/actions';

// eslint-disable-next-line
class MethodologyTab extends React.Component {
  state = {
    selectedTab: 0,
    showModuleAdd: false,
  };

  hideModuleAdd = () => {
    const { reload } = this.props;
    this.setState({
      showModuleAdd: false,
    });
    reload();
  };

  showModuleAdd = () => {
    this.setState({
      showModuleAdd: true,
    });
  };

  handleChange = (event, value) => {
    const { reload } = this.props;
    reload();
    this.setState({ selectedTab: value });
  };

  setMethodologyCode = (methodology, value) => {
    // eslint-disable-next-line no-param-reassign
    methodology.moodleCode = value;
  };

  createTrainginPlan = (idCourse, idMethodology, moodleCode) => {
    const { dispatch } = this.props;
    if (!idCourse || !idMethodology) {
      swal('Modificación pendiente', 'No se ha podido crear, por favor contacte con el administrador.', 'warning');
      return false;
    }
    if (!moodleCode || moodleCode === '') {
      swal('Modificación pendiente', 'El Course Code es obligatorio', 'warning');
      return false;
    }

    dispatch(createTrainginPlan({
      idCourse,
      idMethodology,
      moodleCode,
    }));

    return true;
  };

  render() {
    const {
      methodology,
      addRoadMap,
      reload,
      semesters,
      updateSemester,
      handleActive,
      handleActiveOldTP,
      onClickDelete,
      handleMoodleCode,
      idCourse,
      dispatch,
    } = this.props;
    const {
      selectedTab,
      showModuleAdd,
    } = this.state;

    const allTabs = methodology.roadMaps.map(roadMap => (
      <Tab label={roadMap.name} key={roadMap.idRoadMap} />
    ));

    const currentRoadMap = methodology.roadMaps[selectedTab];
    const roadMapTab = currentRoadMap && (
      <RoadMapTab
        roadMap={currentRoadMap}
        reload={reload}
        methodology={methodology}
        semesters={semesters}
        updateSemester={updateSemester}
        onClickDelete={onClickDelete}
        dispatch={dispatch}
      />
    );

    const moduleAdd = showModuleAdd && (
      <Module
        moduleToShow={0}
        showModal={showModuleAdd}
        hideModuleEdit={this.hideModuleAdd}
        methodology={methodology}
      />
    );

    return (
      <Wrapper>
        {methodology.idTrainingPlan
          ? (
            <section>
              <div className="columns alignItems training-plan-activation">
                <div className="column is-one-fifth">Metodología activada</div>
                <FormControlLabel
                  control={<Switch color="primary" />}
                  checked={methodology.isActive ? true : false} // eslint-disable-line
                  onChange={e => handleActive(methodology.idTrainingPlan, e.target.checked)}
                />
              </div>
              <div className="columns alignItems training-plan-activation">
                <div className="column is-one-fifth">Metodología activada plan antiguo</div>
                <FormControlLabel
                  control={<Switch color="primary" />}
                  checked={methodology.showOldTpStudent ? true : false} // eslint-disable-line
                  onChange={e => handleActiveOldTP(methodology.idTrainingPlan, e.target.checked)}
                />
              </div>
              <div className="columns alignItems training-plan-code">
                <div className="column is-one-fifth">
                  <TextField
                    fullWidth
                    label="Course Code"
                    defaultValue={methodology.moodleCode}
                    onChange={e => this.setMethodologyCode(methodology, e.target.value)}
                    key={methodology.idTrainingPlan}
                  />
                </div>
                <div className="column is-four-fifth ">
                  <Button
                    color="primary"
                    onClick={
                      () => handleMoodleCode(methodology.idTrainingPlan, methodology.moodleCode)
                    }
                  >
                    Guardar
                  </Button>
                </div>
              </div>
              <div className="columns alignItems">
                <div className="column is-four-fifths">
                  <AddRoadMap
                    addRoadMap={addRoadMap}
                    idTrainingPlan={methodology.idTrainingPlan}
                  />
                </div>
                <div className="column is-one-fifth">
                  <Button color="primary" onClick={() => this.showModuleAdd()}>
                    Añadir módulo
                  </Button>
                </div>
              </div>
              <div className="muiTabs">
                <Tabs
                  value={selectedTab}
                  onChange={this.handleChange}
                  indicatorColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  {allTabs}
                </Tabs>
              </div>
              {moduleAdd}
              {roadMapTab}
            </section>
          )
          : (
            <section>
              <div className="columns alignItems training-plan-code">
                <div className="column is-one-fifth">
                  <TextField
                    fullWidth
                    label="Course Code"
                    defaultValue=""
                    onChange={e => this.setMethodologyCode(methodology, e.target.value)}
                  />
                </div>
                <div className="column is-four-fifth ">
                  <Button
                    color="primary"
                    onClick={
                      () => this.createTrainginPlan(
                        idCourse,
                        methodology.idMethodology,
                        methodology.moodleCode,
                      )
                    }
                  >
                    Crear
                  </Button>
                </div>
              </div>
              <div className="columns alignItems training-plan-activation">
                <b>
                  Esta metodología todavía no está asociada con este curso, para hacerlo
                  introduce el codigo del curso y creala.
                  Nota: La metodología se crea desactivada y con un intinerario personalizado.
                </b>
              </div>
            </section>
          )
        }
      </Wrapper>
    );
  }
}

MethodologyTab.propTypes = {
  dispatch: PropTypes.func.isRequired,
  idCourse: PropTypes.number,
  methodology: PropTypes.object,
  addRoadMap: PropTypes.func.isRequired,
  reload: PropTypes.func.isRequired,
  semesters: PropTypes.array.isRequired,
  updateSemester: PropTypes.func.isRequired,
  handleActive: PropTypes.func.isRequired,
  handleActiveOldTP: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  handleMoodleCode: PropTypes.func.isRequired,
};

MethodologyTab.defaultProps = {
  methodology: {},
  idCourse: null,
};

export default connect(
  state => ({
    methodologies: state.trainingPlans.state,
  }),
)(MethodologyTab);
