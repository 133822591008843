import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';

import ReactTable from 'ui/ReactTable';
import { Button } from '@material-ui/core';
import Loading from '$routes/Loading';
import Failed from '$routes/Failed';
import {
  update as updateDiscount,
  list as listDiscounts,
} from '../../redux/discounts/actions';

const dateFormat = 'DD-MM-YYYY';

class Grid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(listDiscounts());
  }

  switchState = (id, value) => () => {
    const { dispatch } = this.props;
    dispatch(updateDiscount(id, { onlyActiveState: true, active: value }));
    dispatch(listDiscounts());
  };

  renderType = (char) => {
    switch (char) {
      case 'F': return 'Fijo';
      case 'S': return 'Pago único';
      case 'H': return 'Horas';
      case 'P': return 'Periodo';
      default: return '???';
    }
  };

  render() {
    const { data, loading } = this.props;
    if (loading) return <Loading />;
    if (!data) return <Failed />;

    return (
      <div>
        <ReactTable
          data={data}
          columns={[
            // {
            //   Header: 'ID',
            //   accessor: 'idDiscount',
            //   maxWidth: 30,
            // },
            {
              Header: 'Código',
              accessor: 'code',
              maxWidth: 75,
            },
            {
              Header: 'Tipo',
              accessor: 'type',
              Cell: ({ value }) => this.renderType(value),
              maxWidth: 75,
            },
            {
              Header: 'Nombre',
              accessor: 'name',
              width: 150,
            },
            {
              Header: () => <div style={{ textAlign: 'center' }}>Fecha inicio</div>,
              accessor: 'startDate',
              Cell: ({ value }) => (value ? moment(value).format(dateFormat) : '-'),
              style: { textAlign: 'center' },
              maxWidth: 100,
            },
            {
              Header: () => <div style={{ textAlign: 'center' }}>Fecha fin</div>,
              accessor: 'finishDate',
              Cell: ({ value }) => (value ? moment(value).format(dateFormat) : '-'),
              style: { textAlign: 'center' },
              maxWidth: 100,
            },
            {
              Header: () => <div style={{ textAlign: 'center' }}>Horas</div>,
              accessor: 'hours',
              Cell: ({ value }) => (value ? `${parseInt(value, 10)}h` : '-'),
              style: { textAlign: 'center' },
              maxWidth: 55,
            },
            {
              Header: () => <div style={{ textAlign: 'center' }}>Valor</div>,
              accessor: 'discountValue',
              Cell: ({ value }) => `${parseInt(value, 10)}%`,
              style: { textAlign: 'center' },
              maxWidth: 70,
            },
            {
              Header: () => <div style={{ textAlign: 'center' }}>Acumulable</div>,
              accessor: 'isCumulative',
              Cell: ({ value }) => (value ? 'Sí' : 'No'),
              style: { textAlign: 'center' },
              maxWidth: 100,
            },
            {
              Header: () => <div style={{ textAlign: 'center' }}>Estado</div>,
              accessor: 'active',
              Cell: ({ value }) => (value ? 'Activo' : 'Inactivo'),
              style: { textAlign: 'center' },
              maxWidth: 70,
            },
            {
              Header: () => <div style={{ textAlign: 'center' }}>Rematrícula</div>,
              accessor: 'reenroll',
              style: { textAlign: 'center' },
              Cell: ({ value }) => (value ? 'Sí' : 'No'),
              maxWidth: 100,
            },
            {
              Header: () => <div style={{ textAlign: 'center' }}>Orden</div>,
              accessor: 'order',
              maxWidth: 55,
              style: { textAlign: 'center' },
            },
            {
              // separator
              resizable: false,
            },
            {
              Header: 'Acciones',
              fixed: 'right',
              columns: [
                {
                  Cell: (row) => {
                    let text = 'DESACTIVAR';
                    let value = false;
                    if (!row.original.active) {
                      text = 'ACTIVAR';
                      value = true;
                    }
                    return (
                      <Button onClick={this.switchState(row.original.idDiscount, value)}>
                        {text}
                      </Button>
                    );
                  },
                  maxWidth: 100,
                  resizable: false,
                  sortable: false,
                },
                {
                  Cell: row => (
                    <Link to={`/descuentos/editar/${row.original.idDiscount}`}>
                      <Button>Editar</Button>
                    </Link>
                  ),
                  maxWidth: 100,
                  resizable: false,
                  sortable: false,
                },
              ],
            },
          ]}
        />
      </div>
    );
  }
}

Grid.propTypes = {
  data: PropTypes.array,
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

Grid.defaultProps = {
  data: null,
};

export default connect(
  state => ({
    data: state.discounts.list,
    loading: state.discounts.loading,
  }),
)(Grid);
