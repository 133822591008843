import React from 'react';
import PropTypes from 'prop-types';

import validator from 'validator';
import swal from 'sweetalert2';

import {
  Button,
  TextField,
} from '@material-ui/core';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faTrash from '@fortawesome/fontawesome-free-solid/faTrash';

import { ZipCodesWrapper, CodeWrapper } from './ZipCodesGroup.styles';

// eslint-disable-next-line
class ZipCodesGroup extends React.Component {
  state = {
    stringToAdd: '',
  }

  onAdd = (e) => {
    e.preventDefault();
    const { data, set, type } = this.props;
    const { stringToAdd } = this.state;

    // validate: should be number, at least...
    if (!validator.isInt(`${stringToAdd}`, { min: 1 })) {
      swal({
        type: 'warning',
        title: 'Error de validación',
        text: 'El código postal debe ser un número...',
      });
      return;
    }

    const mapperToType = (item) => {
      switch (type) {
        case 'Provinces':
          return {
            idProvince: item.id,
            code: item.code,
          };
        case 'ZipCodes':
          return {
            idZipCode: item.id,
            zipCode: item.code,
          };
        default:
          break;
      }
      return {};
    };

    // add to the list and reset field
    set(type, [...data, mapperToType({ id: null, code: stringToAdd })]);
    this.setState({ stringToAdd: '' });
  }

  onRemove = (id, code) => () => {
    const { data, set, type } = this.props;

    // find id on the list, if no id then search by code
    if (id === null) {
      set(type, data.filter(item => item[type === 'Provinces' ? 'code' : 'zipCode'] !== code));
    } else {
      set(type, data.filter(
        item => item[type === 'Provinces' ? 'idProvince' : 'idZipCode'] !== id,
      ));
    }
  }

  render() {
    const { label, type, data } = this.props;

    const mapperFromType = (item) => {
      switch (type) {
        case 'Provinces':
          return {
            id: item.idProvince,
            code: item.code,
          };
        case 'ZipCodes':
          return {
            id: item.idZipCode,
            code: item.zipCode,
          };
        default:
          break;
      }
      return {};
    };

    const { stringToAdd } = this.state;

    return (
      <div>
        <div className="columns is-vcentered">
          <div className="column is-one-fifth">{label}</div>
          <div className="column is-four-fifths">
            <form onSubmit={this.onAdd}>
              <TextField
                style={{ width: 100 }}
                value={stringToAdd}
                onChange={e => this.setState({ stringToAdd: e.target.value })}
              />
              <Button type="submit" style={{ marginLeft: 20 }}>
                Añadir
              </Button>
            </form>
          </div>
        </div>
        <div className="columns">
          <div className="column is-one-fifth" />
          <div className="column is-four-fifths">
            <ZipCodesWrapper>
              {data.map((item, i) => {
                const { id, code } = mapperFromType(item);
                /* eslint-disable react/no-array-index-key */
                return (
                  <CodeWrapper key={`${i}-${code}`}>
                    {code}
                    <button type="button" onClick={this.onRemove(id, code)}>
                      <FontAwesomeIcon className="icon" icon={faTrash} />
                    </button>
                  </CodeWrapper>
                );
              })}
            </ZipCodesWrapper>
          </div>
        </div>
      </div>
    );
  }
}

ZipCodesGroup.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  set: PropTypes.func.isRequired,
};

export default ZipCodesGroup;
