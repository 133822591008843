import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography, TextField, Select, FormControl, MenuItem,
} from '@material-ui/core';
import { Wrapper } from './styles';
import SubTitle from '../../../../ui/SubTitle';

class BillingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    let billingName;
    let billingZipCode;
    let billingIdDocumentNumber;
    let billingAddress;
    let billingCountry;
    let billingCity;

    const {
      data, optionsData, disabled, set,
    } = this.props;

    if (data) {
      billingName = (
        <div className="column is-half">
          <div className="columns is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Empresa</Typography>
            </div>
            <div className="column is-three-fifths">
              <TextField
                disabled={disabled}
                fullWidth
                onChange={e => set('billingInfo', 'billingName', e.target.value)}
                value={data.billingName ? data.billingName : ''}
              />
            </div>
          </div>
        </div>
      );
      billingIdDocumentNumber = (
        <div className="column is-half">
          <div className="columns is-vcentered">
            <div className="column is-two-fifths">
              <Typography>CIF</Typography>
            </div>
            <div className="column is-three-fifths">
              <TextField
                disabled={disabled}
                fullWidth
                onChange={e => set('billingInfo', 'billingIdDocumentNumber', e.target.value)}
                value={data.billingIdDocumentNumber ? data.billingIdDocumentNumber : ''}
              />
            </div>
          </div>
        </div>
      );
      billingZipCode = (
        <div className="column is-half">
          <div className="columns right is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Código postal</Typography>
            </div>
            <div className="column is-three-fifths">
              <TextField
                disabled={disabled}
                fullWidth
                onChange={e => set('billingInfo', 'billingZipCode', e.target.value)}
                value={data.billingZipCode ? data.billingZipCode : ''}
              />
            </div>
          </div>
        </div>
      );
      billingAddress = (
        <div className="column is-half">
          <div className="columns is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Dirección</Typography>
            </div>
            <div className="column is-three-fifths">
              <TextField
                fullWidth
                disabled={disabled}
                onChange={e => set('billingInfo', 'billingAddress', e.target.value)}
                value={data.billingAddress ? data.billingAddress : ''}
              />
            </div>
          </div>
        </div>
      );
      billingCountry = (
        <div className="column is-half">
          <div className="columns right is-vcentered">
            <div className="column is-two-fifths">
              <Typography>País</Typography>
            </div>
            <div className="column is-three-fifths">
              <FormControl fullWidth>
                <Select
                  disabled={disabled}
                  value={data.billingIdCountry ? data.billingIdCountry : ''}
                  onChange={e => set('billingInfo', 'billingIdCountry', e.target.value)}
                >
                  {optionsData.countries.map(country => (
                    <MenuItem
                      key={country.idCountry}
                      value={country.idCountry}
                    >
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      );
      billingCity = (
        <div className="column is-half">
          <div className="columns right is-vcentered">
            <div className="column is-two-fifths">
              <Typography>Municipio</Typography>
            </div>
            <div className="column is-three-fifths">
              <TextField
                fullWidth
                disabled={disabled}
                onChange={e => set('billingInfo', 'billingCity', e.target.value)}
                value={data.billingCity ? data.billingCity : ''}
              />
            </div>
          </div>
        </div>
      );
    }

    const { title } = this.props;

    return (
      <Wrapper>
        <div className="student-data">
          <SubTitle>{title}</SubTitle>
          <div className="columns is-multiline is-narrow content">
            {billingName}
            {billingCity}
            {billingIdDocumentNumber}
            {billingZipCode}
            {billingAddress}
            {billingCountry}
          </div>
        </div>
      </Wrapper>);
  }
}

BillingForm.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object,
  optionsData: PropTypes.object,
  disabled: PropTypes.bool,
  set: PropTypes.func.isRequired,
};

BillingForm.defaultProps = {
  data: undefined,
  optionsData: {},
  disabled: false,
};

export default (BillingForm);
