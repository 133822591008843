import React from 'react';
import moment from 'moment';
import 'moment/locale/es';
import PropTypes from 'prop-types';

import {
  TextField, Button,
} from '@material-ui/core';

import { Wrapper } from './InfoChannel.styles';


moment.locale('es');

class InfoChannelNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      channel: null,
    };
  }

  compare = (a, b) => {
    if (Number(a.limit) < Number(b.limit)) return -1;
    if (Number(a.limit) > Number(b.limit)) return 1;
    return 0;
  }

  handleBlur = name => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  }

  addClick = () => {
    const { create } = this.props;
    const { channel } = this.state;
    create(channel);
  }

  render() {
    return (
      <Wrapper>
        <div className="columns is-new is-multiline">
          <div className="column is-4 input-new">
            <TextField
              label="Concepto"
              className="input-info-channel"
              placeholder="Nuevo Canal"
              onBlur={this.handleBlur('channel')}
            />
          </div>
          <div className="column is-2 add-button">
            <Button
              fullWidth
              onClick={this.addClick}
            > Añadir Canal
            </Button>
          </div>
        </div>
      </Wrapper>
    );
  }
}

InfoChannelNew.propTypes = {
  create: PropTypes.func.isRequired,
};

export default InfoChannelNew;
