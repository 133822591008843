import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Title from 'ui/Title';
import BasicForm from '../Form/BasicForm';

import {
  update as updateCourse,
  getMethodologies,
} from '$redux/courses/actions';

import {
  list as listModuleCourseModalities,
} from '$redux/course-modalities/actions';

import {
  list as listModuleCourseCategories,
} from '$redux/course-categories/actions';

class Courses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      code: '',
      coursePrice: '',
      inClassHourPrice: '',
      onLineHourPrice: '',
      active: false,
      idCourseModality: 0,
      idCourseCategory: 0,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getMethodologies());
    dispatch(listModuleCourseModalities());
    dispatch(listModuleCourseCategories());
  }

  set = (key, value) => {
    this.setState({
      [key]: value,
    });
  }

  saveCourse = (methodologies) => {
    const { dispatch } = this.props;
    dispatch(updateCourse(0, { ...this.state, methodologies }));
  }

  render() {
    const { methodologies, modalities, categories } = this.props;
    return (
      <div>
        <Title>Ciclos</Title>
        <BasicForm
          set={this.set}
          saveCourse={this.saveCourse}
          methodologies={methodologies}
          categories={categories}
          modalities={modalities}
        />
      </div>
    );
  }
}

Courses.propTypes = {
  dispatch: PropTypes.func.isRequired,
  methodologies: PropTypes.array,
  modalities: PropTypes.array,
  categories: PropTypes.array,
};

Courses.defaultProps = {
  methodologies: [],
  modalities: [],
  categories: [],
};

export default connect(
  state => ({
    methodologies: state.courses.methodologies,
    modalities: state.courseModalities.list,
    categories: state.courseCategories.list,
  }),
)(Courses);
