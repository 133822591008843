import React from 'react';
import PropTypes from 'prop-types';
import SubTitle from 'ui/SubTitle';

import {
  InputLabel,
} from '@material-ui/core';
import { Wrapper } from './RoadMap.styles';

class RoadMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      data,
    } = this.props;

    let roadMapChosen;
    let semester;
    if (data.roadMap) {
      ({ roadMap: { name: roadMapChosen, semester } } = data);
    }
    const paymentMethod = (
      <div className="column is-three-fifths">
        <div className="columns is-vcentered">
          <div className="column ">
            <InputLabel>Itinerario escogido: </InputLabel>
          </div>
          <div className="column is-narrow">
            {roadMapChosen}
          </div>
          <div className="column is-narrow">
            { semester === 1 ? '(Matriculado de Septiembre a Enero)' : '(Matriculado de Febrero a Mayo)'}
          </div>
          <div className="column is-two-fifths" />
        </div>
      </div>
    );


    return (
      <Wrapper>
        <SubTitle>Itinerario</SubTitle>
        <div className="columns is-multiline is-vcentered">
          {paymentMethod}
        </div>
      </Wrapper>
    );
  }
}

RoadMap.propTypes = {
  data: PropTypes.object,
};

RoadMap.defaultProps = {
  data: {},
};

export default RoadMap;
