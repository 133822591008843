import styled from 'styled-components';

export const Wrapper = styled.div`
  font-size: 1em;
`;

export const SemesterWrapper = styled.div`
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: .9em;
`;

export const ModuleWrapper = styled.div`
  padding: 2px 0;
  font-size: .9em;
  border-bottom: solid 1px;
  border-color: rgb(0,0,0,0.1);
`;

export const UnitWrapper = styled.div`
  padding: 2px 5px;
  font-size: .9em;
  color: rgb(0,0,0,0.7);
`;
