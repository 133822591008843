import styled from 'styled-components';

export const Wrapper = styled.div`
  .export-data {
      margin-top: 20px;
      float: right;
      button {
        color: #6896c8;
      }
    }
  .align-right {
    text-align: right;
    padding: 0;
  }

  .export {
    color: #6896c8;
    font-size: 12px;
  }

  .upload-cols {
    align-items: center;
  }

  .upload-label {
    text-align:right;
  }

  .upload-input {
    text-align: left;
  }
  .uppy-FileInput-btn {
    background: $secondary;
    background: rgb(208, 207, 207);
    color: rgb(131, 131, 131);
    position: relative;
    margin-top: 0px;
    margin-bottom: 8px;
    padding: 11px 10px 10px 10px;
    border-radius: 0px;
    border: 1px solid transparent;
    line-height: 18px;
    user-select: none;
  }

  .uppy-FileInput-btn:focus,
  .uppy-FileInput-btn:active,
  .uppy-FileInput-btn:hover {
    background: rgb(208, 207, 207);
    border: 1px solid transparent;
  }

  .upload-step {
    padding-top: 10px;

    .container {

      display: flex;
      flex-direction: row;

      .isrow {
        display: flex;
        flex-direction: row;
        width: 100%;
      }

      .label-button {
        width: 80%;
      }

      .inputfile {
        width: .1px;
        height: .1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }

      .like-button {

        &.is-padding {
          background: $secondary;
          background: rgb(208, 207, 207);
          color: rgb(131, 131, 131);
          position: relative;
          margin-top: 16px;
          margin-bottom: 8px;
          padding: 13px 10px 10px 10px;
        }
      }

      .container {
        width: 40vw;
      }
    }
  }

`;
