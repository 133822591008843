import {
  REQUEST_FAILED,
  LIST,
  LIST_SUCCEEDED,
  GET,
  GET_SUCCEEDED,
  UPDATE,
  UPDATE_SUCCEEDED,
  REMOVE,
  REMOVE_SUCCEEDED,
  CREATE,
  CREATE_SUCCEEDED,
  SET,
  RESET,
  SWITCH,
  SWITCH_SUCCEEDED,
  GET_METHODOLOGIES_SUCCEEDED,
  ACTIVATE_TP_SUCCESS,
  SHOW_OLD_TP_SUCCESS,
  DELETE_MODULE,
  MODIFY_MOODLE_CODE_TP_SUCCESS,
} from './action-types';
import utils from '../utils';

const initialState = {
  list: null,
  single: null,
  // single: {
  //   idCourse: null,
  //   code: '',
  //   name: '',
  //   coursePrice: '',
  //   inClassHourPrice: '',
  //   onLineHourPrice: '',
  //   active: false,
  //   isLogse: false,
  // },
  loading: false,
};

const coursesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case REQUEST_FAILED: {
      const { error } = action;

      let message = 'La petición no se ha completado con éxito.';
      let status = 0;

      if (error.response && error.response.data && error.response.data.message) {
        ({ message } = error.response.data);
        ({ status } = error.response);
      }

      utils.dialog({
        type: status === 422 ? 'warning' : 'error',
        title: status === 422 ? 'Error de validación' : 'Error inesperado',
        message,
      });
      return {
        ...state,
        loading: false,
      };
    }
    case DELETE_MODULE:
    case LIST:
    case GET:
    case UPDATE:
    case REMOVE:
    case SWITCH:
    case CREATE: {
      return {
        ...state,
        loading: true,
      };
    }
    case SWITCH_SUCCEEDED:
    case LIST_SUCCEEDED: {
      const { data } = action;
      return {
        ...state,
        list: data,
        single: undefined,
        loading: false,
      };
    }
    case GET_SUCCEEDED: {
      const { data } = action;
      return {
        ...state,
        single: data,
        loading: false,
      };
    }
    case UPDATE_SUCCEEDED:
    case REMOVE_SUCCEEDED:
    case CREATE_SUCCEEDED: {
      utils.dialog({
        title: '¡Perfecto!',
        message: 'La petición se ha procesado con éxito',
      });

      return {
        ...state,
        loading: false,
      };
    }
    case SET: {
      const { key, value } = action;

      return {
        ...state,
        single: {
          ...state.single,
          [key]: value,
        },
      };
    }
    case RESET: {
      return {
        ...state,
        single: initialState.single,
      };
    }
    case GET_METHODOLOGIES_SUCCEEDED: {
      const { data } = action;
      return {
        ...state,
        methodologies: data,
      };
    }
    case ACTIVATE_TP_SUCCESS:
    case SHOW_OLD_TP_SUCCESS: {
      return {
        ...state,
      };
    }
    case MODIFY_MOODLE_CODE_TP_SUCCESS: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

export default coursesReducer;
