import React from 'react';
import PropTypes from 'prop-types';

import { InputLabel } from '@material-ui/core';
import { DateRangePicker } from 'react-dates';

import { Wrapper, DatePickerWrapper } from './DateRangePicker.styles';

const CustomDRP = (props) => {
  const { border, label, ...filteredProps } = props;
  return (
    <Wrapper>
      <div style={{ lineHeight: 1 }}>
        <InputLabel>{label}</InputLabel>
      </div>
      <DatePickerWrapper border={border}>
        <DateRangePicker
          small
          noBorder
          startDatePlaceholderText="Fecha inicio"
          endDatePlaceholderText="Fecha fin"
          showClearDates
          isOutsideRange={() => false}
          {...filteredProps}
        />
      </DatePickerWrapper>
    </Wrapper>
  );
};

CustomDRP.propTypes = {
  label: PropTypes.string,
  border: PropTypes.bool,
};

CustomDRP.defaultProps = {
  label: null,
  border: true,
};

export default CustomDRP;
