export const LIST = 'UNITS_LIST';
export const LIST_SUCCEEDED = 'UNITS_LIST_SUCCEEDED';
export const LIST_FAILED = 'UNITS_LIST_FAILED';
export const GET = 'UNIT_GET';
export const GET_SUCCEEDED = 'UNIT_GET_SUCCEEDED';
export const GET_FAILED = 'UNIT_GET_FAILED';
export const CREATE = 'UNIT_CREATE';
export const CREATE_SUCCEEDED = 'UNIT_CREATE_SUCCEEDED';
export const CREATE_FAILED = 'UNIT_CREATE_FAILED';
export const EDIT = 'UNIT_EDIT';
export const EDIT_SUCCEEDED = 'UNIT_EDIT_SUCCEEDED';
export const EDIT_FAILED = 'UNIT_EDIT_FAILED';
