import React from 'react';
import PropTypes from 'prop-types';

const UnitItem = ({ enrollmentUnit }) => (
  <div className="columns is-vcentered units" key={enrollmentUnit.idEnrollmentTransactionUnit}>
    <div className="column is-one-fifths">{enrollmentUnit.code}</div>
    <div className="column is-two-fifths">{enrollmentUnit.name}</div>
    <div className="column is-one-fifths is-offset-one-fifth amount">{enrollmentUnit.cost}</div>
  </div>
);

UnitItem.propTypes = {
  enrollmentUnit: PropTypes.object.isRequired,
};

export default UnitItem;
