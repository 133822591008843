import {
  REQUEST_GROUP_CREATE,
  REQUEST_GROUP_CREATE_SUCCEEDED,
  REQUEST_GROUP_CREATE_FAILED,
  REQUEST_GROUP_LIST_CICLES,
  REQUEST_GROUP_LIST_CICLES_SUCCEEDED,
  REQUEST_GROUP_LIST_CICLES_FAILED,
  REQUEST_GROUP_FILTERS,
  REQUEST_GROUP_FILTERS_SUCCEEDED,
  REQUEST_GROUP_FILTERS_FAILED,
  REQUEST_GROUP_MASSIVE_OPTIONS,
  REQUEST_GROUP_MASSIVE_OPTIONS_SUCCEEDED,
  REQUEST_GROUP_MASSIVE_OPTIONS_FAILED,
  REQUEST_GROUP_GET,
  REQUEST_GROUP_GET_SUCCEEDED,
  REQUEST_GROUP_GET_FAILED,
  REQUEST_GROUP_UPDATE_ENROLLMENT_TO_GROUP,
  REQUEST_GROUP_UPDATE_ENROLLMENT_TO_GROUP_SUCCEEDED,
  REQUEST_GROUP_UPDATE_ENROLLMENT_TO_GROUP_FAILED,
  REQUEST_GROUP_GET_CICLE_ENROLLMENT_BY_GROUP,
  REQUEST_GROUP_GET_CICLE_ENROLLMENT_BY_GROUP_SUCCEEDED,
  REQUEST_GROUP_GET_CICLE_ENROLLMENT_BY_GROUP_FAILED,
  REQUEST_GROUP_GET_ALL_COURSES_BY_SEMESTER_AND_COURSE,
  REQUEST_GROUP_GET_ALL_COURSES_BY_SEMESTER_AND_COURSE_SUCCEEDED,
  REQUEST_GROUP_GET_ALL_COURSES_BY_SEMESTER_AND_COURSE_FAILED,
  REQUEST_GROUP_UPDATE_COURSES_BY_SEMESTER_AND_COURSE,
  REQUEST_GROUP_UPDATE_COURSES_BY_SEMESTER_AND_COURSE_SUCCEEDED,
  REQUEST_GROUP_UPDATE_COURSES_BY_SEMESTER_AND_COURSE_FAILED,
  REQUEST_GROUP_REMOVE_CICLES,
  REQUEST_GROUP_REMOVE_CICLES_SUCCEEDED,
  REQUEST_GROUP_REMOVE_CICLES_FAILED,
  SET,
} from './action-types';

import utils from '../utils';

const initialState = {
  data: [],
  totals: {},
  loading: false,
};

const groupReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case REQUEST_GROUP_REMOVE_CICLES:
    case REQUEST_GROUP_GET:
    case REQUEST_GROUP_GET_CICLE_ENROLLMENT_BY_GROUP:
    case REQUEST_GROUP_GET_ALL_COURSES_BY_SEMESTER_AND_COURSE:
    case REQUEST_GROUP_UPDATE_COURSES_BY_SEMESTER_AND_COURSE:
    case REQUEST_GROUP_LIST_CICLES: {
      return {
        ...state,
        loading: true,
      };
    }
    case REQUEST_GROUP_LIST_CICLES_SUCCEEDED: {
      const {
        data,
        totals,
      } = action;
      return {
        ...state,
        data,
        totals,
        loading: false,
      };
    }
    case REQUEST_GROUP_UPDATE_ENROLLMENT_TO_GROUP: {
      const {
        data,
      } = action;
      return {
        ...state,
        data,
      };
    }
    case REQUEST_GROUP_GET_SUCCEEDED: {
      const {
        group,
        groupRoom,
        alreadyInGroupsQuery,
        candidates,
      } = action;
      return {
        ...state,
        group,
        groupRoom,
        alreadyInGroupsQuery,
        candidates,
        loading: false,
      };
    }
    case REQUEST_GROUP_GET_CICLE_ENROLLMENT_BY_GROUP_SUCCEEDED: {
      const {
        enrollmentsForGroups,
      } = action;
      return {
        ...state,
        enrollmentsForGroups,
        loading: false,
      };
    }
    case REQUEST_GROUP_GET_ALL_COURSES_BY_SEMESTER_AND_COURSE_SUCCEEDED: {
      const {
        proposals,
      } = action;
      return {
        ...state,
        proposals,
        loading: false,
      };
    }

    case REQUEST_GROUP_UPDATE_COURSES_BY_SEMESTER_AND_COURSE_SUCCEEDED: {
      const {
        result,
      } = action;
      return {
        ...state,
        result,
        loading: false,
      };
    }

    case REQUEST_GROUP_REMOVE_CICLES_SUCCEEDED: {
      const {
        result,
      } = action;
      return {
        ...state,
        result,
        loading: false,
      };
    }

    case REQUEST_GROUP_UPDATE_COURSES_BY_SEMESTER_AND_COURSE_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error inesperado',
        message: 'No se han podido crear las aulas!',
      });
      return {
        ...state,
        loading: false,
      };
    }

    case REQUEST_GROUP_REMOVE_CICLES_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error inesperado',
        message: 'No se han podido eliminar los ciclos!',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case REQUEST_GROUP_LIST_CICLES_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error inesperado',
        message: 'No se ha podido recoger los ciclos, ha ocurrido un error.',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case REQUEST_GROUP_GET_ALL_COURSES_BY_SEMESTER_AND_COURSE_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error',
        message: 'Ha ocurrido un error, no se han podido recoger los modulos',
      });
      return {
        ...state,
        loading: false,
      };
    }

    case REQUEST_GROUP_CREATE_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error',
        message: 'No se ha podido crear.',
      });
      return {
        ...state,
        loading: false,
      };
    }

    case REQUEST_GROUP_UPDATE_ENROLLMENT_TO_GROUP_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error',
        message: 'No se ha podido crear.',
      });
      return {
        ...state,
        loading: false,
      };
    }

    case REQUEST_GROUP_GET_FAILED:
    case REQUEST_GROUP_GET_CICLE_ENROLLMENT_BY_GROUP_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error',
        message: 'El ciclo no se ha podido recoger',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case REQUEST_GROUP_CREATE: {
      return {
        ...state,
        loading: true,
      };
    }
    case REQUEST_GROUP_CREATE_SUCCEEDED: {
      utils.dialog({
        title: '¡Perfecto!',
        message: 'La petición se ha procesado con éxito',
      });

      return {
        ...state,
        loading: false,
      };
    }
    case REQUEST_GROUP_UPDATE_ENROLLMENT_TO_GROUP_SUCCEEDED: {
      utils.dialog({
        title: '¡Perfecto!',
        message: 'La petición se ha procesado con éxito',
      });

      return {
        ...state,
        loading: false,
      };
    }
    case REQUEST_GROUP_MASSIVE_OPTIONS:
    case REQUEST_GROUP_FILTERS: {
      return {
        ...state,
        loading: true,
      };
    }
    case REQUEST_GROUP_MASSIVE_OPTIONS_SUCCEEDED:
    case REQUEST_GROUP_FILTERS_SUCCEEDED: {
      const { filtersData } = action;
      return {
        ...state,
        filtersData,
        loading: false,
      };
    }
    case REQUEST_GROUP_MASSIVE_OPTIONS_FAILED:
    case REQUEST_GROUP_FILTERS_FAILED: {
      utils.dialog({
        type: 'error',
        title: 'Error de carga',
        message: 'No se ha podido traer la información referente a los filtros',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case SET: {
      const { key, value } = action;

      return {
        ...state,
        [key]: value,
      };
    }
    default:
      return state;
  }
};

export default groupReducer;
