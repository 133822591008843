import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -15px;

  img {
    width: 150px;
  }

  .filters {
    align-content: space-around;
    height: 85px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: 32px;
    grid-column-gap: .75vw;

    & > * {
      max-height: 100%;
      font-size: 11px;

      &.button {
        grid-column-end: span 2;
      }
    }
  }

  .filters-form {
    width: 100%;
  }
  .apply-filters-button {
    min-height: auto;
  }
`;
