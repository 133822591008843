import React from 'react';
import moment from 'moment';
import 'moment/locale/es';
import PropTypes from 'prop-types';

import {
  Select, Button,
} from '@material-ui/core';

import { Wrapper } from './EditModule.styles';


moment.locale('es');

class ModuleReqAddUnits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      unitRequired: {},
    };
  }

  handleChange = (event) => {
    this.setState({
      unitRequired: event.target.value,
    });
  }

  addClick = () => {
    const { set, data } = this.props;
    const { unitRequired } = this.state;
    const unitReadyToAdd = JSON.parse(unitRequired);
    const newList = [...data.UnitRequirements, {
      idModule: data.idModule,
      idUnitRequired: unitReadyToAdd.idUnit,
      UnitRequired: { ...unitReadyToAdd },
    }];
    set(newList);
  }

  render() {
    const { methodology } = this.props;
    return (
      <Wrapper>
        <div className="columns is-multiline">
          <div className="column is-6">
            <Select
              native
              label="Unidades"
              onChange={this.handleChange}
            >
              <option key={module.code} value="">Unidades requeridas</option>;
              {methodology.roadMaps[0].modules.map(module => module.units.map((unit) => {
                const json = JSON.stringify({
                  idUnit: unit.idUnit, name: unit.name, code: unit.code,
                });
                return (<option key={unit.code} value={json}>{unit.name}</option>);
              }))}
            </Select>
          </div>
          <div className="column is-1 is-vcentered">
            <Button
              fullWidth
              onClick={this.addClick}
              style={{ backgroundColor: 'transparent' }}
            > Añadir
            </Button>
          </div>
        </div>
      </Wrapper>
    );
  }
}

ModuleReqAddUnits.propTypes = {
  set: PropTypes.func.isRequired,
  data: PropTypes.object,
  methodology: PropTypes.object.isRequired,
};

ModuleReqAddUnits.defaultProps = {
  data: undefined,
};

export default ModuleReqAddUnits;
