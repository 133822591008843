import {
  CREATE,
  CREATE_SUCCEEDED,
  CREATE_FAILED,
  UPDATE,
  UPDATE_SUCCEEDED,
  UPDATE_FAILED,
  GET,
  GET_SUCCEEDED,
  GET_FAILED,
} from './action-types';

export const create = data => ({ type: CREATE, data });
export const createSucceeded = success => ({ type: CREATE_SUCCEEDED, success });
export const createFailed = error => ({ type: CREATE_FAILED, error });
export const update = data => ({ type: UPDATE, data });
export const updateSucceeded = success => ({ type: UPDATE_SUCCEEDED, success });
export const updateFailed = error => ({ type: UPDATE_FAILED, error });
export const get = idRoadMap => ({ type: GET, idRoadMap });
export const getSucceeded = success => ({ type: GET_SUCCEEDED, success });
export const getFailed = error => ({ type: GET_FAILED, error });
