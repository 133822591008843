import React from 'react';
import { connect } from 'react-redux';
import Title from 'ui/Title';
import PropTypes from 'prop-types';
import ReactTable from 'ui/ReactTable';

import { get } from '$redux/splitPayments/actions';

class SplitPaymentCharges extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // componentDidMount() {
  //   const { dispatch } = this.props;
  //   dispatch(get());
  // }

  onFetchData = (state) => {
    const { dispatch } = this.props;
    const { pageSize, page, sorted } = state;
    const bindingObject = {
      pageSize,
      page,
      sorted,
    };
    dispatch(get(bindingObject));
  }

  render() {
    const {
      splitPaymentTransactions, pages, pageSize,
    } = this.props;
    const date = new Date();
    return (
      <div>
        <Title>
          Estado de pagos hoy {`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`}
        </Title>
        <ReactTable
          topBorder
          columns={[
            {
              Header: 'Última actualización',
              accessor: 'updatedAt',
              width: 200,
              Cell: (row) => {
                const {
                  original: { updatedAt },
                } = row;
                const updatedDate = new Date(updatedAt);
                return <p>{`${updatedDate.getDate()}/${updatedDate.getMonth() + 1}/${updatedDate.getFullYear()}`}</p>;
              },
            },
            {
              Header: 'Estado',
              accessor: 'status',
              width: 120,
              Cell: (row) => {
                const {
                  original: { status },
                } = row;
                if (status === 'pending') return <p style={{ color: 'orange' }}>Pendiente (Mail)</p>;
                if (status === 'failure') return <p style={{ color: 'red' }}>Fallido</p>;
                return <p style={{ color: 'green' }}>Pagado</p>;
              },
            },
            {
              Header: 'Mensaje',
              accessor: 'message',
              Cell: (row) => {
                const {
                  original: { message },
                } = row;
                if (message === 'authentication_required') return <p style={{ color: 'red' }}>Error de autenticación</p>;
                return <p>{message}</p>;
              },
            },
            {
              Header: 'Cantidad (Euros)',
              accessor: 'amount',
            },
            {
              Header: 'Usuario',
              accessor: 'name',
              width: 80,
            },
            {
              Header: 'Email',
              accessor: 'email',
              width: 200,
            },
          ]}
          manual
          defaultPageSize={pageSize}
          pages={pages}
          data={splitPaymentTransactions}
          onFetchData={this.onFetchData} // Request new data when things change
        />
      </div>
    );
  }
}

SplitPaymentCharges.propTypes = {
  dispatch: PropTypes.func.isRequired,
  splitPaymentTransactions: PropTypes.array,
  pageSize: PropTypes.number,
  pages: PropTypes.number,
};

SplitPaymentCharges.defaultProps = {
  splitPaymentTransactions: [],
  pageSize: 10,
  pages: 0,
};

export default connect(
  state => ({
    splitPaymentTransactions: state.splitPayments.splitPaymentTransactions,
    pages: state.splitPayments.pages,
  }),
)(SplitPaymentCharges);
