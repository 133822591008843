import styled from 'styled-components';

export const Wrapper = styled.div`
  font-size: .8em;
  .checkbox {
    padding: 0 0 0 22px;
  }
`;

export const PlanHeaderWrapper = styled.div`
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 500;
`;

export const PlanRowWrapper = styled.div`
  padding: 2px 0;
  border-top: 1px solid #ccc;
`;
