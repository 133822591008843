import React from 'react';
import moment from 'moment';
import 'moment/locale/es';
import PropTypes from 'prop-types';

import {
  Select, Button,
} from '@material-ui/core';

import { Wrapper } from './EditModule.styles';


moment.locale('es');

class ModuleRequirements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      moduleRequired: {},
    };
  }

  handleChange = (event) => {
    this.setState({
      moduleRequired: event.target.value,
    });
  }

  addClick = () => {
    const { set, data } = this.props;
    const { moduleRequired } = this.state;
    const moduleReadyToAdd = JSON.parse(moduleRequired);
    const newList = [...data.ModuleRequirements, {
      idModule: data.idModule,
      idModuleRequired: moduleReadyToAdd.idModule,
      ModuleRequired: { ...moduleReadyToAdd },
    }];
    set(newList);
  }

  render() {
    const { methodology } = this.props;
    return (
      <Wrapper>
        <div className="columns is-multiline">
          <div className="column is-6">
            <Select
              native
              label="Módulos"
              onChange={this.handleChange}
            >
              <option key={module.code} value="">Módulos requeridos</option>;
              {methodology.roadMaps[0].modules.map((module) => {
                const json = JSON.stringify({
                  idModule: module.idModule, name: module.name, code: module.code,
                });
                return (<option key={module.code} value={json}>{module.name}</option>);
              })}
            </Select>
          </div>
          <div className="column is-1 is-vcentered">
            <Button
              fullWidth
              onClick={this.addClick}
              style={{ backgroundColor: 'transparent' }}
            > Añadir
            </Button>
          </div>
        </div>
      </Wrapper>
    );
  }
}

ModuleRequirements.propTypes = {
  set: PropTypes.func.isRequired,
  data: PropTypes.object,
  methodology: PropTypes.object.isRequired,
};

ModuleRequirements.defaultProps = {
  data: undefined,
};

export default ModuleRequirements;
