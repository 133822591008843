export const PAYMENTMODES_REQUEST = 'PAYMENTMODES_REQUEST';
export const PAYMENTMODES_REQUEST_SUCCEEDED = 'PAYMENTMODES_REQUEST_SUCCEEDED';
export const PAYMENTMODES_REQUEST_FAILED = 'PAYMENTMODES_REQUEST_FAILED';

export const PAYMENTMODES_UPDATE = 'PAYMENTMODES_UPDATE_REQUEST';
export const PAYMENTMODES_UPDATE_SUCCEEDED = 'PAYMENTMODES_UPDATE_SUCCEEDED';
export const PAYMENTMODES_UPDATE_FAILED = 'PAYMENTMODES_UPDATE_FAILED';

export const PAYMENTMODES_SET_SELECTION = 'PAYMENTMODES_SET_SELECTION';
export const PAYMENTMODES_SET_PAYMENTINFO = 'PAYMENTMODES_SET_PAYMENTINFO';
export const PAYMENTMODES_SET_ACTIVE = 'PAYMENTMODES_SET_ACTIVE';
