/* eslint-disable react/no-unused-state */
import React from 'react';

import {
  Button,
} from '@material-ui/core';

import swal from 'sweetalert2';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SubTitle from 'ui/SubTitle';

import { Wrapper } from './styles';

import BasicForm from './BasicForm';

import {
  list as listCourses,
} from '$redux/courses/actions';

import {
  reqLeadExportCreateLead,
} from '$redux/lead-exportar/actions';

import { calcularDiferenciaDias } from '$utils/fechas';


// eslint-disable-next-line
class Form extends React.Component {
  constructor(props) {
    super(props);
    const { dispatch } = this.props;
    this.state = {
      dateInit: '',
      dateEnd: '',
    };

    dispatch(listCourses());
  }

  onCreate = () => {
    const { dispatch } = this.props;

    const diasMaximosInforme = 365;

    const {
      dateInit, dateEnd,
    } = this.state;

    if (!dateInit || !dateEnd) {
      swal({
        type: 'warning',
        title: 'Error de validación',
        text: `Tienes que introducir los campos Desde y Hasta y la diferencia de días no puede ser más de ${diasMaximosInforme} días.`,
      });

      return false;
    }

    const dias = calcularDiferenciaDias(dateInit, dateEnd);
    if (dias <= 0 || calcularDiferenciaDias(dateInit, dateEnd) > diasMaximosInforme) {
      swal({
        type: 'warning',
        title: 'Error de validación',
        text: `La diferencia no puede ser más de ${diasMaximosInforme} días.`,
      });

      return false;
    }


    dispatch(reqLeadExportCreateLead(
      {
        dateInit,
        dateEnd,
      },
    ));

    return true;
  }

  setValue = name => (event) => {
    let { value } = event.target;

    const { type } = event.target;
    if (type === 'checkbox') {
      value = event.target.checked;
    }

    this.setState({
      [name]: value,
    });
  }

  render() {
    return (
      <Wrapper>
        <SubTitle>Seleccióna la fecha para generar el informe</SubTitle>
        <BasicForm data={this.state} setValue={this.setValue} />
        <Button color="secondary" onClick={this.onCreate}>Crear</Button>
      </Wrapper>
    );
  }
}

Form.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(Form);
