import React from 'react';
import PropTypes from 'prop-types';
import UnitItem from './UnitItem';

class ModuleItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { enrollmentModule } = this.props;
    const unitList = enrollmentModule.Units.map(item => (
      <UnitItem key={item.idEnrollmentTransactionUnit} enrollmentUnit={item} />));

    const moduleItem = (
      <div>
        <div className="columns is-vcentered modules">
          <div className="column is-one-fifths">{enrollmentModule.code}</div>
          <div className="column is-two-fifths">{enrollmentModule.name}</div>
          <div className="column is-one-fifths">{enrollmentModule.stage}</div>
          <div className="column is-one-fifths amount">{enrollmentModule.cost}</div>
        </div>
        {unitList}
      </div>
    );
    return moduleItem;
  }
}

ModuleItem.propTypes = {
  enrollmentModule: PropTypes.object.isRequired,
};

export default ModuleItem;
