import React from 'react';
import PropTypes from 'prop-types';
import SubTitle from 'ui/SubTitle';
import {
  TextField, Select,
} from '@material-ui/core';
import swal from 'sweetalert2';
import Loading from '../../../Loading';


class ModuleRow extends React.Component {
  handleChange = (name, value) => {
    const stateReplica = {};
    stateReplica[name] = value;
    this.setState(stateReplica);
  }

  save = (moduleEdit) => {
    const { edit, moduleToShow, create } = this.props;
    if (moduleToShow) {
      edit(moduleEdit);
    } else {
      const {
        code,
        name,
        onLineHours,
        inClassHours,
        onLineHourPrice,
        inClassHourPrice,
        basePrice,
        price,
        moodleCode,
      } = this.state;
      if (code
        && name
        && onLineHours
        && inClassHours
        && onLineHourPrice
        && inClassHourPrice
        && basePrice
        && price
        && moodleCode) {
        create(moduleEdit);
      } else {
        swal({
          type: 'warning',
          title: 'Campos vacíos',
          text: 'Todos los campos deben ser rellenados para crear un módulo',
        });
      }
    }
  }

  render() {
    const {
      data, educationalLevels, moduleToShow, set,
    } = this.props;
    if (!data && moduleToShow) return <Loading />;
    let educationalLevelsOptions = [];
    if (educationalLevels.length > 0) {
      educationalLevelsOptions = educationalLevels.map(option => (
        <option value={option.code} key={option.name}>{option.name}</option>
      ));
    }
    return (
      <div>
        <SubTitle className="modal-form-title">{moduleToShow !== 0 ? 'Editar Módulo' : 'Añadir Módulo'}</SubTitle>
        <div className="columns is-multiline">
          <div className="column is-1">
            <TextField
              label="SKU"
              defaultValue={moduleToShow !== 0 ? data.code : ''}
              onChange={event => set('code', event.target.value)}
            />
          </div>
          <div className="column is-4">
            <TextField
              fullWidth
              label="NOMBRE"
              defaultValue={moduleToShow !== 0 ? data.name : ''}
              onChange={event => set('name', event.target.value)}
            />
          </div>
          <div className="column is-1">
            <TextField
              label="H.ONLINE"
              defaultValue={moduleToShow !== 0 ? data.onLineHours : ''}
              onChange={event => set('onLineHours', event.target.value)}
            />
          </div>
          <div className="column is-1">
            <TextField
              label="H. PRE."
              defaultValue={moduleToShow !== 0 ? data.inClassHours : ''}
              onChange={event => set('inClassHours', event.target.value)}

            />
          </div>
          <div className="column is-1">
            <TextField
              label="P. ONLINE"
              defaultValue={moduleToShow !== 0 ? data.onLineHourPrice : ''}
              onChange={event => set('onLineHourPrice', event.target.value)}
            />
          </div>
          <div className="column is-1">
            <TextField
              label="P. PRE."
              onChange={event => set('inClassHourPrice', event.target.value)}
              defaultValue={moduleToShow !== 0 ? data.inClassHourPrice : ''}
            />
          </div>
          <div className="column is-1">
            <TextField
              label="P. BASE"
              defaultValue={moduleToShow !== 0 ? data.basePrice : ''}
              onChange={event => set('basePrice', event.target.value)}
            />
          </div>
          <div className="column is-1">
            <TextField
              label="P. FIJO"
              defaultValue={moduleToShow !== 0 ? data.price : ''}
              onChange={event => set('price', event.target.value)}
            />
          </div>
          <div className="column is-1">
            <Select
              native
              label="TIPO"
              className="educational-level-select"
              defaultValue={moduleToShow !== 0 ? data.EducationalLevel.code : ''}
              onChange={event => set('educationalLevel', event.target.value)}
            >
              {educationalLevelsOptions.map(option => option)}
            </Select>
          </div>
          <div className="column is-1">
            <TextField
              label="Code"
              defaultValue={moduleToShow !== 0 ? data.moodleCode : ''}
              onChange={event => set('moodleCode', event.target.value)}
            />
          </div>
          <div className="column is-10">
            <TextField
              fullWidth
              label="Avisos y observaciones"
              defaultValue={moduleToShow !== 0 ? data.warningMessage : ''}
              onChange={event => set('warningMessage', event.target.value)}
            />
          </div>
        </div>
      </div>
    );
  }
}

ModuleRow.propTypes = {
  data: PropTypes.object,
  edit: PropTypes.func.isRequired,
  create: PropTypes.func.isRequired,
  educationalLevels: PropTypes.array,
  moduleToShow: PropTypes.number.isRequired,
  set: PropTypes.func.isRequired,
};

ModuleRow.defaultProps = {
  data: { EducationalLevel: { code: '1' } },
  educationalLevels: [],
};

export default ModuleRow;
