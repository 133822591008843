import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  create as createModuleSemester,
  get as getModuleSemester,
  list as listModuleSemesters,
  remove as removeModuleSemester,
  reset as resetModuleSemester,
  set as setModuleSemester,
  update as updateModuleSemester,
} from '$redux/moduleSemesters/actions';

import { Button } from '@material-ui/core';
import Title from 'ui/Title';
import Loading from '$routes/Loading';
import Failed from '$routes/Failed';

import Grid from './Grid';
import Editor from './Editor';

class ModuleSemester extends React.Component {
  state = {
    editorOpen: false,
    editorAction: 'new',
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(listModuleSemesters());
  }

  onCreate = () => {
    const {
      dispatch,
      single,
    } = this.props;
    dispatch(createModuleSemester(single));
  };

  onUpdate = () => {
    const {
      dispatch,
      single,
    } = this.props;
    dispatch(updateModuleSemester(single.idModuleSemester, single));
  };

  set = (key, value) => {
    const { dispatch } = this.props;
    dispatch(setModuleSemester(key, value));
  };

  openEditor = (id) => {
    const { dispatch } = this.props;

    if (id) {
      dispatch(getModuleSemester(id));
      this.setState({
        editorOpen: true,
        editorAction: 'edit',
      });
    } else {
      dispatch(resetModuleSemester());
      this.setState({
        editorOpen: true,
        editorAction: 'new',
      });
    }
  };

  removeModuleSemester = (id) => {
    const { dispatch } = this.props;
    if (id) {
      dispatch(removeModuleSemester(id));
    }
  };

  render() {
    const {
      data,
      single,
      loading,
    } = this.props;

    if (loading) return <Loading />;
    if (!data) return <Failed />;

    const {
      editorOpen,
      editorAction,
    } = this.state;

    return (
      <div>
        <Title>
          Semestres
          <Button onClick={() => this.openEditor()} size="small">Crear nuevo semestre</Button>
        </Title>
        {
          editorOpen ? (
            <Editor
              action={editorAction}
              data={single}
              set={this.set}
              onSend={editorAction === 'new' ? this.onCreate : this.onUpdate}
              onClose={() => this.setState({ editorOpen: false })}
            />
          ) : null
        }
        <Grid
          data={data}
          loading={loading}
          onEdit={this.openEditor}
          removeItem={this.removeModuleSemester}
        />
      </div>
    );
  }
}

ModuleSemester.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.array,
  single: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

ModuleSemester.defaultProps = {
  data: null,
};

export default connect(
  state => ({
    data: state.moduleSemesters.list,
    single: state.moduleSemesters.single,
    loading: state.moduleSemesters.loading,
  }),
)(ModuleSemester);
