import styled from 'styled-components';

export const Wrapper = styled.div`

  .row {
    display: flex;
    padding: 10px 0;
  }

  .centered-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .icon-trash:hover, .icon-pencil:hover, .add-file:hover {
    cursor: pointer
  }

  .edit-justificante-centered-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    margin-left: 5px;

    svg {
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 5px;
    }

    span {
      margin-right: 5px;
    }
  }
`;
