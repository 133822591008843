import styled from 'styled-components';

export const Wrapper = styled.div`

  .content{
    font-size: 0.9em;
    .header, .total {
      font-weight: 700;
    }

    .modules, .units, .discounts {
      border-top: solid 1px rgba(0, 0, 0, 0.3);
      margin-bottom: 10px;
    }

    .total {
      border-top: solid 2px;
    }

    .units {
      padding-left: 10px;
      opacity: 0.8;
    }

    .discounts-group > div:first-child
    {
      border-top: solid 2px;
    }
    .amount {
      text-align: right;
    }
  }

  .checkbox {
    max-height: 10px;
  }

`;
