import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { UnitWrapper } from './RoadMap.styles';

class RoadMapUnitRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      checked, itemText, idUnit, checkunit, disabled,
    } = this.props;

    return (
      <UnitWrapper>
        <div className="columns is-vcentered">
          <div className="column is-1">
            <FormControlLabel
              disabled={disabled}
              control={<Checkbox color="primary" />}
              checked={checked}
              onChange={e => checkunit(
                idUnit,
                e.target.checked,
              )}
            />
          </div>
          <div className="column">
            {itemText}
          </div>
        </div>
      </UnitWrapper>
    );
  }
}

RoadMapUnitRow.propTypes = {
  disabled: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  itemText: PropTypes.string.isRequired,
  checkunit: PropTypes.func.isRequired,
  idUnit: PropTypes.number.isRequired,
};

export default RoadMapUnitRow;
