import {
  RESET,
  LOGIN,
  LOGIN_SUCCEEDED,
  LOGIN_FAILED,
  LOGOUT,
  REFRESH_PERMISSIONS,
  REFRESH_PERMISSIONS_SUCCEEDED,
  REFRESH_PERMISSIONS_FAILED,
} from './action-types';
import utils from '../utils';

const initialState = {
  token: null,
  user: null,
  loadingLogin: false,
  loadingPermissions: false,
  permissionsReady: false,
};

const loginReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case RESET: {
      return {
        ...state,
        loadingLogin: false,
        loadingPermissions: false,
        permissionsReady: false,
      };
    }
    case LOGIN: {
      return {
        ...state,
        loadingLogin: true,
      };
    }
    case LOGIN_SUCCEEDED: {
      const { token, user } = action;
      utils.dialog({
        title: `Bienvenido, ${user.name}`,
        message: 'Se ha iniciado sesión con éxito.',
      });
      return {
        ...state,
        token,
        user,
        loadingLogin: false,

        // permissions are included in the login request
        permissionsReady: true,
      };
    }
    case LOGIN_FAILED: {
      utils.dialog({
        type: 'warning',
        title: 'Error de autenticación',
        message: 'Los datos introducidos no son correctos...',
      });
      return {
        ...state,
        loadingLogin: false,
      };
    }
    case LOGOUT: {
      return {
        ...state,
        token: null,
        user: null,
      };
    }
    case REFRESH_PERMISSIONS: {
      return {
        ...state,
        loadingPermissions: true,
      };
    }
    case REFRESH_PERMISSIONS_SUCCEEDED: {
      const { permissions } = action;
      return {
        ...state,
        user: {
          ...state.user,
          permissions,
        },
        loadingPermissions: false,
        permissionsReady: true,
      };
    }
    case REFRESH_PERMISSIONS_FAILED: {
      utils.dialog({
        type: 'error',
        title: 'Error de permisos',
        message: 'Los permisos de usuario no se han obtenido correctamente...',
      });
      return {
        ...state,
        loadingPermissions: false,
      };
    }
    default:
      return state;
  }
};

export default loginReducer;
