import styled from 'styled-components';

export const Wrapper = styled.div`
  z-index: 10;
  position: relative;
  margin-bottom: 30px;

  .is-bottom-aligned {
    align-items: flex-end;
  }

  .form-actions {
    display: flex;

    & > * { margin-right: 10px; }
    & > *:last-child { margin-right: 0; }

    .close-button {
      min-width: 0;
    }
  }
`;
