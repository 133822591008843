import styled from 'styled-components';

export const Wrapper = styled.section`
  .avatar {
    margin-bottom: 30px;
    img { max-width: 128px; }
  }
  .field {
    margin-bottom: 25px;
  }
  .version-display {
    margin-top: 0;
    font-size: 15px;
  }
  .box {
    margin-bottom: 10px;
  }
`;

export const Overlay = styled.div`
  z-index: 100;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: rgba(0, 0, 0, .7);
  display: flex;
  align-items: center;
  justify-content: center;

  transition: opacity .3s ease-out;

  opacity: ${({ loading }) => (loading ? 1 : 0)};
  pointer-events: ${({ loading }) => (loading ? 'all' : 'none')};
`;
