import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 30px;
  padding-top: 90px;
  max-width: 1200px;
  margin: 0 auto;

  h1 {
    text-align: center;
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 30px;
  }

  button {
    cursor: pointer;

    display: block;
    margin: 0 auto;
    padding: 10px 15px;

    font-size: 18px;
    font-weight: 900;
    text-transform: uppercase;
    background: #2da962;
    border: 1px solid rgba(0, 0, 0, .15);
    color: #fff;

    :hover {
      background: #dae08a;
      color: #788248;
    }
  }

  h2 {
    font-size: 22px;
    font-weight: 300;
    margin-top: 30px;
    margin-bottom: 15px;
  }

  pre {
    color: #187bad;
  }
`;
