import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from '@material-ui/core';

import Title from 'ui/Title';
import theme from 'ui/theme';

import { setSelection, removeSelections, requestEnrollmentActivation } from '$redux/enrollments/actions';

import { Wrapper } from './styles';
import Filters from './Filters';
import Grid from './Grid';


class EnrollmentHolds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {},
    };
  }

  setSelection = (hold, value) => {
    const { dispatch } = this.props;
    dispatch(setSelection(hold, value));
  }

  removeSelections = () => {
    const { dispatch } = this.props;
    dispatch(removeSelections());
  }

  createEnrollments = () => {
    const { dispatch, selections } = this.props;
    const { filters: { idSemester } } = this.state;
    dispatch(requestEnrollmentActivation(idSemester, selections));
  }

  applyFilters = (filtersSelected) => {
    this.setState({ filters: filtersSelected });
  }

  render() {
    const {
      selections,
      selections: { length: selectedItems },
    } = this.props;
    const { filters } = this.state;

    return (
      <Wrapper theme={theme}>
        <Title>
          Matrículas en espera
          {
            <div>
              <Button
                size="small"
                disabled={!selectedItems}
                onClick={this.createEnrollments}
              >
                Crear Matrículas ({selectedItems})
              </Button>
              <Button
                size="small"
                disabled={!selectedItems}
                onClick={this.removeSelections}
              >
                Quitar selección
              </Button>
            </div>
          }
        </Title>
        <Filters applyFilters={this.applyFilters} />
        <Grid
          filters={filters}
          selections={selections}
          setSelection={this.setSelection}
        />
      </Wrapper>
    );
  }
}

EnrollmentHolds.propTypes = {
  dispatch: PropTypes.func.isRequired,
  selections: PropTypes.array.isRequired,
};

export default connect(
  state => ({
    selections: state.enrollments.selections,
  }),
)(EnrollmentHolds);
