import {
  select, call, put, takeEvery,
} from 'redux-saga/effects';
import api from 'linkia-api';

import {
  CREATE,
  UPDATE,
  GET,
} from './action-types';
import {
  createSucceeded,
  createFailed,
  updateSucceeded,
  updateFailed,
  getSucceeded,
  getFailed,
} from './actions';

import { tokenSelector } from '../login/selectors';

function* createRoadMap(action) {
  const token = yield select(tokenSelector);
  const {
    data: {
      name,
      idTrainingPlan,
      isCustomizable,
      semester,
    },
  } = action;
  try {
    const success = yield call(api.roadMap.createWithSemester, {
      token, name, idTrainingPlan, isCustomizable, semester,
    });
    yield put(createSucceeded(success));
  } catch (e) {
    yield put(createFailed(e));
  }
}

function* updateRoadMap(action) {
  const token = yield select(tokenSelector);
  const {
    data: {
      courseOnLineHourPrice,
      idRoadMap,
      name,
      isCustomizable,
      active,
      semester,
      idTrainingPlan,
    },
  } = action;
  const data = {
    token,
    courseOnLineHourPrice,
    idRoadMap,
    name,
    isCustomizable,
    active,
    semester,
    idTrainingPlan,
  };
  try {
    const success = yield call(api.roadMap.updateRoadMap, data);
    yield put(updateSucceeded(success));
  } catch (e) {
    yield put(updateFailed(e));
  }
}

function* getRoadMap(action) {
  const token = yield select(tokenSelector);
  const { idRoadMap } = action;
  try {
    const data = yield call(api.roadMap.getRoadMap, token, idRoadMap);
    yield put(getSucceeded(data));
  } catch (e) {
    yield put(getFailed(e));
  }
}

export default [
  takeEvery(CREATE, createRoadMap),
  takeEvery(UPDATE, updateRoadMap),
  takeEvery(GET, getRoadMap),
];
