import {
  call, put, select, takeEvery,
} from 'redux-saga/effects';
import api from 'linkia-api';

import {
  ADD_SERVICES_ACTION_BULK,
  ADD_SERVICES_ACTION_USER,
  GET_SERVICES_ACTIONS_FILTER_FILTERED,
  GET_SERVICES_ACTIONS_LOGS_FILTER_FILTERED,
  GET_SERVICES_ACTIONS_WAITING,
  GET_SERVICES_EXPORT,
  GET_SERVICES_FILTER_FILTERED,
  GET_SERVICES_LOG_FILTER_FILTERED,
  GET_SERVICES_STUDENT_GRADES_FILTER_FILTERED,
  REMOVE_SERVICES_ACTION_STATUS,
  RESET_SERVICES_ACTIONS_WAITING,
  UPDATE_SERVICES_ACTION_STATUS,
} from './action-types';
import {
  requestAddServicesActionBulkFailed,
  requestAddServicesActionBulkSucceeded,
  requestAddServicesActionUserFailed,
  requestAddServicesActionUserSucceeded,
  requestChangeStatusActionServiceFailed,
  requestChangeStatusActionServiceSucceeded,
  requestGetServicesActionFilterFilteredFailed,
  requestGetServicesActionFilterFilteredSucceeded,
  requestGetServicesActionsLogsFilterFilteredFailed,
  requestGetServicesActionsLogsFilterFilteredSucceeded,
  requestGetServicesActionsWaitingFailed,
  requestGetServicesActionsWaitingSucceeded,
  requestGetServicesExportFailed,
  requestGetServicesExportSucceeded,
  requestGetServicesFilterFilteredFailed,
  requestGetServicesFilterFilteredSucceeded,
  requestGetServicesLogFilterFilteredFailed,
  requestGetServicesLogFilterFilteredSucceeded,
  requestGetServicesStudentGradesFilterFilteredFailed,
  requestGetServicesStudentGradesFilterFilteredSucceeded,
  requestRemoveActionServiceFailed,
  requestRemoveActionServiceSucceeded,
  requestResetServicesActionsWaitingFailed,
  requestResetServicesActionsWaitingSucceeded,
} from './actions';

import { tokenSelector } from '../login/selectors';

function* getServicesFilterFiltered(action) {
  const token = yield select(tokenSelector);
  const {
    bindingObject,
  } = action;
  try {
    const data = yield call(api.service.getServicesFilterFiltered, {
      bindingObject,
      token,
    });
    yield put(requestGetServicesFilterFilteredSucceeded(data));
  } catch (e) {
    yield put(requestGetServicesFilterFilteredFailed());
  }
}

function* getServicesLogFilterFiltered(action) {
  const token = yield select(tokenSelector);
  const {
    bindingObject,
  } = action;
  try {
    const data = yield call(api.service.getServicesLogFilterFiltered, {
      bindingObject,
      token,
    });
    yield put(requestGetServicesLogFilterFilteredSucceeded(data));
  } catch (e) {
    yield put(requestGetServicesLogFilterFilteredFailed());
  }
}

function* getServicesActionsFilterFiltered(action) {
  const token = yield select(tokenSelector);
  const {
    bindingObject,
  } = action;
  try {
    const data = yield call(api.service.getServicesActionsFilterFiltered, {
      bindingObject,
      token,
    });
    yield put(requestGetServicesActionFilterFilteredSucceeded(data));
  } catch (e) {
    yield put(requestGetServicesActionFilterFilteredFailed());
  }
}

function* getServicesActionsLogsFilterFiltered(action) {
  const token = yield select(tokenSelector);
  const {
    bindingObject,
  } = action;
  try {
    const data = yield call(api.service.getServicesActionsLogsFilterFiltered, {
      bindingObject,
      token,
    });
    yield put(requestGetServicesActionsLogsFilterFilteredSucceeded(data));
  } catch (e) {
    yield put(requestGetServicesActionsLogsFilterFilteredFailed());
  }
}

function* getServicesStudentsGradesFilterFiltered(action) {
  const token = yield select(tokenSelector);
  const {
    bindingObject,
  } = action;
  try {
    const data = yield call(api.service.getServicesStudentsGradesFilterFiltered, {
      bindingObject,
      token,
    });
    yield put(requestGetServicesStudentGradesFilterFilteredSucceeded(data));
  } catch (e) {
    yield put(requestGetServicesStudentGradesFilterFilteredFailed());
  }
}

function* addServicesActionUser(action) {
  const token = yield select(tokenSelector);
  const {
    bindingObject,
  } = action;
  try {
    const success = yield call(api.service.addServicesActionUser, {
      bindingObject,
      token,
    });
    yield put(requestAddServicesActionUserSucceeded(success));
  } catch (e) {
    yield put(requestAddServicesActionUserFailed());
  }
}

function* addServicesActionBulk(action) {
  const token = yield select(tokenSelector);
  const {
    bindingObject,
  } = action;
  try {
    const success = yield call(api.service.addServicesActionsBulk, {
      bindingObject,
      token,
    });
    yield put(requestAddServicesActionBulkSucceeded(success));
  } catch (e) {
    yield put(requestAddServicesActionBulkFailed());
  }
}

function* getServicesExport(action) {
  const token = yield select(tokenSelector);
  const { bindingObject } = action;
  try {
    const data = yield call(api.service.getServicesExport, {
      bindingObject,
      token,
    });
    yield put(requestGetServicesExportSucceeded(data));
  } catch (e) {
    yield put(requestGetServicesExportFailed());
  }
}

function* getServicesActionsWaiting(action) {
  const token = yield select(tokenSelector);
  const {
    bindingObject,
  } = action;
  try {
    const data = yield call(api.service.getServicesActionsWaitingConfirmation, {
      bindingObject,
      token,
    });
    yield put(requestGetServicesActionsWaitingSucceeded(data));
  } catch (e) {
    yield put(requestGetServicesActionsWaitingFailed());
  }
}

function* resetServicesActionsWaiting(action) {
  const token = yield select(tokenSelector);
  const { idUserService } = action;
  try {
    const data = yield call(api.service.resetServicesActionsToWaitingStatus, {
      bindingObject: idUserService,
      token,
    });
    yield put(requestResetServicesActionsWaitingSucceeded(data));
  } catch (e) {
    yield put(requestResetServicesActionsWaitingFailed(e));
  }
}

function* updateServiceActionStatus(action) {
  const token = yield select(tokenSelector);
  const {
    id,
    status,
  } = action;
  try {
    const success = yield call(api.service.changeStatusUserServiceAction, {
      bindingObject: {
        status,
      },
      token,
      id,
    });
    yield put(requestChangeStatusActionServiceSucceeded(success));
  } catch (e) {
    yield put(requestChangeStatusActionServiceFailed(e));
  }
}

function* deleteServiceAction(action) {
  const token = yield select(tokenSelector);
  const { id } = action;
  try {
    const success = yield call(api.service.deleteUserServiceAction, {
      token,
      id,
    });
    yield put(requestRemoveActionServiceSucceeded(success));
  } catch (e) {
    yield put(requestRemoveActionServiceFailed(e));
  }
}

export default [
  takeEvery(ADD_SERVICES_ACTION_USER, addServicesActionUser),
  takeEvery(GET_SERVICES_FILTER_FILTERED, getServicesFilterFiltered),
  takeEvery(GET_SERVICES_LOG_FILTER_FILTERED, getServicesLogFilterFiltered),
  takeEvery(GET_SERVICES_ACTIONS_FILTER_FILTERED, getServicesActionsFilterFiltered),
  takeEvery(GET_SERVICES_ACTIONS_LOGS_FILTER_FILTERED, getServicesActionsLogsFilterFiltered),
  takeEvery(GET_SERVICES_STUDENT_GRADES_FILTER_FILTERED, getServicesStudentsGradesFilterFiltered),
  takeEvery(GET_SERVICES_EXPORT, getServicesExport),
  takeEvery(ADD_SERVICES_ACTION_BULK, addServicesActionBulk),
  takeEvery(GET_SERVICES_ACTIONS_WAITING, getServicesActionsWaiting),
  takeEvery(RESET_SERVICES_ACTIONS_WAITING, resetServicesActionsWaiting),
  takeEvery(UPDATE_SERVICES_ACTION_STATUS, updateServiceActionStatus),
  takeEvery(REMOVE_SERVICES_ACTION_STATUS, deleteServiceAction),
];
