import {
  select, call, put, takeEvery,
} from 'redux-saga/effects';
import api from 'linkia-api';

import {
  REQUEST_UTM_FILTERS,
  REQUEST_UTM_EXPORT,
  REQUEST_UTM_EXPORT_WITH_ENROLL,
} from './action-types';

import {
  reqUtmFiltersSucceeded,
  reqUtmFiltersFailed,
  requestUtmExportSucceeded,
  requestUtmExportFailed,
  requestUtmExportWithEnrollSucceeded,
  requestUtmExportWithEnrollFailed,
} from './actions';


import { tokenSelector } from '../login/selectors';

function* actionUtmFilters(action) {
  const token = yield select(tokenSelector);
  const {
    bindingObject,
  } = action;
  try {
    const data = yield call(api.utm.getUtmsFiltered, {
      bindingObject, token,
    });
    yield put(reqUtmFiltersSucceeded(data, bindingObject));
  } catch (e) {
    yield put(reqUtmFiltersFailed());
  }
}

function* actionUtmExport(action) {
  const token = yield select(tokenSelector);
  const { bindingObject } = action;
  try {
    const data = yield call(api.utm.getUtmsExport, {
      bindingObject,
      token,
    });
    yield put(requestUtmExportSucceeded(data));
  } catch (e) {
    yield put(requestUtmExportFailed());
  }
}

function* actionUtmExportWithEnroll(action) {
  const token = yield select(tokenSelector);
  const { bindingObject } = action;
  try {
    const data = yield call(api.utm.getUtmsExportWithEnroll, {
      bindingObject,
      token,
    });
    yield put(requestUtmExportWithEnrollSucceeded(data));
  } catch (e) {
    yield put(requestUtmExportWithEnrollFailed());
  }
}

export default [
  takeEvery(REQUEST_UTM_FILTERS, actionUtmFilters),
  takeEvery(REQUEST_UTM_EXPORT, actionUtmExport),
  takeEvery(REQUEST_UTM_EXPORT_WITH_ENROLL, actionUtmExportWithEnroll),
];
