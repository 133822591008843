import React from 'react';
import PropTypes from 'prop-types';
import SubTitle from 'ui/SubTitle';

import {
  Wrapper,
  SemesterWrapper,
} from './RoadMap.styles';
import RoadMapModuleRow from './RoadMapModuleRow';

class RoadMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.semesters = [];
  }

  onCheckModule = (module, semester, checked) => {
    const {
      data,
      checkmodule,
      movemodule,
    } = this.props;

    const move = this.checkMove(
      checked,
      data.RoadMapModules,
      module,
      semester,
    );

    if (move) {
      movemodule(
        module.idModule,
        semester.semester,
        semester.courseYear,
      );
    } else {
      checkmodule(
        module.idModule,
        checked,
        semester,
      );
    }

    return true;
  }

  checkMove = (checked, roadMapModules, module, semester) => {
    let move = false;

    const hasMove = checked && module && semester;
    if (!hasMove) {
      return move;
    }

    if (checked && module && semester) {
      roadMapModules.forEach((roadMapModule) => {
        const isModule = roadMapModule.Module.idModule === module.idModule;
        const IsChecked = roadMapModule.Module.checked;
        const hasDifferentSemester = semester.semester !== module.semester
          || semester.courseYear !== module.courseYear;

        if (isModule && IsChecked && hasDifferentSemester) {
          move = true;
          return true;
        }

        return true;
      });
    }
    return move;
  }

  getModuleName = module => (module.code.toUpperCase().concat(': ').concat(module.name));

  getModuleCode = module => ('M'.concat(module.idModule));

  createModuleItemsHold = (roadMapModule, semester) => {
    const { checkunit } = this.props;

    const module = roadMapModule.Module;
    module.stage = roadMapModule.ModuleSemester.stage;

    const itemText = this.getModuleName(module);
    const moduleKey = this.getModuleCode(module);

    return (
      <RoadMapModuleRow
        key={moduleKey}
        checked={module.checked}
        itemText={itemText}
        module={module}
        checkmodule={this.onCheckModule}
        semester={semester}
        checkunit={checkunit}
      />
    );
  }

  createModuleItemsOld = (roadMapModule, semester) => {
    let itemModule;
    const { checkunit } = this.props;

    if (roadMapModule.ModuleSemester.semester === semester.semester) {
      const module = roadMapModule.Module;
      module.stage = roadMapModule.ModuleSemester.stage;

      const itemText = this.getModuleName(module);
      const moduleKey = this.getModuleCode(module);

      let matchSemester = false;
      if (roadMapModule.Module.courseYear) {
        matchSemester = roadMapModule.Module.courseYear === semester.courseYear
          && roadMapModule.Module.semester === semester.semester;
      } else {
        matchSemester = roadMapModule.Module.moduleSemesterCourseYear === semester.courseYear
          && roadMapModule.Module.moduleSemesterSemester === semester.semester;
      }
      const checked = module.checked && matchSemester;
      itemModule = (
        <RoadMapModuleRow
          key={moduleKey}
          checked={checked}
          itemText={itemText}
          module={module}
          semester={semester}
          checkmodule={this.onCheckModule}
          checkunit={checkunit}
        />
      );
    }
    return itemModule;
  }

  createModuleItemsParent = (roadMapModule, semester) => {
    const { checkunit } = this.props;

    if (roadMapModule.ModuleSemester.semester !== semester.semester) {
      return false;
    }

    const checked = roadMapModule.Module.checked
      && roadMapModule.Module.semester === semester.semester
      && roadMapModule.Module.courseYear === semester.courseYear;

    const module = roadMapModule.Module;
    module.stage = roadMapModule.ModuleSemester.stage;

    const itemText = this.getModuleName(
      module,
    );
    const moduleKey = this.getModuleCode(
      module,
    );

    return (
      <RoadMapModuleRow
        disabled={semester.disabled}
        key={moduleKey}
        checked={checked}
        itemText={itemText}
        module={module}
        semester={semester}
        checkmodule={this.onCheckModule}
        checkunit={checkunit}
      />
    );
  }

  createSemesterItem(semester, roadMapModules, type) {
    const semesterGroupKey = 'SG'.concat(semester.idModuleSemester);
    const semesterRowKey = 'SR'.concat(semester.idModuleSemester);
    const semesterKey = 'S'.concat(semester.idModuleSemester);

    let subItems = null;

    const hasType = ['OLD', 'PERSONALIZED', 'HOLD', 'PARENT'].includes(type);
    if (!hasType) {
      throw new Error('Invalid type');
    }

    if (type === 'OLD' || type === 'PERSONALIZED') {
      subItems = roadMapModules.map(
        roadMapModule => this.createModuleItemsOld(roadMapModule, semester),
      );
    } else if (type === 'HOLD') {
      subItems = roadMapModules.map(
        roadMapModule => this.createModuleItemsHold(roadMapModule, semester),
      );
    } else if (type === 'PARENT') {
      subItems = roadMapModules.map(
        roadMapModule => this.createModuleItemsParent(roadMapModule, semester),
      );
    }

    return (
      <div key={semesterGroupKey} className="roadmap-semester">
        <SemesterWrapper>
          <div key={semesterRowKey} className="columns">
            <div key={semesterKey} className="column">{ semester.stage }</div>
          </div>
        </SemesterWrapper>
        <div className="modules columns is-multiline content">
          {subItems}
        </div>
      </div>
    );
  }

  render() {
    const { data, type } = this.props;
    let roadMapCustomizable;
    if (data.Semesters) {
      roadMapCustomizable = data.Semesters.map(
        semester => this.createSemesterItem(
          semester,
          data.RoadMapModules,
          type,
        ),
      );
    }

    return (
      <Wrapper>
        <SubTitle>Configuración</SubTitle>
        {roadMapCustomizable}
      </Wrapper>
    );
  }
}

RoadMap.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
  checkmodule: PropTypes.func.isRequired,
  checkunit: PropTypes.func.isRequired,
  movemodule: PropTypes.func.isRequired,
};

RoadMap.defaultProps = {
  data: null,
  type: '',
};

export default RoadMap;
