import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Button } from '@material-ui/core';
import SubTitle from 'ui/SubTitle';

import { Wrapper } from './styles';

import BasicForm from './BasicForm';
import DeadlinesForm from './DeadlinesForm';
import CoursesForm from './CoursesForm';
import AcademiesForm from './AcademiesForm';
import VoucherForm from './VoucherForm';

// eslint-disable-next-line
class Form extends React.Component {
  render() {
    const {
      action,
      data,
      ui,
      set,
      onSend,
    } = this.props;

    return (
      <Wrapper>
        <BasicForm data={data} set={set} />
        <DeadlinesForm data={data} set={set} />

        <SubTitle>Restringir por cursos con metodología</SubTitle>
        <CoursesForm data={data} ui={ui} set={set} />

        <SubTitle>Restringir por centros</SubTitle>
        <AcademiesForm data={data} ui={ui} set={set} />

        <SubTitle>Restringir por código promocional</SubTitle>
        <VoucherForm data={data} set={set} />

        <div className="form-actions">
          {
            action === 'edit' ? (
              <Button color="primary" onClick={onSend}>Actualizar</Button>
            ) : (
              <Button color="secondary" onClick={onSend}>Crear</Button>
            )
          }
          <Link to="/cobros"><Button>Volver</Button></Link>
        </div>
      </Wrapper>
    );
  }
}

Form.propTypes = {
  action: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  set: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
};

export default Form;
