import {
  FILTERS_REQUEST,
  FILTERS_REQUEST_SUCCEEDED,
  FILTERS_REQUEST_FAILED,
  CLEAN_FILTERS_REQUEST,
  GET_ENROLLMENTS_FILTERED_WITH_SERVICES,
  GET_ENROLLMENTS_FILTERED_WITH_SERVICES_SUCCEEDED,
  GET_ENROLLMENTS_FILTERED_WITH_SERVICES_FAILED,
} from './action-types';
import utils from '../utils';

const initialState = {
  data: [],
  loading: false,
  redirectTo: '',
};

const enrollmentsWithServicesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case FILTERS_REQUEST:
    case GET_ENROLLMENTS_FILTERED_WITH_SERVICES:
    {
      return {
        ...state,
        loading: true,
      };
    }
    case CLEAN_FILTERS_REQUEST: {
      return {
        ...state,
        filters: {},
      };
    }
    case GET_ENROLLMENTS_FILTERED_WITH_SERVICES_SUCCEEDED:
    {
      const {
        data, pages, origin, bindingObject: { filters },
      } = action;
      return {
        ...state,
        [origin]: data,
        filters,
        pages,
        loading: false,
      };
    }
    case GET_ENROLLMENTS_FILTERED_WITH_SERVICES_FAILED:
    {
      utils.dialog({
        type: 'warning',
        title: 'Error inesperado',
        message: 'No se ha podido cargar las matrículas',
      });
      return {
        ...state,
        loading: false,
      };
    }
    case FILTERS_REQUEST_SUCCEEDED: {
      const { filtersData } = action;
      return {
        ...state,
        filtersData,
        loading: false,
      };
    }
    case FILTERS_REQUEST_FAILED: {
      utils.dialog({
        type: 'error',
        title: 'Error de carga',
        message: 'No se ha podido traer la información referente a los filtros',
      });
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default enrollmentsWithServicesReducer;
